import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import * as actionType from "../../../redux/actionTypes/csaSignup";
import ModalComponent from "../../common/components/modal";
import { useForm } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import HelpIcon from "@mui/icons-material/Help";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "./snap-share.scss";
import {
  Table,
  TableRow,
  TableCell,
  Button,
  Divider,
  Grid,
  Checkbox,
  Box,
  FormControlLabel,
  Typography,
  Link,
  Stack,
} from "@mui/material";
import ShareNestedFieldArray from "./share-nested-fieldArray";
import { popUpAlert } from "../../../utils/globalMethods";
import { twoDigitdecNum } from "../../../utils/globalMethods";
import { useParams } from "react-router-dom";
import CommonLoading from "../../common/components/commonLoading";
import { CustomButton } from "../../common/components/customButton";
import useTranslator from "../../../hooks/useTranslator";
import useLanguageCode from "../../../hooks/useLanguageCode";

function SnapShareComponent(props) {
  const languageCode = useLanguageCode();

  const transProps = useTranslator(
    [
      "summerShareDetails",
      "SPFStartMonth",
      "shareCost",
      "SPFPaymentSchedule",
      "paymentDate",
      "paymentAmount",
      "paymentShedule",
      "overallCost",
      "ok",
      "SPFShare",
      "howToUseThisPage",
      "seeHowSPFworks",
      "Pick",
      "SPF",
      "shareBelow",
      "benifites",
      "requestHelp",
      "ifTheFarm",
      "estimated",
      "SPFPaymentDates",
      "SPFPilot",
      "SPFAmount",
      "SPFMonthlyCap",
      "EBTBalance",
      "pleaseCheck",
      "soldOut",
      "showPrecalculation",
      "shareDetails",
      "monthlyShareCost",
      "paymentSchedule",
      "pleaseChoose",
      "upcomingMonthly",
      "payWithSPF",
      "amountSPF",
      "SPFwill",
      "prev",
      "next",
    ],
    "spfShare"
  );
  let { agreementId } = useParams();
  const navigate = useNavigate();
  let loginId = props?.userAuth?.user?.id; //searchParams.get("loginId");
  const [clientInfo, setClientInfo] = useState();
  const [sharePrecal, setSharePrecal] = useState();
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState("");
  const [showContent, setShowContent] = useState();
  const [showOne, setShowOne] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [shwoMnthlyTxt, setShowMnthlyTxt] = useState(false);
  const [mnthlyCost, setMnthlyCost] = useState();
  const [isLoggin] = useState(loginId ? true : false);
  // eslint-disable-next-line no-unused-vars
  const [selectedAgrmntShr, setSelectedAgrmntShr] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isShareRowEdit, setIsShareRowEdit] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [popupShareId, setPopupShareId] = useState();
  const [allSharePrecal, setAllSharePrecal] = useState();
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    errors,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      share: [
        {
          name: "",
          nestedArray: [
            {
              nam: "",
              dsc: "",
              shareId: "",
              cost: "",
              type: "",
              startDate: "",
              endDate: "",
            },
          ],
        },
      ],
    },
  });

  // const formData = watch();

  useEffect(() => {
    if (props.vendorChange) {
      setShowMnthlyTxt(false);
      setSharePrecal();
      setShowContent([]);
    } else {
      setShowContent(props?.selectedShare);
    }
    if (
      props.csaSignup?.agrmntShareList?.agrmntShareList &&
      props.csaSignup?.agrmntShareList?.agrmntShareList.length
    ) {
      setSelectedAgrmntShr(props.csaSignup?.agrmntShareList?.agrmntShareList);
      props.csaSignup?.agrmntShareList?.agrmntShareList.forEach((item, i) => {
        setShowContent((preState) => ({
          ...preState,
          [item.shareId]: true,
        }));
        // call precalculation api
        const data = {
          id: item?.shareId,
          shareCost: item?.shareCost,
          startDate: item?.startDate,
          endDate: item?.endDate,
        };
        setTimeout(() => {
          handleCheckClick(data);
        }, i * 1000);
      });
      setTimeout(() => {
        const ids = props.csaSignup?.agrmntShareList?.agrmntShareList.map(
          (item) => item.shareId
        );
        setShowMnthlyTxt(ids.length);
        const monthlyConst = props.csaSignup?.agrmntShareList?.agrmntShareList
          .map((item) => item.shareCost)
          .reduce((partialSum, a) => partialSum + a, 0);
        setMnthlyCost(monthlyConst);
      }, 500);
    } else {
      setSelectedAgrmntShr([]);
    }
  }, [
    props.vendorChange,
    props?.selectedShare,
    props.csaSignup?.agrmntShareList,
  ]);

  useEffect(() => {
    setSharePrecal(props?.sharePrecal);
  }, [props?.sharePrecal]);

  useEffect(() => {
    setClientInfo(props?.clientInfo);
  }, [props?.clientInfo]);

  const snapCsaContent = () => {
    setShowOne(!showOne);
  };
  const hipContent = () => {
    setShowTwo(!showTwo);
  };

  const Estimated = () => {
    setShowThree(!showThree);
  };

  useEffect(() => {
    if (agreementId) {
      const payload = { agreementId };
      props.getClientAgrmntShrLst(payload);
    }
  }, [agreementId]);

  useEffect(() => {
    const payload = {
      vendorId: props?.farmInfo?.farmDetails?.vendorId,
    };
    props.getCSAShareList(payload);
  }, [props?.farmInfo?.farmDetails?.vendorId]);

  const handleButtonClick = (data) => {
    // while share details edit in admin signup
    if (isShareRowEdit && Object.keys(isShareRowEdit).length) {
      const isShareNotSave = Object.values(isShareRowEdit).some(
        (item) => item === true
      );
      if (isShareNotSave) {
        popUpAlert({
          text: "Please save the edited Share Information",
          icon: "warning",
          timer: "2000",
        });
        return false;
      }
    }
    setIsSubmit(true);
    props.setVendorChange(false);
    submitShareForm(data, true);
    props.setAllSharePrecal(allSharePrecal);
  };

  const submitShareForm = (data, isSubmit) => {
    const value = Object.values(showContent);
    let isShareSelected = (arr) => arr.some(Boolean);

    // const total = props?.clientInfo?.snapAmount + props?.clientInfo?.hipAmount;
    if (!isShareSelected(value)) {
      setIsError(true);
    } else {
      setIsError(false);
      const ids = Object.keys(showContent).filter(
        (k) => showContent[k] === true
      );
      const shares = props.csaSignup?.shareList?.shareList.filter(
        (item) => ids.indexOf(item.id.toString()) !== -1
      );
      if (isLoggin) {
        const updatedShare = data.share.filter(
          (item) => ids.indexOf(item?.shareId) !== -1
        );
        shares.map((item, i) => {
          item.nestedArray = updatedShare[i]?.nestedArray;
          return item;
        });
        props.shareDetails(shares);
      } else {
        props.shareDetails(shares);
      }

      // console.log(isLoggin);
      // console.log(mnthlyCost);
      // console.log(total);
      // console.log(Math.max(...value));
      if (!isLoggin) {
        const hipSnapAmt =
          props?.farmInfo?.farmDetails?.isHIPVendor == "Y"
            ? clientInfo?.snapAmount + clientInfo?.hipAmount
            : clientInfo?.snapAmount;
        // console.log(hipSnapAmt);
        if (mnthlyCost > hipSnapAmt) {
          submitAlert(shares);
          return false;
        }
      }

      // if (total < Math.max(...value)) {
      //   openSweetAlert();
      // } else {
      if (isSubmit) {
        props.setActiveTab("menuStep4");
      }
      props.setSelectedShares(showContent);
      props.setSharePrecal(sharePrecal);
      props.setCSAFormValidate((prevState) => ({
        ...prevState,
        snap_csa: true,
      }));
      // }
    }
  };

  const submitAlert = (shares) => {
    popUpAlert({
      text: "We have captured the information you entered so far. In some cases, you will not be able to finish this SPF request online. Pick “yes” to get help from DTA and we will contact you in the next few business days. Pick “no” to return to the form",
      icon: "info",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      onConfirmed: true,
      handleConfirm: () => {
        saveCSASignup(shares);
      },
    });
  };

  const saveCSASignup = (data) => {
    const share = [];
    let totalShrCost = 0;
    if (data.length) {
      data.forEach((item, i) => {
        const startDate =
          moment(item?.startDate) < moment()
            ? moment()
            : moment(item?.startDate);
        const date1 = startDate;
        const date2 = moment(item?.endDate);
        const diff = date2?.diff(date1, "months");
        totalShrCost = totalShrCost + item?.shareCost;
        const shareObj = {
          index: i + 1,
          shareId: item?.id,
          shareName: item?.nam,
          dsc: item?.dsc,
          dlvryFlg: item?.dlvryFlg,
          shareSelected: [
            {
              duration: diff + " Month(s)",
              shareStartDate: dayjs(startDate).format("MM/DD/YYYY"),
              shareEndDate: dayjs(item?.endDate).format("MM/DD/YYYY"),
              paymentType: "MNTHLY",
              submittedBy: "CLIENT",
              determinedAmount: item?.shareCost,
            },
          ],
          submittedBy: "CLIENT",
        };
        share.push(shareObj);
      });
    }

    const timestamp = new Date().getTime();
    const systemInfo = sessionStorage.getItem("systemInfo");
    const systemData = JSON.parse(systemInfo);
    const csaSignupForm = {
      apid: clientInfo?.apid,
      dob: clientInfo?.dob,
      email: clientInfo?.email,
      farmDetails: props?.farmInfo?.farmDetails,
      firstName: clientInfo?.firstName,
      hhSize: clientInfo?.hhSize,
      languageCode: "en",
      lastName: clientInfo?.lastName,
      chosenName: clientInfo?.chosenNam,
      panNumber: clientInfo?.panNumber,
      phoneNumber: clientInfo?.phoneNumber,
      shareDetails: share,
      totalAmount: totalShrCost,
      totalShareAmountBalance: 0,
      snapAmount: clientInfo?.snapAmount,
      hipAmount: clientInfo?.hipAmount,
      totalShareAmountPaid: totalShrCost,
      lastUpdtUidNam: "CSASGNUP",
      createTimeStamp: timestamp,
      lastUpdtTimeStamp: timestamp,
      ipAddress: systemData.ip,
    };
    // console.log(csaSignupForm)
    props.saveCSASignup(csaSignupForm);
  };

  useEffect(() => {
    if (props.csaSignup?.saveCSASignup?.code == "200") {
      const payload = {
        clientNam: clientInfo?.firstName + " " + clientInfo?.lastName,
        isNeedHelp: "Y",
        vendorName: props?.farmInfo?.farmDetails?.farmName,
      };
      props.getRequestHelp(payload);
    }
  }, [props.csaSignup?.saveCSASignup]);

  useEffect(() => {
    // console.log(props.csaSignup?.requestHelp);
    if (isSubmit) {
      if (props.csaSignup?.requestHelp?.requestHelp?.code === "200") {
        popUpAlert({
          text: "Mail sent successfully",
          icon: "success",
          timer: "1000",
        });
        setTimeout(() => {
          navigate("/");
        }, 1001);
      }
      setIsSubmit(false);
    }
  }, [props.csaSignup?.requestHelp?.requestHelp]);

  useEffect(() => {
    // console.log(props.csaSignup?.snapShare?.snapShare);
    if (props.csaSignup?.snapShare && props.csaSignup?.snapShare?.snapShare) {
      setSharePrecal((prevState) => ({
        ...prevState,
        [props.csaSignup?.snapShare?.snapShare[0]?.shareId]:
          props.csaSignup?.snapShare?.snapShare,
      }));

      setAllSharePrecal((prevState) => ({
        ...prevState,
        [props.csaSignup?.snapShare?.snapShare[0]?.shareId]:
          props.csaSignup?.snapShare?.snapShare,
      }));
    }
  }, [props.csaSignup?.snapShare]);

  const handleCheckClick = (data) => {
    const payloadOne = {
      // monthly
      clientId: props?.clientInfo?.apid,
      shareId: data?.id,
      shareStartDate:
        dayjs(data?.startDate) < dayjs()
          ? dayjs().add(1, "month").format("MM/01/YYYY")
          : dayjs(data?.startDate).format("MM/DD/YYYY"), //dayjs(data?.startDate).format("MM/DD/YYYY"),
      shareEndDate: dayjs(data?.endDate).format("MM/DD/YYYY"),
      paymentType: data.type === 1 ? "DAILY" : "MNTHLY", //"MNTHLY",
      determinedAmount: data?.shareCost,
      submittedBy: isLoggin ? "ADMIN" : "CLIENT",
    };
    const payloadArray = [payloadOne];

    props.getSnapShare(payloadArray);
  };

  const handleCheckClickTwo = () => {
    const payload = {
      clientNam: clientInfo?.firstName + " " + clientInfo?.lastName,
      isNeedHelp: "Y",
      vendorName: props?.farmInfo?.farmDetails?.farmName,
    };
    props.getRequestHelp(payload);
    popUpAlert({
      text: "Mail sent successfully",
      icon: "success",
      timer: "1000",
    });
  };

  useEffect(() => {
    /* eslint-disable no-unsafe-optional-chaining */
    const { loading } = props?.csaSignup?.shareList;
    setData(props.csaSignup?.shareList?.shareList);
    setTimeout(() => {
      resetShare();
      setIsLoading(loading);
    }, 300);
  }, [props.csaSignup?.shareList?.shareList]);

  const resetShare = () => {
    /* eslint-disable no-unsafe-optional-chaining */
    const arr = [];
    const shareData = [...props.csaSignup?.shareList?.shareList];
    shareData?.forEach((item) => {
      const obj = {
        id: item?.id,
        name: "",
        nestedArray: [
          {
            nam: item?.nam,
            dsc: item?.dsc,
            shareId: item?.id,
            cost: item?.shareCost,
            type: "2",
            startDate:
              dayjs(item?.startDate) < dayjs()
                ? dayjs().add(1, "month").format("MM/01/YYYY")
                : dayjs(item?.startDate).format("MM/DD/YYYY"),
            endDate:
              dayjs(item?.endDate) < dayjs()
                ? dayjs().format("MM/DD/YYYY")
                : dayjs(item?.endDate).format("MM/DD/YYYY"),
          },
          // { nam: item?.nam, dsc: item?.dsc, shareId: item?.id, cost: item?.shareCost, type: "2", startDate: dayjs(item?.startDate) < dayjs() ? dayjs().format("MM/DD/YYYY") : dayjs(item?.startDate).format("MM/DD/YYYY"), endDate: dayjs().add(1, 'year').format("MM/DD/YYYY") }
        ],
      };
      arr.push(obj);
    });
    const defaultValues = {
      share: arr,
    };
    reset(defaultValues);
  };

  const openSweetAlert = () => {
    popUpAlert({
      text: "We have captured the information you entered so far. In some cases, you will not be able to finish this SPF request online. Pick “yes” to get help from KFresh Team and we will contact you in the next few business days. Pick “no” to return to the form",
      icon: "info",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => handleCheckClickTwo(),
      // setTimeout(() => {
      //   openSweetAlertTwo();
      //   setTimeout(() => {
      //     navigate('/');
      //   }, 1001);
      // }, 1000);}
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     handleCheckClickTwo();
      //     setTimeout(() => {
      //       openSweetAlertTwo();
      //       setTimeout(() => {
      //         navigate('/');
      //       }, 1001);
      //     }, 1000);
      //   }
    });
  };

  const formatemonth = (item) => {
    return moment(item).format("MMM");
  };

  useEffect(() => {
    if (showContent) {
      const ids = Object.keys(showContent).filter(
        (k) => showContent[k] === true
      );
      setShowMnthlyTxt(ids.length);
      const shares = props.csaSignup?.shareList?.shareList.filter(
        (item) => ids.indexOf(item.id.toString()) !== -1
      );
      const monthlyConst = shares
        .map((item) => item.shareCost)
        .reduce((partialSum, a) => partialSum + a, 0);
      setMnthlyCost(monthlyConst);
      // setIsLoading(true);
    }
  }, [showContent]);

  const calcMnthlyCost = () => {
    const ids = Object.keys(showContent).filter((k) => showContent[k] === true);
    const monthlyConst = getValues()
      .share.filter((item) => ids.indexOf(item.id.toString()) !== -1)
      .map((item) => parseFloat(item.nestedArray[0].cost))
      .reduce((partialSum, a) => partialSum + a, 0);
    setMnthlyCost(monthlyConst);
  };

  const getShareName = (item) => {
    // console.log(item)
    let nam = item.nam;
    if (item.multiLang && item.multiLang.length) {
      // console.log(item.multiLang)
      nam = item.multiLang.find((lang) => lang.lang === languageCode)?.nam;
      if (!nam) {
        nam = item.nam;
      }
    }
    return nam;
  };

  const getShareDsc = (item) => {
    // console.log(item)
    let dsc = item.dsc;
    if (item.multiLang && item.multiLang.length) {
      // console.log(item.multiLang)
      dsc = item.multiLang.find((lang) => lang.lang === languageCode)?.dsc;
      if (!dsc) {
        dsc = item.dsc;
      }
    }
    return dsc;
  };

  const renderModal = () => {
    return (
      <Grid>
        {sharePrecal &&
          sharePrecal[popupShareId] &&
          sharePrecal[popupShareId].map((item, i) => (
            <div key={i}>
              {item && (
                <Box
                  sx={{
                    // marginTop: "10px",
                    marginBottom: "10px",
                    width: "700px",
                    padding: "3px",
                    marginLeft: "-10px",
                  }}
                >
                  <Grid
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#FFFF",
                      fontSize: "20px",
                      fontFamily: "Texta-bold",
                      marginLeft: "10px",
                      padding: "18px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        fontFamily: "Texta-bold",
                      }}
                    >
                      {transProps.summerShareDetails} -{" "}
                      {item?.paymentType.toUpperCase() === "DAILY"
                        ? "Daily Share"
                        : "Monthly Share"}
                    </Typography>
                    <br />
                    <Typography
                      sx={{ fontSize: "20px", fontFamily: "Texta-book" }}
                    >
                      {transProps.SPFStartMonth}:{" "}
                      {formatemonth(item?.paymentInfo[0]?.paymentDate)} | SPF
                      End Month :{" "}
                      {formatemonth(
                        item?.paymentInfo[item?.paymentInfo.length - 1]
                          ?.paymentDate
                      )}{" "}
                      |
                      {item?.paymentType.toUpperCase() === "DAILY"
                        ? " Daily"
                        : " Monthly"}{" "}
                      {transProps.shareCost}: ${" "}
                      {item?.paymentInfo[0].paymentAmount}
                    </Typography>
                    <br />
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        fontFamily: "Texta-bold",
                      }}
                    >
                      {" "}
                      {transProps.SPFPaymentSchedule}{" "}
                    </Typography>{" "}
                    <br />
                    <Table sx={{ border: "1px solid", maxWidth: "50%" }}>
                      <TableRow
                        sx={{
                          border: "1px 0px  2px 0px solid #535954",
                          fontSize: "20px !important",
                        }}
                      >
                        <TableCell
                          sx={{
                            border: "1px  solid",
                            fontFamily: "Texta-book",
                            fontSize: "19px !important",
                            fontWeight: "bold",
                            lineHeight: "1.5px",
                          }}
                        >
                          {transProps.paymentDate}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1px  solid",
                            fontSize: "19px !important",
                            fontFamily: "Texta-book",
                            fontWeight: "bold",
                            lineHeight: "1.5px",
                          }}
                        >
                          {transProps.paymentAmount}
                        </TableCell>
                      </TableRow>
                      {item?.paymentInfo.map((item, i) => (
                        <TableRow
                          key={i}
                          sx={{
                            border: "1px solid",
                            fontSize: "20px",
                            fontFamily: "Texta-book",
                          }}
                        >
                          <TableCell
                            sx={{
                              border: "1px solid",
                              fontFamily: "Texta-book",
                              fontSize: "19px !important",
                              lineHeight: "1.5px",
                            }}
                          >
                            {item.paymentDate}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "1px solid",
                              fontFamily: "Texta-book",
                              fontSize: "19px !important",
                              lineHeight: "1.5px",
                            }}
                          >
                            ${twoDigitdecNum(item.paymentAmount)}{" "}
                            {i === 0 && (
                              <div
                                className="tooltip"
                                style={{ display: "inline" }}
                              >
                                <HelpIcon className="green-color cursor-pointer" />
                                <span className="tooltiptext">
                                  {transProps.paymentShedule}
                                </span>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow
                        sx={{
                          border: "2px 1px 1px 1px solid",
                          fontSize: "20px",
                          fontFamily: "Texta-book",
                          lineHeight: "1.5px",
                        }}
                      >
                        <TableCell
                          sx={{
                            border: "1px solid",
                            fontFamily: "Texta-book",
                            fontSize: "19px !important",
                            fontWeight: "bold",
                            lineHeight: "1.5px",
                          }}
                        >
                          {transProps.overallCost}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1px  solid",
                            fontFamily: "Texta-book",
                            fontSize: "19px !important",
                          }}
                        >
                          {" "}
                          ${twoDigitdecNum(item?.totalAmount)}
                        </TableCell>
                      </TableRow>
                    </Table>
                  </Grid>
                </Box>
              )}
            </div>
          ))}
        <Button
          variant="contained"
          className="followUp-Button"
          onClick={() => {
            setShowModal(false);
          }}
        >
          {transProps.ok}
        </Button>
      </Grid>
    );
  };

  const showPrecalculationDetails = (item) => {
    setShowModal(true);
    setPopupShareId(item.id);
    callPreCal(item);
  };

  const callPreCal = (item) => {
    const shares = getValues()["share"];
    const matchingShare = shares.find((data) => data.id == item.id);

    if (matchingShare) {
      const shareArr = matchingShare.nestedArray;

      const payloadArray = shareArr.map((share) => ({
        clientId: props?.clientInfo?.apid,
        shareId: item.id,
        shareStartDate:
          dayjs(share.startDate) < dayjs()
            ? dayjs().add(1, "month").format("MM/01/YYYY")
            : dayjs(share.startDate).format("MM/DD/YYYY"),
        shareEndDate: dayjs(share.endDate).format("MM/DD/YYYY"),
        paymentType: share.type == 1 ? "DAILY" : "MNTHLY",
        determinedAmount: share.cost,
        submittedBy: isLoggin ? "ADMIN" : "CLIENT",
      }));

      if (payloadArray && payloadArray.length > 0) {
        props.getSnapShare(payloadArray);
      }
    } else {
      console.error("Matching share not found");
    }
  };

  return (
    <Form id="snap-share" onSubmit={handleSubmit(handleButtonClick)}>
      {showModal && (
        <ModalComponent
          shown={showModal}
          close={() => {
            setShowModal(false);
            // reset();
          }}
          title={"Precalculation"}
          modalBody={renderModal}
          size={"lg"}
          label={"PRECALCULATION"}
        />
      )}
      <Grid
        item
        lg={12}
        md={12}
        ml={4}
        // mt={7.2}
        className="wrapper-gird"
        sx={{ border: "1px solid #cccccc", backgroundColor: "#FFFF" }}
      >
        <Box>
          {isLoading && <CommonLoading />}
          <Box
            sx={{
              borderBottom: "1px solid #CCCCCC",
            }}
          >
            <Grid container xs={12}>
              <Grid item xs={8}>
                <Typography variant="h5" className="sub-heading">
                  {transProps.SPFShare} <br />
                  <span style={{ fontSize: "17px", marginBottom: "30px" }}>
                    {transProps.howToUseThisPage}
                  </span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                pr={2}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "white !important",
                    lineHeight: "1",
                    textAlign: " center",
                    whiteSpace: "nowrap",
                    verticalAlign: "baseline",
                    backgroundColor: "#457425",
                    borderRadius: "3px",
                    padding: "0.25em 0.4em",
                    width: "82",
                    height: "10",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                >
                  <Link
                    style={{
                      fontFamily: "texta-bold",
                      color: "white",
                      fontSize: "19px",
                      fontWeight: "700",
                    }}
                    onClick={() => {
                      window.open(
                        "https://www.youtube.com/watch?v=TUfReS54oU8"
                      );
                    }}
                    target="_blank"
                  >
                    <i className="fa fa-play-circle" aria-hidden="true" />
                    &nbsp;{transProps.seeHowSPFworks}
                  </Link>{" "}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Row
            display={"flex"}
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.125)",
              borderTop: "none",
              height: "auto",
            }}
          >
            <Grid>
              <Grid sx={{ padding: "5px" }}>
                <TableCell
                  sx={{
                    // backgroundColor: "#EEEEEE",
                    fontSize: "20px",
                    borderRadius: "3px",
                    margin: "0px 0px 8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Texta-book",
                      fontSize: "21px",
                      fontWeight: "400",
                      lineHeight: "1.5",
                      color: "#212529",
                      textAlign: "left",
                    }}
                  >
                    {transProps.Pick}{" "}
                    <a className="alink" href="#">
                      {transProps.SPF}
                    </a>
                    {transProps.shareBelow}{" "}
                    <a className="alink" href="#" onClick={hipContent}>
                      {transProps.SPF}
                    </a>
                    {transProps.benifites}{" "}
                    <a className="alink" href="#" onClick={openSweetAlert}>
                      {transProps.requestHelp}
                    </a>{" "}
                    {transProps.ifTheFarm}{" "}
                    <a className="alink" href="#" onClick={Estimated}>
                      {transProps.estimated}
                    </a>
                  </Typography>

                  {showThree && (
                    <Box
                      sx={{
                        border: "1px",
                        borderColor: "green",
                        backgroundColor: "#d4edda",
                        transition: "transform 1s",
                        marginTop: "20px",
                        padding: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontFamily: "Texta-book",
                          color: "#155724",
                        }}
                      >
                        <Grid sx={{ display: "flex", flexDirection: "row" }}>
                          <Grid sx={{ width: "95%" }}>
                            {" "}
                            {transProps.SPFPaymentDates}
                          </Grid>
                          <Grid sx={{ marginLeft: "10px" }}>
                            <CloseIcon
                              onClick={() => setShowThree(!showThree)}
                            />
                          </Grid>
                        </Grid>
                      </Typography>
                    </Box>
                  )}

                  {showOne && (
                    <Box
                      sx={{
                        border: "1px",
                        borderColor: "green",
                        backgroundColor: "#d4edda",
                        transition: "transform 1s",
                        marginTop: "20px",
                        padding: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontFamily: "Texta-book",
                          color: "#155724",
                        }}
                      >
                        <Grid sx={{ display: "flex", flexDirection: "row" }}>
                          <Grid sx={{ width: "95%" }}>
                            {" "}
                            {transProps.SPFPilot}
                          </Grid>
                          <Grid sx={{ marginLeft: "10px" }}>
                            <CloseIcon onClick={() => setShowOne(!showOne)} />
                          </Grid>
                        </Grid>
                      </Typography>
                    </Box>
                  )}

                  {showTwo && (
                    <Box
                      sx={{
                        border: "1px",
                        borderColor: "green",
                        backgroundColor: "#d4edda",
                        transition: "transform 1s",
                        marginTop: "20px",
                        padding: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontFamily: "Texta-book",
                          color: "#155724",
                        }}
                      >
                        <Grid sx={{ display: "flex", flexDirection: "row" }}>
                          <Grid sx={{ width: "95%" }}>
                            {" "}
                            {transProps.SPFAmount}
                          </Grid>
                          <Grid sx={{ marginLeft: "10px" }}>
                            <CloseIcon onClick={() => setShowTwo(!showTwo)} />
                          </Grid>
                        </Grid>
                      </Typography>
                    </Box>
                  )}
                </TableCell>
              </Grid>
            </Grid>
            <Row>
              {/* <Col item xs={12} md={4}>
                <p className="text-center benefit_overlay">
                  Your SNAP Monthly Amount = $
                  {twoDigitdecNum(clientInfo?.snapAmount)}
                </p>
              </Col> */}
              {props?.farmInfo?.farmDetails?.isHIPVendor === "Y" && (
                <Col item xs={12} md={4}>
                  <p className="text-center benefit_overlay">
                    {transProps.SPFMonthlyCap} ={" "}
                    <span>${twoDigitdecNum(clientInfo?.hipAmount)}</span>
                  </p>
                </Col>
              )}
              {isLoggin && (
                <Col item xs={12} md={4}>
                  <p className="text-center benefit_overlay">
                    {transProps.EBTBalance} = $
                    {twoDigitdecNum(clientInfo?.ebtCardBalance)}
                  </p>
                </Col>
              )}
            </Row>

            <Grid>
              {/* {isError && (<Typography component="span" className='dh-txt-danger' sx={{ fontSize: '18px', marginLeft: '18px' }}><i className="fa fa-info-circle"></i> Please choose the CSA share you would like to purchase</Typography>)} */}
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Texta-bold",
                  marginLeft: "18px",
                }}
              >
                {transProps.pleaseCheck}
              </Typography>
              <Grid sx={{ marginBottom: "20px" }} item xs={12} sm={12} md={12}>
                {data &&
                  data.map((item, i) => (
                    <Grid key={i} sx={{ padding: "3px", marginLeft: "30px" }}>
                      <FormControlLabel
                        sx={{
                          fontSize: "30px",
                          fontFamily: "Texta-bold",
                          color: "#457425",
                          lineHeight: 1.3,
                          fontWeight: "bold",
                        }}
                        control={
                          <Checkbox
                            disabled={
                              !loginId && item?.shareStatus === "SLDOUT"
                            }
                            onClick={(e) => {
                              setIsError(false);
                              if (!isLoggin) {
                                handleCheckClick(item);
                              }
                              if (!e.target.checked) {
                                if (getValues()) {
                                  const data = getValues()["share"].map(
                                    (share) => {
                                      if (item.id == share.id) {
                                        share.nestedArray = [
                                          {
                                            nam: item?.nam,
                                            dsc: item?.dsc,
                                            shareId: item?.id,
                                            cost: item?.shareCost,
                                            type: "2",
                                            startDate:
                                              dayjs(item?.startDate) < dayjs()
                                                ? dayjs()
                                                    .add(1, "month")
                                                    .format("MM/01/YYYY")
                                                : dayjs(item?.startDate).format(
                                                    "MM/DD/YYYY"
                                                  ),
                                            endDate:
                                              dayjs(item?.endDate) < dayjs()
                                                ? dayjs().format("MM/01/YYYY")
                                                : dayjs(item?.endDate).format(
                                                    "MM/DD/YYYY"
                                                  ),
                                          },
                                        ];
                                      }
                                      return share;
                                    }
                                  );
                                  const defaultValues = {
                                    share: data,
                                  };
                                  reset(defaultValues);
                                }
                              } else {
                                // dayjs(item?.startDate) < dayjs() ? dayjs().add(1, 'month').format("MM/DD/YYYY")
                                if (item) {
                                  const data = {
                                    id: item?.id,
                                    shareCost: item?.shareCost,
                                    startDate: item?.startDate,
                                    endDate: item?.endDate,
                                    type: 2,
                                  };
                                  handleCheckClick(data);
                                }
                              }
                              setShowContent((prevState) => ({
                                ...prevState,
                                [item.id]: e.target.checked,
                              }));
                              callPreCal({ id: item.id });
                            }}
                          />
                        }
                        label={
                          <div className="heading-text">
                            {"$" +
                              twoDigitdecNum(item.shareCost) +
                              "-" +
                              getShareName(item) +
                              " "}
                            {item?.shareStatus === "SLDOUT" ? (
                              <span className="badge badge-danger-snap">
                                {transProps.soldOut}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        }
                        checked={showContent[item.id] === true}
                      />
                      <Typography className="heading-sub-text">
                        {getShareDsc(item)}
                      </Typography>
                      {isLoggin && showContent[item.id] && (
                        <div>
                          <ShareNestedFieldArray
                            nestIndex={i}
                            shareInfo={item}
                            setPreCalc={(preCalc) => {
                              // console.log(preCalc);
                              if (preCalc) {
                                const data = {
                                  id: preCalc?.shareId,
                                  shareCost: preCalc?.cost,
                                  startDate: preCalc?.startDate,
                                  endDate: preCalc?.endDate,
                                  type: preCalc?.type,
                                };
                                handleCheckClick(data);
                                calcMnthlyCost();
                              }
                            }}
                            triggerPrecal={(item) => callPreCal(item)}
                            setIsShareRowEdit={setIsShareRowEdit}
                            {...{
                              control,
                              register,
                              setValue,
                              getValues,
                              watch,
                              errors,
                            }}
                          />

                          <CustomButton
                            variant="contained"
                            sx={{ mt: 2 }}
                            onClick={() => showPrecalculationDetails(item)}
                          >
                            {transProps.showPrecalculation}
                          </CustomButton>
                        </div>
                      )}

                      {showContent[item.id] && !isLoggin && (
                        <Box
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            width: "788px",
                            padding: "3px",
                            marginLeft: "-10px",
                          }}
                        >
                          <Grid
                            sx={{
                              borderRadius: "5px",
                              backgroundColor: "#DBE8DF",
                              fontSize: "20px",
                              fontFamily: "Texta-bold",
                              marginLeft: "10px",
                              padding: "18px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "20px",
                                fontFamily: "Texta-bold",
                              }}
                            >
                              {transProps.shareDetails}
                            </Typography>
                            <br />
                            <Typography
                              sx={{
                                fontSize: "20px",
                                fontFamily: "Texta-book",
                              }}
                            >
                              {transProps.SPFStartMonth}:{" "}
                              {formatemonth(
                                sharePrecal[item.id]?.[0]?.paymentInfo[0]
                                  ?.paymentDate
                              )}{" "}
                              | {transProps.SPFEndMonth} :{" "}
                              {formatemonth(
                                sharePrecal[item.id]?.[0]?.paymentInfo[
                                  sharePrecal[item.id]?.[0]?.paymentInfo
                                    .length - 1
                                ]?.paymentDate
                              )}{" "}
                              | {transProps.monthlyShareCost}: ${" "}
                              {
                                sharePrecal[item.id]?.[0]?.paymentInfo[0]
                                  ?.paymentAmount
                              }
                            </Typography>
                            <br />
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "20px",
                                fontFamily: "Texta-bold",
                              }}
                            >
                              {" "}
                              {transProps.SPFPaymentSchedule}{" "}
                            </Typography>{" "}
                            <br />
                            <Table
                              sx={{ border: "1px solid", maxWidth: "50%" }}
                            >
                              <TableRow
                                sx={{
                                  border: "1px 0px  2px 0px solid #535954",
                                  fontSize: "20px !important",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    border: "1px  solid",
                                    fontFamily: "Texta-book",
                                    fontSize: "19px !important",
                                    fontWeight: "bold",
                                    lineHeight: "1.5px",
                                  }}
                                >
                                  {transProps.paymentDate}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    border: "1px  solid",
                                    fontSize: "19px !important",
                                    fontFamily: "Texta-book",
                                    fontWeight: "bold",
                                    lineHeight: "1.5px",
                                  }}
                                >
                                  {transProps.paymentAmount}
                                </TableCell>
                              </TableRow>
                              {sharePrecal[item.id]?.[0]?.paymentInfo.map(
                                (item, i) => (
                                  <TableRow
                                    key={i}
                                    sx={{
                                      border: "1px solid",
                                      fontSize: "20px",
                                      fontFamily: "Texta-book",
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        border: "1px solid",
                                        fontFamily: "Texta-book",
                                        fontSize: "19px !important",
                                        lineHeight: "1.5px",
                                      }}
                                    >
                                      {item?.paymentDate}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        border: "1px solid",
                                        fontFamily: "Texta-book",
                                        fontSize: "19px !important",
                                        lineHeight: "1.5px",
                                      }}
                                    >
                                      ${twoDigitdecNum(item?.paymentAmount)}
                                      {i === 0 && (
                                        <div className="tooltip">
                                          <HelpIcon className="green-color cursor-pointer" />
                                          <span className="tooltiptext">
                                            {transProps.paymentShedule}
                                          </span>
                                        </div>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                              <TableRow
                                sx={{
                                  border: "2px 1px 1px 1px solid",
                                  fontSize: "20px",
                                  fontFamily: "Texta-book",
                                  lineHeight: "1.5px",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    border: "1px solid",
                                    fontFamily: "Texta-book",
                                    fontSize: "19px !important",
                                    fontWeight: "bold",
                                    lineHeight: "1.5px",
                                  }}
                                >
                                  {transProps.overallCost}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    border: "1px  solid",
                                    fontFamily: "Texta-book",
                                    fontSize: "19px !important",
                                  }}
                                >
                                  {" "}
                                  $
                                  {twoDigitdecNum(
                                    sharePrecal[item.id]?.[0]?.totalAmount
                                  )}
                                </TableCell>
                              </TableRow>
                            </Table>
                          </Grid>
                        </Box>
                      )}
                    </Grid>
                  ))}
              </Grid>
              {isError && (
                <span
                  className="dh-txt-danger"
                  style={{ fontSize: "20px", marginLeft: "18px" }}
                >
                  {" "}
                  <i className="fa fa-info-circle"></i>{" "}
                  {transProps.pleaseChoose}
                </span>
              )}
            </Grid>
            {shwoMnthlyTxt ? (
              <TableRow>
                <TableCell sx={{ borderStyle: "none", width: "900px" }}>
                  <Grid
                    p={2}
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#DBE8DF",
                      fontSize: "20px",
                      fontFamily: "Texta-bold",
                      marginTop: "-20px",
                    }}
                  >
                    <Typography className="snap-title">
                      {transProps.upcomingMonthly}
                    </Typography>
                    <Divider
                      sx={{
                        borderWidth: 1,
                        backgroundColor: "#457425",
                        width: "70px",
                      }}
                    />
                    <Grid sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography
                        sx={{
                          fontFamily: "Texta-book",
                          fontSize: "20px",
                          fontWeight: 400,
                          lineHeight: "1.5",
                        }}
                      >
                        {props?.farmInfo?.farmDetails?.isHIPVendor === "N"
                          ? transProps.payWithSPF
                          : transProps.payWithSPF}
                      </Typography>
                      <Typography className="snap-info">
                        &nbsp;${twoDigitdecNum(mnthlyCost)}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
            {shwoMnthlyTxt &&
            props?.farmInfo?.farmDetails?.isHIPVendor === "Y" ? (
              <TableRow>
                <TableCell sx={{ borderStyle: "none", width: "900px" }}>
                  <Grid
                    p={2}
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#DBE8DF",
                      fontSize: "20px",
                      fontFamily: "Texta-bold",
                      marginTop: "-20px",
                    }}
                  >
                    <Typography className="snap-title">
                      {transProps.amountSPF} ={" "}
                      <span className="snap-info">
                        $
                        {twoDigitdecNum(mnthlyCost) >
                        twoDigitdecNum(clientInfo?.hipAmount)
                          ? twoDigitdecNum(clientInfo?.hipAmount)
                          : twoDigitdecNum(mnthlyCost)}{" "}
                        <div className="tooltip">
                          <HelpIcon className="green-color cursor-pointer" />
                          <span className="tooltiptext">
                            {transProps.SPFwill}
                          </span>
                        </div>
                      </span>
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
          </Row>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              // border: "1px solid rgba(0, 0, 0, 0.125)",
              borderTop: "none",
              backgroundColor: "#FFFF",
              height: "80px",
            }}
          >
            <TableCell>
              <Grid>
                <Button
                  onClick={() => {
                    props.setActiveTab("menuStep2");
                    submitShareForm(getValues(), false);
                    props.setVendorChange(false);
                  }}
                  sx={{
                    backgroundColor: "#ffffff",
                    color: "#457425",
                    height: "50px",
                    width: "100px",
                    borderColor: "#457425",
                    border: "solid",
                    borderWidth: "1px",
                    "&:hover": {
                      backgroundColor: "#457425",
                      color: "#ffffff",
                    },
                    "&:active": {
                      backgroundColor: "#457425",
                      color: "#ffffff",
                    },
                  }}
                >
                  <ArrowBackIosNewIcon /> {transProps.prev}
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#457425 !important",
                    color: "white",
                    height: "50px",
                    width: "100px",
                    marginLeft: "10px",
                  }}
                  type="submit"
                  value="Submit"
                >
                  {" "}
                  {transProps.next} <ArrowForwardIosIcon />
                </Button>
              </Grid>
            </TableCell>
          </Grid>
        </Box>
      </Grid>
    </Form>
  );
}
SnapShareComponent.propTypes = {
  setActiveTab: PropTypes.func,
  getClientInfo: PropTypes.func.isRequired,
  getCSAShareList: PropTypes.func.isRequired,
  getClientAgrmntShrLst: PropTypes.func.isRequired,
  getSnapShare: PropTypes.func,
  csaSignup: PropTypes.object.isRequired,
  activeMenu: PropTypes.object,
  handleUpdate: PropTypes.func,
  setSelectedShares: PropTypes.object,
  clientInfo: PropTypes.func,
  getRequestHelp: PropTypes.func,
  farmInfo: PropTypes.object,
  setCSAFormValidate: PropTypes.func.isRequired,
  shareDetails: PropTypes.object,
  selectedShare: PropTypes.object,
  saveCSASignup: PropTypes.func,
  setSharePrecal: PropTypes.object,
  sharePrecal: PropTypes.object,
  vendorChange: PropTypes.bool,
  setVendorChange: PropTypes.object,
  setIsShareRowEdit: PropTypes.object,
  userAuth: PropTypes.object.isRequired,
  setAllSharePrecal: PropTypes.func,
};
function mapDispatchToProps(dispatch) {
  return {
    getCSAShareList: (sharePayload) =>
      dispatch(actionType.getCSAShareList(sharePayload)),
    getClientAgrmntShrLst: (payload) =>
      dispatch(actionType.getClientAgrmntShrLst(payload)),
    getSnapShare: (Payload) => dispatch(actionType.getSnapShare(Payload)),
    getRequestHelp: (Payload) => dispatch(actionType.getRequestHelp(Payload)),
    getClientInfo: (payload) => dispatch(actionType.getClientInfo(payload)),
    saveCSASignup: (csaSignupForm) =>
      dispatch(actionType.saveCSASignup(csaSignupForm)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SnapShareComponent);
