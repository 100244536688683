import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useLoader from "../../../../hooks/useLoader";
import toast from "react-hot-toast";
import { formerPayload, replyToFarmerPayload } from "./data";
import PropTypes from "prop-types";
import axiosInstance from "../../../../redux/interceptor";
import { AUTH_API } from "../../../../config";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import CloseIcon from "@mui/icons-material/Close";

const ReplyToFarmer = ({
  replyDialogOpen,
  setReplyDialogOpen,
  replyDataFromFarmer,
  getSubmittedAlert,
  vendorId,
}) => {
  const { openLoader, closeLoader } = useLoader();

  const { handleSubmit, control, reset } = useForm({});

  const handleModalClose = () => {
    setReplyDialogOpen(false);
    reset();
  };

  const onSubmit = async (data) => {
    const btnType = window.event.submitter.name;
    const url = `${AUTH_API}/createOrUpdateAlert`;
    try {
      openLoader("alert");
      const payload = replyToFarmerPayload(
        data,
        replyDataFromFarmer,
        vendorId,
        btnType
      );
      const res = await axiosInstance.post(url, payload);
      if (res.status === 200) {
        getSubmittedAlert();
        toast.success("Alert Action Successful!");
      }
    } catch (error) {
      toast.error("Filed to Submit!");
      console.log(error);
    } finally {
      closeLoader("alert");
      setTimeout(() => {
        handleModalClose();
      }, [2000]);
    }
  };

  return (
    <>
      <Dialog
        open={replyDialogOpen}
        maxWidth="sm"
        fullWidth
        scroll="paper"
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "22px", fontWeight: "600" }}
        >
          Alert
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleModalClose}
          sx={{
            position: "absolute !important",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Stack direction={"column"} gap={"20px"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Grid item xs={3}>
                      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                        Alert From Farmer
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        name="replyFromFormer"
                        control={control}
                        defaultValue={
                          replyDataFromFarmer?.recipientComment ?? ""
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            multiline
                            rows={5}
                            fullWidth
                            sx={{ width: "25rem" }}
                            readOnly={true}
                            disabled={true}
                          />
                        )}
                      />
                    </Grid>
                  </Stack>

                  <Stack direction={"row"} alignItems={"center"}>
                    <Grid item xs={3}>
                      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                        Reply Alert
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        name="replyMessageToFormer"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Write Return message here"
                            multiline
                            rows={5}
                            fullWidth
                            sx={{ width: "25rem" }}
                          />
                        )}
                      />
                    </Grid>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-end !important"}
                    ml={2}
                    spacing={1}
                  >
                    {/* <div style={{ marginLeft: "20px" }}> */}
                    <Button
                      autoFocus
                      color="success"
                      variant="contained"
                      type="submit"
                      name="AP"
                    >
                      Approved
                    </Button>
                    <Button
                      autoFocus
                      color="error"
                      variant="contained"
                      name="DE"
                      type="submit"
                    >
                      Denied
                    </Button>
                    <Button
                      autoFocus
                      //   color="Primary"
                      variant="contained"
                      name="RD"
                      type="submit"
                    >
                      Reply
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
ReplyToFarmer.propTypes = {
  vendorId: PropTypes.number,
  replyDialogOpen: PropTypes.bool,
  setReplyDialogOpen: PropTypes.bool,
  replyDataFromFarmer: PropTypes.object,
  getSubmittedAlert: PropTypes.func,
  alertTableData: PropTypes.array,
};
export default ReplyToFarmer;
