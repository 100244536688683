import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import Button from 'react-bootstrap/Button';
import {Row, Col} from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { sendSupportEmail } from "../../redux/actionTypes/registration";
import DateTimePicker from "../common/components/dateTimePicker";

import "../../styles/help.scss";
import { maskMobile } from "../../utils/globalMethods";
import { FormControl, MenuItem, Select } from "@mui/material";
import ModalComponent from "../common/components/modal";
import SelectDropdown from "../../components/SelectDropdown";
import { GET_VENDOR_LIST } from "../../redux/actionTypes";
import useTranslator from "../../hooks/useTranslator";

const HelpComponent = (props) => {
  const transProps = useTranslator(
    [
      "needAdditionalHelp",
"forInformationRegardingYourKFCase",
"massLink",
"callKFreshAssistance",
"speakWith",
"helpTitle",
"helpText1",
"helpText2",
"helpText3",
"helpText4",
"govLink",
"helpNote",
"userContactInfo",
"firstName",
"phoneNumber",
"emailAddress",
"lastName",
"pleaseProvide",
"deviceInfo",
"device",
"iPhone",
"iPad",
"iOSLaptopDesktop",
"androidPhone",
"androidTablet",
"pc",
"idonKnow",
"browser",
"chrome",
"edge",
"firefox",
"internetExplorer",
"safari",
"description",
"maxCharacter",
"submit",
"vendorInfo",
"vendorName",
"dateAndTime",
"pleaseProvideLocation",
"chooseCategory",
"select",
"howtoUseMap",
"howtoUseSPF",
"reportaBug",
"reportInaccurateInformation",
"questionAboutKFreshCase",
"helpforSPFvendors",
"other",
"help",
      
    ],
    "helpComponent"
  );
  const { shown, close, language, sendSupportEmail } = props;
  const selectStyle = {
    fontWeight: "800",
    fontSize: "1rem",
    fontFamily: "inherit !important",
  };

  const [category, setCategory] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState("");
  const [dateOfVisit, setDateOfVisit] = useState(new Date().getTime());

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    if (Object.keys(errors).length === 0) {
      sendSupportEmail(data);
      close();
    }
  };

  const mapVendorList = (vendors) => {
    if (vendors.length) {
      let vendorOptions = vendors.map((item) => {
        return {
          id: item.vendorId,
          name: item.vendorName,
          values: item.vendorName,
          isActive: 1,
        };
      });
      setVendorList(vendorOptions);
    }
  };

  useEffect(() => {
    props.getVendorList({
      cityName: "",
      miles: "",
      lat: "",
      lon: "",
      allCityFlag: "Y",
    });
  }, []);

  useEffect(() => {
    mapVendorList(props.mapdata.vendor);
  }, [category]);

  const renderCategory = (category) => {
    setCategory(category);
    setSelectedVendors("");
    setDateOfVisit("");
    reset();
  };
  const handleKeyPress = (event, category) => {
    console.log(event.key);
    if (event.key === "Enter") {
      setCategory(category);
    }
  };

  const HowToUseTheMap = () => {
    return (
      <>
        <div className="video-responsive col-md-12">
          <iframe
            width="100%"
            height="400"
            id="useMapVideo"
            src="https://www.youtube.com/embed/KlaPKfIqt7Q"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <Form.Text
            tabIndex={0}
            onKeyPress={(event) => handleKeyPress(event, "Other")}
            muted
            onClick={() => {
              renderCategory("Other");
            }}
            className="cursor-pointer"
          >
            {transProps.needAdditionalHelp}
          </Form.Text>
        </div>
      </>
    );
  };

  const QuestionAboutDTACase = () => {
    return (
      <p className="font-light14">
        {transProps.forInformationRegardingYourKFCase}{" "}
        <a
          href="https://dtaconnect.eohhs.mass.gov/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          {transProps.massLink}
        </a>{" "}
        {transProps.callKFreshAssistance}{" "}
        <b>
          <a href="tel:(877)382-2363"> 877-382-2363 </a>
        </b>{" "}
        {transProps.speakWith}
      </p>
    );
  };

  const HowToUseHIP = () => {
    return (
      <>
        <p className="font-light14">{transProps.helpTitle}</p>
        <ListGroup as="ul" className="howtousehip font-light14">
          <ListGroup.Item as="li">{transProps.helpText1}</ListGroup.Item>
          <ListGroup.Item as="li">{transProps.helpText2}</ListGroup.Item>
          <ListGroup.Item as="li">{transProps.helpText3}</ListGroup.Item>
          <ListGroup.Item as="li">
            {transProps.helpText4}{" "}
            <a
              href="http://mass.gov/hip"
              target="_blank"
              rel="noopener noreferrer"
            >
              {transProps.govLink}
            </a>
          </ListGroup.Item>
        </ListGroup>
        <Form.Group>
          <Form.Text muted>
            <a
              href="http://mass.gov/snap"
              target="_blank"
              rel="noopener noreferrer"
            >
              {transProps.helpNote}
            </a>
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Text
            muted
            tabIndex={0}
            onKeyPress={(event) => handleKeyPress(event, "Other")}
            onClick={() => {
              renderCategory("Other");
            }}
            className="cursor-pointer addtional-help"
          >
            {transProps.needAdditionalHelp}
          </Form.Text>
        </Form.Group>
      </>
    );
  };

  const UserContactInfo = () => {
    const [mobile, setMobile] = useState("");

    return (
      <Card className="mb-3">
        <Card.Body>
          <Card.Title className="resText font-medium16">
            {transProps.userContactInfo}
          </Card.Title>
          <Row>
            <Col md={4} sm={6}>
              <Form.Group as={Col} controlId="formGridFirstName">
                <Form.Label className="font-medium14">
                  {transProps.firstName}
                </Form.Label>
                <Form.Control
                  className="font-light14"
                  type="text"
                  {...register("firstName")}
                />
              </Form.Group>
            </Col>
            <Col md={4} sm={6}>
              <Form.Group as={Col} controlId="formGridLastName">
                <Form.Label className="font-medium14">
                  {transProps.lastName}
                </Form.Label>
                <Form.Control
                  className="font-light14"
                  type="text"
                  {...register("lastName")}
                />
              </Form.Group>
            </Col>
            <Col md={4} sm={6}>
              <Form.Group as={Col} controlId="formPhone">
                <Form.Label className="font-medium14">
                  {transProps.phoneNumber}
                </Form.Label>
                <Form.Control
                  className="font-light14"
                  type="tel"
                  size="lg"
                  value={mobile}
                  {...register("phone", {
                    pattern: {
                      value:
                        /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                      message: "Invalid phone number",
                    },
                  })}
                  onChange={(e) => setMobile(maskMobile(e.target.value))}
                  placeholder="(___) ___-____"
                />
              </Form.Group>
            </Col>
            <Col md={4} sm={6}>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className="font-medium14">
                  {transProps.emailAddress}
                </Form.Label>
                <Form.Control
                  className="font-light14 lowercase"
                  type="text"
                  {...register("email", {
                    required: false,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Invalid email format",
                    },
                  })}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const ReportABug = () => {
    return (
      <>
        <Col className="text-muted font-light15">
          {transProps.pleaseProvide}
        </Col>
        <Card className="mb-3">
          <Card.Body>
            <Card.Title className="resText font-medium16">
              {transProps.deviceInfo}
            </Card.Title>
            <Card.Text>
              <Row>
                <Col md={4}>
                  <Form.Group as={Col} controlId="formGridDevice">
                    <Form.Label className="font-medium14">
                      {transProps.device} *
                    </Form.Label>
                    <FormControl fullWidth>
                      <Select
                        {...register("device", {
                          required: "Please select Device",
                        })}
                        labelId="demo-simple-select-label-Device"
                        id="demo-simple-select-Device"
                        style={{ background: "#fff" }}
                        value={watch("device") || ""}
                        className="font-light14"
                        displayEmpty
                        onChange={(e) => {
                          setValue("device", e.target.value);
                          clearErrors("device");
                        }}
                      >
                        <MenuItem value="" disabled hidden>
                          -- {transProps.select} --
                        </MenuItem>
                        <MenuItem value="iPhone">{transProps.iPhone}</MenuItem>
                        <MenuItem value="iPad">{transProps.iPad}</MenuItem>
                        <MenuItem value="iOS laptop or desktop">
                          {transProps.iOSLaptopDesktop}
                        </MenuItem>
                        <MenuItem value="Android phone">
                          {transProps.androidPhone}
                        </MenuItem>
                        <MenuItem value="Android tablet">
                          {transProps.androidTablet}
                        </MenuItem>
                        <MenuItem value="PC">{transProps.pc}</MenuItem>
                        <MenuItem value="I don`t know">
                          {transProps.idonKnow}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridBrowser">
                    <Form.Label className="font-medium14">
                      {transProps.browser} *
                    </Form.Label>
                    <FormControl fullWidth>
                      <Select
                        {...register("browser", {
                          required: "Please select Browser",
                        })}
                        labelId="demo-simple-select-label-browser"
                        id="demo-simple-select-browser"
                        style={{ background: "#fff" }}
                        value={watch("browser") || ""}
                        className="font-light14"
                        displayEmpty
                        onChange={(e) => {
                          setValue("browser", e.target.value);
                          clearErrors("browser");
                        }}
                      >
                        <MenuItem value="" disabled hidden>
                          -- {transProps.select} --
                        </MenuItem>
                        <MenuItem value="Chrome">{transProps.chrome}</MenuItem>
                        <MenuItem value="Edge">{transProps.edge}</MenuItem>
                        <MenuItem value="Firefox">
                          {transProps.firefox}
                        </MenuItem>
                        <MenuItem value="Internet Explorer">
                          {transProps.internetExplorer}
                        </MenuItem>
                        <MenuItem value="Safari">{transProps.safari}</MenuItem>
                        <MenuItem value="I don`t know">
                          {transProps.idonKnow}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Form.Group>
                </Col>
                <Col md={8}>
                  <Form.Group as={Col} controlId="formDescription">
                    <Form.Label className="font-medium14">
                      {transProps.description} *
                    </Form.Label>
                    <Form.Control
                      className="font-light14"
                      as="textarea"
                      placeholder=""
                      style={{ height: "114px" }}
                      maxLength="500"
                      {...register("description", {
                        required: "Description is required",
                        maxLength: 500,
                      })}
                    />
                    <Form.Text
                      id="formDescription"
                      className="float-right font-light13"
                      muted
                    >
                      <i
                        className="fa fa fa-info-circle ml-2"
                        aria-hidden="true"
                      ></i>{" "}
                      {transProps.maxCharacter}: 500
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
        <UserContactInfo />
        <Form.Control
          type="text"
          value={"Bug"}
          hidden
          {...register("type", { required: true })}
        />
        <Button variant="primary" type="submit" className="float-right">
          {transProps.submit}
        </Button>
      </>
    );
  };

  const ReportInaccurateInformation = ({
    vendorList,
    selectedVendors,
    setSelectedVendors,
  }) => {
    return (
      <>
        <Col className="text-muted font-light15">
          {transProps.pleaseProvide}
        </Col>
        <Card className="mb-3">
          <Card.Body>
            <Card.Title className="resText font-medium16">
              {transProps.vendorInfo}
            </Card.Title>
            <Card.Text>
              <Row>
                <Col md={4}>
                  <Form.Group as={Col} controlId="formGridDevice">
                    <Form.Label className="font-medium14">
                      {transProps.vendorName} *
                    </Form.Label>
                    <SelectDropdown
                      label="Vendor Name"
                      {...register("vendorName", {
                        required: "Please select Vendor",
                      })}
                      defaultValue={selectedVendors}
                      onChange={(v) => {
                        setSelectedVendors(v);
                        setValue(`vendorName`, v.name);
                        clearErrors("vendorName");
                      }}
                      dataList={vendorList}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridBrowser">
                    <Form.Label className="font-medium14 mt-2">
                      {transProps.dateAndTime} *
                    </Form.Label>
                    <DateTimePicker
                      // className="font-light14"
                      handleChange={(newValue) => {
                        setDateOfVisit(newValue.unix() * 1000);
                        setValue(`date`, newValue.format("MM/DD/YYYY hh:mm a"));
                      }}
                      value={dateOfVisit}
                      mode={"help"}
                      {...register("date")}
                      maxDate={new Date().getTime()}
                    />
                  </Form.Group>
                </Col>
                <Col md={8}>
                  <Form.Group as={Col} controlId="formDescription">
                    <Form.Label className="font-medium14">
                      {transProps.pleaseProvideLocation} *
                    </Form.Label>
                    <Form.Control
                      className="font-light14"
                      as="textarea"
                      placeholder=""
                      style={{ height: "114px" }}
                      maxlength="500"
                      {...register("description", {
                        required: "Description is required",
                        maxLength: 500,
                      })}
                    />
                    <Form.Text
                      id="formDescription"
                      className="float-right font-light13"
                      muted
                    >
                      <i
                        className="fa fa fa-info-circle ml-2"
                        aria-hidden="true"
                      ></i>{" "}
                      {transProps.maxCharacter}: 500
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
        <UserContactInfo />
        <Form.Control
          type="text"
          value={"inaccurateinfo"}
          hidden
          {...register("type", { required: true })}
        />
        <Button variant="primary" type="submit" className="float-right">
          {transProps.submit}
        </Button>
      </>
    );
  };

  ReportInaccurateInformation.propTypes = {
    vendorList: PropTypes.array,
    selectedVendors: PropTypes.object,
    setSelectedVendors: PropTypes.func,
  };

  const HelpForHIPVendors = ({
    vendorList,
    selectedVendors,
    setSelectedVendors,
  }) => {
    return (
      <>
        <Col className="text-muted font-light15">
          {transProps.pleaseProvide}
        </Col>
        <Card className="mb-3">
          <Card.Body>
            <Card.Title className="resText font-medium16">
              {transProps.vendorInfo}
            </Card.Title>
            <Card.Text>
              <Row>
                <Col md={4}>
                  <Form.Group as={Col} controlId="formGridDevice">
                    <Form.Label className="font-medium14">
                      {transProps.vendorName} *
                    </Form.Label>
                    <SelectDropdown
                      label="Vendor Name"
                      {...register("vendorName", {
                        required: "Please select Vendor",
                      })}
                      defaultValue={selectedVendors}
                      onChange={(v) => {
                        setSelectedVendors(v);
                        setValue(`vendorName`, v.name);
                        clearErrors("vendorName");
                      }}
                      dataList={vendorList}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group as={Col} controlId="formDescription">
                    <Form.Label className="font-medium14">
                      {transProps.description} *
                    </Form.Label>
                    <Form.Control
                      className="font-light14"
                      as="textarea"
                      placeholder=""
                      style={{ height: "114px" }}
                      maxlength="500"
                      {...register("description", {
                        required: "Description is required",
                        maxLength: 500,
                      })}
                    />
                    <Form.Text
                      id="formDescription"
                      className="float-right font-light13"
                      muted
                    >
                      <i
                        className="fa fa fa-info-circle ml-2"
                        aria-hidden="true"
                      ></i>{" "}
                      {transProps.maxCharacter}: 500
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
        <UserContactInfo />
        <Form.Control
          type="text"
          value={"helpvendors"}
          hidden
          {...register("type", { required: true })}
        />
        <Button variant="primary" type="submit" className="float-right">
          {transProps.submit}
        </Button>
      </>
    );
  };

  HelpForHIPVendors.propTypes = {
    vendorList: PropTypes.array,
    selectedVendors: PropTypes.object,
    setSelectedVendors: PropTypes.func,
  };

  const Other = () => {
    return (
      <>
        <Col className="text-muted font-light15">
          {transProps.pleaseProvide}
        </Col>
        <Card className="mb-3">
          <Card.Body>
            <Card.Text>
              <Row>
                <Col md={12}>
                  <Form.Group as={Col} controlId="formDescription">
                    <Form.Label className="font-medium14">
                      {transProps.description} *
                    </Form.Label>
                    <Form.Control
                      className="font-light14"
                      as="textarea"
                      placeholder=""
                      style={{ height: "114px" }}
                      maxlength="500"
                      {...register("description", {
                        required: "Description is required",
                        maxLength: 500,
                      })}
                    />
                    <Form.Text
                      id="formDescription"
                      className="float-right font-light13"
                      muted
                    >
                      <i
                        className="fa fa fa-info-circle ml-2"
                        aria-hidden="true"
                      ></i>{" "}
                      {transProps.maxCharacter}: 500
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
        <UserContactInfo />
        <Form.Control
          type="text"
          value={"Other"}
          hidden
          {...register("type", { required: true })}
        />
        <Button variant="primary" type="submit" className="float-right">
          {transProps.submit}
        </Button>
      </>
    );
  };

  const renderModal = () => {
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row} className="mb-3 select-category">
          <Form.Label column sm={4} className="font-semibold16">
            {transProps.chooseCategory} *{" "}
          </Form.Label>
          <Col sm={6}>
            <FormControl fullWidth>
              <Select
                sx={{ ...selectStyle }}
                displayEmpty
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                inputProps={{ "aria-label": "Choose Category" }}
                onChange={(e) => {
                  renderCategory(e.target.value);
                }}
              >
                <MenuItem value="" disabled="" selected="">
                  -- {transProps.select} --
                </MenuItem>
                <MenuItem value="How to use the map">
                  {transProps.howtoUseMap}
                </MenuItem>
                <MenuItem value="How to use HIP">
                  {transProps.howtoUseSPF}
                </MenuItem>
                <MenuItem value="Report a bug">
                  {transProps.reportaBug}
                </MenuItem>
                <MenuItem value="Report inaccurate information">
                  {transProps.reportInaccurateInformation}
                </MenuItem>
                <MenuItem value="Question about DTA case">
                  {transProps.questionAboutKFreshCase}
                </MenuItem>
                <MenuItem value="Help for HIP vendors">
                  {transProps.helpforSPFvendors}
                </MenuItem>
                <MenuItem value="Other">{transProps.other}</MenuItem>
              </Select>
            </FormControl>
          </Col>
        </Form.Group>
        {Object.keys(errors).length > 0 && (
          <span className="text-danger font-regular16">
            <i className="fa fa-exclamation-circle"></i>&nbsp;
            {errors[Object.keys(errors)[0]].message}
          </span>
        )}
        {category === "" && ""}
        {category === "How to use the map" && <HowToUseTheMap />}
        {category === "How to use HIP" && <HowToUseHIP />}
        {category === "Report a bug" && <ReportABug />}
        {category === "Question about DTA case" && <QuestionAboutDTACase />}
        {category === "Report inaccurate information" && (
          <ReportInaccurateInformation
            vendorList={vendorList}
            selectedVendors={selectedVendors}
            setSelectedVendors={setSelectedVendors}
          />
        )}
        {category === "Help for HIP vendors" && (
          <HelpForHIPVendors
            vendorList={vendorList}
            selectedVendors={selectedVendors}
            setSelectedVendors={setSelectedVendors}
          />
        )}
        {category === "Other" && <Other />}
      </Form>
    );
  };

  return (
    <ModalComponent
      shown={shown}
      close={() => close()}
      title={transProps.help}
      modalBody={renderModal}
      size={"lg"}
      id={"helpModal"}
      centered={true}
      label={transProps.help}
    />
  );
};

HelpComponent.propTypes = {
  shown: PropTypes.bool,
  close: PropTypes.func,
  language: PropTypes.object,
  vendors: PropTypes.array,
  mapdata: PropTypes.object,
  sendSupportEmail: PropTypes.func,
  getVendorList: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
        sendSupportEmail: (payload) => dispatch(sendSupportEmail(payload)),
        getVendorList: (payload) => {
            dispatch({ type: GET_VENDOR_LIST, payload:payload });
        },
      };
}

export default connect(mapStateToProps,mapDispatchToProps)(HelpComponent);
