import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const PartialSignupDetails = ({ row }) => {
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return "-";
    }

    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return phoneNumber;
  };

  return (
    <Grid container className="collapsibleTable" spacing={2} paddingBottom={2}>
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom component="div">
          Client`s Full Name
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.clientNam ? row.clientNam : "-"}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom component="div">
          Client Phone
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.phoneNumber ? formatPhoneNumber(row.phoneNumber) : "-"}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom component="div">
          Last Updated Time
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.lastUpdtTs ? row.lastUpdtTs : "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};

PartialSignupDetails.propTypes = {
  row: PropTypes.object.isRequired,
};

export default PartialSignupDetails;
