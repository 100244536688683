import React, { useEffect, useState } from "react";
import {
  Badge,
  Checkbox,
  Typography,
  Stack,
  Button,
  FormLabel,
  FormControlLabel,
  Box,
} from "@mui/material";
// import { Button } from "react-bootstrap";
import SignaturePad from "../../components/SignaturePad";
// import signatureImage from "../../../src/assets/images/signatureImage.png";
import "../../styles/certification.scss";
import { ReactComponent as FeatherIcon } from "../../../src/assets/images/feather.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AUTH_API, headers } from "../../config";
import axios from "axios";
import useTranslator from "../../hooks/useTranslator";
import { useFormik } from "formik";
import useLoader from "../../hooks/useLoader";
import toast from "react-hot-toast";
import { TextField, Select, MenuItem } from "@mui/material";
import { map } from "lodash";

const Certification = () => {
  const transProps = useTranslator(
    [
      "pageTitle",
      "list1",
      "list2",
      "accept",
      "signature",
      "goBack",
      "submit",
      "next",
    ],
    "certification"
  );

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const [openSignatureModal, setOpenSignatureModal] = useState(false);
  const [signatureButton, setSignatureButton] = useState(true);
  const [submitButton, setSubmitButton] = useState(true);
  const [sigImage, setSigImage] = useState();
  const [applicationID, setApplicationID] = useState("");
  const [response, setResponse] = useState("");
  const { openLoader, closeLoader } = useLoader();
  const [getSign, setGetSign] = useState("");
  const [elgStatus, setElgStatus] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const _appNumber = params?.appNumber ?? "";
  const _clientId = params?.clientId ?? "";

  const location = useLocation();
  const disabled = location?.state?.disabled ?? false;

  const get_signature = async () => {
    try {
      const res = await axios.post(
        AUTH_API + `/farmers/signatureInfo/${_appNumber}`,
        {},
        { headers: headers }
      );
      if (res.data) {
        setGetSign(res?.data?.signBytes);


        if (res.data?.signBytes) {
          setSubmitButton(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const get_eligibilityStatus = async () => {
    try {
      const res = await axios.post(
        AUTH_API + `/seniors/eligibilityDetails/getEligibility/${_appNumber}`,
        {},
        { headers: headers }
      );
      if (res) {
        setElgStatus(res?.data?.EligibilityStatusCd);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    get_signature();
    get_eligibilityStatus();
  }, []);

  const formik = useFormik({
    initialValues: {
      acceptCheckBox: getSign ? true : false,
      getSignature: false,
    },
    onSubmit: (values) => {
      // console.log(values);
    },
    enableReinitialize: true,
  });

  const submit = async () => {
    if (
      !disabled &&
      elgStatus !== "AP" &&
      elgStatus !== "DE" &&
      elgStatus !== "WL"
    ) {
      try {
        openLoader("runeligibility");
        const res = await axios.post(
          AUTH_API + `/seniors/eligibilityDetails/runEligibility/${_appNumber}`,
          {},
          { headers: headers }
        );
        if (res && res?.data) {
          handleNext();
          toast.success('Application form is submitted')
        }
      } catch (error) {
        setSubmitButton(false);
        toast.error("Application Submitting is failed");
      } finally {
        setSubmitButton(false);
        closeLoader("runeligibility");
      }
    } else {
      handleNext();
    }
  };

  const toggleSignature = async (signatureImage) => {
    if (signatureImage) {
      const payLoad = {
        dirtyStatus: getSign ? "UPDATE" : "INSERT",
        clientId: parseInt(_clientId),
        signatoryName: "",
        signSource: "Verified",
        signBytes: signatureImage,
        deleteInd: "N",
      };
      try {
        const headers = {
          "Content-Type": "application/json",
        };
        const res = await axios.post(
          AUTH_API + "/farmers/signature/save",
          payLoad,
          {
            headers: headers,
          }
        );
        if (res && res?.data) {
          formik.setFieldValue("acceptCheckBox", true);
          formik.setFieldValue("getSignature", true);
          get_signature();
          toast.success("Signature added successfully");
        }
      } catch (error) {
        console.log(error);
        toast.error("Adding signature failed");
      } finally {
        setOpenSignatureModal(false);
        setSubmitButton(!submitButton);
      }
    }
  };

  const handleGoBack = () => {
    if (_appNumber && _clientId) {
      navigate(`/programIncome/${_appNumber}/${_clientId}`, {
        state: { disabled: disabled },
      });
    } else {
      navigate(`/programIncome`, {
        state: { disabled: disabled },
      });
    }
  };

  const handleNext = () => {
    navigate(`/eligibilitystatus/${_appNumber}/${_clientId}`, {
      state: { disabled: disabled },
    });
  };

  const termsaccept = () => {
    setSignatureButton(!signatureButton);
  };

  return (
    <>
      <div>
        <Typography sx={{ fontSize: 18, fontWeight: 700, color: "#6c6c6c" }}>
          {transProps.pageTitle}
        </Typography>
      </div>

      <div style={{ marginTop: "2rem" }}>
        <ul className="rightsList">
          <li>{transProps.list1}</li>
          <li>{transProps.list2}</li>
        </ul>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {getSign ? <img width={150} height={100} src={getSign} /> : ""}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // marginTop: "2.5rem",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={formik.values.acceptCheckBox}
            {...formik.getFieldProps("acceptCheckBox")}
            onClick={termsaccept}
            disabled={disabled}
          />
          <p style={{ marginLeft: "0.5rem", marginTop: "1rem" }}>
            {transProps.accept}
          </p>
        </div>
        <Box>
          <Button
            sx={{
              mb: 1,
              width: "100%",
            }}
            startIcon={<FeatherIcon />}
            onClick={() => setOpenSignatureModal(true)}
            variant="contained"
            color="success"
            disabled={!formik.values.acceptCheckBox || disabled}
          >
            {transProps.signature}
          </Button>
        </Box>
      </div>

      <SignaturePad
        open={openSignatureModal}
        onClose={toggleSignature}
        setOpenSignatureModal={setOpenSignatureModal}
      />

      <Stack direction="row" spacing={2} mt={3} justifyContent="flex-end">
        <Button variant="outlined" color="inherit" onClick={handleGoBack}>
          {transProps.goBack}
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={submit}
          disabled={submitButton}
        >
          {transProps.next}
        </Button>
      </Stack>
    </>
  );
};
export default Certification;
