import { reject } from "lodash";

const initialState = {
  loadingItems: [],
};

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING_ITEM": {
      return { loadingItems: [...state.loadingItems, action.payload] };
    }
    case "REMOVE_LOADING_ITEM": {
      const newItems = reject(
        state.loadingItems,
        (item) => item === action.payload
      );
      return { loadingItems: newItems };
    }
    default:
      return state;
  }
};

export default loaderReducer;
