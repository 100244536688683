import { map } from "lodash";
import { useTranslation } from "react-i18next";

const useLkpTranslator = () => {
  const { t } = useTranslation();

  const getLkpTranslation = (path = "states", lookup) => {
    const translation = map(lookup, (lkp) => {
      const { lkpDesc } = lkp;
      return {
        ...lkp,
        lookupLongVal: t(`${path}.${lkpDesc}`),
      };
    });

    return translation;
  };

  return { getLkpTranslation };
};

export default useLkpTranslator;
