import React, { useState } from 'react';
import {
  Box,
  TextField,
  Stack,
  Button,
  FormLabel
} from "@mui/material";
import PropTypes from "prop-types";
import {useFormik} from 'formik'
import * as Yup from "yup";
import axios from 'axios';
import { AUTH_API } from "../../config";
import toast from 'react-hot-toast';

const ForgotPassword = ({ screen, setScreen, setEmail }) => {
const validationSchema = Yup.object({
email: Yup.string().email("Invalid email format").required("Email is required"),
});

const formik = useFormik({
initialValues: {
    email: "",
},
validationSchema,
onSubmit:  async (values) => {
    setEmail(values.email)
     try {
      const res = await axios.post(`${AUTH_API}/forgotPassword/${values.email}`, {}, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if(res.status === 200){
        setScreen("otp_screen")  
        toast.success("OTP sent. Please check your email.");
      }
    } catch (error) {
      console.log(error);
        toast.error("OTP request failed.");
    } 
},
});

  return (
    <div className="login-paper">
        <h2 className="font-bold boldFont pt-3">
           Forgot your password
        </h2>
        <span style={{fontSize:'1rem', textAlign:'center'}} className='my-2'>
            Please enter the email address you would like your password reset information sent to
        </span>
        <Stack component="form" className="login-form w-100"   onSubmit={formik.handleSubmit}>
            <Box sx={{ marginTop: "1rem" }}>
            <FormLabel className="labelName">E-mail</FormLabel>
            <TextField
                fullWidth
                id="email"
                placeholder="Enter the email address"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
            />
            </Box>
            <Box 
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                mt={5}
                gap={2}
            >
                <Button  type="submit" variant='contained' color='success' sx={{width:'100%'}}>
                    Request OTP
                </Button>
                <Button variant="outlined" sx={{width:'100%'}} onClick={()=>setScreen("")}>
                    Back To Login
                </Button>
            </Box>
        </Stack>
    </div>
  )
}

ForgotPassword.propTypes = {
  screen: PropTypes.string,
  setScreen: PropTypes.func,
  setEmail: PropTypes.func,
}

export default ForgotPassword