export const actionTypes = {
  SET_LOADING_ITEM: "SET_LOADING_ITEM",
  REMOVE_LOADING_ITEM: "REMOVE_LOADING_ITEM",
};

export function setLoadingItem(payload) {
  return {
    type: actionTypes.SET_LOADING_ITEM,
    payload: payload,
  };
}

export function removeLoadingItem(payload) {
  return {
    type: actionTypes.REMOVE_LOADING_ITEM,
    payload: payload,
  };
}
