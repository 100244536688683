import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { map } from "lodash";
import axiosInstance from "../../redux/interceptor";
import useLoader from "../../hooks/useLoader";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import PropTypes from "prop-types";

const AgeLimitModel = ({ editData, open, handleClose, getAgeData }) => {
  const { openLoader, closeLoader } = useLoader();
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      seniorAge: editData?.propValue ?? "",
      tribalAgeBelow: editData?.propValue1 ?? "",
      effectiveBeginDt: dayjs(editData?.effectiveBeginDt) ?? "",
    },
  });
  const onSubmit = async (data) => {
    const payload = [
      {
        id: editData?.id,
        propKey: editData?.propKey,
        propValue: data?.seniorAge,
        effectiveBeginDt: data?.effectiveBeginDt,
      },
      {
        id: editData?.id1,
        propKey: editData?.propKey1,
        propValue: data?.tribalAgeBelow,
        effectiveBeginDt: data?.effectiveBeginDt,
      },
    ];
    // if (data.tribalAgeBelow > data.tribalAgeAbove) {
    //   popUpAlert({
    //     title: "Error!",
    //     text: "Upper Age Limit should be greater than or equal to Lower Age Limit",
    //     icon: "error",
    //     showCancelButton: false,
    //     showConfirmButton: true,
    //     confirmButtonClass: "btn-danger",
    //     confirmButtonText: "Ok!",
    //     closeOnConfirm: false,
    //   });
    // } else if (data.seniorAge <= data.tribalAgeAbove) {
    //   popUpAlert({
    //     title: "Error!",
    //     text: "Senior Age Limit should be greater than Upper Age Limit",
    //     icon: "error",
    //     showCancelButton: false,
    //     showConfirmButton: true,
    //     confirmButtonClass: "btn-danger",
    //     confirmButtonText: "Ok!",
    //     closeOnConfirm: false,
    //   });
    // } else {
    //   openLoader("sub_el_cr");
    //   const { seniorAge, tribalAgeAbove, tribalAgeBelow } = data;
    openLoader("sub_el_cr");
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/staff/update/eligibility/age`,
        payload
      );
      if (res.status === 200) {
        //   fetchValues();
        handleClose();
        getAgeData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("sub_el_cr");
    }
    // }
  };
  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        scroll="paper"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ paddingBottom: "50px" }}
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography
            variant="h5"
            display="inline"
            className="text-capitalize"
            gutterBottom
            component="div"
          >
            Age Limit
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                gap: "1rem",
                display: "flex",
                flexDirection: "column",
                padding: "1rem",
              }}
            >
              <Grid
                container
                xs={12}
                sx={{
                  border: "1px solid grey",
                  borderRadius: "1rem",
                  padding: "1rem",
                }}
              >
                <Grid
                  xs={4}
                  item
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Form.Group
                    as={Col}
                    controlId="seniorAge"
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      fontWeight="fontWeightBold"
                      className="typography"
                      variant="h6"
                    >
                      Age of Senior
                    </Typography>
                    <Box>
                      <Form.Control
                        placeholder="Enter Senior Age"
                        type="number"
                        {...register("seniorAge", { valueAsNumber: true })}
                      />
                    </Box>
                  </Form.Group>
                </Grid>

                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  xs={4}
                >
                  <Form.Group
                    as={Col}
                    controlId="tribalAgeBelow"
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      fontWeight="fontWeightBold"
                      className="typography"
                      variant="h6"
                    >
                      Tribal Age
                    </Typography>
                    <Box>
                      <Form.Control
                        placeholder="Enter Tribal Age"
                        type="number"
                        {...register("tribalAgeBelow", {
                          valueAsNumber: true,
                        })}
                      />
                    </Box>
                  </Form.Group>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                  xs={4}
                >
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    Effective Start Date
                  </Typography>
                  <Box>
                    <Controller
                      name="effectiveBeginDt"
                      control={control}
                      required
                      render={({ field }) => (
                        <>
                          <DatePicker
                            name="effectiveBeginDt"
                            control={control}
                            required
                            {...field}
                            disablePast
                            slotProps={{
                              textField: {
                                error: false,
                              },
                            }}
                            format="YYYY/MM/DD"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="date"
                            placeholder="Select Date"
                          />
                        </>
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  sx={{ color: "#6C6C6C", border: "1px solid #6C6C6C" }}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    textTransform: "none",
                    width: "100px",
                    background: "#457425",
                    color: "#FFF",
                  }}
                >
                  Set Limit
                </Button>
              </Box>
            </Box>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};
AgeLimitModel.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  editData: PropTypes.bool,
  getAgeData: PropTypes.func
};

export default AgeLimitModel;
