import React from 'react'
import PropTypes from "prop-types";
import { mapStateToProps } from "./redux/reducers";
import { connect } from "react-redux";
import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutes = (props) => {
 const auth = props?.userAuth?.user
  return (
      auth !== null ? <Outlet /> : <Navigate to="/" />
    )
}

PrivateRoutes.propTypes = {
  userAuth: PropTypes.object,
};

export default connect(mapStateToProps)(PrivateRoutes);