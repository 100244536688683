/*
  NOT question types
  ------------------

  These NON-question types cannot be passed into ControlWrapper because they are not
  question types. These are components of the form, but must be separately rendered

 */

// HEADING
//
// # Required fields:
//   VALUE: actual text value of the heading
//   SIZE: one of SIZE_SMALL, SIZE_MEDIUM, SIZE_LARGE
//
export const HEADING = "heading";

// TEXT
//
// # Required fields:
//   VALUE: actual text value of the text
//
export const TEXT = "text";

export const WARNING_TEXT = "warningText";

export const HTML_TEXT = "htmlText";

// DIVIDER
//
// No additional properties beyond the specified type
//
export const DIVIDER = "divider";

/*
  Question types
  --------------

  The following applies to all types below. For additional type-specific
  required or optional fields, see the comment next to that type below.

  # Required fields:
    NAME: a string with name of the question
    LABEL: can be any one of the following
      (1) a string containing the question text
      (2) for pop-out help, an object containing the following fields
        LABEL_OBJ_BEFORE: Optional text to display before the trigger term.
        LABEL_OBJ_AFTER: Optional text to display after the trigger term.
        LABEL_OBJ_TRIGGER: Required text to put inside the trigger button to expand help tip.
        LABEL_OBJ_HELP: Required text containing the additional help text for the term.
    TYPE: a string with the type of the question for the resolver. Type must be
      one of the following types below

  # Optional fields:
    LABEL_HAS_MARKUP: boolean to indicate if label has markup and should be
      dangerously rendered in the DOM
    INLINE_HELP: a string with inline message to display below label
    INLINE_HELP_HAS_MARKUP: boolean to indicate if inline help text has markup and should be
      dangerously rendered in the DOM
    REQUIRED: boolean indicating if this field is required, false if absent
 */

export const DATE = "date";
export const DATE_RANGE = "dateRange";
export const ADDRESS = "address";
export const ADDRESS_COMMON = "addresscommon";

export const NUMBERS = "numbers";

// INPUT
//
// # Optional fields:
//   PLACEHOLDER: placeholder to be display if blank
//   CLEAR_LABEL: string to label a clear button. The clear button will be shown when a label is
//     provided and hidden otherwise.
//
export const INPUT_TEXT = "inputText";
export const INPUT_EMAIL = "inputEmail";
export const INPUT_PHONE = "inputPhone";
export const INPUT_SSN = "inputSSN";
export const INPUT_SEARCH = "inputSearch";
export const INPUT_ALPHA_TEXT = "inputAlphaText";
export const INPUT_ALPHA_TEXT_SUBMIT = "inputAlphaTextSubmit";
export const INPUT_ALPHANUMERIC_TEXT = "inputAlphanumericText";
export const INPUT_TEXT_AREA = "textarea";
export const INPUT_NUMERIC_ONLY = "inputNumericOnly";
export const INPUT_NUMERIC_ONLY_TWO = "inputNumericOnlyTwo";

// SELECT
//
// # Required fields:
//   OPTIONS: string list of options
//
export const SELECT = "select";
export const SELECT_TWO = "selectTwo";

//BUTTON
export const BUTTON = "button";
// OPTIONS (RADIO and CHECKBOX)
//
// # Required fields:
//   OPTIONS: can be one of
//     (1) string list of options, must be strings
//     (2) an object containing the following fields
//       OPTION_VALUE: string value if this is selected, must be string
//       OPTION_DISPLAY: string value to display
//       OPTION_DISPLAY_SIZE: string value to customize the font size of a specific optino
//         Valid values are OPTION_DISPLAY_SIZE_SMALL or OPTION_DISPLAY_SIZE_LARGE
//         Blank or invalid reverts to normal font size
//
// # Optional fields:
//   CLEAR_OPTION: string to display on an option to clear answer, no option to clear if blank
//   OPTIONS_PER_ROW: number specifying the number of options to place in a row
//     Valid numbers are 1, 2, and 3. Will default to 1 if absent or invalid.
//   CONDITIONALS: an array of objects with the following fields
//     CONDITIONAL_TARGET_VALUE: when value equals or contains this, then show associated questions
//       if the options are specified with OPTION_VALUE and OPTION_DISPLAY, then this target must
//       be the OPTION_VALUE
//     CONDITIONAL_QUESTIONS: questions to display when associated target value is fulfilled
//
export const RADIO = "radio";
export const CHECKBOX = "checkbox";

// UNITS
//
// # Required fields:
//   IN_BETWEEN: string to display between amount and units, default value is 'per'
//   UNITS: string list of allowed units
//
// # Optional fields:
//   CLEAR_OPTION: string to display on an option to clear answer, no option to clear if blank
//   MONEY: boolean indicating if the amount is money and should be presented accordingly
//
export const UNITS = "units";
export const NOFREQ_UNITS = "noFreqUnits";
export const EARNED_INCOME = "earnedIncome";
export const UNEARNED_INCOME = "unEarnedIncome";

// REPEATABLE QUESTION
//
export const REPEATABLE_QUESTION = "repeatableQuestion";

//LANGUAGE CONSTANTS
export const ENGLISH = "en-US";
