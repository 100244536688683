import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import "../market/market.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AUTH_API } from "../../config";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useLoader from "../../hooks/useLoader";
import useLookup from "../../hooks/useLookup";
import { map } from "lodash";
import InputMask from "react-input-mask";

function MarketRegistration() {
  const [response, setResponse] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { getOptions } = useLookup("marketRegistration");
  const { openLoader, closeLoader } = useLoader();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const timeStamp = new Date().getTime();
  const registration = async (values) => {
    try {
      openLoader("registration");
      let payload = {
        firstName: values.fName,
        lastName: values.lName,
        primaryEmail: values.email,
        primaryMobile: values.phone,
        password: values.password,
        confirm_password: values.cPassword,
        businessDesc: "",
        fnsNum: values.fed,
        communicationEmail: "",
        products: "",
        publicEmail: "",
        publicMobile: "",
        publicWebsite: "",
        additionalContactInfo: "",
        publicPhoto: "",
        bankName: values.bank,
        bankType: values.bankType,
        accountNo: values.accountN,
        routingNo: values.routingN,
        csaFnsNum: "",
        csaCommunicationEmail: "",
        vendorType: "MRKCRD",
        isHipAndCsaVdr: false,
        role: "MRKCRD",
        isVerified: "N",
        isActive: "N",
        forceLogin: "N",
        createTimeStamp: timeStamp,
        lastUpdtTimeStamp: timeStamp,
        submitForm: "MRKCRD",
        mname: values.mName,
        marketName: values.marketName,
        marketAddress: values.marketAddress,
        marketphone: values.marketPhone,
        city: values.city,
        state: values.state,
        county: values.county,
        zipcode: values.zipCode,
        sigma: values.sigma,
        fed: values.fed,
        eid: values.eid,
        farmers: values.farmers,
        status: "PE",
        apptoApply: `${values.market}${values.market && values.road && ", "}${
          values.road
        }`,
      };
      const res = await axios.post(
        AUTH_API + "/public/marketcoordinator/register",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.status == 200) {
        setResponse[res.data];
        navigate(`/publicInfo`, {
          state: { userData: res.data },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("registration");
    }
  };

  const { stateOpts, bankingOpts } = useMemo(() => {
    return {
      stateOpts: getOptions("state"),
      bankingOpts: getOptions("bankingtype"),
    };
  }, [getOptions]);

  const validationSchema = Yup.object({
    fName: Yup.string().required("First Name is required"),
    lName: Yup.string().required("Last Name is required"),
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
    cPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords do not match"),
    marketName: Yup.string().required("Market Name is required"),
    marketAddress: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    county: Yup.string().required("County is required"),
    zipCode: Yup.string()
      .required("ZipCode is required")
      .min(5, "Must be exactly 5 digits"),
    sigma: Yup.string()
      .required("Sigma ID is required")
      .min(9, "Must be exactly 9 digits"),
    fed: Yup.string()
      .required("FED ID is required")
      .min(9, "Must be exactly 9 digits"),
    routingN: Yup.string()
      .required("Routing Number is required")
      .min(9, "Must be exactly 9 digits"),
    bank: Yup.string().required("Bank is required"),
    accountN: Yup.string()
      .required("Account Number is required")
      .min(12, "Must be exactly 12 digits"),
    bankType: Yup.string().required("Bank Type is required"),
    farmers: Yup.string().required("Number of Farmers is required"),
    phone: Yup.string()
      .required("Phone is required")
      .min(10, "Must be exactly 10 digits"),
    marketPhone: Yup.string().min(10, "Must be exactly 10 digits"),
    eid: Yup.string()
      .required("EID number is required")
      .min(9, "Must be exactly 9 digits"),
    market: Yup.string().when("road", {
      is: (road, market) => !road || market,
      then: () => Yup.string().required("Please choose at least one"),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      fName: "",
      mName: "",
      lName: "",
      password: "",
      cPassword: "",
      email: "",
      marketName: "",
      marketAddress: "",
      marketPhone: "",
      city: "",
      state: "",
      county: "",
      zipCode: "",
      sigma: "",
      fed: "",
      routingN: "",
      bank: "",
      accountN: "",
      bankType: "",
      farmers: "",
      phone: "",
      eid: "",
      market: "",
      road: "",
    },
    validationSchema,
    onSubmit: (values) => {
      registration(values);
    },
    enableReinitialize: true,
  });

  return (
    <React.Fragment>
      <Typography className="title" variant="h5" sx={{ margin: "1rem " }}>
        Market Coordinator Registration
      </Typography>

      <Box
        sx={{ border: "1px solid #d9d9d9", borderRadius: "0.5rem" }}
        m={3}
        p={2}
        width="80%"
      >
        <Grid container spacing={2}>
          <Grid item xs={4} md={4} lg={4}>
            <Typography className="typography" variant="h6">
              First Name
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              InputProps={{
                onInput: (event) => {
                  event.target.value = event.target.value.replace(
                    /[^a-z A-Z]/g,
                    ""
                  );
                },
              }}
              inputProps={{ maxLength: 20 }}
              fullWidth
              name="fName"
              size="small"
              placeholder={"Enter First Name"}
              value={formik.values.fName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.fName && formik.errors.fName}
              helperText={formik.touched.fName && formik.errors.fName}
            ></TextField>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Typography className="typography" variant="h6">
              Middle Name
            </Typography>
            <TextField
              InputProps={{
                onInput: (event) => {
                  event.target.value = event.target.value.replace(
                    /[^a-z A-Z]/g,
                    ""
                  );
                },
              }}
              inputProps={{ maxLength: 20 }}
              fullWidth
              name="mName"
              size="small"
              placeholder={"Enter Middle Name"}
            ></TextField>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Typography variant="h6" className="typography">
              Last Name
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              InputProps={{
                onInput: (event) => {
                  event.target.value = event.target.value.replace(
                    /[^a-z A-Z]/g,
                    ""
                  );
                },
              }}
              inputProps={{ maxLength: 20 }}
              fullWidth
              className="formControl"
              name="lName"
              size="small"
              placeholder={"Enter Last Name"}
              value={formik.values.lName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lName && formik.errors.lName}
              helperText={formik.touched.lName && formik.errors.lName}
            ></TextField>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="h6" className="typography">
              Email Address
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              inputProps={{ maxLength: 50 }}
              fullWidth
              name="email"
              size="small"
              placeholder={"Enter Email"}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
            ></TextField>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography className="typography" variant="h6">
              Phone Number
              <span style={{ color: "red" }}>*</span>
            </Typography>
            {/* <TextField
              // InputProps={{
              //   onInput: (event) => {
              //     event.target.value = event.target.value.replace(
              //       /[^0-9]/g,
              //       ""
              //     );
              //   },
              // }}
              // inputProps={{ maxLength: 10 }}
              fullWidth
              name="phone"
              size="small"
              placeholder={"Enter Phone"}
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && formik.errors.phone}
              helperText={formik.touched.phone && formik.errors.phone}
              InputProps={{
                inputComponent: TextMaskCustom,
              }}
            ></TextField> */}
            <InputMask
              mask="(999)-999-9999"
              value={formik.values.phone}
              onChange={formik.handleChange}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  fullWidth
                  name="phone"
                  placeholder={"Enter Phone Number"}
                  type="tel"
                  error={formik.touched.phone && formik.errors.phone}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              )}
            </InputMask>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="h6" className="typography">
              Password
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              name="password"
              placeholder="Enter your password"
              type={showPassword ? "text" : "password"}
              id="password"
              size="small"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography className="typography" variant="h6">
              Confirm Password
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              type="password"
              name="cPassword"
              size="small"
              placeholder={"Confirm Password"}
              value={formik.values.cPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.cPassword && formik.errors.cPassword}
              helperText={formik.touched.cPassword && formik.errors.cPassword}
            ></TextField>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{ border: "1px solid #d9d9d9", borderRadius: "0.5rem" }}
        m={3}
        p={2}
        width="80%"
      >
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="h6" className="typography">
              Market Name
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              inputProps={{ maxLength: 30 }}
              fullWidth
              name="marketName"
              size="small"
              placeholder={"Enter Market Name"}
              value={formik.values.marketName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.marketName && formik.errors.marketName}
              helperText={formik.touched.marketName && formik.errors.marketName}
            ></TextField>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography className="typography" variant="h6">
              Market Address
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              inputProps={{ maxLength: 40 }}
              fullWidth
              name="marketAddress"
              size="small"
              placeholder={"Enter Market Address"}
              value={formik.values.marketAddress}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.marketAddress && formik.errors.marketAddress
              }
              helperText={
                formik.touched.marketAddress && formik.errors.marketAddress
              }
            ></TextField>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="h6" className="typography">
              City
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              InputProps={{
                onInput: (event) => {
                  event.target.value = event.target.value.replace(
                    /[^a-z A-Z]/g,
                    ""
                  );
                },
              }}
              inputProps={{ maxLength: 20 }}
              fullWidth
              name="city"
              size="small"
              placeholder={"Enter City"}
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.city && formik.errors.city}
              helperText={formik.touched.city && formik.errors.city}
            ></TextField>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography className="typography" variant="h6">
              State
              <span style={{ color: "red" }}>*</span>
            </Typography>

            <Box width="100%" overflow="hidden">
              <Select
                fullWidth
                size="small"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.state && formik.errors.state}
              >
                {map(stateOpts, (item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{formik.errors.state}</FormHelperText>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="h6" className="typography">
              County
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              InputProps={{
                onInput: (event) => {
                  event.target.value =
                    event.target.value.replace(/[^a-z A-Z]/g);
                },
              }}
              inputProps={{ maxLength: 20 }}
              fullWidth
              name="county"
              size="small"
              placeholder={"Enter County"}
              value={formik.values.county}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.county && formik.errors.county}
              helperText={formik.touched.county && formik.errors.county}
            ></TextField>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography className="typography" variant="h6">
              ZipCode
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              name="zipCode"
              size="small"
              placeholder={"Enter ZipCode"}
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.zipCode && formik.errors.zipCode}
              helperText={formik.touched.zipCode && formik.errors.zipCode}
              InputProps={{
                onInput: (event) => {
                  event.target.value = event.target.value.replace(
                    /[^0-9]/g,
                    ""
                  );
                },
              }}
              inputProps={{ maxLength: 5 }}
            ></TextField>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="h6" className="typography">
              SIGMA Account Number
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              name="sigma"
              size="small"
              placeholder={"Enter SIGMA Account Number"}
              value={formik.values.sigma}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.sigma && formik.errors.sigma}
              helperText={formik.touched.sigma && formik.errors.sigma}
              InputProps={{
                onInput: (event) => {
                  event.target.value = event.target.value.replace(
                    /[^0-9]/g,
                    ""
                  );
                },
              }}
              inputProps={{ maxLength: 9 }}
            ></TextField>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography className="typography" variant="h6">
              Phone Number
            </Typography>
            {/* <TextField
              fullWidth
              name="marketPhone"
              size="small"
              placeholder={"Enter Phone Number"}
              value={formik.values.marketPhone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.marketPhone && formik.errors.marketPhone}
              helperText={
                formik.touched.marketPhone && formik.errors.marketPhone
              }
              InputProps={{
                onInput: (event) => {
                  event.target.value = event.target.value.replace(
                    /[^0-9]/g,
                    ""
                  );
                },
              }}
              inputProps={{ maxLength: 10 }}
            ></TextField> */}
            <InputMask
              mask="(999)-999-9999"
              value={formik.values.marketPhone}
              onChange={formik.handleChange}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  fullWidth
                  placeholder={"Enter Phone Number"}
                  name="marketPhone"
                  type="tel"
                  error={
                    formik.touched.marketPhone && formik.errors.marketPhone
                  }
                  helperText={
                    formik.touched.marketPhone && formik.errors.marketPhone
                  }
                />
              )}
            </InputMask>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="h6" className="typography">
              FED ID
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              name="fed"
              size="small"
              placeholder={"Enter FED ID"}
              value={formik.values.fed}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.fed && formik.errors.fed}
              helperText={formik.touched.fed && formik.errors.fed}
              InputProps={{
                onInput: (event) => {
                  event.target.value = event.target.value.replace(
                    /[^0-9]/g,
                    ""
                  );
                },
              }}
              inputProps={{ maxLength: 9 }}
            ></TextField>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography className="typography" variant="h6">
              EIN Number
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              name="eid"
              size="small"
              placeholder={"Enter EIN Number"}
              value={formik.values.eid}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.eid && formik.errors.eid}
              helperText={formik.touched.eid && formik.errors.eid}
              InputProps={{
                onInput: (event) => {
                  event.target.value = event.target.value.replace(
                    /[^0-9]/g,
                    ""
                  );
                },
              }}
              inputProps={{ maxLength: 9 }}
            ></TextField>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{ border: "1px solid #d9d9d9", borderRadius: "0.5rem" }}
        m={3}
        p={2}
        width="80%"
      >
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="h6" className="typography">
              How many farmers do you represent?
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              name="farmers"
              size="small"
              placeholder={"Enter the number of farmers"}
              value={formik.values.farmers}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.farmers && formik.errors.farmers}
              helperText={formik.touched.farmers && formik.errors.farmers}
              InputProps={{
                onInput: (event) => {
                  event.target.value = event.target.value.replace(
                    /[^0-9]/g,
                    ""
                  );
                },
              }}
              inputProps={{ maxLength: 3 }}
            ></TextField>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography className="typography" variant="h6">
              Check all that apply?
              <span style={{ color: "red" }}>*</span>
            </Typography>

            <FormControlLabel
              control={
                <Checkbox
                  {...formik.getFieldProps("market")}
                  checked={formik.values.market === "market"}
                  onChange={(e, checked) => {
                    formik.setFieldValue("market", checked ? "market" : "");
                  }}
                />
              }
              label="Market"
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  {...formik.getFieldProps("road")}
                  checked={formik.values.road === "road"}
                  onChange={(e, checked) => {
                    formik.setFieldValue("road", checked ? "road" : "");
                  }}
                />
              }
              label="Roadside Stand"
              labelPlacement="end"
            />
            <br></br>
            <span style={{ color: "red", fontSize: ".75rem" }}>
              {formik.errors.market}
            </span>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{ border: "1px solid #d9d9d9", borderRadius: "0.5rem" }}
        m={3}
        p={2}
        width="80%"
      >
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="h6" className="typography">
              Routing Number
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              InputProps={{
                onInput: (event) => {
                  event.target.value = event.target.value.replace(
                    /[^0-9]/g,
                    ""
                  );
                },
              }}
              inputProps={{ maxLength: 9 }}
              fullWidth
              name="routingN"
              size="small"
              placeholder={"Enter Routing Number"}
              value={formik.values.routingN}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.routingN && formik.errors.routingN}
              helperText={formik.touched.routingN && formik.errors.routingN}
            ></TextField>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography className="typography" variant="h6">
              Banking Account Number
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              InputProps={{
                onInput: (event) => {
                  event.target.value = event.target.value.replace(
                    /[^0-9]/g,
                    ""
                  );
                },
              }}
              inputProps={{ maxLength: 12 }}
              fullWidth
              name="accountN"
              size="small"
              placeholder={"Enter Banking Account Number"}
              value={formik.values.accountN}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.accountN && formik.errors.accountN}
              helperText={formik.touched.accountN && formik.errors.accountN}
            ></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="h6" className="typography">
              Bank Name
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              InputProps={{
                onInput: (event) => {
                  event.target.value = event.target.value.replace(
                    /[^a-z A-Z]/g,
                    ""
                  );
                },
              }}
              inputProps={{ maxLength: 25 }}
              name="bank"
              size="small"
              placeholder={"Enter Bank Name"}
              value={formik.values.bank}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.bank && formik.errors.bank}
              helperText={formik.touched.bank && formik.errors.bank}
            ></TextField>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography className="typography" variant="h6">
              Bank Type
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box width="100%" overflow="hidden">
              <Select
                fullWidth
                size="small"
                name="bankType"
                value={formik.values.bankType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.bankType && formik.errors.bankType}
              >
                {map(bankingOpts, (item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{formik.errors.bankType}</FormHelperText>
            </Box>
            {/* <TextField
              fullWidth
              name="bankType"
              size="small"
              placeholder={"Enter Bank Type"}
              value={formik.values.bankType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.bankType && formik.errors.bankType}
              helperText={formik.touched.bankType && formik.errors.bankType}
            ></TextField> */}
          </Grid>
        </Grid>
      </Box>

      <Box width="82%">
        <Stack
          direction={"row"}
          spacing={2}
          display={"flex"}
          justifyContent={"flex-end"}
          pb={2}
        >
          <Button
            size="small"
            variant="contained"
            color="success"
            onClick={formik.handleSubmit}
          >
            Next
          </Button>
        </Stack>
      </Box>
    </React.Fragment>
  );
}

export default MarketRegistration;
