import React from "react";
import { Outlet } from "react-router-dom";
import styles from "./index.module.css";
import SideNavigation from "../sidebar";

function Layout() {
  return (
    <div className={styles.layout}>
      <div className={styles.leftSection} >
        <SideNavigation role={"senior"} />
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;
