import React from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MapComponent from "../common/components/mapComponent";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { removeSpecialCharacter } from "../../utils/globalMethods";

const StoreHistoryVendorDetails = ({ row }) => {
  return (
    <Grid container className="collapsibleTable" spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          display="inline"
          className="resText"
          gutterBottom
          component="div"
        >
          Store Details
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          {row.siteNameLabel}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.siteName ? removeSpecialCharacter(row.siteName) : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Address 1
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.addressDetail.addressLine1
            ? row.addressDetail.addressLine1
            : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Address 2
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.addressDetail.addressLine2
            ? row.addressDetail.addressLine2
            : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          City
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.addressDetail.city ? row.addressDetail.city : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          State
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.addressDetail.state ? row.addressDetail.state : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Zip Code
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.addressDetail.zipcode ? row.addressDetail.zipcode : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Month(s) of Operation
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.monthsOfOperation.length
            ? row.monthsOfOperation.toString().split(",").join(", ")
            : ""}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Order Operation(s)
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.orderOperations.length
            ? row.orderOperations.toString().split(",").join(", ")
            : ""}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" gutterBottom component="div">
          Regular Hour(s) of Operation
        </Typography>
        {row.storeHours.map((item) => {
          return (
            <Grid xs={12} key={item.id} container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="subtitle1" gutterBottom key={item.id}>
                  {item.weekdayDescription}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom key={item.id}>
                  <small
                    style={{
                      color: `${item.isWorkingDay === "Y" ? "" : "red"}`,
                    }}
                    aria-label={
                      item.isWorkingDay === "Y"
                        ? item.startTime + " - " + item.endTime
                        : "Closed"
                    }
                  >
                    {item.isWorkingDay === "Y"
                      ? item.startTime + " - " + item.endTime
                      : "Closed"}
                  </small>
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" gutterBottom component="div">
          Location
        </Typography>
        <MapComponent
          lat={row.addressDetail.latitude}
          lng={row.addressDetail.longitude}
          row={row.addressDetail}
          draggable={false}
        />
      </Grid>
      {Object.keys(row.specialHoursOfOperation).length > 0 && (
        <Grid item xs={12} spacing={2}>
          <Typography variant="h6" gutterBottom component="div">
            Special Hour(s) of Operation
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Week</TableCell>
                  <TableCell align="left">Day(s)</TableCell>
                  <TableCell align="left">Month(s)</TableCell>
                  <TableCell align="left">Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    {row.specialHoursOfOperation.status}
                  </TableCell>
                  <TableCell align="left">
                    {row.specialHoursOfOperation.week}
                  </TableCell>
                  <TableCell align="left" className="wrapText">
                    {row.specialHoursOfOperation.dayInfo.join(", ")}
                  </TableCell>
                  <TableCell align="left" className="wrapText">
                    {row.specialHoursOfOperation.monthInfo.join(", ")}
                  </TableCell>
                  <TableCell align="left">
                    {row.specialHoursOfOperation.time}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
};

StoreHistoryVendorDetails.propTypes = {
  row: PropTypes.object.isRequired,
};

export default StoreHistoryVendorDetails;
