import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/reducers";
import PhoneCall from "@mui/icons-material/PhoneRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import contact from "../../../assets/images/contact_phone.png";
import police from "../../../assets/images/local_police.png";
import help from "../../../assets/images/help.png";
import accessibility from "../../../assets/images/accessibility.png";
import comment from "../../../assets/images/comment.png";
import masslogo from "../../../assets/images/mass-logo.png";
import dta_1 from "../../../assets/images/dta_1.png";
import Kcare from "../../../assets/images/kcares.svg";
import Copyright from "../../../assets/images/copyright.png";
import SNAP_PathToWork from "../../../assets/images/SNAP_ PathToWork.png";
import Knotify from "../../../assets/images/notify.svg";
import UMASS_Logo_DTA_SNAPEd from "../../../assets/images/UMASS_Logo_DTA_SNAPEd.png";
import "../../../styles/footer.scss";
import { TransProps } from "react-i18next";
import { config } from "../../../config";
import HelpComponent from "../../../pages/user/help";
import ModalComponent from "../components/modal";
import PropTypes from "prop-types";
import useTranslator from "../../../hooks/useTranslator";
import {
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  Link as MaterialLink,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import "../../../styles/feedback.scss";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { sendFeedback } from "../../../redux/actionTypes/administration";
import { Feedback } from "@mui/icons-material";
import CopyrightIcon from "@mui/icons-material/Copyright";

const Footer = (props) => {
  const transProps = useTranslator(
    [
      "Copyright",
      "sitePolicies",
      "accessibility",
      "help",
      "contactUs",
      "feedback",
      "pleaseLetUsknow",
      "helpUsImprove",
      "thankYou",
      "didYouFind",
      "yes",
      "no",
      "doYouHave",
      "weWillUse",
      "doNotInclude",
      "sendFeedBack",
      "pleaseTell",
      "yourFeedbackWill",
      "why",
      "feedbackWillOnly",
      "contactExecutive",
    ],
    "footer"
  );
  const [helpModalShow, setHelpModalShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [feedback, setFeedback] = useState("");
  const [showThank, setShowThank] = useState(false);

  const { register, handleSubmit } = useForm();
  const [error, setError] = useState(null);
  const [text, setText] = useState("");
  const [checked, setChecked] = useState(false);

  const maxCharacters = 500;

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const handleTextChange = (event) => {
    setError(null);
    const inputValue = event.target.value;
    if (inputValue.length <= maxCharacters) {
      setText(inputValue);
    }
    setFeedback(inputValue);
  };

  const onSubmit = () => {
    if (!feedback) {
      setError(transProps.pleaseLetUsknow);
      return;
    } else {
      setError("");
      const isHelpfulValue = selectedOption === "Yes" ? "Y" : "N";
      const payload = {
        feedbackMessage: feedback,
        isHelpful: isHelpfulValue,
      };
      props.sendFeedback(payload);
    }
  };

  useEffect(() => {
    const { sendFeedback } = props.administration;
    if (sendFeedback?.data?.code === "200") {
      setShowThank(true);
    }
  }, [props.administration?.sendFeedback]);

  const handleOptionChange = (event) => {
    setError(null);
    setText("");
    setSelectedOption(event.target.value);
  };

  const toggleHelpModal = () => {
    setHelpModalShow(true);
  };

  const renderModal = () => {
    // return (<FeedbackComponent />)
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction={"row"}
            style={{ display: "flex", justifyContent: "center" }}
            pb={4}
          >
            <Grid xs={11} sx={{ border: "1px solid #dcdcdc" }}>
              <Grid item>
                <Typography variant="h5" className="feedback-title">
                  {transProps.helpUsImprove}
                </Typography>
              </Grid>
              {showThank ? (
                <Grid item className="feedback-content">
                  <Typography className="font-regular14">
                    {transProps.thankYou}{" "}
                  </Typography>
                  {/* <Typography className='font-regular14'>If you would like to continue helping us improve Mass.gov,&nbsp;  
                  <Link className='feedback-link' onClick={() => { window.open(config.navUrls.userPanel) }}><b>join our user panel</b></Link> to test new features for the site.
              </Typography> */}
                </Grid>
              ) : (
                <Grid item className="feedback-content">
                  <FormControl component={"fieldset"}>
                    <Typography className="font-regular14">
                      {transProps.didYouFind}
                    </Typography>
                    <RadioGroup
                      row
                      aria-label="options"
                      className="font-regular14"
                      name="options"
                      value={selectedOption}
                      onChange={handleOptionChange}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label={transProps.yes}
                        classes={{ label: "font-regular14" }}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label={transProps.no}
                        classes={{ label: "font-regular14" }}
                      />
                    </RadioGroup>
                  </FormControl>
                  {selectedOption === "Yes" && (
                    <Grid item>
                      <Typography className="font-regular14">
                        {transProps.doYouHave}
                        ?&nbsp;*
                      </Typography>
                      <Typography className="font-regular14">
                        {transProps.weWillUse}
                      </Typography>

                      <Grid className="text-area" sx={{ marginTop: ".5em" }}>
                        <TextareaAutosize
                          className={`textArea-content font-regular14 ${
                            error ? "danger-border" : ""
                          }`}
                          {...register("feedbackMessage", { required: true })}
                          value={text}
                          onChange={handleTextChange}
                          maxLength={"500"}
                          minRows={3}
                          style={{ width: "100%", overflow: "hidden" }}
                        />
                        <Typography className="maxCharacter font-light13">
                          {maxCharacters - text.length}/{maxCharacters}
                        </Typography>
                      </Grid>
                      <Typography className="font-regular13">
                        {transProps.doNotInclude}{" "}
                      </Typography>
                      {error && (
                        <div
                          style={{
                            borderLeft: "2px solid rgb(205, 13, 13)",
                            paddingLeft: "13px",
                          }}
                        >
                          <span className="dh-txt-danger">{error}</span>
                        </div>
                      )}
                      <Grid className="btn-wrapper" mt={2}>
                        <Button
                          className="submit-btn font-regular14"
                          variant="contained"
                          onClick={onSubmit}
                        >
                          {transProps.sendFeedBack}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {selectedOption === "No" && (
                    <Grid item>
                      <Typography className="font-regular14">
                        {" "}
                        {transProps.pleaseTell}.*
                      </Typography>
                      <Typography className="font-regular14">
                        {transProps.weWillUse}
                      </Typography>

                      <Grid className="text-area" sx={{ marginTop: ".5em" }}>
                        <TextareaAutosize
                          className={`textArea-content font-regular14 ${
                            error ? "danger-border" : ""
                          }`}
                          {...register("feedbackMessage", { required: true })}
                          value={text}
                          onChange={handleTextChange}
                          maxLength={"500"}
                          minRows={3}
                          style={{ width: "100%", overflow: "hidden" }}
                        />
                        <Typography className="maxCharacter font-light13">
                          {maxCharacters - text.length}/{maxCharacters}
                        </Typography>
                      </Grid>
                      <Typography className="font-regular13">
                        {transProps.doNotInclude}
                      </Typography>

                      <Grid
                        style={{ marginBottom: ".5em", position: "relative" }}
                      >
                        <Grid item xs={12} display="flex">
                          <Typography
                            variant="body1"
                            className="font-semibold14"
                          >
                            {transProps.yourFeedbackWill}
                            <span
                              className="accordin-btn"
                              onClick={handleChange}
                            >
                              {transProps.why}&nbsp;
                              <i
                                className={`fa ${
                                  checked ? "fa-chevron-up" : "fa-chevron-down"
                                }`}
                                style={{ fontSize: "12px" }}
                                aria-hidden="true"
                              ></i>
                            </span>
                          </Typography>
                        </Grid>
                        <Collapse
                          in={checked}
                          style={{
                            background: "#E8EEF4",
                            marginTop: ".5em",
                            position: "relative",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{ padding: ".5em", overflowY: "auto" }}
                          >
                            <Typography className="feedback-help-text font-regular14">
                              {transProps.feedbackWillOnly}
                              &nbsp;
                              <Link
                                className="feedback-link"
                                onClick={() => {
                                  window.open(
                                    config.navUrls.healthAndHumanService
                                  );
                                }}
                              >
                                <b>{transProps.contactExecutive}</b>
                              </Link>
                            </Typography>
                            <IconButton
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                              }}
                              onClick={handleChange}
                              aria-label="close"
                            >
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        </Collapse>
                      </Grid>
                      {error && (
                        <div
                          style={{
                            borderLeft: "4px solid rgb(205, 13, 13)",
                            paddingLeft: "13px",
                          }}
                        >
                          <span className="dh-txt-danger font-regular14">
                            {error}
                          </span>
                        </div>
                      )}
                      <Grid className="btn-wrapper" mt={2}>
                        <Button
                          className="submit-btn font-regular14"
                          variant="contained"
                          onClick={onSubmit}
                        >
                          {transProps.sendFeedBack}{" "}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Form>
      </React.Fragment>
    );
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      {helpModalShow ? (
        <HelpComponent
          shown={helpModalShow}
          close={() => {
            setHelpModalShow(false);
          }}
        />
      ) : (
        ""
      )}
      {showModal && (
        <ModalComponent
          shown={showModal}
          close={() => {
            setShowModal(false);
            setShowThank(false);
            setSelectedOption("");
            setText("");
          }}
          title={"Feedback"}
          modalBody={renderModal}
          size={"lg"}
          label={transProps.feedback}
        />
      )}
      <Grid
        container
        role="contentinfo"
        className="main-footer"
        sx={{ paddingTop: "10px", paddingLeft: "17px" }}
      >
        <Grid item xs={6} sm={7} md={7} lg={7}>
          <span style={{ paddingRight: "20px" }}>
            {" "}
            <Link onClick={() => {}} aria-label="Kcares Connect Logo">
              <img
                className="footer-lg-img res-footer"
                // width={"100px"}
                src={Kcare}
                alt="Kcares Connect Logo"
              />
            </Link>
          </span>
          <span>
            <Link onClick={() => {}} aria-label="Knotify Logo">
              <img
                className="footer-lg-img"
                // width={"100px"}
                src={Knotify}
                alt="Knotify Logo"
              />
            </Link>
          </span>
          <Typography sx={{ paddingTop: "4px" }}>
            <CopyrightIcon fontSize="small" />
            <span style={{ fontSize: "12px", fontWeight: "600" }}>
              {transProps.Copyright}
            </span>
            {/* <img src={Copyright} alt="" /> */}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={5}
          lg={5}
          className="font-18 footer-list"
          aria-label="navigation"
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Grid container>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                {" "}
                <MaterialLink href="tel:(877)382-2363" aria-label="phonenumber">
                  <PhoneCall
                    fontSize="medium"
                    style={{ paddingRight: "9px" }}
                  />
                  (844)599-2248
                </MaterialLink>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                {" "}
                <MaterialLink
                  component={Link}
                  // onClick={() => {
                  //   window.open(config.navUrls.contactUs);
                  // }}
                  aria-label="contactUs"
                >
                  {" "}
                  <span style={{ paddingRight: "9px" }}>
                    <img src={contact} alt="" />
                  </span>
                  {transProps.contactUs}
                </MaterialLink>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                {" "}
                <MaterialLink
                  component={Link}
                  // onClick={() => {
                  //   window.open(config.navUrls.sitePoliciesUrl);
                  // }}
                  aria-label="Site Policies"
                >
                  {" "}
                  <span>
                    <img src={police} alt="" style={{ paddingRight: "9px" }} />
                  </span>
                  {transProps.sitePolicies}
                </MaterialLink>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                {" "}
                <MaterialLink
                  component={Link}
                  // onClick={() => {
                  //   window.open(config.navUrls.accessibilityUrl);
                  // }}
                  aria-label="accessibility"
                >
                  {" "}
                  <span>
                    <img
                      src={accessibility}
                      alt=""
                      style={{ paddingRight: "9px" }}
                    />
                  </span>
                  {transProps.accessibility}
                </MaterialLink>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                {" "}
                <MaterialLink
                  component={Link}
                  aria-label="feedback"
                  // onClick={() => {
                  //   setShowModal(true);
                  // }}
                >
                  {" "}
                  <span>
                    <img src={comment} alt="" style={{ paddingRight: "9px" }} />
                  </span>
                  {transProps.feedback}
                </MaterialLink>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                {" "}
                <MaterialLink
                  className="border-none"
                  component={Link}
                  // onClick={toggleHelpModal}
                  aria-label="help"
                >
                  {" "}
                  <span>
                    <img src={help} alt="" style={{ paddingRight: "9px" }} />
                  </span>
                  {transProps.help}
                </MaterialLink>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

Footer.propTypes = {
  sendFeedback: PropTypes.func,
  administration: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    sendFeedback: (payload) => dispatch(sendFeedback(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
