import React, { useState, Fragment, useEffect, useCallback } from "react"; // , useEffect
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useForm, useFieldArray } from "react-hook-form"; //, Controller
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SignupForm from "./signup-form";
import getBase64 from "./getbase64";
import moment from "moment";
import { AUTH_API, headers } from "../../../config";
import axios from "axios";
import { ProgramSectionData } from "./data/program-section-format";
import { ReactComponent as FeatherIcon } from "../../../assets/images/feather.svg";
import {
  SET_USER,
  PUT_USER,
  GET_USER,
  EDIT_USER,
  SET_PROGRAM,
  GET_ORDER_OPTION,
  GET_MONTH_OPTION,
  GET_DAY_OPTION,
  clearRegistrationStatus,
} from "../../../redux/actionTypes/registration";
import SlideToggle from "react-slide-toggle";
import { mapStateToProps } from "../../../redux/reducers";
import RegistrationSidebar from "../../common/sidebar/registration-sidebar";
import {
  GET_LOC_TYPE,
  GET_LOC_TYPE_QUES,
} from "../../../redux/actionTypes/dropdown";
import sections from "./data/signup-section";
import { locTypeProgramQuestionsSections } from "./signup-actions";
import styled from "styled-components";
import { GET_COUNTYCITY } from "../../../redux/actionTypes";
import {
  sortName,
  isNumber,
  popUpAlert,
  maskMobile,
  checkToken,
  validateEmail,
} from "../../../utils/globalMethods";
import {
  getFarmersMarket,
  getFarmersProducts,
} from "../../../redux/actionTypes/administration";
import CommonLoading from "../../common/components/commonLoading";
import "../../../styles/registration.scss";
import { Button, Form, Stack } from "react-bootstrap";
import { Button as MUIButton } from "@mui/material";
import SelectDropdown from "../../common/selectdropdown-component";
import Tooltip from "@mui/material/Tooltip";
import { Info, Margin } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useMsal } from "@azure/msal-react";
import { authUserDetails } from "../../../redux/actionTypes/auth";
import SignaturePad from "../../../components/SignaturePad";
import ShareAddEditComponent from "../../mystore/tabs/shareAddEdit";
import PinDropIcon from "@mui/icons-material/PinDrop";

// Cookie
import { useCookies } from "react-cookie";
import ModalComponent from "../../common/components/modal";
import {
  addVendorShareList,
  editVendorShareList,
} from "../../../redux/actionTypes/csaSignup";
import SnapCSALocation from "../../mystore/tabs/snapCSALocation";
import ShareInfoTab from "../../mystore/tabs/share";
import { Grid, MenuItem, Select, FormControl } from "@mui/material";
import useTranslator from "../../../hooks/useTranslator";
import EditIcon from "@mui/icons-material/Edit";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import StoreIcon from "@mui/icons-material/Store";
import StorefrontIcon from "@mui/icons-material/Storefront";

const StyledImageUpload = styled.div`
  height: inherit;
  width: inherit;
`;

const StyledImage = styled.img`
  height: 180px;
  object-fit: contain;
  width: 200px;
`;

let language = localStorage.getItem("selectedLanguage");
if (language === null) {
  language = "en-US";
}

const locationTypeCollection = [
  {
    id: "csaPickupSite",
    value: "SPF Roadside Stand",
    icon: StoreIcon,
  },
  {
    id: "farmStand",
    value: "Farm Stand",
  },
  {
    id: "farmersMarketBooth",
    value: "Farmers Market Booth",
  },
  {
    id: "farmersMarket",
    value: "Farmers' Market",
    icon: StorefrontIcon,
  },
  {
    id: "mobileMarket",
    value: "Mobile Market Stop",
  },
];

function UserRegistrationComponent(props) {
  const transProps = useTranslator(
    [
      "registration",
      "locType",
      "publicInfo",
      "register",
      "regCnt",
      "regSpf",
      "needHelp",
      "needHelpSpf",
      "needHelpCnt",
      "basicRegistration",
      "firstName",
      "fnameReq",
      "lastName",
      "lnameReq",
      "routingNumber",
      "routinRequired",
      "routingDigits",
      "bankingAccNum",
      "bankingReq",
      "bankingDigit",
      "bankName",
      "bankNameReq",
      "bankType",
      "banktypeReq",
      "selectBankType",
      "savings",
      "checking",
      "federalId",
      "remove",
      "federalNumReq",
      "federalNumDigits",
      "primaryEmail",
      "primaryEmailReq",
      "invalidEmailAddress",
      "primaryPhoneNumber",
      "primaryPhoneNumRequired",
      "invalidPhoneNumber",
      "password",
      "passwordRequired",
      "confirmPassword",
      "confirmPasswordRequired",
      "passwordsNotMatch",
      "signatureDate",
      "signature",
      "registerAndNext",
      "merchantName",
      "merchantNameRequired",
      "publicEmailAddress",
      "publicPhone",
      "website",
      "invalidWebsite",
      "businessDescription",
      "maximumCharacter",
      "maxCharacter",
      "vendorPhoto",
      "notAvailable",
      "clickEdit",
      "fileShould",
      "fileFormat",
      "saveAndNext",
      "locationType",
      "SPFFarmersMarket",
      "SPFFarmersMarketBooth",
      "SPFProgram",
      "SPFFarmStand",
      "SPFMobileMarketStop",
      "yes",
      "no",
      "marketsList",
      "finish",
      "claremont",
      "downtown",
      "redondo",
      "burbank",
      "hollywood",
      "alhambra",
      "culver",
      "encino",
      "venice",
      "spfPickupSite",
      "pickupSiteName",
      "startDate",
      "endDate",
      "address1",
      "address2",
      "city",
      "state",
      "zipcode",
      "farmStand",
      "farmersMarketBooth",
      "farmerMarketName",
      "mobileMarket",
      "locationName",
      "farmersMarket",
      "specialInstr",
      "workingHours",
      "products",
      "monthOperations",
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
      "whatDetails",
      "AM",
      "PM",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
      "open",
      "close",
      "available",
      "allDay",
      "custom",
      "invalidZipcode",
      "day",
      "startTime",
      "endTime",
      "storeStatus",
      "closeMap",
      "showMap",
      "update",
      "fileUpload",
      "cancel",
      "location",
      "discard",
      "suggestedAddress",
      "originalAddress",
      "addressInformation",
    ],
    "userRegistrationComponent"
  );
  const { state } = useLocation();
  const [onEdit, setOnEdit] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState({
    file: null,
    base64URL: "",
  });
  const [selectedCSAImage, setSelectedCSAImage] = useState({
    file: null,
    base64URL: "",
  });
  const [locType, setLocType] = useState({
    csaPickupSite: false,
    farmStand: false,
    farmersMarketBooth: false,
    mobileMarket: false,
    farmersMarket: false,
  });
  const [isDisblOthrChkbx, setIsDisblOthrChkbx] = useState(false);
  const [isDisblMrkMngrChkbx, setIsDisblMrkMngrChkbx] = useState(false);
  const [enableMenuList, setEnableMenuList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [fnsNumber, setFnsNumber] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [validated] = useState(false);
  const userLogin = JSON.parse(sessionStorage.getItem("userLogin"));
  const [searchParams] = useSearchParams();
  let vendorId = searchParams.get("vendor_id") || userLogin?.vendorId;

  let createCSA = "";
  if (state?.vendorData) {
    createCSA = state.vendorData;
  }

  const { user } = props.userAuth;

  const [activeTab, setActiveTab] = useState("csa_reg");

  const [vendorType, setVendorType] = useState(
    createCSA
      ? { name: "CSAVDR", values: "CSA Vendor", isActive: 1 }
      : { name: "HIPVDR", values: "FEP Vendor", isActive: 1 }
  );
  const [disableVendorType, setDisableVendorType] = useState(false);
  const [enableLocationType, setEnableLocationType] = useState(false);
  const [enableShare, setEnableShare] = useState(false);
  const [csaBasicData, setCsaBasicData] = useState();

  const typeList = [
    { name: "CSAVDR", values: "CSA Vendor", isActive: 1 },
    { name: "HIPCSAVDR", values: "FEP and CSA Vendor", isActive: 1 },
  ];

  if (user?.Role === "ADMIN" || user?.Role === "HIPCRD") {
    typeList.unshift({ name: "HIPVDR", values: "FEP Vendor", isActive: 1 });
  }
  const navigate = useNavigate();

  const { instance } = useMsal();

  const [cookies, setCookie] = useCookies(["validation", "createCSA"]);

  let staticMenu = [
    {
      label: transProps.registration,
      className: "list-group-item menu-list",
      liClassName: "",
      id: "hip_reg",
      name: "hip_reg",
    },
    {
      label: transProps.publicInfo,
      className: "list-group-item menu-list",
      liClassName: "disabled",
      id: "hip_public_info",
      name: "hip_public_info",
    },
    {
      label: transProps.locType,
      className: "list-group-item menu-list disabled",
      id: "loc_type",
      name: "loc_type",

      liClassName: enableLocationType ? "" : "disabled",
      clickEvent: enableLocationType,
    },
  ];
  // aaaaa
  const [menuType, setMenuType] = useState(staticMenu);

  useEffect(() => {
    if (vendorType.name == "HIPVDR" || vendorType.name == "HIPCSAVDR") {
      setActiveTab("hip_reg");
    } else if (vendorType.name == "CSAVDR") {
      setActiveTab("csa_reg");
    }
  }, [vendorType]);

  useEffect(() => {
    if (vendorType.name == "HIPVDR") {
      setMenuType([
        {
          label: transProps.registration,
          className: "list-group-item menu-list",
          liClassName: "",
          id: "hip_reg",
          name: "hip_reg",
          icon: AppRegistrationIcon,
        },
        {
          label: transProps.publicInfo,
          className: "list-group-item menu-list",
          liClassName: "disabled",
          id: "hip_public_info",
          name: "hip_public_info",
          icon: LibraryBooksIcon,
        },
        {
          label: transProps.locType,
          className: "list-group-item menu-list disabled",
          id: "loc_type",
          name: "loc_type",
          liClassName: enableLocationType ? "" : "disabled",
          clickEvent: enableLocationType,
          icon: PinDropIcon,
        },

        // {
        //   label: "Share Information",
        //   className: "list-group-item menu-list",
        //   // liClassName: "disabled",
        //   id: "hip_share_info",
        //   name: "hip_share_info",
        // },
      ]);
    } else if (vendorType.name == "CSAVDR") {
      setMenuType([
        {
          label: transProps.registration,
          className: "list-group-item menu-list",
          liClassName: "",
          id: "csa_reg",
          name: "csa_reg",
        },
        {
          label: transProps.publicInfo,
          className: "list-group-item menu-list",
          liClassName: "disabled",
          id: "csa_public_info",
          name: "csa_public_info",
        },
      ]);
    } else if (vendorType.name == "HIPCSAVDR") {
      setMenuType([
        {
          label: transProps.registration,
          className: "list-group-item menu-list",
          liClassName: "",
          id: "hip_reg",
          name: "hip_reg",
        },
        {
          label: transProps.publicInfo,
          className: "list-group-item menu-list",
          liClassName: "disabled",
          id: "hip_public_info",
          name: "hip_public_info",
        },
        {
          label: transProps.registration,
          className: "list-group-item menu-list",
          liClassName: "",
          id: "csa_reg",
          name: "csa_reg",
        },
        {
          label: transProps.publicInfo,
          className: "list-group-item menu-list",
          liClassName: "disabled",
          id: "csa_public_info",
          name: "csa_public_info",
        },
        {
          label: transProps.locType,
          className: "list-group-item menu-list disabled",
          id: "loc_type",
          name: "loc_type",
          liClassName: enableLocationType ? "" : "disabled",
          clickEvent: enableLocationType,
        },
      ]);
    }
  }, [vendorType, enableShare, enableLocationType, activeTab]);

  useEffect(() => {
    setCookie("validation", true);
    setCookie("createCSA", createCSA);
    props.getCityList();
    const payload = {
      name: "",
      size: 20,
      from: 0,
    };
    // props.getFarmersMarket(payload);
    // props.getFarmersProducts({});
    props.locTypeProgramQuestionsSections(sections);
  }, []);

  useEffect(() => {
    if (
      props.user.registrationResponse &&
      props.user.registrationResponse.status === "success"
    ) {
      setDisableVendorType(true);
      if (activeTab == "hip_reg") {
        setActiveTab("hip_public_info");
      } else if (activeTab == "csa_reg") {
        setActiveTab("csa_public_info");
      }
      setIsLoading(false);
      props.clearRegistrationStatus();
      popUpAlert({
        title: "Successful",
        text: "Basic Registration completed successfully!",
        icon: "success",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    } else if (
      props.user.registrationResponse &&
      props.user.registrationResponse.status === "error"
    ) {
      setIsLoading(false);
      popUpAlert({
        title: "Failed!",
        text: props.user.registrationResponse.message,
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Ok!",
        closeOnConfirm: false,
      });
      props.clearRegistrationStatus();
    }
  }, [props.user.registrationResponse]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      firstName: "",
      lastName: "",
      vendorName: "",
      primaryEmail: "",
      primaryMobile: "",
      password: "",
      confirm_password: "",
      businessDesc: "",
      fnsNum: [{ id: 0, name: "" }],
      communicationEmail: [{ id: 0, email: "" }],
      products: "",
      publicEmail: "",
      publicMobile: "",
      publicWebsite: "",
      additionalContactInfo: "",
      publicPhoto: "",
      bankName: "",
      bankType: "",
      accountNo: "",
      routingNo: "",
    },
  });
  // eslint-disable-next-line no-unused-vars
  const basicForm = watch();

  let initial = {
    firstName: createCSA ? createCSA?.idTokenClaims?.given_name : "",
    lastName: createCSA ? createCSA?.idTokenClaims?.family_name : "",
    vendorName: "",
    primaryEmail: createCSA ? createCSA?.idTokenClaims?.email : "",
    primaryMobile: "",
    password: "",
    confirm_password: "",
    businessDesc: "",
    csaFnsNum: [{ id: 0, name: "" }],
    csaCommunicationEmail: [{ id: 0, email: "" }],
    products: "",
    publicEmail: "",
    publicMobile: "",
    publicWebsite: "",
    friendlyURL: "",
    additionalContactInfo: "",
    publicPhoto: "",
  };
  const [formValue, setFormValue] = useState(initial);
  const [isSameAsHipInfo, setIsSameAsHipInfo] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    control: control3,
    watch: watch3,
    reset: reset3,
    formState: { errors: errors3 },
  } = useForm({
    defaultValues: formValue,
  });

  // eslint-disable-next-line no-unused-vars
  const csaBasicForm = watch3();

  // eslint-disable-next-line no-unused-vars
  const {
    register: register4,
    handleSubmit: handleSubmit4,
    watch: watch4,
    clearErrors: clearErrors4,
    formState: { errors: errors4 },
  } = useForm({
    defaultValues: formValue,
  });

  // eslint-disable-next-line no-unused-vars
  const csaPublicForm = watch4();

  // eslint-disable-next-line no-unused-vars
  const { register: register2, getValues } = useForm({
    defaultValues: locType,
  });

  const [primaryMobile, setPrimaryMobile] = useState("");
  const [publicMobile, setPublicMobile] = useState("");
  const [primaryCSAMobile, setPrimaryCSAMobile] = useState("");
  const [publicCSAMobile, setPublicCSAMobile] = useState("");
  const [openSignatureModal, setOpenSignatureModal] = useState(false);
  const [signature, setSignature] = useState(null);
  const {
    fields: fnsFields,
    append: appendFns,
    remove: removeFns,
  } = useFieldArray({
    control,
    name: "fnsNum",
  });

  const { fields: csaFnsFields, remove: removeCSAFns } = useFieldArray({
    control: control3,
    name: "csaFnsNum",
  });

  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: "communicationEmail",
  });

  const {
    fields: csaEmailFields,
    append: appendCSAEmail,
    remove: removeCSAEmail,
  } = useFieldArray({
    control: control3,
    name: "csaCommunicationEmail",
  });

  const watchFieldArray = watch("fnsNum");
  const controlledFields = fnsFields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const watchCSAFieldArray = watch3("csaFnsNum");
  const controlledCSAFields = csaFnsFields.map((field, index) => {
    return {
      ...field,
      ...watchCSAFieldArray[index],
    };
  });

  const EmailFieldArray = watch("communicationEmail");
  const controlledFieldsEmail = emailFields.map((emailfield, index) => {
    return {
      ...emailfield,
      ...EmailFieldArray[index],
    };
  });

  const csaEmailFieldArray = watch3("csaCommunicationEmail");
  const controlledFieldsCSAEmail = csaEmailFields.map((emailfield, index) => {
    return {
      ...emailfield,
      ...csaEmailFieldArray[index],
    };
  });

  const updateVendorCallback = (data = {}) => {
    if (data.vendorType == "HIPVDR" && data.isHipAndCsaVdr) {
      setActiveTab("csa_reg");
    } else if (
      (data.vendorType == "CSAVDR" && data.isHipAndCsaVdr) ||
      (data.vendorType == "HIPVDR" && !data.isHipAndCsaVdr)
    ) {
      setActiveTab("loc_type");
      // setActiveTab("hip_share_info");
      // setEnableLocationType(true);
      setCookie("validation", false);
    } else if (data.vendorType == "CSAVDR" && !data.isHipAndCsaVdr) {
      handleOnFinish();
      setCookie("validation", false);
    }
  };

  const onHipBasicSubmit = (data) => {
    // setIsLoading(true);
    setFormValue(data);
    //data.vendorName = data.firstName + " "+ data.lastName;
    if (data.fnsNum && data.fnsNum.length > 0) {
      data.fnsNum = data.fnsNum.map((item) => item.name).toString();
    }
    if (data.communicationEmail && data.communicationEmail.length > 0) {
      const commEmails = [
        ...new Set(data.communicationEmail.map((item) => item.name)),
      ];
      data.communicationEmail = commEmails.toString();
    }
    data.csaFnsNum = "";
    data.csaCommunicationEmail = "";
    if (data.primaryMobile) {
      data.primaryMobile = data.primaryMobile.replace(/\D/g, "");
    }
    // data.password = "Welcome123";
    // data.confirm_password = "Welcome123";
    // data.confirm_password = data.password;
    data.vendorType = "HIPVDR";
    data.isHipAndCsaVdr = vendorType.name === "HIPCSAVDR" ? true : false;
    const timeStamp = new Date().getTime();
    data = Object.assign(data, {
      role: "VENDOR",
      isVerified: "N",
      isActive: "N",
      forceLogin: "N",
      createTimeStamp: timeStamp,
      lastUpdtTimeStamp: timeStamp,
      submitForm: "Vendor",
    });
    props.addUser(data);
    window.scrollTo(0, 0);
  };

  const onHipPublicInfoSubmit = (data) => {
    if (data.fnsNum && data.fnsNum.length > 0) {
      data.fnsNum = data.fnsNum.map((item) => item.name).toString();
    }
    if (data.communicationEmail && data.communicationEmail.length > 0) {
      const commEmails = [
        ...new Set(data.communicationEmail.map((item) => item.name)),
      ];
      data.communicationEmail = commEmails.toString();
    }
    data.csaFnsNum = "";
    data.csaCommunicationEmail = "";
    // data.password = "Welcome123";
    // data.confirm_password = "Welcome123";
    // data.confirm_password = data.password;
    const userData = props.user.userinfo;
    let lastUpdtUidNam = "VDR_" + userData.userId;
    data.vendorType = "HIPVDR";
    data.isHipAndCsaVdr = vendorType.name === "HIPCSAVDR" ? true : false;
    const timeStamp = new Date().getTime();
    const systemInfo = sessionStorage.getItem("systemInfo");
    const systemData = JSON.parse(systemInfo);
    data = Object.assign(data, {
      role: "VENDOR",
      isVerified: "N",
      isActive: "N",
      forceLogin: "N",
      userId: userData.userId,
      id: userData.id,
      primaryMobile: data.primaryMobile.replace(/\D/g, ""),
      publicMobile: data.publicMobile.replace(/\D/g, ""),
      lastUpdtUidNam: lastUpdtUidNam,
      createTimeStamp: timeStamp,
      lastUpdtTimeStamp: timeStamp,
      loginId: userData.userId,
      ipAddress: systemData.ip,
      submitForm: "Public",
    });
    data.publicPhoto = selectedImage.base64URL ? selectedImage.base64URL : "";
    props.updateUser(data, updateVendorCallback);
    // setEnableLocationType(true);
    // setActiveTab("hip_share_info");
    setEnableShare(true);
    window.scrollTo(0, 0);
  };

  const onCsaBasicSubmit = (data) => {
    if (data.csaFnsNum && data.csaFnsNum.length > 0) {
      data.csaFnsNum = data.csaFnsNum.map((item) => item.name).toString();
    }
    if (data.csaCommunicationEmail && data.csaCommunicationEmail.length > 0) {
      const csaCommEmails = [
        ...new Set(data.csaCommunicationEmail.map((item) => item.name)),
      ];
      data.csaCommunicationEmail = csaCommEmails.toString();
    }

    data.fnsNum = data.csaFnsNum;
    data.communicationEmail = data.csaCommunicationEmail;
    if (data.primaryMobile) {
      data.primaryMobile = data.primaryMobile.replace(/\D/g, "");
    }
    // data.password = "testing123";
    // data.confirm_password = "testing123";
    data.vendorType = "CSAVDR";
    data.isHipAndCsaVdr = vendorType.name === "HIPCSAVDR" ? true : false;
    const timeStamp = new Date().getTime();
    data = Object.assign(data, {
      role: "VENDOR",
      isVerified: "N",
      isActive: "N",
      forceLogin: "N",
      createTimeStamp: timeStamp,
      lastUpdtTimeStamp: timeStamp,
      submitForm: "Vendor",
    });
    const userData = props.user.userinfo;
    if (Object.keys(userData).length) {
      data["vendorId"] = userData.id;
    }
    setCsaBasicData(data);
    props.addUser(data);
    window.scrollTo(0, 0);
  };

  const onCsaPublicInfoSubmit = (csaData) => {
    let data = {
      ...csaData,
      ...csaBasicData,
    };
    if (data.csaCommunicationEmail != "") {
      data.communicationEmail = data.csaCommunicationEmail;
    }
    data.fnsNum = data.csaFnsNum;
    // data.password = "testing123";
    // data.confirm_password = "testing123";
    data.vendorType = "CSAVDR";
    data.isHipAndCsaVdr = vendorType.name === "HIPCSAVDR" ? true : false;
    const userData = props.user.userinfo;
    let lastUpdtUidNam = "VDR_" + userData.userId;
    const timeStamp = new Date().getTime();
    const systemInfo = sessionStorage.getItem("systemInfo");
    const systemData = JSON.parse(systemInfo);
    data = Object.assign(data, {
      role: "VENDOR",
      isVerified: "N",
      isActive: "N",
      forceLogin: "N",
      userId: userData.userId,
      id: userData.id,
      primaryMobile: data.primaryMobile.replace(/\D/g, ""),
      publicMobile: data.publicMobile.replace(/\D/g, ""),
      lastUpdtUidNam: lastUpdtUidNam,
      createTimeStamp: timeStamp,
      lastUpdtTimeStamp: timeStamp,
      loginId: userData.userId,
      ipAddress: systemData.ip,
      submitForm: "Public",
      type: "add",
    });
    data.publicEmail = csaData.publicEmail;
    data.publicMobile = csaData.publicMobile;
    data.publicWebsite = csaData.publicWebsite;
    // data.products = csaData.products;
    data.publicPhoto = selectedCSAImage.base64URL
      ? selectedCSAImage.base64URL
      : "";
    props.updateUser(data, updateVendorCallback);
    if (vendorType.name == "HIPCSAVDR") {
      setActiveTab("loc_type");
    }
    window.scrollTo(0, 0);
  };
  //
  const onSaveAndNext = (tab) => {
    setActiveTab(tab);
    // setActiveTab("hip_share_info");
    setEnableShare(true);
    setEnableLocationType(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleOnFinish = () => {
    if (createCSA) {
      const payload = {
        email: sessionStorage.getItem("username"),
        navigate: navigate,
        ext_url: "csa",
        currentAccount: instance.getActiveAccount(),
      };
      props.authUserDetails(payload);
    } else if (checkToken()) {
      navigate("/kfreshfep/manage-vendors");
    } else {
      navigate("/kfreshfep");
    }
  };

  const [addMore, setAddMore] = useState(1);
  const handleAddFields = () => {
    setAddMore(addMore + 1);
    appendFns({ name: "" });
  };

  const [communicationEmail, setCommunicationEmail] = useState(1);
  const handleEmailAddFields = () => {
    setCommunicationEmail(communicationEmail + 1);
    appendEmail({ email: "" });
  };

  const [addCSAFns, setAddCSAFns] = useState(1);

  const [communicateCSAEmail, setCommunicateCSAEmail] = useState(1);
  const handleCSAEmailAddFields = () => {
    setCommunicateCSAEmail(communicateCSAEmail + 1);
    appendCSAEmail({ email: "" });
  };

  const handleRemove = (i) => {
    setAddMore(addMore - 1);
    removeFns(i);
  };
  const handleEmailRemove = (j) => {
    setCommunicationEmail(communicationEmail - 1);
    removeEmail(j);
  };

  const handleCSAFnsRemove = (i) => {
    setAddCSAFns(addCSAFns - 1);
    removeCSAFns(i);
  };
  const handleCSAEmailRemove = (j) => {
    setCommunicateCSAEmail(communicateCSAEmail - 1);
    removeCSAEmail(j);
  };

  const getLocType = (e) => {
    let tabsExist = -1;
    let newEnableMenuList = [];
    if (e.target.value === "true") {
      enableMenuList.map((item, i) => {
        if (item.id == e.target.name) {
          tabsExist = i;
        }
      });
      if (tabsExist == -1) {
        let index = locationTypeCollection.findIndex(function (val) {
          return val.id == e.target.name;
        });

        newEnableMenuList = [
          ...enableMenuList.slice(0, index),
          {
            label: locationTypeCollection[index].value,
            className: "list-group-item menu-list",
            liClassName: "",
            id: e.target.name,
            name: e.target.name,
            clickEvent: true,
            nextEvent: e.target.name,
            icon: locationTypeCollection[index].icon,
          },
          ...enableMenuList.slice(index),
        ];
      } else {
        newEnableMenuList = enableMenuList;
      }
    } else if (e.target.value === "false") {
      enableMenuList.map((item, i) => {
        if (item.id == e.target.name) {
          tabsExist = i;
        }
      });
      if (tabsExist != -1) {
        enableMenuList.splice(tabsExist, 1);
      }
      newEnableMenuList = enableMenuList;
    }
    newEnableMenuList.map((item, i) => {
      if (i == newEnableMenuList.length - 1) {
        item.nextEvent = false;
      } else {
        item.nextEvent = newEnableMenuList[i + 1].id;
      }
    });
    setEnableMenuList(newEnableMenuList);

    const values = getValues();
    values[e.target.name] =
      e.target.value === "true" || e.target.value === true ? true : false;
    setLocType(values);
    if (e.target.name === "farmersMarket") {
      if (e.target.value == "true") {
        setIsDisblOthrChkbx(true);
        setIsDisblMrkMngrChkbx(false);
      } else {
        setIsDisblOthrChkbx(false);
        setIsDisblMrkMngrChkbx(false);
      }
    } else {
      const menuItem = Object.entries(values)
        .filter(([, v]) => v === true || v === "true")
        .map(([k]) => k);
      if (menuItem.length > 0 && menuItem.indexOf("farmersMarket") === -1) {
        setIsDisblMrkMngrChkbx(true);
      } else {
        setIsDisblMrkMngrChkbx(false);
      }
      if (e.target.value === true || e.target.value === "true") {
        setIsDisblMrkMngrChkbx(true);
      }
    }
  };

  const getSelectedSection = (id) => {
    const section = sections.find((item) => item.id === id);
    return section;
  };

  const handleFileInputChange = useCallback(
    (e) => {
      let { file } = selectedImage;

      if (e.target.files.length) {
        file = e.target.files[0];
        getBase64(file)
          .then((result) => {
            file["base64"] = result;
            setSelectedImage({
              base64URL: result,
              file,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    [selectedImage]
  );

  const handleRemoveImage = () => {
    setSelectedImage({ file: null, base64URL: "" });
    document.getElementById("infoPhoto").value = "";
  };

  const handleSubmitShareAddEdit = (payload) => {
    if (Object.keys(onEdit).length !== 0) {
      editVendorShareList(payload);
    } else {
      addVendorShareList(payload);
    }
    setShowModal(false);
    setOnEdit({});
  };

  const renderModal = () => {
    return (
      <ShareAddEditComponent
        validated={validated}
        submitShareData={handleSubmitShareAddEdit}
        onEdit={Object.keys(onEdit).length !== 0}
        row={onEdit}
        vendorId={vendorId}
      />
    );
  };

  const handleCSAFileInputChange = useCallback(
    (e) => {
      let { file } = selectedCSAImage;

      if (e.target.files.length) {
        file = e.target.files[0];
        getBase64(file)
          .then((result) => {
            file["base64"] = result;
            setSelectedCSAImage({
              base64URL: result,
              file,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    [selectedCSAImage]
  );

  const handleRemoveCSAImage = () => {
    setSelectedCSAImage({ file: null, base64URL: "" });
    document.getElementById("infoCSAPhoto").value = "";
  };

  const updateStandType = async () => {
    const userData = props.user.userinfo;
    const locationType = "RS";
    try {
      const url = `${AUTH_API}/public/update/locationType/${userData.id}/${locationType}`;
      const res = await axios.post(
        url,
        {},
        {
          headers: headers,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const addProgramData = async (data) => {
    // updateStandType();
    // props.addProgram({ vendorStores: data });
    // setIsLoading(true);
    try {
      await updateStandType();
      props.addProgram({ vendorStores: data });
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    if (props?.user?.program && props?.user?.program?.code === "200") {
      setIsLoading(false);
    }
  }, [props?.user]);

  const cityList = sortName(
    props.mapdata.city
      .filter((item) => !item.cityName.includes("County"))
      .map((item) => ({
        name: item.cityName,
        values: item.cityName,
      }))
  );

  const validateData = () => {
    const formData1 = watch();
    const formData3 = watch3();
    if (
      formData1.firstName != "" ||
      formData1.lastName != "" ||
      // formData1.vendorName != "" ||
      formData1.fnsNum[0].name != "" ||
      formData1.primaryEmail != "" ||
      formData1.primaryMobile != "" ||
      formData3.firstName != "" ||
      formData3.lastName != "" ||
      // formData3.vendorName != "" ||
      formData3.csaFnsNum[0].name != "" ||
      formData3.primaryEmail != "" ||
      formData3.primaryMobile != ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  window.onbeforeunload = () => {
    if (
      validateData() &&
      cookies.validation &&
      window.location.pathname.includes("registration")
    ) {
      return "Do you really want to leave this page?";
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const toggleSignature = async (signatureImage) => {
    setSignature(signatureImage);
    setTimeout(() => {
      setOpenSignatureModal(false);
    }, 500);
    // if (!signatureImage.target) {
    //   const payLoad = {
    //     dirtyStatus: getSign ? "UPDATE" : "INSERT",
    //     clientId: parseInt(_clientId),
    //     signatoryName: "Murali",
    //     signSource: "Verified",
    //     signBytes: signatureImage,
    //     deleteInd: "N",
    //   };
    //   try {
    //     const headers = {
    //       "Content-Type": "application/json",
    //     };
    //     const res = await axios.post(
    //       AUTH_API + "/farmers/signature/save",
    //       payLoad,
    //       {
    //         headers: headers,
    //       }
    //     );
    //     if (res && res?.data) {
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     setOpenSignatureModal(false);
    //   }
    // }
  };

  const marketsList = [
    "claremont",
    "downtown",
    "redondo",
    "burbank",
    "hollywood",
    "alhambra",
    "culver",
    "encino",
    "venice",
  ];

  const [native, setNative] = useState("");
  const onNativeChange = (e) => {
    setNative(e.target.value);
  };
  // var curr = new Date();
  // curr.setDate(curr.getDate());
  // var datem = curr.toISOString().substring(0, 10);
  // console.log('datem', datem);

  var curr = moment();
  var datem = curr.format("MM-DD-YYYY");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid ven-reg-pg">
      {/* {isLoading && <CommonLoading />} */}
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <h3 className="resText">{transProps.register}</h3>
              <SlideToggle
                duration={600}
                collapsed={true}
                render={({ toggle, setCollapsibleElement, progress }) => (
                  <>
                    <p>
                      {!(vendorType.name == "HIPVDR")
                        ? transProps.regSpf
                        : transProps.regCnt}
                      <span
                        className="moreless-button text-decoration-underline"
                        onClick={toggle}
                      >
                        {transProps.needHelp}
                      </span>
                    </p>
                    <div
                      style={{ display: "none" }}
                      ref={setCollapsibleElement}
                    >
                      <div
                        className="alert alert-success moretext"
                        style={{
                          transform: `translateY(${Math.round(
                            20 * (-1 + progress)
                          )}px)`,
                        }}
                      >
                        <i className="fa fa-info-circle"></i>
                        &nbsp;
                        {!(vendorType.name == "HIPVDR")
                          ? transProps.needHelpSpf
                          : transProps.needHelpCnt}
                      </div>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
          {/* {(createCSA || user?.Role === "ADMIN" || user?.Role === "HIPCRD") && (
            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-3">
                <p
                  style={{
                    fontFamily: "texta-bold",
                    fontSize: "22px",
                    textAlign: "center",
                  }}
                >
                  Choose the Registration type
                </p>
              </div>
              <div className="col-3">
                <Stack direction="row" spacing={0.5}>
                  <SelectDropdown
                    value={vendorType}
                    onChange={(v) => {
                      setVendorType(v);
                    }}
                    disabled={disableVendorType}
                    options={typeList}
                    isSearch={true}
                    defaultValueProps={{
                      default: true,
                      defaultOption: {
                        name: "CSAVDR",
                        values: "CSA Vendor",
                        isActive: 1,
                      },
                    }}
                  />
                </Stack>
              </div>
            </div>
          )} */}

          <div style={{ display: "flex" }}>
            <RegistrationSidebar
              staticMenu={menuType}
              enableMenuList={enableMenuList}
              activeTab={activeTab}
              onMenuClick={(v) => {
                setActiveTab(v);
              }}
            />
            <div className="col-md-10">
              <>
                <section
                  className={` dh-reg-step-1 mt-3 mt-sm-0 mt-md-0 mt-lg-0 ${
                    activeTab === "hip_reg" ? "" : "hide"
                  }`}
                  id="reg-hip_reg"
                >
                  {/* Reg */}
                  <form onSubmit={handleSubmit(onHipBasicSubmit)}>
                    <div className="dh-head">
                      <div className="row">
                        <div className="col-md-12">
                          <h4> {transProps.basicRegistration}</h4>
                        </div>
                      </div>
                    </div>

                    <div className="dh-content">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="regValidationFdbk"></div>

                          <div className="dh-box-mr">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="firstName"
                                  >
                                    {transProps.firstName} *
                                  </label>
                                  <input
                                    {...register("firstName", {
                                      required: transProps.fnameReq,
                                      validate: (value) => {
                                        if (value.trim() === "") {
                                          return "First name must contain characters";
                                        }
                                        return true;
                                      },
                                    })}
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors.firstName ? "dh-error-box" : ""
                                    }`}
                                    placeholder=""
                                  />
                                  {errors.firstName && (
                                    <span className="dh-txt-danger">
                                      {errors.firstName.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="lastName"
                                  >
                                    {transProps.lastName} *
                                  </label>
                                  <input
                                    {...register("lastName", {
                                      required: transProps.lnameReq,
                                      validate: (value) => {
                                        if (value.trim() === "") {
                                          return "Last name must contain characters";
                                        }
                                        return true;
                                      },
                                    })}
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors.lastName ? "dh-error-box" : ""
                                    }`}
                                    placeholder=""
                                  />
                                  {errors.lastName && (
                                    <span className="dh-txt-danger">
                                      {errors.lastName.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="farmVendorName"
                                  >
                                    Farm / Vendor Name *
                                  </label>
                                  <input
                                    {...register("vendorName", {
                                      required:
                                        "Farm / Vendor name is required",
                                      validate: (value) => {
                                        if (value.trim() === "") {
                                          return "Farm / Vendor name must contain characters";
                                        }
                                        return true;
                                      },
                                    })}
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors.vendorName ? "dh-error-box" : ""
                                    }`}
                                    placeholder=""
                                  />
                                  {errors.vendorName && (
                                    <span className="dh-txt-danger">
                                      {errors.vendorName.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6" id="fnsNumbersList">
                                {controlledFields.map((item, i) => {
                                  return (
                                    <Fragment key={item.id}>
                                      <div
                                        className="col-md-12"
                                        id={`fnsNum.${i}`}
                                      >
                                        <div className="form-group">
                                          <label
                                            className="dh-form-label dh-title-lb"
                                            htmlFor={`fnsNum.${i}`}
                                          >
                                            Federal Id *
                                            {i > 0 ? (
                                              <span
                                                className={
                                                  "text-decoration-underline cursor-pointer green dh-remove"
                                                }
                                                onClick={() => handleRemove(i)}
                                              >
                                                <i className="fa fa-trash-o"></i>{" "}
                                                Remove
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </label>

                                          <input
                                            {...register(`fnsNum.${i}.name`, {
                                              required:
                                                "Federal Number is required",
                                              pattern: {
                                                value: /^(\d{7})$/,
                                                message:
                                                  "Federal Number should be 7 digits",
                                              },
                                            })}
                                            maxLength={7}
                                            onKeyDown={isNumber}
                                            type="text"
                                            className={`form-control form-control-lg fns-number-input dh-input-box required ${
                                              errors.fnsNum?.[i]?.name
                                                ? "dh-error-box"
                                                : ""
                                            }`}
                                          />
                                          {errors.fnsNum?.[i]?.name && (
                                            <span className="dh-txt-danger">
                                              {errors.fnsNum[i].name.message}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                              </div>
                            </div> */}

                            {/* {addMore < 10 && (
                              <div
                                className="form-text text-muted dh-short-title"
                                id="addMoreFNSNumber"
                              >
                                <span>
                                  Do you have an additional FNS number?{" "}
                                </span>
                              </div>
                            )} */}

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="routingNo"
                                  >
                                    {transProps.routingNumber} *
                                  </label>
                                  <input
                                    {...register("routingNo", {
                                      required: transProps.routinRequired,
                                      pattern: {
                                        value: /^(\d{9})$/,
                                        message: transProps.routingDigits,
                                      },
                                    })}
                                    maxLength={9}
                                    onKeyDown={isNumber}
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors.routingNo ? "dh-error-box" : ""
                                    }`}
                                    id="routingNo"
                                    aria-label="routingNo"
                                    aria-labelledby="routingNo"
                                    aria-describedby="routingNo"
                                    required=""
                                  />
                                  {errors.routingNo && (
                                    <span className="dh-txt-danger">
                                      {errors.routingNo.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="accountNo"
                                  >
                                    {transProps.bankingAccNum} *
                                  </label>
                                  <input
                                    {...register("accountNo", {
                                      required: transProps.bankingReq,
                                      pattern: {
                                        value: /^(\d{11})$/,
                                        message: transProps.bankingDigit,
                                      },
                                    })}
                                    maxLength={11}
                                    onKeyDown={isNumber}
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors.accountNo ? "dh-error-box" : ""
                                    }`}
                                    id="accountNo"
                                    aria-label="accountNo"
                                    aria-labelledby="accountNo"
                                    aria-describedby="accountNo"
                                    required=""
                                  />
                                  {errors.accountNo && (
                                    <span className="dh-txt-danger">
                                      {errors.accountNo.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="bankName"
                                  >
                                    {transProps.bankName} *
                                  </label>
                                  <input
                                    {...register("bankName", {
                                      required: transProps.bankNameReq,
                                    })}
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors.bankName ? "dh-error-box" : ""
                                    }`}
                                    id="bankName"
                                    aria-label="bankName"
                                    aria-labelledby="bankName"
                                    aria-describedby="bankName"
                                    required=""
                                  />
                                  {errors.bankName && (
                                    <span className="dh-txt-danger">
                                      {errors.bankName.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="bankType"
                                  >
                                    {transProps.bankType} *
                                  </label>
                                  <select
                                    {...register("bankType", {
                                      required: transProps.banktypeReq,
                                    })}
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors.bankType ? "dh-error-box" : ""
                                    }`}
                                    id="bankType"
                                    aria-label="bankType"
                                    aria-labelledby="bankType"
                                    aria-describedby="bankType"
                                    required=""
                                  >
                                    <option value="">
                                      {transProps.selectBankType}
                                    </option>
                                    <option value="savings">
                                      {transProps.savings}
                                    </option>
                                    <option value="checking">
                                      {transProps.checking}
                                    </option>
                                  </select>
                                  {errors.bankType && (
                                    <span className="dh-txt-danger">
                                      {errors.bankType.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* Email */}
                            <div style={{ display: "flex" }} className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="vendorName"
                                  >
                                    Merchant Name *
                                  </label>
                                  <input
                                    {...register("vendorName", {
                                      required: "Merchant Name is required",
                                    })}
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors.vendorName ? "dh-error-box" : ""
                                    }`}
                                    id="vendorName"
                                    aria-label="vendorName"
                                    aria-labelledby="vendorName"
                                    aria-describedby="vendorName"
                                    required=""
                                  />
                                  {errors.vendorName && (
                                    <span className="dh-txt-danger">
                                      {errors.vendorName.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6" id="fnsNumbersList">
                                {controlledFields.map((item, i) => {
                                  return (
                                    <Fragment key={item.id}>
                                      <div
                                        className="col-md-12"
                                        id={`fnsNum.${i}`}
                                      >
                                        <div className="form-group">
                                          <label
                                            className="dh-form-label dh-title-lb"
                                            htmlFor={`fnsNum.${i}`}
                                          >
                                            {transProps.federalId} *
                                            {i > 0 ? (
                                              <span
                                                className={
                                                  "text-decoration-underline cursor-pointer green dh-remove"
                                                }
                                                onClick={() => handleRemove(i)}
                                              >
                                                <i className="fa fa-trash-o"></i>{" "}
                                                {transProps.remove}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </label>

                                          <input
                                            {...register(`fnsNum.${i}.name`, {
                                              required:
                                                transProps.federalNumReq,
                                              pattern: {
                                                value: /^(\d{9})$/,
                                                message:
                                                  transProps.federalNumDigits,
                                              },
                                            })}
                                            maxLength={9}
                                            onKeyDown={isNumber}
                                            type="text"
                                            className={`form-control form-control-lg fns-number-input dh-input-box required ${
                                              errors.fnsNum?.[i]?.name
                                                ? "dh-error-box"
                                                : ""
                                            }`}
                                          />
                                          {errors.fnsNum?.[i]?.name && (
                                            <span className="dh-txt-danger">
                                              {errors.fnsNum[i].name.message}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                              </div>
                              {/* <div className="col-md-6" id="Email List">
                                {controlledFieldsEmail.map((item, i) => {
                                  return (
                                    <Fragment key={item.id}>
                                      <div
                                        className="col-md-12"
                                        id={`communicationEmail.${i}`}
                                      >
                                        <label
                                          className="dh-form-label dh-title-lb"
                                          htmlFor={`communicationEmail.${i}`}
                                        >
                                          Communication Email Address
                                          {i > 0 ? (
                                            <span
                                              key={i}
                                              className={
                                                "text-decoration-underline cursor-pointer dh-remove ml-2 green"
                                              }
                                              onClick={() => {
                                                handleEmailRemove(i);
                                              }}
                                            >
                                              <i className="fa fa-trash-o"></i>{" "}
                                              Remove
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </label>
                                        <input
                                          {...register(
                                            `communicationEmail.${i}.name`,
                                            {
                                              pattern: {
                                                value:
                                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message:
                                                  "Invalid Communication Email address",
                                              },
                                            }
                                          )}
                                          type="email"
                                          maxLength="100"
                                          className={`form-control form-control-lg required dh-input-box lowercase ${
                                            errors.communicationEmail
                                              ? "dh-error-box"
                                              : ""
                                          }`}
                                          id="communicationEmailID"
                                        />
                                        {errors.communicationEmail?.[i]
                                          ?.name && (
                                          <span className="dh-txt-danger">
                                            {
                                              errors.communicationEmail[i].name
                                                .message
                                            }
                                          </span>
                                        )}
                                      </div>
                                    </Fragment>
                                  );
                                })}
                              </div> */}
                            </div>

                            {/* {communicationEmail < 10 && (
                              <div
                                className="form-text text-muted dh-short-title"
                                id="addMoreEmailAddress"
                              >
                                <span>Do you have an additional Email? </span>
                              </div>
                            )} */}

                            <div className="row">
                              <div className="col-md-6 form-group">
                                <label className="dh-form-label dh-title-lb">
                                  {transProps.primaryEmail} *
                                </label>
                                <input
                                  {...register("primaryEmail", {
                                    required: transProps.primaryEmailReq,
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: transProps.invalidEmailAddress,
                                    },
                                    validate: validateEmail,
                                  })}
                                  type="email"
                                  maxLength="100"
                                  className={`form-control form-control-lg required dh-input-box lowercase ${
                                    errors.primaryEmail ? "dh-error-box" : ""
                                  }`}
                                  id="primaryEmailID"
                                />
                                {errors.primaryEmail && (
                                  <span className="dh-txt-danger">
                                    {errors.primaryEmail.message}
                                  </span>
                                )}
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="primaryPhoneNumber"
                                  >
                                    {transProps.primaryPhoneNumber} *
                                  </label>
                                  <input
                                    {...register("primaryMobile", {
                                      required:
                                        transProps.primaryPhoneNumRequired,
                                      pattern: {
                                        value:
                                          /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                                        message: transProps.invalidPhoneNumber,
                                      },
                                    })}
                                    type="tel"
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors.primaryMobile ? "dh-error-box" : ""
                                    }`}
                                    value={primaryMobile}
                                    onChange={(e) => {
                                      setPrimaryMobile(
                                        maskMobile(e.target.value)
                                      );
                                    }}
                                    placeholder="(___) ___-____"
                                    id="primaryPhoneNumber"
                                    aria-label="primaryPhoneNumber"
                                    aria-labelledby="primaryPhoneNumber"
                                    aria-describedby="primaryPhoneNumber"
                                    required=""
                                  />
                                  {errors.primaryMobile && (
                                    <span className="dh-txt-danger">
                                      {errors.primaryMobile.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div
                                  className="form-group"
                                  style={{ position: "relative" }}
                                >
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="password"
                                  >
                                    {transProps.password} *
                                  </label>
                                  <input
                                    {...register("password", {
                                      required: transProps.passwordRequired,
                                    })}
                                    type={isPasswordShown ? "text" : "password"}
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors.password ? "dh-error-box" : ""
                                    }`}
                                    id="password"
                                    autoComplete="off"
                                  />
                                  <i
                                    onClick={togglePasswordVisibility}
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "60px",
                                      // top: "50%",
                                      transform: "translateY(-50%)",
                                      cursor: "pointer",
                                      zIndex: 2,
                                    }}
                                    className={
                                      isPasswordShown
                                        ? "fa fa-eye-slash"
                                        : "fa fa-eye"
                                    }
                                    aria-hidden="true"
                                  ></i>
                                  {errors.password && (
                                    <span className="dh-txt-danger">
                                      {errors.password.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="cf_password"
                                  >
                                    {transProps.confirmPassword} *
                                  </label>
                                  <input
                                    {...register("confirm_password", {
                                      required:
                                        transProps.confirmPasswordRequired,
                                      validate: (value) =>
                                        value === watch("password") ||
                                        transProps.passwordsNotMatch,
                                    })}
                                    type="password"
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors.confirm_password
                                        ? "dh-error-box"
                                        : ""
                                    }`}
                                    id="cf_password"
                                    autoComplete="off"
                                  />
                                  {errors.confirm_password && (
                                    <span className="dh-txt-danger">
                                      {errors.confirm_password.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="signatureDate"
                                  >
                                    {transProps.signatureDate}
                                  </label>
                                  <input
                                    {...register("signatureDate", {
                                      // required: "Signature date is required",
                                    })}
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors.signatureDate ? "dh-error-box" : ""
                                    }`}
                                    id="signatureDate"
                                    // type="date"
                                    value={datem}
                                    onChange={onNativeChange}
                                    defaultValue={datem}
                                    disabled="true"
                                  />
                                  {errors.signatureDate && (
                                    <span className="dh-txt-danger">
                                      {errors.signatureDate.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                {/* <Button className="w-100" >Signature</Button> */}
                                {signature ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <img
                                      src={signature}
                                      height={100}
                                      width={100}
                                    />
                                    <div>
                                      <EditIcon
                                        sx={{ cursor: "pointer" }}
                                        onClick={() =>
                                          setOpenSignatureModal(true)
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <MUIButton
                                    sx={{ py: 1, marginTop: "42px" }}
                                    className="w-100"
                                    startIcon={<FeatherIcon />}
                                    onClick={() => setOpenSignatureModal(true)}
                                    variant="contained"
                                    color="success"
                                  >
                                    {transProps.signature}
                                  </MUIButton>
                                )}
                              </div>
                            </div>

                            {/* <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label
                                    className="dh-title-lb"
                                    htmlFor="additionalContactInfo"
                                  >
                                    Additional Contact Info
                                  </label>
                                  <textarea
                                    {...register("additionalContactInfo", {
                                      maxLength: {
                                        value: "3000",
                                        message:
                                          "Maximum character count exceeded.",
                                      },
                                    })}
                                    className={`form-control form-control-lg  ${
                                      errors.additionalContactInfo
                                        ? "dh-error-box"
                                        : ""
                                    }`}
                                    id="additionalContactInfo"
                                    rows="3"
                                    aria-label="additionalContactInfo"
                                    aria-labelledby="additionalContactInfo"
                                    aria-describedby="additionalContactInfo"
                                  ></textarea>
                                  <span className="float-end text-muted mt-1 ">
                                    <i
                                      className="fa fa-info-circle mr-1"
                                      aria-hidden="true"
                                    ></i>
                                    Max character : 3000
                                  </span>
                                  {errors.additionalContactInfo && (
                                    <span className="dh-txt-danger">
                                      {errors.additionalContactInfo.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="dh-footer">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dh-disp text-center">
                            <button
                              type="submit"
                              className="btn btn-primary btn-lg reg-btns-style reg-btns"
                              disabled={!signature}
                            >
                              {transProps.registerAndNext}
                              <i className="fa fa-angle-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
                <section
                  className={`dh-reg-step-2 mt-3 mt-sm-0 mt-md-0 mt-lg-0 ${
                    activeTab === "hip_public_info" ? "" : "hide"
                  }`}
                  id="reg-hip_public_info"
                >
                  <form onSubmit={handleSubmit(onHipPublicInfoSubmit)}>
                    <div className="dh-head">
                      <div className="row">
                        <div className="col-md-12">
                          <h4> {transProps.publicInfo}</h4>
                        </div>
                      </div>
                    </div>

                    <div className="dh-content">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="regValidationFdbk"></div>

                          <div className="dh-box-mr">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="infoEmailID"
                                  >
                                    {transProps.publicEmailAddress}
                                  </label>
                                  <input
                                    {...register("publicEmail", {
                                      pattern: {
                                        value:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: transProps.invalidEmailAddress,
                                      },
                                    })}
                                    type="text"
                                    className={`form-control form-control-lg required dh-input-box lowercase ${
                                      errors.publicEmail ? "dh-error-box" : ""
                                    }`}
                                    maxLength="100"
                                    id="infoEmailID"
                                    aria-label="infoEmailID"
                                    aria-labelledby="infoEmailID"
                                    aria-describedby="infoEmailID"
                                  />
                                  {errors.publicEmail && (
                                    <span className="dh-txt-danger">
                                      {errors.publicEmail.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="infoPhoneNumber"
                                  >
                                    {transProps.publicPhone}
                                  </label>
                                  <input
                                    {...register("publicMobile", {
                                      pattern: {
                                        value:
                                          /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                                        message: transProps.invalidPhoneNumber,
                                      },
                                    })}
                                    type="tel"
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors.publicMobile ? "dh-error-box" : ""
                                    }`}
                                    value={publicMobile}
                                    onChange={(e) => {
                                      setPublicMobile(
                                        maskMobile(e.target.value)
                                      );
                                      clearErrors(["publicMobile"]);
                                    }}
                                    placeholder="(___) ___-____"
                                    id="publicMobile"
                                    aria-label="infoPhoneNumber"
                                    aria-labelledby="infoPhoneNumber"
                                    aria-describedby="infoPhoneNumber"
                                  />
                                  {errors.publicMobile && (
                                    <span className="dh-txt-danger">
                                      {errors.publicMobile.message}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label
                                          className="dh-form-label dh-title-lb"
                                          htmlFor="infoWebsite"
                                        >
                                          {transProps.website}
                                        </label>
                                        <input
                                          {...register("publicWebsite", {
                                            pattern: {
                                              value:
                                                /^((ftp|http|https):\/\/)?(www\.)?([A-z]+)\.([A-z]{2,})/,
                                              message:
                                                transProps.invalidWebsite,
                                            },
                                          })}
                                          type="text"
                                          maxLength="100"
                                          className={`form-control form-control-lg required dh-input-box ${
                                            errors.publicWebsite
                                              ? "dh-error-box"
                                              : ""
                                          }`}
                                          id="infowebsite"
                                          aria-label="infoWebsite"
                                          aria-labelledby="infoWebsite"
                                          aria-describedby="infoWebsite"
                                        />
                                        {errors.publicWebsite && (
                                          <span className="dh-txt-danger">
                                            {errors.publicWebsite.message}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label
                                          className="dh-form-label dh-title-lb"
                                          htmlFor="businessDesc"
                                        >
                                          {transProps.businessDescription}
                                        </label>
                                        <textarea
                                          {...register("businessDesc", {
                                            maxLength: {
                                              value: 3000,
                                              message:
                                                transProps.maximumCharacter,
                                            },
                                          })}
                                          className="form-control form-control-lg"
                                          maxLength="3000"
                                          id="businessDesc"
                                          rows="3"
                                          aria-label="businessDesc"
                                          aria-labelledby="businessDesc"
                                          aria-describedby="businessDesc"
                                        ></textarea>
                                        <span className="float-end  text-muted mt-1 ">
                                          <i
                                            className="fa fa-info-circle mr-1"
                                            aria-hidden="true"
                                          ></i>
                                          {transProps.maxCharacter}
                                        </span>
                                        {errors.businessDesc && (
                                          <span className="dh-txt-danger">
                                            {errors.businessDesc.message}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="col-md-5"
                                  style={{ marginLeft: "15px" }}
                                >
                                  <div
                                    id="edit_public_photo_box"
                                    className="dh-photo-cover"
                                    style={{ position: "relative" }}
                                  >
                                    <label className="dh-form-label dh-title-lb">
                                      {transProps.vendorPhoto}
                                    </label>
                                    <div
                                      className="dh-store-photo"
                                      id="edit_publicPhoto"
                                      style={{ position: "relative" }}
                                    >
                                      {selectedImage.base64URL && (
                                        <StyledImageUpload>
                                          <StyledImage
                                            alt="Vendor Photo"
                                            src={selectedImage.base64URL}
                                          />
                                          <>
                                            <Button
                                              title={"Remove"}
                                              style={{
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                                borderRadius: "3px",
                                              }}
                                              variant="danger"
                                              onClick={handleRemoveImage}
                                            >
                                              <i className="fa fa-trash-o"></i>
                                            </Button>
                                          </>
                                        </StyledImageUpload>
                                      )}
                                      {!selectedImage.base64URL && (
                                        <div>
                                          <h1>
                                            <i className="fa fa-picture-o fa-3"></i>
                                          </h1>
                                          <div className="smallTitle">
                                            {transProps.notAvailable}
                                          </div>
                                          <div>{transProps.clickEdit}</div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="form-group ">
                                      <input
                                        {...register("publicPhoto", {
                                          validate: {
                                            lessThan3MB: (files) =>
                                              files.length == 0 ||
                                              files[0]?.size / 1024 <= 3072 ||
                                              transProps.fileShould,
                                            acceptedFormats: (files) =>
                                              files.length == 0 ||
                                              [
                                                "image/jpeg",
                                                "image/png",
                                                "image/gif",
                                              ].includes(files[0]?.type) ||
                                              transProps.fileFormat,
                                          },
                                        })}
                                        accept="image/*"
                                        type="file"
                                        maxLength="100"
                                        className="form-control required uploadImage-style"
                                        placeholder="Upload Photo"
                                        id="infoPhoto"
                                        required=""
                                        onChange={(e) => {
                                          handleFileInputChange(e);
                                          clearErrors(["publicPhoto"]);
                                        }}
                                      />
                                      {errors.publicPhoto && (
                                        <span className="dh-txt-danger">
                                          {errors.publicPhoto.message}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label
                                      className="dh-form-label dh-title-lb"
                                      htmlFor="infoProductDescription"
                                    >
                                      Products
                                    </label>
                                    <textarea
                                      {...register("products", {
                                        maxLength: {
                                          value: 3000,
                                          message:
                                            "Maximum character count exceeded.",
                                        },
                                      })}
                                      className="form-control form-control-lg"
                                      maxLength="3000"
                                      id="infoProductDescription"
                                      rows="3"
                                      aria-label="infoProductDescription"
                                      aria-labelledby="infoProductDescription"
                                      aria-describedby="infoProductDescription"
                                    ></textarea>
                                    <span className="float-end  text-muted mt-1 ">
                                      <i
                                        className="fa fa-info-circle mr-1"
                                        aria-hidden="true"
                                      ></i>
                                      Max character : 3000
                                    </span>
                                    {errors.products && (
                                      <span className="dh-txt-danger">
                                        {errors.products.message}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dh-footer">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="dh-disp text-center">
                              {/* public */}
                              <button
                                type="submit"
                                className="btn btn-primary btn-lg save-btn-style reg-btns"
                              >
                                {transProps.saveAndNext}
                                <i className="fa fa-angle-right"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>

                {showModal && (
                  <ModalComponent
                    shown={showModal}
                    close={() => {
                      setShowModal(false);
                      setOnEdit({});
                    }}
                    title={
                      Object.keys(onEdit).length === 0
                        ? "ADD SHARE DETAILS"
                        : "UPDATE SHARE DETIALS"
                    }
                    modalBody={renderModal}
                    size={"lg"}
                    label={
                      Object.keys(onEdit).length === 0
                        ? "ADD SHARE DETAILS"
                        : "UPDATE SHARE DETIALS"
                    }
                  />
                )}
                <section
                  className={`dh-reg-step-6 mt-3 mt-sm-0 mt-md-0 mt-lg-0 ${
                    activeTab === "hip_share_info" ? "" : "hide"
                  }`}
                  id="reg-hip_share_info"
                >
                  {activeTab === "hip_share_info" && (
                    <>
                      <Grid mb={2}>
                        <SnapCSALocation
                          vendorInfo={props.user.userinfo}
                          userId={props.user.userinfo}
                        />
                      </Grid>

                      <Grid container paddingBottom={2}>
                        <ShareInfoTab
                          vendorInfo={props.user.userinfo}
                          userId={props.user.userinfo}
                        />

                        <Stack direction="row">
                          <button
                            onClick={() => setActiveTab("loc_type")}
                            className="btn btn-primary btn-lg save-btn-style reg-btns"
                          >
                            {transProps.saveAndNext}{" "}
                            <i className="fa fa-angle-right"></i>
                          </button>
                        </Stack>
                      </Grid>
                    </>
                  )}
                </section>
                {/* ---------------- */}
                {/*<!------ add section ---> ***/}

                <section
                  className={` dh-reg-step-3 mt-3 mt-sm-0 mt-md-0 mt-lg-0 ${
                    activeTab === "csa_reg" ? "" : "hide"
                  }`}
                  id="reg-csa_reg"
                >
                  <form onSubmit={handleSubmit3(onCsaBasicSubmit)}>
                    <div className="dh-head">
                      <div className="row">
                        <div className="col-md-12">
                          <h4>CSA Basic Registration</h4>
                        </div>
                      </div>
                    </div>

                    <div className="dh-content">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="regValidationFdbk"></div>
                          {vendorType.name === "HIPCSAVDR" && !createCSA ? (
                            <div className="col-md-12">
                              <Form.Check
                                type="checkbox"
                                checked={isSameAsHipInfo}
                                id="hip-checkbox"
                                onChange={(e) => {
                                  setIsSameAsHipInfo(!isSameAsHipInfo);
                                  if (e.target.checked) {
                                    reset3(formValue);
                                  } else {
                                    reset3(initial);
                                  }
                                }}
                                label="Same as  Info"
                              />
                            </div>
                          ) : null}
                          <div className="dh-box-mr">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="firstName"
                                  >
                                    First Name *
                                  </label>
                                  <input
                                    {...register3("firstName", {
                                      required: "First Name is required",
                                    })}
                                    className={`form-control form-control-lg required dh-input-box ${
                                      createCSA ? "readonly" : ""
                                    } ${
                                      errors3.firstName ? "dh-error-box" : ""
                                    }`}
                                    placeholder=""
                                    maxLength={20}
                                    disabled={createCSA ? true : false}
                                  />
                                  {errors3.firstName && (
                                    <span className="dh-txt-danger">
                                      {errors3.firstName.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="lastName"
                                  >
                                    Last Name *
                                  </label>
                                  <input
                                    {...register3("lastName", {
                                      required: "Last Name is required",
                                    })}
                                    className={`form-control form-control-lg required dh-input-box ${
                                      createCSA ? "readonly" : ""
                                    } ${
                                      errors3.lastName ? "dh-error-box" : ""
                                    }`}
                                    placeholder=""
                                    maxLength={20}
                                    disabled={createCSA ? true : false}
                                  />
                                  {errors3.lastName && (
                                    <span className="dh-txt-danger">
                                      {errors3.lastName.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="farmVendorName"
                                  >
                                    Farm / Vendor Name *
                                  </label>
                                  <input
                                    {...register3("vendorName", {
                                      required:
                                        "Farm / Vendor Name is required",
                                    })}
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors3.vendorName ? "dh-error-box" : ""
                                    }`}
                                    placeholder=""
                                    maxLength={100}
                                  />
                                  {errors3.vendorName && (
                                    <span className="dh-txt-danger">
                                      {errors3.vendorName.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div> */}

                            <div className="row" id="csaFnsNumbersList">
                              {controlledCSAFields.map((item, i) => {
                                return (
                                  <Fragment key={item.id}>
                                    <div
                                      className="col-md-6"
                                      id={`csaFnsNum.${i}`}
                                    >
                                      <div className="form-group">
                                        <p
                                          className="dh-form-label dh-title-lb"
                                          htmlFor={`csaFnsNum.${i}`}
                                        >
                                          CSA FNS Number *
                                          {i > 0 ? (
                                            <span
                                              key={i}
                                              className={
                                                "text-decoration-underline cursor-pointer dh-remove ml-2 green"
                                              }
                                              onClick={() => {
                                                handleCSAFnsRemove(i);
                                              }}
                                            >
                                              <i className="fa fa-trash-o"></i>{" "}
                                              Remove
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </p>

                                        <input
                                          {...register3(`csaFnsNum.${i}.name`, {
                                            required: "Federal ID is required",
                                            pattern: {
                                              value: /^(\d{9})$/,
                                              message:
                                                "Federal ID should be 9 digits",
                                            },
                                          })}
                                          maxLength={9}
                                          onKeyDown={isNumber}
                                          type="text"
                                          className={`form-control form-control-lg fns-number-input dh-input-box required ${
                                            errors3.csaFnsNum?.[i]?.name
                                              ? "dh-error-box"
                                              : ""
                                          }`}
                                        />
                                        {errors3.csaFnsNum?.[i]?.name && (
                                          <span className="dh-txt-danger">
                                            {errors3.csaFnsNum[i].name.message}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </Fragment>
                                );
                              })}
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="primaryEmailID"
                                  >
                                    Primary CSA Email Address *
                                  </label>
                                  <input
                                    {...register3("primaryEmail", {
                                      required: "Primary Email is required",
                                      pattern: {
                                        value:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid Email Address",
                                      },
                                      validate: validateEmail,
                                    })}
                                    type="email"
                                    maxLength="100"
                                    className={`form-control form-control-lg required dh-input-box lowercase ${
                                      createCSA ? "readonly" : ""
                                    } ${
                                      errors3.primaryEmail ? "dh-error-box" : ""
                                    }`}
                                    id="primaryEmailID"
                                    disabled={createCSA ? true : false}
                                  />
                                  {errors3.primaryEmail && (
                                    <span className="dh-txt-danger">
                                      {errors3.primaryEmail.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div>
                                <div className="row" id="Email List">
                                  {controlledFieldsCSAEmail.map((item, i) => {
                                    return (
                                      <Fragment key={item.id}>
                                        <div
                                          className="col-md-6"
                                          id={`csaCommunicationEmail.${i}`}
                                        >
                                          <p
                                            className="dh-form-label dh-title-lb"
                                            htmlFor={`csaCommunicationEmail.${i}`}
                                          >
                                            Communication Email Address *
                                            {i == 0 && (
                                              <Tooltip
                                                title="DTA will send CSA notifications and updates to Communication Email Address(es) listed here. Can be the same as your Primary Email Address."
                                                placement="top"
                                              >
                                                <IconButton>
                                                  <Info />
                                                </IconButton>
                                              </Tooltip>
                                            )}
                                            {i > 0 ? (
                                              <span
                                                key={i}
                                                className={
                                                  "text-decoration-underline cursor-pointer dh-remove ml-2 green"
                                                }
                                                onClick={() => {
                                                  handleCSAEmailRemove(i);
                                                }}
                                              >
                                                <i className="fa fa-trash-o"></i>{" "}
                                                Remove
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </p>
                                          <input
                                            {...register3(
                                              `csaCommunicationEmail.${i}.name`,
                                              {
                                                required:
                                                  "Communication Email is required",
                                                pattern: {
                                                  value:
                                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                  message:
                                                    "Invalid Communication Email Address",
                                                },
                                              }
                                            )}
                                            type="email"
                                            maxLength="100"
                                            className={`form-control form-control-lg required dh-input-box lowercase ${
                                              errors3.csaCommunicationEmail
                                                ? "dh-error-box"
                                                : ""
                                            }`}
                                            id="communicationCSAEmailID"
                                          />
                                          {errors3.csaCommunicationEmail?.[i]
                                            ?.name && (
                                            <span className="dh-txt-danger">
                                              {
                                                errors3.csaCommunicationEmail[i]
                                                  .name.message
                                              }
                                            </span>
                                          )}
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                                </div>
                                {/* {communicateCSAEmail < 10 && (
                                  <div
                                    className="form-text text-muted dh-short-title"
                                    id="addMoreEmailAddress"
                                  >
                                    <span>
                                      Do you have an additional Email?{" "}
                                    </span>
                                  </div>
                                )} */}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="primaryPhoneNumber"
                                  >
                                    Primary CSA Phone Number *
                                  </label>
                                  <input
                                    {...register3("primaryMobile", {
                                      required:
                                        "Primary Phone Number is required",
                                      pattern: {
                                        value:
                                          /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                                        message: "Invalid Phone Number",
                                      },
                                    })}
                                    type="tel"
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors3.primaryMobile
                                        ? "dh-error-box"
                                        : ""
                                    }`}
                                    value={primaryCSAMobile}
                                    onChange={(e) => {
                                      setPrimaryCSAMobile(
                                        maskMobile(e.target.value)
                                      );
                                      if (errors3.primaryMobile) {
                                        delete errors3.primaryMobile;
                                      }
                                    }}
                                    placeholder="(___) ___-____"
                                    id="primaryPhoneNumber"
                                    aria-label="primaryPhoneNumber"
                                    aria-labelledby="primaryPhoneNumber"
                                    aria-describedby="primaryPhoneNumber"
                                    required=""
                                  />
                                  {errors3.primaryMobile && (
                                    <span className="dh-txt-danger">
                                      {errors3.primaryMobile.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="dh-footer">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dh-disp text-center">
                            {}
                            <button
                              type="submit"
                              className="btn btn-primary btn-lg reg-btns-style reg-btns"
                            >
                              Register and Next{" "}
                              <i className="fa fa-angle-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
                <section
                  className={` dh-reg-step-4 mt-3 mt-sm-0 mt-md-0 mt-lg-0 ${
                    activeTab === "csa_public_info" ? "" : "hide"
                  }`}
                  id="reg-csa_public_info"
                >
                  <form onSubmit={handleSubmit4(onCsaPublicInfoSubmit)}>
                    <div className="dh-head">
                      <div className="row">
                        <div className="col-md-12">
                          <h4>CSA Public Info</h4>
                        </div>
                      </div>
                    </div>

                    <div className="dh-content">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="regValidationFdbk"></div>

                          <div className="dh-box-mr">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="infoEmailID"
                                  >
                                    Public CSA Email Address *
                                  </label>
                                  <input
                                    {...register4("publicEmail", {
                                      required:
                                        "Public CSA Email Address is required",
                                      pattern: {
                                        value:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid Email Address",
                                      },
                                    })}
                                    type="text"
                                    className={`form-control form-control-lg required dh-input-box lowercase ${
                                      errors4.publicEmail ? "dh-error-box" : ""
                                    }`}
                                    maxLength="100"
                                    id="infoEmailID"
                                    aria-label="infoEmailID"
                                    aria-labelledby="infoEmailID"
                                    aria-describedby="infoEmailID"
                                  />
                                  {errors4.publicEmail && (
                                    <span className="dh-txt-danger">
                                      {errors4.publicEmail.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="dh-form-label dh-title-lb"
                                    htmlFor="infoPhoneNumber"
                                  >
                                    Public CSA Phone Number *
                                  </label>
                                  <input
                                    {...register4("publicMobile", {
                                      required:
                                        "Public CSA Phone Number is required",
                                      pattern: {
                                        value:
                                          /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                                        message: "Invalid Phone Number",
                                      },
                                    })}
                                    type="tel"
                                    className={`form-control form-control-lg required dh-input-box ${
                                      errors4.publicMobile ? "dh-error-box" : ""
                                    }`}
                                    value={publicCSAMobile}
                                    onChange={(e) => {
                                      setPublicCSAMobile(
                                        maskMobile(e.target.value)
                                      );
                                      clearErrors4(["publicMobile"]);
                                    }}
                                    placeholder="(___) ___-____"
                                    id="publicMobile"
                                    aria-label="infoPhoneNumber"
                                    aria-labelledby="infoPhoneNumber"
                                    aria-describedby="infoPhoneNumber"
                                  />
                                  {errors4.publicMobile && (
                                    <span className="dh-txt-danger">
                                      {errors4.publicMobile.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label
                                        className="dh-form-label dh-title-lb"
                                        htmlFor="infoWebsite"
                                      >
                                        Website
                                      </label>
                                      <input
                                        {...register4("publicWebsite", {
                                          pattern: {
                                            value:
                                              /^((ftp|http|https):\/\/)?(www\.)?([A-z]+)\.([A-z]{2,})/,
                                            message: "Invalid Website",
                                          },
                                        })}
                                        type="text"
                                        maxLength="100"
                                        className={`form-control form-control-lg required dh-input-box ${
                                          errors4.publicWebsite
                                            ? "dh-error-box"
                                            : ""
                                        }`}
                                        id="infowebsite"
                                        aria-label="infoWebsite"
                                        aria-labelledby="infoWebsite"
                                        aria-describedby="infoWebsite"
                                      />
                                      {errors4.publicWebsite && (
                                        <span className="dh-txt-danger">
                                          {errors4.publicWebsite.message}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label
                                        className="dh-form-label dh-title-lb"
                                        htmlFor="products"
                                      >
                                        Notes about our CSA
                                      </label>
                                      <textarea
                                        {...register4("products", {
                                          maxLength: 3000,
                                        })}
                                        className="form-control form-control-lg"
                                        maxLength="3000"
                                        id="products"
                                        rows="3"
                                        aria-label="products"
                                        aria-labelledby="products"
                                        aria-describedby="products"
                                      ></textarea>
                                      <span className="float-end  text-muted mt-1 ">
                                        <i
                                          className="fa fa-info-circle mr-1"
                                          aria-hidden="true"
                                        ></i>
                                        Max character : 3000
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div
                                  id="edit_public_photo_box"
                                  className="dh-photo-cover"
                                  style={{ position: "relative" }}
                                >
                                  <p className="dh-form-label dh-title-lb">
                                    SPF Vendor Photo
                                  </p>
                                  <div
                                    className="dh-store-photo"
                                    id="edit_publicPhoto"
                                    style={{ position: "relative" }}
                                  >
                                    {selectedCSAImage.base64URL && (
                                      <StyledImageUpload>
                                        <StyledImage
                                          alt="Image not found"
                                          src={selectedCSAImage.base64URL}
                                        />
                                        <>
                                          <Button
                                            title={"Remove"}
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                              borderRadius: "3px",
                                            }}
                                            variant="danger"
                                            onClick={handleRemoveCSAImage}
                                          >
                                            <i className="fa fa-trash-o"></i>
                                          </Button>
                                        </>
                                      </StyledImageUpload>
                                    )}
                                    {!selectedCSAImage.base64URL && (
                                      <div>
                                        <h1>
                                          <i className="fa fa-picture-o fa-3"></i>
                                        </h1>
                                        <div className="smallTitle">
                                          Not Available
                                        </div>
                                        <div>
                                          Click edit to upload vendor photo
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="form-group ">
                                    <input
                                      {...register4("publicPhoto", {
                                        validate: {
                                          lessThan3MB: (files) =>
                                            files.length == 0 ||
                                            files[0]?.size / 1024 <= 3072 ||
                                            "File should be less than 3MB",
                                          acceptedFormats: (files) =>
                                            files.length == 0 ||
                                            [
                                              "image/jpeg",
                                              "image/png",
                                              "image/gif",
                                            ].includes(files[0]?.type) ||
                                            "File format should be PNG, JPEG or GIF",
                                        },
                                      })}
                                      accept="image/*"
                                      type="file"
                                      maxLength="100"
                                      className="form-control required uploadImage-style"
                                      placeholder="Upload Photo"
                                      id="infoCSAPhoto"
                                      required=""
                                      onChange={(e) => {
                                        handleCSAFileInputChange(e);
                                        clearErrors4(["publicPhoto"]);
                                      }}
                                    />
                                    {errors4.publicPhoto && (
                                      <span className="dh-txt-danger">
                                        {errors4.publicPhoto.message}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dh-footer">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dh-disp text-center">
                            <button
                              type="submit"
                              className="btn btn-primary btn-lg save-btn-style reg-btns"
                            >
                              {transProps.saveAndNext}
                              <i className="fa fa-angle-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
              </>
              <section
                style={{ minHeight: "63vh" }}
                className={` dh-reg-step-5 mt-3 mt-sm-0 mt-md-0 mt-lg-0 ${
                  activeTab === "loc_type" ? "" : "hide"
                }`}
                id="reg-loc_type"
              >
                <form>
                  <div className="dh-head">
                    <div className="row">
                      <div className="col-md-12">
                        <h4>{transProps.locationType}</h4>
                      </div>
                    </div>
                  </div>

                  <div className="dh-content">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="dh-box-mr">
                          {props.mapdata.vendorType &&
                            props.mapdata.vendorType.map((ques, i) => (
                              <div
                                key={`ques_${i}`}
                                className="form-text text-muted dh-short-title"
                              >
                                {transProps[ques.values]}
                                <br />
                                <div className="hip-pgm-btn">
                                  <div
                                    className={`dh-opt-round csa-program-btn store-program ${
                                      (isDisblOthrChkbx &&
                                        ques.name !== "farmersMarket") ||
                                      (isDisblMrkMngrChkbx &&
                                        ques.name === "farmersMarket")
                                        ? "dh-opt-disabled"
                                        : ""
                                    }  ${
                                      locType[ques.name] === "true" ||
                                      locType[ques.name] === true
                                        ? "dh-opt-active"
                                        : ""
                                    }`}
                                    id={`btn_${ques.name}_yes`}
                                  >
                                    <div className="custom-control custom-radio custom-control-inline p-0 m-0">
                                      <input
                                        {...register2(ques.name)}
                                        onClick={getLocType}
                                        type="radio"
                                        value={true}
                                        id={`${ques.name}_yes`}
                                        className="form-check-input"
                                        aria-label={ques.name}
                                        disabled={
                                          (isDisblOthrChkbx &&
                                            ques.name !== "farmersMarket") ||
                                          (isDisblMrkMngrChkbx &&
                                            ques.name === "farmersMarket")
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={`${ques.name}_yes`}
                                      >
                                        {transProps.yes}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    className={`dh-opt-round ${
                                      ques.name
                                    }_btn store-program  ${
                                      (isDisblOthrChkbx &&
                                        ques.name !== "farmersMarket") ||
                                      (isDisblMrkMngrChkbx &&
                                        ques.name === "farmersMarket")
                                        ? "dh-opt-disabled"
                                        : ""
                                    } ${
                                      locType[ques.name] === "false" ||
                                      locType[ques.name] === false
                                        ? "dh-opt-active"
                                        : ""
                                    }`}
                                    id={`btn_${ques.name}_no`}
                                  >
                                    <div className="custom-control custom-radio custom-control-inline p-0 m-0">
                                      <input
                                        {...register2(ques.name)}
                                        onClick={getLocType}
                                        type="radio"
                                        id={`${ques.name}_no`}
                                        className="form-check-input"
                                        value={false}
                                        aria-label={ques.name}
                                        disabled={
                                          (isDisblOthrChkbx &&
                                            ques.name !== "farmersMarket") ||
                                          (isDisblMrkMngrChkbx &&
                                            ques.name === "farmersMarket")
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={`${ques.name}_no`}
                                      >
                                        {transProps.no}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dh-footer">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="dh-disp text-center">
                          {/* Location */}
                          <button
                            className={`btn btn-primary btn-lg reg-btns save-btn-style ${
                              Object.values(locType).indexOf(true) !== -1
                                ? ""
                                : "hide"
                            }`}
                            id="hipProgramNextBtn"
                            type="button"
                            onClick={() => onSaveAndNext(enableMenuList[0].id)}
                          >
                            {transProps.saveAndNext}
                            <i className="fa fa-angle-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </section>
              {enableMenuList &&
                enableMenuList.length > 0 &&
                enableMenuList.map((item, i) => (
                  <section
                    key={i}
                    className={` dh-reg-step-2 mt-3 mt-sm-0 mt-md-0 mt-lg-0 ${
                      item.id === activeTab ? "" : "hide"
                    }`}
                    id={`reg-${item.menu}`}
                  >
                    {sections && sections.length > 0 ? (
                      <SignupForm
                        type={"add"}
                        vendorType={props.mapdata.vendorType}
                        location_types_name={item.id}
                        programData={ProgramSectionData}
                        menuItem={item}
                        section={getSelectedSection(item.id)}
                        city={cityList}
                        days={props.mapdata.days}
                        months={props.mapdata.months}
                        order_option={props.mapdata.orderOptions}
                        onSaveAndNext={onSaveAndNext}
                        handleOnFinish={handleOnFinish}
                        user={props.user.userinfo}
                        userAuth={props.userAuth}
                        addProgramData={addProgramData}
                        farmermarkets={props.administration.farmersMarket.list}
                      />
                    ) : (
                      ""
                    )}
                  </section>
                ))}
            </div>
          </div>
        </div>
      </div>
      <SignaturePad
        open={openSignatureModal}
        onClose={toggleSignature}
        setOpenSignatureModal={setOpenSignatureModal}
      />
    </div>
  );
}

UserRegistrationComponent.propTypes = {
  addUser: PropTypes.func.isRequired,
  getFarmersMarket: PropTypes.func.isRequired,
  getFarmersProducts: PropTypes.func,
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.object,
  userAuth: PropTypes.object.isRequired,
  getLocTypeQues: PropTypes.func.isRequired,
  locTypeQues: PropTypes.object.isRequired,
  mapdata: PropTypes.object.isRequired,
  getLocTypeValues: PropTypes.func.isRequired,
  loctype: PropTypes.object.isRequired,
  sections: PropTypes.array,
  locTypeProgramQuestionsSections: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  userinfo: PropTypes.object.isRequired,
  getCityList: PropTypes.func.isRequired,
  regCity: PropTypes.object,
  getMonthOption: PropTypes.func.isRequired,
  regDays: PropTypes.object,
  getDayOption: PropTypes.func.isRequired,
  regMonths: PropTypes.object,
  getOrderOption: PropTypes.func.isRequired,
  reg_orderoption: PropTypes.object,
  addProgram: PropTypes.func.isRequired,
  administration: PropTypes.object,
  clearRegistrationStatus: PropTypes.func,
  userId: PropTypes.number,
  authUserDetails: PropTypes.func,
  registration: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    locTypeProgramQuestionsSections: (val) =>
      dispatch(locTypeProgramQuestionsSections(val)),
    getFarmersMarket: (payload) => dispatch(getFarmersMarket(payload)),
    getFarmersProducts: (payload) => dispatch(getFarmersProducts(payload)),
    addUser: (user) => {
      dispatch({ type: SET_USER, value: user });
    },
    getUser: () => {
      dispatch({ type: GET_USER });
    },
    editUser: (userId) => {
      dispatch({ type: EDIT_USER, value: userId });
    },
    updateUser: (user, callback) => {
      dispatch({ type: PUT_USER, value: user, callback: callback });
    },
    getLocTypeValues: () => {
      dispatch({ type: GET_LOC_TYPE });
    },
    getLocTypeQues: () => {
      dispatch({ type: GET_LOC_TYPE_QUES });
    },
    getCityList: (city_county) => {
      dispatch({ type: GET_COUNTYCITY, city: city_county });
    },
    getOrderOption: () => {
      dispatch({ type: GET_ORDER_OPTION });
    },
    getMonthOption: () => {
      dispatch({ type: GET_MONTH_OPTION });
    },
    getDayOption: () => {
      dispatch({ type: GET_DAY_OPTION });
    },
    addProgram: (program) => {
      dispatch({ type: SET_PROGRAM, value: program });
    },
    clearRegistrationStatus: () => {
      dispatch(clearRegistrationStatus(""));
    },
    authUserDetails: (payload) => dispatch(authUserDetails(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserRegistrationComponent);
