import React, { useState } from 'react'
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { mapStateToProps } from '../../../redux/reducers';
import Button from 'react-bootstrap/Button';
import { Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import '../../../styles/home.scss';

const ShareAddEditLangComponent = (props) => {
    
    const {row, action ,vendorId} = props;
    const [selectedLang, setSelectedLang] = useState('es');
    console.log(row,action);
    
    let initial = 
    action == 'edit' ? {
        lang: row.lang,
        nam: row.nam,        
        range: row.range,
        dsc:row.dsc,
    } : {
        lang: "",
        nam: "",
        range: "",
        dsc:"",
    };
    const [formValue] = useState(initial);

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: formValue
    });

    const onSubmit = (data) => {
        if(Object.keys(errors).length === 0){
            let payload; 
            if(action == 'add'){
                payload = Object.assign(data, {
                    "shareId": row.id,
                    "vendorId" : vendorId,
                });
            }else{
                payload = Object.assign(data, {
                    "shareId": row.shareId,
                    "id": row.id,
                    "vendorId": vendorId,
                });
            }
            props.handleSubmit(payload);
        }
    };

    const Options = () => {
        if(action == 'add'){
            if(row.multiLang.length == 0){
                return(
                    <>
                        <option value="es">Spanish</option>
                        <option value="pt">Portuguese</option>
                    </>
                )
            }else{
                if(row.multiLang[0].lang == "es"){
                    setSelectedLang("pt");
                    return(<option value="pt">Portuguese</option>)
                }else{
                    setSelectedLang("es");
                    return(<option value="es">Spanish</option>)
                }
            }
        }else{
            if(row.lang == "es"){
                console.log("selected");
                setSelectedLang("es");
                return(<option value="es">Spanish</option>)
            }else{
                setSelectedLang("pt");
                return(<option value="pt">Portuguese</option>)
            }
        }

      }

  return (

    <div className="form-block addShareInfo">
        <Form noValidate onSubmit={handleSubmit(onSubmit)} auto>
            <Row>
                <Col>
                    {Object.keys(errors).length > 0 && Object.keys(errors)[0] != "pickupDetails" && <Form.Text className="dh-txt-danger">
                        <b><i className="fa fa fa-info-circle ml-2" aria-hidden="true"></i> {errors[Object.keys(errors)[0]].message} </b>
                    </Form.Text>}
                    <Form.Group as={Col}>
                        <Form.Label className="smallTitle">Select Language *</Form.Label>
                        <Form.Select aria-label="Multi-language Select" defaultValue={selectedLang} {...register("lang", { required: "Please select a Language"})}>
                            <Options />
                            {/* <option value="es">Spanish</option>
                            <option value="pt">Portuguese</option> */}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className="smallTitle">Share Name *</Form.Label>
                        <Form.Control type="text" {...register("nam", { required: "Please enter Share Name"})}/>
                    </Form.Group>
                    {row?.range && (<Row>
                        <Col>
                            <Form.Group as={Col}>
                                <Form.Label className="smallTitle">Delivery Range *</Form.Label>
                                <Form.Control type="text" {...register("range", { //validate : {
                                    required: "Please enter Delivery Range"
                                    // required: (value) => (row.dlvryFlg == "Y" && value.length != 0) || "Please enter delivery range"

                                })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>)}
                    <Form.Group as={Col} controlId="formDescription">
                        <Form.Label className="smallTitle">Description *</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder=""
                            style={{ height: '114px' }}
                            {...register("dsc", { required: "Please enter Description"})}
                            />
                        <Form.Text id="formDescription" className="float-right" muted>
                            <i className="fa fa fa-info-circle ml-2" aria-hidden="true"></i> Max character: 3000
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="modal-footer">
                <Col>
                    <Button variant="primary" type="submit" className="float-right">
                        {action == 'add' ? 'Add':'Update'}
                    </Button>
                </Col>
            </Row>
        </Form>
    </div>
)
}
ShareAddEditLangComponent.propTypes = {
    handleSubmit: PropTypes.func,
    row:PropTypes.object,
    action:PropTypes.string,
    vendorId:PropTypes.number,

};

export default connect(mapStateToProps,)(ShareAddEditLangComponent);