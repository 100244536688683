// import axios from "axios";
// import { pageRefresh, logout, popUpAlert } from "../utils/globalMethods";

// const axiosInstance = axios.create({
//   params: {}, // do not remove this, its added to add params later in the config
// });

// axiosInstance.interceptors.request.use(
//   config => {
//     if(config.url.includes("/oauth/token")){
//       const basicAuth = "Basic " + btoa(process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD);
//       config.headers["Authorization"] = basicAuth;
//     }else{
//       const token = sessionStorage.getItem("access_token");
//       const userData = sessionStorage.getItem("userData");
//       const systemInfo = sessionStorage.getItem("systemInfo");
//       if (token && !config.url.includes("/public")) {
//         config.headers["Authorization"] = "Bearer "+token;
//       }
//       if(userData && config.method !== "get"){
//         const user = JSON.parse(userData);
//         const systemData = JSON.parse(systemInfo);
//         const timestamp = new Date().getTime();
//         if(config.url.includes("/bulkUpdate") || config.url.includes("farmer/import")){
//           config.data.map(item => {
//             // item.loginId = user.id;
//             item.createTimeStamp = timestamp;
//             item.lastUpdtTimeStamp = timestamp;
//             item.ipAddress = systemData.ip;
//             item.lastUpdtUidNam = (user.Role === "ADMIN" ? "ADM_" : user.Role === "HIPCRD" ? "CRD_" : "VDR_")+user.id;
//           })
//         }else{
//           // config.data.loginId = user.id;
//           if (config?.data) {
//             config.data.createTimeStamp = timestamp;
//             config.data.lastUpdtTimeStamp = timestamp;
//             config.data.ipAddress = systemData.ip;
//             if(!config.url.includes("/history")){
//               config.data.lastUpdtUidNam = (user.Role === "ADMIN" ? "ADM_" : user.Role === "HIPCRD" ? "CRD_" : "VDR_")+user.id;
//             }
//           }
//         }
//       }
//     }

//     // config.headers["Access-Control-Allow-Origin"] = "*";
//     config.headers["Content-Type"] = "application/json";
//     return config;
//   },
//   error => {
//     Promise.reject(error);
//   }
// );

// axiosInstance.interceptors.response.use(
//   response => response,
//   error => {
//     console.log(error);
//     if("response" in error){
//       const {status} = error.response;
//       if (status === 401) {
//         logout();
//         pageRefresh();
//       }
//     }else{
//       popUpAlert({
//         title: "Error",
//         text: "Internal Server Error!",
//         icon: "error",
//         timer:1500,
//         showCancelButton: false,
//         showConfirmButton: false,
//       });
//     }
//     return Promise.reject(error);
//  }
// );

// export default axiosInstance;

import axios from "axios";
import { pageRefresh, logout, popUpAlert } from "../utils/globalMethods";

const axiosInstance = axios.create({
  params: {}, // do not remove this, its added to add params later in the config
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.url.includes("/oauth/token")) {
      const basicAuth =
        "Basic " +
        btoa(
          process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD
        );
      config.headers["Authorization"] = basicAuth;
    } else {
      const token = sessionStorage.getItem("access_token");
      const userData = sessionStorage.getItem("userData");
      const systemInfo = sessionStorage.getItem("systemInfo");
      if (token && !config.url.includes("/public")) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      if (userData && config.method !== "get") {
        const user = JSON.parse(userData);
        const systemData = JSON.parse(systemInfo);
        const timestamp = new Date().getTime();
        if (
          config.url.includes("/bulkUpdate") ||
          config.url.includes("farmer/import")
        ) {
          config.data.map((item) => {
            // item.loginId = user.id;
            item.createTimeStamp = timestamp;
            item.lastUpdtTimeStamp = timestamp;
            item.ipAddress = systemData.ip;
            item.lastUpdtUidNam =
              (user.Role === "ADMIN"
                ? "ADM_"
                : user.Role === "HIPCRD"
                ? "CRD_"
                : "VDR_") + user.id;
          });
        } else if (!config.url.includes("/public")) {
          // config.data.loginId = user.id;
          config.data.createTimeStamp = timestamp;
          config.data.lastUpdtTimeStamp = timestamp;
          config.data.ipAddress = systemData.ip;
          if (!config.url.includes("/history")) {
            config.data.lastUpdtUidNam =
              (user.Role === "ADMIN"
                ? "ADM_"
                : user.Role === "HIPCRD"
                ? "CRD_"
                : "VDR_") + user.id;
          }
        }
      }
    }
    config.headers["Audit"] = JSON.stringify({
      source: "dtaconnect-website",
      ipAddress: "54.236.115.39",
      deviceID: "desktop - Chrome 106.0.0.0 - Windows NT 10.0",
      phoneNumber: "",
      emailID: "",
      sessionId: "",
    });
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Content-Type"] = "application/json";
    config.headers["Locale"] = "en";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    if ("response" in error) {
      const { status } = error.response;
      if (status === 401) {
        logout();
        pageRefresh();
        // return getRefreshToken().then(() => {
        //   const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
        //   const configuration = error.config;
        //   configuration.headers.Authorization = `${oauthToken.token_type} ${oauthToken.access_token}` ;
        //   return new Promise((resolve, reject) => {
        //     axios.request(configuration).then(response => {
        //         resolve(response.data);
        //       })
        //       .catch(resErr => {
        //         reject(resErr);
        //       });
        //   });
        // });
      }
    } else {
      popUpAlert({
        title: "Error",
        text: "Internal Server Error!",
        icon: "error",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
