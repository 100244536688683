import {
  SET_CITY,
  SET_LOC_TYPE,
  SET_LOC_TYPE_QUES,
  SET_PROGRAM,
  SET_PROGRAM_FAILURE,
  SET_ORDER_OPTION,
  SET_MONTH_OPTION,
  SET_DAY_OPTION,
  SET_USER_ERROR_RESPONSE,
  SET_USER_ASYNC_SUCCESS,
  STOP_LOADING,
} from "../actionTypes/registration";

const initialState = {
  userinfo: {},
  registrationResponse: "",
  loading: false,
};

const registrationReducer = (state = initialState, action) => {
  // console.log(state);
  // console.log(action);
  switch (action.type) {
    case SET_USER_ASYNC_SUCCESS: {
      return { ...state, userinfo: { ...action.value } };
    }
    case SET_USER_ERROR_RESPONSE: {
      return { ...state, registrationResponse: { ...action.value } };
    }
    // case SET_USER:{
    //     // return [...action.value];
    //     const newState = Object.assign({}, state);
    //     newState.userinfo =action.value;
    //     return newState;
    // }
    // case SET_EDIT_MODE:{
    //     console.log(action);
    //     const user = (state || []).map(x => {
    //         if(x.id === action.value.userId){
    //         x.editMode = action.value.editMode;
    //         }
    //         return {...x};
    //     });
    //     // console.log(user);
    //     return [...action.value];
    //     }
    // case SET_USER_SUCCESS:{
    //     return action.value;
    // }
    case STOP_LOADING: {
      const newState = Object.assign({}, state);
      newState.loading = false;
      return newState;
    }

    case SET_LOC_TYPE: {
      const newState = Object.assign({}, state);
      newState.loctype = action.payload;
      return newState;
      // return [...action.value];
    }
    case SET_LOC_TYPE_QUES: {
      // return [...action.value];
      const newState = Object.assign({}, state);
      newState.locTypeQues = action.payload;
      return newState;
    }
    case SET_MONTH_OPTION: {
      // return [...action.value];
      const newState = Object.assign({}, state);
      newState.months = action.payload;
      return newState;
    }
    case SET_DAY_OPTION: {
      // return [...action.value];
      const newState = Object.assign({}, state);
      newState.days = action.payload;
      return newState;
    }
    case "SET_PROGRAM_RES": {
      // return action.value;
      const newState = Object.assign({}, state);
      newState.program = action.payload;
      return newState;
    }
    case SET_PROGRAM_FAILURE: {
      // return action.value;
      const newState = Object.assign({}, state);
      newState.program = action.payload;
      return newState;
    }
    case SET_CITY: {
      // return [...action.value];
      const newState = Object.assign({}, state);
      newState.regCity = action.payload;
      return newState;
    }
    case SET_ORDER_OPTION: {
      // return [...action.value];
      const newState = Object.assign({}, state);
      newState.reg_orderoption = action.payload;
      return newState;
    }

    case "LOCTYPE_PROGRAM_PAGES_FULLFILLED": {
      // alert("1212");
      var newState = Object.assign({}, state);
      newState.sections = action.payload;
      return newState;
    }
    case "@@redux/INIT": {
      const applyInit = Object.assign({}, state);
      applyInit.sections = [];
      // applyInit.AnswerObject = {};
      // applyInit.dalReference = {};
      // applyInit.inProgressSaveErrorState = false;
      // applyInit.inProgressSaveErrorMessage = '';
      return applyInit;
    }
    case "REFRESH_KEY": {
      return { ...newState, refreshKey: state.refreshKey + 1 };
    }

    default:
      return state;
  }
};

export default registrationReducer;
