import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { formatPhoneNumber } from "../../utils/globalMethods";

const FarmerMarketDetails = ({ row }) => {
  return (
    <Grid container className="collapsibleTable" spacing={2}>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Zip Code
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.zipcode ? row.zipcode : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          City
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.city}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          State
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.state}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Website
        </Typography>
        <Typography variant="subtitle1 website" gutterBottom>
          {row.webAddress ? row.webAddress : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Phone
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.phone ? formatPhoneNumber(row.phone) : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Last updated by
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.lastUpdtUidNam}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom component="div">
          Description
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.description ? row.description : "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};

FarmerMarketDetails.propTypes = {
  row: PropTypes.object.isRequired,
};

export default FarmerMarketDetails;
