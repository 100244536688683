import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { mapStateToProps } from "../../redux/reducers";
import PropTypes from "prop-types";
import LoginComponent from "../user/login/login";
import AdminLoginComponent from "../user/login/admin-login";
import "../../styles/bluebanner.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import logo from "../../assets/images/Vector.svg";
import LanguageSelect from "../common/language/languageSelect";
import HelpComponent from "../../pages/user/help";
import { Link, useNavigate } from "react-router-dom";
import { authUserDetails, tokenRevoke } from "../../redux/actionTypes/auth";
import ForgotPwdComponent from "../user/forgotpassword/forgotpassword";
import {
  pageRefresh,
  azureLogin,
  azureLogOut,
  popUpAlert,
} from "../../utils/globalMethods";
import { Dropdown } from "react-bootstrap";
//Azure
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
// Cookie
import { useCookies } from "react-cookie";
import useTranslator from "../../hooks/useTranslator";
import { useDispatch } from "react-redux";

const BlueBanner = (props) => {
  const transProps = useTranslator(
    [
      "kfresh",
      "home",
      "services",
      "seniorProgramFreshReg",
      "spe",
      "seniorProgramFresh",
      "forProviders",
      "help",
      "administration",
      "manageUsers",
      "manageCoordinates",
      "CSASignup",
      "partialSignup",
      "CSASignupStatus",
      "managePayments",
      "auditLogs",
      "myBusiness",
      "logout",
      "headerSection",
      "login",
    ],
    "header"
  );
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [language, setLanguage] = useState({});
  const [helpModalShow, setHelpModalShow] = useState(false);

  const [modalShown, toggleModal] = useState(false);
  const [modalShownAdmin, toggleModalAdmin] = useState(false);
  const [forgotShown, toggleForgotModal] = useState(false);
  // const [state, dispatch] = useReducer(reducer, initialState);
  const [fullName, setFullName] = useState(null);
  const [menuList, SetMenuList] = useState(false);
  const [pathname, setPathName] = useState(window.location.pathname);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const [mystoreUrl, setMystoreUrl] = useState();
  const [csaSignupLink, setCsaSignupLink] = useState("/kfresh/csa-signup");
  const [cookies, setCookie, removeCookie] = useCookies([
    "relogin",
    "validation",
    "createCSA",
  ]);

  const userLogin = sessionStorage.getItem("userLogin")
    ? JSON.parse(sessionStorage.getItem("userLogin"))
    : "";
  const adminCheck = sessionStorage.getItem("userData")
    ? JSON.parse(sessionStorage.getItem("userData")).Role
    : "";
  const csavendor = sessionStorage.getItem("ext_url")
    ? sessionStorage.getItem("ext_url")
    : "";
  const { authenticated, user } = props.userAuth;

  // Azure
  const { instance } = useMsal();

  useEffect(() => {
    instance.addEventCallback(
      async (event) => {
        // Add below line as first time login and forgotpassword validation when these keys available.
        if (
          (event.eventType === "msal:loginSuccess" ||
            event.eventType === "msal:acquireTokenSuccess") &&
          !(
            event.payload &&
            event.payload.idTokenClaims &&
            (event.payload.idTokenClaims.isForgotPassword == true ||
              event.payload.idTokenClaims.newUser == true)
          )
        ) {
          const email = event.payload.account.idTokenClaims.email.toLowerCase();
          const myAccount = instance.getAccountByHomeId(
            event.payload.account.homeAccountId
          );
          instance.setActiveAccount(myAccount);
          const payload = {
            email: email,
            navigate: navigate,
            ext_url: "csa",
            currentAccount: instance.getActiveAccount(),
          };
          props.authUserDetails(payload);
        } else if (
          event.payload &&
          event.payload.idTokenClaims &&
          (event.payload.idTokenClaims.isForgotPassword == true ||
            event.payload.idTokenClaims.newUser == true)
        ) {
          setCookie("relogin", true);
          popUpAlert({
            title: event.payload.idTokenClaims.isForgotPassword
              ? "Password Updated!"
              : "Account Created!",
            text: "Please Login again to proceed.",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "Login",
            closeOnConfirm: false,
            allowOutsideClick: false,
            onConfirmed: true,
            handleConfirm: () => {
              azureLogOut(instance);
            },
          });
        }
        // Add below line as first time login and forgotpassword validation when these keys available.
        if (
          event.eventType === "msal:loginFailure" ||
          event.eventType === "msal:acquireTokenFailure"
        ) {
          azureLogOut(instance);
        }
      },
      (error) => {
        console.log("error", error);
      }
    );
  }, [instance]);

  useEffect(() => {
    if (cookies.relogin) {
      if (instance.getActiveAccount()) {
        azureLogOut(instance);
      } else {
        removeCookie("relogin");
        setTimeout(() => {
          azureLogin(instance);
        }, 300);
      }
    }
  }, []);
  // useEffect(() => {
  //   if (authenticated && Object.keys(user).length == 0) {
  //     const payload = {
  //       "primaryEmailAddr": authenticated,
  //       navigate: navigate
  //     }
  //     props.authUserDetails(payload)
  //   }
  // }, [props.userAuth.authenticated])
  useEffect(() => {
    if (authenticated && Object.keys(user).length == 0) {
      sessionStorage.setItem("ext_url", "res");
      const payload = {
        email: authenticated,
        navigate: navigate,
        ext_url: "res",
      };
      props.authUserDetails(payload);
    }
  }, [props.userAuth.authenticated]);

  useEffect(() => {
    if (Object.keys(user).length != 0) {
      setFullName(user.Name);
    }
    const link = "/kfresh/csa-signup?loginId=" + props?.userAuth?.user?.id;
    setCsaSignupLink(link);
  }, [props.userAuth.user]);

  useEffect(() => {
    setPathName(window.location.pathname);
  }, [window.location.pathname]);

  // useEffect(() => {
  //   const pathList = pathname.split("/");

  //   SetMenuList(
  //     (pathList.includes("kfreshfep") || pathList.includes("")) &&
  //       !pathList.includes("kfresh")
  //   );
  //   let path = pathList[pathList.length - 1];
  //   if (pathname.slice(-1) == "/") {
  //     path = pathList[pathList.length - 2];
  //   }

  //   if (user.Role === "HIPVDR") {
  //     setMystoreUrl("/kfreshfep/mystore/hip");
  //   } else {
  //     setMystoreUrl("/kfreshfep/mystore/csa");
  //   }
  //   if (
  //     user.Role === "HIPVDR" ||
  //     user.Role === "CSAVDR" ||
  //     user.Role === "SUBVDR"
  //   ) {
  //     if (
  //       !(
  //         path == "" ||
  //         path == "kfreshfep" ||
  //         path == "mystore" ||
  //         path == "hip" ||
  //         path == "csa"
  //       )
  //     ) {
  //       navigate("/kfreshfep");
  //     }
  //   } else if (Object.keys(user).length == 0) {
  //     if (
  //       !(
  //         path == "" ||
  //         path == "kfreshfep" ||
  //         path == "registration" ||
  //         pathname == "/kfresh/csa-landing" ||
  //         pathname == "/kfresh/client-agreement" ||
  //         pathname == "/kfresh/csa-signup"
  //       )
  //     ) {
  //       navigate("/kfreshfep");
  //     }
  //   }
  // }, [pathname]);

  const tokenRevoke = () => {
    const payload = {
      navigate: navigate,
    };
    props.tokenRevoke(payload);
  };

  useEffect(() => {
    if (
      props?.userAuth?.tokenRevoke &&
      props?.userAuth?.tokenRevoke?.status == "SUCCESS"
    ) {
      pageRefresh();
    }
  }, [props.userAuth.tokenRevoke]);
  const openLogin = () => {
    setAnchorEl2(null);
    toggleModal(true);
  };

  const openForgotPwd = () => {
    toggleForgotModal(true);
  };

  const toggleHelpModal = () => {
    setHelpModalShow(true);
  };

  const stlyedHeader = {
    backgroundColor: "#9AC77C",
    // borderBottom: "5px solid #006e52",
    color: "#000 !important",
    // padding: "8px 0px"
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleMenuClose2 = () => {
    setAnchorEl2(null);
  };

  const handleMenuOpen3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleMenuClose3 = () => {
    setAnchorEl3(null);
  };

  const handleNavigate = () => {};

  return (
    <>
      {forgotShown ? (
        <ForgotPwdComponent
          shown={forgotShown}
          close={() => {
            toggleForgotModal(false);
          }}
        />
      ) : (
        ""
      )}
      {helpModalShow ? (
        <HelpComponent
          shown={helpModalShow}
          close={() => {
            setHelpModalShow(false);
          }}
        />
      ) : (
        ""
      )}
      <Grid container item xs={12} className="blue-wrapper">
        {modalShown && (
          <LoginComponent
            shown={modalShown}
            close={(forgotpwd = "") => {
              toggleModal(false);
              if (forgotpwd) {
                openForgotPwd();
              }
            }}
          />
        )}
        {modalShownAdmin && (
          <AdminLoginComponent
            shown={modalShownAdmin}
            close={(forgotpwd = "") => {
              toggleModalAdmin(false);
              if (forgotpwd) {
                openForgotPwd();
              }
            }}
          />
        )}
        {userLogin.role === "ADMIN" ||
        userLogin.role === "HIPVDR" ||
        userLogin.role === "CSAVDR" ||
        userLogin.role === "MRKCRD" ? (
          // <Grid container maxWidth="lg" className="wrapper-bluebanner">
          <>
            <Grid container sx={{ display: "flex", alignItems: "center" }}>
              <Grid
                className="logo-title"
                item
                xs={3}
                sm={2.5}
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  textTransform: "initial !important",
                }}
              >
                <Button
                  className="logo-res"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    adminCheck === "ADMIN"
                      ? navigate("/kfreshfep/admin-dashboard")
                      : adminCheck === "CSAVDR" || adminCheck === "HIPVDR"
                      ? navigate(
                          `/kfreshfep/mystore/${
                            adminCheck === "CSAVDR" ? "csa" : "hip"
                          }`
                        )
                      : navigate("/")
                  }
                >
                  {/* <NavLink to="/" reloadDocument="true"> */}
                  <span style={{ paddingRight: "10px" }} className="res-img-sm">
                    {" "}
                    <img src={logo} alt="DTA Finder Logo" className="img-res" />
                  </span>

                  <span
                    className="boldFont page-title header-titlenew"
                    style={{ color: "#f32c2c" }}
                  >
                    {transProps.kfresh
                      ? transProps.kfresh.substring(0, 1)
                      : transProps.kfresh}
                  </span>
                  <span
                    className="boldFont page-title header-titlenew"
                    style={{ color: "#2d6b03" }}
                  >
                    {transProps.kfresh
                      ? transProps.kfresh.substring(1)
                      : transProps.kfresh}
                  </span>
                  {/* </NavLink> */}
                </Button>
              </Grid>
              <Grid
                item
                xs={false}
                sm={9.5}
                md={8}
                className="banner div-center  blue-menu"
              >
                <AppBar
                  className="blue-header-root appbar-res"
                  position="static"
                  sx={stlyedHeader}
                >
                  {(adminCheck === "ADMIN" || user.Role === "HIPCRD") && (
                    <>
                      <Button
                        sx={{ marginRight: "10px" }}
                        className="menu-item font-regular16 textTransform-capitalize btn-shadow-hide-focus homebtn"
                        onClick={() => navigate("/kfreshfep/admin-dashboard")}
                        aria-label="Service have the popup listbox"
                      >
                        <span>{transProps.home}</span>
                      </Button>
                      {/* {adminCheck === "ADMIN" && (
                        <>
                          <Button
                            className="menu-item mr-3 font-regular16 textTransform-capitalize"
                            onClick={handleMenuOpen}
                          >
                            <span>{transProps.administration}</span>
                            <i
                              className="fa fa-caret-down caretIcon-position"
                              aria-hidden="true"
                            ></i>
                          </Button>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                          >
                            <MenuItem
                              className="language-menu-item font-regular16"
                              onClick={handleMenuClose}
                            >
                              <Link
                                to={"/kfreshfep/manage-users"}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                {transProps.manageUsers}
                              </Link>
                            </MenuItem>
                            <MenuItem
                              className="language-menu-item font-regular16"
                              onClick={handleMenuClose}
                            >
                              <Link
                                to={"/kfreshfep/manage-farmer-markets"}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                {transProps.manageFarmerMarkets}
                              </Link>
                            </MenuItem>
                            <MenuItem
                              className="language-menu-item font-regular16"
                              onClick={handleMenuClose}
                            >
                              <Link
                                to={"/kfreshfep/manage-address"}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                {transProps.manageCoordinates}
                              </Link>
                            </MenuItem>
                            <MenuItem
                              className="language-menu-item font-regular16"
                              onClick={handleMenuClose}
                            >
                              <Link
                                to={csaSignupLink}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                {transProps.CSASignup}
                              </Link>
                            </MenuItem>
                            <MenuItem
                              className="language-menu-item font-regular16"
                              onClick={handleMenuClose}
                            >
                              <Link
                                to={"/kfreshfep/partial-signup"}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                {transProps.partialSignup}
                              </Link>
                            </MenuItem>
                            <MenuItem
                              className="language-menu-item font-regular16"
                              onClick={handleMenuClose}
                            >
                              <Link
                                to={"/kfreshfep/csa-signup-status"}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                {transProps.CSASignupStatus}
                              </Link>
                            </MenuItem>
                            <MenuItem
                              className="language-menu-item font-regular16"
                              onClick={handleMenuClose}
                            >
                              <Link
                                to={"/kfreshfep/manage-payments"}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                {transProps.managePayments}
                              </Link>
                            </MenuItem>
                            <MenuItem
                              className="language-menu-item font-regular16"
                              onClick={handleMenuClose}
                            >
                              <Link
                                to={"/kfreshfep/audit-logs"}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                {transProps.auditLogs}
                              </Link>
                            </MenuItem>
                          </Menu>
                        </>
                      )} */}
                    </>
                  )}

                  {/* <Button className="menu-item">
                    <LanguageSelect className="menu-item font-regular16" />
                  </Button> */}
                  <Button
                    className="menu-item textTransform-capitalize"
                    onClick={handleMenuOpen3}
                  >
                    <span className="d-inline-block  mr-2">
                      <i className="fa fa-user" aria-hidden="true"></i>{" "}
                      {userLogin.firstName} {userLogin.lastName}
                    </span>{" "}
                    {"  "}
                    <i
                      className="fa fa-caret-down caretIcon-position"
                      aria-hidden="true"
                    ></i>
                  </Button>
                  <Menu
                    id="simple-user"
                    anchorEl={anchorEl3}
                    keepMounted
                    open={Boolean(anchorEl3)}
                    onClose={handleMenuClose3}
                  >
                    {(user.Role === "HIPVDR" ||
                      user.Role === "CSAVDR" ||
                      user.Role === "SUBVDR") && (
                      <MenuItem className="language-menu-item font-regular16">
                        <Link
                          to={mystoreUrl}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {transProps.myBusiness}
                        </Link>
                      </MenuItem>
                    )}

                    <MenuItem
                      className="language-menu-item font-regular16"
                      onClick={() => {
                        // if (user.Role === "HIPVDR") tokenRevoke();
                        // else azureLogOut(instance);
                        sessionStorage.removeItem("userLogin");
                        sessionStorage.removeItem("userData");
                        sessionStorage.removeItem("appNumber");
                        sessionStorage.removeItem("username");
                        dispatch({ type: "SET_HIP_USER_DETAILS", payload: {} });
                        if (adminCheck === "ADMIN") {
                          navigate("/admin");
                        } else navigate("/");
                      }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </AppBar>
              </Grid>
              <Grid item xs={9} className="blue-hamburger">
                <nav className="header navbar navbar-expand-lg navbar-light blue-wrapper">
                  <button
                    className="navbar-toggler humber-res"
                    type="button"
                    aria-expanded={!isNavCollapsed ? true : false}
                    aria-label="Toggle navigation"
                    onClick={handleNavCollapse}
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className={`${
                      isNavCollapsed ? "collapse" : ""
                    } navbar-collapse`}
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav topMenu ml-auto mt-4">
                      {(adminCheck === "ADMIN" || user.Role === "HIPCRD") && (
                        <>
                          <li className="nav-item dropdown">
                            <a
                              href="/kfreshfep/admin-dashboard"
                              className="btn btn-outline-primary btn-block pl-3 text-left nav-text  width-100"
                            >
                              {transProps.home}
                            </a>
                          </li>
                          <li className="nav-item dropdown">
                            <LanguageSelect className="form-control language-select border-brand" />
                          </li>
                          {adminCheck === "ADMIN" && (
                            <li className="nav-item dropdown">
                              <Dropdown>
                                <Dropdown.Toggle className="nav-text">
                                  <span className="d-inline-block mr-3 font-medium16">
                                    {transProps.administration}
                                  </span>
                                  <small className="fa fa-chevron-down mt-2"></small>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  style={{ border: "1px solid #006e52" }}
                                >
                                  <Dropdown.Item href="/kfreshfep/manage-users">
                                    {transProps.manageUsers}
                                  </Dropdown.Item>
                                  <Dropdown.Item href="/kfreshfep/manage-farmer-markets">
                                    {transProps.manageFarmerMarkets}
                                  </Dropdown.Item>
                                  <Dropdown.Item href="/kfreshfep/manage-address">
                                    {transProps.manageCoordinates}
                                  </Dropdown.Item>
                                  <Dropdown.Item href={csaSignupLink}>
                                    {transProps.CSASignup}
                                  </Dropdown.Item>
                                  <Dropdown.Item href="/kfreshfep/partial-signup">
                                    {transProps.partialSignup}
                                  </Dropdown.Item>
                                  <Dropdown.Item href="/kfreshfep/csa-signup-status">
                                    {transProps.CSASignupStatus}
                                  </Dropdown.Item>
                                  <Dropdown.Item href="/kfreshfep/manage-payments">
                                    {transProps.managePayments}
                                  </Dropdown.Item>
                                  <Dropdown.Item href="/kfreshfep/audit-logs">
                                    {transProps.auditLogs}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </li>
                          )}
                        </>
                      )}
                      <li className="nav-item dropdown">
                        <Dropdown>
                          <Dropdown.Toggle className="nav-text">
                            <span className="d-inline-block mr-3 font-medium16">
                              <i className="fa fa-user" aria-hidden="true"></i>{" "}
                              {fullName}
                            </span>{" "}
                            {"  "}
                            <small className="fa fa-chevron-down mt-2"></small>
                          </Dropdown.Toggle>
                          {/* <Dropdown.Menu style={{border:'1px solid #006e52'}}>
                     {user.Role==="VENDOR" && <Dropdown.Item href="/kfreshfep/mystore">My Business</Dropdown.Item>}
                     <Dropdown.Item onClick={() => {tokenRevoke();}}>Logout</Dropdown.Item>
                   </Dropdown.Menu> */}
                          <Dropdown.Menu
                            style={{ border: "1px solid #006e52" }}
                          >
                            {(user.Role === "HIPVDR" ||
                              user.Role === "CSAVDR" ||
                              user.Role === "SUBVDR") && (
                              <Dropdown.Item href={mystoreUrl}>
                                {transProps.myBusiness}
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item
                              onClick={() => {
                                
                                sessionStorage.removeItem("userLogin");
                                sessionStorage.removeItem("userData");
                                sessionStorage.removeItem("appNumber");
                                sessionStorage.removeItem("username");
                                dispatch({ type: "SET_HIP_USER_DETAILS", payload: {} });
                                // if (user.Role === "HIPVDR") tokenRevoke();
                                // else azureLogOut(instance);
                                navigate("/admin");
                              }}
                            >
                              {transProps.logout}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </div>
                </nav>
              </Grid>
            </Grid>
          </>
        ) : userLogin.role === "SENIOR" ? (
          <>
            <Grid container sx={{ display: "flex", alignItems: "center" }}>
              <Grid
                className="logo-title"
                item
                xs={3}
                sm={2.5}
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  textTransform: "initial !important",
                }}
              >
                <Button
                  className="logo-res"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/seniorDashboard")}
                >
                  {/* <NavLink to="/" reloadDocument="true"> */}
                  <span style={{ paddingRight: "10px" }}>
                    {" "}
                    <img src={logo} alt="DTA Finder Logo" className="img-res" />
                  </span>

                  <span
                    className="boldFont page-title header-titlenew"
                    style={{ color: "#f32c2c" }}
                  >
                    {transProps.kfresh
                      ? transProps.kfresh.substring(0, 1)
                      : transProps.kfresh}
                  </span>
                  <span
                    className="boldFont page-title header-titlenew"
                    style={{ color: "#2d6b03" }}
                  >
                    {transProps.kfresh
                      ? transProps.kfresh.substring(1)
                      : transProps.kfresh}
                  </span>
                  {/* </NavLink> */}
                </Button>
              </Grid>
              <Grid
                item
                xs={false}
                sm={9.5}
                md={8}
                className="banner div-center  blue-menu"
              >
                {/* <Grid item xs={12} sx={{ mx: 2,cursor: 'pointer' }} display="flex" alignItems="center"> */}
                <AppBar
                  className="blue-header-root appbar-res"
                  position="static"
                  sx={stlyedHeader}
                >
                  <span className="sr-only">{transProps.headerSection}</span>
                  <Button
                    sx={{ marginRight: "10px" }}
                    className="menu-item font-regular16 textTransform-capitalize btn-shadow-hide-focus homebtn"
                    onClick={() => navigate("/seniorDashboard")}
                    aria-label="Service have the popup listbox"
                  >
                    <span>{transProps.home}</span>
                  </Button>

                  <Button className="menu-item">
                    <LanguageSelect className="menu-item font-regular16" />
                  </Button>
                  <Button
                    className="menu-item textTransform-capitalize"
                    onClick={handleMenuOpen3}
                  >
                    <span className="d-inline-block  mr-2">
                      <i className="fa fa-user" aria-hidden="true"></i>{" "}
                      {userLogin.firstName} {userLogin.lastName}
                    </span>{" "}
                    {"  "}
                    <i
                      className="fa fa-caret-down caretIcon-position"
                      aria-hidden="true"
                    ></i>
                  </Button>
                  {/* <Menu
                    id="simple-user"
                    anchorEl={anchorEl3}
                    keepMounted
                    open={Boolean(anchorEl3)}
                    onClose={handleMenuClose3}
                  >
                    <MenuItem
                      className="language-menu-item font-regular16"
                      onClick={() => {
                        // if (user.Role === "SENIOR") tokenRevoke();
                        // else azureLogOut(instance);

                        navigate("/");
                      }}
                    >
                      Logout
                    </MenuItem>
                  </Menu> */}
                  <Menu
                    id="simple-user"
                    anchorEl={anchorEl3}
                    keepMounted
                    open={Boolean(anchorEl3)}
                    onClose={handleMenuClose3}
                  >
                    <MenuItem
                      className="language-menu-item font-regular16"
                      onClick={() => {
                        // if (user.Role === "HIPVDR") tokenRevoke();
                        // else azureLogOut(instance);
                        
                        sessionStorage.removeItem("userLogin");
                        sessionStorage.removeItem("userData");
                        sessionStorage.removeItem("appNumber");
                        sessionStorage.removeItem("username");
                        dispatch({ type: "SET_HIP_USER_DETAILS", payload: {} });
                        navigate("/");
                      }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </AppBar>
              </Grid>
              <Grid item xs={9} className="blue-hamburger">
                <nav className="header navbar navbar-expand-lg navbar-light blue-wrapper">
                  <button
                    className="navbar-toggler"
                    type="button"
                    aria-expanded={!isNavCollapsed ? true : false}
                    aria-label="Toggle navigation"
                    onClick={handleNavCollapse}
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className={`${
                      isNavCollapsed ? "collapse" : ""
                    } navbar-collapse`}
                    id="navbarSupportedContent"
                  >
                    <ul
                      className="navbar-nav topMenu ml-auto"
                      style={{ zIndex: 9999 }}
                    >
                      {/* <li className="nav-item dropdown">
                        <Dropdown>
                          {(!cookies.validation ||
                            !pathname.includes("registration") ||
                            !cookies.createCSA) && (
                            <Dropdown.Toggle className="nav-text width-100 text-left">
                              <span className="d-inline-block mr-3">
                                {transProps.services}
                              </span>
                              <small className="fa fa-chevron-down mt-2"></small>
                            </Dropdown.Toggle>
                          )}
                          <Dropdown.Menu>
                            <Dropdown.Item href="/kfreshfep">
                              {transProps.seniorProgramFreshReg}{" "}
                              {transProps.spf}
                            </Dropdown.Item>
                            <Dropdown.Item href="/kfresh/csa-landing">
                              {transProps.seniorProgramFresh} {transProps.spf}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li> */}
                      <Button
                        className="menu-item font-regular16 textTransform-capitalize btn-shadow-hide-focus homebtn"
                        onClick={() => navigate("/seniorDashboard")}
                        aria-label="Service have the popup listbox"
                      >
                        <span>{transProps.home}</span>
                      </Button>
                      <li className="nav-item dropdown">
                        <LanguageSelect className="form-control language-select border-brand" />
                      </li>
                      <Button
                        className="menu-item textTransform-capitalize"
                        onClick={handleMenuOpen3}
                      >
                        <span className="d-inline-block  mr-2">
                          <i className="fa fa-user" aria-hidden="true"></i>{" "}
                          {fullName}
                        </span>{" "}
                        {"  "}
                        <i
                          className="fa fa-caret-down caretIcon-position"
                          aria-hidden="true"
                        ></i>
                      </Button>
                      <Menu
                        id="simple-user"
                        anchorEl={anchorEl3}
                        keepMounted
                        open={Boolean(anchorEl3)}
                        onClose={handleMenuClose3}
                      >
                        <MenuItem
                          className="language-menu-item font-regular16"
                          onClick={() => {
                            // if (user.Role === "SENIOR") tokenRevoke();
                            // else azureLogOut(instance);
                            
                           dispatch({ type: "SET_HIP_USER_DETAILS", payload: {} });
                          sessionStorage.removeItem("userLogin");
                          sessionStorage.removeItem("userData");
                          sessionStorage.removeItem("appNumber");
                          sessionStorage.removeItem("username");
                            navigate("/");
                          }}
                        >
                          {transProps.logout}
                        </MenuItem>
                      </Menu>
                    </ul>
                  </div>
                </nav>
              </Grid>
            </Grid>
          </>
        ) : (
          !userLogin && (
            <>
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid
                  className="logo-title"
                  item
                  xs={3}
                  sm={2.5}
                  md={4}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    textTransform: "initial !important",
                  }}
                >
                  <Button
                    className="logo-res"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/")}
                  >
                    {/* <NavLink to="/" reloadDocument="true"> */}
                    <span style={{ paddingRight: "10px" }}>
                      {" "}
                      <img
                        src={logo}
                        alt="DTA Finder Logo"
                        className="img-res"
                      />
                    </span>

                    <span
                      className="boldFont page-title header-titlenew"
                      style={{ color: "#f32c2c" }}
                    >
                      {transProps.kfresh
                        ? transProps.kfresh.substring(0, 1)
                        : transProps.kfresh}
                    </span>
                    <span
                      className="boldFont page-title header-titlenew"
                      style={{ color: "#2d6b03" }}
                    >
                      {transProps.kfresh
                        ? transProps.kfresh.substring(1)
                        : transProps.kfresh}
                    </span>
                    {/* </NavLink> */}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={false}
                  sm={9.5}
                  md={8}
                  className="banner div-center  blue-menu"
                >
                  <AppBar
                    className="blue-header-root appbar-res"
                    position="static"
                    sx={stlyedHeader}
                  >
                    <span className="sr-only">{transProps.headerSection}</span>
                    {/* <Button
                      sx={{ marginRight: "10px" }}
                      className="menu-item font-regular16 textTransform-capitalize btn-shadow-hide-focus homebtn"
                      onClick={() => navigate("/seniorDashboard")}
                      aria-label="Service have the popup listbox"
                    >
                      <span>{transProps.home}</span>
                    </Button> */}

                    <Button className="menu-item">
                      <LanguageSelect className="menu-item font-regular16" />
                    </Button>
                    <Button
                      className="menu-item font-regular16 textTransform-Initial btn-shadow-hide-focus homebtn"
                      onClick={() => navigate("/")}
                      aria-label="Service have the popup listbox"
                    >
                      <span>{transProps.login}</span>
                    </Button>
                  </AppBar>
                </Grid>
                <Grid item xs={9} className="blue-hamburger">
                  <nav className="header navbar navbar-expand-lg navbar-light blue-wrapper">
                    <button
                      className="navbar-toggler"
                      type="button"
                      aria-expanded={!isNavCollapsed ? true : false}
                      aria-label="Toggle navigation"
                      onClick={handleNavCollapse}
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                      className={`${
                        isNavCollapsed ? "collapse" : ""
                      } navbar-collapse`}
                      id="navbarSupportedContent"
                    >
                      <ul
                        className="navbar-nav topMenu ml-auto"
                        style={{ zIndex: 9999 }}
                      >
                        {/* <li className="nav-item dropdown">
                      <Dropdown>
                        {(!cookies.validation ||
                          !pathname.includes("registration") ||
                          !cookies.createCSA) && (
                          <Dropdown.Toggle className="nav-text width-100 text-left">
                            <span className="d-inline-block mr-3">
                              {transProps.services}
                            </span>
                            <small className="fa fa-chevron-down mt-2"></small>
                          </Dropdown.Toggle>
                        )}
                        <Dropdown.Menu>
                          <Dropdown.Item href="/kfreshfep">
                            {transProps.seniorProgramFreshReg}{" "}
                            {transProps.spf}
                          </Dropdown.Item>
                          <Dropdown.Item href="/kfresh/csa-landing">
                            {transProps.seniorProgramFresh} {transProps.spf}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li> */}
                        <Button
                          className="menu-item font-regular16 textTransform-capitalize btn-shadow-hide-focus homebtn"
                          onClick={() => navigate("/seniorDashboard")}
                          aria-label="Service have the popup listbox"
                        >
                          <span>{transProps.home}</span>
                        </Button>
                        <li className="nav-item dropdown">
                          <LanguageSelect className="form-control language-select border-brand" />
                        </li>
                        <Button
                          className="menu-item font-regular16 textTransform-capitalize btn-shadow-hide-focus homebtn"
                          onClick={() => navigate("/")}
                          aria-label="Service have the popup listbox"
                        >
                          <span>{transProps.login}</span>
                        </Button>
                      </ul>
                    </div>
                  </nav>
                </Grid>
              </Grid>
            </>
          )
        )}
      </Grid>
    </>
  );
};

BlueBanner.propTypes = {
  vendorDetails: PropTypes.object,
  mapdata: PropTypes.object,
  userAuth: PropTypes.object,
  authUserDetails: PropTypes.func,
  tokenRevoke: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    authUserDetails: (payload) => dispatch(authUserDetails(payload)),
    tokenRevoke: (payload) => dispatch(tokenRevoke(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BlueBanner);
