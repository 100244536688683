import googlemapReducer from "./GoogleMap";
import registrationReducer from "./registration";
// import dropdownReducer from "./dropdown";
import vendorDetailsReducer from "./vendordetails";
import commentsReducer from "./comments";
import administrationReducer from "./administration";
import { combineReducers } from "redux";
import adminDashboardReducer from "./adminDashboard";
import authReducer from "./authReducer";
import CSASignupReducer from "./csaSignup";
import sideNavigationReducer from "./sideNavigationReducer";
import { seniorInfoReducer } from "./seniorInfo";
import loaderReducer from "./loaderReducer";

export const rootReducer = combineReducers({
  mapdata: googlemapReducer,
  user: registrationReducer,
  vendorDetails: vendorDetailsReducer,
  comments: commentsReducer,
  administration: administrationReducer,
  adminDashboard: adminDashboardReducer,
  userAuth: authReducer,
  csaSignup: CSASignupReducer,
  sideNavStatus: sideNavigationReducer,
  seniorInfoData: seniorInfoReducer,
  loader: loaderReducer,
  // dropdown: dropdownReducer,
  // locTypeQues: dropdownReducer
});

export const mapStateToProps = (state) => {
  return {
    mapdata: state.mapdata,
    loaddata: state.mapdata,
    area: state.mapdata,
    type: state.mapdata,
    order_options: state.mapdata,
    days: state.mapdata,
    months: state.mapdata,
    vendor: state.mapdata,
    sections: state.user.sections,
    user: state.user,
    loctype: state.user,
    locTypeQues: state.user,
    regCity: state.user,
    reg_orderoption: state.user,
    userinfo: state.user,
    regDays: state.user,
    regMonths: state.user,
    storeDetails: state.mapdata,
    specialHour: state.mapdata,
    vendorDetails: state.vendorDetails,
    administration: state.administration,
    adminDashboard: state.adminDashboard,
    userAuth: state.userAuth,
    commentList: state.comments.commentList,
    csaSignup: state.csaSignup,
    sideNavStatus: state.sideNavStatus,
    seniorInfoData: state.seniorInfoData,
  };
};
