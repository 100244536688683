import { Grid, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const SnapCSALocationDetail = ({ row }) => {
  return (
    <Grid container className="collapsibleTable" spacing={2}>
      {row.shareNam && (
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom component="div">
            Share Name List
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {row.shareNam ? row.shareNam : "-"}
          </Typography>
        </Grid>
      )}
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom component="div">
          Latitude
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.latitude ? row.latitude : "-"}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom component="div">
          Longitude
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.longitude ? row.longitude : "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};

SnapCSALocationDetail.propTypes = {
  row: PropTypes.object.isRequired,
};

export default SnapCSALocationDetail;
