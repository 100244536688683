import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import PropTypes from "prop-types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import toast from "react-hot-toast";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataGridTable from "../../../common/DataGrid";
import { AUTH_API } from "../../../config";
import useLoader from "../../../hooks/useLoader";
import axiosInstance from "../../../redux/interceptor";
import { formerPayload } from "../../mystore/tabs/alertOverview/data";

const AlertMarketCord = ({ active }) => {
  const { openLoader, closeLoader } = useLoader();
  const [alertTableData, setAlertTableData] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);

  const marketCordData = JSON.parse(sessionStorage.getItem("userLogin"));
  const getFormerMCId = marketCordData?.vendorId ?? null;

  const schema = yup.object().shape({
    alertMessageFormer: yup.string().required("Reply message is required"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setViewDialogOpen(false);
    setMessage({});
  };

  const handleMoreVertClick = (itemId) => {
    setSelectedItemId(itemId === selectedItemId ? null : itemId);
  };

  useEffect(() => {
    if (active) {
      getSubmittedAlert();
    }
  }, [active]);

  const getSubmittedAlert = async () => {
    try {
      openLoader("alert_get");
      const url = `${AUTH_API}/getAlertsForFarmerAndCoordinator/${getFormerMCId}`;
      const res = await axiosInstance.get(url);
      if (res) {
        const modifiedData = res?.data.map((item, index) => {
          const datePart = item.alertCreatedDate
            ? item.alertCreatedDate.split("T")[0]
            : null;
          const dateUpdatePart = item.alertUpdatedDate
            ? item.alertUpdatedDate.split("T")[0]
            : null;
          const dateDue = item.alertDueDate
            ? item.alertDueDate.split("T")[0]
            : null;
          return {
            ...item,
            id: index + 1,
            alertCreatedDate: datePart,
            alertUpdatedDate: dateUpdatePart,
            alertDueDate: dateDue,
          };
        });

        const updatedAlertTableData = modifiedData.filter(
          (item) => item.deleteInd !== "Y"
        );
        setAlertTableData(updatedAlertTableData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("alert_get");
    }
  };

  const onSubmit = async (data) => {
    const url = `${AUTH_API}/createOrUpdateAlert`;
    try {
      openLoader("alert");
      const payload = formerPayload(data, message, marketCordData?.vendorId);
      const res = await axiosInstance.post(url, payload);
      if (res.status === 200) {
        getSubmittedAlert();
        toast.success("Alert Reply Submitted !");
      }
    } catch (error) {
      toast.error("Filed to Submit!");
      console.log(error);
    } finally {
      closeLoader("alert");
      setTimeout(() => {
        handleClose();
      }, [2000]);
    }
  };

  let dynamicColumns = [
    {
      field: "alertTitle",
      headerName: "Alert Title",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "alertMessage",
      headerName: "Alert Message",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "alertStatus",
      headerName: "Alert Status",
      headerClassName: "grid_header",
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.alertStatus === "SB" && <span>Submit</span>}
            {params?.row?.alertStatus === "CR" && <span>Alert Created</span>}
            {params?.row?.alertStatus === "AP" && <span>Approved</span>}
            {params?.row?.alertStatus === "DE" && <span>Denied</span>}
            {params?.row?.alertStatus === "RD" && <span>Returned</span>}
          </span>
        );
      },
    },
    {
      field: "alertDueDate",
      headerName: "Alert Due Date",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "view",
      headerName: "View",
      headerClassName: "grid_header",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.alertStatus === "AP" && (
              <span
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  padding: "3px",
                  backgroundColor: "green",
                  borderRadius: "3px",
                }}
              >
                Alert Approved
              </span>
            )}
            {params.row.alertStatus === "DE" && (
              <span
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  padding: "3px",
                  backgroundColor: "red",
                  borderRadius: "3px",
                }}
              >
                Alert Denied
              </span>
            )}
            {!(
              params.row.alertStatus === "AP" || params.row.alertStatus === "DE"
            ) && (
              <>
                <IconButton
                  onClick={() =>
                    handleMoreVertClick(params.id ? params.id : null)
                  }
                >
                  <MoreVertIcon />
                </IconButton>
                {selectedItemId === params.id && (
                  <>
                    <IconButton onClick={() => handleViewClick(params.row)}>
                      <Tooltip title="View" placement="right">
                        <VisibilityIcon />
                      </Tooltip>
                    </IconButton>
                  </>
                )}
              </>
            )}
          </>
        );
      },
    },
  ];

  const handleViewClick = async (data) => {
    setMessage(data);
    setViewDialogOpen(true);
  };

  return (
    <div style={{ padding: "20px 10px", border: "1px solid #ccc" }}>
      <Grid container>
        <Grid item xs={2}>
          <Typography
            sx={{ fontSize: "18px", fontWeight: "600", marginBottom: "20px" }}
          >
            Market Coordinator Name:
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
            {`${marketCordData?.firstName}  ${marketCordData?.lastName}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGridTable columns={dynamicColumns} data={alertTableData} />
        </Grid>
      </Grid>
      <Dialog
        open={viewDialogOpen}
        maxWidth="sm"
        fullWidth
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "22px", fontWeight: "600" }}
        >
          Alert
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute !important",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Stack direction={"column"} spacing={4}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Grid item xs={3}>
                      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                        Alert Message
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        name="alertMessageFormer"
                        control={control}
                        defaultValue={message?.alertMessage ?? ""}
                        required
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Write Alert message here"
                            multiline
                            rows={5}
                            fullWidth
                            sx={{ width: "25rem" }}
                            disabled={true}
                            readOnly={true}
                          />
                        )}
                      />
                    </Grid>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Grid item xs={3}>
                      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                        Reply<span style={{ color: "red" }}>*</span>
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        name="replyFormer"
                        control={control}
                        defaultValue={message?.recipientComment ?? ""}
                        required
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={errors.replyFormer?.message}
                            helperText={errors.replyFormer?.message}
                            placeholder="Write Alert message here"
                            multiline
                            rows={5}
                            fullWidth
                            sx={{ width: "25rem" }}
                            disabled={message?.recipientComment}
                          />
                        )}
                      />
                    </Grid>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-end !important"}
                    ml={2}
                  >
                    <div style={{ marginLeft: "20px" }}>
                      <Button
                        autoFocus
                        color="success"
                        variant="contained"
                        type="submit"
                        disabled={message?.recipientComment}
                      >
                        Submit
                      </Button>
                    </div>
                  </Stack>
                </Stack>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AlertMarketCord.propTypes = {
  active: PropTypes.string,
};

export default AlertMarketCord;
