import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "../../redux/interceptor";
import useLoader from "../../hooks/useLoader";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import DataGridTable from "../../common/DataGrid";
import { ReactComponent as SeniorIcon } from "../../assets/icons/elderly.svg";
import { ReactComponent as TribeIcom } from "../../assets/icons/person.svg";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import AgeLimitModel from "./AgeLimitModel";
import { ReactComponent as DateIcon } from "../../assets/icons/date.svg";
import { map } from "lodash";
import toast from "react-hot-toast";

const AgeLimit = () => {
  const { openLoader, closeLoader } = useLoader();
  const [tableRow, setTableRow] = useState([]);
  const [allData, setAllData] = useState({});
  const [edit, setEdit] = useState(false);
  const [ageData, setAgeData] = useState([]);

  const { register, handleSubmit, reset, control, getValues } = useForm({
    defaultValues: {
      seniorAge: "",
      tribalAgeAbove: "",
      tribalAgeBelow: "",
    },
  });

  useEffect(() => {
    // fetchValues();
    getAgeData();
  }, []);

  const getAgeData = async () => {
    try {
      openLoader("fetch_el_cr");
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/staff/eligibility/criteria/seniorAgeHistory`,
        {}
      );

      if (res?.status === 200) {
        let values = res?.data.map((item, index) => ({
          ...item,
          idx: index + 1,
        }));
        setTableRow(values);
      }
    } catch (error) {
      //
    } finally {
      closeLoader("fetch_el_cr");
    }
  };

  const handleOpenEdit = (data) => {
    setAgeData(data);
    setEdit(!edit);
  };
  const handleCloseEdit = () => {
    setEdit(false);
  };

  const COLOMNS = [
    {
      field: "effectiveBeginDt",
      headerName: "Begin Date",
      flex: 1,
      valueGetter: (value) => {
        if (value) {
          return dayjs(value).format("YYYY-MM-DD");
        } else {
          return "-";
        }
      },
    },
    {
      field: "effectiveEndDt",
      headerName: "End Date",
      flex: 1,
      valueGetter: (value) => {
        if (dayjs(value).year() !== 9999) {
          return dayjs(value).format("YYYY-MM-DD");
        } else {
          return "-";
        }
      },
    },

    {
      field: "propValue",
      headerName: "Senior Age",
      flex: 1,
    },
    {
      field: "propValue1",
      headerName: "Tribal Above",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        if (params.row?.idx === 1) {
          return (
            <>
              <IconButton onClick={() => handleOpenEdit(params.row)}>
                <Tooltip title="Edit" placement="right">
                  <ModeEditOutlineOutlinedIcon />
                </Tooltip>
              </IconButton>
            </>
          );
        } else {
          <></>;
        }
      },
    },
  ];

  const clear = () => {
    reset({
      seniorAge: 0,
      tribalAgeAbove: 0,
      tribalAgeBelow: 0,
    });
  };

  const fetchValues = async () => {
    try {
      openLoader("fetch_el_cr");
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/staff/eligibility/criteria`,
        {}
      );

      if (res?.status === 200) {
        const values = res?.data;
        setAllData(values);
        const keyObj = Object.fromEntries(
          map(values, (v) => [v.uniqueKey, v.propValue])
        );
        reset({
          seniorAge: keyObj[1],
          tribalAgeAbove: keyObj[2],
          tribalAgeBelow: keyObj[3],
        });
      }
    } catch (error) {
      //
    } finally {
      closeLoader("fetch_el_cr");
    }
  };

  const onSubmit = async (data) => {
    // if (data.tribalAgeBelow > data.tribalAgeAbove) {
    //   popUpAlert({
    //     title: "Error!",
    //     text: "Upper Age Limit should be greater than or equal to Lower Age Limit",
    //     icon: "error",
    //     showCancelButton: false,
    //     showConfirmButton: true,
    //     confirmButtonClass: "btn-danger",
    //     confirmButtonText: "Ok!",
    //     closeOnConfirm: false,
    //   });
    // } else if (data.seniorAge <= data.tribalAgeAbove) {
    //   popUpAlert({
    //     title: "Error!",
    //     text: "Senior Age Limit should be greater than Upper Age Limit",
    //     icon: "error",
    //     showCancelButton: false,
    //     showConfirmButton: true,
    //     confirmButtonClass: "btn-danger",
    //     confirmButtonText: "Ok!",
    //     closeOnConfirm: false,
    //   });
    // } else {
    openLoader("sub_el_cr");
    const { seniorAge, tribalAgeAbove, tribalAgeBelow } = data;
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/staff/save/eligibility/criteria`,
        [
          {
            id: 1,
            propKey: "SeniorAge",
            propValue: seniorAge,
            uniqueKey: 1,
            effectiveBeginDt: getValues("effectiveBeginDt"),
          },
          {
            id: 3,
            propKey: "TribalAgeAbove",
            propValue: tribalAgeBelow,
            uniqueKey: 3,
            effectiveBeginDt: getValues("effectiveBeginDt"),
          },
        ]
      );
      if (res.status === 200) {
        getAgeData();
      }
    } catch (error) {
      toast.error("Age Limit already exists");
      console.log(error);
    } finally {
      closeLoader("sub_el_cr");
    }
    // }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <Typography
            sx={{ fontWeight: 600, fontSize: "24px" }}
            display="inline"
            className="text-capitalize"
            gutterBottom
            component="div"
          >
            Age Limit
          </Typography>
        </Stack>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
              border: "1px solid grey",
              borderRadius: "1rem",
              padding: "1rem",
            }}
          >
            <Grid
              container
              xs={12}
              sx={{
                border: "1px solid grey",
                borderRadius: "1rem",
                padding: "1rem",
              }}
            >
              <Grid
                xs={4}
                item
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Form.Group
                  as={Col}
                  controlId="seniorAge"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <SeniorIcon />
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    Age of Senior
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <Form.Control
                      placeholder="Enter Senior Age"
                      // type="number"
                      {...register("seniorAge", { valueAsNumber: true })}
                    />
                  </Box>
                </Form.Group>
              </Grid>

              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                xs={4}
              >
                <Form.Group
                  as={Col}
                  controlId="tribalAgeBelow"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TribeIcom />
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    Tribal Age
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <Form.Control
                      placeholder="Enter Tribal Age"
                      // type="number"
                      {...register("tribalAgeBelow", {
                        valueAsNumber: true,
                      })}
                    />
                  </Box>
                </Form.Group>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
                xs={4}
              >
                <DateIcon />
                <Typography
                  fontWeight="fontWeightBold"
                  className="typography"
                  variant="h6"
                >
                  Effective Start Date
                </Typography>
                <Box>
                  <Controller
                    name="effectiveBeginDt"
                    control={control}
                    required
                    render={({ field }) => (
                      <>
                        <DatePicker
                          {...field}
                          disablePast
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                          format="YYYY/MM/DD"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          type="date"
                          placeholder="Select Date"
                        />
                      </>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
            >
              <Button
                variant="outlined"
                sx={{ color: "#6C6C6C", border: "1px solid #6C6C6C" }}
                onClick={clear}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                type="submit"
                style={{
                  textTransform: "none",
                  width: "100px",
                  background: "#457425",
                  color: "#FFF",
                }}
              >
                Set Limit
              </Button>
            </Box>
          </Box>
        </Form>

        <Box>
          <DataGridTable columns={COLOMNS} data={tableRow} />
        </Box>
      </Box>
      {edit && (
        <AgeLimitModel
          editData={ageData}
          open={edit}
          handleClose={handleCloseEdit}
          getAgeData={getAgeData}
        />
      )}
    </>
  );
};

export default AgeLimit;
