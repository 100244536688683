import React from 'react';
import LandingBg from "../../assets/images/bgimage.png";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const SearchMarkets = () => { 
 const navigate = useNavigate();
  return (
    <>
      <div className="image-container">
        <img
          style={{
            width: "100%",
            height: "70vh",
            objectFit: "cover",
          }}
          src={LandingBg}
          alt="Descriptive Alt Text"
        />
        <div className="welcome_content">
          <h1>
            <b>Michigan Senior Project Fresh Program</b>
          </h1>
          <span className="w-100">
            <b>
              Provides benefits to lower-income older adults to purchase locally
              grown fruits, vegetables, honey, and herbs
            </b>
          </span>
          <button onClick={() => navigate("/kfreshfep")}>Search Market</button>
        </div>
      </div>
    </>
  );
}

export default SearchMarkets