import React, { useState, useEffect, useRef } from "react";
// import Header from "./common/header/Header";
// import Footer from "./common/footer/Footer";
import { connect } from "react-redux";
// import { GET_USERS } from "../../redux/actionTypes";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import SearchComponent from "./search-component";
import { GoogleMapComponent } from "./GoogleMapComponent";
// import { Row, Col, Container, Tabs, Tab, Badge } from "react-bootstrap";
import mapLogo from "../../assets/images/map-svgrepo-com@2x.png";
import listViewLogo from "../../assets/images/list-view-svgrepo-com@2x.png";
import MapKeyComponent from "./mapkey-component";
import ListViewResult from "./ListViewResult";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";

import "./listView.css";
import ModalComponent from "../common/components/modal";
import csa_farms from "../../assets/images/marker/csa-farms.png";
import farm_stands from "../../assets/images/marker/farm-stands.png";
import farmers_market from "../../assets/images/marker/farmers-market-managers.png";
import farmers_market_managers from "../../assets/images/marker/market-managers.png";
import mobile_market from "../../assets/images/marker/mobile-market.png";
import blue_cluster from "../../assets/images/marker/blue-cluster.png";
import yellow_cluster from "../../assets/images/marker/yellow-cluster.png";
import { Button } from "react-bootstrap";
import useTranslator from "../../hooks/useTranslator";

const getImageTooltip = (name, lang) => {
  switch (name) {
    case "csaPickupSite":
      return {
        img: csa_farms,
        tooltip: lang["CSA Program Tooltip"], //"A place where you can pick up farm-fresh HIP produce through a weekly membership program. You must sign up with the farm in advance."
      };
    case "farmersMarketManagers":
      return {
        img: farmers_market_managers,
        tooltip: lang["Farmer Market Tooltip"], //"A place where you can buy HIP produce from multiple farm tables by checking out at a central location."
      };
    case "farmStand":
      return {
        img: farm_stands,
        tooltip: lang["Farm Stand Tooltip"], //"A stand or store where a farm sells HIP produce, usually located at or near the farm."
      };
    case "mobileMarket":
      return {
        img: mobile_market,
        tooltip: lang["Market Stop Tooltip"], //"A pop-up location where you can buy fresh HIP produce delivered by a farm retailer."
      };
    case "farmersMarket":
      return {
        img: farmers_market,
        tooltip: lang["Market Booth Tooltip"], //"A table at a farmers&apos; market where you can buy HIP produce directly from a farm. Some booths at the market may not process HIP – check to make sure!"
      };
    default:
      return {
        img: csa_farms,
        tooltip:
          "A place where you can pick up farm-fresh HIP produce through a weekly membership program. You must sign up with the farm in advance.",
      };
  }
};
const getLocationType = (data) => {
  data?.map((item) => {
    if (item.store.storeType === "HIP Farmers Market Booth")
      item["location_types_name"] = "farmersMarket";
    else if (item.store.storeType === "HIP Farmers Market")
      item["location_types_name"] = "farmersMarketManagers";
    else if (item.store.storeType === "HIP CSA PROGRAM")
      item["location_types_name"] = "csaPickupSite";
    else if (item.store.storeType === "HIP Farm Stand")
      item["location_types_name"] = "farmStand";
    else if (item.store.storeType === "HIP Mobile Market Stop")
      item["location_types_name"] = "mobileMarket";
  });
  return data;
};

function CustomTabPanel(props) {
  const { children, value, index, isClearSearch, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ position: "relative" }}
      {...other}
      className="m-2"
    >
      {isClearSearch && (
        <>
          {value === index && (
            <Box sx={{ ...(value === 1 && { pl: 2, pr: 2 }) }}>
              <Typography className="mb-2 mt-2">{children}</Typography>
            </Box>
          )}
        </>
      )}
      {!isClearSearch && (
        <Box sx={{ ...(value === 1 && { pl: 2, pr: 2 }) }}>
          <Typography className="mb-2 mt-2">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  isClearSearch: PropTypes.bool,
};

const DashboardComponent = (props) => {
  const transProps = useTranslator(
    [
      "resultInfo",
      "resultPop",
      "mapView",
      "listView",
      "stateWide",
      "within",
      "from",
      "yourLocation",
      "locations",
      "noVendorAvailable",
      "FEPCSAProgram",
      "SPFFarmStand",
      "SPFFarmersMarketBooth",
      "SPFMobileMarketStop",
      "SPFFarmersMarket",
      "numberofSPFVendorLocations",
    ],
    "dashBoard"
  );
  const [staticText, setStaticText] = useState(false);
  const [showMapKeyList, setShowMapKeyList] = useState(true);
  const [searchProperties, setSearchProperties] = useState();

  const [result, setResult] = useState([]);
  const [isLoadMapData, setIsLoadMapData] = useState(false);
  const [coordinates, setCoordinates] = useState();
  const [modalShown, toggleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showLocationModal, setShowLocationModal] = useState();
  const [locationId, setLocationId] = useState(null);
  const [clickedMarkerClusterer, setClickedMarkerClusterer] = useState([]);
  const [mapRefresh, setMapRefresh] = useState(true);
  const [isLocTypeClicked, setIsLocTypeClicked] = useState(false);
  const mapKeyRef = useRef(null);
  const [mapKeyTooltip, setMapKeyTooltip] = useState();
  // const executeScroll = () => mapKeyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const wrapperRef = useRef(null);
  const isListViewDisabled = result?.length <= 0;
  const [isClearSearch, setIsClearSearch] = useState(false);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setMapKeyTooltip(0);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let data = props?.mapdata?.storesList;
    setResult(getLocationType(data));
  }, [props?.mapdata?.storesList, isLoadMapData]);

  const updateBooleanValue = (value) => {
    setStaticText(value);
    setIsLoadMapData(!isLoadMapData);
    setValue(0);
    setMapRefresh(!mapRefresh);
    setClickedMarkerClusterer([]);
    setIsLocTypeClicked(locationId ? true : false);
    setLocationId(null);
  };

  const [value, setValue] = useState(0);

  useEffect(() => {
    if (locationId) {
      setValue(1);
    }
  }, [locationId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const renderModal = () => {
    return (
      <Typography mb={2} lineHeight="lg">
        <Typography
          variant="body2"
          display="inline"
          sx={{ fontFamily: "Texta-book", fontSize: "18px" }}
        >
          {transProps.resultInfo}
        </Typography>
      </Typography>
    );
  };

  return (
    <Grid
      container
      style={{
        display: "flex",
        flexDirection: "row",
        padding: "20px 10px 40px 10px",
        background: "#FFF",
      }}
    >
      <Grid
        lg={6}
        md={12}
        sm={12}
        xs={12}
        item
        className=" search-criteria bg-lightgreen map-area"
        style={{ borderBottom: "1px solid #ddd", width: "32rem" }}
      >
        {modalShown && (
          <ModalComponent
            shown={modalShown}
            close={() => {
              toggleModal(false);
            }}
            title={transProps.resultPop}
            modalBody={renderModal}
            size={"lg"}
            centered={true}
            label={transProps.resultPop}
          />
        )}

        <SearchComponent
          updateBooleanValue={updateBooleanValue}
          searchProperties={setSearchProperties}
          coordinates={setCoordinates}
          showLocationModal={setShowLocationModal}
          setIsLoading={setIsLoading}
          resultShown={staticText}
          isClearSearch={(data) => {
            setIsClearSearch(data);
            setIsLocTypeClicked(!data);
          }}
        />

        {/* <MapComponent /> */}
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        {staticText && (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                sx={{
                  "& .Mui-selected": {
                    backgroundColor: "#f6f6f6",
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#006E52",
                  },
                  background: "#FFFF",
                }}
                value={value}
                onChange={handleChange}
                aria-label="Result section has Map view and List View"
                className="dashboard-tabs"
              >
                <Tab
                  label={
                    <div className="tab-header">
                      <img
                        alt="mapIcon"
                        src={mapLogo}
                        style={{ width: "27px" }}
                      />
                      <span className="m-l-5">{transProps.mapView}</span>
                    </div>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    <div
                      className={`${
                        isListViewDisabled ? "disable-tab" : "tab-header"
                      }`}
                    >
                      <img
                        alt="ListIcon"
                        src={listViewLogo}
                        style={{ width: "22px" }}
                      />
                      <span className="m-l-5">{transProps.listView}</span>
                    </div>
                  }
                  {...a11yProps(1)}
                  disabled={isListViewDisabled}
                />
              </Tabs>
            </Box>
            {props?.mapdata && (
              <Grid
                xs
                className="searchMessage"
                style={{ position: "relative" }}
              >
                <small
                  tabIndex={0}
                  id="allSearchRslt"
                  className="boldFont text-brand"
                >
                  <i className="fa fa-map-marker mr-1"></i>
                  <span id="rangeMsg">
                    {searchProperties.area.id === ""
                      ? transProps.stateWide
                      : transProps.within + " " + searchProperties.area?.label}
                  </span>
                  &nbsp;{transProps.from}&nbsp;
                  <span id="cityMsg">
                    {searchProperties.area.id === ""
                      ? "Michigan"
                      : searchProperties.is_mylocation
                      ? transProps.yourLocation
                      : searchProperties.city}
                  </span>
                  ,
                  <strong>
                    <span id="storeCountMsg">
                      {props?.mapdata?.storesLocationCount &&
                      props?.mapdata?.storesLocationCount
                        ? " " +
                          // props?.mapdata?.storesTotalCount +
                          // " " +
                          // " " +
                          props?.mapdata?.storesLocationCount +
                          " " +
                          transProps.locations
                        : " " + transProps.noVendorAvailable}
                    </span>
                  </strong>{" "}
                  <span id="storeLocation"> </span>
                  {result !== undefined && result?.length > 0 && (
                    <button
                      style={{
                        border: "none",
                        background: "#fff",
                        padding: 0,
                      }}
                      aria-label="Information"
                      className="resultInfo"
                      onClick={() => {
                        toggleModal(!modalShown);
                      }}
                    >
                      <i
                        className="fa fa fa-info-circle ml-2"
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                </small>
              </Grid>
            )}
            <CustomTabPanel
              value={value}
              index={0}
              isClearSearch={isClearSearch}
            >
              {/*<MapKeyComponent
              isOpened={showMapKeyList}
              setIsOpened={setShowMapKeyList}
              isClass={true}
                  />*/}
              <GoogleMapComponent
                mapResult={result}
                searchProperties={searchProperties}
                coordinates={coordinates}
                mapRefresh={mapRefresh}
                clickedMarkerClusterer={setClickedMarkerClusterer}
                isLoading={isLoading}
                locationId={setLocationId}
                isLocTypeClicked={isLocTypeClicked}
                setValue={setValue}
              />
            </CustomTabPanel>
            <CustomTabPanel
              value={value}
              index={1}
              isClearSearch={isClearSearch}
            >
              <ListViewResult
                mapRefresh={mapRefresh}
                mapResult={result}
                locationId={locationId}
                filterClusterer={clickedMarkerClusterer}
              />
            </CustomTabPanel>

            {windowDimensions.width < 768 && (
              <div
                ref={mapKeyRef}
                className="col-md-12 d-md-none d-lg-none d-sm-block bg-white legendSection"
                id="legendCollapse"
              >
                {/* <div className="card card-body  border-bottom-0 p-0"> */}
                {/* <div className="row"> */}
                {/* <div className="col-md-12 d-md-none d-lg-none d-sm-block bg-white legendSection"> */}
                <h4 className="resText mt-3" style={{ marginLeft: "10px" }}>
                  {transProps.mapView}
                </h4>
                <ul className="nav navbar-nav m-0 p-0">
                  <li className="dropdown nav-item pb-3">
                    <ul
                      className="p-0 legendsMenu typesSection typesSection-sm"
                      ref={wrapperRef}
                    >
                      {props.mapdata.vendorType !== undefined &&
                        props.mapdata.vendorType.map((item, i) => (
                          <li
                            tabIndex={0}
                            key={i}
                            onKeyPress={() => {
                              // if(e.key === "Enter") {
                              //     props.onMapKeyClick(item.name);
                              // }
                            }}
                            onClick={() => {
                              setMapKeyTooltip(item.addId);
                              // props.onMapKeyClick(item.name);
                            }}
                            className="p-2 border-bottom cursor-pointer tooltip"
                          >
                            <div className="row m-0">
                              {/* <OverlayTrigger
                                  key={i}
                                  placement="right"
                                  overlay={
                                          <Tooltip id={`tooltip-${i}`}>
                                              <div>{getImageTooltip(item.addId)["tooltip"]}</div>
                                          </Tooltip>
                              
                                  }
                                  > */}
                              <Button variant="light" className="btn-to-div">
                                <div className="p-0">
                                  <span className="p-2">
                                    <img
                                      src={getImageTooltip(item.addId)["img"]}
                                      width="24"
                                      className="mx-2"
                                      alt="img"
                                    />{" "}
                                  </span>
                                  <small className="boldFont ">
                                    {transProps[item.values]}
                                  </small>
                                  <span className="pull-right text-muted info-icon p-2">
                                    <i
                                      className="fa fa-info-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span
                                    className={`tooltiptextMobile ${
                                      mapKeyTooltip != item.addId ||
                                      mapKeyTooltip == 0
                                        ? "d-none"
                                        : ""
                                    }`}
                                  >
                                    {getImageTooltip(item.addId)["tooltip"]}
                                  </span>
                                </div>
                              </Button>
                              {/* </OverlayTrigger> */}
                            </div>
                          </li>
                        ))}
                      <li>
                        <div className="pt-2">
                          <div className="d-inline-block text-center pl-2 float-left">
                            <img
                              src={blue_cluster}
                              width="34"
                              className="mr-1 ml-2"
                              aria-label="Blue Cluster"
                              alt="img"
                            />
                            <p className="mb-0 text-center font-14">(2-9)</p>
                          </div>
                          <div className="d-inline-block text-center float-left">
                            <img
                              src={yellow_cluster}
                              width="34"
                              className="mr-2"
                              aria-label="Yellow Cluster"
                              alt="img"
                            />
                            <p className="mb-0 text-center font-14">(10+)</p>
                          </div>

                          <div className="d-inline-block text-center float-left  verticalAlign">
                            <p className="mt-3 font-16">
                              {transProps.numberofSPFVendorLocations}
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
                {/* </div> */}
                {/* </div> */}
                {/* </div> */}
              </div>
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

DashboardComponent.propTypes = {
  // users: PropTypes.array.isRequired,
  mapdata: PropTypes.object,
  loading: PropTypes.bool,
};

// export default DashboardComponent;
export default connect(mapStateToProps)(DashboardComponent);
