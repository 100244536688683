import * as React from "react";
import ReactECharts from "echarts-for-react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

// eslint-disable-next-line no-unused-vars
const GraphGrid = styled(Grid)(({ theme }) => ({
  flex: "1 1 auto",
  padding: "20px",
  border: "1px solid rgba(0,0,0,.125)",
  borderRadius: "0.25rem",
}));

const GraphComponent = (props) => {
  // const legend = [
  //   // "SPF Program",
  //   "SPF Roadside Stand",
  //   // "SPF Farmers' Market Booth",
  //   "SPF Farmers' Market",
  //   // "SPF Mobile Market Stop",
  // ];

  const option =
    props.graphType === "pie"
      ? {
          title: {
            text: "",
            subtext: "",
            x: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b}<br> {c} ({d}%)",
          },
          toolbox: {
            show: false,
          },
          legend: {
            orient: "horizontal",
            left: "center",
            data: props.legend,
          },
          color: ["#c23531", "#2f4554", "#61a0a8", "#d48265", "#91c7ae"],
          series: [
            {
              name: "Store Location Types",
              type: "pie",
              radius: "50%",
              center: ["55%", "65%"],
              label: {
                formatter: "{c} ({d}%)",
              },
              labelLine: {
                normal: {
                  show: true,
                  length1: 0.0,
                  length2: 0.1,
                  color: "#2d3e50",
                },
                emphasis: {
                  show: true,
                },
              },
              data: props.chartData,
            },
          ],
        }
      : props.graphType === "bar"
      ? {
          title: {
            text: "",
            subtext: "",
            x: "center",
          },
          legend: {
            left: "center",
            data: props.legend, //['HIP CSA Program', 'HIP Farm Stand', "HIP Farmers' Market Booth","HIP Farmers' Market",'HIP Mobile Market Stop']
          },
          color: ["#c23531", "#2f4554", "#61a0a8", "#d48265", "#91c7ae"],
          toolbox: {
            show: false,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              animation: false,
              label: {
                backgroundColor: "#505765",
              },
            },
          },
          dataZoom: [
            {
              type: "slider",
              show: true,
              xAxisIndex: [0, 1],
              start: 1,
              end: 100,
            },
            {
              type: "inside",
              xAxisIndex: [0, 1],
              start: 1,
              end: 35,
            },
          ],
          dataset: {
            source: props.chartData,
          },
          grid: {
            // top:'35%'
            top: "40%",
            right: "12%",
          },
          xAxis: {
            type: "category",
            name: "County",
          },
          yAxis: {
            name: "Count",
            nameGap: "5",
          },
          series: [
            { type: "bar" },
            { type: "bar" },
            // { type: "bar" },
            // { type: "bar" },
            // { type: "bar" },
          ],
        }
      : {
          tooltip: {
            position: "top",
          },
          animation: false,
          grid: {
            height: "75%",
            top: "0%",
            left: "26%",
            right: "5%",
            width: "60%",
          },
          xAxis: {
            type: "category",
            name: "Months",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            splitArea: {
              show: true,
            },
          },
          yAxis: {
            type: "category",
            name: "Location type",
            data: props.legend,
            splitArea: {
              show: true,
            },
          },
          visualMap: {
            min: 0,
            max: props.maxNumber,
            show: false,
            calculable: true,
            orient: "horizontal",
            left: "center",
            bottom: "0%",
          },
          series: [
            {
              name: "Store",
              type: "heatmap",
              data: props.chartData,
              label: {
                show: true,
                color: "#000000",
              },
              textStyle: {
                color: "#000",
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };

  return (
    <GraphGrid>
      <Typography mb={2} variant="body1" align="center" gutterBottom>
        {props.title}
      </Typography>
      <ReactECharts option={option} />
    </GraphGrid>
  );
};

GraphComponent.propTypes = {
  title: PropTypes.string.isRequired,
  graphType: PropTypes.string.isRequired,
  chartData: PropTypes.array.isRequired,
  maxNumber: PropTypes.number,
  legend: PropTypes.array.isRequired,
};

export default GraphComponent;
