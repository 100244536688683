import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import { daysOfOperation } from "../../../constants/constants";

const CancelDetails = ({ row }) => {
  const filterDay = (dayValue) => {
    let tempData = daysOfOperation.filter((data) => data.weekDay === dayValue);

    if (tempData.length > 0) {
      return tempData[0].values;
    } else {
      return [];
    }
  };
  return (
    <Grid container className="collapsibleTable" spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h6" gutterBottom component="div">
          Description
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.dsc ? row.dsc : "-"}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom component="div">
          Weight/Amount of Goods
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.shareQty}
        </Typography>
      </Grid>
      {/* <Grid item xs={4}>     {(row.shareQtyUnit === "qty") ? "Units" : "Pounds"}
                <Typography variant="h6" gutterBottom component="div">
                     Farm Contact Info
                 </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {row.contactInfo ? row.contactInfo : '-'}
                </Typography>
            </Grid> */}
      <Grid item xs={6}>
        <Typography variant="h6" gutterBottom component="div">
          Pickup/Delivery Location
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.address1 || row.address2 || row.city || row.state || row.zipcode
            ? [row.address1, row.address2, row.city, row.state, row.zipcode]
                .filter((value) => value !== undefined && value !== "")
                .join(", ")
            : "-"}
        </Typography>
      </Grid>
      {row.day && (
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom component="div">
            Pickup Day/Time
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <AccessTimeRoundedIcon fontSize="small" />
            {[filterDay(row?.day), row?.openTime, row?.closeTime]
              .filter((value) => value !== undefined && value !== "")
              .join("- ") || "-"}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

CancelDetails.propTypes = {
  row: PropTypes.object.isRequired,
};
export default CancelDetails;
