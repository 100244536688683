import React from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";

function TextButton({ onClick, icon, children }) {
  return (
    <Stack className="textButton" component="button" onClick={onClick}>
      {icon}
      <p className="txtBtnTitle">{children}</p>
    </Stack>
  );
}

TextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default TextButton;
