import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
} from "@mui/material";
import { Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { map } from "lodash";
import axiosInstance from "../../redux/interceptor";
import useLoader from "../../hooks/useLoader";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import useLookup from "../../hooks/useLookup";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { ReactComponent as StateAmountIcon } from "../../assets/icons/things_to_do.svg";
import PropTypes from "prop-types";

const FederalAmountModel = ({
  open,
  handleClose,
  editData,
  countyData,
  getCountyData,
}) => {
  const { openLoader, closeLoader } = useLoader();
  const { register, handleSubmit, reset, control, getValues } = useForm({
    defaultValues: {
      federalAmount: countyData?.fedAmount ?? "",
      stateAmount: countyData?.stateAmount ?? "",
      propValue: countyData?.code ?? "",
    },
  });

  //   useEffect(() => {
  //     reset({
  //       federalAmount: countyData?.fedAmount ?? "",
  //       stateAmount: countyData?.stateAmount ?? "",
  //       propValue: "",
  //       effectiveBeginDt: countyData?.effectiveBeginDt ?? null,
  //     });
  //   }, [countyData]);

  const { getOptions } = useLookup("marketRegistration");

  const updateData = async () => {
    const payload = {
      id: countyData?.id,
      code: getValues("propValue"),
      fedAmount: getValues("federalAmount"),
      stateAmount: getValues("stateAmount"),
      effectiveBeginDt: getValues("effectiveBeginDt"),
    };
    try {
      openLoader("fetch_el_cr");
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/staff/update/fedAndStateAmount`,
        payload
      );
      if (res.status === 200) {
        handleClose();
        getCountyData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("fetch_el_cr");
    }
  };

  const onSubmit = async (data) => {
    const payload = {
      // propKey: "State Amount",
      fedAmount: data?.federalAmount,
      stateAmount: data?.stateAmount,
      effectiveBeginDt: data?.effectiveBeginDt,
      code: data?.propValue,
    };
    try {
      openLoader("fetch_el_cr");
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/staff/save/fedAndStateAmount`,
        payload
      );
      if (res.status === 200) {
        handleClose();
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("fetch_el_cr");
    }
  };

  const { countyOpts } = useMemo(() => {
    return {
      countyOpts: getOptions("county"),
    };
  }, [getOptions]);

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        scroll="paper"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ paddingBottom: "50px" }}
      >
        <DialogTitle id="responsive-dialog-title">Amount limits</DialogTitle>
        <DialogContent>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                border: "1px solid grey",
                borderRadius: "1rem",
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                gap: "2rem",
              }}
              xs={12}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  gap: "1rem",
                }}
                xs={12}
              >
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                  xs={12}
                >
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <LocationOnOutlinedIcon />
                    <Typography
                      fontWeight="fontWeightBold"
                      className="typography"
                      variant="h6"
                    >
                      Choose County
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </Typography>
                    <Select
                      fullWidth
                      {...register("propValue")}
                      placeholder="Select County"
                      value={countyData?.code}
                    >
                      {map(countyOpts, (item) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <CalendarMonthOutlinedIcon />
                    <Typography
                      fontWeight="fontWeightBold"
                      className="typography"
                      variant="h6"
                    >
                      Effective Start Date
                    </Typography>
                    <Controller
                      name="effectiveBeginDt"
                      control={control}
                      defaultValue={
                        countyData?.effectiveBeginDt
                          ? dayjs(countyData?.effectiveBeginDt)
                          : null
                      }
                      required
                      render={({ field }) => (
                        <>
                          <DatePicker
                            {...field}
                            disablePast
                            slotProps={{
                              textField: {
                                error: false,
                              },
                            }}
                            format="YYYY/MM/DD"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="date"
                            placeholder="Enter due date"
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <AccountBalanceOutlinedIcon />
                    <Typography
                      fontWeight="fontWeightBold"
                      className="typography"
                      variant="h6"
                    >
                      Federal Amount
                    </Typography>
                    <Form.Group as={Col} controlId="federalAmount">
                      <Form.Control
                        placeholder="Enter Amount"
                        {...register("federalAmount", { valueAsNumber: true })}
                      />
                    </Form.Group>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <StateAmountIcon />
                    <Typography
                      fontWeight="fontWeightBold"
                      className="typography"
                      variant="h6"
                    >
                      State Amount
                    </Typography>
                    <Form.Group as={Col} controlId="stateAmount">
                      <Form.Control
                        placeholder="Enter Amount"
                        {...register("stateAmount", { valueAsNumber: true })}
                      />
                    </Form.Group>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    alignItems: "flex-end",
                    flexDirection: "column",
                    display: "flex",
                  }}
                  xs={12}
                >
                  <Grid item xs={12} sx={{ display: "flex", gap: "10px" }}>
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      sx={{ color: "#6C6C6C", border: "1px solid #6C6C6C" }}
                    >
                      Close
                    </Button>
                    {!countyData && (
                      <Button
                        // sx={{ ml: "auto", mt: "1rem", mr: "1rem" }}
                        variant="contained"
                        type="submit"
                        style={{
                          textTransform: "none",
                          width: "100px",
                          background: "#457425",
                          color: "#FFF",
                        }}
                      >
                        Allocate
                      </Button>
                    )}
                    {countyData && (
                      <Button
                        variant="contained"
                        style={{
                          textTransform: "none",
                          width: "100px",
                          background: "#457425",
                          color: "#FFF",
                        }}
                        onClick={updateData}
                      >
                        Update
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>

              {/* <Button
        // sx={{ ml: "auto", mt: "1rem", mr: "1rem" }}
        variant="contained"
        color="success"
        onClick={update}
        style={{ textTransform: "none", width: "150px" }}
      >
        Update Amount
      </Button> */}
            </Box>
          </Form>
        </DialogContent>
        {/* <DialogActions></DialogActions> */}
      </Dialog>
    </>
  );
};
FederalAmountModel.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  editData: PropTypes.bool,
  countyData: PropTypes.func,
  getCountyData: PropTypes.func,
};

export default FederalAmountModel;
