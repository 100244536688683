import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  TextField,
  Typography,
  Grid,
  Stack,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@mui/material";
import "../../styles/login.scss";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { AUTH_API } from "../../config";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ForgotPassword from "./ForgotPassword";
import OtpPassword from "./OtpPassword";
import useLoader from "../../hooks/useLoader";
import toast from "react-hot-toast";
import marketIcon from "../../assets/images/marketicon.png";
import seniorIcon from "../../assets/images/senioricon.png";
import farmerIcon from "../../assets/images/farmericon.png";
import { useDispatch } from "react-redux";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

const Login = ({ handleSignupDialog }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState("senior");
  const [captchaCode, setCaptchaCode] = useState("");
  const { openLoader, closeLoader } = useLoader();
  const [email, setEmail] = useState("");
  const [screen, setScreen] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = Yup.object({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
    // captcha: Yup.string().required("Captcha is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      captcha: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      // if (!validateCaptcha(values.captcha)) {
      //   toast.error("Invalid CAPTCHA");
      //   return;
      // }
      if (selectedRole === "farmer") {
        handleVendorLogin(values);
      } else if (selectedRole === "senior") {
        handleSeniorLogin(values);
      } else {
        handleMarketCoLogin(values);
      }
    },
  });

  const handleVendorLogin = async (values) => {
    const payload = {
      email: values.email,
      password: values.password,
    };
    sessionStorage.setItem("ext_url", "csa");
    try {
      openLoader("farmer_login");
      const res = await axios.post(AUTH_API + "/farmer/login", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const userData = {
        Name:
          res &&
          res?.data?.userData?.firstName + " " + res?.data?.userData?.lastName,
        Role: res && res?.data?.userData?.role,
        id: res && res?.data?.userData?.id,
        vendorId: res && res?.data?.userData?.vendorId,
      };
      if (res.data.code == "200") {
        sessionStorage.setItem("userLogin", JSON.stringify(res.data.userData));
        sessionStorage.setItem("userData", JSON.stringify(userData));
        sessionStorage.setItem("username", res.data.userData.email);
        dispatch({ type: "SET_HIP_USER_DETAILS", payload: userData });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        if (res.data.userData.role === "HIPVDR") {
          navigate("/kfreshfep/mystore/hip");
        } else {
          navigate("/kfreshfep/mystore/csa");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Login failed: Incorrect email or password");
    } finally {
      closeLoader("farmer_login");
    }
  };

  const handleSeniorLogin = async (values) => {
    const payload = {
      email: values.email,
      password: values.password,
    };
    try {
      openLoader("senior_login");
      const res = await axios.post(AUTH_API + "/senior/login", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const userData = {
        Name:
          res &&
          res?.data?.userData?.firstName + " " + res?.data?.userData?.lastName,
        Role: res && res?.data?.userData?.role,
        id: res && res?.data?.userData?.id,
        vendorId: res && res?.data?.userData?.vendorId,
        lastUpdtUidNam: res && res?.data?.userData?.lastUpdtUidNam,
      };
      if (res.data.code == "200") {
        sessionStorage.setItem("userLogin", JSON.stringify(res.data.userData));
        sessionStorage.setItem("userData", JSON.stringify(userData));
        sessionStorage.setItem("username", res.data.userData.email);
        dispatch({ type: "SET_HIP_USER_DETAILS", payload: userData });
        navigate("/seniorDashboard");
      }
    } catch (error) {
      console.log(error);
      toast.error("Login failed: Incorrect email or password");
    } finally {
      closeLoader("senior_login");
    }
  };

  const handleMarketCoLogin = async (values) => {
    openLoader("market_login");
    sessionStorage.setItem("ext_url", "csa");
    try {
      const payload = {
        email: values.email,
        password: values.password,
      };
      const res = await axios.post(
        AUTH_API + "/marketcoordinator/login",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const userData = {
        Name:
          res &&
          res?.data?.userData?.firstName + " " + res?.data?.userData?.lastName,
        Role: res && res?.data?.userData?.role,
        id: res && res?.data?.userData?.id,
        vendorId: res && res?.data?.userData?.vendorId,
        lastUpdtUidNam: res && res?.data?.userData?.lastUpdtUidNam,
      };

      if (res.data.code == "200") {
        sessionStorage.setItem("userLogin", JSON.stringify(res.data.userData));
        sessionStorage.setItem("userData", JSON.stringify(userData));
        dispatch({ type: "SET_HIP_USER_DETAILS", payload: userData });
        sessionStorage.setItem("username", res.data.userData.email);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate("/marketDashboard");
      }
    } catch (error) {
      console.log(error);
      toast.error("Login Failed!");
    } finally {
      console.log("finally");
      closeLoader("market_login");
    }
  };

  const handleOpenSignUpReg = () => {
    if (selectedRole === "senior") {
      handleSignupDialog();
    } else if (selectedRole === "farmer") {
      navigate("/kfreshfep/registration");
    } else {
      navigate("/marketRegistration");
    }
  };

  const renderScreen = (screen) => {
    if (screen === "forgot_screen") {
      return (
        <ForgotPassword
          screen={screen}
          setScreen={setScreen}
          setEmail={setEmail}
        />
      );
    }
    if (screen === "otp_screen") {
      return (
        <OtpPassword
          screen={screen}
          setScreen={setScreen}
          setEmail={setEmail}
          email={email}
          role={selectedRole}
        />
      );
    }
  };

  function getClassName(role) {
    return `icon_role ${selectedRole === role ? "active" : ""}`;
  }

  const handleRoleClick = (role) => {
    setSelectedRole(role);
  };

  // useEffect(() => {
  //   loadCaptchaEnginge(6, "grey");
  // }, []);

  return (
    <>
      <Grid
        container
        component="main"
        className="login-root"
        // sx={{ padding: "0 10px !important" }}
      >
        <Grid item xs={12} sm={12} md={12} square pb={5}>
          <Container maxWidth="xs">
            {screen === "" ? (
              <div className="login-paper">
                <h2 className="font-bold boldFont pb-1">Sign In</h2>
                <Stack
                  component="form"
                  className="login-form w-100"
                  onSubmit={formik.handleSubmit}
                >
                  Please select the role
                  <Stack direction="row" justifyContent="space-around">
                    <div
                      className={getClassName("senior")}
                      onClick={() => handleRoleClick("senior")}
                    >
                      <p>Senior</p>
                      <img src={seniorIcon} alt="senior" loading="lazy" />
                    </div>
                    <div
                      className={getClassName("coordinator")}
                      onClick={() => handleRoleClick("coordinator")}
                    >
                      <p>Coordinator</p>
                      <img src={marketIcon} alt="coordinator" loading="lazy" />
                    </div>
                    <div
                      className={getClassName("farmer")}
                      onClick={() => handleRoleClick("farmer")}
                    >
                      <p>Farmer</p>
                      <img src={farmerIcon} alt="farmer" loading="lazy" />
                    </div>
                  </Stack>
                  <Box sx={{ marginTop: "0.5rem" }}>
                    <TextField
                      fullWidth
                      id="email"
                      placeholder="Enter Email Id or Mobile Number"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.email && formik.errors.email}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Box>
                  <Box sx={{ marginTop: "0.5rem" }}>
                    <TextField
                      fullWidth
                      name="password"
                      placeholder="Enter your password"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.password && formik.errors.password}
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Remember me"
                    />
                    <Typography
                      sx={{ cursor: "pointer", color: "#44A0E3 !important" }}
                      onClick={() => setScreen("forgot_screen")}
                    >
                      Forgot Password
                    </Typography>
                  </Stack>
                  {/* <Box sx={{ marginTop: "0.2rem" }}>
                    <div className="captcha-container">
                      <LoadCanvasTemplate />
                    </div>
                  </Box> */}
                  {/* <Box>
                    <TextField
                      fullWidth
                      id="captcha"
                      name="captcha"
                      placeholder="Enter CAPTCHA"
                      value={formik.values.captcha}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.captcha && formik.errors.captcha}
                      helperText={
                        formik.touched.captcha && formik.errors.captcha
                      }
                    />
                  </Box> */}
                  <Button className="login-button" type="submit">
                    Login
                  </Button>
                </Stack>

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  my={0.5}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    OR
                  </Typography>
                  <Typography variant="body1">
                    {`Don't you have an account?`}{" "}
                    <span
                      style={{ color: "#44A0E3", cursor: "pointer" }}
                      onClick={handleOpenSignUpReg}
                    >
                      Sign Up
                    </span>
                  </Typography>
                </Box>
              </div>
            ) : (
              renderScreen(screen)
            )}
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

Login.propTypes = {
  handleSignupDialog: PropTypes.func,
};

export default Login;
