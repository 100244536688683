import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useFieldArray, Controller } from "react-hook-form";
import {  Box } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import dayjs from "dayjs";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {Row, Col} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { isNumber } from "../../../utils/globalMethods";

const ShareNestedFieldArray = ({ getValues,setValue,nestIndex, watch, control, register, setPreCalc, setIsShareRowEdit, triggerPrecal }) => { //console.log(shareInfo);
    const [isEditing, setIsEditing] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const w = watch();

    const { fields, remove, append } = useFieldArray({
        control,
        name: `share.${nestIndex}.nestedArray`
    });
   
    const onChangeEdit = (e,item,k, isUndoChange) =>{
        if (!isUndoChange) {
            setIsEditing((prevState)=>({
                ...prevState,
                [nestIndex+k] : true
            }));
            let editInfo = {};
            if (isEditing) {
                editInfo = isEditing;
                editInfo[nestIndex+k] = true;
            } else {
                editInfo[nestIndex+k] = true;
            }
            console.log(editInfo);
            setIsShareRowEdit(editInfo);
            // setIsShowPrecalButton((prevState)=>({
            //     ...prevState,
            //     [nestIndex] : false
            // }));
        } else {
            console.log(item);
            setIsEditing((prevState)=>({
                ...prevState,
                [nestIndex+k] : false
            }));
            let editInfo = {};
            if (isEditing) {
                editInfo = isEditing;
                editInfo[nestIndex+k] = false;
            } else {
                editInfo[nestIndex+k] = false;
            }
            setIsShareRowEdit(editInfo);
            setIsShareRowEdit(isEditing);
            setValue(`share.${nestIndex}.nestedArray.${k}.cost`, item?.cost);
            setValue(`share.${nestIndex}.nestedArray.${k}.type`, item?.type);
            setValue(`share.${nestIndex}.nestedArray.${k}.startDate`, item?.startDate);
            setValue(`share.${nestIndex}.nestedArray.${k}.endDate`, item?.endDate);
            // setIsShowPrecalButton((prevState)=>({
            //     ...prevState,
            //     [nestIndex] : true
            // }));
        } 
        triggerPrecal({id: item.shareId});
    };

    function getAllMonthYear(startDate, endDate) {
        // const date = new Date(startDate.getTime()); 
        // const dates=[];     
        // while (date <= endDate) {
        //   dates.push(dayjs(date,"YYYY-MM-DD").format("MM/YYYY"));
        // }      
        // return dates;
        const betweenMonths = [];
        if (startDate <= endDate){
            var date = startDate.startOf('month');
         
            while (date < endDate.endOf('month')) {
               betweenMonths.push(date.format('YYYY-MM'));
               date.add(1,'month');
            }
        }
        return betweenMonths;
    }

    const onChangeSave = (e,item,k) =>{
        // console.log(e);
        // console.log(item);
        // console.log(getValues(`share.${nestIndex}.nestedArray.${k}.endDate`));
        const shares = getValues()['share'];
        // console.log(shares);
        const dailyShare = shares.find(data=>data.id==item.shareId).nestedArray.filter(share=>share.type=="1");
        if (dailyShare && dailyShare.length) {
            const dates = dailyShare.map(e=>e.endDate);
            const isDuplicateDate = new Set(dates.map(v => v));
            if (isDuplicateDate.size < dates.length) {
                setIsError(true);
                setErrMsg("Selected date already exist. Please select other date");
                return;
            } else {
                setIsError(false);
                setErrMsg("");
            }
        }

        const mnthlyShare = shares.find(data=>data.id==item.shareId).nestedArray.filter(share=>share.type=="2");
        // console.log(mnthlyShare)
        if (mnthlyShare && mnthlyShare.length) {
            // console.log(mnthlyShare)
            const allDates = [];  

            mnthlyShare.map((data)=>{
                const d1 = moment(dayjs(data.startDate, "MM/DD/YYYY").format("YYYY-MM-DD"));
                const d2 = moment(dayjs(data.endDate, "MM/DD/YYYY").format("YYYY-MM-DD"));
                
                const dates = getAllMonthYear(d1, d2);
                if (dates && dates.length) {
                    dates.map(dat =>allDates.push(dat));
                }
                return data;
            });
            // console.log(allDates)
            const isDuplicateMonth = new Set(allDates.map(v => v));
            // console.log(isDuplicateMonth);
            if (isDuplicateMonth.size < allDates.length) {
                setIsError(true);
                setErrMsg("Selected month is already exist. Please select other month");
                return;
            } else {
                setIsError(false);
                setErrMsg("");
            }
        }

        setIsEditing((prevState)=>({
            ...prevState,
            [nestIndex+k] : false
        }));
        setPreCalc(getValues(`share.${nestIndex}.nestedArray.${k}`));
        let editInfo = {};
        if (isEditing) {
            editInfo = isEditing;
            editInfo[nestIndex+k] = false;
        } else {
            editInfo[nestIndex+k] = false;
        }
        console.log(editInfo);
        setIsShareRowEdit(editInfo);
        triggerPrecal({id: item.shareId});
    }

    useEffect(()=>{
        register('share');
    },[register]);

    const handleFrequery = (e,k)=>{
        console.log(w);
        setValue(`share.${nestIndex}.nestedArray.${k}.type`, e.target.value);
        if (e.target.value == 1) { 
            const startDt = getValues(`share.${nestIndex}.nestedArray.${k}.startDate`);
            setValue(`share.${nestIndex}.nestedArray.${k}.endDate`, startDt);
        }
    }

    const appendShare = (item) => {
        const shares = getValues()['share'];
        const dailyShare = shares.find(data=>data.id==item.shareId).nestedArray.filter(share=>share.type=="1");
        if (dailyShare && dailyShare.length) {
           const dates = dailyShare.map(e=>e.endDate);
           const datesArray = dates.map((element) => new Date(dayjs(element,'MM/DD/YYYY').format("YYYY-MM-DD")));
           const maxDate = new Date(Math.max(...datesArray));
           const date = dayjs(maxDate).add(1, 'day').format('MM/DD/YYYY');
           append({ shareId: item.shareId, cost: item.cost, type: 1, startDate: date, endDate: date })
        } else {
            append({ shareId: item.shareId, cost: item.cost, type: 1, startDate: item.startDate, endDate: item.startDate });
        }
        triggerPrecal({id: item.shareId});
    }

    return (
        <Box>
            {isError && (<span className='dh-txt-danger' style={{marginTop: '5px', marginBottom: '5px' }}>
                <i className="fa fa-info-circle"></i> {errMsg}
            </span>)}
            {fields.map((item, k) => { 
                return (
                    <div className="form-block" key={item.id}>
                        <Row>
                            <Col md={2}> 
                                <TextField className="hide" sx={{ width: '70px' }} {...register(`share.${nestIndex}.nam`)} defaultValue={item.nam}/>
                                <TextField className="hide" sx={{ width: '70px' }} {...register(`share.${nestIndex}.dsc`)} defaultValue={item.dsc}/>
                                <TextField className="hide" sx={{ width: '70px' }} {...register(`share.${nestIndex}.shareId`)} defaultValue={item.shareId}/>
                                <Form.Group as={Col} controlId="cost">
                                    <Form.Label className="smallTitle">Cost *</Form.Label>
                                    <Form.Control type="number" className="formcontrol-year" size="md" {...register(`share.${nestIndex}.nestedArray.${k}.cost`,
                                     { required: true })} onKeyDown={isNumber} maxLength={10} disabled={!isEditing?.[nestIndex+k]} 
                                      />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter Cost.
                                    </Form.Control.Feedback>                                  
                                </Form.Group>
                            </Col>
                            <Col md={2}> 
                                <Form.Group as={Col} controlId="frequency">
                                    <Form.Label className="smallTitle">Frequency *</Form.Label>
                                    <Form.Select
                                        disabled={!isEditing?.[nestIndex+k]}
                                        defaultValue={1}
                                        className="form-control"
                                        autocomplete="off"
                                        {...register(`share.${nestIndex}.nestedArray.${k}.type`, { required: true })}
                                        onChange={(e) =>handleFrequery(e,k)}
                                    >
                                        <option value={1}>Daily</option>
                                        <option value={2}>Monthly</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={2}> 
                                <Form.Group as={Col} controlId="startDate">
                                    <Form.Label className="smallTitle">Start Duration *</Form.Label>
                                    {isEditing?.[nestIndex+k] ?(
                                        <LocalizationProvider dateAdapter={AdapterDayjs} disabled={!isEditing?.[nestIndex+k]} sx={{"&.MuiStack-root": { paddingTop: "0 !important"}}}>
                                                <DemoContainer components={['DatePicker']} sx={{"& .MuiTextField-root": { minWidth: "130px !important"}, 
                                                "&.MuiStack-root": { paddingTop: "0 !important", overflow:"hidden" },
                                               }}>

                                                <Controller
                                                    control={control}
                                                    name={`share.${nestIndex}.nestedArray.${k}.startDate`}
                                                    {...register(`share.${nestIndex}.nestedArray.${k}.startDate`, { required: true })}
                                                    onChange={(date) => {
                                                        if (getValues(`share.${nestIndex}.nestedArray.${k}.type`) == 1) {
                                                            setValue(`share.${nestIndex}.nestedArray.${k}.endDate`,dayjs(date).format("MM/DD/YYYY"));
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            name={`share.${nestIndex}.nestedArray.${k}.startDate`}
                                                            refs={register(`share.${nestIndex}.nestedArray.${k}.startDate`, { required: true })}
                                                            minDate={dayjs()} 
                                                            defaultValue={dayjs(item?.startDate)}
                                                            selected={getValues(`share.${nestIndex}.nestedArray.${k}.startDate`)}
                                                            value={dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.startDate`))}
                                                            onChange={(date) => {
                                                                field.onChange(dayjs(date).format("MM/DD/YYYY"))
                                                                if (getValues(`share.${nestIndex}.nestedArray.${k}.type`) ==1) {
                                                                    setValue(`share.${nestIndex}.nestedArray.${k}.endDate`,dayjs(date).format("MM/DD/YYYY"));
                                                                } else {
                                                                    if (dayjs(date) > dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.endDate`))) {
                                                                        setValue(`share.${nestIndex}.nestedArray.${k}.endDate`,dayjs(date).format("MM/DD/YYYY"));
                                                                    }
                                                                }
                                                            }}
                                                            sx={{ "& .MuiInputBase-input.MuiOutlinedInput-input": { padding:"9.5px 12px !important" }}}
                                                        />
                                                    )}
                                                />
                                                </DemoContainer>
                                        </LocalizationProvider> ) : 
                                        <Form.Control type="text" value={getValues(`share.${nestIndex}.nestedArray.${k}.startDate`)} className={!isEditing?.[nestIndex+k] ? "grey" : ""} />
                                    }
                                </Form.Group>
                            </Col>
                            <Col md={2}> 
                                <Form.Group as={Col} controlId="endDate">
                                    <Form.Label className="smallTitle">End Duration *</Form.Label>
                                    {isEditing?.[nestIndex+k] ?(
                                        <LocalizationProvider dateAdapter={AdapterDayjs} disabled={!isEditing?.[nestIndex+k]} sx={{"&.MuiStack-root": { paddingTop: "0 !important"}}}>
                                                <DemoContainer components={['DatePicker']} sx={{"& .MuiTextField-root": { minWidth: "130px !important"}, 
                                                "&.MuiStack-root": { paddingTop: "0 !important", overflow:"hidden" }}}>
                                                    <Controller
                                                        control={control}
                                                        name={`share.${nestIndex}.nestedArray.${k}.endDate`}
                                                        {...register(`share.${nestIndex}.nestedArray.${k}.endDate`, { required: true })}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                name={`share.${nestIndex}.nestedArray.${k}.endDate`}
                                                                refs={register(`share.${nestIndex}.nestedArray.${k}.endDate`, { required: true })}
                                                                minDate={dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.startDate`))}
                                                                maxDate={getValues(`share.${nestIndex}.nestedArray.${k}.type`) ==1 ? dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.startDate`)) : dayjs().add(1, 'year')} 
                                                                defaultValue={getValues(`share.${nestIndex}.nestedArray.${k}.type`) ==1 ? dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.startDate`)) : (dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.endDate`)) < dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.startDate`)) ? dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.startDate`)) : dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.endDate`)))}
                                                                value={getValues(`share.${nestIndex}.nestedArray.${k}.type`) ==1 ? dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.startDate`)) : (dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.endDate`)) < dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.startDate`)) ? dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.startDate`)) : dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.endDate`)))}
                                                                onChange={(date) => {
                                                                    field.onChange(dayjs(date).format("MM/DD/YYYY"));
                                                                    setValue(`share.${nestIndex}.nestedArray.${k}.endDate`,dayjs(date).format("MM/DD/YYYY"));
                                                                }}
                                                                selected={field.value}
                                                                sx={{ "& .MuiInputBase-input.MuiOutlinedInput-input": { padding:"9.5px 12px !important" }}}
                                                            />
                                                        )}
                                                    />
                                                </DemoContainer>
                                        </LocalizationProvider> ) : 
                                        <Form.Control type="text" defaultValue={getValues(`share.${nestIndex}.nestedArray.${k}.type`) ==1 ? getValues(`share.${nestIndex}.nestedArray.${k}.startDate`) : (dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.endDate`)) < dayjs(getValues(`share.${nestIndex}.nestedArray.${k}.startDate`)) ? getValues(`share.${nestIndex}.nestedArray.${k}.startDate`) : getValues(`share.${nestIndex}.nestedArray.${k}.endDate`))} className={!isEditing?.[nestIndex+k] ? "grey" : ""} />
                                    }
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label className="smallTitle"></Form.Label>
                                    <div>
                                        {!isEditing?.[nestIndex+k] && <EditIcon className="cursor-pointer" onClick={(e)=>onChangeEdit(e,item,k,false)}>{isEditing?.[nestIndex+k] ? 'Editing...' : 'Edit'}</EditIcon> }&nbsp;
                                        {isEditing?.[nestIndex+k] && <><SaveIcon className="cursor-pointer" onClick={(e)=> onChangeSave(e,item,k)}/>
                                        <DoDisturbIcon className="cursor-pointer" onClick={(e)=> onChangeEdit(e,item,k,true)} /> </>} &nbsp;&nbsp;                                 
                                        <AddCircleIcon className="cursor-pointer" type="button" onClick={() =>appendShare(item)} />&nbsp;
                                        { k != 0 ? <RemoveCircleOutlineIcon className="cursor-pointer" type="button" onClick={() => { 
                                            remove(k);
                                            triggerPrecal({id: item.shareId});
                                            setIsError('')
                                         }} /> : ""}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                );
            })}
        </Box>

    );
};

ShareNestedFieldArray.propTypes = {
    nestIndex: PropTypes.number.isRequired,
    control: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    shareInfo: PropTypes.object.isRequired,
    getValues:PropTypes.func,
    setValue: PropTypes.func,
    watch: PropTypes.func,
    setPreCalc: PropTypes.func,
    setIsShareRowEdit: PropTypes.func,
    triggerPrecal: PropTypes.func
}

export default ShareNestedFieldArray;
