import React from 'react'
import help from '../../assets/images/help_human.png'

const Help = () => {
  return (
    <div className="help_container">
      <div className="help_content">
        <h1>How Can We Help</h1>
        <ul>
          <li>Access to Fresh Produce</li>
          <div>
            Provides coupons or vouchers for seniors to purchase fresh fruits,
            vegetables, herbs, and honey from approved local vendors.
          </div>
          <li>Nutritional Support</li>
          <div>
            Encourages consumption of nutritious foods, which can improve
            seniors overall health and well-being.
          </div>
          <li>Access to Fresh Produce</li>
          <div>
            Supports local farmers and the economy by promoting sales at
            farmers markets and other direct-to-consumer outlets.
          </div>
          <li>Social Support</li>
          <div>
            Offers seniors an opportunity to engage with their community,
            interact with local farmers, and participate in events promoting
            healthy lifestyles.
          </div>
        </ul>
      </div>
      <div className="help_image_content">
        <img src={help} />
      </div>
    </div>
  );
}

export default Help