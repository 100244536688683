import { takeLatest, put, call } from "redux-saga/effects";
import { API_URL, PUBLIC_API } from "../../config";
import axiosInstance from "../interceptor";
import { actionTypes } from "../actionTypes/csaSignup";
import { popUpAlert } from "../../utils/globalMethods";
import { ExportClientPartialSignup, ExportClientPaymentList, ExportCsaSignupStatus, ExportCsaAgreementList } from "../../pages/common/components/exportExcel";

function* fetchCsaVendorListSaga(props) {
    let url = `${PUBLIC_API}/csaSnapFlag/vendor/list`; ///csaSnapFlag/vendor/list`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.SET_CSA_VENDOR_LIST, payload: result.data.data });
    }
    catch (e) {
        // yield put({ type: actionTypes.FETCH_CSA_VENDOR_LIST_FAILURE, payload: e });
    }
}
function* fetchGetAddUserListSaga(props) {
    let url = `${API_URL}/vendor/add/user`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.SET_ADD_USER_LIST, payload: result.data });       
    }
    catch (e) {
        console.log(e.response.data);

        popUpAlert({
            title: "Error",
            text: e?.response?.data?.message ? e?.response?.data?.message : "Failed to Process the request!",
            icon: "error",
            timer:1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
        // yield put({ type: actionTypes.FETCH_CSA_VENDOR_LIST_FAILURE, payload: e });
    }
}

function* fecthVendorSubUserListSaga(props) {
    let url = `${API_URL}/vendor/list/user`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.SET_VENDOR_SUB_USER_LIST, payload: result.data });
    }
    catch (e) {
        //console.log("failed");
    }
}
function* updateSubUserSaga(props) {
    let url =`${API_URL}/vendor/convert/user`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        if (result.data.code === "200") {
            yield put({ type: actionTypes.REFRESH_KEY });
            popUpAlert({
                title: "Successfully",
                text: "User conversion done successfully!",
                icon: "success",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        } else {
            popUpAlert({
                title: "Error",
                text: "Failed to Process the request!",
                icon: "error",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }
    }
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed to Process the request!",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* deleteSubUserSaga(props) {
    let url = `${API_URL}/vendor/delete/user`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);

        if (result.data.code == 200) {
            yield put({ type: actionTypes.REFRESH_KEY });
            popUpAlert({
                title: "Success",
                text: "Sub User Vendor has been removed from the application",
                icon: "success",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        } else {
            popUpAlert({
                title: "Error",
                text: "Failed to delete the Sub User!",
                icon: "error",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }
    }
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed to delete the Sub User!",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* fetchSnapCsaLocationListSaga(props) {
    let url = `${API_URL}/snapCsaLocation/list`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        if(props.payload.all){
            yield put({ type: actionTypes.SET_ALL_SNAP_CSA_LOCATION_LIST, payload: result.data });
        }else{
            yield put({ type: actionTypes.SET_SNAP_CSA_LOCATION_LIST, payload: result.data });
        }
    }
    catch (e) {
        //yeild put
    }
}

function* addSnapCsaLocationSaga(props) {
    let url = `${API_URL}/snapCsaLocation/add`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        if (result.data.code == 200) {
            popUpAlert({
                title: "Successfully",
                text: " SNAP CSA Location added successfully!",
                icon: "success",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
            yield put({ type: actionTypes.REFRESH_KEY });
        } else {
            popUpAlert({
                title: "Error",
                text: "Failed to Process the request!",
                icon: "error",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }
    }
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed to Process the request!",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* editSnapCSALocationListSaga(props) {
    let url = `${API_URL}/snapCsaLocation/update`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        if (result.data.code == 200) {
            popUpAlert({
                title: "Successfully",
                text: " SNAP CSA Location updated successfully!",
                icon: "success",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
            yield put({ type: actionTypes.REFRESH_KEY });
        } else {
            popUpAlert({
                title: "Error",
                text: "Failed to Process the request!",
                icon: "error",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }
    }
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed to Process the request!",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* deleteSnapCSALocationSaga(props) {
    let url = `${API_URL}/snapCsaLocation/delete`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);

        if (result.data.code == 200) {
            popUpAlert({
                title: "Success",
                text: "SNAP CSA Location has been removed from the application",
                icon: "success",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
            yield put({ type: actionTypes.REFRESH_KEY });
        } else {
            popUpAlert({
                title: "Error",
                text: "Failed to delete the SNAP CSA Location!",
                icon: "error",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }
    }
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed to delete the SNAP CSA Location!",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}
function* fetchManageCsaVendorListSaga(props) {
    let url = `${API_URL}/manage/csaVendor/list`; ////manage/csaVendor/list`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        // return false;
        yield put({ type: actionTypes.SET_MANAGE_CSA_VENDOR_LIST, payload: result.data });
    }
    catch (e) {
        // yield put({ type: actionTypes.FETCH_CSA_VENDOR_LIST_FAILURE, payload: e });
    }
}

function* fetchGetCientPickupLocationListSaga(props) {
    let url = `${API_URL}/vendor/pickup/list`; ///vendor/pickup/list`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        // return false;
        yield put({ type: actionTypes.SET_CLIENT_PICKUP_LOCATION_LIST, payload: result.data });
    }
    catch (e) {
        // yield put({ type: actionTypes.FETCH_CSA_VENDOR_LIST_FAILURE, payload: e });
    }
}

function* getPickupLocationListSaga(props) {
    let url = `${API_URL}/pickup/list`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.SET_PICKUP_LOCTION_LIST, payload: result.data });
    }
    catch (e) {
        // yield put({ type: actionTypes.FETCH_CSA_VENDOR_LIST_FAILURE, payload: e });
    }
}

function* fetchGetClientAgreementListSaga(props) {
    let url = `${API_URL}/client/agreementList`; ////client/agreementList`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        // return false;
        yield put({ type: actionTypes.SET_CLIENT_AGREEMENT_LIST, payload: result.data });
    }
    catch (e) {
        // yield put({ type: actionTypes.FETCH_CSA_VENDOR_LIST_FAILURE, payload: e });
    }
}

function* fetchGetExportClientAgreementListSaga(props) {
    let url = `${API_URL}/export/clientAgreement`; ////client/agreementList`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        if(result.data.code == 200){
            ExportCsaAgreementList(result.data.data);
        }else{
            popUpAlert({
                text: "No Data Found!",
                icon: "warning",
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: "OK",
            });
        }
    }
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* fetchGetClientPaymentListSaga(props) {
    let url = `${API_URL}/client/paymentList`; ////client/paymentList`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        console.log(result.data.data);
        // return false;
        yield put({ type: actionTypes.SET_CLIENT_PAYMENT_LIST, payload: result.data });
    }
    catch (e) {
        // yield put({ type: actionTypes.FETCH_CSA_VENDOR_LIST_FAILURE, payload: e });
    }
}

function* fetchCsaVendorInfoSaga(props) {
    let url = `${PUBLIC_API}/csaSnapFlag/vendor/info`; ///csaSnapFlag/vendor/list`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        console.log(result.data.data);
        // return false;
        yield put({ type: actionTypes.SET_CSA_VENDOR_INFO, payload: result.data.data[0] });
    }
    catch (e) {
        // yield put({ type: actionTypes.FETCH_CSA_VENDOR_LIST_FAILURE, payload: e });
    }
}

function* fetchClientInfoSaga(props) {
    let url = `${PUBLIC_API}/csaSignupApi/getSnapAndHipAmount`; ///csaSnapFlag/vendor/list`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.SET_CLIENT_INFO, payload: result.data });
    }
    catch (e) {
        yield put({ type: actionTypes.SET_CLIENT_INFO, payload: e.response.data });
    }
}

function* fetchCsaShareListSaga(props) {
    let url = `${PUBLIC_API}/csaSignup/shareInfo/list`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.SET_CSA_SHARE_LIST, payload: result.data.data });
    }
    catch (e) {
        // yield put({ type: actionTypes.FETCH_CSA_VENDOR_LIST_FAILURE, payload: e });
    }
}

function* fetchClientAgrmntShrLstSaga(props) {
    let url = `${PUBLIC_API}/client/agreement/shareList`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.SET_CLIENT_AGRMNT_SHR_LST, payload: result.data.data });
    }
    catch (e) {
        // yield put({ type: actionTypes.FETCH_CSA_VENDOR_LIST_FAILURE, payload: e });
    }
}

// RE
function* updateCancelAgreementShareSaga(props) {
    let url = `${PUBLIC_API}/cancel/agreement/share`;
    try {
        const uri = url;
        yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.REFRESH_KEY });
        popUpAlert({
            title: "Successfully",
            text: "Cancel Agreement Share has been updated successfully!",
            icon: "success",
            timer:1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    } 
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed to Process the request!",
            icon: "error",
            timer:1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* fetchGetDocumentSaga(props) {
    let url = `${PUBLIC_API}/csaSignupApi/notices`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.SET_DOUCMENT, payload: result.data.data });
    }
    catch (e) {
        // yield put({ type: actionTypes.FETCH_CSA_VENDOR_LIST_FAILURE, payload: e });
        // console.log(e, 'the error occured')
    }
}

function* fetchSnapShareSaga(props) {
    let url = `${PUBLIC_API}/payment/schedule/precalculation`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.SET_SNAP_SHARE, payload: result.data.data });
    }
    catch (e) {
        // yield put({ type: actionTypes.FETCH_CSA_VENDOR_LIST_FAILURE, payload: e });
        // console.log(e, 'the error occured')
    }
}

function* fetchGetSignatureSaga(props) {
    let url = `${PUBLIC_API}/csaSignupApi/getESignature`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        console.log(result)
        yield put({ type: actionTypes.SET_SIGNATURE, payload: result.data });
    }
    catch (e) {
        yield put({ type: actionTypes.SET_SIGNATURE, payload: e.response.data });
        // yield put({ type: actionTypes.FETCH_CSA_VENDOR_LIST_FAILURE, payload: e });
        // console.log(e, 'the error occured')
    }
}

function* fetchRequestHelpSaga(props) {
    let url = `${PUBLIC_API}/signup/needHelp`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.SET_REQUEST_HELP, payload: result.data });
    }
    catch (e) {
        // yield put({ type: actionTypes.FETCH_CSA_VENDOR_LIST_FAILURE, payload: e });
        // console.log(e, 'the error occured')
    }
}

function* fetchSnapHipAmountSaga(props) {
    let url = `${PUBLIC_API}/csaSignupApi/getSnapAndHipAmount`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        console.log(result.data.data);
        yield put({ type: actionTypes.SET_SNAP_HIP_AMOUNT, payload: result.data.data });
    }
    catch (e) {
        // yield put({ type: actionTypes.FETCH_CSA_VENDOR_LIST_FAILURE, payload: e });
    }
}
function* getCancelAgreementListSaga(props) {
    let url =`${PUBLIC_API}/client/cancelAgreementList`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post,uri,props.payload);
        yield put({ type: actionTypes.SET_CANCEL_AGREEMENT_LIST,payload: result.data})
    }
    catch (e) {
        //yield
        if (e?.response?.data?.code === "508") {
            popUpAlert({
                title: "Error",
                text: e?.response?.data?.message ? e?.response?.data?.message : "Client does not exist!",
                icon: "error",
                timer:1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }
        yield put({ type: actionTypes.SET_CANCEL_AGREEMENT_LIST,payload:[]})
    }
}

function* fetchVendorShareListSaga(props) {
    let url = `${API_URL}/shareInfo/list`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        console.log(result.data.data);
        yield put({ type: actionTypes.SET_VENDOR_SHARE_LIST, payload: result.data });
    }
    catch (e) {
        // yield put({ type: actionTypes.FETCH_VENDOR_SHARE_LIST, payload: e });

    }
}
function* addVendorShareListSaga(props) {
    let url = `${API_URL}/shareInfo/add`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.REFRESH_KEY });
        if(result.data.code == 200){
            popUpAlert({
                title: "Successfully",
                text: "Share List has been added successfully!",
                icon: "success",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }else{
            popUpAlert({
                title: "Error",
                text: "Failed to Process the request!",
                icon: "error",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }
    }
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed to Process the request!",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* editVendorShareListSaga(props) {
    let url = `${API_URL}/shareInfo/update`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        if (result.data.code == 200) {
            popUpAlert({
                title: "Successfully",
                text: " Share Details updated successfully!",
                icon: "success",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
            yield put({ type: actionTypes.REFRESH_KEY });
        } else {
            popUpAlert({
                title: "Error",
                text: "Failed to Process the request!",
                icon: "error",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }
    }
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed to Process the request!",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* deleteVendorShareListSaga(props) {
    let url = `${API_URL}/shareInfo/delete`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);

        if (result.data.code == 200) {
            popUpAlert({
                title: "Success",
                text: "Share Details has been removed from the application",
                icon: "success",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
            yield put({ type: actionTypes.REFRESH_KEY });
        } else {
            popUpAlert({
                title: "Error",
                text: "Failed to delete the Share!",
                icon: "error",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }
    }
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed to delete the Share!",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* addShareLangSaga(props) {
    let url = `${API_URL}/share/lang/add`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.REFRESH_KEY });
        if(result.data.code == 200){
            popUpAlert({
                title: "Successfully",
                text: "Other language has been added to Share successfully!",
                icon: "success",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }else{
            popUpAlert({
                title: "Error",
                text: "Failed to Process the request!",
                icon: "error",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }
    }
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed to Process the request!",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* editShareLangSaga(props) {
    let url = `${API_URL}/share/lang/update`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        if (result.data.code == 200) {
            popUpAlert({
                title: "Successfully",
                text: " Other language has been updated Share successfully!",
                icon: "success",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
            yield put({ type: actionTypes.REFRESH_KEY });
        } else {
            popUpAlert({
                title: "Error",
                text: "Failed to Process the request!",
                icon: "error",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }
    }
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed to Process the request!",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* deleteShareLangSaga(props) {
    let url = `${API_URL}/share/lang/delete`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);

        if (result.data.code == 200) {
            popUpAlert({
                title: "Success",
                text: "Share Details for Language has been removed.",
                icon: "success",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
            yield put({ type: actionTypes.REFRESH_KEY });
        } else {
            popUpAlert({
                title: "Error",
                text: "Failed to delete the Share!",
                icon: "error",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }
    }
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed to delete the Share!",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* fetchClientPartialSignupListSaga(props) {
    let url = `${API_URL}/client/partialSignupList`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.SET_CLIENT_PARTIAL_SIGNUP_LIST, payload: result.data });
    } catch (e) {
        // yield put({type: actionTypes.FETCH_CLIENT_PARTIAL_SIGNUP_LIST, payload: e});  
    }
}

function* exportClientPartialSignupListSaga(props) {
    let url = `${API_URL}/client/export/partialSignupList`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        if(result.data.code == 200){
            ExportClientPartialSignup(result.data.data);
        }else{
            popUpAlert({
                text: "No Data Found!",
                icon: "warning",
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: "OK",
            });
        }
    }
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* exportClientPaymentListSaga(props) {
    let url = `${API_URL}/client/export/paymentList`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        if(result.data.code == 200){
            ExportClientPaymentList(result.data.data);
        }else{
            popUpAlert({
                text: "No Data Found!",
                icon: "warning",
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: "OK",
            });
        }
    } 
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* deleteClientPartialSignupListSaga(props) {
    let url = `${API_URL}/delete/agreementShare`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        if (result.data.code == 200) {
            popUpAlert({
                title: "Successfully",
                text: "Partial Signup has been deleted successfully!",
                icon: "success",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: "OK",
            });
        } else {
            popUpAlert({
                title: "Error",
                text: "Failed to delete Partial Signup!",
                icon: "error",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }
        yield put({ type: actionTypes.REFRESH_KEY });
    }
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed to delete Partial Signup!",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* fetchCsaSignupStatusListSaga(props) {
    let url = `${API_URL}/client/pendingSignupList`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        console.log(result.data.data);
        yield put({ type: actionTypes.SET_CSASIGNUP_STATUS_LIST, payload: result.data });
    }
    catch (e) {
        // yield put({ type: actionTypes.FETCH_CSASIGNUP_STATUS_LIST, payload: e });

    }
}

function* exportCsaSignupStatusSaga(props) {
    let url = `${API_URL}/client/export/pendingSignupList`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        if (result.data.code == 200) {
            ExportCsaSignupStatus(result.data.data);
        }else{
            popUpAlert({
                text: "No Data Found!",
                icon: "warning",
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: "OK",
            });
        }
    }
    catch {
        popUpAlert({
            title: "Error",
            text: "Failed",
            icon: "error",
            timer: 1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

function* updateApproveCsaSignupStatusSaga(props) {
    let url = `${API_URL}/client/agreementUpdate`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        if (result.data.code === "200") {
            const status = props.payload?.apprvlStatus === "APPRVD" ? "approved" : "rejected"
            yield put({ type: actionTypes.REFRESH_KEY });
            popUpAlert({
                title: "Successfully",
                text: "Agreement share "+status+" successfully!",
                icon: "success",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        } else {
            popUpAlert({
                title: "Error",
                text: "Failed to Process the request!",
                icon: "error",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }
    } catch (e) {
        if (e?.response?.data?.code === "714") {
            popUpAlert({
                title: "Error",
                text: e?.response?.data?.message,
                icon: "error",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });  
        } else {
            popUpAlert({
                title: "Error",
                text: "Failed to Process the request!",
                icon: "error",
                timer: 1500,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }      
    }
}

function* submitSignupSaga(props) {
    let url = `${PUBLIC_API}/csaSignupApi/submitCSAForm`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        console.log(result);
        yield put({ type: actionTypes.SET_SUBMIT_SIGNUP, payload: result.data });
    }
    catch (e) {
        console.log("Failed", e);
    }
}

function* saveCSASignupSaga(props) {
    let url = `${PUBLIC_API}/csaSignupApi/saveCSAForm`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        console.log(result);
        yield put({ type: actionTypes.SET_SAVE_CSASIGNUP, payload: result.data });
    }
    catch (e) {
        console.log("Failed", e);
    }
}

function* getCSADocSaga(props) {
    let url = `${PUBLIC_API}/csaSignupApi/notices`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        console.log(result);
        yield put({ type: actionTypes.SET_CSA_DOC, payload: result.data });
    }
    catch (e) {
        console.log("Failed", e);
    }
}

export const watchDeleteSubVendorSaga = function* () {
    yield takeLatest(actionTypes.DELETE_SUB_USER, deleteSubUserSaga);
}

export const watchUpdateSubUserSaga = function* () {
    yield takeLatest(actionTypes.UPDATE_SUB_USER,updateSubUserSaga);
}

export const watchGetVendorSubUserListSaga = function* () {
    yield takeLatest(actionTypes.GET_VENDOR_SUB_USER_LIST, fecthVendorSubUserListSaga);
}

export const watchGetCSADocSaga = function* () {
    yield takeLatest(actionTypes.GET_CSA_DOC, getCSADocSaga);
}

export const watchSubmitSignupSaga = function* () {
    yield takeLatest(actionTypes.GET_SUBMIT_SIGNUP, submitSignupSaga);
}

export const watchSaveCSASignupSaga = function* () {
    yield takeLatest(actionTypes.GET_SAVE_CSASIGNUP, saveCSASignupSaga);
}

export const watchAddSnapCsaLocationSaga = function* () {
    yield takeLatest(actionTypes.ADD_SNAP_CSA_LOCATION, addSnapCsaLocationSaga);
}

export const watchEditSnapCSALocationListSaga = function* () {
    yield takeLatest(actionTypes.EDIT_SNAP_CSA_LOCATION, editSnapCSALocationListSaga);
}

export const watchDeleteSnapCSALocationSaga = function* () {
    yield takeLatest(actionTypes.DELETE_SNAP_CSA_LOCATION,deleteSnapCSALocationSaga);
}
export const watchGetSnapCsaLocationListSaga = function* () {
    yield takeLatest(actionTypes.GET_SNAP_CSA_LOCATION_LIST, fetchSnapCsaLocationListSaga);
}

export const watchGetCsaSignupStatusListSaga = function* () {
    yield takeLatest(actionTypes.GET_CSASIGNUP_STATUS_LIST, fetchCsaSignupStatusListSaga);
};

export const watchExportCsaSignupStatusSaga = function* () {
    yield takeLatest(actionTypes.EXPORT_CSASIGNUP_STATUS, exportCsaSignupStatusSaga);
};

export const watchUpdateApproveCsaSignupStatusSaga = function* () {
    yield takeLatest(actionTypes.UPDATE_APPROVE_CSASIGNUP_STATUS, updateApproveCsaSignupStatusSaga);
};

export const watchGetClientPartialSignupListSaga = function* () {
    yield takeLatest(actionTypes.GET_CLIENT_PARTIAL_SIGNUP_LIST, fetchClientPartialSignupListSaga);
};
export const watchGetCancelAgreementListSaga = function* () {
    yield takeLatest(actionTypes.GET_CANCEL_AGREEMENT_LIST,getCancelAgreementListSaga);
};

export const watchExportClientPaymentListSaga = function* () {
    yield takeLatest(actionTypes.EXPORT_CLIENT_PAYMENTLIST, exportClientPaymentListSaga);
};

export const watchExportClientPartialSignupSaga = function* () {
    yield takeLatest(actionTypes.EXPORT_CLIENT_PARTIAL_SIGNUP, exportClientPartialSignupListSaga);
};

export const watchDeleteClientPartialSignupListSaga = function* () {
    yield takeLatest(actionTypes.DELETE_CLIENT_PARTIAL_SIGNUP_LIST, deleteClientPartialSignupListSaga);
};

export const watchGetVendorShareListSaga = function* () {
    yield takeLatest(actionTypes.GET_VENDOR_SHARE_LIST, fetchVendorShareListSaga);
};

export const watchAddVendorShareListSaga = function* () {
    yield takeLatest(actionTypes.ADD_VENDOR_SHARE_LIST, addVendorShareListSaga);
};

export const watchEditVendorShareListSaga = function* () {
    yield takeLatest(actionTypes.EDIT_VENDOR_SHARE_LIST, editVendorShareListSaga);
};

export const watchDeleteVendorShareListSaga = function* () {
    yield takeLatest(actionTypes.DELETE_VENDOR_SHARE_LIST, deleteVendorShareListSaga);
};

export const watchAddShareLangSaga = function* () {
    yield takeLatest(actionTypes.ADD_SHARE_LANG, addShareLangSaga);
};

export const watchEditShareLangSaga = function* () {
    yield takeLatest(actionTypes.EDIT_SHARE_LANG, editShareLangSaga);
};

export const watchDeleteShareLangSaga = function* () {
    yield takeLatest(actionTypes.DELETE_SHARE_LANG, deleteShareLangSaga);
};

export const watchGetCSAVendorListSaga = function* () {
    yield takeLatest(actionTypes.GET_CSA_VENDOR_LIST, fetchCsaVendorListSaga);
};

export const watchGetCSAVendorInfoSaga = function* () {
    yield takeLatest(actionTypes.GET_CSA_VENDOR_INFO, fetchCsaVendorInfoSaga);
};

export const watchGetClientInfoSaga = function* () {
    yield takeLatest(actionTypes.GET_CLIENT_INFO, fetchClientInfoSaga);
};

export const watchGetCSAShareListSaga = function* () {
    yield takeLatest(actionTypes.GET_CSA_SHARE_LIST, fetchCsaShareListSaga);
};

export const watchGetClientAgrmntShrLstSaga = function* () {
    yield takeLatest(actionTypes.GET_CLIENT_AGRMNT_SHR_LST, fetchClientAgrmntShrLstSaga);
};

export const watchGetSnapShareSaga = function* () {
    yield takeLatest(actionTypes.GET_SNAP_SHARE, fetchSnapShareSaga);
};

export const watchCancelUpdateAgreementShareSaga = function* () {
    yield takeLatest(actionTypes.UPDATE_CANCEL_AGREEMENT_SHARE, updateCancelAgreementShareSaga);
};

export const watchGetDocumentSaga = function* () {
    yield takeLatest(actionTypes.GET_DOUCMENT, fetchGetDocumentSaga);
};

export const watchGetRequestHelpSaga = function* () {
    yield takeLatest(actionTypes.GET_REQUEST_HELP, fetchRequestHelpSaga);
};

export const watchGetSnapHipAmount = function* () {
    yield takeLatest(actionTypes.GET_SNAP_HIP_AMOUNT, fetchSnapHipAmountSaga);
};

export const watchGetManageCSAVendorListSaga = function* () {
    yield takeLatest(actionTypes.GET_MANAGE_CSA_VENDOR_LIST, fetchManageCsaVendorListSaga);
};

export const watchGetCientPickupLocationListSaga = function* () {
    yield takeLatest(actionTypes.GET_CLIENT_PICKUP_LOCATION_LIST, fetchGetCientPickupLocationListSaga);
};
export const watchGetClientPaymentListSaga = function* () {
    yield takeLatest(actionTypes.GET_CLIENT_PAYMENT_LIST, fetchGetClientPaymentListSaga);
};

export const watchGetClientAgreementListSaga = function* () {
    yield takeLatest(actionTypes.GET_CLIENT_AGREEMENT_LIST, fetchGetClientAgreementListSaga);
};

export const watchGetPickupLocationListSaga = function* () {
    yield takeLatest(actionTypes.GET_PICKUP_LOCTION_LIST, getPickupLocationListSaga);
};

export const watchGetExportClientAgreementListSaga = function* () {
    yield takeLatest(actionTypes.GET_EXPORT_CLIENT_AGREEMENT_LIST, fetchGetExportClientAgreementListSaga);
};

export const watchGetSignatureSaga = function* () {
    yield takeLatest(actionTypes.GET_SIGNATURE, fetchGetSignatureSaga);
};
export const watchGetAddUserListSaga = function* () {
    yield takeLatest(actionTypes.GET_ADD_USER_LIST, fetchGetAddUserListSaga);
};

