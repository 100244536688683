import React from "react";
import PropTypes from "prop-types";
import useTranslator from "../../../hooks/useTranslator";
import { Icon } from "@mui/material";

const RegistrationSidebar = (props) => {
  const fullMenu = props.staticMenu.concat(props.enableMenuList);
  const transProps = useTranslator(
    [
      "registration",
      "locType",
      "publicInfo",
      "register",
      "regCnt",
      "regSpf",
      "needHelp",
      "needHelpSpf",
      "needHelpCnt",
    ],
    "userRegistrationComponent"
  );

  // const enableMenuList = Object.entries(props.dynamicMenuEnable).filter(([, v]) => (v === true || v === "true")).map(([k]) => k);
  return (
    <div
      className={"col-md-2"}
      style={{
        background: "#000",
        padding: "0px",
        border: "0px #2c2c2c solid",
        maxWidth: "240px",
      }}
    >
      <ul className="list-group" id="vendorRegMenus">
        {fullMenu.map((menu, i) => {
          return (
            <li
              key={`sidebar_${i}`}
              onClick={() => {
                if (menu.clickEvent) {
                  props.onMenuClick(menu.id);
                }
              }}
            >
              <span
                style={{ cursor: "pointer", background: "#000" }}
                className={
                  props.activeTab === menu.id
                    ? `active ${menu.className}`
                    : menu.className
                }
                id={menu.id}
              >
                {menu.icon && <menu.icon />}&nbsp;&nbsp;
                {menu.label}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

RegistrationSidebar.propTypes = {
  activeTab: PropTypes.string,
  onMenuClick: PropTypes.func.isRequired,
  staticMenu: PropTypes.array.isRequired,
  enableMenuList: PropTypes.array,
};

export default RegistrationSidebar;
