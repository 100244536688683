import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import {
  authenticateHIPUser,
  clearLoginError,
} from "../../../redux/actionTypes/auth";
import { maskMobile } from "../../../utils/globalMethods";
import "../../../styles/header.scss";
import ModalComponent from "../../common/components/modal";
import { useNavigate } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";

const LoginComponent = (props) => {
  const transProps = useTranslator(
    [
      "register",
      "update",
      "enterPrimaryEmail",
      "errorEmail",
      "enterPrimaryPhone",
      "errorPhone",
      "enterSPFOrg",
      "errorSPFID",
      "back",
      "authenticating",
      "continue",
      "vendorLogin",
    ],
    "loginComponent"
  );
  const [modalShown] = useState(props.shown);
  const [showUpdateFields, setShowUpdateFields] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [mobile, setMobile] = useState("");
  const [orgId, setOrgId] = useState("");
  const navigate = useNavigate();

  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const formatOrgId = (value) => {
    return value.replace(/[^0-9]/g, "");
  };

  // eslint-disable-next-line no-unused-vars
  const handleInputChange = (event) => {
    if (props.userAuth.login.status || errors.loginError) {
      clearErrors(["loginError"]);
      props.clearLoginError();
    }
  };

  useEffect(() => {
    if (props.userAuth.login.status) {
      props.clearLoginError();
    }
  }, []);

  useEffect(() => {
    if (isSubmitting && props.userAuth.login.status == "error") {
      setError("loginError", {
        type: "custom",
        message: props.userAuth.login.message,
      });
      setSubmitting(false);
    } else if (props.userAuth.login.status == "success") {
      props.close();
    }
  }, [props.userAuth.login]);

  const onSubmit = (data) => {
    if (Object.keys(errors).length === 0) {
      clearErrors(["loginError"]);
      setSubmitting(true);
      props.authenticateHIPUser(
        data.email.toLowerCase(),
        data.phone.replace(/\D/g, ""),
        data.hipid
      );
    }
  };

  const renderModal = () => {
    const handleButtonClick = () => {
      // window.location.href = "/kfreshfep/registration";
      navigate("/kfreshfep/registration");
    };
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    };
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-0">
          <div className="row">
            {!showUpdateFields && (
              <div className="col-md-12 mt-5">
                <p>
                  <button
                    onClick={handleButtonClick}
                    className=" mt-2 boldFont btn btn-block btn-lg btn-outline-primary"
                  >
                    {transProps.register}{" "}
                    <i className="fa fa-pencil-square-o mt-1"></i>
                  </button>
                </p>
                <p>
                  <button
                    onClick={() => setShowUpdateFields(true)}
                    className="boldFont btn btn-block btnModal btnSubmit btn-outline-primary btn-lg"
                    tabIndex="0"
                  >
                    {transProps.update}{" "}
                  </button>
                </p>
              </div>
            )}
            {showUpdateFields && (
              <>
                <div
                  className="col-md-12 errorMsg mb-2"
                  style={{ fontSize: "19px" }}
                >
                  {Object.keys(errors).length > 0 && (
                    <span className="text-danger">
                      <i className="fa fa-exclamation-circle"></i> &nbsp;
                      {errors[Object.keys(errors)[0]].message}
                    </span>
                  )}
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="inputLabel">
                      {transProps.enterPrimaryEmail}
                      <small>*</small>
                    </label>
                    <input
                      {...register("email", {
                        required: {
                          value: true,
                          message: transProps.errorEmail,
                        },
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Invalid email format",
                        },
                      })}
                      className="form-control form-control-lg lowercase"
                      maxLength="100"
                      placeholder=""
                      name="email"
                      type="text"
                      id="username"
                      // tabIndex="0"
                      required={true}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="inputLabel">
                      {transProps.enterPrimaryPhone}
                      <small>*</small>
                    </label>
                    <input
                      {...register("phone", {
                        required: {
                          value: true,
                          message: transProps.errorPhone,
                        },
                        pattern: {
                          value:
                            /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                          message: "Invalid phone number",
                        },
                      })}
                      className="form-control form-control-lg "
                      maxLength="100"
                      placeholder={mobile ? mobile : "(___) ___-____"}
                      name="phone"
                      type="tel"
                      value={mobile}
                      id="phonenumber"
                      // tabIndex="0"
                      required={true}
                      onChange={(e) => {
                        setMobile(maskMobile(e.target.value));
                        handleInputChange();
                      }}
                      autoComplete="off"
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                </div>
                <div className="col-md-12 position-relative">
                  <div className="form-group">
                    <label className="inputLabel" style={{ width: "100%" }}>
                      {transProps.enterSPFOrg}
                      <small>*</small>
                    </label>
                    <input
                      {...register("hipid", {
                        required: {
                          value: true,
                          message: transProps.errorSPFID,
                        },
                      })}
                      className="form-control form-control-lg show "
                      // tabIndex="0"
                      name="hipid"
                      id="hipid"
                      type="text"
                      maxLength="6"
                      value={orgId}
                      required=""
                      onChange={(e) => {
                        setOrgId(formatOrgId(e.target.value));
                        handleInputChange();
                      }}
                      autoComplete="off"
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <button
                    onClick={() => setShowUpdateFields(false)}
                    className="boldFont btn btn-block btnModal btnSubmit btn-primary btn-lg"
                    // tabIndex="3"
                  >
                    {transProps.back} &nbsp;
                  </button>
                </div>
                <div className="col-md-6 mt-3">
                  <button
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    className="boldFont btn btn-block btnModal btnSubmit btn-primary btn-lg"
                    // tabIndex="3"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        {" "}
                        <i className="fa fa-spinner fa-spin"></i>{" "}
                        {transProps.authenticating}
                      </>
                    ) : (
                      <>
                        {transProps.continue} &nbsp;
                        <i
                          style={{ verticalAlign: "inherit" }}
                          className="fa fa-arrow-right"
                        ></i>{" "}
                      </>
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    );
  };

  return (
    // <Modal
    //   show={modalShown}
    //   onHide={() => props.close()}
    //   backdrop="static"
    //   keyboard={false}
    //   animation={1}
    //   id={"loginModal"}
    // >
    //   <Modal.Header closeButton>
    //     <Modal.Title id="contained-modal-title-vcenter">
    //       <h4 className="modal-title modalTitle resText">{transProps.vendorLogin}</h4>
    //     </Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body className="fullWidth">
    //     <form onSubmit={handleSubmit(onSubmit)}>
    //       <div className="pt-0">
    //         <div className="row">
    //           {!showUpdateFields && <div className="col-md-12 mt-5">
    //             <p>
    //               <NavLink
    //                 to={"/kfreshfep/registration"}
    //                 className=" mt-2 boldFont btn btn-block btn-lg btn-outline-primary"
    //                 tabIndex="0"
    //                 reloadDocument="true"
    //               >
    //                 {language.Register}{" "}
    //                 <i className="fa fa-pencil-square-o mt-1"></i>
    //               </NavLink>
    //             </p>
    //             <p>
    //               <button
    //                 onClick={() => setShowUpdateFields(true)}
    //                 className="boldFont btn btn-block btnModal btnSubmit btn-outline-primary btn-lg"
    //                 tabIndex="0"
    //               >
    //                 {language.Update}{" "}
    //               </button>
    //             </p>
    //           </div>}
    //           {showUpdateFields && <>
    //             <div
    //               className="col-md-12 errorMsg mb-2"
    //               style={{ fontSize: "19px" }}
    //             >
    //               {Object.keys(errors).length > 0 &&
    //                 <span className="text-danger"><i className="fa fa-exclamation-circle"></i> &nbsp;{errors[Object.keys(errors)[0]].message}</span>
    //               }
    //             </div>
    //             <div className="col-md-12">
    //               <div className="form-group">
    //                 <label className="inputLabel">
    //                   {language.EnterPrimaryEmail}<small>*</small>
    //                 </label>
    //                 <input
    //                   {...register("email", { required: {value:true, message: props.language?.errorEmail},
    //                   pattern: {
    //                     value: /\S+@\S+\.\S+/,
    //                     message: "Invalid email format"
    //                   }})}
    //                   className="form-control form-control-lg "
    //                   maxLength="100"
    //                   placeholder=""
    //                   name="email"
    //                   type="text"
    //                   id="username"
    //                   tabIndex="1"
    //                   required={true}
    //                   onChange={handleInputChange}
    //                 />
    //               </div>
    //             </div>
    //             <div className="col-md-12">
    //               <div className="form-group">
    //                 <label className="inputLabel">
    //                   {language.EnterPrimaryPhone}<small>*</small>
    //                 </label>
    //                 <input
    //                   {...register("phone", { required: {value:true, message: props.language?.errorPhone},
    //                     pattern: {
    //                       value:
    //                         /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
    //                       message: "Invalid phone number",
    //                     },
    //                   })}
    //                   className="form-control form-control-lg "
    //                   maxLength="100"
    //                   placeholder="(___) ___-____"
    //                   name="phone"
    //                   type="tel"
    //                   value={mobile}
    //                   id="phonenumber"
    //                   tabIndex="1"
    //                   required={true}
    //                   onChange={(e) =>{
    //                     setMobile(maskMobile(e.target.value));
    //                     handleInputChange();
    //                   }}
    //                 />
    //               </div>
    //             </div>
    //             <div className="col-md-12 position-relative">
    //               <div className="form-group">
    //                 <label className="inputLabel" style={{ width: "100%" }}>
    //                   {language.EnterHIPOrg}<small>*</small>
    //                 </label>
    //                 <input
    //                   {...register("hipid", { required: {value:true, message: props.language?.errorHIPID}})}
    //                   className="form-control form-control-lg show "
    //                   tabIndex="2"
    //                   name="hipid"
    //                   id="hipid"
    //                   type="text"
    //                   maxLength="6"
    //                   value={orgId}
    //                   required=""
    //                   onChange={(e) =>{
    //                     setOrgId(formatOrgId(e.target.value));
    //                     handleInputChange();
    //                   }}
    //                 />
    //               </div>
    //             </div>
    //             <div className="col-md-6 mt-3">
    //               <button
    //                 onClick={() => setShowUpdateFields(false)}
    //                 className="boldFont btn btn-block btnModal btnSubmit btn-primary btn-lg"
    //                 tabIndex="3"
    //               >{language.Back} &nbsp;</button>
    //             </div>
    //             <div className="col-md-6 mt-3">
    //               <button
    //                 type="submit"
    //                 onClick={handleSubmit(onSubmit)}
    //                 className="boldFont btn btn-block btnModal btnSubmit btn-primary btn-lg"
    //                 tabIndex="3"
    //                 disabled={isSubmitting}
    //               >
    //                 {isSubmitting ? (
    //                   <>
    //                     {" "}
    //                     <i className="fa fa-spinner fa-spin"></i>{" "}
    //                     Authenticating
    //                   </>
    //                 ) : (
    //                   <>
    //                     {language.Continue} &nbsp;
    //                     <i
    //                       style={{ verticalAlign: "inherit" }}
    //                       className="fa fa-arrow-right"
    //                     ></i>{" "}
    //                   </>
    //                 )}
    //               </button>
    //             </div>
    //           </>}
    //         </div>
    //       </div>
    //     </form>
    //   </Modal.Body>
    // </Modal>

    <ModalComponent
      shown={modalShown}
      close={() => props.close()}
      title={transProps.vendorLogin}
      modalBody={renderModal}
      centered={true}
      label={transProps.vendorLogin}
    />
  );
};

LoginComponent.propTypes = {
  shown: PropTypes.bool,
  close: PropTypes.func,
  authenticateHIPUser: PropTypes.func.isRequired,
  clearLoginError: PropTypes.func.isRequired,
  userAuth: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearLoginError: () =>
      dispatch(clearLoginError({ status: "", message: "" })),
    authenticateHIPUser: (email, phone, hipid) =>
      dispatch(
        authenticateHIPUser({ username: email, phone: phone, hipid: hipid })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
