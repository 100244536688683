import React, { useState, Fragment, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form"; //, Controller
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import { UPDATE_VENDOR } from "../../../redux/actionTypes/vendordetails";
import { PUT_USER } from "../../../redux/actionTypes/registration";
import "../../../styles/registration.scss";
import {
  convertUTCDateToLocalDate,
  isNumber,
  maskMobile,
  isAlphanumeric,
} from "../../../utils/globalMethods";
import { getVendorInfoList } from "../../../redux/actionTypes/vendordetails";

const CsaVendorDetailsTab = (props) => {
  let initial = {
    vendorName: "",
    fnsNum: [{ id: 0, name: "" }],
    firstName: "",
    lastName: "",
    accountNo: "",
    routingNo: "",
    primaryMobile: "",
    primaryEmail: "",
    createdBy: "",
    createTs: "",
    lastUpdtTs: "",
    adminLastUpdtTs: "",
    lastUpdtUidNam: "",
    lastUpdtUid: "",
    additionalContactInfo: "",
    communicationEmail: [{ id: 0, email: "" }],
  };

  const userData = props?.userAuth?.user
    ? props?.userAuth?.user
    : JSON.parse(sessionStorage.getItem("userData"));
  const [mode, setMode] = useState(props.add_CSA ? "edit" : "view");
  const [mobile, setMobile] = useState("");
  const [update, setUpdate] = useState(false);
  const [formValue, setFormValue] = useState(initial);

  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
  } = useForm({
    defaultValues: formValue,
  });

  useEffect(() => {
    if (props.vendorInfo) {
      const formData = props.vendorInfo;
      if (typeof formData.fnsNum === "string") {
        const format = formData.fnsNum
          .split(",")
          .map((item, i) => ({ id: i, name: item }));
        formData.fnsNum = format;
      }
      if (typeof formData.communicationEmail === "string") {
        const format = formData.communicationEmail
          .split(",")
          .map((item, i) => ({ id: i, name: item }));
        formData.communicationEmail = format;
      }
      setTimeout(() => {
        if (formData?.primaryMobile) {
          setMobile(maskMobile(formData?.primaryMobile));
        }
        reset(formData);
      }, 1000);
      formData.lastUpdtUid = props.vendorInfo.lastUpdtUidNam;
      formData.publicMobile = maskMobile(props.vendorInfo.publicMobile);
      formData.adminLastUpdtTs = props.vendorInfo.adminLastUpdtTs
        ? convertUTCDateToLocalDate(props.vendorInfo.adminLastUpdtTs)
        : "";
      formData.createTs = props.vendorInfo.createTs
        ? convertUTCDateToLocalDate(props.vendorInfo.createTs)
        : "";
      formData.lastUpdtTs = props.vendorInfo.lastUpdtTs
        ? convertUTCDateToLocalDate(props.vendorInfo.lastUpdtTs)
        : "";
      setMobile(maskMobile(props.vendorInfo.primaryMobile));
      setFormValue(formData);
    }
  }, [props.vendorInfo]);

  const { fields, remove } = useFieldArray({
    control,
    name: "fnsNum",
  });
  let watchFieldArray = watch("fnsNum");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray?.[index],
    };
  });
  const [addMore, setAddMore] = useState(1);

  const handleRemove = (i) => {
    setAddMore(addMore - 1);
    remove(i);
  };

  const [communicateEmail, setCommunicateEmail] = useState(1);
  const EmailAddFields = () => {
    setCommunicateEmail(communicateEmail + 1);
    EmailAppend({ name: "" });
  };

  const EmailRemoveFields = (i) => {
    setCommunicateEmail(communicateEmail - 1);
    EmailRemove(i);
  };
  // RE
  const {
    fields: emailField,
    append: EmailAppend,
    remove: EmailRemove,
  } = useFieldArray({
    control,
    name: "communicationEmail",
  });

  let watchEmailArray = watch("communicationEmail");
  const controlledEmailFields = emailField.map((emailField, index) => {
    return {
      ...emailField,
      ...watchEmailArray?.[index],
    };
  });

  const updateVendorCallback = () => {
    if (props.add_CSA) {
      navigate("?vendor_id=" + props.vendorId);
      location.reload();
    }
  };

  const onFormSubmit = (data) => {
    console.log(data);
    if (data.fnsNum && data.fnsNum.length > 0) {
      data.fnsNum = data.fnsNum.map((item) => item.name).toString();
    }
    if (data.communicationEmail && data.communicationEmail.length > 0) {
      const commEmails = [
        ...new Set(data.communicationEmail.map((item) => item.name)),
      ];
      data.communicationEmail = commEmails.toString();
    }
    const timeStamp = new Date().getTime();
    const systemInfo = sessionStorage.getItem("systemInfo");
    const systemData = JSON.parse(systemInfo);
    let role = "";
    if (userData?.Role === "ADMIN") {
      role = "ADM_";
    } else if (userData?.Role === "HIPCRD") {
      role = "CRD_";
    } else {
      role = "VDR_";
    }
    let updatedInfo = {
      firstName: data.firstName,
      accountNo: data.accountNo,
      routingNo: data.routingNo,
      lastName: data.lastName,
      fnsNum: data.fnsNum ? data.fnsNum : "",
      communicationEmail: data.communicationEmail
        ? data.communicationEmail
        : "",
      csaFnsNum: data.fnsNum ? data.fnsNum : "",
      csaCommunicationEmail: data.communicationEmail
        ? data.communicationEmail
        : "",
      vendorName: data.vendorName,
      primaryEmail: data.primaryEmail,
      primaryMobile: data.primaryMobile,
      publicEmail: data.publicEmail ? data.publicEmail : "",
      publicMobile: data.publicMobile ? data.publicMobile : "",
      publicWebsite: data.publicWebsite ? data.publicWebsite : "",
      csaText: data.csaText ? data.csaText : "",
      publicPhoto: data.publicPhoto ? data.publicPhoto : "",
      csaPickupSite: data.csaPickupSite ? data.csaPickupSite : "N",
      farmStand: data.farmStand ? data.farmStand : "N",
      farmersMarket: data.farmersMarket ? data.farmersMarket : "N",
      farmersMarketManagers: data.farmersMarketManagers
        ? data.farmersMarketManagers
        : "N",
      mobileMarket: data.mobileMarket ? data.mobileMarket : "N",
      forceLogin: data.forceLogin,
      vendorId: props.vendorId,
      id: props.vendorId,
      csaSnapFlag: data.csaSnapFlag,
      additionalContactInfo: data.additionalContactInfo
        ? data.additionalContactInfo
        : "",
      products: data.products ? data.products : "",
      businessDesc: data.businessDesc ? data.businessDesc : "",
      lastUpdateTime: data.lastUpdateTime ? data.lastUpdateTime : "",
      createTimeStamp: timeStamp,
      lastUpdtTimeStamp: timeStamp,
      userId: props.userId,
      submitForm: "Vendor",
      vendorType: "CSAVDR",
      lastUpdtUidNam: role + userData?.id,
      // lastUpdtUidNam: userData?.Name,
      ipAddress: systemData.ip,
      loginId: userData?.id,
    };
    if (update) {
      props.updateUser(updatedInfo, updateVendorCallback);
      props.getVendorInfoList(updatedInfo);
      setFormValue(updatedInfo);
      setUpdate(false);
      setMode("view");
    }
  };

  return (
    <div className="tab-content-wrapper">
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Row>
          <Col>
            <h3 className="resText"> SPF FARMER Details</h3>
          </Col>
          {mode === "view" ? (
            <Col className="txt-right">
              <Button
                onClick={() => setMode("edit")}
                className="btn btn-primary"
              >
                <span>
                  <i className="fa fa-pencil"></i> Edit
                </span>
              </Button>
            </Col>
          ) : (
            <Col className="txt-right">
              <Button
                type="submit"
                onClick={() => setUpdate(true)}
                className="btn btn-primary m-r-5"
              >
                <span>
                  <i className="fa fa-check"></i> Update
                </span>
              </Button>
              {!props.add_CSA && (
                <Button
                  onClick={() => {
                    setMode("view");
                    if (props?.vendorInfo?.primaryMobile) {
                      setMobile(maskMobile(props?.vendorInfo?.primaryMobile));
                    }
                    reset(props.vendorInfo);
                  }}
                  className="btn btn-primary"
                >
                  <span>
                    <i className="fa fa-times"></i> Cancel
                  </span>
                </Button>
              )}
            </Col>
          )}
        </Row>
        <br />
        <Row>
          <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="vendorName"
              >
                Farm / Vendor Name *
              </label>
              <input
                {...register("vendorName", {
                  required: "Farm/Vendor Name is required",
                })}
                // value={vendorDetails?.farmName}
                className={`form-control form-control-lg required dh-input-box ${
                  errors.vendorName ? "dh-error-box" : ""
                }`}
                placeholder=""
                disabled={mode === "view"}
              />
              {errors.vendorName && (
                <span className="dh-txt-danger">
                  {errors.vendorName.message}
                </span>
              )}
            </div>
          </Col>
          <Col md={9} sm={9} xl={9} lg={9}>
            <div className="row" id="fnsNumbersList">
              {controlledFields.map((item, i) => {
                return (
                  <Fragment key={`fnsNum.${i}`}>
                    <div className="col-md-3" id={`fnsNum.${i}`}>
                      <div className="form-group mb-0">
                        <label
                          className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                          htmlFor={`fnsNum.${i}`}
                        >
                          Federal Number *
                          {i > 0 && mode === "edit" ? (
                            <span
                              key={i}
                              className={
                                "text-decoration-underline cursor-pointer dh-remove ml-2 green"
                              }
                              onClick={() => {
                                handleRemove(i);
                              }}
                            >
                              <i className="fa fa-trash-o"></i> Remove
                            </span>
                          ) : (
                            ""
                          )}
                        </label>

                        <input
                          {...register(`fnsNum.${i}.name`, {
                            required: true,
                            valueAsNumber: true,
                          })}
                          maxLength={7}
                          onKeyDown={isNumber}
                          // control={control}
                          type="text"
                          className={`form-control form-control-lg fns-number-input dh-input-box required ${
                            errors.fnsNum?.[i]?.name ? "dh-error-box" : ""
                          }`}
                          disabled={
                            mode === "view" ||
                            userData?.Role === "CSAVDR" ||
                            userData?.Role === "SUBVDR"
                          }
                        />
                        {errors.fnsNum?.[i]?.name && (
                          <span className="dh-txt-danger">
                            Federal ID is required
                          </span>
                        )}
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={12} sm={12} xl={12} lg={12}>
            <div className="row" id="communicationEmail">
              {controlledEmailFields.map((item, i) => {
                return (
                  <Fragment key={`communicationEmail.${i}`}>
                    <div className="col-md-3" id={`communicationEmail.${i}`}>
                      <div className="form-group mb-0">
                        <label
                          className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                          htmlFor={`communicationEmail.${i}`}
                        >
                          Communication Email *
                          {i > 0 && mode === "edit" ? (
                            <span
                              key={i}
                              className={
                                "text-decoration-underline cursor-pointer dh-remove ml-2 green"
                              }
                              onClick={() => {
                                EmailRemoveFields(i);
                              }}
                            >
                              <i className="fa fa-trash-o"></i> Remove
                            </span>
                          ) : (
                            ""
                          )}
                        </label>

                        <input
                          {...register(`communicationEmail.${i}.name`, {
                            required: "Communication Email is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid Email Address",
                            },
                          })}
                          id="infoEmailID"
                          aria-label="infoEmailID"
                          aria-labelledby="infoEmailID"
                          aria-describedby="infoEmailID"
                          onKeyDown={isAlphanumeric}
                          type="text"
                          className={`form-control form-control-lg communication-email-input dh-input-box required  ${
                            errors.communicationEmail?.[i]?.name
                              ? "dh-error-box"
                              : ""
                          }`}
                          disabled={mode === "view"}
                        />
                        {errors.communicationEmail?.[i]?.name && (
                          <span className="dh-txt-danger">
                            {errors.communicationEmail[i].name.message}
                            {/* Communication Email is required */}
                          </span>
                        )}
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
            {mode === "edit" && controlledEmailFields.length < 10 && (
              <div
                className="form-text text-muted dh-short-title"
                id="communicationEmailID"
              >
                <span>Do you have an additional Communication Email? </span>
                <span
                  className={"text-decoration-underline cursor-pointer green"}
                  onClick={() => EmailAddFields()}
                >
                  <i className="fa fa-plus"></i> Add More
                </span>
              </div>
            )}
          </Col>
        </Row>

        <br />
        <Row>
          <Col md={6} sm={6} xl={6} lg={6}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="accountNo"
              >
                Bank Account Number *
              </label>
              <input
                {...register("accountNo", {
                  required: "Account no is required",
                })}
                type="tel"
                className={`form-control form-control-lg required dh-input-box ${
                  errors.accountNo ? "dh-error-box" : ""
                }`}
                placeholder="Enter Account Number"
                // id="accountNo"
                // aria-label="accountNo"
                // aria-labelledby="accountNo"
                // aria-describedby="accountNo"
                maxLength={11}
                onKeyDown={isNumber}
                required=""
                disabled={mode === "view"}
              />
              {errors.accountNo && (
                <span className="dh-txt-danger">
                  {errors.accountNo
                    ? errors.accountNo.message
                    : "Account no is required"}
                </span>
              )}
            </div>
          </Col>
          <Col md={6} sm={6} xl={6} lg={6}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="routingNo"
              >
                Routing Number *
              </label>
              <input
                {...register("routingNo", {
                  required: "Routing no is required",
                })}
                type="tel"
                className={`form-control form-control-lg required dh-input-box ${
                  errors.routingNo ? "dh-error-box" : ""
                }`}
                placeholder="Enter Routing Number"
                // id="routingNo"
                // aria-label="routingNo"
                // aria-labelledby="routingNo"
                // aria-describedby="routingNo"
                maxLength={9}
                onKeyDown={isNumber}
                required=""
                disabled={mode === "view"}
              />
              {errors.routingNo && (
                <span className="dh-txt-danger">
                  {errors.routingNo
                    ? errors.routingNo.message
                    : "Routing no is required"}
                </span>
              )}
            </div>
          </Col>
        </Row>
        <br />

        <Row>
          <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="firstName"
              >
                First Name *
              </label>
              <input
                {...register("firstName", {
                  required: "First Name is required",
                })}
                className={`form-control form-control-lg required dh-input-box ${
                  errors.firstName ? "dh-error-box" : ""
                }`}
                placeholder=""
                disabled={!props.add_CSA}
              />
              {errors.firstName && (
                <span className="dh-txt-danger">
                  {errors.firstName.message}
                </span>
              )}
            </div>
          </Col>
          <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="lastName"
              >
                Last Name *
              </label>
              <input
                {...register("lastName", {
                  required: "Last Name is required",
                })}
                className={`form-control form-control-lg required dh-input-box ${
                  errors.lastName ? "dh-error-box" : ""
                }`}
                placeholder=""
                disabled={!props.add_CSA}
              />
              {errors.lastName && (
                <span className="dh-txt-danger">{errors.lastName.message}</span>
              )}
            </div>
          </Col>

          <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="primaryPhoneNumber"
              >
                Primary SPF Contact Phone *
              </label>
              <input
                {...register("primaryMobile", {
                  required: "Primary Phone Number is required",
                  pattern: {
                    value: /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                    message: "Invalid Phone Number",
                  },
                })}
                type="tel"
                className={`form-control form-control-lg required dh-input-box ${
                  errors.primaryMobile ? "dh-error-box" : ""
                }`}
                maxLength={"14"}
                value={mobile}
                onChange={(e) => {
                  setMobile(maskMobile(e.target.value));
                }}
                defaultValue={
                  mobile ? mobile : maskMobile(formValue?.primaryMobile)
                }
                placeholder="(___) ___-____"
                id="primaryPhoneNumber"
                aria-label="primaryPhoneNumber"
                aria-labelledby="primaryPhoneNumber"
                aria-describedby="primaryPhoneNumber"
                required=""
                disabled={mode === "view"}
              />
              {errors.primaryMobile && (
                <span className="dh-txt-danger">
                  {/* {errors.primaryMobile.message} */}
                  {errors.primaryMobile
                    ? errors.primaryMobile.message
                    : "Primary Phone Number is required"}
                </span>
              )}
            </div>
          </Col>
          <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="infoEmailID"
              >
                Primary SPF Contact Email *
              </label>
              <input
                {...register("primaryEmail", {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid Email Address",
                  },
                })}
                type="text"
                className={`form-control form-control-lg required dh-input-box ${
                  errors.primaryEmail ? "dh-error-box" : ""
                }`}
                maxLength="100"
                id="infoEmailID"
                aria-label="infoEmailID"
                aria-labelledby="infoEmailID"
                aria-describedby="infoEmailID"
                disabled={!props.add_CSA}
              />
              {errors.public_email && (
                <span className="dh-txt-danger">
                  {errors.primaryEmail.message}
                </span>
              )}
            </div>
          </Col>
        </Row>
        <br />
        {!props.add_CSA && (
          <Row>
            <Col md={3} sm={3} xl={3} lg={3}>
              <div className="form-group mb-0">
                <label
                  className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                  htmlFor="createdBy"
                >
                  Registered By
                </label>
                <input
                  {...register("createdBy")}
                  className={`form-control form-control-lg required dh-input-box ${
                    errors.createdBy ? "dh-error-box" : ""
                  }`}
                  placeholder=""
                  disabled="true"
                  value={formValue?.createdBy}
                />
              </div>
            </Col>
            <Col md={3} sm={3} xl={3} lg={3}>
              <div className="form-group mb-0">
                <label
                  className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                  htmlFor="createTs"
                >
                  Registered Time
                </label>
                <input
                  {...register("createTs")}
                  className={`form-control form-control-lg required dh-input-box ${
                    errors.createTs ? "dh-error-box" : ""
                  }`}
                  placeholder=""
                  disabled="true"
                  value={formValue?.createTs}
                />
              </div>
            </Col>

            <Col md={3} sm={3} xl={3} lg={3}>
              <div className="form-group mb-0">
                <label
                  className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                  htmlFor="lastUpdtTs"
                >
                  Last Updated Time
                </label>
                <input
                  {...register("lastUpdtTs")}
                  className={`form-control form-control-lg required dh-input-box ${
                    errors.lastUpdtTs ? "dh-error-box" : ""
                  }`}
                  disabled="true"
                />
                {errors.lastUpdtTs && (
                  <span className="dh-txt-danger">
                    {errors.lastUpdtTs.message}
                  </span>
                )}
              </div>
            </Col>
            <Col md={3} sm={3} xl={3} lg={3}>
              <div className="form-group mb-0">
                <label
                  className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                  htmlFor="lastUpdtUidNam"
                >
                  Last Updated By
                </label>
                <input
                  {...register("lastUpdtUidNam")}
                  className={`form-control form-control-lg required dh-input-box ${
                    errors.lastUpdtUidNam ? "dh-error-box" : ""
                  }`}
                  placeholder=""
                  disabled="true"
                  value={formValue?.lastUpdtUidNam}
                />
              </div>
            </Col>
          </Row>
        )}
        <br />
        <Row>
          {userData?.Role === "ADMIN" && (
            <Col md={9} sm={9} xl={9} lg={9}>
              <div className="form-group mb-0">
                <label
                  className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                  htmlFor="additionalContactInfo"
                >
                  Admin Notes
                </label>
                <textarea
                  {...register("additionalContactInfo", {
                    maxLength: {
                      value: 3000,
                      message: "Maximum character count exceeded.",
                    },
                  })}
                  className={`form-control form-control-lg  ${
                    errors.primary_contact_info ? "dh-error-box" : ""
                  }`}
                  id="additionalContactInfo"
                  rows="3"
                  aria-label="additionalContactInfo"
                  aria-labelledby="additionalContactInfo"
                  aria-describedby="additionalContactInfo"
                  disabled={mode === "view"}
                  value={formValue?.additionalContactInfo}
                ></textarea>
                <span className="float-right text-muted mt-1 ">
                  <i className="fa fa-info-circle mr-1" aria-hidden="true"></i>
                  Max character : 3000
                </span>
                {errors.additionalContactInfo && (
                  <span className="dh-txt-danger">
                    {errors.additionalContactInfo.message}
                  </span>
                )}
              </div>
            </Col>
          )}
          {!props.add_CSA && (
            <Col md={3} sm={3} xl={3} lg={3}>
              <div className="form-group mb-0">
                <label
                  className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                  htmlFor="adminLastUpdtTs"
                >
                  Staff Last Updated
                </label>
                <input
                  {...register("adminLastUpdtTs")}
                  className={`form-control form-control-lg required dh-input-box ${
                    errors.adminLastUpdtTs ? "dh-error-box" : ""
                  }`}
                  disabled="true"
                />
              </div>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
};
CsaVendorDetailsTab.propTypes = {
  updateVendorDetails: PropTypes.func.isRequired,
  vendorInfo: PropTypes.string,
  updateUser: PropTypes.func.isRequired,
  userId: PropTypes.number,
  userAuth: PropTypes.object.isRequired,
  vendorDetails: PropTypes.object.isRequired,
  getVendorInfoList: PropTypes.func,
  add_CSA: PropTypes.bool,
  vendorId: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateVendorDetails: (user) => {
      dispatch({ type: UPDATE_VENDOR, value: user });
    },
    updateUser: (user, callback) => {
      dispatch({ type: PUT_USER, value: user, callback: callback });
    },
    getVendorInfoList: (payload) => dispatch(getVendorInfoList(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CsaVendorDetailsTab);
