import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/reducers';
import { refreshKey } from '../../redux/actionTypes/administration';
import { Grid, IconButton, Stack } from '@mui/material';
import PageHeaderComponent from '../common/components/pageHeader';
import { CustomButton } from '../common/components/customButton';
import DatePickerComponent from '../common/components/dateRangePicker';
import moment from 'moment';
import { CalculateUserTzToUTCTz, popUpAlert } from '../../utils/globalMethods';
import SearchComponent from '../common/components/searchComponent';
import * as actionTypes from "../../redux/actionTypes/csaSignup";
import { PropTypes } from "prop-types";
import TableComponent from '../common/components/table';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import PartialSignupDetails from './partialSignupDetails';


function createData(id,vendorName,chosenNam,clientEmail,shareInfo,totalAmnt,submittedDate,phoneNumber,clientNam,lastUpdtTs,row) {
    return {
      id,
      vendorName,
      chosenNam,
      clientEmail,
      shareInfo,
      totalAmnt,
      phoneNumber:phoneNumber,
      submittedDate:moment(submittedDate, "YYYY-MM-DDTHH:mm:ss[Z]").format('MM/DD/YYYY h:mm A'),
      clientNam:clientNam,
      lastUpdtTs:moment(lastUpdtTs, "YYYY-MM-DDTHH:mm:ss[Z]").format('MM/DD/YYYY h:mm A'),
      row
    };
  }
const PartialSignupComponent=(props) => {

  const navigate = useNavigate();

    const [rows, setRows] = useState([]);
    const [size, setSize] = useState(10);
    const [from, setFrom] = useState(0);
     // eslint-disable-next-line no-unused-vars 
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [search, setSearch] = useState("");
    const [partialSignupTotal, setPartialSignupTotal] = useState([]);
    const [tableSort, setTableSort] = useState({"submittedDate":"DESC"});
    const [columns, setColumns] = useState([]);
    const [expand, setExpand] = useState(false);
     // eslint-disable-next-line no-unused-vars 
    const [onUpdate, setOnUpdate] = useState({});
    const [showLoader, setShowLoader] = useState(true);
    const [dateRangePickerLabel] = useState("Select Date");
    const [filterData, setFilterData] = useState({
      startDate: null,
      endDate: null,  
    });
    const [resetDate, setResetDate] = useState(false);


    const filterHandleChange = (date) => {
      console.log(date);
        setFilterData({
          ...filterData,
          endDate: date?.[0]?.endDate ? CalculateUserTzToUTCTz(date?.[0]?.endDate) : null,
          startDate:date?.[0]?.startDate ? CalculateUserTzToUTCTz(date?.[0]?.startDate) : null,
      });
    };

    useEffect(() => {
      const userRole = props?.userAuth?.user
        ? props?.userAuth?.user
        : JSON.parse(sessionStorage.getItem("userData"));
      if (props?.allowedRoles) {
        const checkRole = props?.allowedRoles.find((role) =>
          userRole?.Role.includes(role)
        );
        if (!checkRole) {
          navigate("/kfreshfep");
          return;
        }
      }
      setOnUpdate({});
      setSelectedRows([]);
      const payload = {
        nam: search,
        size: size,
        from: from,
        startDate: filterData.startDate
          ? moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY")
          : "",
        endDate: filterData.endDate
          ? moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY")
          : "",
        vendorNamSort: "vendorName" in tableSort ? tableSort["vendorName"] : "",
        clientEmailSort:
          "clientEmail" in tableSort ? tableSort["clientEmail"] : "",
        shareNamSort: "shareInfo" in tableSort ? tableSort["shareInfo"] : "",
        submittedDateSort:
          "submittedDate" in tableSort ? tableSort["submittedDate"] : "",
        AmountSort: "totalAmnt" in tableSort ? tableSort["totalAmnt"] : "",
        clientNameSort: "clientNam" in tableSort ? tableSort["clientNam"] : "",
      };
      props.getClientPartialSignupList(payload);
    }, [size, from, props.csaSignup.refreshKey, search, tableSort, filterData]);

    // useEffect(() => {
    //    console.log(props.csaSignup?.clientPartialSignupList?.clientPartialSignupList);

    //   if (props.csaSignup?.clientPartialSignupList?.clientPartialSignupList?.length) {

    //     setClientPartialSignupList(props?.csaSignup?.clientPartialSignupList?.clientPartialSignupList);
    //   } else {
    //     setClientPartialSignupList([]);
    //   }
    // }, [props?.csaSignup?.clientPartialSignupList?.clientPartialSignupList]);

    useEffect(() => {
      const { list, total, loading } = props.csaSignup.clientPartialSignupList;
      let data = [];
      setRows([]);
      setTimeout(() => {
        list.forEach((element) => {
          data.push(
            createData(
              element?.id,
              element?.vendorName,
              element?.chosenNam,
              element?.clientEmail,
              element?.shareInfo?.nam,
              element?.totalAmnt,
              element?.submittedDate,
              element?.phoneNumber,
              element?.clientNam,
              element?.lastUpdtTs,
              element
            )
          );
        });
        setRows(data);
        setPartialSignupTotal(total);
        setShowLoader(loading);
      }, 300);
      handleColumns();
      console.log(data);
    }, [props?.csaSignup?.clientPartialSignupList]);

    const handleNext = (page, rowSize) => {
      size != rowSize && setSize(rowSize);
      setFrom(rowSize * page);
    };
    const handleSearch = (e) => {
      setSearch(e.target.value.toLowerCase());
      setShowLoader(true);
      setFrom(0);
    };

    // const handleRowClick = (e,row) => {
    //     var updatedSelectedRows = [];
    //     if (e.target.checked){
    //       updatedSelectedRows = [...selectedRows, row];
    //     } else {
    //       updatedSelectedRows = selectedRows.filter((r) => r.id !== row.id);
    //     }
    //     setSelectAll(true);
    //     setSelectedRows(updatedSelectedRows);
    //   };

    //   const handleSelectAll = (e) => {
    //     setSelectAll(e.target.checked);
    //     if(e.target.checked){
    //       setSelectedRows(rows);
    //     }else{
    //       setSelectedRows([]);
    //     }
    //   };

    const handleDelete = (row) => {
      const payload = {
        clientId: row?.row?.clientId,
        shareId: row?.row?.shareInfo?.id,
        vendorId: row?.row?.vendorId,
        agreementId: row?.id,
        //  "createTimeStamp": timestamp,
        //  "lastUpdtTimeStamp": timestamp,
        //  "loginId": 3,
        //  "ipAddress": "200.200.220.2"
      };
      props.deleteClientPartialSignupList(payload);
    };

    const handleOnDeleteAction = (row) => {
      popUpAlert({
        title: "Are you sure?",
        text: "Partial Signup will be removed from the application.",
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Yes, delete it!",
        closeOnConfirm: false,
        onConfirmed: true,
        handleConfirm: () => handleDelete(row),
      });
    };

    const handleColumns = () => {
      const headers = [
        { id: "details", label: "Details" },
        {
          id: "vendorName",
          label: "Vendor Name",
          sort: "vendorName" in tableSort ? tableSort["vendorName"] : "",
        },
        {
          id: "clientNam",
          label: "Client Name",
          sort: "clientNam" in tableSort ? tableSort["clientNam"] : "",
          cell: (item) => {
            const fullName = item.clientNam || "";
            const nameArray = fullName.split(" ");
            const firstName = nameArray[0];
            return (
              <>
                <div>{item.chosenNam || firstName}</div>
              </>
            );
          },
        },
        {
          id: "clientEmail",
          label: "Client Email",
          sort: "clientEmail" in tableSort ? tableSort["clientEmail"] : "",
        },
        {
          id: "shareInfo",
          label: "Share Name",
          sort: "shareInfo" in tableSort ? tableSort["shareInfo"] : "",
        },
        {
          id: "totalAmnt",
          label: "Amount",
          sort: "totalAmnt" in tableSort ? tableSort["totalAmnt"] : "",
          cell: (item) => {
            return <div> {"$" + item?.totalAmnt} </div>;
          },
        },
        {
          id: "submittedDate",
          label: "Date of Signup",
          sort: "submittedDate" in tableSort ? tableSort["submittedDate"] : "",
          format: (value) =>
            moment(value, "YYYY-MM-DDTHH:mm:ss[Z]").format(
              "MM/DD/YYYY, h:mm a"
            ),
        },
        {
          id: "action",
          label: "Action",
          cell: (item) => {
            return (
              <>
                <IconButton
                  aria-label="edit"
                  onClick={() =>
                    navigate(
                      "/kfresh/csa-signup/" +
                        item?.id +
                        "/" +
                        item?.row?.clientId +
                        "/" +
                        item?.row?.vendorId +
                        "?loginId=" +
                        props?.userAuth?.user?.id,
                      {
                        state: {
                          vendorId: item?.row?.vendorId,
                          agreementId: item?.id,
                          clientId: item?.row?.clientId,
                          loginId: props?.userAuth?.user?.id,
                        },
                      }
                    )
                  }
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleOnDeleteAction(item)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            );
          },
        },
      ];
      setColumns(headers);
    };  

    const renderDetails = (row) => {
      return(
      <PartialSignupDetails
          row={row}
      />

      );
    };

     const handleSort = (item) => {
        let sortitem = {};
        if (item.sort == "" || item.sort == "DESC") {
          sortitem[item.id] = "ASC";
        } else {
          sortitem[item.id] = "DESC";
        }
        setTableSort(sortitem);
      };

  const handleExport = () => {
    const payload = {
      "nam": search,
      "startDate": filterData.startDate ? moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY") : "",
      "endDate": filterData.endDate ? moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY") : "",
    }
    props.exportClientPartialSignup(payload);
  }
      
  return (
        <Grid container className="container-block" rowSpacing={2} >
            <Grid item xs={12} md={6}>
                <PageHeaderComponent
                    title = {"PARTIAL SIGNUP "}
                    showSubtitle = {true}
                    subtitle = {"Total Records"}
                    count={partialSignupTotal}
                />
            </Grid>
            <Grid item xs={12}  md={6} sx={{ flexGrow: 1 }}>
                <Stack direction='row' spacing={2} justifyContent="flex-end">
                    <Grid item >
                        <DatePickerComponent label ={dateRangePickerLabel}  selectedDateObj={(date) => { filterHandleChange(date, "", "date") }} 
                        resetDate={resetDate}
                        />
                        {/* label={`${moment(CalculateUTCTzToUserTz(filterData.startDate)).format('MM-DD-YYYY')} - ${moment(CalculateUTCTzToUserTz(filterData.endDate)).format('MM-DD-YYYY')}`} */}
                    </Grid>
                    <Grid item>
                      <CustomButton variant="outlined"  startIcon={<i className="fa fa-download" aria-hidden="true"></i>} onClick={handleExport}>
                         Export
                      </CustomButton>
                    </Grid>
                    <Grid item>
                        <CustomButton variant="outlined" startIcon={<i className="fa fa-refresh" aria-hidden="true"></i>} onClick={()=>{
                       setSearch("");
                       setExpand(false);
                       props.refreshKey();
                       setFilterData('');
                       setResetDate(preDate=>
                        !preDate)
                       setShowLoader(true)}}>
                            Refresh  
                        </CustomButton>  
                    </Grid>          
                </Stack>
            </Grid>
            <Grid item xs={12} sx={{ flexGrow: 1 }}>
                <SearchComponent
                  placeholder={"Name,Email"}
                  handleChange={handleSearch}
                  searchText={search}
                />
            </Grid>
            <Grid item xs={12}>
                <TableComponent 
                  columns={columns}
                  rows={rows}
                  showDetails={true}
                  expand={expand}
                  renderDetails={renderDetails}
                  handlePagination={handleNext}
                  total={partialSignupTotal}
                  page={Math.floor(from / size)}
                  rowsPerPage={size}
                  handleSort={handleSort}
                  dataLoading={showLoader}
                />
            </Grid>

        </Grid>

    )
}
PartialSignupComponent.propTypes = {
    getClientPartialSignupList: PropTypes.func.isRequired,
    csaSignup: PropTypes.object.isRequired,
    showVendorHistoryDetails : PropTypes.bool,
    refreshKey:PropTypes.func.isRequired,
    exportClientPartialSignup: PropTypes.func.isRequired,
    deleteClientPartialSignupList:PropTypes.func.isRequired,
    id:PropTypes.object,
    userAuth: PropTypes.object.isRequired,
    allowedRoles: PropTypes.array,
}

function mapDispatchToProps(dispatch) {
    return {
        refreshKey: () => dispatch(refreshKey()),
        getClientPartialSignupList: (payload) =>dispatch(actionTypes.getClientPartialSignupList(payload)),
        exportClientPartialSignup : (payload) =>dispatch(actionTypes.exportClientPartialSignup(payload)),
        deleteClientPartialSignupList:(payload) =>dispatch(actionTypes.deleteClientPartialSignupList(payload))
      };
  }
export default connect(mapStateToProps,mapDispatchToProps)(PartialSignupComponent);