import * as React from 'react';
import dayjs from 'dayjs';
import PropTypes from "prop-types";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import "../../../styles/help.scss";
import { renderTimeViewClock } from '@mui/x-date-pickers';

const DateTimePickerField = ({value,handleChange,maxDate,mode}) => {
    const [date, setDate] = React.useState(dayjs(value?value:new Date().getTime()));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker
            value={date}
            className={mode === "help" ? "dateTimePicker" : ""}
            onChange={(newValue) => {
                setDate(newValue);
                handleChange(newValue)
            }}
            maxDate={dayjs(maxDate)}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

DateTimePickerField.propTypes = {
    value:PropTypes.string,
    handleChange:PropTypes.func,
    maxDate:PropTypes.string,
    mode:PropTypes.string,
}

export default DateTimePickerField;