import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import { Box, Stack } from "@mui/material";
import farmer from "../../assets/images/farmer.png";
import senior from "../../assets/images/senior.png";
import Registration from "./Registration";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const RegistrationDialog = ({
  open,
  handleClose,
  handleLoginDialog,
}) => {
  const onClose = () => {
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="lg"
        PaperProps={{
          sx: {
            borderRadius: "10px",
          },
        }}
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Registration handleLoginDialog={handleLoginDialog} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

RegistrationDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleLoginDialog: PropTypes.func,
};

export default RegistrationDialog;
