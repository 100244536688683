export const ProgramSectionData =
  {
    csaPickupSite: {
      storeAvailFlag:true,
      id:"",
      VendorId:"",
      pickup_site_name: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      zipcode: "",
      special_instructions: "",
      working_hours: [
        {
          day: "Sunday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: true,
        },
        {
          day: "Monday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Tuesday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Wednesday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Thursday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Friday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Saturday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: true,
        },
      ],
      is_specialhours: false,
      distribution_option: [
        {
          name: "Curbside pickup",
          enabled: false,
          value: "",
        },
        {
          name: "Delivery",
          enabled: false,
          value: "",
        },
        {
          name: "In-Person",
          enabled: false,
          value: "",
        },
        {
          name: "Online pre-selection",
          enabled: false,
          value: "",
        },
        {
          name: "Phone pre-selection",
          enabled: false,
          value: "",
        },
        {
          name: "Other",
          enabled: false,
          value: "",
        },
      ],
      othertext: "",
      months_operations: [
        {
          value: "January",
          isenabled: false,
        },
        {
          value: "February",
          isenabled: false,
        },
        {
          value: "March",
          isenabled: false,
        },
        {
          value: "April",
          isenabled: false,
        },
        {
          value: "May",
          isenabled: false,
        },
        {
          value: "June",
          isenabled: false,
        },
        {
          value: "July",
          isenabled: false,
        },
        {
          value: "August",
          isenabled: false,
        },
        {
          value: "September",
          isenabled: false,
        },
        {
          value: "October",
          isenabled: false,
        },
        {
          value: "November",
          isenabled: false,
        },
        {
          value: "December",
          isenabled: false,
        },
      ],
      special_hoursOfOperation: [
        {
          status: "Available",
          week: "1",
          time_filter: "Custom",
          day: [{
           label:"Monday",
            value: "Monday",
            checked: 1
        }, {
           label:"Tuesday",
            value: "Tuesday",
            checked: 1
        }, {
           label:"Wednesday",
            value: "Wednesday",
            checked: 1
        }, {
           label:"Thursday",
            value: "Thursday",
            checked: 1
        }, {
           label:"Friday",
            value: "Friday",
            checked: 1
        }, {
           label:"Saturday",
            value: "Saturday",
            checked: 1
        }, {
           label:"Sunday",
            value: "Sunday",
            checked: 1
        }],
          month:[{
            label: "January",
            value: "January",
            checked: 1
        }, {
            label: "February",
            value: "February",
            checked: 1
        }, {
            label: "March",
            value: "March",
            checked: 1
        }, {
            label: "April",
            value: "April",
            checked: 1
        }, {
            label: "May",
            value: "May",
            checked: 1
        }, {
            label: "June",
            value: "June",
            checked: 1
        }, {
            label: "July",
            value: "July",
            checked: 1
        }, {
            label: "August",
            value: "August",
            checked: 1
        }, {
            label: "September",
            value: "September",
            checked: 1
        }, {
            label: "October",
            value: "October",
            checked: 1
        }, {
            label: "November",
            value: "November",
            checked: 1
        }, {
            label: "December",
            value: "December",
            checked: 1
        }],
          open_time: "12:00",
          close_time: "12:00",
          open_time_meridian: "am",
          close_time_meridian: "pm",
        },
      ],
      location_types_name: "csaPickupSite",
      location: {
        lat: "",
        lng: "",
      },
      latitude: "",
      longitude: "",
    },
    farmStand: {
      storeAvailFlag:true,
      id:"",
      VendorId:"",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      zipcode: "",
      special_instructions: "",
      working_hours: [
        {
          day: "Sunday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: true,
        },
        {
          day: "Monday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Tuesday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Wednesday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Thursday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Friday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Saturday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: true,
        },
      ],
      is_specialhours: false,
      distribution_option: [
        {
          name: "Curbside pickup",
          enabled: false,
          value: "",
        },
        {
          name: "Delivery",
          enabled: false,
          value: "",
        },
        {
          name: "In-Person",
          enabled: false,
          value: "",
        },
        {
          name: "Online pre-selection",
          enabled: false,
          value: "",
        },
        {
          name: "Phone pre-selection",
          enabled: false,
          value: "",
        },
        {
          name: "Other",
          enabled: false,
          value: "",
        },
      ],
      othertext: "",
      months_operations: [
        {
          value: "January",
          isenabled: false,
        },
        {
          value: "February",
          isenabled: false,
        },
        {
          value: "March",
          isenabled: false,
        },
        {
          value: "April",
          isenabled: false,
        },
        {
          value: "May",
          isenabled: false,
        },
        {
          value: "June",
          isenabled: false,
        },
        {
          value: "July",
          isenabled: false,
        },
        {
          value: "August",
          isenabled: false,
        },
        {
          value: "September",
          isenabled: false,
        },
        {
          value: "October",
          isenabled: false,
        },
        {
          value: "November",
          isenabled: false,
        },
        {
          value: "December",
          isenabled: false,
        },
      ],
      special_hoursOfOperation: [
        {
          status: "Available",
          week: "1",
          time_filter: "Custom",
          day: [{
            label:"Monday",
             value: "Monday",
             checked: 1
         }, {
            label:"Tuesday",
             value: "Tuesday",
             checked: 1
         }, {
            label:"Wednesday",
             value: "Wednesday",
             checked: 1
         }, {
            label:"Thursday",
             value: "Thursday",
             checked: 1
         }, {
            label:"Friday",
             value: "Friday",
             checked: 1
         }, {
            label:"Saturday",
             value: "Saturday",
             checked: 1
         }, {
            label:"Sunday",
             value: "Sunday",
             checked: 1
         }],
          month: [{
            label: "January",
            value: "January",
            checked: 1
        }, {
            label: "February",
            value: "February",
            checked: 1
        }, {
            label: "March",
            value: "March",
            checked: 1
        }, {
            label: "April",
            value: "April",
            checked: 1
        }, {
            label: "May",
            value: "May",
            checked: 1
        }, {
            label: "June",
            value: "June",
            checked: 1
        }, {
            label: "July",
            value: "July",
            checked: 1
        }, {
            label: "August",
            value: "August",
            checked: 1
        }, {
            label: "September",
            value: "September",
            checked: 1
        }, {
            label: "October",
            value: "October",
            checked: 1
        }, {
            label: "November",
            value: "November",
            checked: 1
        }, {
            label: "December",
            value: "December",
            checked: 1
        }],
          open_time: "12:00",
          close_time: "12:00",
          open_time_meridian: "am",
          close_time_meridian: "pm",
        },
      ],
      location_types_name: "csaPickupSite",
      location: {
        lat: "",
        lng: "",
      },
      latitude: "",
      longitude: "",
    },
    mobileMarket: {
      storeAvailFlag:true,
      id:"",
      VendorId:"",  
      location_name: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      zipcode: "",
      special_instructions: "",
      working_hours: [
        {
          day: "Sunday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: true,
        },
        {
          day: "Monday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Tuesday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Wednesday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Thursday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Friday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Saturday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: true,
        },
      ],
      is_specialhours: false,
      distribution_option: [
        {
          name: "Curbside pickup",
          enabled: false,
          value: "",
        },
        {
          name: "Delivery",
          enabled: false,
          value: "",
        },
        {
          name: "In-Person",
          enabled: false,
          value: "",
        },
        {
          name: "Online pre-selection",
          enabled: false,
          value: "",
        },
        {
          name: "Phone pre-selection",
          enabled: false,
          value: "",
        },
        {
          name: "Other",
          enabled: false,
          value: "",
        },
      ],
      othertext: "",
      months_operations: [
        {
          value: "January",
          isenabled: false,
        },
        {
          value: "February",
          isenabled: false,
        },
        {
          value: "March",
          isenabled: false,
        },
        {
          value: "April",
          isenabled: false,
        },
        {
          value: "May",
          isenabled: false,
        },
        {
          value: "June",
          isenabled: false,
        },
        {
          value: "July",
          isenabled: false,
        },
        {
          value: "August",
          isenabled: false,
        },
        {
          value: "September",
          isenabled: false,
        },
        {
          value: "October",
          isenabled: false,
        },
        {
          value: "November",
          isenabled: false,
        },
        {
          value: "December",
          isenabled: false,
        },
      ],
      special_hoursOfOperation: [
        {
          status: "Available",
          week: "1",
          time_filter: "Custom",
          day: [{
            label:"Monday",
             value: "Monday",
             checked: 1
         }, {
            label:"Tuesday",
             value: "Tuesday",
             checked: 1
         }, {
            label:"Wednesday",
             value: "Wednesday",
             checked: 1
         }, {
            label:"Thursday",
             value: "Thursday",
             checked: 1
         }, {
            label:"Friday",
             value: "Friday",
             checked: 1
         }, {
            label:"Saturday",
             value: "Saturday",
             checked: 1
         }, {
            label:"Sunday",
             value: "Sunday",
             checked: 1
         }],
          month: [{
            label: "January",
            value: "January",
            checked: 1
        }, {
            label: "February",
            value: "February",
            checked: 1
        }, {
            label: "March",
            value: "March",
            checked: 1
        }, {
            label: "April",
            value: "April",
            checked: 1
        }, {
            label: "May",
            value: "May",
            checked: 1
        }, {
            label: "June",
            value: "June",
            checked: 1
        }, {
            label: "July",
            value: "July",
            checked: 1
        }, {
            label: "August",
            value: "August",
            checked: 1
        }, {
            label: "September",
            value: "September",
            checked: 1
        }, {
            label: "October",
            value: "October",
            checked: 1
        }, {
            label: "November",
            value: "November",
            checked: 1
        }, {
            label: "December",
            value: "December",
            checked: 1
        }],
          open_time: "12:00",
          close_time: "12:00",
          open_time_meridian: "am",
          close_time_meridian: "pm",
        },
      ],
      location_types_name: "mobileMarket",
      location: {
        lat: "",
        lng: "",
      },
      latitude: "",
      longitude: "",
    },
    farmersMarketBooth: {
      storeAvailFlag:true,
      id:"",
      VendorId:"",  
      farmer_market_name: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      zipcode: "",
      special_instructions: "",
      working_hours: [
        {
          day: "Sunday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: true,
        },
        {
          day: "Monday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Tuesday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Wednesday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Thursday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Friday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Saturday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: true,
        },
      ],
      is_specialhours: false,
      distribution_option: [
        {
          name: "Curbside pickup",
          enabled: false,
          value: "",
        },
        {
          name: "Delivery",
          enabled: false,
          value: "",
        },
        {
          name: "In-Person",
          enabled: false,
          value: "",
        },
        {
          name: "Online pre-selection",
          enabled: false,
          value: "",
        },
        {
          name: "Phone pre-selection",
          enabled: false,
          value: "",
        },
        {
          name: "Other",
          enabled: false,
          value: "",
        },
      ],
      othertext: "",
      months_operations: [
        {
          value: "January",
          isenabled: false,
        },
        {
          value: "February",
          isenabled: false,
        },
        {
          value: "March",
          isenabled: false,
        },
        {
          value: "April",
          isenabled: false,
        },
        {
          value: "May",
          isenabled: false,
        },
        {
          value: "June",
          isenabled: false,
        },
        {
          value: "July",
          isenabled: false,
        },
        {
          value: "August",
          isenabled: false,
        },
        {
          value: "September",
          isenabled: false,
        },
        {
          value: "October",
          isenabled: false,
        },
        {
          value: "November",
          isenabled: false,
        },
        {
          value: "December",
          isenabled: false,
        },
      ],
      special_hoursOfOperation: [
        {
          status: "Available",
          week: "1",
          time_filter: "Custom",
          day: [{
            label:"Monday",
             value: "Monday",
             checked: 1
         }, {
            label:"Tuesday",
             value: "Tuesday",
             checked: 1
         }, {
            label:"Wednesday",
             value: "Wednesday",
             checked: 1
         }, {
            label:"Thursday",
             value: "Thursday",
             checked: 1
         }, {
            label:"Friday",
             value: "Friday",
             checked: 1
         }, {
            label:"Saturday",
             value: "Saturday",
             checked: 1
         }, {
            label:"Sunday",
             value: "Sunday",
             checked: 1
         }],
          month:[{
            label: "January",
            value: "January",
            checked: 1
        }, {
            label: "February",
            value: "February",
            checked: 1
        }, {
            label: "March",
            value: "March",
            checked: 1
        }, {
            label: "April",
            value: "April",
            checked: 1
        }, {
            label: "May",
            value: "May",
            checked: 1
        }, {
            label: "June",
            value: "June",
            checked: 1
        }, {
            label: "July",
            value: "July",
            checked: 1
        }, {
            label: "August",
            value: "August",
            checked: 1
        }, {
            label: "September",
            value: "September",
            checked: 1
        }, {
            label: "October",
            value: "October",
            checked: 1
        }, {
            label: "November",
            value: "November",
            checked: 1
        }, {
            label: "December",
            value: "December",
            checked: 1
        }],
          open_time: "12:00",
          close_time: "12:00",
          open_time_meridian: "am",
          close_time_meridian: "pm",
        },
      ],
      location_types_name: "farmersMarket",
      location: {
        lat: "",
        lng: "",
      },
      latitude: "",
      longitude: "",
    },
    farmersMarket: {
      storeAvailFlag:true,
      id:"",
      VendorId:"",  
      farmer_market_name: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      zipcode: "",
      special_instructions: "",
      working_hours: [
        {
          day: "Sunday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: true,
        },
        {
          day: "Monday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Tuesday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Wednesday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Thursday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Friday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: false,
        },
        {
          day: "Saturday",
          open_time: "12:00",
          open_time_meridian: "am",
          close_time: "12:00",
          close_time_meridian: "pm",
          holiday: true,
        },
      ],
      is_specialhours: false,
      distribution_option: [
        {
          name: "Curbside pickup",
          enabled: false,
          value: "",
        },
        {
          name: "Delivery",
          enabled: false,
          value: "",
        },
        {
          name: "In-Person",
          enabled: false,
          value: "",
        },
        {
          name: "Online pre-selection",
          enabled: false,
          value: "",
        },
        {
          name: "Phone pre-selection",
          enabled: false,
          value: "",
        },
        {
          name: "Other",
          enabled: false,
          value: "",
        },
      ],
      othertext: "",
      months_operations: [
        {
          value: "January",
          isenabled: false,
        },
        {
          value: "February",
          isenabled: false,
        },
        {
          value: "March",
          isenabled: false,
        },
        {
          value: "April",
          isenabled: false,
        },
        {
          value: "May",
          isenabled: false,
        },
        {
          value: "June",
          isenabled: false,
        },
        {
          value: "July",
          isenabled: false,
        },
        {
          value: "August",
          isenabled: false,
        },
        {
          value: "September",
          isenabled: false,
        },
        {
          value: "October",
          isenabled: false,
        },
        {
          value: "November",
          isenabled: false,
        },
        {
          value: "December",
          isenabled: false,
        },
      ],
      special_hoursOfOperation: [
        {
          status: "Available",
          week: "1",
          time_filter: "Custom",
          day: [{
            label:"Monday",
             value: "Monday",
             checked: 1
         }, {
            label:"Tuesday",
             value: "Tuesday",
             checked: 1
         }, {
            label:"Wednesday",
             value: "Wednesday",
             checked: 1
         }, {
            label:"Thursday",
             value: "Thursday",
             checked: 1
         }, {
            label:"Friday",
             value: "Friday",
             checked: 1
         }, {
            label:"Saturday",
             value: "Saturday",
             checked: 1
         }, {
            label:"Sunday",
             value: "Sunday",
             checked: 1
         }],
          month:[{
            label: "January",
            value: "January",
            checked: 1
        }, {
            label: "February",
            value: "February",
            checked: 1
        }, {
            label: "March",
            value: "March",
            checked: 1
        }, {
            label: "April",
            value: "April",
            checked: 1
        }, {
            label: "May",
            value: "May",
            checked: 1
        }, {
            label: "June",
            value: "June",
            checked: 1
        }, {
            label: "July",
            value: "July",
            checked: 1
        }, {
            label: "August",
            value: "August",
            checked: 1
        }, {
            label: "September",
            value: "September",
            checked: 1
        }, {
            label: "October",
            value: "October",
            checked: 1
        }, {
            label: "November",
            value: "November",
            checked: 1
        }, {
            label: "December",
            value: "December",
            checked: 1
        }],
          open_time: "12:00",
          close_time: "12:00",
          open_time_meridian: "am",
          close_time_meridian: "pm",
        },
      ],
      location_types_name: "farmersMarketBooth",
      location: {
        lat: "",
        lng: "",
      },
      latitude: "",
      longitude: "",
    },
  };
