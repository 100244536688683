import React, { useState } from "react";
import PropTypes from "prop-types";
import SidebarNavigation from "../../common/sidebar/sidebar-navigation";
import { Grid, Box, Typography } from "@mui/material";
import { WhiteWrapper } from "../../../styles/js/CSASignup";
import "../../../styles/components/_CsaSignup.scss";
import FarmInfocomponent from "./farm-info-component";
import ClientInfoComponent from "./client-info-component";
import SnapShareComponent from "./snap-share-component";
import SignSubmitComponent from "./sign-submit-component";
import PickupDeliveryComponent from "./pickup-delivery-component";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import * as actionType from "../../../redux/actionTypes/csaSignup";
import { useParams } from "react-router-dom";
import { popUpAlert } from "../../../utils/globalMethods";
import CommonLoading from "../../common/components/commonLoading";
import { Padding } from "@mui/icons-material";
import useLanguageCode from "../../../hooks/useLanguageCode";
import useTranslator from "../../../hooks/useTranslator";

let staticMenu = [];
function CSASignupWrapper(props) {
  const transProps = useTranslator(
    [
      "title",
      "farmInformation",
      "clientInformation",
      "spfShare",
      "pickupDelevery",
      "signSubmit",
    ],
    "spfEnrollment"
  );
  const languageCode = useLanguageCode();
  const language = languageCode?.toUpperCase();

  const navigate = useNavigate();

  const userRole = props?.userAuth?.user
    ? props?.userAuth?.user
    : JSON.parse(sessionStorage.getItem("userData"));
  if (props?.allowedRoles) {
    const checkRole = props?.allowedRoles.find((role) =>
      userRole?.Role.includes(role)
    );
    if (!checkRole) {
      navigate("/kfreshfep");
      return;
    }
  }
  let { agreementId, vendorId, clientId } = useParams();
  let loginId = props?.userAuth?.user?.id;
  const [activeTab, setActiveTab] = useState("menuStep1");
  const [activeTabId, setActiveTabId] = useState();
  const [newmenus] = useState([]);
  const [formValidate, setFormValidate] = useState({});
  const [shareSelection, setShareSelection] = useState([]);
  const [shareDetails, setShareDetails] = useState([]);
  const [clientInfo, setClientInfo] = useState();
  const [farmInfo, setFarmInfo] = useState({});
  const [pickupDetails, setPickupDetails] = useState({});
  const [signature, setSignature] = useState({});
  const [isSignSubmit, setIsSignSubmit] = useState(false);
  const [sharePrecal, setSharePrecal] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  const [vendorChange, setVendorChange] = useState();
  const [submitPickupData, setSubmitPickupData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [allSharePrecal, setAllSharePrecal] = useState();

  const [menuActive] = useState({
    farm_info: true,
    client_info: false,
    snap_csa: false,
    pickup_del: false,
    sign_submit: false,
  });

  staticMenu = [
    {
      label: transProps.farmInformation,
      className: "list-group-item menu-list",
      liClassName: "",
      id: "menuStep1",
      name: "farm_info",
      activeTab: activeTab,
    },
    {
      label: transProps.clientInformation,
      className: "list-group-item menu-list",
      liClassName: "disabled",
      id: "menuStep2",
      name: "client_info",
      activeTab: activeTab,
    },
    {
      label: transProps.spfShare,
      className: "list-group-item menu-list disabled",
      id: "menuStep3",
      name: "snap_csa",
      liClassName: "disabled",
      activeTab: activeTab,
    },
    {
      label: transProps.pickupDelevery,
      className: "list-group-item menu-list disabled",
      id: "menuStep4",
      name: "pickup_del",
      liClassName: "disabled",
      activeTab: activeTab,
    },
    {
      label: transProps.signSubmit,
      className: "list-group-item menu-list disabled",
      id: "menuStep5",
      name: "sign_submit",
      liClassName: "disabled",
      activeTab: activeTab,
    },
  ];

  useEffect(() => {
    setIsLoading(false);
    if (props.csaSignup?.submitSignup?.submitSignup?.code === "200") {
      const response = {
        appId: props.csaSignup?.submitSignup?.submitSignup?.appId,
        documentKey: props.csaSignup?.submitSignup?.submitSignup?.documentKey,
        clientId: props.csaSignup?.submitSignup?.submitSignup?.clientId,
      };
      navigate("/kfresh/client-agreement", { state: response });
    } else if (props.csaSignup?.submitSignup?.submitSignup?.code === "15") {
      popUpAlert({
        text: props.csaSignup?.submitSignup?.submitSignup?.message,
        icon: "warning",
        timer: 2000,
      });
      setIsSignSubmit(false);
    }
  }, [props.csaSignup?.submitSignup?.submitSignup]);

  useEffect(() => {
    if (isSignSubmit) {
      if (!signature?.signature) {
        popUpAlert({
          text: "Esign Id does not exist. Please resubmit the form",
          icon: "warning",
          timer: 2000,
        });
        setIsSignSubmit(false);
        return;
      }
      setIsLoading(true);
      const share = [];
      let totalShrCost = 0;
      if (shareDetails.length) {
        shareDetails.forEach((item, i) => {
          const deliveryData = pickupDetails
            .filter(
              (data) =>
                parseInt(data.shareId) == item.id &&
                data.location === "delivery"
            )
            .map((delivery) => {
              const obj = delivery;
              obj.city = delivery?.city?.values;
              return obj;
            });
          const pickupInfoArr = pickupDetails.find(
            (data) => data?.shareId == item.id && data?.location == "pickup"
          );
          totalShrCost = totalShrCost + item?.shareCost;

          const shareSelected = [];
          if (
            allSharePrecal &&
            allSharePrecal[item.id] &&
            item?.nestedArray &&
            item?.nestedArray?.length == allSharePrecal[item.id].length
          ) {
            //item?.nestedArray && item?.nestedArray?.length
            allSharePrecal[item.id].forEach((obj) => {
              const duration =
                obj?.paymentType == "DAILY"
                  ? "1 Day"
                  : obj?.paymentInfo?.length + " Month(s)";
              const startDt = obj?.paymentInfo?.[0]?.paymentDate;
              const endDt =
                obj?.paymentType == "DAILY"
                  ? obj?.paymentInfo?.[0]?.paymentDate
                  : obj?.paymentInfo?.[obj?.paymentInfo?.length - 1]
                      ?.paymentDate;
              const shrObj = {
                duration: duration,
                shareStartDate: startDt,
                shareEndDate: endDt,
                paymentType: obj?.paymentType,
                submittedBy: "ADMIN",
                determinedAmount: obj?.determinedAmount,
              };
              shareSelected.push(shrObj);
            });
          } else {
            allSharePrecal[item.id].forEach((obj) => {
              const duration =
                obj?.paymentType == "DAILY"
                  ? "1 Day"
                  : obj?.paymentInfo?.length + " Month(s)";
              const startDt = obj?.paymentInfo?.[0]?.paymentDate;
              const endDt =
                obj?.paymentType == "DAILY"
                  ? obj?.paymentInfo?.[0]?.paymentDate
                  : obj?.paymentInfo?.[obj?.paymentInfo?.length - 1]
                      ?.paymentDate;
              const shrObj = {
                duration: duration,
                shareStartDate: startDt,
                shareEndDate: endDt,
                paymentType: obj?.paymentType,
                submittedBy: "ADMIN",
                determinedAmount: obj?.determinedAmount,
              };
              shareSelected.push(shrObj);
            });
          }

          let shareNam = item?.nam;
          let shareDsc = item?.dsc;

          if (
            languageCode.toLowerCase() != "en" &&
            item?.multiLang &&
            item?.multiLang.length
          ) {
            const lang = item?.multiLang.find(
              (l) => l.lang.toLowerCase() == languageCode.toLowerCase()
            );
            if (lang) {
              shareNam = lang?.nam;
              shareDsc = lang?.dsc;
            }
          }

          const shareObj = {
            index: i + 1,
            shareId: item?.id,
            shareName: shareNam,
            dsc: shareDsc,
            dlvryFlg: item?.dlvryFlg,
            shareSelected,
            submittedBy: loginId ? "ADMIN" : "CLIENT",
          };

          if (deliveryData && deliveryData.length) {
            shareObj.deliveryDetails = deliveryData[0];
          }
          if (pickupInfoArr?.address) {
            shareObj.pickupDetails = pickupInfoArr.address;
          }
          share.push(shareObj);
        });
      }

      const farmData = { ...farmInfo?.farmDetails };
      farmData.email = props.csaSignup?.csaVendorInfo?.csaVendorInfo?.email;
      farmData.phoneNumber =
        props.csaSignup?.csaVendorInfo?.csaVendorInfo?.primaryMobile;

      const timestamp = new Date().getTime();
      const systemInfo = sessionStorage.getItem("systemInfo");
      const systemData = JSON.parse(systemInfo);
      const csaSignupForm = {
        apid: clientInfo?.apid,
        dob: clientInfo?.dob,
        email: clientInfo?.email,
        farmDetails: farmData,
        firstName: clientInfo?.firstName,
        hhSize: clientInfo?.hhSize,
        languageCode: languageCode,
        lastName: clientInfo?.lastName,
        chosenName: clientInfo?.chosenNam,
        panNumber: clientInfo?.panNumber,
        phoneNumber: clientInfo?.phoneNumber,
        shareDetails: share,
        signature: signature?.signature,
        isESignExist: signature?.isESignExist,
        totalAmount: totalShrCost,
        totalShareAmountBalance: 0,
        snapAmount: clientInfo?.snapAmount,
        hipAmount: clientInfo?.hipAmount,
        totalShareAmountPaid: totalShrCost,
        lastUpdtUidNam: "CSASGNUP",
        createTimeStamp: timestamp,
        lastUpdtTimeStamp: timestamp,
        ipAddress: systemData.ip,
      };
      if (agreementId && clientId && vendorId) {
        csaSignupForm.agreementId = agreementId;
      }

      if (loginId) {
        csaSignupForm.loginId = loginId;
      }
      props.submitSignup(csaSignupForm);
    }
  }, [signature, isSignSubmit]);

  return (
    <Grid
      container
      spacing={0}
      className="wrapper-banner csa-signup"
      mt={2}
      mb={2}
      alignItems={"start"}
      sx={{ flex: 1, background: "#FFF" }}
    >
      {isLoading && <CommonLoading />}
      <Grid item xs={12} sm={3} mt={3} lg={3}>
        <Box
          className="wrapper"
          sx={{
            borderRadius: "1px",
            background: "#000",
            padding: "10px",
            height: "94vh",
          }}
        >
          <Typography
            tabIndex={0}
            variant="h6"
            className="resText"
            sx={{ color: "#FFF" }}
          >
            {transProps.title}
          </Typography>
          {/* <Typography paragraph={true} /> */}
          <div tabIndex={0} className="sr-only">
            Leftmenu contains list of five items
          </div>
          <SidebarNavigation
            staticMenu={staticMenu}
            newmenus={newmenus}
            activeTab={activeTab}
            activeTabId={activeTabId}
            formArrValidate={formValidate}
            dynamicMenuEnable={menuActive}
            onMenuClick={(v, id) => {
              setActiveTab(v);
              setActiveTabId(id);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={9} mt={3} lg={9}>
        <div tabIndex={0} className="sr-only">
          Right menu
        </div>

        {activeTab == "menuStep1" && (
          <FarmInfocomponent
            setActiveTab={setActiveTab}
            setCSAFormValidate={setFormValidate}
            setFarmInfo={setFarmInfo}
            farmInfo={farmInfo}
            setVendorChange={setVendorChange}
            vendorChange={vendorChange}
          />
        )}
        {activeTab == "menuStep2" && (
          <ClientInfoComponent
            setActiveTab={setActiveTab}
            setCSAFormValidate={setFormValidate}
            setClientInfo={setClientInfo}
            clientInfo={clientInfo}
          />
        )}
        {activeTab == "menuStep3" && (
          <SnapShareComponent
            setActiveTab={setActiveTab}
            vendorChange={vendorChange}
            setVendorChange={setVendorChange}
            setCSAFormValidate={setFormValidate}
            farmInfo={farmInfo}
            setSelectedShares={setShareSelection}
            selectedShare={shareSelection}
            setSharePrecal={setSharePrecal}
            sharePrecal={sharePrecal}
            shareDetails={setShareDetails}
            clientInfo={clientInfo}
            setAllSharePrecal={setAllSharePrecal}
          />
        )}
        {activeTab == "menuStep4" && (
          <PickupDeliveryComponent
            setActiveTab={setActiveTab}
            setVendorChange={setVendorChange}
            setCSAFormValidate={setFormValidate}
            selectedShare={shareSelection}
            setPickupDetails={setPickupDetails}
            setSelectedAddress={setSelectedAddress}
            selectedAddress={selectedAddress}
            pickupDetails={pickupDetails}
            setSubmitPickupData={setSubmitPickupData}
            submitPickupData={submitPickupData}
          />
        )}
        {activeTab == "menuStep5" && (
          <SignSubmitComponent
            setSignature={setSignature}
            setActiveTab={setActiveTab}
            setCSAFormValidate={setFormValidate}
            clientInfo={clientInfo}
            setIsSignSubmit={setIsSignSubmit}
          />
        )}
      </Grid>
    </Grid>
  );
}
CSASignupWrapper.propTypes = {
  submitSignup: PropTypes.func,
  csaSignup: PropTypes.object,
  setActiveTab: PropTypes.string,
  userAuth: PropTypes.object.isRequired,
  allowedRoles: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    submitSignup: (csaSignupForm) =>
      dispatch(actionType.submitSignup(csaSignupForm)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CSASignupWrapper);
