import React, { useState } from "react";
import {
  Box,
  TextField,
  Stack,
  Button,
  FormLabel,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import toast from "react-hot-toast";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { AUTH_API } from "../../config";
import useLoader from "../../hooks/useLoader";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import Buttonn from "react-bootstrap/Button";

const ResetPassword = ({ role }) => {
  const user = JSON.parse(sessionStorage.getItem("userLogin"));
  const [showPassword, setShowPassword] = useState(false);
  const { openLoader, closeLoader } = useLoader();
  const validationSchema = Yup.object({
    otp: Yup.string()
      .matches(/^[0-9]{6}$/, "Must be 6 digits")
      .required("OTP is required"),
    newPassword: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
        "Password must contain at least one special character"
      )
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
      newPassword: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      let { otp, newPassword } = values;
      let payload = {
        userLoginId: user.email,
        role: role,
        otp,
        newPassword,
      };
      try {
        openLoader("reset-password");
        const res = await axios.post(`${AUTH_API}/saveNewPassword`, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.status === 200) {
          toast.success("Password reset successful.");
          formik.resetForm();
        }
      } catch (error) {
        toast.error("Password reset attempt failed.");
      } finally {
        closeLoader("reset-password");
      }
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  console.log("user", user);

  const sendOtp = async () => {
    try {
      openLoader("otp");
      const res = await axios.post(
        `${AUTH_API}/forgotPassword/${user.email}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        toast.success("OTP sent. Please check your email.");
      }
    } catch (error) {
      toast.error("OTP request failed.");
    } finally {
      closeLoader("otp");
    }
  };

  return (
    <Box>
      <div
        className="w-100 h-100"
        style={{
          padding: "1rem",
          display: "flex",
          // justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            minWidth: "500px",
            padding: "30px",
            border: "1px solid rgba(0,0,0,.125)",
            borderRadius: "0.25rem",
          }}
        >
          <Stack direction="row" sx={{ justifyContent: "space-between" }}>
            <Typography
              variant="h5"
              display="inline"
              className="eligibilityText text-capitalize"
              gutterBottom
              component="div"
            >
              Reset Password
            </Typography>
          </Stack>

          <p>
            Enter a new password for <b>{user.email}</b>
          </p>
          <Buttonn color="success" onClick={sendOtp}>
            Send OTP
          </Buttonn>

          <Stack
            component="form"
            className="login-form w-100"
            onSubmit={formik.handleSubmit}
          >
            <Box sx={{ marginTop: "1rem" }}>
              {/* <FormLabel className="labelName">OTP Number</FormLabel> */}
              <Typography
                sx={{
                  fontSize: "16px !important",
                  fontWeight: "700 !important",
                  float: "left !important",
                }}
              >
                OTP Number
              </Typography>
              <TextField
                size="small"
                fullWidth
                id="otp"
                placeholder="Enter the otp number"
                name="otp"
                value={formik.values.otp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.otp && formik.errors.otp}
                helperText={formik.touched.otp && formik.errors.otp}
                InputProps={{
                  onInput: (event) => {
                    event.target.value = event.target.value.replace(
                      /[^0-9]/g,
                      ""
                    );
                  },
                }}
                inputProps={{ maxLength: 6 }}
              />
            </Box>
            <Box sx={{ marginTop: "1rem" }}>
              {/* <FormLabel className="labelName">New Password</FormLabel> */}
              <Typography
                sx={{
                  fontSize: "16px !important",
                  fontWeight: "700 !important",
                  float: "left !important",
                }}
              >
                New Password
              </Typography>
              <TextField
                size="small"
                fullWidth
                id="newPassword"
                placeholder="Enter your new password"
                type={showPassword ? "text" : "password"}
                name="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.newPassword && formik.errors.newPassword}
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {/* <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt={5}
            gap={2}
          >
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{ width: "100%" }}
            >
              Reset Password
            </Button> */}
            {/* <Row className="modal-footer"> */}
            <Col style={{ marginTop: "25px" }}>
              <Buttonn variant="primary" type="submit" className="float-right">
                Reset Password
                <i className="fa fa-arrow-right"></i>
              </Buttonn>
            </Col>
            {/* </Row> */}
            {/* </Box> */}
          </Stack>
        </Box>
      </div>
    </Box>
  );
};

ResetPassword.propTypes = {
  role: PropTypes.string,
};

ResetPassword.defaultProps = {
  role: "",
};

export default ResetPassword;
