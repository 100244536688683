import React from "react";
import DataGridTable from "../../../common/DataGrid";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Button, Stack } from "@mui/material";

const FarmerDocuments = () => {
  const COLUMN = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "docType",
      headerName: "Document Type",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "userFileName",
      headerName: "File Name",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "createUserId",
      headerName: "Created By",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "createDt",
      headerName: "Created Date",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "grid_header",
      flex: 1,
      renderCell: ({
        row: { documentId = null, sourceCaseId = null, userFileName = "" },
      }) => {
        return (
          <Stack direction="row" alignItems={"center"}>
            <Button
              sx={{
                color: "#44A0E3",
                cursor: "pointer",
                padding: "0px !important",
                "& .css-1d6wzja-MuiButton-startIcon": {
                  marginRight: "3px !important",
                },
              }}
              startIcon={<FileDownloadOutlinedIcon />}
              // onClick={(e) =>
              //   handlePDFDownload(e, documentId, sourceCaseId, userFileName)
              // }
            >
              <span style={{ fontSize: "14px", paddingRight: "5px" }}>
                Download
              </span>
            </Button>
          </Stack>
        );
      },
    },
  ];
  const ROW = [
    {
      id: 1,
      docType: "Identity",
      userFileName: "Identityfile.png",
      createUserId: "Alex Roy",
      createDt: "13/12/2024",
    },
    {
      id: 2,
      docType: "Income",
      userFileName: "File2.png",
      createUserId: "Alex Roy",
      createDt: "03/01/2024",
    },
    {
      id: 3,
      docType: "Identity",
      userFileName: "Idfile.png",
      createUserId: "Alex Roy",
      createDt: "03/19/2024",
    },
    {
      id: 4,
      docType: "Identity",
      userFileName: "File1.png",
      createUserId: "Alex Roy",
      createDt: "03/05/2024",
    },
  ];
  return (
    <div>
      {" "}
      <DataGridTable columns={COLUMN} data={ROW} />
    </div>
  );
};

export default FarmerDocuments;
