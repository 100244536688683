import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import cx from "classnames";
import { findIndex, map, size } from "lodash";
import { Typography } from "@mui/material";

function MultiStepper({ steps }) {
  const currentIndex = findIndex(steps, (s) => s.done);
  const offset = (100 / size(steps) / 2).toFixed(2);
  const stepLength = 100 / (size(steps) - 1);

  return (
    <div className={styles.multiStep}>
      <div
        className={styles.progressBar}
        style={{
          "--offset": `${offset}%`,
          "--process-width": `${stepLength * currentIndex}%`,
        }}
      >
        <div className={styles.progress}></div>
      </div>
      <div className={styles.stepsContainer}>
        {map(steps, (step, index) => {
          const isActive = index < currentIndex;
          const isCurrent = index === currentIndex;
          return (
            <div className={styles.step}>
              <div
                className={cx(styles.indicator, {
                  [styles.active]: isActive,
                  [styles.current]: isCurrent,
                })}
              >
                {index + 1}
              </div>
              <div className={styles.stepTitle}>
                <Typography
                  lineHeight={1.75}
                  variant={
                    isActive ? "subtitle1" : isCurrent ? "body1" : "body2"
                  }
                  sx={{
                    fontWeight: isCurrent ? 700 : isActive ? 500 : 400,
                    color: isCurrent ? "#457425" : "#000",
                  }}
                >
                  {step.title}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

MultiStepper.propTypes = { steps: PropTypes.object };

export default MultiStepper;
