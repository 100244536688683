
const initialState = {
  stats: {
    vendorTotalCount: 0,
    unverifiedCount: 0,
    verifiedCount: 0,
  },
  statsSenior: {
    vendorTotalCount: 0,
    unverifiedCount: 0,
    verifiedCount: 0,
  },
  statsMarket: {
    vendorTotalCount: 0,
    unverifiedCount: 0,
    verifiedCount: 0,
  },
  statisticsData: {
    totalStoreCount: 0,
    totalCsaPickupSite: 0,
    totalFarmStand: 0,
    totalFarmersMarket: 0,
    totalFarmersMarketManagers: 0,
    totalMobileMarket: 0,
    totalMonths: [],
    totalCity: [],
  },
  seniorStatisticsData: {
    totalStoreCount: 0,
    totalCsaPickupSite: 0,
    totalFarmStand: 0,
    totalFarmersMarket: 0,
    totalFarmersMarketManagers: 0,
    totalMobileMarket: 0,
    totalMonths: [],
    totalCity: [],
  },
  marketStatisticsData: {
    totalStoreCount: 0,
    totalCsaPickupSite: 0,
    totalFarmStand: 0,
    totalFarmersMarket: 0,
    totalFarmersMarketManagers: 0,
    totalMobileMarket: 0,
    totalMonths: [],
    totalCity: [],
  },
};
  
  const adminDashboardReducer = (state=initialState, action) => {
      switch (action.type) {
        case "SET_VENDOR_STATS": {
          return {
            ...state,
            stats: {
              vendorTotalCount: action.payload.vendorTotalCount,
              unverifiedCount: action.payload.unverifiedCount,
              verifiedCount: action.payload.verifiedCount,
            },
          };
        }
        case "SET_SENIOR_STATS": {
          return {
            ...state,
            statsSenior: {
              vendorTotalCount: action.payload.vendorTotalCount,
              unverifiedCount: action.payload.unverifiedCount,
              verifiedCount: action.payload.verifiedCount,
            },
          };
        }

        case "SET_SENIOR_COUNTY": {
          return {
            ...state,
            seniorStatisticsData: action.payload,
          };
        }
        case "SET_MARKET_COUNTY": {
          return {
            ...state,
            marketStatisticsData: action.payload,
          };
        }
        case "SET_MARKET_STATS": {
          return {
            ...state,
            statsMarket: {
              vendorTotalCount: action.payload.vendorTotalCount,
              unverifiedCount: action.payload.unverifiedCount,
              verifiedCount: action.payload.verifiedCount,
            },
          };
        }
        case "SET_STORE_STATS": {
          return { ...state, statisticsData: action.payload.statisticsData };
        }
        default:
          return state;
      }
    };
  
    export default adminDashboardReducer;