import React from 'react'
import products1 from '../../assets/images/products1.png'
import products2 from "../../assets/images/products2.png";
import products3 from "../../assets/images/products3.png";
import products4 from "../../assets/images/products4.png";
import products5 from "../../assets/images/products5.png";
import products6 from "../../assets/images/products6.png";

const FarmProducts = () => {
  return (
    <div className="products_container">
      <div className="products_heading">
        <h1>Agro Ecological Farm Products</h1>
        <p>
          Agro Ecological Farm Products offer environmentally friendly options
          for consumers seeking sustainable and responsibly sourced goods.
        </p>
      </div>
      <div className="products_card_container">
        <div className="product_card">
          <div className="product_content">
            <h6>Fresh Vegetables</h6>
            <p>
              Fresh vegetables offer a burst of flavor and vitality at the
              farmers market.
            </p>
          </div>
          <div className="product_image">
            <img src={products1} />
          </div>
        </div>
        <div className="product_card">
          <div className="product_content">
            <h6>Fresh Fruits</h6>
            <p>
              Fresh fruits are natures sweet delights, brimming with vitamins
              and bursting with juicy flavor.
            </p>
          </div>
          <div className="product_image">
            <img src={products2} />
          </div>
        </div>
        <div className="product_card">
          <div className="product_content">
            <h6>Fresh Greens</h6>
            <p>
              Fresh greens add a nutritious touch to any meal, boosting flavor
              and health.
            </p>
          </div>
          <div className="product_image">
            <img src={products3} />
          </div>
        </div>
        <div className="product_card">
          <div className="product_content">
            <h6>Fresh Peppers</h6>
            <p>
              Fresh peppers add vibrant color and fiery flavor to dishes,
              enhancing taste and appeal.
            </p>
          </div>
          <div className="product_image">
            <img src={products4} />
          </div>
        </div>
        <div className="product_card">
          <div className="product_content">
            <h6>Fresh Berries</h6>
            <p>
              Fresh berries are juicy and sweet, perfect for breakfast or
              dessert.
            </p>
          </div>
          <div className="product_image">
            <img src={products5} />
          </div>
        </div>
        <div className="product_card">
          <div className="product_content">
            <h6>Natural Honey</h6>
            <p>Natural honey is pure sweetness, straight from the hive.</p>
          </div>
          <div className="product_image">
            <img src={products6} />
          </div>
        </div>
      </div>
      <div className="products_seemore_btn">
        <button>
          See More <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  );
}

export default FarmProducts