import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import CancelFormComponent from "./cancel";
import PropTypes from "prop-types";
import fruitsVeg from "../../assets/images/fruitsVegBasket.jpg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CsaLandingComponent() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [reset, setReset] = useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setShowForm(true);
    setReset(!reset);
  };
  const CenteredDialogTitle = ({ children }) => {
    const styles = {
      textAlign: "center",
      paddingBottom: 0,
    };
    const borderStyles = {
      borderBottom: "2px solid #388557",
      display: "inline-block",
      paddingBottom: "5px",
      fontSize: 20.8,
      // opacity:0.5
    };

    return (
      <DialogTitle style={styles}>
        {" "}
        <span style={borderStyles}>{children}</span>{" "}
      </DialogTitle>
    );
  };

  CenteredDialogTitle.propTypes = {
    children: PropTypes.array,
  };

  return (
    <>
      <Grid
        container
        sx={{
          backgroundImage: `url(${fruitsVeg})`,
          minHeight: "450px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.121)",
          backgroundPosition: "center",
          flex: 1,
        }}
      >
        <Grid sx={{ height: "230px" }}>
          <Box
            sx={{
              width: 600,
              color: "white",
              border: 1,
              borderColor: "white",
              background: "rgb(0 0 0 / 60%)",
            }}
          >
            <h4 style={{ padding: "20px", fontSize: "20px", marginBottom: 0 }}>
              Community Supported Agriculture (CSA) programs provide SNAP
              customers with fresh, nutritious fruits and vegetables while
              supporting local farmers. DTA works together with local farmers to
              offer SNAP customers an easy way to pay for fruits and vegetables
              automatically from your SNAP account each month on the day you get
              your benefits.
            </h4>
          </Box>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{ paddingRight: 2, paddingLeft: 2, paddingBottom: 2, mt: -20 }}
        >
          {/* <Button sx={{ backgroundColor: 'white', textTransform: 'none', color: 'black', width: '12%', marginBottom: 1, "&:hover": { backgroundColor: '#006E52', color: '#ffffff' } }} onClick={() => navigate('/kfresh/csa-signup')} size='medium'>
              Enroll in a CSA Share
              </Button> */}
          <Button
            onClick={handleClickOpen}
            sx={{
              backgroundColor: "white",
              textTransform: "capitalize",
              color: "black",
              width: "12%",
              marginBottom: 1,
              "&:hover": { backgroundColor: "#006E52", color: "#ffffff" },
            }}
          >
            Cancel your CSA share
          </Button>
          {/* <Button sx={{ backgroundColor: 'white', textTransform: 'capitalize', color: 'black', width: '12%', "&:hover": { backgroundColor: '#006E52', color: '#ffffff' } }} 
                onClick={() => window.open('https://www.mass.gov/service-details/how-to-participate-in-the-snap-csa-pilot', '_blank')}>
              Learn More
              </Button> */}
        </Grid>
      </Grid>
      <Dialog
        fullWidth="lg"
        maxWidth="lg"
        style={{ zIndex: "999" }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        onClose={handleClose}
        PaperProps={{ style: { top: 0 } }}
      >
        <DialogTitle>
          <CenteredDialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            CANCEL CSA SHARE AGREEMENT
          </CenteredDialogTitle>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <CancelFormComponent
              showForm={showForm}
              setShowForm={setShowForm}
              setReset={setReset}
              reset={reset}
            />
            {showForm && (
              <Box sx={{ flexGrow: 1 }}>
                <p className="mb-0 green-color">
                  If you have any question, Please call DTA assistance line
                </p>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
