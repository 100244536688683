const initState = {
    vendorShareList: {
        list: [],
        total: 0,
        loading: true,
        error: false
    },

    csaVendorList: {
        csaVendorList: [],
        loading: true,
        isError: false
    },
    csaVendorInfo: {
        csaVendorInfo: {},
        loading: true,
        isError: false
    },
    clientInfo: {
        clientInfo: {},
        loading: true,
        isError: false
    },
    shareList: {
        shareList: [],
        loading: true,
        isError: false
    },
    requestHelp: {
        requestHelp: [],
        isLoading: false,
        isError: false
    },

    snapShare: {
        snapShare: [],
        isLoading: false,
        isError: false
    },

    //RE
    getUpdateList: {
        getUpdateList: [],
        isLoading: false,
        isError: false
    },
    getAddUserList: {
        getAddUserList: [],
        isLoading: false,
        isError: false
    },

    getDocumentList: {
        getDocumentList: [],
        isLoading: false,
        isError: false
    },

    snapShareHipAmount: {
        snapShareHipAmount: [],
        isLoading: false,
        isError: false
    },
    manageVendorList: {
        list: [],
        total: 0,
        loading: true,
        error: false
    },
    clientAgreementList: {
        list: [],
        total: 0,
        loading: true,
        error: false
    },
    pickupLocationList: {
        list: [],
        total: 0,
        loading: true,
        error: false
    },
    clientPartialSignupList: {
        list: [],
        total: 0,
        loading: true,
        error: false
    },
    clientPaymentList: {
        list: [],
        total: 0,
        loading: true,
        error: false
    },
    clientPickupLocationList: {
        clientPickupLocationList: [],
        isLoading: false,
        isError: false
    },
    csaSignupStatusList: {
        list: [],
        total: 0,
        loading: true,
        error: false
    },
    snapCsaLocationList: {
        list: [],
        total: 0,
        loading: true,
        error: false
    },
    allSnapCsaLocationList: {
        list: [],
        total: 0,
        loading: true,
        error: false
    },
    cancelAgreementList: {
        list: [],
        total: 0,
        loading: true,
        error: false
    },
    submitSignup: {
        submitSignup: {},
        loading: true,
        isError: false
    },
    csaDocument: {
        csaDocument: {},
        isLoading: false,
        isError: false
    },
    agrmntShareList: {
        agrmntShareList: [],
        isLoading: false,
        isError: false
    },
    vendorSubUserList: {
        list: [],
        total: 0,
        loading: true,
        error: false
    },
    eSign: {},
    saveCSASignup: {},
    isLoading: false,
    isError: false,
    exportClientPaymentList: [],
    exportClientAgreement: [],
    refreshKey: 0,
};

const CSASignupReducer = (state = initState, action = {}) => {
    const newState = { ...state };
    switch (action.type) {
        case "SET_SIGNATURE":
            {
                return { ...state, eSign: { ...action.payload } };
            }
        case "SET_CSA_DOC":
            return {
                ...newState,
                csaDocument: { csaDocument: action.payload, isLoading: false, isError: false }
            };
        case "SET_SUBMIT_SIGNUP":
            return {
                ...newState,
                submitSignup: { submitSignup: action.payload, loading: false, isError: false }
            };
        case "SET_SAVE_CSASIGNUP":
            {
                return { ...state, saveCSASignup: { ...action.payload } };
            }
        // RE
        case "SET_UPDATE":
            return {
                ...newState,
                getUpdateList: { getUpdateList: [...action.payload], isLoading: false, isError: false }
            };

        case "SET_ADD_USER_LIST":
            return {
                ...newState,
                getAddUserList: { getAddUserList: action.payload, isLoading: false, isError: false }
            };

        case "SET_DOUCMENT":
            return {
                ...newState,
                getDocumentList: { getDocumentList: [...action.payload], isLoading: false, isError: false }
            };

        case "SET_CSA_VENDOR_LIST":
            return {
                ...newState,
                csaVendorList: { csaVendorList: [...action.payload], loading: false, isError: false }
            };
        case "SET_CSA_VENDOR_INFO":
            return {
                ...newState,
                csaVendorInfo: { csaVendorInfo: action.payload, loading: false, isError: false }
            };
        case "SET_CLIENT_INFO":
            return {
                ...newState,
                clientInfo: { clientInfo: action.payload, loading: false, isError: false }
            };
        case "SET_CSA_SHARE_LIST":
            return {
                ...newState,
                shareList: { shareList: action.payload, loading: false, isError: false }
            };
        case "SET_CLIENT_AGRMNT_SHR_LST":
            return {
                ...newState,
                agrmntShareList: { agrmntShareList: action.payload, isLoading: false, isError: false }
            };
        case "SET_SNAP_SHARE":
            return {
                ...newState,
                snapShare: { snapShare: action.payload, isLoading: false, isError: false }
            };

        case "SET_REQUEST_HELP":
            return {
                ...newState,
                requestHelp: { requestHelp: action.payload, isLoading: false, isError: false }
            };
        case "SET_SNAP_HIP_AMOUNT":
            return {
                ...newState,
                snapShareHipAmount: { snapShareHipAmount: action.payload, isLoading: false, isError: false }
            };
        case "SET_CANCEL_AGREEMENT_LIST":
            return {
                ...newState,
                cancelAgreementList: { list: action.payload.data || [], total: action.payload.total || 0, loading: false, error: false }
            };

        case "SET_VENDOR_SHARE_LIST":
            return {
                ...newState,
                vendorShareList: { list: action.payload.data || [], total: action.payload.total || 0, loading: false, error: false }
            };
        case "SET_MANAGE_CSA_VENDOR_LIST":
            return {
                ...newState,
                manageVendorList: { list: action.payload.data || [], total: action.payload.total || 0, loading: false, error: false }
            };
        case "SET_CLIENT_PICKUP_LOCATION_LIST":
            return {
                ...newState,
                clientPickupLocationList: { clientPickupLocationList: action.payload, isLoading: false, isError: false }
            };

        case "SET_CLIENT_PAYMENT_LIST":
            return {
                ...newState,
                clientPaymentList: { list: action.payload.data || [], total: action.payload.total || 0, loading: false, error: false }
            };
        case "SET_EXPORT_CLIENT_PAYMENTLIST":
            return {
                ...newState,
                exportClientPaymentList: { exportClientPaymentList: action.payload }
            };
        case "SET_EXPORT_CLIENT_AGREEMENT":
            return {
                ...newState,
                exportClientAgreement: { exportClientAgreement: action.payload }
            };
        case "SET_CLIENT_AGREEMENT_LIST":
            return {
                ...newState,
                clientAgreementList: { list: action.payload.data || [], total: action.payload.total || 0, loading: false, error: false }
            };
        case "SET_PICKUP_LOCTION_LIST":
            return {
                ...newState,
                pickupLocationList: { list: action.payload.data || [], total: action.payload.total || 0, loading: false, error: false }
            };
        case "SET_CLIENT_PARTIAL_SIGNUP_LIST":
            return {
                ...newState,
                clientPartialSignupList: { list: action.payload.data || [], total: action.payload.total || 0, loading: false, error: false }
            };

        case "SET_CSASIGNUP_STATUS_LIST":
            return {
                ...newState,
                csaSignupStatusList: { list: action.payload.data || [], total: action.payload.total || 0, loading: false, error: false }
            };

        case "SET_SNAP_CSA_LOCATION_LIST":
            return {
                ...newState,
                snapCsaLocationList: { list: action.payload.data || [], total: action.payload.total || 0, loading: false, error: false }
            };

        case "SET_ALL_SNAP_CSA_LOCATION_LIST":
            return {
                ...newState,
                allSnapCsaLocationList: { list: action.payload.data || [], total: action.payload.total || 0, loading: false, error: false }
            };

        case "SET_VENDOR_SUB_USER_LIST":
            return {
                    ...newState,
                    vendorSubUserList: { list: action.payload.data || [], total: action.payload.total || 0, loading: false, error: false }
        };
        case "REFRESH_KEY":
            return {
                ...newState,
                refreshKey: newState.refreshKey + 1
            };

        default:
            return newState;
    }
};
export default CSASignupReducer;