import Swal from "sweetalert2";
import moment from "moment";
import momentTz from "moment-timezone";
import * as XLSX from "xlsx";
import { setKey, setLanguage, fromAddress } from "react-geocode";
import {
  msalConfig,
  loginRequest,
  GOOGLE_KEY,
  postLogoutRedirectUri,
} from "../config";
import { PublicClientApplication } from "@azure/msal-browser";

setKey(GOOGLE_KEY);
setLanguage("en");

export const sortName = (Item, key = "values") => {
  return Item.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0));
};

export const sortNum = (Item) => {
  Item.sort((a, b) => a.values - b.values);
};

export const popUpAlert = (props) => {
  Swal.fire({
    width: props.width,
    title: props.title,
    text: props.text,
    icon: props.icon,
    color: props.color,
    html: props.html,
    showCancelButton: props.showCancelButton,
    confirmButtonClass: props.confirmButtonClass,
    confirmButtonText: props.confirmButtonText,
    cancelButtonColor: props.cancelButtonColor,
    closeOnConfirm: props.closeOnConfirm,
    cancelButtonText: props.cancelButtonText
      ? props.cancelButtonText
      : "Cancel",
    timer: props.timer,
    showConfirmButton: props.showConfirmButton,
    handleDismiss: props.handleDismiss,
  }).then((result) => {
    if (result.isConfirmed && props.onConfirmed) {
      props.handleConfirm();
    }
    if (result.isDismissed && props.handleDismiss) {
      props.handleDismiss();
    }
  });
};

export const maskMobile = (value) => {
  return (
    value &&
    value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "($1) $2")
      .replace(/(\d{3})(\d)/, "$1-$2")
      .replace(/(-\d{4})(\d+?)$/, "$1")
  );
};

export const geocode = (address) => {
  let loc = [];

  fromAddress(address).then(
    (response) => {
      console.log(response);
      // if (status == "OK") {
      //     loc[0]=results[0].geometry.location.lat();
      //     loc[1]=results[0].geometry.location.lng();
      // the place where loc contains geocoded coordinates
      return loc;
      // } else {
      //     return loc;
      //   console.log("Geocode was not successful for the following reason: " + status);
      // }
    },
    (error) => {
      console.error(error);
    }
  );
  console.log(loc);

  return loc;
};

export const extractExcel = (file, cityList, callback) => {
  const reader = new FileReader();
  reader.onload = async (e) => {
    let data = new Uint8Array(e.target.result);
    let workbook = XLSX.read(data, { type: "array" });

    let results = [];
    workbook.SheetNames.forEach(function (sheetName) {
      let roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
      });
      roa.shift();
      if (roa.length) results.push(...roa);
    });

    let farmersMarketList = [];
    for (let index = 0; index < results.length; index++) {
      const val = results[index];
      if (
        typeof val[0] != "undefined" &&
        val[0] != "" &&
        typeof val[1] != "undefined" &&
        val[1] != "" &&
        typeof val[2] != "undefined" &&
        val[2] != "" &&
        typeof val[3] != "undefined" &&
        val[3] != "" &&
        typeof val[4] != "undefined" &&
        val[4] != "" &&
        typeof val[5] != "undefined" &&
        val[5] != ""
      ) {
        let latlng = await geocode(
          val[2] + " " + val[3] + " " + val[4] + val[5]
        );
        if (latlng.length == 0) {
          let city = cityList.filter(
            (item) => item.cityName.toLowerCase() == val[3].toLowerCase()
          );
          if (city.length > 0) {
            latlng[0] = city[0]["latitude"];
            latlng[1] = city[0]["longitude"];
          } else {
            popUpAlert({
              title: "Failed!",
              text: "City name is not matched with Massachusetts state.",
              icon: "error",
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonClass: "btn-danger",
              confirmButtonText: "Ok!",
              closeOnConfirm: false,
            });
          }
        }
        farmersMarketList.push({
          id: val[0],
          name: val[1],
          phone: val[6] ? val[6] : "",
          webAddress: val[7] ? val[7] : "",
          description: val[8] ? val[8] : "",
          addressDetail: {
            addressLine1: val[2],
            addressLine2: "",
            id: val[0],
            city: val[3],
            state: val[4],
            zipcode: val[5],
            latitude: latlng[0],
            longitude: latlng[1],
          },
        });
      } else {
        popUpAlert({
          title: "Failed!",
          text: "File not found. select a file to start upload.",
          icon: "error",
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonClass: "btn-danger",
          confirmButtonText: "Ok!",
          closeOnConfirm: false,
        });
      }
    }
    callback(farmersMarketList);
  };
  reader.readAsArrayBuffer(file);
};

export function formatDTADateForPicker(unFormattedDate) {
  const monthJson = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  const spilttedDate = unFormattedDate.split("-");
  if (spilttedDate.length === 3) {
    const month = monthJson[spilttedDate[0]];
    const day = spilttedDate[1];
    const year = spilttedDate[2];
    return `${month} ${day},${year}`;
  }
  return "-";
}

export const CalculateUTCTzToUserTz = (dtVal) => {
  const dateTime = dtVal;
  if (dateTime) {
    const browserTimezone = moment(dateTime).local().format("MM/DD/YYYY");
    return browserTimezone;
  }
  return "-";
};

export const pageRefresh = () => {
  window.location.reload();
};

export const CalculateUserTzToUTCTz = (dtVal) => {
  const dateTime = moment(dtVal).format("YYYY-MM-DD HH:mm:ss");
  const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userTZ = momentTz.tz(dateTime, browserTz);
  const UTCTz = userTZ.clone().tz("Etc/GMT");
  return UTCTz;
};

export const getNextDate = () => {
  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export function convertUTCDateToLocalDate(date) {
  const momentObj = moment.utc(date);
  const userOffset = moment().utcOffset();
  const localMomentObj = momentObj.utcOffset(userOffset);
  const formattedDateTime = localMomentObj.format("MM/DD/YYYY hh:mm A");
  return formattedDateTime;
}

export function convertUTCDateTo24HLocalDate(date) {
  const momentObj = moment.utc(date);
  const userOffset = moment().utcOffset();
  const localMomentObj = momentObj.utcOffset(userOffset);
  const formattedDateTime = localMomentObj.format("MM/DD/YYYY H:mm");
  return formattedDateTime;
}

export const isNumber = (evt) => {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (
    charCode > 31 &&
    (charCode < 48 || charCode > 57) &&
    !(charCode > 95 && charCode < 106)
  ) {
    evt.preventDefault();
  }
  return true;
};

export const isAlphanumeric = (evt) => {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;

  // Allow numbers (48-57), uppercase letters (65-90), lowercase letters (97-122),
  // control keys (8 for backspace, 46 and 190 for dot, 37-40 for arrow keys)
  if (
    (charCode >= 48 && charCode <= 57) ||
    (charCode >= 65 && charCode <= 90) ||
    (charCode >= 97 && charCode <= 122) ||
    charCode === 8 || // Backspace
    charCode === 46 ||
    charCode === 190 || // Dot (.) or 190
    charCode === 37 || // Left Arrow
    charCode === 38 || // Up Arrow
    charCode === 39 || // Right Arrow
    charCode === 40 // Down Arrow
  ) {
    return true;
  } else {
    evt.preventDefault();
    return false;
  }
};

export const checkToken = () => {
  return true;
  // const token = sessionStorage.getItem("access_token");
  // return token ? true : false;
};

export const logout = () => {
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("refresh_token");
  sessionStorage.removeItem("username");
  sessionStorage.removeItem("userData");
};

export const CalculateDaysBwTwoDates = (startDt, endDt) => {
  const date1 = new Date(startDt); //mm/dd/yyyy
  const date2 = new Date(endDt); //mm/dd/yyyy
  // To calculate the time difference of two dates
  const Difference_In_Time = date2.getTime() - date1.getTime();
  // To calculate the no. of days between two dates
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days;
};

export const getYesterdaysDate = () => {
  var date = new Date();
  date.setDate(date.getDate() - 1);
  return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
};

export const uuidv4 = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};
export const isBase64String = (base64) => {
  if (base64) {
    const base64regex = /^(?:[data]{4}:(image)\/[a-z]*)/;
    if (base64.match(base64regex)) return true;
    else return false;
  }
  return false;
};

export const twoDigitdecNum = (num) => {
  return isNaN(num) ? "0.00" : Number(parseFloat(num)).toFixed(2);
};

export const azureLogOut = (instance) => {
  logout();
  if (sessionStorage.getItem("accountInfo")) {
    const currentAccount = instance.getAccountByHomeId(
      JSON.parse(sessionStorage.getItem("accountInfo")).homeAccountId
    );
    instance.logoutRedirect({
      account: currentAccount,
      postLogoutRedirectUri: postLogoutRedirectUri.redirectUri,
      authority: msalConfig.auth.authority,
    });
  } else {
    instance.logoutRedirect({
      postLogoutRedirectUri: postLogoutRedirectUri.redirectUri,
      authority: msalConfig.auth.authority,
    });
  }
};

export const azureLogin = (instance) => {
  instance
    .loginRedirect(loginRequest)
    .then()
    .catch((e) => {
      console.error(e);
    });
};

export const getRefreshToken = () => {
  return new Promise((resolve) => {
    if (sessionStorage.getItem("oauthToken") != null) {
      try {
        const msalInstance = new PublicClientApplication(msalConfig);
        const currentAccount = JSON.parse(
          window.sessionStorage.getItem("accountInfo")
        );
        const silentRequest = {
          scopes: loginRequest.scopes,
          account: currentAccount,
        };
        msalInstance
          .ssoSilent(silentRequest)
          .then((response) => {
            const oauth = {
              token_type: "Bearer",
              access_token: response.idToken,
            };
            window.sessionStorage.setItem("oauthToken", JSON.stringify(oauth));
            resolve(oauth);
          })
          .catch((err) => {
            console.log(err);
            msalInstance.logoutRedirect();
          });
      } catch (err) {
        console.log("Refresh token error", err);
      }
    }
  });
};

export const removeSpecialCharacter = (obj) => {
  if (obj) {
    const vendorName = obj.replace(/&#38;/g, "'");
    return vendorName;
  }
};

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return "-";
  }

  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return phoneNumber;
};

export const validateEmail = (value) => {
  if (
    value.toLowerCase().endsWith("@state.ma.us") ||
    value.toLowerCase().endsWith("@mass.gov")
  ) {
    return "Emails from this domain are not allowed.";
  }
  return true;
};
