import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../../redux/reducers";
import moment from "moment";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useForm, useFieldArray } from "react-hook-form";
import { Row, Col, Form } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SelectDropdown from "../../common/selectdropdown-component";
import "../../../styles/home.scss";
import DatePickerViews from "../../common/components/datePicker";
import TimePickerViews from "../../common/components/timePicker";
import * as actionTypes from "../../../redux/actionTypes/csaSignup";

const ShareAddEditComponent = (props) => {
  const { submitShareData, onEdit, row, vendorId, getSnapCsaLocationList } =
    props;
  const [addressList, setAddressList] = useState([]);
  const [daysList, setDaysList] = useState([]);
  const [selectedDay, setSelectedDay] = useState([]);
  const [units, setUnits] = useState(
    onEdit && row.shareQtyUnit && row.shareQtyUnit == "lbs" ? "Pounds" : "Units"
  );
  const [startDate, setStartDate] = useState(
    (onEdit && moment(row.startDate, "YYYY-MM-DD")) ||
      moment().add(1, "M").startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    (onEdit && moment(row.endDate, "YYYY-MM-DD")) ||
      moment().add(2, "M").startOf("month").format("YYYY-MM-DD")
  );
  const [selectedAddress, setSelectedAddress] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [currentSelection, setCurrentSelection] = useState({});
  const [deliveryLocation, setDeliveryLocation] = useState(
    (onEdit && row.dlvryFlg) || "Y"
  );

  let initial = onEdit
    ? {
        nam: row.nam,
        startDate: row.startDate,
        endDate: row.endDate,
        shareCost: row.shareCost,
        pickupDetails: row.pickupDetails,
        shareQty: row.shareQty,
        shareQtyUnit: row.shareQtyUnit,
        dlvryFlg: deliveryLocation,
        range: row.range,
        dsc: row.dsc,
        id: row.id,
      }
    : {
        nam: "",
        startDate: startDate,
        endDate: endDate,
        shareCost: "",
        pickupDetails: [
          {
            address: "",
            day: "",
            openTime: "12:00 AM",
            closeTime: "12:00 AM",
          },
        ],
        dlvryFlg: deliveryLocation,
        shareQty: "",
        range: "",
        dsc: "",
      };
  const [formValue] = useState(initial);

  useEffect(() => {
    const payload = {
      test: "2",
      vendorId: vendorId,
      from: 0,
      size: 1000,
      all: true,
    };
    getSnapCsaLocationList(payload);
  }, []);

  useEffect(() => {
    const { list } = props.csaSignup.allSnapCsaLocationList;
    let data = [];
    list.forEach((ele) => {
      let address = ele.addressLine1 ? ele.addressLine1 + ", " : "";
      address += ele.addressLine2 ? ele.addressLine2 + ", " : "";
      address += ele.city ? ele.city + ", " : "";
      address += ele.state ? ele.state + ", " : "";
      address += ele.zipcode ? ele.zipcode : "";
      data.push({
        name: ele.addressId,
        vendorId: vendorId,
        values: address,
        isActive: 1,
      });
    });
    setAddressList(data);
  }, [props.csaSignup.allSnapCsaLocationList]);

  useEffect(() => {
    const list = props.mapdata.days;
    let data = [];
    list.forEach((ele) => {
      data.push({ name: ele.weekDay, values: ele.values, isActive: 1 });
    });
    setDaysList(data);
  }, [props.mapdata.days]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: formValue,
  });

  useEffect(() => {
    if (onEdit) {
      let addressData = [];
      let daysData = [];
      let constDayList = props.mapdata.days;
      row.pickupDetails.map((ele) => {
        let address = ele.address1 ? ele.address1 + ", " : "";
        address += ele.address2 ? ele.address2 + ", " : "";
        address += ele.city ? ele.city + ", " : "";
        address += ele.state ? ele.state + ", " : "";
        address += ele.zipcode ? ele.zipcode : "";
        addressData.push({ name: ele.addressId, values: address });
        daysData.push({
          name: ele.day,
          values: constDayList.filter((item) => item.weekDay == ele.day)[0]
            .values,
        });
      });
      setSelectedAddress(addressData);
      setSelectedDay(daysData);
    }
  }, [row, props.mapdata.days]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "pickupDetails",
  });

  const onSubmit = (data) => {
    if (Object.keys(errors).length === 0) {
      let payload = Object.assign(data, {
        vendorId: vendorId,
        contactInfo: "",
        shareQtyUnit: units == "Units" ? "qty" : "lbs",
        vendorType: "CSAVDR",
        dlvryFlg: deliveryLocation,
      });
      if (onEdit) {
        payload.isStatusUpdate = "N";
        payload.pickupFlg = "Y";
      }
      submitShareData(payload);
    }
  };

  return (
    <div className="form-block addShareInfo">
      <Form noValidate onSubmit={handleSubmit(onSubmit)} auto>
        <Row>
          <Col>
            {Object.keys(errors).length > 0 &&
              Object.keys(errors)[0] != "pickupDetails" && (
                <Form.Text className="dh-txt-danger">
                  <b>
                    <i
                      className="fa fa fa-info-circle ml-2"
                      aria-hidden="true"
                    ></i>{" "}
                    {errors[Object.keys(errors)[0]].message}{" "}
                  </b>
                </Form.Text>
              )}
            <Form.Group as={Col}>
              <Form.Label className="smallTitle">Share Name *</Form.Label>
              <Form.Control
                type="text"
                {...register("nam", { required: "Please enter Share Name" })}
              />
            </Form.Group>
            <Row className={"mb-2"}>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label className="smallTitle">
                    Start Duration *
                  </Form.Label>
                  <DatePickerViews
                    handleChange={(newValue) => {
                      setValue("startDate", newValue.format("YYYY-MM-DD"));
                      setStartDate(newValue.format("YYYY-MM-DD"));
                    }}
                    value={startDate}
                    {...register("startDate", {
                      required: "Please enter Start Date",
                    })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label className="smallTitle">End Duration *</Form.Label>
                  <DatePickerViews
                    handleChange={(newValue) => {
                      setValue("endDate", newValue.format("YYYY-MM-DD"));
                      setEndDate(newValue.format("YYYY-MM-DD"));
                    }}
                    value={endDate}
                    {...register("endDate", {
                      required: "Please enter End Date",
                    })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col} controlId="formUserFirstName">
                  <Form.Label className="smallTitle">Montly Cost *</Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="btnGroupAddon">
                      <i className="fa fa-usd"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      {...register("shareCost", {
                        required: "Please enter Montly Cost",
                      })}
                      placeholder=" "
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            {fields.map((item, index) => {
              return (
                <Card key={item.id} className="mb-1">
                  <Card.Body>
                    <Row>
                      <Col xs={5}>
                        <Form.Group as={Col} controlId="formUserFirstName">
                          <Form.Label className="smallTitle">
                            Pickup Location *
                          </Form.Label>
                          <SelectDropdown
                            id="addShareInfo"
                            {...register(`pickupDetails.${index}.addressId`, {
                              required: "Please select Address",
                            })}
                            value={selectedAddress[index]}
                            onChange={(v) => {
                              setSelectedAddress((current) => {
                                current[index] = v;
                                return current;
                              });
                              setCurrentSelection({ [index]: v });
                              setValue(
                                `pickupDetails.${index}.addressId`,
                                v.name
                              );
                              setValue(
                                `pickupDetails.${index}.address`,
                                v.values
                              );
                            }}
                            options={addressList}
                            isSearch={true}
                            defaultValueProps={{
                              default: true,
                              defaultOption: {
                                name: "",
                                values: "-- Select --",
                              },
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={5}>
                        <Form.Group as={Col} controlId="formUserFirstName">
                          <Form.Label className="smallTitle">
                            Pickup Days *
                          </Form.Label>
                          {/* <Form.Control type="text" {...register("nam", { required: "Please enter Share Name"})}/> */}
                          <SelectDropdown
                            {...register(`pickupDetails.${index}.day`, {
                              required: "Please select Day",
                            })}
                            value={selectedDay[index]}
                            onChange={(v) => {
                              setSelectedDay((current) => {
                                current[index] = v;
                                return current;
                              });
                              setCurrentSelection({ [index]: v });
                              setValue(`pickupDetails.${index}.day`, v.name);
                            }}
                            options={daysList}
                            isSearch={true}
                            defaultValueProps={{
                              default: true,
                              defaultOption: {
                                name: "",
                                values: "-- Select --",
                              },
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <Form.Group as={Col} controlId="formUserFirstName">
                          <Form.Label className="smallTitle">
                            Opens *
                          </Form.Label>
                          <TimePickerViews
                            {...register(`pickupDetails.${index}.openTime`, {
                              required: "Please select Opening Time",
                            })}
                            handleChange={(newValue) => {
                              setValue(
                                `pickupDetails.${index}.openTime`,
                                newValue.format("hh:mm A")
                              );
                            }}
                            value={moment(
                              getValues(`pickupDetails.${index}.openTime`),
                              "hh:mm A"
                            ).format("YYYY-MM-DDTHH:mm")}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={5}>
                        <Form.Group as={Col} controlId="formUserFirstName">
                          <Form.Label className="smallTitle">
                            Closes *
                          </Form.Label>
                          <TimePickerViews
                            {...register(`pickupDetails.${index}.closeTime`, {
                              required: "Please select Closing Time",
                            })}
                            handleChange={(newValue) => {
                              setValue(
                                `pickupDetails.${index}.closeTime`,
                                newValue.format("hh:mm A")
                              );
                            }}
                            value={moment(
                              getValues(`pickupDetails.${index}.closeTime`),
                              "hh:mm A"
                            ).format("YYYY-MM-DDTHH:mm")}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={2}>
                        <IconButton
                          aria-label="ADD"
                          onClick={() =>
                            append({
                              address: "",
                              day: "",
                              openTime: "",
                              closeTime: "",
                            })
                          }
                        >
                          <AddIcon />
                        </IconButton>
                        {index != 0 && (
                          <IconButton
                            aria-label="DELETE"
                            onClick={() => {
                              setSelectedDay((current) => {
                                current.splice(index, 1);
                                return current;
                              });
                              setSelectedAddress((current) => {
                                current.splice(index, 1);
                                return current;
                              });
                              remove(index);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                  {Object.keys(errors).length > 0 &&
                    "pickupDetails" in errors &&
                    index in errors["pickupDetails"] && (
                      <Card.Footer className="text-muted">
                        <Form.Text className="dh-txt-danger">
                          <b>
                            <i
                              className="fa fa fa-info-circle ml-2"
                              aria-hidden="true"
                            ></i>{" "}
                            Please select all fields in Pickup Details.{" "}
                          </b>
                        </Form.Text>
                      </Card.Footer>
                    )}
                </Card>
              );
            })}
            <Row>
              <Col>
                <Form.Group as={Col} controlId="formUserDelivery">
                  <Form.Label className="smallTitle">
                    Delivery Location *
                  </Form.Label>
                  <Col key={`inline-radio`}>
                    {/* <Form.Control type="text" {...register("nam", { required: "Please enter Share Name"})}/> */}
                    <Form.Check
                      inline
                      label="Yes"
                      value="Y"
                      {...register("dlvryFlg")}
                      checked={deliveryLocation === "Y"}
                      name="group1"
                      type={"radio"}
                      id={`inline-radio-1`}
                      onChange={(e) => setDeliveryLocation(e.target.value)}
                    />
                    <Form.Check
                      inline
                      label="No"
                      value="N"
                      {...register("dlvryFlg")}
                      checked={deliveryLocation === "N"}
                      name="group1"
                      type={`radio`}
                      id={`inline-radio-2`}
                      onChange={(e) => setDeliveryLocation(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col} controlId="formUserFirstName">
                  <Form.Label className="smallTitle">
                    Weight/Amount of Goods *
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      {...register("shareQty", {
                        required: "Please enter Weight/Amount of goods",
                      })}
                    />
                    <DropdownButton
                      variant="outline-secondary"
                      title={units}
                      id="input-group-dropdown-2"
                      align="end"
                      onSelect={(v) => {
                        setUnits(v == "qty" ? "Units" : "Pounds");
                        setValue("shareQtyUnit", v);
                      }}
                    >
                      <Dropdown.Item eventKey="qty">Units</Dropdown.Item>
                      <Dropdown.Item eventKey="lbs">Pounds</Dropdown.Item>
                    </DropdownButton>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            {deliveryLocation == "Y" && (
              <Row>
                <Col>
                  <Form.Group as={Col}>
                    <Form.Label className="smallTitle">
                      Delivery Range *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register("range", {
                        validate: {
                          required: (value) =>
                            (deliveryLocation == "Y" && value.length != 0) ||
                            "Please enter Delivery Range",
                        },
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Form.Group as={Col} controlId="formDescription">
              <Form.Label className="smallTitle">Description *</Form.Label>
              <Form.Control
                as="textarea"
                placeholder=""
                style={{ height: "114px" }}
                {...register("dsc", { required: "Please enter Description" })}
              />
              <Form.Text id="formDescription" className="float-right" muted>
                <i className="fa fa fa-info-circle ml-2" aria-hidden="true"></i>{" "}
                Max character: 3000
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        {onEdit && <Form.Control type="text" value={row.id} hidden />}
        <Row className="modal-footer">
          <Col>
            <Button variant="primary" type="submit" className="float-right">
              {onEdit ? "Update" : "Add"}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
ShareAddEditComponent.propTypes = {
  validated: PropTypes.bool,
  submitShareData: PropTypes.func,
  mapdata: PropTypes.object,
  onEdit: PropTypes.bool,
  row: PropTypes.object,
  getMyStoreDetails: PropTypes.func.isRequired,
  vendorId: PropTypes.number,
  csaSignup: PropTypes.object.isRequired,
  getSnapCsaLocationList: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSnapCsaLocationList: (payload) =>
      dispatch(actionTypes.getSnapCsaLocationList(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareAddEditComponent);
