//Global Routes
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Header from "./pages/common/header/Header";
import Footer from "./pages/common/footer/Footer";
import DashboardComponent from "./pages/dashboard/dashboard-component";
import HomeComponent from "./pages/home";
import AdminComponent from "./pages/admin";
import VendorComponent from "./pages/vendor";
import FarmerMarkerComponent from "./pages/manage/farmerMarket";
import UsersComponent from "./pages/manage/users";
import AuditLogsComponent from "./pages/manage/auditLogs";
import CoordinatesComponent from "./pages/manage/coordinates";
import UserRegistration from "./pages/user/signup/signup-component";
import Mystore from "./pages/mystore/mystore";
import GreenBanner from "./pages/home/GreenBanner";
import BlueBanner from "./pages/home/BlueBanner";

//CSA

import CsaLandingPage from "./pages/csa/csa-landing-component";
import CSASignupWrapper from "./pages/csa/signup/csa-signup-wrapper";
import CsaSignupStatus from "./pages/manage/csaSignupStatus";
import PartialSignup from "./pages/manage/partialSignup";
import ClientAgreement from "./pages/csa/signup/client-agreement";
import PaymentsComponent from "./pages/manage/Payments";
import FresherLogin from "./pages/fresherlogin";
import AdminLogin from "./pages/adminLogin";

// Senior Pages

import ApplyBenefits from "./pages/senior/applyBenefits";
import DashboardContainer from "./pages/senior/dashboardContainer";
import YourInformation from "./pages/senior/yourInformation";
import ContactInformation from "./pages/senior/contactInformation";
import ProgramIncome from "./pages/senior/programIncome";
import EligibilityStatus from "./pages/senior/eligibility";
import Layout from "./pages/senior/layout";
import Certification from "./pages/senior/certification";
import AdminContainer from "./pages/admin/AdminContainer";
import DocumentUpload from "./pages/senior/DocumentUpload";
import SeniorTransaction from "./pages/senior/seniorTransaction";
import AdminTransaction from "./pages/admin/transaction";
import SeniorRequests from "./pages/admin/SeniorWaitingList";
import FarmerContainer from "./pages/vendor/FarmerContainer";
import AccessRights from "./pages/admin/AccessRights";
import DocumentUploaded from "./pages/admin/documentUploaded";
import EligibilityCriteria from "./pages/admin/EligibilityCriteria";
import ResetPassword from "./pages/admin/ResetPassword";
import FarmerResetPassword from "./pages/farmer/ResetPassword";
import MarketRequests from "./pages/admin/MarketWaitingList";

// Market Pages
import MarketLayout from "./pages/market/layout";
import Registration from "./pages/fresherlogin/Registration";
import MarketRegistration from "./pages/market/registration";
import PublicInfo from "./pages/market/publicInfo";
import MarketDashboardLayout from "./pages/marketDashboard/layout";
import MarketDashboard from "./pages/market/dashboard";
import MarketDetails from "./pages/admin/MarketDetails";
import EligibilityCriteriaTab from "./pages/admin/EligibilityCriteriaTab";
import Home from "./pages/landingPage";
import PrivateRoutes from "./PrivateRoutes";

export default function RouterComponent() {
  const { pathname } = useLocation();
  const isAdmin = process.env.REACT_APP_USER_ROLE === "admin";

  const navigate = useNavigate();
  let isAuth = sessionStorage.getItem("userLogin");

  let hostname = window.location.hostname;
  const publicRoute = [
    ...(hostname === "localhost" ? ["/admin"] : []),
    "/marketRegistration",
    "/kfreshfep/registration",
    "/publicInfo",
    "/kfreshfep",
  ];

  //  const restrictRoute = [
  //    "/",
  //    "/admin",
  //    "/marketRegistration",
  //    "/publicInfo",
  //    "/kfreshfep/registration",
  //  ];

  useEffect(() => {
    if (isAuth === null) {
      if (isAdmin && pathname !== "/admin") {
        navigate("/admin", { replace: true });
      } else if (!isAdmin && !publicRoute.includes(pathname)) {
        navigate("/", { replace: true });
      }
    }
    // else {
    //   if(restrictRoute.includes(pathname)){
    //     navigate(-1);
    //   }
    // }
  }, [isAdmin, isAuth, navigate, pathname]);

  return (
    <>
      {pathname !== "/" && pathname !== "/admin" && <BlueBanner />}
      <Routes>
        {isAdmin ? (
          <Route path="/" element={<Navigate to="/admin" />} />
        ) : (
          <Route path="/" element={<Home />} />
          // <Route path="/" element={<FresherLogin />} />
        )}
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="kfresh/csa-landing" element={<CsaLandingPage />} />
        <Route path="kfresh/csa-signup" element={<CSASignupWrapper />} />
        <Route exact path="kfreshfep" element={<DashboardComponent />} />
        <Route
          path="kfreshfep/registration"
          element={<UserRegistration mode={"add"} exact />}
        />
        <Route path="" element={<FarmerContainer />}>
          <Route
            path="kfreshfep/mystore/:vdrTyp"
            element={<Mystore allowedRoles={["HIPVDR", "CSAVDR"]} />}
          />

          <Route
            path="farmer-transactions"
            element={<SeniorTransaction allowedRoles={["HIPVDR", "CSAVDR"]} />}
          />
          <Route
            path="farmer-document"
            element={<DocumentUpload allowedRoles={["HIPVDR", "CSAVDR"]} />}
          />
          <Route
            path="farmer-reset-password"
            element={<FarmerResetPassword role="farmer" />}
          />
        </Route>
        <Route path="" element={<AdminContainer />}>
          <Route
            path="kfreshfep/vendors/:vdrTyp"
            element={<Mystore allowedRoles={["ADMIN", "HIPCRD"]} />}
          />
          <Route
            path="kfreshfep/markets-co/:id"
            element={<MarketDetails allowedRoles={["ADMIN", "HIPCRD"]} />}
          />
          <Route
            path="kfreshfep/eligibility-criteria"
            element={<EligibilityCriteriaTab />}
          />
          <Route
            path="kfreshfep/senior-requests"
            element={<SeniorRequests allowedRoles={["ADMIN"]} />}
          />
          <Route
            path="kfreshfep/manage-market-coordinator"
            element={<MarketRequests allowedRoles={["ADMIN"]} />}
          />
          <Route
            path="kfreshfep/admin-dashboard"
            element={<AdminComponent allowedRoles={["ADMIN", "HIPCRD"]} />}
          />
          <Route
            path="kfreshfep/manage-vendors"
            element={<VendorComponent allowedRoles={["ADMIN", "HIPCRD"]} />}
          />
          <Route
            path="kfreshfep/manage-users"
            element={<UsersComponent allowedRoles={["ADMIN"]} />}
          />
          <Route
            path="kfreshfep/manage-farmer-markets"
            element={<FarmerMarkerComponent allowedRoles={["ADMIN"]} />}
          />
          <Route
            path="kfreshfep/manage-address"
            element={<CoordinatesComponent allowedRoles={["ADMIN"]} />}
          />
          <Route
            path="kfresh/csa-signup/:agreementId/:clientId/:vendorId"
            element={<CSASignupWrapper allowedRoles={["ADMIN"]} />}
          />
          <Route
            path="kfreshfep/csa-signup-status"
            element={<CsaSignupStatus allowedRoles={["ADMIN"]} />}
          />
          <Route
            path="kfreshfep/partial-signup"
            element={<PartialSignup allowedRoles={["ADMIN"]} />}
          />
          <Route
            path="kfreshfep/audit-logs"
            element={<AuditLogsComponent allowedRoles={["ADMIN"]} />}
          />
          <Route
            path="kfreshfep/manage-payments"
            element={<PaymentsComponent allowedRoles={["ADMIN"]} />}
          />
          <Route
            path="kfreshfep/transactions"
            element={<AdminTransaction allowedRoles={["ADMIN"]} />}
          />
          <Route
            path="kfreshfep/accessRights"
            element={<AccessRights allowedRoles={["ADMIN"]} />}
          />
          <Route
            path="kfreshfep/documents"
            element={<DocumentUploaded allowedRoles={["ADMIN"]} />}
          />
          <Route
            path="kfreshfep/resetPassword"
            element={<ResetPassword allowedRoles={["ADMIN"]} />}
          />
        </Route>
        <Route
          path="kfresh/client-agreement/:appNumber?"
          element={<ClientAgreement />}
        />
        <Route path="*" element={<Navigate replace to="/" />} />
        <Route path="" element={<Layout />}>
          <Route
            exact
            path="senior/kfreshfep"
            element={<DashboardComponent />}
          />
          <Route path="seniorDashboard" element={<ApplyBenefits />} />
          <Route path="document" element={<DocumentUpload />} />
          <Route
            path="senior-reset-password"
            element={<FarmerResetPassword role="senior" />}
          />
          <Route path="seniorTransaction" element={<SeniorTransaction />} />
          <Route path="" element={<DashboardContainer />}>
            <Route path="yourInfo/:appNumber?" element={<YourInformation />} />
            <Route
              path="contactInfo/:appNumber?/:clientId?"
              element={<ContactInformation />}
            />
            <Route
              path="programIncome/:appNumber?/:clientId?"
              element={<ProgramIncome />}
            />
            <Route
              path="certification/:appNumber?/:clientId?"
              element={<Certification />}
            />
            <Route
              path="eligibilitystatus/:appNumber?/:clientId?"
              element={<EligibilityStatus />}
            />
          </Route>
        </Route>

        <Route path="" element={<MarketLayout />}>
          <Route
            path="marketRegistration"
            element={<MarketRegistration />}
            exact
          />
          <Route path="publicInfo" element={<PublicInfo />} exact />
        </Route>

        <Route path="" element={<MarketDashboardLayout />}>
          <Route path="marketDashboard" element={<MarketDashboard />} />
          <Route path="market-document" element={<DocumentUpload />} />
          <Route
            path="marketCoordinator-reset-password"
            element={<FarmerResetPassword role="farmer" />}
          />
        </Route>
      </Routes>
      {/* <Footer /> */}
    </>
  );
}
