import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const DataGridTable = ({
  pageState,
  setPageState,
  data,
  columns,
  pageSize = 5,
}) => {
  const handlePagination = (pages) => {
    setPageState({
      page: pages.page,
      pageSize: pages.pageSize,
    });
  };

  const generateUniqueID = () => {
    // This function generates a unique ID based on the timestamp
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  };

  const getRowId = (row) => {
    // Here, we generate a unique ID for each row if an 'id' is missing
    if (!row.id) {
      row.id = generateUniqueID();
    }
    return row.id;
  };

  return (
    <Box>
      <DataGrid
        getRowId={getRowId}
        autoHeight
        // rowSelection={false}
        // keepNonExistentRowsSelected
        // pageSizeOptions={[5]}
        // rowCount={data?.paginationData?.totalRecords || 0}
        // paginationMode="server"
        // paginationModel={pageState}
        // onPaginationModelChange={handlePagination}
        columns={columns}
        rows={data?.results ? data?.results : data ? data : []}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        sx={{
          backgroundColor: "white",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "16px",
            fontWeight: "700",
            padding: "0px 10px",
            color: "#000000",
          },
          "& .MuiDataGrid-row": {
            fontSize: "14px",
            color: "black",
            padding: "0px 10px",
          },
          "& .css-levciy-MuiTablePagination-displayedRows": {
            marginBottom: "0px !important",
          },
        }}
        localeText={{ noRowsLabel: "No data found" }}
      />
    </Box>
  );
};
DataGridTable.propTypes = {
  pageState: PropTypes.number,
  setPageState: PropTypes.number,
  data: PropTypes.array,
  columns: PropTypes.array,
  pageSize: PropTypes.number,
};
export default DataGridTable;
