import dayjs from "dayjs";

export const payloadCreate = (data, staffName, vendorId, editData) => {
  const payload = {
    alertTitle: data?.title ? data?.title : "",
    alertMessage: data?.alertMessage ? data?.alertMessage : "",
    alertStatus: editData?.alertStatus || "CR",
    alertType: "FR",
    alertDueDate: data?.dueDate
      ? dayjs(data?.dueDate).format("YYYY-MM-DD")
      : null,
    assignTo: vendorId ?? null,
    createdBy: editData?.createdBy ?? staffName?.Name ?? "",
    alertCreatedDate:
      editData?.alertCreatedDate || dayjs(new Date()).format("YYYY-MM-DD"),
    deleteInd: "N",
    updatedBy: staffName?.Name ?? "",
    alertUpdatedDate: dayjs(new Date()).format("YYYY-MM-DD"),
    dirtyStatus: editData?.dirtyStatus ? "UPDATE":"INSERT"
  };

  if (editData) {
    payload.alertId = editData.alertId ? editData.alertId : null;
  }

  return payload;
};

export const formerPayload = (data, message, vendorId) => {
  return {
    alertTitle: message?.alertTitle ?? "",
    alertMessage: data?.alertMessageFormer ? data?.alertMessageFormer : "",
    recipientComment: data?.replyFormer ? data?.replyFormer : "",
    alertStatus: "SB",
    alertType: "FR",
    alertDueDate: message?.alertDueDate ?? null,
    assignTo: vendorId ?? null,
    createdBy: message?.createdBy ?? "",
    alertCreatedDate: message?.alertCreatedDate ?? null,
    deleteInd: "N",
    updatedBy: message?.updatedBy ?? "",
    alertUpdatedDate: message?.alertUpdatedDate ?? null,
    dirtyStatus: "UPDATE",
    alertId: message?.alertId ?? null,
  };
};

export const replyToFarmerPayload = (
  data,
  replyDataFromFarmer,
  vendorId,
  btnType
) => {
  return {
    alertTitle: replyDataFromFarmer?.alertTitle ?? "",
    alertMessage:
      btnType === "RD"
        ? data?.replyMessageToFormer
          ? data?.replyMessageToFormer
          : ""
        : replyDataFromFarmer?.alertMessage ?? "",
    recipientComment: data?.replyFormerBack ? data?.replyFormerBack : "",
    alertStatus: btnType,
    alertType: "FR",
    alertDueDate: replyDataFromFarmer?.alertDueDate ?? null,
    assignTo: vendorId ?? null,
    createdBy: replyDataFromFarmer?.createdBy ?? "",
    alertCreatedDate: replyDataFromFarmer?.alertCreatedDate ?? null,
    deleteInd: "N",
    updatedBy: replyDataFromFarmer?.updatedBy ?? "",
    alertUpdatedDate: replyDataFromFarmer?.alertUpdatedDate ?? null,
    dirtyStatus: "UPDATE",
    alertId: replyDataFromFarmer?.alertId ?? null,
  };
};
