import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import { getVendorInfoList } from "../../redux/actionTypes/vendordetails";
import {
  convertUTCDateToLocalDate,
  formatPhoneNumber,
} from "../../utils/globalMethods";
import CommonLoading from "../common/components/commonLoading";

const VendorDetails = (props) => {
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    const payload = {
      role: "ADMIN",
      vendorId: props?.row?.id,
      vendorType: props?.vendorType,
    };
    props.getVendorInfoList(payload);
  }, [props?.row?.id, props.vendorDetails.refreshKey, isLoading]);

  useEffect(() => {
    const { loading } = props.vendorDetails.vendorInfoList;
    if (props.vendorDetails.vendorInfoList.isHIPVendor === "Y") {
      props.setVendorAddtInfo((prevState) => ({
        ...prevState,
        [props.vendorDetails.vendorInfoList.vendorId]:
          props.vendorDetails.vendorInfoList.hipInfo,
      }));
    }
    setIsLoading(loading);
  }, [props.vendorDetails.vendorInfoList.hipInfo]);

  useEffect(() => {
    const { loading } = props.vendorDetails.vendorInfoList;
    if (props.vendorDetails.vendorInfoList.isCSAVendor === "Y") {
      props.setVendorAddtInfo((prevState) => ({
        ...prevState,
        [props.vendorDetails.vendorInfoList.vendorId]:
          props.vendorDetails.vendorInfoList.csaInfo,
      }));
    }
    setIsLoading(loading);
  }, [props.vendorDetails.vendorInfoList.csaInfo]);

  return (
    <Grid container className="collapsibleTable" spacing={2}>
      {isLoading && <CommonLoading />}
      <Grid item xs={10}>
        <Typography
          variant="h5"
          display="inline"
          className="resText text-capitalize"
          gutterBottom
          component="div"
        >
          Vendor Info for KFresh Communications-1
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          First Name
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {props.vendorAddtInfo[props?.row?.id]?.firstName
            ? props.vendorAddtInfo[props?.row?.id]?.firstName
            : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Last Name
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {props.vendorAddtInfo[props?.row?.id]?.lastName
            ? props.vendorAddtInfo[props?.row?.id]?.lastName
            : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Email Address
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {props.vendorAddtInfo?.[props?.row?.id]?.primaryEmail
            ? props.vendorAddtInfo?.[props?.row?.id]?.primaryEmail
            : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Mobile#
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {props.vendorAddtInfo[props?.row?.id]?.primaryMobile
            ? formatPhoneNumber(
                props.vendorAddtInfo[props?.row?.id]?.primaryMobile
              )
            : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Registered Date
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {props.vendorAddtInfo[props?.row?.id]?.createTs
            ? convertUTCDateToLocalDate(
                props.vendorAddtInfo[props?.row?.id]?.createTs
              )
            : "-"}
        </Typography>
      </Grid>
      {props?.showVendorHistoryDetails && (
        <Grid item xs={3}>
          <Typography variant="h6" gutterBottom component="div">
            Verified Date
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {props.vendorAddtInfo[props?.row?.id]?.verifiedTs
              ? convertUTCDateToLocalDate(
                  props.vendorAddtInfo[props?.row?.id]?.verifiedTs
                )
              : "-"}
          </Typography>
        </Grid>
      )}
      {props.vendorDetails.vendorInfoList.isHIPVendor === "Y" && (
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom component="div">
            Additional Contact Info
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {props.vendorAddtInfo[props?.row?.id]?.additionalContactInfo
              ? props.vendorAddtInfo[props?.row?.id]?.additionalContactInfo
              : "-"}
          </Typography>
        </Grid>
      )}
      {props.vendorDetails.vendorInfoList.isCSAVendor === "Y" && (
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom component="div">
            Admin Notes
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {props.vendorAddtInfo[props?.row?.id]?.additionalContactInfo
              ? props.vendorAddtInfo[props?.row?.id]?.additionalContactInfo
              : "-"}
          </Typography>
        </Grid>
      )}
      <Grid item xs={10}>
        <Typography
          variant="h5"
          display="inline"
          className="resText"
          gutterBottom
          component="div"
        >
          Vendor Info for Public display
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom component="div">
          Email
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {props?.vendorAddtInfo?.[props?.row?.id]?.publicEmail
            ? props?.vendorAddtInfo[props?.row?.id]?.publicEmail
            : "-"}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom component="div">
          Mobile#
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {props?.vendorAddtInfo?.[props?.row?.id]?.publicMobile
            ? formatPhoneNumber(
                props?.vendorAddtInfo[props?.row?.id]?.publicMobile
              )
            : "-"}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom component="div">
          Website
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {props?.vendorAddtInfo[props?.row?.id]?.publicWebsite
            ? props?.vendorAddtInfo[props?.row?.id]?.publicWebsite
            : "-"}
        </Typography>
      </Grid>
      {props.vendorDetails.vendorInfoList.isHIPVendor === "Y" ? (
        <>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom component="div">
              Products
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {props.vendorAddtInfo[props?.row?.id]?.products
                ? props.vendorAddtInfo[props?.row?.id]?.products
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom component="div">
              Description
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {props.vendorAddtInfo[props?.row?.id]?.businessDesc
                ? props.vendorAddtInfo[props?.row?.id]?.businessDesc
                : "-"}
            </Typography>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom component="div">
            Notes for SPF
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {props.vendorAddtInfo[props?.row?.id]?.products
              ? props.vendorAddtInfo[props?.row?.id]?.products
              : "-"}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom component="div">
          Photo
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          nowrap={true}
        >
          {props.vendorAddtInfo[props?.row?.id]?.publicPhoto && (
            <img
              src={props.vendorAddtInfo[props?.row?.id]?.publicPhoto}
              className="img-thumbnail"
            />
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

VendorDetails.propTypes = {
  row: PropTypes.object.isRequired,
  showVendorHistoryDetails: PropTypes.bool,
  vendorDetails: PropTypes.object,
  getVendorInfoList: PropTypes.func,
  vendorAddtInfo: PropTypes.object,
  setVendorAddtInfo: PropTypes.object,
  vendorType: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return {
    getVendorInfoList: (payload) => dispatch(getVendorInfoList(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorDetails);
