import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import DataGridTable from "../../../../common/DataGrid";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DateTimePickerField from "../../../common/components/dateTimePicker";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { AUTH_API, headers } from "../../../../config";
import PropTypes from "prop-types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { payloadCreate } from "./data";
import { isEmpty } from "lodash";
import toast from "react-hot-toast";
import useLoader from "../../../../hooks/useLoader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axiosInstance from "../../../../redux/interceptor";
import AlertViewFarmer from "./alertViewFarmer";
import ReplyToFarmer from "./replyToFarmer";

const AlertOverview = ({ vendorInfo, vendorId }) => {
  const [open, setOpen] = useState(false);
  const { openLoader, closeLoader } = useLoader();
  const [alertTableData, setAlertTableData] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [deleteSingleData, setDeleteSingleData] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [replyDialogOpen, setReplyDialogOpen] = useState(false);
  const [replyDataFromFarmer, setReplyDataFromFarmer] = useState({});

  const isEditFlow = !isEmpty(editData);
  const getFormerMCId = vendorId ? vendorId : null;

  const staffName = JSON.parse(sessionStorage.getItem("userData"));
  const formerData = JSON.parse(sessionStorage.getItem("userLogin"));
  const formerRole = ["VENDOR", "HIPVDR", "CSAVDR"].includes(formerData?.role);

  const schema = yup.object().shape({
    title: yup.string().required("Title is required"),
    alertMessage: yup.string().required("Alert message is required"),
    dueDate: yup.date().required("Due date is required"),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClickOpen = () => {
    setOpen(true);
    reset({});
  };

  const handleClose = () => {
    setOpen(false);
    setEditData({});
  };

  const handleMoreVertClick = (itemId) => {
    setSelectedItemId(itemId === selectedItemId ? null : itemId);
  };

  useEffect(() => {
    getSubmittedAlert();
    // sessionStorage.setItem("alertView", true);
  }, []);

  const getSubmittedAlert = async () => {
    try {
      openLoader("alert_get");
      const url = `${AUTH_API}/getAlertsForFarmerAndCoordinator/${getFormerMCId}`;
      const res = await axiosInstance.get(url);
      if (res) {
        const modifiedData = res?.data.map((item, index) => {
          const datePart = item.alertCreatedDate
            ? item.alertCreatedDate.split("T")[0]
            : null;
          const dateUpdatePart = item.alertUpdatedDate
            ? item.alertUpdatedDate.split("T")[0]
            : null;
          const dateDue = item.alertDueDate
            ? item.alertDueDate.split("T")[0]
            : null;
          return {
            ...item,
            id: index + 1,
            alertCreatedDate: datePart,
            alertUpdatedDate: dateUpdatePart,
            alertDueDate: dateDue,
          };
        });

        const updatedAlertTableData = modifiedData.filter(
          (item) => item.deleteInd !== "Y"
        );
        setAlertTableData(updatedAlertTableData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("alert_get");
    }
  };

  const onSubmit = async (data) => {
    const url = `${AUTH_API}/createOrUpdateAlert`;
    try {
      openLoader("alert");
      const payload = payloadCreate(data, staffName, vendorId, editData);
      const res = await axiosInstance.post(url, payload);
      if (res.status === 200) {
        getSubmittedAlert();
        toast.success("Alert Added Successfully!");
      }
    } catch (error) {
      toast.error("Alert Action failed!");
      console.log(error);
    } finally {
      closeLoader("alert");
      setTimeout(() => {
        handleClose();
      }, [2000]);
    }
  };

  const handleReplyViewClick = (viewData) => {
    setReplyDialogOpen(true);
    setReplyDataFromFarmer(viewData);
  };

  let dynamicColumns = [
    {
      field: "alertTitle",
      headerName: "Alert Title",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "alertMessage",
      headerName: "Alert Message",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "alertStatus",
      headerName: "Alert Status",
      headerClassName: "grid_header",
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.alertStatus === "SB" && <span>Submit</span>}
            {params?.row?.alertStatus === "CR" && <span>Alert Created</span>}
            {params?.row?.alertStatus === "AP" && <span>Approved</span>}
            {params?.row?.alertStatus === "DE" && <span>Denied</span>}
            {params?.row?.alertStatus === "RD" && <span>Returned</span>}
          </span>
        );
      },
    },
    {
      field: "alertDueDate",
      headerName: "Alert Due Date",
      headerClassName: "grid_header",
      flex: 1,
    },
  ];

  if (!formerRole) {
    dynamicColumns.push({
      field: "action",
      headerName: "Action",
      headerClassName: "grid_header",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.alertStatus === "AP" && (
              <span
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  padding: "3px",
                  backgroundColor: "green",
                  borderRadius: "3px",
                }}
              >
                Alert Approved
              </span>
            )}
            {params.row.alertStatus === "DE" && (
              <span
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  padding: "3px",
                  backgroundColor: "red",
                  borderRadius: "3px",
                }}
              >
                Alert Denied
              </span>
            )}
            {!(
              params.row.alertStatus === "AP" || params.row.alertStatus === "DE"
            ) &&
              (params.row.alertStatus === "SB" ? (
                <IconButton onClick={() => handleReplyViewClick(params.row)}>
                  <Tooltip title="Reply View" placement="right">
                    <VisibilityIcon />
                  </Tooltip>
                </IconButton>
              ) : (
                <>
                  <IconButton
                    onClick={() =>
                      handleMoreVertClick(params.id ? params.id : null)
                    }
                  >
                    <MoreVertIcon />
                  </IconButton>
                  {selectedItemId === params.id && (
                    <>
                      <IconButton onClick={() => handleEditClick(params.row)}>
                        <Tooltip title="Edit" placement="right">
                          <ModeEditOutlineOutlinedIcon />
                        </Tooltip>
                      </IconButton>
                      <IconButton onClick={() => handleDeleteClick(params.row)}>
                        <Tooltip title="Delete" placement="right">
                          <DeleteOutlineOutlinedIcon />
                        </Tooltip>
                      </IconButton>
                    </>
                  )}
                </>
              ))}
          </>
        );
      },
    });
  } else {
    dynamicColumns.push({
      field: "view",
      headerName: "View",
      headerClassName: "grid_header",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.alertStatus === "AP" && (
              <span
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  padding: "3px",
                  backgroundColor: "green",
                  borderRadius: "3px",
                }}
              >
                Alert Approved
              </span>
            )}
            {params.row.alertStatus === "DE" && (
              <span
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  padding: "3px",
                  backgroundColor: "red",
                  borderRadius: "3px",
                }}
              >
                Alert Denied
              </span>
            )}
            {!(
              params.row.alertStatus === "AP" || params.row.alertStatus === "DE"
            ) && (
              <>
                <IconButton
                  onClick={() =>
                    handleMoreVertClick(params.id ? params.id : null)
                  }
                >
                  <MoreVertIcon />
                </IconButton>
                {selectedItemId === params.id && (
                  <>
                    <IconButton onClick={() => handleViewClick(params.row)}>
                      <Tooltip title="View" placement="right">
                        <VisibilityIcon />
                      </Tooltip>
                    </IconButton>
                  </>
                )}
              </>
            )}
          </>
        );
      },
    });
  }
  const COLUMN = dynamicColumns;

  const handleDeleteClick = (data) => {
    let { id } = data;
    setDeleteSingleData(id);
    setIsDeleteOpen(true);
  };

  const handleEditClick = (data) => {
    setOpen(true);
    reset({});
    setEditData(data);
  };

  const handleDelete = async () => {
    try {
      openLoader("alert_delete");
      if (alertTableData && alertTableData.length > 0) {
        const payload = alertTableData.filter(
          (item) => item.id !== deleteSingleData
        );
        const itemToDelete = alertTableData.find(
          (item) => item.id === deleteSingleData
        );
        const alertIdToDelete = itemToDelete ? itemToDelete.alertId : null;

        const url = `${AUTH_API}/deleteAlert/${alertIdToDelete}`;
        const res = await axios.delete(url, payload, {
          headers: headers,
        });
        if (res.status === 200) {
          getSubmittedAlert();
          setDeleteSingleData("");
          setIsDeleteOpen(false);
          toast.success(res?.data || "Alert Deleted Successfully!");
        }
      }
    } catch (err) {
      console.log("ERROR", err);
      toast.error("Failed to Delete Alert!");
    } finally {
      closeLoader("alert_delete");
      handleClose();
    }
  };

  const handleViewClick = async (data) => {
    setMessage(data);
    setViewDialogOpen(true);
  };
  
  return (
    <div style={{ padding: "20px 10px", border: "1px solid #ccc" }}>
      <Grid container>
        <Grid item xs={2}>
          <Typography
            sx={{ fontSize: "18px", fontWeight: "600", marginBottom: "20px" }}
          >
            Farmer Name:
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
            {vendorInfo?.vendorName}
          </Typography>
        </Grid>
        {!formerRole && (
          <>
            <Grid
              item
              xs={8}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "20px",
              }}
            >
              <Button variant="contained" onClick={handleClickOpen}>
                +Add Alert
              </Button>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <DataGridTable columns={COLUMN} data={alertTableData} />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "22px", fontWeight: "600" }}
        >
          Alert
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute !important",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Stack direction={"column"} gap={"20px"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Grid item xs={3}>
                      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                        Title<span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        name="title"
                        control={control}
                        defaultValue={editData?.alertTitle ?? ""}
                        required
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{ width: "25rem" }}
                            placeholder="Write a Title here"
                            error={errors.title?.message}
                            helperText={errors.title?.message}
                          />
                        )}
                      />
                    </Grid>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Grid item xs={3}>
                      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                        Alert Message<span style={{ color: "red" }}>*</span>
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        name="alertMessage"
                        control={control}
                        defaultValue={editData?.alertMessage ?? ""}
                        required
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={errors.alertMessage?.message}
                            helperText={errors.alertMessage?.message}
                            placeholder="Write Alert message here"
                            multiline
                            rows={6}
                            fullWidth
                            sx={{ width: "25rem" }}
                          />
                        )}
                      />
                    </Grid>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Grid item xs={3}>
                      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                        Due Date<span style={{ color: "red" }}>*</span>
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        name="dueDate"
                        control={control}
                        defaultValue={
                          editData?.alertDueDate
                            ? dayjs(editData?.alertDueDate).format("YYYY-MM-DD")
                            : null
                        }
                        required
                        render={({ field }) => (
                          <>
                            <DatePicker
                              {...field}
                              error={errors.dueDate?.message}
                              helperText={errors.dueDate?.message}
                              slotProps={{
                                textField: {
                                  error: false,
                                },
                              }}
                              format="YYYY/MM/DD"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              type="date"
                              value={dayjs(field.value || "")}
                              label="Enter due date"
                              sx={{ width: "106%" }}
                              onChange={(date) => field.onChange(date)}
                            />
                            {errors.dueDate?.message && (
                              <span style={{ color: "red" }}>Invalid Date</span>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-end !important"}
                    ml={2}
                  >
                    <div style={{ marginLeft: "20px" }}>
                      <Button
                        autoFocus
                        color="success"
                        variant="contained"
                        type="submit"
                      >
                        {isEditFlow ? "Update" : "Submit"}
                      </Button>
                    </div>
                  </Stack>
                </Stack>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleteOpen}
        maxWidth="sm"
        keepMounted
        onClose={() => {
          setIsDeleteOpen(false);
          setDeleteSingleData("");
        }}
      >
        <DialogTitle>
          <Typography variant="h3grey" style={{ fontWeight: "600" }}>
            Delete Confirmation
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h4grey" style={{ fontWeight: "600" }}>
              {" "}
              Are you sure you want to delete this record?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ margin: "0.8rem", textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIsDeleteOpen(false);
              setDeleteSingleData("");
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <AlertViewFarmer
        viewDialogOpen={viewDialogOpen}
        setViewDialogOpen={setViewDialogOpen}
        message={message}
        getSubmittedAlert={getSubmittedAlert}
        vendorId={vendorId}
      />
      <ReplyToFarmer
        replyDialogOpen={replyDialogOpen}
        setReplyDialogOpen={setReplyDialogOpen}
        replyDataFromFarmer={replyDataFromFarmer}
        getSubmittedAlert={getSubmittedAlert}
        vendorId={vendorId}
      />
    </div>
  );
};
AlertOverview.propTypes = {
  vendorInfo: PropTypes.object,
  vendorId: PropTypes.number,
  getSubmittedAlert: PropTypes.func,
};
export default AlertOverview;
