import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/reducers";
import { Button, Col, Form, Row } from "react-bootstrap";
import { GET_COUNTYCITY } from "../../../redux/actionTypes";
import { isNumber, sortName } from "../../../utils/globalMethods";

function SnapAddEditComponent(props) {
  const { validated, mapdata, handleSubmit, onEdit, getCityList, row } = props;
  useEffect(() => {
    getCityList();
  }, []);
  //   useEffect(() => {
  //     // let cityOption = props.mapdata.city.map(item => {
  //     //   return {
  //     //     name: item.id,
  //     //     values: item.cityName,
  //     //     isActive: 1
  //     //   };
  //     // });
  //     // let defaultData = [{name:"Select City",values:"Select City"}];
  //     setCityList(props.mapdata.city);
  //   }, [props.mapdata.city]);

  return (
    <div className="form-block">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group as={Col} controlId="snapAddress">
              <Form.Label className="smallTitle">Address Line 1 *</Form.Label>
              <Form.Control
                type="text"
                size="lg"
                defaultValue={onEdit ? row.address1 : ""}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter Address Line 1.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group as={Col} controlId="snapAddress">
              <Form.Label className="smallTitle">Address Line 2</Form.Label>
              <Form.Control
                type="text"
                size="lg"
                defaultValue={onEdit ? row.address2 : ""}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group as={Col} controlId="snapCity">
              <Form.Label className="smallTitle">City *</Form.Label>
              <Form.Select size="lg" required>
                <option value="">Select City</option>
                {sortName(mapdata.city, "cityName").map((item) => {
                  return (
                    <option
                      key={item.id}
                      value={
                        item.cityName +
                        "," +
                        item.latitude +
                        "," +
                        item.longitude
                      }
                      selected={onEdit && row.city == item.cityName}
                    >
                      {item.cityName}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please select City.
              </Form.Control.Feedback>
              {/* <Form.Label className="smallTitle">City *</Form.Label>
                        <SelectDropdown
                            value={city}
                            onChange={(v) => {
                                setCity(v);
                            }}
                            options={cityList}
                            isSearch={true}
                            defaultValueProps={{
                                default: true,
                                defaultOption: {name:"Select City",values:"Select City"},
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please select City.
                        </Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group as={Col} controlId="snapZipCode">
              <Form.Label className="smallTitle">Zip Code *</Form.Label>
              <Form.Control
                type="text"
                size="lg"
                maxLength={5}
                onKeyDown={isNumber}
                defaultValue={onEdit ? row.zipcode : ""}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid Zipcode.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group as={Col} controlId="snapState">
              <Form.Label className="smallTitle">State *</Form.Label>
              <Form.Select size="lg" required>
                <option value="MA">MA</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please select State.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          {/* <Col md={12}>
                    <Form.Group as={Col} controlId="snapMap">
                        <MapComponent 
                                lat={lat}
                                lng={lng}
                                row={row}
                                draggable={true}
                            />
                    </Form.Group>
                </Col> */}
        </Row>
        {onEdit && (
          <Form.Control type="text" value={row.addressId} readOnly hidden />
        )}
        <Row className="modal-footer">
          <Col>
            <Button variant="primary" type="submit" className="float-right">
              {onEdit ? "Update" : "Add"}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

SnapAddEditComponent.propTypes = {
  validated: PropTypes.bool,
  handleSubmit: PropTypes.func,
  mapdata: PropTypes.object,
  getCityList: PropTypes.func.isRequired,
  onEdit: PropTypes.bool,
  row: PropTypes.object,
  submitUserData: PropTypes.func,
  latLng: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCityList: (city_county) =>
      dispatch({ type: GET_COUNTYCITY, city: city_county }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnapAddEditComponent);
