import React, { useState, useEffect, useCallback } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerClusterer,
  Marker,
  Circle,
} from "@react-google-maps/api";
import { GOOGLE_KEY } from "../../config";
import { GET_STORE_DETAILS } from "../../redux/actionTypes";
import { mapStateToProps } from "../../redux/reducers";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setKey } from "react-geocode";
import "../../styles/dashboard.scss";
import user_marker from "../../assets/images/marker/user-marker.png";
import Loader from "../../components/Loader";

var center = {
  lat: 42.732536,
  lng: -84.555534,
  address: "Michigan",
  city: "Michigan",
};

const centerLocation = {
  lat: 42.732536,
  lng: -84.555534,
  address: "Michigan",
  city: "Michigan",
  located: false,
};
const circleOptions = {
  strokeColor: "grey",
  strokeOpacity: 0.8,
  strokeWeight: 0,
  fillColor: "#2e6e53",
  fillOpacity: 0.35,
  // center: center,
  map: center,
  radius: 8046.7,
};

const LOCATION_TYPES = {
  csaPickupSite: {
    loc_type_nam: "HIP CSA Program",
    loc_type_img: "./marker/csa-farms.png",
  },
  farmStand: {
    loc_type_nam: "HIP Farm Stand",
    loc_type_img: "./marker/farm-stands.png",
  },
  farmersMarket: {
    loc_type_nam: "HIP Farmers' Market Booth",
    loc_type_img: "./marker/farmers-market-managers.png",
  },
  farmersMarketManagers: {
    loc_type_nam: "HIP Farmers Market",
    loc_type_img: "./marker/market-managers.png",
  },
  mobileMarket: {
    loc_type_nam: "HIP Mobile Market Stop",
    loc_type_img: "./marker/mobile-market.png",
  },
};

const getIcon = (location) => {
  let icon = LOCATION_TYPES[location["location_types_name"]]?.loc_type_img;
  return icon;
};

setKey(GOOGLE_KEY);

function MapWithMarkerClusterer(props) {
  const { isLoading } = props;
  const [mapZoom] = useState(11);
  // eslint-disable-next-line no-unused-vars
  const [loadingStatus, setLoadingStatus] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [activeMarker, setActiveMarker] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [locationId, setLocationId] = useState(null);
  // const [searchStoreResultView, setSearchResultView] = useState(false);
  const [clickedMarkerClusterer, setClickedMarkerClusterer] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [mapRef, setMapRef] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [backBtn, setBackBtn] = useState(false);
  const [mapZoomLevel, setMapZoomLevel] = useState([]);
  const [map, setMap] = useState(null);
  const [circleOption, setCircleOption] = useState(circleOptions);
  const [coordinates, setCoordinates] = useState(centerLocation);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_KEY,
  });

  // const onLoad = React.useCallback(function callback(map) {
  //   // const bounds = new window.google.maps.LatLngBounds(center);
  //   // map.fitBounds(bounds);
  //   // setMap(map);
  // }, []);

  // const onUnmount = React.useCallback(function callback(map) {
  //   // setMap(null);
  // }, []);

  const onLoad = () => {
    // console.log("Circle onLoad circle: ", circle);
  };

  const onUnmount = () => {
    //map
    //console.log(map);
  };

  const onMapLoad = useCallback((map) => {
    //map
    setLoadingStatus(false);
    setMap(map);
  }, []);

  const handleMarkerClustererClick = useCallback(
    (markerClusterer) => {
      props.locationId(null);
      console.log("handleMarkerClustererClick", markerClusterer);
      // props.locationId(null);
      const clickedMarkers = markerClusterer.getMarkers();
      const ids = clickedMarkers.map((m) => m.id);
      if (mapZoomLevel) {
        setClickedMarkerClusterer(ids);
        props.clickedMarkerClusterer(ids);
        const mapZoom = map.getZoom();
        setMapZoomLevel((oldArray) => [
          { clickedMarkers: ids, zoom: mapZoom },
          ...oldArray,
        ]);
      }
    },
    [map]
  );

  const handleClick = () => {
    props.locationId(null);
    setBackBtn(true);
    if (mapZoomLevel.length) {
      map.setZoom(mapZoomLevel[0].zoom);
      setClickedMarkerClusterer(
        mapZoomLevel[1]?.clickedMarkers ? mapZoomLevel[1]?.clickedMarkers : []
      );
      props.clickedMarkerClusterer(
        mapZoomLevel[1]?.clickedMarkers ? mapZoomLevel[1]?.clickedMarkers : []
      );
      setMapZoomLevel(
        mapZoomLevel.filter((item) => item.zoom !== mapZoomLevel[0].zoom)
      );
    } else {
      map.setZoom(map.getZoom() - 2);
    }
    // setSingleSection(false);
    // setSearchResultView(true);
  };

  const handleActiveMarker = (marker) => {
    // if (marker === activeMarker) {
    //   return;
    // }
    // setMapWidth("60%");
    // alert(marker);
    props.locationId(null);
    setTimeout(() => {
      storeDetails(marker);
    }, 500);
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = () => {
    //marker, place
    //   console.log(marker);
    //   console.log(place);
    // return setMarkerMap(prevState => {
    //   return { ...prevState, [place.id]: marker };
    // });
  };

  const storeDetails = (id) => {
    if (id) {
      setActiveMarker(id);

      // setTimeout(()=>{
      // props.setValue(1);
      props.locationId(id);
      // setSingleSection(true);
      setLocationId(id);
      // let payload = {
      //   "cityName": "",
      //   "miles": "",
      //   "lat": "",
      //   "lon": "",
      //   "allCityFlag": "N",
      //   "vendorId": "",
      //   "csaPickupSite": "Y",
      //   "farmStand": "Y",
      //   "farmersMarket": "Y",
      //   "farmersMarketBooth": "Y",
      //   "mobileMarket": "Y",
      //   "monthsOperation": {
      //       "janOperation": "Y",
      //       "febOperation": "Y",
      //       "marOperation": "Y",
      //       "aprOperation": "Y",
      //       "mayOperation": "Y",
      //       "junOperation": "Y",
      //       "julOperation": "Y",
      //       "augOperation": "Y",
      //       "sepOperation": "Y",
      //       "octOperation": "Y",
      //       "novOperation": "Y",
      //       "decOperation": "Y"
      //   },
      //   "distributionOption": {
      //       "delivery": "Y",
      //       "curbsidePickup": "Y",
      //       "onlinePreselection": "Y",
      //       "phonePreselection": "Y",
      //       "inPerson": "Y",
      //       "otherType": "Y"
      //   },
      //   "monOperation": "Y",
      //   "tueOperation": "Y",
      //   "wedOperation": "Y",
      //   "friOperation": "Y",
      //   "thuOperation": "Y",
      //   "satOperation": "Y",
      //   "sunOperation": "Y",
      //   "openToday": "N",
      //   "storeId": id
      // }
      // props.getStoreDetails(payload);
      // },[500]);
    } else {
      setActiveMarker(null);
      // setSingleSection(false);
    }
  };

  useEffect(() => {
    mapReload();
  }, [coordinates, props?.searchProperties]);

  useEffect(() => {
    if (!props.isLocTypeClicked) {
      mapReload();
    }
  }, [props?.mapRefresh]);

  useEffect(() => {
    if (props?.mapResult && props?.mapResult.length) {
      if (
        props.searchProperties?.vendor_name !== "all" &&
        props.searchProperties?.city === "all"
      ) {
        setCoordinates((prevState) => ({
          ...prevState,
          lat: props?.mapResult?.[0].latitude
            ? parseFloat(props?.mapResult?.[0].latitude)
            : "",
          lng: props?.mapResult?.[0].longitude
            ? parseFloat(props?.mapResult?.[0].longitude)
            : "",
          city: "Boston",
          located: false,
          user_marker: props.searchProperties.is_mylocation,
        }));
      } else {
        setCoordinates((prevState) => ({
          ...prevState,
          lat: parseFloat(props.searchProperties.latitude),
          lng: parseFloat(props.searchProperties.longitude),
          city:
            props.searchProperties.city === "all"
              ? "Boston"
              : props.searchProperties.city,
          located: props.searchProperties.city === "all" ? false : true,
          user_marker: props.searchProperties.is_mylocation,
        }));
      }
    } else {
      setCoordinates((prevState) => ({
        ...prevState,
        lat: parseFloat(props.coordinates.lat),
        lng: parseFloat(props.coordinates.lng),
        city:
          props.searchProperties.city === "all"
            ? "Boston"
            : props.searchProperties.city,
        located: props.searchProperties.city === "all" ? false : true,
        user_marker: props.searchProperties.is_mylocation,
      }));
    }
  }, [props.mapResult]);

  const mapReload = () => {
    setClickedMarkerClusterer([]);
    props.clickedMarkerClusterer([]);
    let radius = 8046.7;
    let area = "";
    if (
      props?.searchProperties?.area &&
      props?.searchProperties?.area?.id !== ""
    ) {
      radius =
        parseInt(props?.searchProperties?.area?.label?.split(" ")[0]) * 1609.34;
      console.log(radius);
      area = parseInt(props?.searchProperties?.area?.label?.split(" ")[0]);
    }
    setCircleOption((prevState) => ({
      ...prevState,
      radius: radius,
    }));

    if (map) {
      map.setZoom(null);
      let zoom = props?.searchProperties?.area.id === "" ? 8 : 11;
      if (area && area > 5) {
        switch (area) {
          case 10:
            zoom = 10;
            break;
          case 15:
            zoom = 10;
            break;
          case 20:
            zoom = 9;
            break;
          case 25:
            zoom = 9;
            break;
          case 30:
            zoom = 9;
            break;
          default:
            zoom = 11;
        }
        map.setZoom(zoom);
      } else if (area) {
        switch (area) {
          case 5:
            zoom = 11;
            break;
          case 2:
            zoom = 12;
            break;
          case 1:
            zoom = 13;
            break;
          default:
            zoom = 11;
        }
        // zoom = props?.searchProperties?.city === 'all' ? 8 : zoom;
        map.setZoom(zoom);
      } else {
        map.setZoom(zoom);
      }
    }
  };
  return isLoaded ? (
    <>
      <div
        className={`resetZoomFilter ${
          clickedMarkerClusterer.length > 0 ? "" : "d-none"
        } `}
        id="showResetZoom"
      >
        <button
          className="btn btn-default back-btn btn-block btn-lg reSizeFont text-overflow searchBtn filterHover"
          onClick={handleClick}
          title="Back"
        >
          <i className="fa fa-arrow-left mr-1"></i>
          Back
        </button>
      </div>
      <GoogleMap
        id="googleMap"
        ref={(ref) => setMapRef(ref)}
        mapContainerStyle={{
          width: "100%",
          // minHeight: "100vh",
          height: "662px",
        }}
        zoom={mapZoom}
        // Do stuff on map initial laod
        onLoad={onMapLoad}
        onUnmount={onUnmount}
        // Save the current center position in state
        // onCenterChanged={() => setCenter(mapRef.getCenter().toJSON())}
        center={coordinates}
        options={{
          scrollZoom: {
            ctrl: true,
          },
          zoomControl: true,
          draggable: true,
          // scrollwheel: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          styles: [
            {
              featureType: "administrative",
              elementType: "geometry",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "transit",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
          ],
        }}
      >
        {isLoading && <Loader visible={isLoading} />}
        {props.mapResult !== undefined && props.mapResult.length ? (
          <MarkerClusterer
            imagePath={
              "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
            }
            gridSize={40}
            maxZoom={16}
            zoomOnClick={true}
            onClick={handleMarkerClustererClick}
          >
            {(clusterer) =>
              props.mapResult.map((location) => (
                // mapData.map((location) => (
                <Marker
                  options={{ id: location.store.id }}
                  key={location.store.id} //createKey(location)
                  position={{
                    lat: parseFloat(location.latitude),
                    lng: parseFloat(location.longitude),
                  }}
                  clusterer={clusterer}
                  title={location.vendorName.replace(/&#38;/g, "’")}
                  animation={2}
                  icon={getIcon(location)}
                  id={location.store.id.toString()}
                  onClick={() => {
                    handleActiveMarker(location.store.id);
                  }}
                  onLoad={(marker) => markerLoadHandler(marker, location)}

                  // label={"new"}
                >
                  {/* {activeMarker === location.store.id ? (
                <InfoWindow
                  position={{
                    lat: parseFloat(location.latitude),
                    lng: parseFloat(location.longitude),
                  }}
                  onCloseClick={() => {
                    //alert(1);
                    setActiveMarker(null);
                    // setSingleSection(false);
                    // setMapWidth("100%");
                  }}
                >
                  <div id="content">
                    <div id="siteNotice"></div>
                    <h4
                      id="firstHeading"
                      className="text-color p-none mt-4"
                    >
                      {" "}
                      {location?.vendor?.vendor_name?.replace(
                        /&#38;/g,
                        "’"
                      )}{" "}
                    </h4>
                    <div id="bodyContent">
                      <small>
                        {
                          LOCATION_TYPES[location["location_types_name"]]
                          ?.loc_type_nam
                        }{" "}
                      </small>
                    </div>
                  </div>
                </InfoWindow>
              ) : null} */}
                </Marker>
              ))
            }
          </MarkerClusterer>
        ) : coordinates ? (
          <Marker
            position={coordinates}
            icon={coordinates?.user_marker ? user_marker : ""}
            animation={2}
          />
        ) : (
          ""
        )}
        {!(props.searchProperties.city === "all") && (
          // { !(props.searchProperties.city === "all") && (
          <Circle
            onLoad={onLoad}
            // optional
            onUnmount={onUnmount}
            // required
            center={coordinates}
            // required
            options={circleOption}
          />
        )}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

MapWithMarkerClusterer.propTypes = {
  mapdata: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  mapResult: PropTypes.array,
  searchProperties: PropTypes.object.isRequired,
  coordinates: PropTypes.object,
  language: PropTypes.object,
  initialStoreView: PropTypes.bool,
  isLoading: PropTypes.bool,
  getStoreDetails: PropTypes.func,
  locationId: PropTypes.func,
  isLocTypeClicked: PropTypes.bool,
  clickedMarkerClusterer: PropTypes.func,
  mapRefresh: PropTypes.bool,
  setValue: PropTypes.number,
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStoreDetails: (payload) =>
      dispatch({ type: GET_STORE_DETAILS, payload: payload }),
  };
};

export const GoogleMapComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(MapWithMarkerClusterer));
