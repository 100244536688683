import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import useTranslator from "../../../hooks/useTranslator";
import { useLocation,  useNavigate } from "react-router-dom";

let selectedLanguage = localStorage.getItem("selectedLanguage");

const LanguageSelect = (props) => {
  const { pathname } = useLocation();
  let checkRole = JSON.parse(sessionStorage.getItem("userData"))?.Role
  
  const transProp = useTranslator(
    [
      "english",
      "spanish",
      "portuguese",
      "chinese",
      "vietnamese",
      "arabic",
      "russia",
    ],
    "languages"
  );
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState({});

  const changeLanguage = useCallback(
    (newLng) => {
      if (i18n.isInitialized && i18n.language !== newLng) {
        i18n.changeLanguage(newLng);
        // buildYupLocale(null, t);
      }
    },
    [t, i18n]
  );

  const languageSelect = (e) => {
    if(pathname === "/kfreshfep/registration" || checkRole === "HIPVDR" || checkRole === "ADMIN"){
      return;
    }
    const newLng = e.target.value;
    localStorage.setItem("selectedLanguage", newLng);
    if (i18n.isInitialized && i18n.language !== newLng) {
      const transOb = {
        "en-US": "en",
        "es-ES": "es",
        "pt-BR": "pt",
        "vi-VI": "vi",
        "zh-Hans": "zh",
        "ar-EG": "ar",
        "ru-RU": "ru",
      };
      i18n.changeLanguage(transOb[newLng]);
    }
    // props.onGetLanguage(newLng);
    // setTimeout(() => window.location.reload(true), 1000);
  };

  const languages = [
    {
      value: "en-US",
      display: "English",
    },
    {
      value: "es-ES",
      display: "español",
    },
    {
      value: "pt-BR",
      display: "Português",
    },
    {
      value: "zh-Hans",
      display: "中文",
    },
    {
      value: "vi-VI",
      display: "Tiếng Việt",
    },
    {
      value: "ar-EG",
      display: "عربي",
    },
    {
      value: "ru-RU",
      display: "Россия",
    },
  ];

  return (
    //     <>
    //    <select onChange={ languageSelect }
    //         className={ props.className }
    //         aria-label="Language"
    //         defaultValue={selectedLanguage}
    //         >
    //         {languages.map((lang) => {
    //             return <option key={ lang.value }   value={ lang.value }>{ lang.display }</option>;
    //             })
    //         }
    //     </select>

    //     </>
    <>
      <Select
        className={props.className}
        onChange={languageSelect}
        inputProps={{ "aria-label": "Language" }}
        defaultValue={selectedLanguage || "en-US"}
      >
        {languages.map((lang) => {
          return (
            <MenuItem
              className="language-menu-item"
              key={lang.value}
              value={lang.value}
            >
              {lang.display}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

// Mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  //working
  return {
    // onGetLanguage: (val) => dispatch(selectedLanguageAction(val)),
  };
}

LanguageSelect.propTypes = {
  onGetLanguage: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelect);
