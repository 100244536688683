import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import DataGridTable from "../../common/DataGrid";
import PropTypes from "prop-types";

const HistoryModel = ({
  historyColomns,
  historyTableRow,
  openHistory,
  handleClickCloseHistory,
}) => {
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      scroll="paper"
      open={openHistory}
      onClose={handleClickCloseHistory}
      aria-labelledby="responsive-dialog-title"
      sx={{ paddingBottom: "50px" }}
    >
      <DialogTitle id="responsive-dialog-title">
        {historyTableRow?.[0]?.county ?? historyTableRow?.[0]?.propKey ?? ""}
      </DialogTitle>
      <DialogContent>
        <DataGridTable columns={historyColomns} data={historyTableRow} />
      </DialogContent>
    </Dialog>
  );
};
HistoryModel.propTypes = {
  historyColomns: PropTypes.array,
  historyTableRow: PropTypes.array,
  handleClickCloseHistory: PropTypes.func,
  openHistory: PropTypes.bool,
};

export default HistoryModel;
