import React , { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import {CustomButton} from "../common/components/customButton";
import TableComponent from "../common/components/table";
import PageHeaderComponent from "../common/components/pageHeader";
import CommonLoading from "../common/components/commonLoading";

import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import { getCoordinatesList,editCoordinates,refreshKey } from "../../redux/actionTypes/administration";
import ModalComponent from "../common/components/modal";
import CoordinatesEditComponent from "./coordinatesEdit";
import '../../styles/home.scss';
import CoordinatesDetails from './coordinatesDetails';
import { checkToken } from "../../utils/globalMethods";
import { useNavigate } from "react-router-dom";

function createData(latitude,longitude, storeCount, storeInfo) {
  storeInfo.forEach(object => {
    object.latitude = latitude;
    object.longitude = longitude;
  });
  return {
    latitude,
    longitude, 
    storeCount, 
    storeInfo
  };
}

const CoordinatesComponent = (props) => {
  const navigate = useNavigate();
 
  const [manageCoordinatesTotal, setManageCoordinatesTotal] = useState(0);
  const [rows, setRows] = useState([]);
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [onEdit, setOnEdit] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [isLoading, setIsLoading]=useState(false);

  const handleNext = (page,rowSize) => {
    (size != rowSize) && setSize(rowSize);
    setFrom(rowSize*page);
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }else{
      let payload = {
        "latitude": event.target[0].value,
        "longitude": event.target[1].value,
        "addrId": event.target[3].value,
        "storeId": event.target[2].value
      }
      if(Object.keys(onEdit).length !== 0){
        props.editCoordinates(payload);
      }
      setIsLoading(true);
      setShowModal(false);
      setOnEdit({});
      event.preventDefault();
      event.stopPropagation();
    }
  };

  useEffect(() => {
    const userInfo = props?.userAuth?.user ? props?.userAuth?.user : JSON.parse(sessionStorage.getItem("userData"));
     if (props?.allowedRoles && userInfo && userInfo !== null && Object.keys(userInfo).length != 0) {
      const checkRole = props.allowedRoles.find((role) => userInfo?.Role.includes(role));
      if (!checkRole) {
        navigate("/kfreshfep");
        return;
      }
    }
    const payload = {
      "isActive":"Y",
      "farmersMarket":"N",
      "isVerified":"Y",
      "size":size,
      "from":from,
     }
    if(checkToken()){
      props.getCoordinatesList(payload);
    }
    setIsLoading(false);
  }, [size,from,props.administration.refreshKey]);

  useEffect(() => {
    const { coordinatesList } = props.administration;
    let data = [];
   coordinatesList.list.forEach(element => {
      data.push(createData(element.latitude,element.longitude, element.storeCount, element.storeInfo))
    });
    setRows(data);
    setManageCoordinatesTotal(coordinatesList.total);
    setShowLoader(coordinatesList.loading);
  }, [props.administration.coordinatesList]);

  const handleOnEditAction = (row) => {
    setOnEdit(row);
    setShowModal(true);
  }

  const renderModal = () => {
    return (<CoordinatesEditComponent 
      validated={validated}
      handleSubmit={handleSubmit}
      onEdit={true}
      row={onEdit}
    />)
  }

  const renderDetails = (row) => {
    return(<CoordinatesDetails 
    row = {row} 
    handleOnEditAction={handleOnEditAction}
    />)
  }

  const columns = [
    { id: 'details', label: 'Details' },
    { id: 'latitude', label: 'Latitude' },
    {
      id: 'longitude',
      label: 'Longitude',
    },
    {
      id: 'storeCount',
      label: 'Number of Stores',
    }
  ];

  const handleRefresh = () => {
    setShowLoader(true);
    props.refreshKey();
  }

  return (
    <>
      <Grid container className="container-block" rowSpacing={2}>
      { isLoading && 
        <CommonLoading />
      }
      {showModal &&
          <ModalComponent 
            shown = {showModal}
            close = {() => {
              setShowModal(false);
              setOnEdit({});  
            }}
            title = {"EDIT COORDINATES"}
            modalBody = {renderModal}
            size={"md"}
            label={"EDIT COORDINATES"}
          />
        }
        <Grid item xs={12} md={6} >
          <PageHeaderComponent 
            title = {"MANAGE COORDINATES"}
            showSubtitle = {true}
            subtitle = {"Total"}
            count = {manageCoordinatesTotal}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
          <Stack direction="row" spacing={0.5} justifyContent="flex-end">
            <CustomButton variant="outlined" startIcon={<i className="fa fa-refresh" aria-hidden="true"></i>} onClick={handleRefresh}>
              Refresh
            </CustomButton>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <TableComponent 
            columns={columns}
            rows = {rows}
            showDetails = {true}
            renderDetails = {renderDetails}
            total = {manageCoordinatesTotal}
            handlePagination={handleNext}
            page={Math.floor(from/size)}
            rowsPerPage={size}
            dataLoading={showLoader}
          />
        </Grid>
      </Grid>
    </>
  );
};

CoordinatesComponent.propTypes = {
  getCoordinatesList: PropTypes.func.isRequired,
  administration:PropTypes.object,
  editCoordinates:PropTypes.func.isRequired,
  refreshKey:PropTypes.func.isRequired,
  allowedRoles: PropTypes.array,
  userAuth : PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    getCoordinatesList: (payload) => dispatch(getCoordinatesList(payload)),
    editCoordinates: (payload) => dispatch(editCoordinates(payload)),
    refreshKey: () => dispatch(refreshKey()),
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(CoordinatesComponent);