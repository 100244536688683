import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import { map } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import axiosInstance from "../../redux/interceptor";
import useLoader from "../../hooks/useLoader";

const EligibilityCriteriaModel = ({
  handleClose,
  editData,
  open,
  names,
  fetchValues,
}) => {
  const { openLoader, closeLoader } = useLoader();

  const { register, handleSubmit, control, watch } = useForm({
    defaultValues: {
      propKey: editData?.propKey ?? "",
      effectiveBeginDt: dayjs(editData?.effectiveBeginDt) ?? null,
      propValue: editData?.propValue ?? "",
    },
  });
  const onSubmit = async (data) => {
    const payload = {
      id: editData?.id,
      propKey: data?.propKey,
      effectiveBeginDt: data?.effectiveBeginDt,
      propValue: data?.propValue,
    };

    try {
      openLoader("fetch_el_cr");
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/staff/update/eligibility/criteria`,
        payload
      );
      if (res.status === 200) {
        handleClose();
        fetchValues();
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("fetch_el_cr");
    }
  };
  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        scroll="paper"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ paddingBottom: "50px" }}
      >
        <DialogTitle id="responsive-dialog-title">
          Update Income limits
        </DialogTitle>
        <DialogContent>
          {" "}
          <Form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "flex", gap: "10px", flexDirection: "column" }}
          >
            <Box>
              <Grid
                container
                xs={12}
                sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <Grid item xs={3}>
                  <Typography variant="h5" component="div">
                    Family Size
                  </Typography>
                  <Select
                    fullWidth
                    //   name="propKey"
                    size="small"
                    value={editData?.propKey}
                    {...register("propKey")}
                  >
                    {map(names, (item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h5" component="div">
                    Effective Start Date
                  </Typography>
                  <Controller
                    name="effectiveBeginDt"
                    control={control}
                    required
                    render={({ field }) => (
                      <>
                        <DatePicker
                          {...field}
                          disablePast
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                          format="YYYY/MM/DD"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          type="date"
                          placeholder="Enter due date"
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h5" component="div">
                    Gross Monthly
                  </Typography>
                  <Form.Control
                    placeholder="Monthly Amount"
                    type="number"
                    name="propValue"
                    {...register("propValue")}
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  sx={{ color: "#6C6C6C", border: "1px solid #6C6C6C" }}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    textTransform: "none",
                    width: "100px",
                    background: "#457425",
                    color: "#FFF",
                  }}
                >
                  Update
                </Button>
              </Box>
            </Grid>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};
EligibilityCriteriaModel.propTypes = {
  handleClose: PropTypes.func,
  editData: PropTypes.object,
  names: PropTypes.array,
  open: PropTypes.bool,
  fetchValues: PropTypes.func
};

export default EligibilityCriteriaModel;
