import axiosInstance from "../interceptor";
import { takeLatest, call, put } from "redux-saga/effects"; //takeEvery
import { actionTypes } from "../actionTypes/adminDashboard";
import { API_URL } from "../../config";
import { removeLoadingItem, setLoadingItem } from "../actionTypes/loader";

// function* getVendorStatsSaga(props) {
//   let url = `${API_URL}/vendor/stats`;
//   try {
//     const uri = url;
//     const result = yield call(axiosInstance.post, uri, props.payload);
//     yield put({
//       type: actionTypes.SET_VENDOR_STATS,
//       payload: result.data.statisticsData,
//     });
//   } catch {
//     console.log("Failed");
//   }
// }

function* getVendorStatsSaga(props) {
  let url = `${API_URL}/farmer/stats`;
  try {
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    yield put({
      type: actionTypes.SET_VENDOR_STATS,
      payload: result.data.statisticsData,
    });
  } catch {
    console.log("Failed");
  }
}


function* getSeniorsStatsSaga(props) {
  let url = `${API_URL}/senior/stats`;
  try { 
    yield put(setLoadingItem("get_senior_stats"));
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    yield put({
      type: actionTypes.SET_SENIOR_STATS,
      payload: result.data.statisticsData,
    });
  } catch {
    console.log("Failed");
  }finally{
    yield put(removeLoadingItem("get_senior_stats"));
  }
}

function* getSeniorsCountyStatsSaga(props) {
  let url = `${API_URL}/senior/county/stats`;
  try {
    yield put(setLoadingItem("get_senior_county"));
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    yield put({
      type: actionTypes.SET_SENIOR_COUNTY,
      payload: result.data.statisticsData,
    });
  } catch {
    console.log("Failed");
  } finally {
    yield put(removeLoadingItem("get_senior_county"));
  }
}

function* getMarketStatsSaga(props) {
  let url = `${API_URL}/marketCoordinator/stats`;
  try {
    yield put(setLoadingItem("get_market_stats"));
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    yield put({
      type: actionTypes.SET_MARKET_STATS,
      payload: result.data.statisticsData,
    });
  } catch {
    console.log("Failed");
  } finally {
    yield put(removeLoadingItem("get_market_stats"));
  }
}

function* getMarketCountyStatsSaga(props) {
  let url = `${API_URL}/marketcoordinator/county/stats`;
  try {
    yield put(setLoadingItem("get_market_county"));
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    yield put({
      type: actionTypes.SET_MARKET_COUNTY,
      payload: result.data.statisticsData,
    });
  } catch {
    console.log("Failed");
  } finally {
    yield put(removeLoadingItem("get_market_county"));
  }
}

// function* getStoreStatsSage(props) {
//   let url = `${API_URL}/store/stats`;
//   try {
//     yield put(setLoadingItem("get_store_stats"));
//     const uri = url;
//     const result = yield call(axiosInstance.post, uri, props.payload);
//     yield put({ type: actionTypes.SET_STORE_STATS, payload: result.data });
//   } catch {
//     console.log("Failed");
//   } finally {
//     yield put(removeLoadingItem("get_store_stats"));
//   }
// }

function* getStoreStatsSage(props) {
  let url = `${API_URL}/farmerStore/stats`;
  try {
    yield put(setLoadingItem("get_store_stats"));
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    yield put({ type: actionTypes.SET_STORE_STATS, payload: result.data });
  } catch {
    console.log("Failed");
  } finally {
    yield put(removeLoadingItem("get_store_stats"));
  }
}

export const watchGetVendorStatsSaga = function* () {
  yield takeLatest(actionTypes.GET_VENDOR_STATS, getVendorStatsSaga);
};

export const watchGetSeniorStatsSaga = function* () {
  yield takeLatest(actionTypes.GET_SENIOR_STATS, getSeniorsStatsSaga);
};

export const watchGetSeniorCountyStatsSaga = function* () {
  yield takeLatest(actionTypes.GET_SENIOR_COUNTY, getSeniorsCountyStatsSaga);
};

export const watchGetMarketStatsSaga = function* () {
  yield takeLatest(actionTypes.GET_MARKET_STATS, getMarketStatsSaga);
};

export const watchGetMarketCountyStatsSaga = function* () {
  yield takeLatest(actionTypes.GET_MARKET_COUNTY, getMarketCountyStatsSaga);
};

export const watchGetStoreStatsSage = function* () {
  yield takeLatest(actionTypes.GET_STORE_STATS, getStoreStatsSage);
};
