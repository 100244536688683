import React from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router";
import { Box, Stack } from "@mui/material";
import SideNavigation from "../../pages/senior/sidebar";
import styles from "./index.module.css";
function FarmerContainer() {
  return (
    // <Stack direction="row" sx={{}}>
    //   <Box sx={{ background: "#000" }}>
    //     <SideNavigation role="farmer" />
    //   </Box>
    //   <Outlet />
    // </Stack>
    <div className={styles.layout}>
      <div className={styles.leftSection}>
        <SideNavigation role={"farmer"} />
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
}

FarmerContainer.propTypes = {};

export default FarmerContainer;
<div className={styles.layout}>
  <div className={styles.leftSection}>
    <SideNavigation role={"senior"} />
  </div>
  <div className={styles.content}>
    <Outlet />
  </div>
</div>;
