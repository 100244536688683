import React, { useEffect } from "react";
import {
  Typography,
  Container,
  Grid,
  Box,
  Link,
  Paper,
  Divider,
} from "@mui/material";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import * as actionType from "../../../redux/actionTypes/csaSignup";
import { useState } from "react";
import CommonLoading from "../../common/components/commonLoading";
import { useSearchParams, useParams } from "react-router-dom";
import axios from "axios";
import { AUTH_API, headers } from "../../../config/index";
import useTranslator from "../../../hooks/useTranslator";

const ClientAgreement = (props) => {
  const transProps = useTranslator(
    [
      "spfSignup",
      "yourSpfSignup",
      "approval",
      "your",
      "agreementId",
      "youMayWant",
      "downloadYourAgreement",
      "forFutureReference",
      "farmAndSpf",
      "homePage",
    ],
    "clientAgreement"
  );
  const { state } = useLocation();
  const [docStream, setDocStream] = useState();
  let loginId = props?.userAuth?.user?.id;
  const [isLoggin] = useState(loginId ? true : false);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState("");
  const params = useParams();
  const _appNumber = params?.appNumber ?? "";
  console.log(_appNumber, "agree");

  const downloadAgreement = async () => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        AUTH_API + `/farmers/kfreshPdf/${_appNumber}`,
        {},

        {
          responseType: "blob",
        }
      );
      if (res && res.data) {
        const blob = new Blob([res.data], { type: "application/pdf" });
        console.log(res.data, "data");
        const pdfUrl = window.URL.createObjectURL(blob);
        window.open(pdfUrl, "_blank");
      }

      console.log(res, "form");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const agreementID = state?.appId;

  useEffect(() => {
    if (state?.appId) {
      props.getCSADoc(state);
      setIsLoading(true);
    }
  }, [state]);

  useEffect(() => {
    if (props.csaSignup?.csaDocument?.csaDocument?.stream) {
      setIsLoading(false);
      setDocStream(props.csaSignup?.csaDocument?.csaDocument?.stream);
    }
  }, [props.csaSignup?.csaDocument?.csaDocument]);

  const generatePDF = () => {
    var link = document.createElement("a");
    link.download = "csa_signup_agreement.pdf";
    link.href = `data:application/pdf;base64,${docStream}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box sx={{ height: "100vh" }}>
      {isLoading && <CommonLoading />}
      <Container item xs={12}>
        <Paper
          sx={{
            boxShadow: "1px 4px 8px 1px rgba(0, 0, 0, 0.1)",
            alignContent: "center",
            marginTop: "30px",
            marginBottom: "100px",
            padding: "20px",
            width: "65%",
            marginLeft: "200px",
          }}
        >
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                gutterBottom
                fontSize={"20px"}
                letterSpacing={"1px"}
              >
                {transProps.spfSignup}
              </Typography>
              <Divider
                sx={{
                  alignItems: "left",
                  borderWidth: 1,
                  backgroundColor: "#006e52",
                  width: "80px",
                  height: "3px",
                  marginLeft: "13px",
                  marginBottom: "20px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "24px",
                  color: "#006e52",
                  textAlign: "center",
                  display: "inline",
                  fontFamily: "Texta-Bold",
                  paddingBottom: "10px",
                  lineHeight: "1.5px",
                  margin: "15px",
                }}
              >
                {transProps.yourSpfSignup}
                <br />
                <span style={{ marginLeft: "16px" }}>
                  {" "}
                  {transProps.approval}
                </span>
              </Typography>
              <Typography
                gutterBottom
                margin={"15px"}
                fontFamily={"Texta-book"}
                fontSize={"20px"}
              >
                {transProps.your}{" "}
                <span style={{ fontFamily: "Texta-bold", fontSize: "20px" }}>
                  {transProps.agreementId} {_appNumber}
                </span>
              </Typography>
              <Typography
                margin={"15px"}
                fontFamily={"Texta-book"}
                fontSize={"20px"}
              >
                {transProps.youMayWant}{" "}
                <a
                  style={{ color: "#006e52", textDecorationLine: "none" }}
                  className="cursor-pointer"
                  onClick={downloadAgreement}
                >
                  {transProps.downloadYourAgreement}
                </a>{" "}
                {transProps.forFutureReference}
              </Typography>

              <Typography
                variant="body1"
                gutterBottom
                margin={"15px"}
                fontFamily={"Texta-book"}
                fontSize={"20px"}
              >
                {transProps.farmAndSpf}
              </Typography>
            </Grid>
            <Grid item xs={12} margin={"15px"}>
              {isLoggin ? (
                <Link
                  href="/seniorDashboard"
                  sx={{ color: "#006e52", textDecorationColor: "#006e52" }}
                >
                  {transProps.homePage}
                </Link>
              ) : (
                <Link
                  href="/seniorDashboard"
                  sx={{ color: "#006e52", textDecorationColor: "#006e52" }}
                >
                  {" "}
                  {transProps.homePage}
                </Link>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

ClientAgreement.propTypes = {
  getCSADoc: PropTypes.func,
  csaSignup: PropTypes.object,
  userAuth: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    getCSADoc: (payload) => dispatch(actionType.getCSADoc(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientAgreement);
