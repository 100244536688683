import React, { useEffect, useMemo, useState } from "react";
import axiosInstance from "../../redux/interceptor";
import { Stack, TablePagination, Typography } from "@mui/material";
import { filter, includes } from "lodash";
import { popUpAlert } from "../../utils/globalMethods";
import SearchComponent from "../common/components/searchComponent";
import CommonLoading from "../common/components/commonLoading";
import useLoader from "../../hooks/useLoader";
import { Tab, Tabs } from "react-bootstrap";
import SeniorList from "./SeniorList";

function SeniorWaitingList() {
  const { openLoader, closeLoader } = useLoader();
  const [isLoading, setIsLoading] = useState(false);
  const [listCount, setListCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [seniorWaitingList, setSeniorWaitingList] = useState([]);
  const [activeTab, setActiveTab] = useState("WL");
  const [search, setSearch] = useState("");

  const sortedSeniors = useMemo(() => {
    return filter(seniorWaitingList, ({ appNumber, appStatus }) => {
      return search
        ? includes(appNumber?.toLowerCase(), search.toLowerCase())
        : true && appStatus === activeTab;
    });
  }, [search, seniorWaitingList, activeTab]);

  const fetchSeniorsWaitingList = async (appStatus) => {
    try {
      openLoader("senior_wait_list");
      const res = await axiosInstance.post(
        `${
          process.env.REACT_APP_BASE_URL
        }/staff/dashboard?size=${perPage}&page=${page - 1}`,
        {
          appStatus,
        }
      );
      if (res.status === 200) {
        setSeniorWaitingList(res.data?.content?.[0]?.applicationModel);
        setListCount(res.data?.totalElements);
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("senior_wait_list");
    }
  };

  useEffect(() => {
    fetchSeniorsWaitingList(activeTab);
  }, [activeTab, page]);

  const submitApproval = async (appNumber, status) => {
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/senior/update/application/${appNumber}/${status}`,
        {
          data: {},
        }
      );
      if (res.status === 200) {
        fetchSeniorsWaitingList(activeTab);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onApprove = (appNumber) => {
    popUpAlert({
      title: "Are you sure?",
      text: "Do you want to approve this application?",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, approve it",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => submitApproval(appNumber, "AP"),
    });
  };

  const onReject = (appNumber) => {
    popUpAlert({
      title: "Are you sure?",
      text: "Do you want to reject this application?",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, reject it!",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => submitApproval(appNumber, "DE"),
    });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Stack sx={{ width: "100%", p: "1rem" }}>
      {isLoading && <CommonLoading />}

      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <Typography
          variant="h5"
          display="inline"
          className="resText text-capitalize"
          gutterBottom
          component="div"
        >
          Senior
        </Typography>
        <SearchComponent
          placeholder={"Application Number"}
          handleChange={handleSearch}
          searchText={search}
        />
      </Stack>
      <Tabs
        id="controlled-tab"
        activeKey={activeTab}
        onSelect={setActiveTab}
        style={{ background: "#fff" }}
      >
        <Tab
          eventKey="WL"
          title={
            <div className="tab-header">
              <i className="fa fa-hourglass-end"></i>
              <span className="m-l-5">Waitlisted</span>
            </div>
          }
        >
          <div className="tab-content-wrapper">
            <SeniorList
              seniors={sortedSeniors}
              onApprove={onApprove}
              onReject={onReject}
              isLoading={isLoading}
            />
          </div>
        </Tab>
        <Tab
          eventKey="AP"
          title={
            <div className="tab-header">
              <i className="fa fa-check-square-o"></i>
              <span className="m-l-5">Approved</span>
            </div>
          }
        >
          <div className="tab-content-wrapper">
            <SeniorList
              seniors={sortedSeniors}
              onApprove={onApprove}
              onReject={onReject}
              isLoading={isLoading}
            />
          </div>
        </Tab>
        <Tab
          eventKey="DE"
          title={
            <div className="tab-header">
              <i className="fa fa-ban"></i>
              <span className="m-l-5">Denied</span>
            </div>
          }
        >
          <div className="tab-content-wrapper">
            <SeniorList
              seniors={sortedSeniors}
              onApprove={onApprove}
              onReject={onReject}
              isLoading={isLoading}
            />
          </div>
        </Tab>
      </Tabs>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={listCount}
        rowsPerPage={perPage}
        page={Math.floor(page-1)}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={(e) => setPerPage(+e.target.value)}
        className={"paginationBlock"}
      />
    </Stack>
  );
}

export default SeniorWaitingList;
