import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
  Grid,
  Button,
  Typography, Box,
} from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ErrorIcon from '@mui/icons-material/Error';
import { Form, Card } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { isNumber, sortName } from "../../../utils/globalMethods";
import { GET_COUNTYCITY } from '../../../redux/actionTypes';
// import SelectDropdown from "../../common/selectdropdown-component";
import { daysOfOperation } from "../../../constants/constants";
import SelectDropdown from "../../../components/SelectDropdown";
import useTranslator from "../../../hooks/useTranslator";

function PickupDeliveryComponent(props) {
  const transProps = useTranslator(
    [
      "pageTitle",
      "share",
      "pickupLocation",
      "deliveryLocation",
      "pleaseChoose",
      "choosepickupLocation",
      "select",
      "pickUp",
      "pickUpTime",
      "pleaseEnter",
      "addressLine1",
      "addressLine2",
      "city",
      "state",
      "ma",
      "zipCode",
      "prev",
      "next",
    ],
    "pickupDelivery"
  );
  // eslint-disable-next-line no-unused-vars
  const [csaShareList, setCsaShareList] = useState([]);
  const [selectedSharePick, setSelectedSharePickup] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  // console.log("addressssssssss",selectedAddress);
  const [cityList, setCityList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedCity, setSelectedCity] = useState({
    id: "",
    label: "--Select--",
  });
  const [pickupDetails, setPickupDetails] = useState();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  const [selectedPickupTime, setSelectedPickupTime] = useState();

  const [isError, setIsError] = useState(false);
  // console.log("iserrorrrrrrrrr",isError);

  const formData = watch();

  useEffect(() => {
    if (props.submitPickupData) {
      if (Object.keys(props.submitPickupData).length > 0) {
        delete props.submitPickupData.pickup_delivery;
        Object.keys(props.submitPickupData).forEach((key, i) => {
          setValue(`${key}.location`, props.submitPickupData[key].location);
          if (props.submitPickupData[key].location === "delivery") {
            setValue(
              `pickup_delivery${i}.addressLine1`,
              props.submitPickupData[key].addressLine1
            );
            setValue(
              `pickup_delivery${i}.addressLine2`,
              props.submitPickupData[key].addressLine2
            );
            setValue(
              `pickup_delivery${i}.city`,
              props.submitPickupData[key].city
            );
            setValue(
              `pickup_delivery${i}.state`,
              props.submitPickupData[key].state
            );
            setValue(
              `pickup_delivery${i}.zipcode`,
              props.submitPickupData[key].zipcode
            );
            setValue(
              `pickup_delivery${i}.shareId`,
              props.submitPickupData[key].shareId
            );
          }
        });
      }
    }
  }, [props.submitPickupData]);

  useEffect(() => {
    setPickupDetails(pickupDetails);
  }, [[props.pickupDetails]]);

  useEffect(() => {
    if (props.selectedAddress) {
      setSelectedAddress(props.selectedAddress);
      console.log("loggggggg", props.selectedAddress);
    }
  }, [props.selectedAddress]);

  const handleButtonClick = (data) => {
    const isPickupSelected = selectedSharePick.some((item, i) => {
      return getValues(`pickup_delivery${i}.location`) === "pickup";
    });
    if (selectedAddress === undefined && isPickupSelected) {
      setIsError(true);
      return;
    } else {
      setIsError(false);
      props.setSubmitPickupData(data);
      submitPickupForm(data);
      props.setActiveTab("menuStep5");
    }
  };

  const submitPickupForm = () => {
    if (formData) {
      delete formData.pickup_delivery;
      const pickupData = Object.keys(formData).map((item) => formData[item]);

      const pickupInfo = pickupData.map((addressData) => {
        if (addressData.location == "pickup") {
          addressData.address = selectedPickupTime[addressData.shareId];
        }
        return addressData;
      });
      props.setPickupDetails(pickupInfo);
    }
    props.setSelectedAddress(selectedAddress);
    props.setCSAFormValidate((prevState) => ({
      ...prevState,
      pickup_del: true,
    }));
  };

  const handleChange = (item, e) => {
    setIsError(false);
    const selectedValue = e.target.value;
    if (isValidJSON(selectedValue)) {
      try {
        setSelectedAddress((prevState) => ({
          ...prevState,
          [item.id]: JSON.parse(selectedValue),
        }));
      } catch (error) {
        console.error("JSON parsing error:", error);
      }
    } else {
      // Handle the case when the value is not valid JSON (e.g., "--Select--")
    }
  };
  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    props.getCityList();
  }, []);

  useEffect(() => {
    let cityOption = props.mapdata.city.map((item) => {
      return {
        id: item.id,
        label: item.cityName,
        isActive: 1,
      };
    });
    let defaultData = [
      { id: " ", label: "Select City", isActive: 1, default: true },
    ];
    setCityList(defaultData.concat(sortName(cityOption, "label")));
  }, [props.mapdata.city]);

  const filterDay = (dayValue) => {
    let tempData = daysOfOperation.filter((data) => data.weekDay == dayValue);
    return tempData[0].values;
  };
  useEffect(() => {
    setCsaShareList(
      Object.keys(props.csaSignup?.shareList?.shareList).length
        ? props.csaSignup?.shareList?.shareList
        : {}
    );
    const objectId = Object.keys(props?.selectedShare).filter(
      (k) => props?.selectedShare[k] === true
    );
    const shareListLoc = props?.csaSignup?.shareList?.shareList;
    let foundId = [];
    objectId.forEach((item) => {
      foundId.push(shareListLoc.find((objId) => objId.id === parseInt(item)));
    });
    if (foundId && foundId.length) {
      foundId = foundId.filter((item) => item?.id);
      setSelectedSharePickup(foundId);
    } else {
      setSelectedSharePickup([]);
    }
  }, [props.csaSignup?.shareList?.shareList]);

  const groupByPickupDtl = (address) => {
    const res = address.reduce((acc, curr) => {
      if (!acc[curr.addressId]) acc[curr.addressId] = []; //If this type wasn't previously stored
      acc[curr.addressId].push(curr);
      return acc;
    }, {});
    return res;
  };

  // const getDataList = (data) => {
  //   const dataList = [];
  //   Object.keys(data)?.forEach((address, i) => {
  //     const obj = {};
  //     obj.id =  data[address][0].addressId.toString(); //JSON.stringify(data[address]);
  //     obj.label = data[address][0].address1;
  //     obj.isActive = 1;
  //     dataList.push(obj);
  //   });
  //   return dataList;
  // };

  const handlePickupTime = (pickupTime, shareId) => {
    const pckTim = { ...pickupTime };
    pckTim.day = daysOfOperation.filter(
      (data) => data.weekDay == pckTim.day
    )[0].values;
    setSelectedPickupTime((prevState) => ({
      ...prevState,
      [shareId]: pckTim,
    }));
  };

  return (
    <Form onSubmit={handleSubmit(handleButtonClick)}>
      <Grid
        item
        lg={12}
        md={12}
        ml={4}
        className="heading-grid"
        sx={{ background: "#FFFF" }}
      >
        <Box
          sx={{ backgroundColor: "#FFFF", borderBottom: "1px solid #CCCCCC" }}
        >
          <Typography
            variant="h5"
            xs={{ fontFamily: "texta-bold" }}
            className="sub-heading"
          >
            {transProps.pageTitle}
          </Typography>
        </Box>
        {selectedSharePick &&
          selectedSharePick?.length &&
          selectedSharePick.map((item, i) => {
            return (
              <div className="container" key={item.id} id="pickup-delivery">
                <div className="pickuplocation-card">
                  <Row className="m-2">
                    <Col>
                      <h5 className="pickup-share">
                        {transProps.share} :{" "}
                        <span
                          className="pickup-share"
                          style={{ color: "#457425" }}
                        >
                          ${item.shareCost} - {item.nam}
                        </span>{" "}
                      </h5>
                      {/* <hr style={{ borderWidth: 1, backgroundColor: '#006e52', width: '70px', height: '3px', marginLeft: '15px', marginTop: '-10px' }} /> */}
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      marginLeft: "3px",
                      marginBottom: "5px",
                    }}
                  >
                    <Form.Control
                      {...register(`pickup_delivery${i}.shareId`)}
                      value={item?.id}
                      type="text"
                      style={{ display: "none" }}
                    />
                    <Col md={4}>
                      <div className="d-flex align-items-start">
                        <Form.Check
                          inline
                          className="custom-radio"
                          name={`pickup_delivery${i}.location`}
                          id={`pickup${i}.location`}
                          type="radio"
                          value={"pickup"}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setValue(
                                `pickup_delivery${i}.location`,
                                "pickup"
                              );
                            }
                          }}
                          {...register(`pickup_delivery${i}.location`, {
                            required: "Please select the location type",
                          })}
                          // label={"Pickup Location"}
                        />
                        <label
                          htmlFor={`pickup${i}.location`}
                          className="ml-2"
                          style={{ fontSize: "20px" }}
                        >
                          {transProps.pickupLocation}
                        </label>
                      </div>
                    </Col>
                    <Col md={8}>
                      {item?.dlvryFlg === "Y" ? (
                        <div className="d-flex align-items-start">
                          <Form.Check
                            inline
                            className="custom-radio "
                            name={`pickup_delivery${i}.location`}
                            id={`delivery${i}.location`}
                            value={"delivery"}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setValue(
                                  `pickup_delivery${i}.location`,
                                  "delivery"
                                );
                              }
                            }}
                            {...register(`pickup_delivery${i}.location`, {
                              required: "Please select the location type",
                            })}
                            type="radio"
                            // label={`Delivery Location (Delivery Range-${item?.range})`}
                          />{" "}
                          <label
                            htmlFor={`delivery${i}.location`}
                            className="ml-2"
                            style={{ fontSize: "20px" }}
                          >
                            {transProps.deliveryLocation}-{item?.range})
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>

                  {getValues(`pickup_delivery${i}.location`) === "pickup" && (
                    <Card className="pickuplocation-card-two">
                      {isError && (
                        <Typography
                          component="span"
                          className="dh-txt-danger"
                          sx={{
                            fontSize: "20px",
                            marginLeft: "18px",
                            fontFamily: "texta-bold",
                          }}
                        >
                          <i className="fa fa-info-circle"></i>
                          {transProps.pleaseChoose}
                        </Typography>
                      )}
                      <Row>
                        <Col>
                          <Form.Label className="choosepickup">
                            {transProps.choosepickupLocation}{" "}
                            <span className="mandatory-star">*</span>
                          </Form.Label>
                          <Form.Select
                            className="select"
                            placeholder="Select Pickup Location"
                            {...register(`pickup_delivery${i}.address`, {
                              required:
                                getValues(`pickup_delivery${i}.location`) ===
                                "pickup",
                            })}
                            value={JSON.stringify(selectedAddress?.[item?.id])}
                            onChange={(e) => handleChange(item, e)}
                          >
                            <option
                              className="font-weight-bold"
                              style={{ fontSize: "20px" }}
                            >
                              --{transProps.select}--
                            </option>
                            {item.pickupDetails.length > 0 &&
                              Object.keys(
                                groupByPickupDtl(item.pickupDetails)
                              )?.map((address, j) => (
                                <option
                                  className="font-weight-bold"
                                  style={{ fontSize: "20px" }}
                                  key={j}
                                  value={JSON.stringify(
                                    groupByPickupDtl(item.pickupDetails)[
                                      address
                                    ]
                                  )}
                                >
                                  {
                                    groupByPickupDtl(item.pickupDetails)[
                                      address
                                    ][0].address1
                                  }
                                </option>
                              ))}
                          </Form.Select>
                          {/* <div className="select">
                        <SelectDropdown
                          {...register(`pickup_delivery${i}.address`, { required: getValues(`pickup_delivery${i}.location`) ==="pickup" })}
                          label={"---Select---"}                        
                          className='select'
                          defaultValue={selectedAddress?.[item?.id]?.[0]?.addressId ? selectedAddress?.[item?.id]?.[0]?.addressId.toString() : ""}
                          onChange={e => {
                            console.log(item);
                            console.log(e);
                            handleChange(item, e);
                          }}
                          dataList={item.pickupDetails.length > 0 ? getDataList(groupByPickupDtl(item.pickupDetails)) : []}
                        />
                      </div> */}
                        </Col>
                      </Row>
                      {selectedAddress?.[item?.id] && (
                        <>
                          {errors[`pickup_delivery.${item?.id}.time`] && (
                            <Typography
                              style={{
                                color: "#dc3545",
                                fontFamily: "Texta-book",
                                fontSize: "20px",
                                fontWeight: 400,
                                lineHeight: "1.5",
                              }}
                            >
                              <ErrorIcon /> {transProps.pickUp}
                            </Typography>
                          )}
                          <Row>
                            <Col>
                              <p className="pickuptime">
                                {transProps.pickUpTime}{" "}
                                <span className="mandatory-star">*</span>
                              </p>
                            </Col>
                          </Row>
                          {selectedAddress?.[item?.id].length &&
                            selectedAddress?.[item?.id].map((address, k) => (
                              <Row key={k}>
                                <Col style={{ display: "flex" }}>
                                  <Col
                                    style={{
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                      marginLeft: "30px",
                                      display: "flex",
                                    }}
                                  >
                                    <Form.Check
                                      className="custom-radio"
                                      id={`pickup_delivery${i}.${k}.time`}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      onClick={() =>
                                        handlePickupTime(address, item?.id)
                                      }
                                      {...register(`pickup_delivery${i}.time`, {
                                        required:
                                          getValues(
                                            `pickup_delivery${i}.location`
                                          ) === "pickup",
                                      })}
                                      type="radio"
                                      label={
                                        filterDay(address.day) +
                                        "  " +
                                        address?.openTime +
                                        "-" +
                                        address?.closeTime
                                      }
                                    />
                                    {/* <p className="day">{selectedAddress?.[item?.id].day}</p> */}
                                  </Col>
                                  {/* <Col>
                              <p className="time">{selectedAddress?.[item?.id]?.openTime}-{selectedAddress?.[item?.id]?.closeTime}</p>
                            </Col> */}
                                </Col>
                              </Row>
                            ))}
                        </>
                      )}
                    </Card>
                  )}
                  {/* {getValues(`pickup_delivery${i}.location`) === "delivery" && ( */}
                  <Card
                    className="deliverylocation"
                    style={{
                      display:
                        getValues(`pickup_delivery${i}.location`) === "delivery"
                          ? "block"
                          : "none",
                    }}
                  >
                    {/* <Row className="m-2">
                    <Col>
                      <h5 className="delivery-share">SHARE :<span className="pickup-share" style={{ color: '#457425', textTransform:"capitalize" }}>{item?.nam}</span> </h5>
                      <hr style={{ borderWidth: 1, backgroundColor: '#006e52', width: '70px', height: '3px', marginLeft: '-19px', marginTop: '-10px' }} />
                    </Col>
                  </Row> */}
                    {errors && errors[`pickup_delivery${i}.addressLine1`] && (
                      <Typography
                        component="span"
                        className="dh-txt-danger"
                        sx={{ fontSize: "18px", marginLeft: "18px" }}
                      >
                        <i className="fa fa-info-circle"></i>{" "}
                        {transProps.pleaseEnter}
                      </Typography>
                    )}
                    <Row className="mt-2">
                      <Col md={6}>
                        <Form.Label className="lastName ">
                          {transProps.addressLine1}{" "}
                          <span className="mandatory-star">*</span>
                        </Form.Label>
                        <Form.Control
                          // className='addressinput'
                          {...register(`pickup_delivery${i}.addressLine1`, {
                            required:
                              getValues(`pickup_delivery${i}.location`) ===
                              "delivery"
                                ? true
                                : false,
                          })}
                          type="text"
                          style={{ height: "36px" }}
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Label className="lastName ">
                          {transProps.addressLine2}{" "}
                          <span className="mandatory-star">*</span>
                        </Form.Label>
                        <Form.Control
                          // className='addressinput'
                          {...register(`pickup_delivery${i}.addressLine2`, {
                            required:
                              getValues(`pickup_delivery${i}.location`) ===
                              "delivery"
                                ? true
                                : false,
                          })}
                          type="text"
                          style={{ height: "36px" }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col mt={6}>
                        <Form.Label className="lastName ">
                          {transProps.city}{" "}
                          <span className="mandatory-star">*</span>
                        </Form.Label>
                        <SelectDropdown
                          label={"City"}
                          // placeholder="County/City"
                          defaultValue={getValues(`pickup_delivery${i}.city`)}
                          {...register(`pickup_delivery${i}.city`, {
                            required:
                              getValues(`pickup_delivery${i}.location`) ===
                              "delivery"
                                ? true
                                : false,
                          })}
                          onChange={(v) => {
                            setSelectedCity((prevState) => ({
                              ...prevState,
                              [`pickup_delivery${i}.city`]: v,
                            }));
                            setValue(`pickup_delivery${i}.city`, v);
                          }}
                          dataList={cityList}
                          // isSearch={true}
                        />
                      </Col>
                      <Col mt={6}>
                        <Form.Label className="lastName ">
                          {transProps.state}{" "}
                          <span className="mandatory-star">*</span>
                        </Form.Label>
                        <Form.Select
                          className="deliveryselect"
                          defaultValue={"MA"}
                          {...register(`pickup_delivery${i}.state`)}
                          aria-readonly
                        >
                          <option
                            className="font-weight-bold"
                            value={"MA"}
                            style={{ fontSize: "20px" }}
                          >
                            {transProps.ma}
                          </option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={6}>
                        <Form.Label className="zipCode ">
                          {transProps.zipCode}{" "}
                          <span className="mandatory-star">*</span>
                        </Form.Label>
                        <Form.Control
                          {...register(`pickup_delivery${i}.zipcode`, {
                            required:
                              getValues(`pickup_delivery${i}.location`) ===
                              "delivery"
                                ? true
                                : false,
                            pattern: {
                              value: /^(\d{5})$/,
                              message: "Zipcode should be 5 digits",
                            },
                          })}
                          maxLength={5}
                          onKeyDown={isNumber}
                          // inputProps={{ min: 1, maxLength: 5 }}
                          style={{ height: "36px" }}
                        />
                      </Col>
                    </Row>
                  </Card>
                  {/* )}  */}
                </div>
              </div>
            );
          })}
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{ borderTop: "none", height: "80px" }}
        >
          <Grid style={{ margin: "10px" }}>
            <Button
              onClick={() => {
                props.setActiveTab("menuStep3");
                submitPickupForm(getValues());
                props.setSubmitPickupData(getValues());
              }}
              sx={{
                backgroundColor: "#ffffff",
                color: "#457425",
                height: "50px",
                width: "100px",
                borderColor: "#457425",
                border: "solid",
                borderWidth: "1px",
                "&:hover": {
                  backgroundColor: "#457425",
                  color: "#ffffff",
                },
                "&:active": {
                  backgroundColor: "#457425",
                  color: "#ffffff",
                },
              }}
            >
              <ArrowBackIosNewIcon /> {transProps.prev}
            </Button>
            <Button
              sx={{
                backgroundColor: "#457425 !important",
                color: "white",
                height: "50px",
                width: "100px",
                marginLeft: "10px",
              }}
              type="submit"
              value="Submit"
            >
              {" "}
              {transProps.next} <ArrowForwardIosIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}
PickupDeliveryComponent.propTypes = {
  setActiveTab: PropTypes.func.isRequired,
  csaSignup: PropTypes.object,
  selectedShare: PropTypes.object,
  setPickupDetails: PropTypes.func,
  setCSAFormValidate: PropTypes.func.isRequired,
  getCityList: PropTypes.func.isRequired,
  mapdata: PropTypes.object,
  selectedAddress: PropTypes.object,
  setSelectedAddress: PropTypes.func,
  pickupDetails: PropTypes.object,
  setSubmitPickupData: PropTypes.func,
  submitPickupData: PropTypes.object
}
const mapDispatchToProps = (dispatch) => {
  return {
    getCityList: (city_county) => dispatch({ type: GET_COUNTYCITY, city: city_county }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PickupDeliveryComponent);
