import React, { useRef, useState, useEffect, useMemo } from "react";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DocumentUploadForm from "./FileForm";
import "../../styles/fileUpload.scss";
import PropTypes from "prop-types";

const FileUploadModal = ({ setDocOpen, docOpen, payload, section }) => {
  const descriptionElementRef = useRef("");
  const [docmentFiles, setDocmentFiles] = useState([]);

  const handleClose = () => {
    setDocOpen(false);
  };
  return (
    <>
      <Dialog
        open={docOpen}
        maxWidth="md"
        fullWidth
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="document"
        aria-describedby="documentDescription"
        sx={{ paddingBottom: "50px" }}
      >
        <DialogTitle id="document">Add Document</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute !important",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText
            id="documentDescription"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* Form */}
            <DocumentUploadForm
              payload={payload}
              docOpen={docOpen}
              setDocOpen={setDocOpen}
              docmentFiles={docmentFiles}
              section={section}
            />
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions sx={{ position: "relative" }}>
          <Button
            sx={{ position: "absolute", top: "50%", right: "5%" }}
            className="okay-btn"
            variant="contained"
            onClick={handleClose}
            // sx={{ mr: "2rem", width: "6rem" }}
          >
            OK
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

FileUploadModal.propTypes = {
  setDocOpen: PropTypes.bool,
  docOpen: PropTypes.bool,
  payload: PropTypes.object,
  section: PropTypes.object,
};

export default FileUploadModal;
