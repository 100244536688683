import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import CloseIcon from "@mui/icons-material/Close";
import "./add-signature.css";
import React, { useEffect, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import DrawComponent from "./draw-sign/drawComponent";
import { styled } from "@mui/material/styles";
import FontSign from "./font-sign/fontSign";
import UploadSign from "./upload-sign/UploadSign";
import axios from "axios";
import Icon from "./draw-sign/drawStyle";
import { noop } from "lodash";
import useTranslator from "../../hooks/useTranslator";
export default function SignaturePad({ open, onClose, setOpenSignatureModal }) {
  const transProps = useTranslator(
    [
      "pageTitle",
      "drawSignature",
      "typeText",
      "uploadPhoto",
      "color",
      "signature",
      "clearSignature",
      "discard",
      "addSignature",
    ],
    "signature"
  );
  // const [open, setOpen] = useState(false);
  const [minMax, setMinMax] = useState("md");
  const [sign, setSign] = useState(0);
  const [getWidth, setGetWidth] = useState(window.innerWidth);
  const [color, setColor] = useState("black");
  const [drawSign, setDrawSign] = useState("");
  const [fontData, setFontData] = useState("");
  const [uploadData, setUploadData] = useState("");
  const [clear, setClear] = useState(false);

  // const [color, setColor] = useState("black");

  useEffect(() => {
    window.addEventListener("resize", () => setGetWidth(window.innerWidth));
  }, []);

  const clickHandler = (event) => {
    setSign(event);
  };

  const onClear = () => {
    if (clear) {
      setClear(false);
    } else {
      setClear(true);
    }
  };

  const arr = [
    transProps.drawSignature,
    transProps.typeText,
    transProps.uploadPhoto,
  ];

  const addSign = () => {
    if (sign === 0) {
      onClose(drawSign);
    }
    if (sign === 1) {
      onClose(fontData);
    }
    if (sign === 2) {
      //console.log(uploadData);
    }
  };

  const minmax = () => {
    // setOpen(true);
    // const head = {
    //   method: "GET",
    //   path: "/eeapi/application-process/screening/300000249/selected-program",
    //   scheme: "https",
    //   Accept: "application/json, text/plain",
    //   "Accept-Encoding": "gzip, deflate, br",
    //   Caseid: "300000249",
    //   Role: "cn=EE_SUPVRS,|cn=EE_SUPUSR, |cn=EE_VEND_ADMN",
    // };
    // const requestOptions = {
    //   method: "GET",
    //   headers: {
    //     path: "/eeapi/application-process/screening/300000249/selected-program",
    //     scheme: "https",
    //     Accept: "application/json, text/plain",
    //     "Accept-Encoding": "gzip, deflate, br",
    //     Caseid: "300000249",
    //     Role: "cn=EE_SUPVRS,|cn=EE_SUPUSR, |cn=EE_VEND_ADMN",
    //   },
    // };
    // fetch(
    //   "https://d2p63rrw2kpvft.cloudfront.net/eeapi/application-process/screening/300000249/selected-program",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((val) => {
    //     console.log(val);
    //   });
    // axios
    //   .get(
    //     "https://d2p63rrw2kpvft.cloudfront.net/eeapi/application-process/screening/300000249/selected-program",
    //     {
    //       headers: {
    //         method: "GET",
    //         path: "/eeapi/application-process/screening/300000249/selected-program",
    //         scheme: "https",
    //         Accept: "application/json, text/plain",
    //         "Accept-Encoding": "gzip, deflate, br",
    //         Caseid: "300000249",
    //         Role: "cn=EE_SUPVRS,|cn=EE_SUPUSR, |cn=EE_VEND_ADMN",
    //       },
    //     }
    //   )
    // .then((val) => {
    //   console.log(val);
    // });
    // switch (minMax) {
    //   case "sm":
    //     setMinMax("md");
    //     break;
    //   case "md":
    //     setMinMax("sm");
    //     break;
    //   default:
    // }
  };

  return (
    <div>
      <Grid>
        <Dialog open={open} maxWidth={"xl"} onClose={onClose}>
          <Grid margin={2}>
            <Grid
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                container
                margin={1}
                sx={{ whiteSpace: "noWrap", fontWeight: 500, fontSize: "24px" }}
              >
                <span>{transProps.pageTitle}</span>
              </Grid>
              <Grid display="flex" alignItems="flex-end" margin={1}>
                {/* <Grid sx={{ cursor: "pointer" }}>
                  <Tooltip title="Max">
                    <div onClick={minmax}>
                      <CloseFullscreenIcon />
                    </div>
                  </Tooltip>
                </Grid> */}
                <Grid sx={{ cursor: "pointer" }}>
                  <Tooltip title="Close">
                    <div onClick={() => setOpenSignatureModal(false)}>
                      <CloseIcon />
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              display="flex"
              margin={1}
              border={1}
              padding={1}
              borderRadius={1}
            >
              <Grid className="leftSignBar" marginRight={1}>
                {arr.map((val, index) => (
                  <div
                    key={index}
                    onClick={() => clickHandler(index)}
                    className={
                      index === sign ? "signCategory select" : "signCategory"
                    }
                  >
                    <div>
                      <span>{val}</span>
                    </div>
                  </div>
                ))}
              </Grid>
              <Grid className="signContent">
                <Grid className="scrollContent">
                  <Grid margin={2}>{transProps.signature}</Grid>

                  <Grid className={"contentCard"}>
                    {sign === 0 && (
                      <Grid
                        marginLeft={2}
                        marginRight={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid>
                          <DrawComponent
                            color={color}
                            clear={clear}
                            setDrawSign={setDrawSign}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {sign === 1 && (
                      <Grid
                        marginLeft={2}
                        marginRight={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid>
                          <FontSign
                            color={color}
                            clear={clear}
                            setFontData={setFontData}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {sign === 2 && (
                      <Grid
                        marginLeft={2}
                        marginRight={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <UploadSign
                          setUploadData={setUploadData}
                          clear={clear}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  marginLeft={2}
                  marginTop={1}
                  marginBottom={1}
                  marginRight={2}
                  display="flex"
                  justifyContent={sign !== 2 ? "space-between" : "flex-end"}
                  alignItems="center"
                >
                  {sign !== 2 && (
                    <Grid item container direction="row">
                      <Grid>{transProps.color} </Grid>
                      <Grid sx={{ cursor: "pointer" }} color={"black"}>
                        <div onClick={() => setColor("black")}>
                          <CircleIcon />
                        </div>
                      </Grid>
                      <Grid
                        sx={{ cursor: "pointer" }}
                        color="rgb(68, 160, 227)"
                      >
                        <div onClick={() => setColor("rgb(68, 160, 227)")}>
                          <CircleIcon />
                        </div>
                      </Grid>
                      <Grid sx={{ cursor: "pointer" }} color="red">
                        <div onClick={() => setColor("red")}>
                          <CircleIcon />
                        </div>
                      </Grid>
                      <Grid sx={{ cursor: "pointer" }} color="green">
                        <div onClick={() => setColor("green")}>
                          <CircleIcon />
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  <Grid>
                    <Button
                      sx={{ whiteSpace: "nowrap" }}
                      variant="contained"
                      size="small"
                      onClick={onClear}
                    >
                      {sign === 1
                        ? "Change Signature"
                        : transProps.clearSignature}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid marginRight={1}>
                <Button
                  size="small"
                  onClick={() => setOpenSignatureModal(false)}
                  variant="outlined"
                >
                  {transProps.discard}
                </Button>
              </Grid>
              <Grid marginRight={1}>
                <Button
                  sx={{
                    backgroundColor: "#0f4e96",
                  }}
                  variant="contained"
                  size="small"
                  onClick={addSign}
                >
                  {transProps.addSignature}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </Grid>
    </div>
  );
}

SignaturePad.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setOpenSignatureModal: PropTypes.func,
};

SignaturePad.defaultProps = {
  open: false,
  onClose: noop,
};
