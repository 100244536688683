import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import { sortName } from "../../../utils/globalMethods";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { daysOfOperation } from "../../../constants/constants";

const ShareInfoDetails = ({ row, editLang, deleteLang }) => {
  const [locations, setLocations] = useState({});

  const filterDay = (dayValue) => {
    let tempData = daysOfOperation.filter((data) => data.weekDay == dayValue);
    return tempData[0].values;
  };
  useEffect(() => {
    let data = {};
    sortName(row.pickupDetails, "day").map((item) => {
      if (item.addressId in data) {
        data[item.addressId]["days"].push(
          " " +
            filterDay(item.day) +
            ", " +
            item.openTime +
            " - " +
            item.closeTime
        );
      } else {
        data[item.addressId] = {};
        data[item.addressId]["days"] = [];
        data[item.addressId]["days"].push(
          " " +
            filterDay(item.day) +
            ", " +
            item.openTime +
            " - " +
            item.closeTime
        );
        data[item.addressId]["location"] =
          item.address1 +
          ", " +
          (item.address2 ? item.address2 + ", " : "") +
          item.city +
          ", " +
          item.state +
          ", " +
          item.zipcode;
      }
    });
    setLocations(data);
  }, [row]);

  return (
    <Grid container className="collapsibleTable" spacing={2}>
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom component="div">
          Description
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.dsc ? row.dsc : "-"}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom component="div">
          Weight/Amount Of Goods
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.shareQty ? row.shareQty : "-"}{" "}
          {row.shareQtyUnit == "qty" ? "Units" : "Pounds"}
        </Typography>
      </Grid>
      {row?.dlvryFlg === "Y" && (
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom component="div">
            Delivery Range
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {row.range ? row.range : "-"}
          </Typography>
        </Grid>
      )}
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom component="div">
          Pickup Details
        </Typography>
        {Object.keys(locations).map((key) => {
          return (
            <>
              <Typography variant="subtitle1" gutterBottom>
                {locations[key]["location"]}
              </Typography>
              {locations[key]["days"].map((item) => {
                return (
                  <Typography variant="subtitle1" key={item} gutterBottom>
                    <AccessTimeRoundedIcon fontSize="small" />
                    {item}
                  </Typography>
                );
              })}
            </>
          );
        })}
      </Grid>
      {row.multiLang.length != 0 && (
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom component="div">
            Other Language
          </Typography>
          {row.multiLang.map((item) => {
            return (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  <b>
                    {item.lang == "es"
                      ? "Share Details - Spanish"
                      : "Share Details - Portuguese"}
                  </b>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => editLang(item)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => deleteLang(item)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Typography>
                <Typography variant="subtitle1" key={item} gutterBottom>
                  <b>Share Name: </b>
                  {item.nam}
                </Typography>
                <Typography variant="subtitle1" key={item} gutterBottom>
                  {row.dlvryFlg == "Y" && (
                    <span>
                      <b>Range: </b>
                      {item.range}
                    </span>
                  )}
                </Typography>
                <Typography variant="subtitle1" key={item} gutterBottom>
                  <b>Description: </b>
                  {item.dsc}
                </Typography>
              </>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};

ShareInfoDetails.propTypes = {
  row: PropTypes.object.isRequired,
  editLang: PropTypes.func,
  deleteLang: PropTypes.func,
};

export default ShareInfoDetails;
