/*
  Constants specific to the CHECKBOX and RADIO question types. See the `types.js` file containing
  all of the possible question types for full documentation of what properties
  are allowed for each question type in the questions data file.
 */

// Required
export const OPTIONS = "options";
export const OPTION_VALUE = "optionValue";
export const OPTION_DISPLAY = "optionDisplay";
export const OPTION_DISPLAY_SIZE = "optionDisplaySize";
export const OPTION_DISPLAY_SIZE_SMALL = "small";
export const OPTION_DISPLAY_SIZE_LARGE = "large";
export const VALUE = "value";

// Optional
export const CLEAR_OPTION = "clearOption";
export const CLEAR_POSITION ="clearPosition";
export const OPTIONS_PER_ROW = "optionsPerRow";
export const CONDITIONALS = "conditionals";
export const CONDITIONAL_TARGET_VALUE = "valueToMatch";
export const CONDITIONAL_QUESTIONS = "conditionalQuestions";
export const TARGET_VALUE = "targetValue";
export const ANSWER_VALUE = "Answer";
export const TABLE_DSC = "tableDsc";
export const INITIAL_VALUE = "initialValue";

// emergency program codes description
export const IMM_NEEDS_PGRM_CODE = "pgrmCode";
export const OPTION_COUNT ="applicationTypeCount";
export const DISPLAY_INCOME_TYPE_NAME = "displayIncomeName";
