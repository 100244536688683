import { Button } from "@mui/base";
import PropTypes from "prop-types";
import { Chip, Divider, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
// import * as htmlToImage from "html-to-image";
// import { toPng } from "html-to-image";
import { FileUploader } from "react-drag-drop-files";
import { FileUpload } from "@mui/icons-material";
import "../add-signature.css";
export default function UploadSign(props) {
  const [upImg, setUpImg] = useState("");
  const [getImg, setGetImg] = useState("");

  useEffect(() => {
    setUpImg("");
  }, [props.clear]);
  const fileTypes = ["JPEG", "PNG", "JPG"];
  const handleChange = (event) => {
    const reader = new FileReader();
    reader.readAsDataURL(event);

    if (event.size <= 2000000) {
      reader.onload = () => {
        props.setUploadData(reader.result);
        setUpImg(reader.result);
      };
    } else {
      console.log("not ok");
    }
  };

  return (
    <Grid
      sx={{
        backgroundColor: "white",
        minWidth: "565px",
        minHeight: "28vh",
        borderRadius: 1,
      }}
    >
      <Grid>
        <Grid>
          {upImg ? (
            <Grid
              display="flex"
              alignItems="center"
              marginTop={0.5}
              justifyContent="center"
            >
              <Grid>
                <img width={"400px"} height={"250px"} src={upImg} />
              </Grid>
            </Grid>
          ) : (
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
            >
              <Grid
                display="flex"
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid>
                  <Grid>
                    <DriveFolderUploadIcon
                      sx={{ fontSize: 150, color: "#d9d9d9" }}
                    />
                    <Grid>
                      <span>Drop your files here</span>
                    </Grid>
                    <Grid>
                      <Divider
                        sx={{
                          "& .MuiChip-filled ": {
                            borderRadius: 0,
                          },
                          "& .MuiDivider-wrapper": {
                            paddingRight: 0,
                            paddingLeft: 0,
                          },
                        }}
                      >
                        <Chip label="or" />
                      </Divider>
                    </Grid>
                    <Grid
                      border={2}
                      paddingLeft={3}
                      paddingTop={1}
                      paddingBottom={1}
                      paddingRight={3}
                      borderRadius={1}
                      textAlign={"center"}
                      margin={1}
                      className="uploadImgButton"
                      borderColor="rgb(91, 171, 231)"
                    >
                      <span>Browse</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FileUploader>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

UploadSign.propTypes = {
  clear: PropTypes.func,
  setUploadData: PropTypes.func,
};
