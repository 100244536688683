export const FETCH_DEMOGRAPHIC_DATA = "FETCH_DEMOGRAPHIC_DATA";
export const FETCH_PROGRAM_INFO = "FETCH_PROGRAM_INFO";
export const FETCH_DEMOGRAPHIC_DASHBOARD_DATA =
  "FETCH_DEMOGRAPHIC_DASHBOARD_DATA";

export const fetchDemographicData = (payload) => ({
  type: FETCH_DEMOGRAPHIC_DATA,
  payload,
});

export const fetchProgramInfo = (payload) => ({
  type: FETCH_PROGRAM_INFO,
  payload,
});

export const fetchDemographicDashboardData = (payload) => ({
  type: FETCH_DEMOGRAPHIC_DASHBOARD_DATA,
  payload,
});
