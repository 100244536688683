import { Box, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import DataGridTable from "../../common/DataGrid";
import useTranslator from "../../hooks/useTranslator";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px !important",
    fontWeight: "700 !important",
    color: "#6C6C6C",
    // padding: "10px 0px 20px 15px",
  },
  typography: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
  },
  tableBorder: {
    "& .css-0": { padding: "5px 16px" },
    "& .MuiDataGrid-columnHeader": { paddingLeft: "0px !important" },
    "& .MuiDataGrid-row": { paddingLeft: "0px !important" },
  },
}));
const SeniorTransaction = () => {
  const classes = useStyles();
  const transProps = useTranslator(
    [
      "pageTitle",
      "id",
      "merchantNumber",
      "merchantName",
      "merchantShortAddress",
      "posNumber",
      "value",
      "dateOfTransaction",
      "cardBalance",
    ],
    "transactionDetails"
  );

  let COLOMNS = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
    },
    {
      field: "accountID",
      headerName: "Account ID",
      flex: 1,
    },
    {
      field: "txnDate",
      headerName: "Transaction Date",
      flex: 1,
    },
    {
      field: "saleAmount",
      headerName: "Sale Amount",
      flex: 1,
    },
    {
      field: "settlementAmount",
      headerName: "Settlement Amount",
      flex: 1,
    }
  ];

  let ROWS = [
    {
      firstName: "Eugene",
      lastName: "Fletcher",
      accountID: "AP1234567",
      txnDate: "Mar 31, 2024",
      saleAmount: "$6.00",
      settlementAmount: "$20.00",
    },
    {
      firstName: "Jorge",
      lastName: "Horton",
      accountID: "AP3423578",
      txnDate: "Mar 30, 2024",
      saleAmount: "$5.00",
      settlementAmount: "$14.00",
    },
    {
      firstName: "Aaron",
      lastName: "Owens",
      accountID: "AP3444567",
      txnDate: "Mar 29, 2024",
      saleAmount: "$2.00",
      settlementAmount: "$9.00",
    },
    {
      firstName: "Lucille",
      lastName: "Harrison",
      accountID: "AP3334567",
      txnDate: "Mar 19, 2024",
      saleAmount: "$3.00",
      settlementAmount: "$7.00",
    },
    {
      firstName: "Aubree",
      lastName: "Lee",
      accountID: "AP5234567",
      txnDate: "Mar 14, 2024",
      saleAmount: "$4.00",
      settlementAmount: "$4.00",
    }
  ];

  if(window.location.pathname === '/seniorTransaction'){
    COLOMNS = [
      {
        field: "merchantName",
        headerName: "Merchant Name",
        flex: 1,
      },
      {
        field: "merchantID",
        headerName: "Merchant ID",
        flex: 1,
      },
      {
        field: "storeAddress",
        headerName: "Store Address",
        flex: 1,
      },
      {
        field: "txnDate",
        headerName: "Transaction Date",
        flex: 1,
      },
      {
        field: "saleAmount",
        headerName: "Sale Amount",
        flex: 1,
      },
      {
        field: "cardAmount",
        headerName: "Card Amount",
        flex: 1,
      }
    ];
  
    ROWS = [
      {
        merchantID: "APOOO155",
        merchantName: "Blocks Stand and Greenhouse",
        storeAddress: "3225 Centre Blvd, Lansing, MI, 48912",
        txnDate: "Mar 31, 2024",
        saleAmount: "$7.00",
        cardAmount: "$3.00",
      },
      {
        merchantID: "APOOO160",
        merchantName: "Donahee Farms",
        storeAddress: "230 Jarvisville Road, Lansing, MI, 48933",
        txnDate: "Mar 30, 2024",
        saleAmount: "$3.00",
        cardAmount: "$10.00",
      },
      {
        merchantID: "APOOO171",
        merchantName: "9 Bean Rows",
        storeAddress: "1209 Charles Street, Wayne, MI, 48184",
        txnDate: "Mar 25, 2024",
        saleAmount: "$5.00",
        cardAmount: "$13.00",
      },
      {
        merchantID: "APOOO175",
        merchantName: "Pregitzer Farm Market LLC",
        storeAddress: "4948 Tennessee Avenue, Livonia, MI, 48150",
        txnDate: "Mar 15, 2024",
        saleAmount: "$5.00",
        cardAmount: "$18.00",
      },
      {
        merchantID: "APOOO255",
        merchantName: "Phillips Orchards & Cider Mill",
        storeAddress: "1078 Summit Park Avenue, Farmington Hills, MI, 48335",
        txnDate: "Mar 3, 2024",
        saleAmount: "$2.00",
        cardAmount: "$23.00",
      }
    ];
  }

  return (
    <Box flexGrow={1} m={2}>
      <Typography className={classes.title} mb={1}>
        {transProps.pageTitle}
      </Typography>
      <DataGridTable columns={COLOMNS} data={ROWS} />
    </Box>
  );
};
export default SeniorTransaction;
