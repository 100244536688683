import { useTranslation } from "react-i18next";
import { entries, forEach, isArray, isObject, map, reduce } from "lodash";
import { useMemo } from "react";

function useTranslator(props, path, root) {
  const { t } = useTranslation("translation", {
    keyPrefix: `${root ?? "components"}${path ? `.${path}` : ""}`,
  });

  const getProps = (prop, keyPrefixOpt) => {
    if (isArray(prop)) {
      // eslint-disable-next-line prefer-spread
      return [prop[0], t.apply(null, prop)];
    }
    if (isObject(prop)) {
      const keys = Object.keys(prop);
      const obj = {};
      return forEach(keys, (key) => {
        obj[key] = getProps(key, keyPrefixOpt);
      });
    }
    const value = t(prop, keyPrefixOpt);
    return [prop, value];
  };

  const pageProps = useMemo(() => {
    if (!isArray(props)) {
      const xxx = entries(props);
      return reduce(
        entries(props),
        (acc, [key, value = []]) => {
          const transObj = Object.fromEntries(
            map(value, (v) => getProps(v, { keyPrefix: `components.${key}` }))
          );
          acc[key] = transObj;
          return acc;
        },
        {}
      );
    }

    if (props.length === 1) {
      return t(props);
    }

    if (props.length > 1) {
      return Object.fromEntries(map(props, getProps));
    }
  }, [props, t]);

  return pageProps;
}

export default useTranslator;
