import React from "react";
import { Outlet, useMatch, useParams } from "react-router-dom";
import styles from "./index.module.css";
import MultiStepper from "../../components/MultiStepper";
import { Grid, Stack, Typography } from "@mui/material";
import useTranslator from "../../hooks/useTranslator";

export default function DashboardContainer() {
  const transProps = useTranslator(
    [
      "pageTitle",
      "yourInfo",
      "contactInfo",
      "programIncome",
      "applicationId",
      "signature",
    ],
    "seniorSteper"
  );
  const params = useParams();
  const _appNumber = params?.appNumber ?? "-";
  const isYourInfoPage = useMatch("/yourInfo/:appNumber?");
  const isContactInfoPage = useMatch("/contactInfo/:appNumber?/:clientId?");
  const isProgramIncomePage = useMatch("/programIncome/:appNumber?/:clientId?");
  const isCertificationPage = useMatch("/certification/:appNumber?/:clientId?");
  const isEligibilityPage = useMatch(
    "/eligibilitystatus/:appNumber?/:clientId?"
  );

  const stages = [
    {
      title: transProps.yourInfo,
      done: isYourInfoPage,
    },
    {
      title: transProps.contactInfo,
      done: isContactInfoPage,
    },
    {
      title: transProps.programIncome,
      done: isProgramIncomePage || isCertificationPage || isEligibilityPage,
    },
    {
      title: transProps.signature,
      done: isCertificationPage || isEligibilityPage,
    },
  ];
  return (
    <div className={styles.mainTitle}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography className={styles.subtitle}>
            {transProps.pageTitle}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          display="flex"
          justifyContent={{ xs: "flex-start", md: "flex-end" }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Typography className={styles.subtitle}>
              {transProps.applicationId}: &nbsp;
            </Typography>
            <Typography className={styles.subtitle}>{_appNumber}</Typography>
          </Stack>
        </Grid>
      </Grid>
      {/* <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      ></Stack> */}
      <div className={styles.appContainer}>
        <div className={styles.multiStepBar}>
          <MultiStepper steps={stages} />
        </div>
        <div className={styles.formContent}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
