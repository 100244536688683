import React, { useState } from "react";
import {
  Stack,
  Card,
  CardActions,
  CardContent,
  Box,
  Typography,
  TextField,
  FormLabel,
  FormControlLabel,
  InputAdornment,
  IconButton 
} from "@mui/material";
import LandingBg from "../../assets/images/adminbgimage.png";
import { Button } from "react-bootstrap";
import "../../styles/login.scss";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import "../../styles/home.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { AUTH_API } from "../../config";
import useLoader from "../../hooks/useLoader";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";


const LandingHomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const { openLoader, closeLoader } = useLoader();

  const validationSchema = Yup.object({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      sessionStorage.setItem("ext_url", "csa");
      try {
        openLoader('admin-login');
        const res = await axios.post(AUTH_API + "/admin/login", values, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const userData = {
          Name:
            res &&
            res?.data?.userData?.firstName +
              " " +
              res?.data?.userData?.lastName,
          Role: res && res?.data?.userData?.role,
          id: res && res?.data?.userData?.id,
        };
        if (res.data.code == "200") {
          sessionStorage.setItem(
            "userLogin",
            JSON.stringify(res.data.userData)
          );
          sessionStorage.setItem("userData", JSON.stringify(userData));
          sessionStorage.setItem("username", res.data.userData.email);
          dispatch({ type: "SET_HIP_USER_DETAILS", payload: userData });
          await new Promise((resolve) => setTimeout(resolve, 1000));
          navigate("/kfreshfep/admin-dashboard");
        }
      } catch (error) {
        console.log(error);
        toast.error("Login failed: Incorrect email or password.");
      } finally {
        closeLoader('admin-login');
      }
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  return (
    <div style={{ height: "100vh" }}>
      <div className="image-container">
        <img
          style={{
            width: "100%",
            height: "100vh",
            objectFit: "cover",
          }}
          src={LandingBg}
          alt="Descriptive Alt Text"
        />
      </div>
      <Stack direction="row" className="card-content-admin">
        <Card
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{
            minWidth: 500,
            minHeight: 400,
            borderRadius: "16px",
            boxShadow: 3,
          }}
        >
          <CardContent>
            <Typography
              className="modal-title modalTitle resText"
              variant="h6"
              component="h2"
            >
              Staff Login
            </Typography>
            <Stack className="login-form">
              <Box sx={{ marginTop: "1rem" }}>
                <FormLabel className="labelName">Email </FormLabel>
                <TextField
                  fullWidth
                  id="email"
                  placeholder="Enter email address"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Box>
              <Box sx={{ marginTop: "1rem" }}>
                <FormLabel className="labelName">Password</FormLabel>
                <TextField
                  fullWidth
                  name="password"
                  placeholder="Enter your password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.password && formik.errors.password}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                />
              </Box>

              {/* <Button
                style={{
                  marginTop: "40px",
                  fontSize: "1.5rem",
                  backgroundColor: "#457425",
                }}
              >
                Login
              </Button> */}
            </Stack>
          </CardContent>
          <CardActions>
            <Box
              width="100%"
              gap={2}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mb={1}
            >
              <Button
                style={{
                  marginTop: "40px",
                  fontSize: "1.5rem",
                  backgroundColor: "#457425",
                }}
                // onClick={formik.handleSubmit}
                type="submit"
              >
                Login
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Stack>
    </div>
  );
};

LandingHomePage.propTypes = {
  setLoginOpen: PropTypes.func,
  setRegOpen: PropTypes.func,
  setSelectedRole: PropTypes.func,
};

export default LandingHomePage;
