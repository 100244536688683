import React, { useEffect, useMemo, useState } from "react";
import axiosInstance from "../../redux/interceptor";
import { Stack, TablePagination, Typography } from "@mui/material";
import { filter, includes } from "lodash";
import { popUpAlert } from "../../utils/globalMethods";
import SearchComponent from "../common/components/searchComponent";
import CommonLoading from "../common/components/commonLoading";
import useLoader from "../../hooks/useLoader";
import { Tab, Tabs } from "react-bootstrap";
import MarketList from "./MarketList";
import VendorDetails from "../vendor/vendorDetails";

function MarketWaitingList() {
  const { openLoader, closeLoader } = useLoader();
  const [isLoading, setIsLoading] = useState(false);
  const [listCount, setListCount] = useState(10);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [marketWaitingList, setMarketWaitingList] = useState([]);
  const [activeTab, setActiveTab] = useState("PE");
  const [search, setSearch] = useState("");
  const [vendorInfo, setVendorInfo] = useState({});

  const userRole = JSON.parse(sessionStorage.getItem("userData")) || {}

  // const sortedMarket = useMemo(() => {
  //   return filter(marketWaitingList, ({ fnsNum }) => {
  //     return search ? includes(fnsNum, search) : marketWaitingList;
  //   });
  // }, [search, marketWaitingList, activeTab]);

  // console.log("marketWaitingList", marketWaitingList);
  
      const sortedMarket = useMemo(() => {
        return filter(marketWaitingList, (item) => {
          const fnsNumMatches = item.fnsNum.startsWith(search);
          const marketNameMatches =
            item.marketName &&
            item.marketName.toLowerCase().startsWith(search.toLowerCase());
          return search
            ? fnsNumMatches || marketNameMatches
            : marketWaitingList;
        });
      }, [search, marketWaitingList, activeTab]);

  const fetchMarketsWaitingList = async (appStatus) => {
    let payLoad = {
      vendorType: "MRKCRD",
      isVerified: "Y",
      isNotVerified: "Y",
      status: appStatus,
      isActive: "Y",
      isNotActive: "Y",
      name: "",
      lastUpdtTsSort: "DESC",
      size: perPage,
      from: page,
      isVerifiedSort: "",
      adminLastUpdtTsSort: "",
    };
    try {
      openLoader("market_wait_list");
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/csa/marketCoordinators/list`,
          payLoad
      );
      if (res.status === 200) {
        // setMarketWaitingList(res.data?.content?.[0]?.applicationModel);
        // setListCount(res.data?.totalElements);
        setMarketWaitingList(res.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("market_wait_list");
    }
  };

    const renderDetails = (row) => {
      return (
        <VendorDetails
          row={row}
          // vendorAddtInfo={isRefresh ? {} : vendorInfo}
          vendorAddtInfo={vendorInfo || {}}
          vendorType={"HIPVDR"}
          setVendorAddtInfo={(data) => {
            setVendorInfo(data);
          }}
          showVendorHistoryDetails={false}
        />
      );
    };

  useEffect(() => {
    fetchMarketsWaitingList(activeTab);
  }, [activeTab, page]);

  const submitApproval = async (appNumber, status) => {
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/senior/update/application/${appNumber}/${status}`,
        {
          data: {},
        }
      );
      if (res.status === 200) {
        fetchMarketsWaitingList(activeTab);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onApprove = (appNumber) => {
    popUpAlert({
      title: "Are you sure?",
      text: "Do you want to approve this application?",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, approve it",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => submitApproval(appNumber, "AP"),
    });
  };

  const onReject = (appNumber) => {
    popUpAlert({
      title: "Are you sure?",
      text: "Do you want to reject this application?",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, reject it!",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => submitApproval(appNumber, "DE"),
    });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Stack sx={{ width: "100%", p: "1rem" }}>
      {isLoading && <CommonLoading />}

      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <Typography
          variant="h5"
          display="inline"
          className="resText text-capitalize"
          gutterBottom
          component="div"
        >
          Market Coordinator
        </Typography>
        <SearchComponent
          placeholder={"Market Name, Fed ID"}
          handleChange={handleSearch}
          searchText={search}
        />
      </Stack>
      <Tabs
        id="controlled-tab"
        activeKey={activeTab}
        onSelect={setActiveTab}
        style={{ background: "#fff" }}
      >
        <Tab
          eventKey="PE"
          title={
            <div className="tab-header">
              <i className="fa fa-hourglass-end"></i>
              <span className="m-l-5">Pending</span>
            </div>
          }
        >
          <div className="tab-content-wrapper">
            <MarketList
              markets={sortedMarket}
              onApprove={onApprove}
              onReject={onReject}
              isLoading={isLoading}
              userRole={userRole}
              renderDetails={renderDetails}
              fetchMarketsWaitingList={fetchMarketsWaitingList}
              activeTab={activeTab}
            />
          </div>
        </Tab>
        <Tab
          eventKey="AP"
          title={
            <div className="tab-header">
              <i className="fa fa-check-square-o"></i>
              <span className="m-l-5">Approved</span>
            </div>
          }
        >
          <div className="tab-content-wrapper">
            <MarketList
              markets={sortedMarket}
              onApprove={onApprove}
              onReject={onReject}
              isLoading={isLoading}
              userRole={userRole}
              renderDetails={renderDetails}
              fetchMarketsWaitingList={fetchMarketsWaitingList}
              activeTab={activeTab}
            />
          </div>
        </Tab>
        <Tab
          eventKey="DE"
          title={
            <div className="tab-header">
              <i className="fa fa-ban"></i>
              <span className="m-l-5">Denied</span>
            </div>
          }
        >
          <div className="tab-content-wrapper">
            <MarketList
              markets={sortedMarket}
              onApprove={onApprove}
              onReject={onReject}
              isLoading={isLoading}
              userRole={userRole}
              renderDetails={renderDetails}
              fetchMarketsWaitingList={fetchMarketsWaitingList}
              activeTab={activeTab}
            />
          </div>
        </Tab>
      </Tabs>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={listCount}
        rowsPerPage={perPage}
        page={Math.floor(page)}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={(e) => setPerPage(+e.target.value)}
        className={"paginationBlock"}
      />
    </Stack>
  );
}

export default MarketWaitingList;
