import React from 'react'
import participant1 from "../../assets/images/participant1.png";
import participant2 from "../../assets/images/participant2.png";

const Participant = () => {
  return (
    <div className="participant_container">
      <div className="participant_rights">
        <div className="image_content">
          <img src={participant1} />
        </div>
        <div className="rights_content">
          <h1>Participant Rights: </h1>
          <h1>Ensuring Fairness and Respect</h1>
          <ol>
            <li>
              You have the right to equal treatment, without discrimination
              based on various factors including race, religion, gender, age,
              and disability.
            </li>
            <li>
              You can apply for Senior Project FRESH/Market FRESH coupons at any
              distribution site in your county. Eligibility is determined by
              your application.
            </li>
            <li>
              You have the right to have assistance/and accommodation with any
              aspect of the program.
            </li>
            <li>
              These rights extend to markets and roadside stands that are
              registered with
            </li>
            <li>
              If discrimination based on any protected class listed in #1
              occurs, you have the right to file a complaint with the USDA.
              Complaints can be submitted
            </li>
          </ol>
        </div>
      </div>
      <div className="participant_responsibility">
        <div className="responsibility_content">
          <h1>Participant Responsibilities - </h1>
          <h1>It is your responsibility to:</h1>
          <ol>
            <li>
              Enter true and honest answers on the application for Senior
              Project FRESH/MarketFRESH.
            </li>
            <li>
              Ask questions at the distribution site or the market if you don’t
              understand something about the program.
            </li>
            <li>
              Ask for or inform the staff person at the distribution site that
              you need assistance in participating in the program.
            </li>
            <li>
              Use all the coupons given to you for items that are on the
              Eligible Items list.
            </li>
            <li>
              Take the lead and file a discrimination complaint if you feel you
              have been discriminated against.
            </li>
          </ol>
        </div>
        <div className="image_content">
          <img src={participant2} />
        </div>
      </div>
    </div>
  );
}

export default Participant