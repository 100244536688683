export const actionTypes = {
  AUTHENTICATE_ADMIN_USER : "AUTHENTICATE_ADMIN_USER",
  SET_AUTHENTICATE_USER : "SET_AUTHENTICATE_USER",
  SET_AUTHENTICATE_USER_ERROR: "SET_AUTHENTICATE_USER_ERROR",
  REFRESH_ACCESS_TOKEN : "REFRESH_ACCESS_TOKEN",
  AUTHENTICATE_HIP_USER : "AUTHENTICATE_HIP_USER",
  HIP_USER_DETAILS : "HIP_USER_DETAILS",
  SET_HIP_USER_DETAILS: "SET_HIP_USER_DETAILS",
  TOKEN_REVOKE : "TOKEN_REVOKE",
  SET_TOKEN_REVOKE: "SET_TOKEN_REVOKE"
  };


  export function tokenRevoke(payload) {
    return {
      type: actionTypes.TOKEN_REVOKE,
      payload: payload
    }
  }
  
  export  function authenticateAdminUser(payload) {
    return {
    type: actionTypes.AUTHENTICATE_ADMIN_USER,
    payload: payload
    };
  }

  export  function clearLoginError(payload) {
    return {
      type: actionTypes.SET_AUTHENTICATE_USER_ERROR,
      payload: payload
    };
  }

  export  function refreshAccessToken(payload) {
    return {
      type: actionTypes.REFRESH_ACCESS_TOKEN,
      payload: payload
    };
  }

  export  function authenticateHIPUser(payload) {
    return {
    type: actionTypes.AUTHENTICATE_HIP_USER,
    payload: payload
    };
  }

  export  function authUserDetails(payload) {
    return {
    type: actionTypes.HIP_USER_DETAILS,
    payload: payload
    };
  }