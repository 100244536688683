import React, { useState, useEffect } from "react";
import SelectDropdown from "../../components/SelectDropdown";
import MultiSelect from "../../components/Multiselect";
import StyledButton from "../../components/StyledButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  GET_COUNTYCITY,
  GET_MAPDATA,
  GET_STORE_LIST,
  GET_VENDOR_LIST,
  SET_MAP_SEARCH,
} from "../../redux/actionTypes";
import { mapStateToProps } from "../../redux/reducers";
import Geocode from "react-geocode";
import {
  setKey,
  setLanguage,
  fromAddress,
  enableDebug,
  fromLatLng,
} from "react-geocode";

import { useCookies } from "react-cookie";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  sortName,
  vendorType,
  orderOptions,
  daysOfOperation,
  monthsOfOperation,
} from "../../utils/constants";
import { GOOGLE_KEY } from "../../config";
import ModalComponent from "../common/components/modal";
import Modal from "../common/modal";
import { getNextDate, removeSpecialCharacter } from "../../utils/globalMethods";
import useTranslator from "../../hooks/useTranslator";
import { AUTH_API, headers } from "../../config/index";
import axios from "axios";
import CheckIcon from "@mui/icons-material/Check";
import { Stack, Chip, FormControl as MUIFormControl } from "@mui/material";

const centerLocation = {
  lat: 42.732536,
  lng: -84.555534,
  address: "Michigan",
  city: "Michigan",
  located: false,
};

const defaultLocation = {
  lat: 42.732536,
  lng: -84.555534,
  address: "Michigan, USA",
  city: "Michigan",
  located: false,
};
setKey(GOOGLE_KEY);

const SearchComponent = (props) => {
  const transProps = useTranslator(
    [
      "usesLocationServices",
      "allVendors",
      "statewide",
      "useMyLocation",
      "areaWithin",
      "allCountiesCities",
      "allSelected",
      "selectAll",
      "orderOption",
      "monthOperation",
      "dayOperation",
      "vendorName",
      "openToday",
      "clearSearch",
      "locPermissionDenied",
      "county",
      "city",
      "type",
      "miles",
      "mile",
      "searchFilters",
      "yes",
      "no",
      "clear",
      "search",
    ],
    "searchComponent"
  );
  const { setIsLoading } = props;
  const [typeNames, setTypeNames] = useState(vendorType);
  const [orderOptionNames, setOrderOptionNames] = useState(orderOptions);
  const [monthNames, setMonthNames] = useState(monthsOfOperation);
  const [dayNames, setDayNames] = useState(daysOfOperation);
  const [selectedCity, setSelectedCity] = useState({
    id: "myLocation",
    label: transProps.useMyLocation,
  });
  const [selectedArea, setSelectedArea] = useState({
    id: "5",
    label: "5 " + transProps.miles,
  });
  const [openToday, setOpenToday] = useState("No");
  const [cityList, setCityList] = useState([]);
  const [coordinates, setCoordinates] = useState(defaultLocation);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [cookies, setCookie] = useCookies(["name"]);
  const [modalShown, toggleModal] = useState(false);
  const [selectedVendors, setSelectedVendors] = useState({
    id: "all",
    label: transProps.allVendors,
  });
  const [vendorList, setVendorList] = useState([]);
  const [initialSearch, setInitialSearch] = useState(true);
  const [isClear, setIsClear] = useState(false);
  const [myLocation, setMyLocation] = useState(false);
  const [mycoordinates] = useState();
  const [areaWithinList, setAreaWithin] = useState();
  const [products, setProducts] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  let selectedType = {
    csaPickupSite: "Y",
    farmStand: "Y",
    farmersMarket: "Y",
    farmersMarketBooth: "Y",
    mobileMarket: "Y",
  };
  let selectedOrderOption = {
    curbsidePickup: "Y",
    delivery: "Y",
    inPerson: "Y",
    onlinePreselection: "Y",
    phonePreselection: "Y",
    otherType: "Y",
  };
  let selectedMonths = {
    janOperation: "Y",
    febOperation: "Y",
    marOperation: "Y",
    aprOperation: "Y",
    mayOperation: "Y",
    junOperation: "Y",
    julOperation: "Y",
    augOperation: "Y",
    sepOperation: "Y",
    octOperation: "Y",
    novOperation: "Y",
    decOperation: "Y",
  };
  let selectedDays = {
    monOperation: "Y",
    tueOperation: "Y",
    wedOperation: "Y",
    thuOperation: "Y",
    friOperation: "Y",
    satOperation: "Y",
    sunOperation: "Y",
  };
  const [searchProperties, setSearchProperties] = useState({
    address: coordinates.address,
    city: "all",
    latitude: parseFloat(coordinates.lat),
    longitude: parseFloat(coordinates.lng),
    area: selectedArea,
    vendorType: "all",
    orderOptions: "all",
    months: "all",
    days: "all",
    vendor_name: "all",
    open_today: openToday,
    is_mylocation: false,
  });
  const customStyles = {
    select: {
      padding: "19px 6px",
      height: "30px",
      background: "#fff",
      border: "1px solid #44A0E3",
      fontFamily: "Texta-book,Helvetica,Arial,sans-serif",
      color: "#464646",
      fontSize: "16px",
      marginTop: "4px",
    },
  };

  const fetchVendorList = (city, miles) => {
    props.getVendorList({
      cityName: city.id === "all" || city.id === "myLocation" ? "" : city.label,
      miles: miles === "" ? "" : miles,
      lat: parseFloat(coordinates.lat),
      lon: parseFloat(coordinates.lng),
      allCityFlag: city.id === "all" ? "Y" : "N",
    });
  };

  useEffect(() => {
    getGeocode(selectedCity, "fullWidth");
    props.getCityList();
    //   if (selectedCity.id === "myLocation") {
    //     setSelectedCity({id:"myLocation", label:transProps.useMyLocation, isActive: 1});
    //   } else
  }, []);
  useEffect(() => {
    if (selectedCity.id === "all") {
      setSelectedCity({
        id: "all",
        label: transProps.allCountiesCities,
        isActive: 1,
      });
      setSelectedArea({ id: "", label: transProps.statewide });
    } else {
      setSelectedArea({
        id: selectedArea.id,
        label:
          selectedArea.id == "1"
            ? selectedArea.label.replace("mile", transProps.mile)
            : selectedArea.label.replace("miles", transProps.miles),
      });
    }
    if (selectedVendors.id === "all") {
      setSelectedVendors({
        id: "all",
        label: transProps.allVendors,
      });
    }

    const data = [
      {
        id: "",
        label: transProps.statewide,
      },
      {
        id: "1",
        label: "1 " + transProps.mile,
      },
      {
        id: "2",
        label: "2 " + transProps.miles,
      },
      {
        id: "5",
        label: "5 " + transProps.miles,
      },
      {
        id: "10",
        label: "10 " + transProps.miles,
      },
      {
        id: "15",
        label: "15 " + transProps.miles,
      },
      {
        id: "20",
        label: "20 " + transProps.miles,
      },
      {
        id: "25",
        label: "25 " + transProps.miles,
      },
      {
        id: "30",
        label: "30 " + transProps.miles,
      },
    ];
    setAreaWithin(data);
  }, []);

  useEffect(() => {
    mapCityList(props.mapdata.city);
    //   mapAreaList(props.mapdata.area);
    mapVendorList(props.mapdata.vendor);
  }, [props.mapdata.city, props.mapdata.vendor]);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (result) => {
              if (result.status === "OK") {
                const city = result?.results?.[0]?.address_components?.find(
                  (item) => item?.types?.indexOf("locality") !== -1
                );
                setCoordinates((prevState) => ({
                  ...prevState,
                  address: result.results[0].formatted_address,
                  city: city?.long_name ? city?.long_name : "",
                  located: true,
                }));
                setSearchProperties((prevState) => ({
                  ...prevState,
                  address: result.results[0].formatted_address,
                  city: city?.long_name ? city?.long_name : "",
                  is_mylocation: true,
                }));
                setMyLocation(true);
                props.getVendorList({
                  cityName: city?.long_name ? city?.long_name : "",
                  miles: selectedArea?.id,
                  lat: parseFloat(position.coords.latitude),
                  lon: parseFloat(position.coords.longitude),
                  allCityFlag: "N",
                });
              }
            }
          );
          setCoordinates((prevState) => ({
            ...prevState,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }));
          setSearchProperties((prevState) => ({
            ...prevState,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }));
        },
        () => {
          setCoordinates((prevState) => ({
            ...prevState,
            located: false,
          }));
        }
      );
    }
  };
  const getGeocode = (city, modalSize) => {
    if (city.id === "myLocation") {
      navigator.permissions
        .query({
          name: "geolocation",
        })
        .then(function (result) {
          if (result.state == "granted" || result.state == "prompt") {
            getCurrentLocation();
          } else if (result.state == "denied") {
            //   setInitialStoreView(false);
            setMyLocation(false);
            setSelectedCity({
              id: "all",
              label: transProps.allCountiesCities,
              isActive: 1,
            });
            setSelectedArea({ id: "", label: transProps.statewide });
            setSearchProperties((prevState) => ({
              ...prevState,
              area: { id: "", label: transProps.statewide },
              city: "all",
              is_mylocation: false,
            }));
            if (modalSize == "modal") {
              setShowLocationModal(true);
              props.showLocationModal(true);
            } else if (
              modalSize == "fullWidth" &&
              (!cookies.loc_permission_modal ||
                cookies.loc_permission_modal == "false")
            ) {
              toggleModal(true);
            }
            // Initial vendor list api call
            if (initialSearch) {
              props.getVendorList({
                cityName: "",
                miles: "",
                lat: parseFloat(coordinates.lat),
                lon: parseFloat(coordinates.lng),
                allCityFlag: "Y",
              });
              setInitialSearch(false);
            }
          }
        });
    } else if (city.id === "all") {
      setCoordinates({
        lat: centerLocation.lat,
        lng: centerLocation.lng,
        address: centerLocation.address,
        city: centerLocation.city,
        located: true,
      });
      setSearchProperties((prevState) => ({
        ...prevState,
        latitude: centerLocation.lat,
        longitude: centerLocation.lng,
        address: centerLocation.address,
        city: "all",
        area: { id: "", label: transProps.statewide },
        is_mylocation: false,
      }));
    } else {
      const address = city.label + ",MI";
      fromAddress(address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setCoordinates({
            lat: lat,
            lng: lng,
            address: response.results[0].formatted_address,
            city: city.label,
            located: true,
          });
          setSearchProperties((prevState) => ({
            ...prevState,
            latitude: lat,
            longitude: lng,
            address: response.results[0].formatted_address,
            city: city.label,
            is_mylocation: false,
          }));
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const mapCityList = (city) => {
    if (city && city.length) {
      let cityOption = city
        ?.filter((item) => item.cityName.includes("County"))
        .map((item) => {
          return {
            id: item.id,
            label: item.cityName,
            isActive: 1,
          };
        });

      let defaultData = [
        {
          id: "myLocation",
          label: transProps.useMyLocation,
          isActive: 1,
          default: true,
        },
        { id: "all", label: transProps.allCountiesCities, isActive: 1 },
      ];
      if (cityOption) {
        setCityList(defaultData.concat(sortName(cityOption, "label")));
      }
      // (selectedCity.id == "myLocation")  && setSelectedCity({id: "myLocation",label: transProps.useMyLocation});
      // (selectedCity.id == "all")  && setSelectedCity({id:"all", label:transProps.allCountiesCities});
    }
  };

  const mapVendorList = (vendors) => {
    if (vendors.length) {
      let vendorOptions = vendors.map((item) => {
        return {
          id: item.vendorId,
          label: item.vendorName,
          isActive: 1,
        };
      });
      const defaultData = [{ id: "all", label: transProps.allVendors }];
      vendorOptions.forEach((item) => {
        item.id = removeSpecialCharacter(item.id);
        item.label = removeSpecialCharacter(item.label);
      });
      setVendorList(defaultData.concat(sortName(vendorOptions)));
      selectedVendors.name == "all" &&
        setSelectedVendors({ id: "all", label: transProps.allVendors });
    }
  };

  const handleChange = (event) => {
    setOpenToday(event.target.value);
  };
  const handleTypeChange = (selectedTypes) => {
    setTypeNames(selectedTypes);
  };
  const handleOrderOptionsChange = (selectedTypes) => {
    setOrderOptionNames(selectedTypes);
  };
  const handleProductChange = (selectedTypes) => {
    setProducts(selectedTypes);
  };
  const handleMonthsChange = (selectedTypes) => {
    setMonthNames(selectedTypes);
  };
  const handleDayChange = (selectedTypes) => {
    setDayNames(selectedTypes);
  };
  const handleCityChange = (v) => {
    console.log(v);
    let miles = "";
    setSearchProperties((prevState) => ({
      ...prevState,
      vendor_name: "all",
    }));
    setSelectedVendors({ id: "all", label: transProps.allVendors });
    getGeocode(v, "modal");
    if (v.id !== "all") {
      miles = "5";
      setSelectedArea({ id: "5", label: "5 " + transProps.miles });
      setSearchProperties((prevState) => ({
        ...prevState,
        area: { id: "5", label: "5 " + transProps.miles },
        city: v.label,
      }));
    } else {
      setSelectedArea({ id: "", label: transProps.statewide });
      setSearchProperties((prevState) => ({
        ...prevState,
        area: { id: "", label: transProps.statewide },
        city: "all",
      }));
    }
    setSelectedCity(v);
    fetchVendorList(v, miles);
  };
  const handleAreaChange = (newValue) => {
    setSelectedArea(newValue);
    setSearchProperties((prevState) => ({
      ...prevState,
      area: newValue,
    }));
    if (newValue.id == "") {
      setSelectedCity({
        id: "all",
        label: transProps.allCountiesCities,
      });
      setSearchProperties((prevState) => ({
        ...prevState,
        city: "all",
      }));
    }
  };
  const handleVendorChange = (v) => {
    setSelectedVendors(v);
    setSearchProperties((prevState) => ({
      ...prevState,
      vendor_name: v.name,
    }));
  };
  const handleClearSearch = () => {
    setSelectedVendors({ id: "all", label: transProps.allVendors });
    setIsClear(!isClear);
    setMyLocation(false);
    props.getVendorList({
      cityName: "",
      miles: "",
      lat: parseFloat(coordinates.lat),
      lon: parseFloat(coordinates.lng),
      allCityFlag: "Y",
    });
    setSelectedCity({
      id: "all",
      label: transProps.allCountiesCities,
      isActive: 1,
    });
    setSelectedArea({ id: "", label: transProps.statewide });
    setSearchProperties((prevState) => ({
      ...prevState,
      area: { id: "", label: transProps.statewide },
      latitude: parseFloat(defaultLocation?.lat),
      longitude: parseFloat(defaultLocation?.lng),
      city: "all",
      address: defaultLocation?.address,
      vendorType: "all",
      orderOptions: "all",
      months: "all",
      days: "all",
      vendor_name: "all",
      is_mylocation: false,
    }));
  };

  useEffect(() => {
    setTypeNames(vendorType);
    setOrderOptionNames(orderOptions);
    setMonthNames(monthsOfOperation);
    setDayNames(daysOfOperation);
    setOpenToday("No");
    if (props.resultShown) {
      const payload = {
        cityName: myLocation ? mycoordinates?.city : "",
        miles: myLocation ? "5" : "",
        lat: myLocation ? parseFloat(mycoordinates?.lat) : defaultLocation.lat,
        lon: myLocation ? parseFloat(mycoordinates?.lng) : defaultLocation.lng,
        allCityFlag: myLocation ? "N" : "Y",
        vendorId: "",
        csaPickupSite: "Y",
        farmStand: "Y",
        farmersMarket: "Y",
        farmersMarketBooth: "Y",
        mobileMarket: "Y",
        monthsOperation: {
          janOperation: "Y",
          febOperation: "Y",
          marOperation: "Y",
          aprOperation: "Y",
          mayOperation: "Y",
          junOperation: "Y",
          julOperation: "Y",
          augOperation: "Y",
          sepOperation: "Y",
          octOperation: "Y",
          novOperation: "Y",
          decOperation: "Y",
        },
        distributionOption: {
          delivery: "Y",
          curbsidePickup: "Y",
          onlinePreselection: "Y",
          phonePreselection: "Y",
          inPerson: "Y",
          otherType: "Y",
        },
        monOperation: "Y",
        tueOperation: "Y",
        wedOperation: "Y",
        thuOperation: "Y",
        friOperation: "Y",
        satOperation: "Y",
        sunOperation: "Y",
        openToday: "N",
      };
      props.getMapStoresList(payload);
      props.searchProperties(searchProperties);
      props.updateBooleanValue(true);
      props.isClearSearch(true);
      setIsLoading(true);
    }
  }, [isClear]);

  const handleButtonClick = () => {
    if (typeNames && typeNames.length) {
      const obj = typeNames.reduce((accumulator, value) => {
        return { ...accumulator, [value.id]: "Y" };
      }, {});
      selectedType = {
        csaPickupSite: "Y",
        farmStand: obj?.farmStand ? "Y" : "N",
        farmersMarket: obj?.farmersMarket ? "Y" : "N",
        farmersMarketBooth: obj?.farmersMarketBooth ? "Y" : "N",
        mobileMarket: obj?.mobileMarket ? "Y" : "N",
      };
    }

    if (monthNames && monthNames.length) {
      const obj = monthNames.reduce((accumulator, value) => {
        return { ...accumulator, [value.id]: "Y" };
      }, {});
      selectedMonths = {
        janOperation: obj?.janOperation ? "Y" : "N",
        febOperation: obj?.febOperation ? "Y" : "N",
        marOperation: obj?.marOperation ? "Y" : "N",
        aprOperation: obj?.aprOperation ? "Y" : "N",
        mayOperation: obj?.mayOperation ? "Y" : "N",
        junOperation: obj?.junOperation ? "Y" : "N",
        julOperation: obj?.julOperation ? "Y" : "N",
        augOperation: obj?.augOperation ? "Y" : "N",
        sepOperation: obj?.sepOperation ? "Y" : "N",
        octOperation: obj?.octOperation ? "Y" : "N",
        novOperation: obj?.novOperation ? "Y" : "N",
        decOperation: obj?.decOperation ? "Y" : "N",
      };
    }

    if (orderOptionNames && orderOptionNames.length) {
      const obj = orderOptionNames.reduce((accumulator, value) => {
        return { ...accumulator, [value.id]: "Y" };
      }, {});
      selectedOrderOption = {
        curbsidePickup: obj?.curbsidePickup ? "Y" : "N",
        delivery: obj?.delivery ? "Y" : "N",
        inPerson: obj?.inPerson ? "Y" : "N",
        onlinePreselection: obj?.onlinePreselection ? "Y" : "N",
        phonePreselection: obj?.phonePreselection ? "Y" : "N",
        otherType: obj?.otherType ? "Y" : "N",
      };
    }

    if (dayNames && dayNames.length) {
      const obj = dayNames.reduce((accumulator, value) => {
        return { ...accumulator, [value.id]: "Y" };
      }, {});
      selectedDays = {
        monOperation: obj?.monOperation ? "Y" : "N",
        tueOperation: obj?.tueOperation ? "Y" : "N",
        wedOperation: obj?.wedOperation ? "Y" : "N",
        thuOperation: obj?.thuOperation ? "Y" : "N",
        friOperation: obj?.friOperation ? "Y" : "N",
        satOperation: obj?.satOperation ? "Y" : "N",
        sunOperation: obj?.sunOperation ? "Y" : "N",
      };
    }
    let payload = {
      cityName:
        selectedCity?.id === "all"
          ? ""
          : selectedCity?.id === "myLocation"
          ? coordinates?.city
          : selectedCity?.label,
      miles: selectedCity.id === "all" ? "" : selectedArea.id,
      lat: parseFloat(coordinates?.lat),
      lon: parseFloat(coordinates?.lng),
      allCityFlag: selectedCity?.id === "all" ? "Y" : "N",
      vendorId: selectedVendors?.id === "all" ? "" : selectedVendors?.id,
      csaPickupSite: "Y",
      farmStand: selectedType?.farmStand,
      farmersMarket: selectedType?.farmersMarket,
      farmersMarketBooth: selectedType?.farmersMarketBooth,
      mobileMarket: selectedType?.mobileMarket,
      monthsOperation: {
        janOperation: selectedMonths?.janOperation,
        febOperation: selectedMonths?.febOperation,
        marOperation: selectedMonths?.marOperation,
        aprOperation: selectedMonths?.aprOperation,
        mayOperation: selectedMonths?.mayOperation,
        junOperation: selectedMonths?.junOperation,
        julOperation: selectedMonths?.julOperation,
        augOperation: selectedMonths?.augOperation,
        sepOperation: selectedMonths?.sepOperation,
        octOperation: selectedMonths?.octOperation,
        novOperation: selectedMonths?.novOperation,
        decOperation: selectedMonths?.decOperation,
      },
      distributionOption: {
        delivery: selectedOrderOption?.delivery,
        curbsidePickup: selectedOrderOption?.curbsidePickup,
        onlinePreselection: selectedOrderOption?.onlinePreselection,
        phonePreselection: selectedOrderOption?.phonePreselection,
        inPerson: selectedOrderOption?.inPerson,
        otherType: selectedOrderOption?.otherType,
      },
      monOperation: selectedDays?.monOperation,
      tueOperation: selectedDays?.tueOperation,
      wedOperation: selectedDays?.wedOperation,
      thuOperation: selectedDays?.thuOperation,
      friOperation: selectedDays?.friOperation,
      satOperation: selectedDays?.satOperation,
      sunOperation: selectedDays?.sunOperation,
      openToday: openToday === "Yes" ? "Y" : "N",
    };
    props.mapSearchPayload(payload);
    props.getMapStoresList(payload);
    props.updateBooleanValue(true);
    props.isClearSearch(false);
    props.searchProperties(searchProperties);
    if (selectedCity?.id === "all") {
      props.coordinates(defaultLocation);
    } else {
      props.coordinates(coordinates);
    }
    setIsLoading(true);
    // setTimeout(() => {
    //     setIsLoading(false);
    //   }, 2000);
  };

  const renderModal = () => {
    return transProps.locPermissionDenied;
  };

  const toggleLocation = () => {
    setCookie("loc_permission_modal", true, { expires: getNextDate() });
    toggleModal(false);
  };

  useEffect(() => {
    handleButtonClick();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [props?.mapdata?.storesList]);

  useEffect(() => {
    const getProductsList = async () => {
      try {
        const res = await axios.post(
          AUTH_API + `/farmers/get/productsList`,
          {},
          {
            headers: headers,
          }
        );

        if (res && res.data) {
          setProducts(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getProductsList();
  }, []);

  return (
    <div className="row p-3">
      {showLocationModal && (
        <ModalComponent
          shown={showLocationModal}
          close={() => {
            setShowLocationModal(false);
          }}
          title={transProps.usesLocationServices}
          modalBody={renderModal}
          size={"lg"}
          label={transProps.usesLocationServices}
        />
      )}
      <Modal
        shown={modalShown}
        close={() => {
          toggleModal(false);
        }}
      >
        <div>
          <div className="modal-header">
            <span>
              <i className="fa fa-map-pin"></i>
            </span>
            <span> {transProps.usesLocationServices}</span>
          </div>
          <div className="modal-body" style={{ paddingTop: "10px" }}>
            <div className="row">
              <div className="col-md-12">
                <p className="f_weight" style={{ fontSize: "20px" }}>
                  {transProps.locPermissionDenied}
                </p>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={toggleLocation}
            data-dismiss="modal"
            aria-label="Close"
          >
            Close
          </button>
        </div>
      </Modal>

      <div className="col-md-12 mb-2">
        <h2
          tabIndex={0}
          className="font-bold boldFont pt-3"
          style={{ fontSize: "24px" }}
          id="search-criteria-heading"
        >
          {transProps.searchFilters}
        </h2>
        {/* <p className="sr-only">Select one or more search criteria and click search.</p> */}
        <div
          style={{
            border: "1px solid #D9D9D9",
            borderRadius: "6px",
            padding: "10px 10px 20px 10px",
          }}
        >
          <div className="col-sm-12 mb-2">
            <div className="col-md-12" id={"city_select"}>
              <label
                className="boldFont"
                style={{ fontSize: "14px", margin: "0px" }}
              >
                {transProps.county}
              </label>
              <SelectDropdown
                label="County/City"
                dataList={cityList}
                defaultValue={selectedCity}
                onChange={handleCityChange}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-6">
              <label
                className="boldFont"
                style={{ fontSize: "14px", margin: "0px" }}
              >
                {transProps.areaWithin}
              </label>
              <SelectDropdown
                label="Area Within"
                disabled={selectedCity.id === "all"}
                dataList={areaWithinList || []}
                defaultValue={selectedArea}
                onChange={handleAreaChange}
              />
            </div>
            <div className="col-md-6">
              <label
                className="boldFont"
                style={{ fontSize: "14px", margin: "0px" }}
              >
                {transProps.openToday}
              </label>
              <Select
                className="col-sm-12"
                style={customStyles.select}
                value={openToday}
                onChange={handleChange}
                sx={{ border: "1px solid #44A0E3" }}
                inputProps={{ "aria-label": "open Today" }}
              >
                <MenuItem className="language-menu-item" value={"Yes"}>
                  {transProps.yes}
                </MenuItem>
                <MenuItem className="language-menu-item" value={"No"}>
                  {transProps.no}
                </MenuItem>
              </Select>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-6">
              <label
                className="boldFont"
                style={{ fontSize: "14px", margin: "0px" }}
              >
                {transProps.monthOperation}
              </label>
              <div className="multiselect-wrapper" id={"month_multiSelect"}>
                <div className="multiselect-input-div">
                  <MultiSelect
                    options={monthsOfOperation}
                    value={monthNames}
                    onChange={handleMonthsChange}
                    label="Months of Operation"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <label
                className="boldFont"
                style={{ fontSize: "14px", margin: "0px" }}
              >
                {transProps.dayOperation}
              </label>
              <div className="multiselect-wrapper" id={"day_multiSelect"}>
                <div className="multiselect-input-div">
                  <MultiSelect
                    options={daysOfOperation}
                    value={dayNames}
                    onChange={handleDayChange}
                    label="Days of Operation"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <label
              className="boldFont"
              style={{ fontSize: "14px", margin: "0px" }}
            >
              {transProps.vendorName}
            </label>
            <SelectDropdown
              label="Vendor name"
              dataList={vendorList}
              defaultValue={selectedVendors}
              onChange={handleVendorChange}
            />
          </div>
          {/*<div className="col-md-12 mb-2">
            <label
              className="boldFont"
              style={{ fontSize: "14px", margin: "0px" }}
            >
              {transProps.type}
            </label>
             <div className="multiselect-wrapper" id={"type_multiSelect"}>
              <div className="multiselect-input-div">
                <MultiSelect
                  options={vendorType}
                  value={typeNames}
                  onChange={handleTypeChange}
                  label="Types"
                />
              </div>
            </div> 
          </div>*/}
          {/* <div className="col-md-12 mt-2 mb-2">
            <label htmlFor="orderOfOperation">
              <label className="boldFont" style={{ fontSize: "14px" }}>
                {transProps.orderOption}
              </label>
            </label>
            <div className="multiselect-wrapper" id={"order_multiSelect"}>
              <div className="multiselect-input-div">
                <MultiSelect
                  options={orderOptions}
                  value={orderOptionNames}
                  onChange={handleTypeChange}
                  label="Order Of Operations"
                />
              </div>
            </div>
          </div> */}

          {/* <div className="col-md-12 mb-2">
            <label
              className="boldFont"
              style={{ fontSize: "14px", margin: "0px" }}
            >
              Product List
            </label>
            <div className="multiselect-wrapper" id={"order_multiSelect"}>
              <div className="multiselect-input-div">
                <MUIFormControl
                  className="w-100"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#44a0e3", // Hover
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#44a0e3", // Focus
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#44a0e3", // Default and other states
                      },
                    },
                  }}
                >
                  <Select
                    sx={{
                      "& .MuiSelect-select": {
                        padding: "12px 14px !important",
                      },
                    }}
                    placeholder="Select Products"
                    multiple
                    value={selectedNames}
                    onChange={(e) => setSelectedNames(e.target.value)}
                    InputLabelProps={{ shrink: false }}
                    renderValue={(selected) => (
                      <Stack gap={1} direction="row" flexWrap="wrap" mb={1}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            onDelete={() =>
                              setSelectedNames(
                                selectedNames.filter((item) => item !== value)
                              )
                            }
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        ))}
                      </Stack>
                    )}
                  >
                    {products?.map((item) => (
                      <MenuItem
                        key={item.productListId}
                        value={item.name}
                        sx={{
                          justifyContent: "space-between",
                        }}
                      >
                        {item.name}
                        {selectedNames.includes(item.name) ? (
                          <CheckIcon color="info" />
                        ) : null}
                      </MenuItem>
                    ))}
                  </Select>
                </MUIFormControl>
              </div>
            </div>
          </div> */}

          {/* <div className="col-md-3 mt-2 mb-2">
                <label htmlFor="OpenToday" style={{ display: "block" }}>
                  <small className="boldFont">{transProps.openToday}</small>
                </label>
                <Select
                  className="shopAvailable-select"
                  style={customStyles.select}
                  value={openToday}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "open Today" }}
                >
                  <MenuItem className="language-menu-item" value={"Yes"}>
                    {transProps.yes}
                  </MenuItem>
                  <MenuItem className="language-menu-item" value={"No"}>
                    {transProps.no}
                  </MenuItem>
                </Select>
              </div> */}
        </div>
      </div>
      {/* </Modal> */}

      <div className="col-md-12 mb-3 text-center">
        <StyledButton
          onClick={handleClearSearch}
          variant={"outline"}
          text={transProps.clear}
          isIcon="true"
          // faIcon="fa fa-refresh"
          direction="left"
        />
        <StyledButton
          onClick={handleButtonClick}
          variant={"primary"}
          text={transProps.search}
          label={"search"}
          isIcon="true"
          // faIcon="fa fa-search"
          direction="left"
        />
      </div>
      {/* {props.resultShown && (
          <h2
            tabIndex={0}
            className="font-bold boldFont pt-3"
            style={{ fontSize: "24px" }}
            id="result-criteria-heading"
          >
            {language["Results Section"]}
          </h2>
        )} */}
    </div>
  );
};

SearchComponent.propTypes = {
  updateBooleanValue: PropTypes.func,
  mapdata: PropTypes.object,
  getCityList: PropTypes.func.isRequired,
  getVendorList: PropTypes.func.isRequired,
  getMapStoresList: PropTypes.func,
  searchProperties: PropTypes.func,
  coordinates: PropTypes.func,
  // modalShown: PropTypes.func,
  showLocationModal: PropTypes.func,
  setIsLoading: PropTypes.func,
  mapSearchPayload: PropTypes.func.isRequired,
  label: PropTypes.string,
  resultShown: PropTypes.bool,
  isClearSearch: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMapData: (searchProperties) => {
      dispatch({ type: GET_MAPDATA, search: searchProperties });
    },
    getCityList: (city_county) => {
      dispatch({ type: GET_COUNTYCITY, city: city_county });
    },
    getVendorList: (payload) => {
      dispatch({ type: GET_VENDOR_LIST, payload: payload });
    },
    getMapStoresList: (payload) =>
      dispatch({ type: GET_STORE_LIST, payload: payload }),
    mapSearchPayload: (payload) =>
      dispatch({ type: SET_MAP_SEARCH, payload: payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);
