import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DataGridTable from "../../common/DataGrid";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px !important",
    fontWeight: "700 !important",
    color: "#6C6C6C",
  },
  typography: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
  },
}));

const AdminTransaction = () => {
  const classes = useStyles();

  const COLOMNS = [
    {
      field: "merchantName",
      headerName: "Merchant Name",
      flex: 1,
    },
    {
      field: "merchantID",
      headerName: "Merchant ID",
      flex: 1,
    },
    {
      field: "storeAddress",
      headerName: "Store Address",
      flex: 1,
    },
    {
      field: "txnDate",
      headerName: "Transaction Date",
      flex: 1,
    },
    {
      field: "saleAmount",
      headerName: "Sale Amount",
      flex: 1,
    },
    {
      field: "cardAmount",
      headerName: "Card Amount",
      flex: 1,
    }
  ];

  const ROWS = [
    {
      merchantID: "APOOO155",
      merchantName: "Blocks Stand and Greenhouse",
      storeAddress: "3225 Centre Blvd, Lansing, MI, 48912",
      txnDate: "Mar 31, 2024",
      saleAmount: "$7.00",
      cardAmount: "$3.00",
    },
    {
      merchantID: "APOOO155",
      merchantName: "Blocks Stand and Greenhouse",
      storeAddress: "3225 Centre Blvd, Lansing, MI, 48912",
      txnDate: "Mar 31, 2024",
      saleAmount: "$7.00",
      cardAmount: "$3.00",
    },
    {
      merchantID: "APOOO155",
      merchantName: "Blocks Stand and Greenhouse",
      storeAddress: "3225 Centre Blvd, Lansing, MI, 48912",
      txnDate: "Mar 31, 2024",
      saleAmount: "$7.00",
      cardAmount: "$3.00",
    },
    {
      merchantID: "APOOO155",
      merchantName: "Blocks Stand and Greenhouse",
      storeAddress: "3225 Centre Blvd, Lansing, MI, 48912",
      txnDate: "Mar 31, 2024",
      saleAmount: "$7.00",
      cardAmount: "$3.00",
    },
    {
      merchantID: "APOOO155",
      merchantName: "Blocks Stand and Greenhouse",
      storeAddress: "3225 Centre Blvd, Lansing, MI, 48912",
      txnDate: "Mar 31, 2024",
      saleAmount: "$7.00",
      cardAmount: "$3.00",
    }
  ];

  return (
    <Box flexGrow={1} m={2}>
      <Typography className={classes.title} mb={1}>
        Transaction Details
      </Typography>
      <DataGridTable columns={COLOMNS} data={ROWS} />
    </Box>
  );
};
export default AdminTransaction;
