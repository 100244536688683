import React from 'react';
import { Badge, Stack, Button } from '@mui/material';
import PropTypes from "prop-types";
import CommentIcon from '@mui/icons-material/Comment';

const CommentBadge = (props) => {
     return (
        <Stack direction="row" spacing={0.5} style={{ display: 'inline' }} onClick={props.onClick}>
            <Badge badgeContent={props.total} color='success'>
                <Button variant="outlined" color='success' sx={{color:"#006e52",textTransform:"capitalize"}} endIcon={<CommentIcon />}>
                    {props.title}
                </Button>
            </Badge>
        </Stack>
    )
}

CommentBadge.propTypes = {
    total:PropTypes.number,
    title: PropTypes.string,
    onClick: PropTypes.func
}

export default CommentBadge;