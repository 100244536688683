import React, { useEffect, useState } from "react";
import { Grid, TablePagination } from "@mui/material";
import "../../styles/home.scss";
import Alert from "@mui/material/Alert";
import { useNavigate, useSearchParams } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PageHeaderComponent from "../common/components/pageHeader";
import {
  getManageVendorList,
  refreshKey,
  manageDeleteVendorDetails,
  getFollowVendorDetails,
  updateVendorBulk,
  getVendorHistoryList,
  exportVendorReport,
  exportVendorLastUpdateReport,
  exportVendorLoginReport,
  updateFollowUpFlag,
  updatePrimaryEmail,
} from "../../redux/actionTypes/vendordetails";
import moment from "moment";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TableComponent from "../common/components/table";
import CloseIcon from "@mui/icons-material/Close";
import Form from "react-bootstrap/Form";
import { Row, Col, Tabs, Tab } from "react-bootstrap";

import CheckIcon from "@mui/icons-material/Check";
import { Link } from "react-router-dom";
import SearchComponent from "../common/components/searchComponent";
import {
  popUpAlert,
  checkToken,
  removeSpecialCharacter,
} from "../../utils/globalMethods";
import ModalComponent from "../common/components/modal";
import { CustomButton } from "../common/components/customButton";
import CustomButtonDropDown from "../common/components/customButtonDropDown";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import AuditIcon from "@mui/icons-material/History";
import FlagIcon from "@mui/icons-material/Flag";
import AuditHistoryComponent from "./auditHistoryComponent";
import VendorDetails from "./vendorDetails";
import AlertDetailsComponent from "./alertDetails";
import DateTimePicker from "../common/components/dateTimePicker";
import CustomSwitch from "../common/components/customSwitch";
import { convertUTCDateToLocalDate } from "../../utils/globalMethods";
import SelectVendorTypeComponent from "./selectVendorType";
import { vendorType } from "../../constants/constants";
import { useForm } from "react-hook-form";
import { Box } from "@mui/system";
import TextButton from "../common/components/TextButton";
import VendorList from "./VendorList";

function createData(
  firstName,
  lastName,
  fnsNum,
  vendorName,
  primaryEmail,
  primaryMobile,
  publicEmail,
  publicMobile,
  lastUpdtTs,
  isVerified,
  isActive,
  createdBy,
  id,
  followUpFlag,
  adminLastUpdtTs,
  createTimeStamp,
  lastUpdtTimeStamp,
  primary_contact_info,
  public_website,
  products,
  business_desc,
  public_photo,
  isEnableDelBtn,
  row
) {
  return {
    firstName,
    lastName,
    fnsNum,
    vendorName,
    primaryEmail,
    primaryMobile,
    publicEmail,
    publicMobile,
    lastUpdtTs: convertUTCDateToLocalDate(lastUpdtTs),
    isVerified: isVerified,
    isActive: isActive,
    createdBy,
    id,
    followUpFlag,
    adminLastUpdtTs: adminLastUpdtTs
      ? convertUTCDateToLocalDate(adminLastUpdtTs)
      : "-",
    createTimeStamp,
    lastUpdtTimeStamp,
    primary_contact_info,
    public_website,
    products,
    business_desc,
    public_photo,
    isEnableDelBtn,
    row,
  };
}

function createFollowVendorData(
  vendorName,
  primaryEmail,
  fnsNum,
  isVerified,
  id
) {
  const isVer = () => {
    if (isVerified === "Y") {
      return <b> Yes</b>;
    } else {
      return (
        <a
          href={
            "/kfreshfep/vendors/" +
            (vendorType === "HIPVDR" ? "hip" : "csa") +
            "?vendor_id=" +
            id
          }
        >
          {" "}
          Pending
        </a>
      );
    }
  };
  return {
    vendorName,
    primaryEmail,
    fnsNum,
    isVerified: isVer(),
    id,
  };
}

const VendorComponent = (props) => {
  const navigate = useNavigate();

  const userRole = props?.userAuth?.user
    ? props?.userAuth?.user
    : JSON.parse(sessionStorage.getItem("userData"));
  const [activeTab, setActiveTab] = useState("PE");
  const [manageVendorListTotal, setManageVendorListTotal] = useState(0);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [hipFollowRows, setHipFollowRows] = useState([]);
  const [csaFollowRows, setCSAFollowRows] = useState([]);
  const [hipTotal, setHipTotal] = useState(0);
  const [csaTotal, setCSATotal] = useState(0);
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [search, setSearch] = useState("");
  const [searchParams] = useSearchParams();
  const [isVerifiedFilter, setIsVerifiedFilter] = useState(
    searchParams.get("isVerified") || "Y"
  );
  const [vendorType, setVendorType] = useState("HIPVDR");
  const [isNotVerifiedFilter, setIsNotVerifiedFilter] = useState("Y");
  const [isActiveFilter, setIsActiveFilter] = useState("Y");
  const [isNotActiveFilter, setIsNotActiveFilter] = useState("Y");
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [followVendorListTotal, setFollowVendorListTotal] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showFollowUpFlag, setShowFollowUpFlag] = useState(false);
  const [showCSASnapHand, setshowCSASnapHand] = useState(false);
  const [showAuditModal, setShowAuditModal] = useState(false);
  const [showVendorLastUpdate, setShowVendorLastUpdate] = useState(false);
  const [followupVendor, setFollowupVendor] = useState();
  const [onUpdate, setOnUpdate] = useState({});
  const [tableSort, setTableSort] = useState({ lastUpdtTs: "DESC" });
  const [vendorInfo, setVendorInfo] = useState({});
  const [isRefresh, setIsRefresh] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [expand, setExpand] = useState(false);
  const [vendorName, setVendorName] = useState();
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [editEmail, setEditEmail] = useState();

  useEffect(() => {
    const userInfo = props?.userAuth?.user
      ? props?.userAuth?.user
      : JSON.parse(sessionStorage.getItem("userData"));
    // if (
    //   props?.allowedRoles &&
    //   userInfo &&
    //   userInfo !== null &&
    //   Object.keys(userInfo).length != 0
    // ) {
    //   const checkRole = props.allowedRoles.find((role) =>
    //     userInfo?.Role.includes(role)
    //   );
    //   if (!checkRole) {
    //     navigate("/kfreshfep");
    //     return;
    //   }
    // }
    setOnUpdate({});
    setSelectedRows([]);
    const payload = {
      vendorType: vendorType,
      isVerified: isVerifiedFilter,
      isNotVerified: isNotVerifiedFilter,
      // isVerified: isVerifiedFilter,
      // isNotVerified: isVerifiedFilter === "N" ? "Y" : "N",
      status: activeTab,
      isActive: isActiveFilter,
      isNotActive: isNotActiveFilter,
      name: search,
      lastUpdtTsSort: "lastUpdtTs" in tableSort ? tableSort["lastUpdtTs"] : "",
      size: size,
      from: from,
      isVerifiedSort: "isVerified" in tableSort ? tableSort["isVerified"] : "",
      adminLastUpdtTsSort:
        "adminLastUpdtTs" in tableSort ? tableSort["adminLastUpdtTs"] : "",
      // createTimeStamp: 1710393560300,
      // ipAddress: "1",
      // lastUpdtTimeStamp: 1710393560300,
      // lastUpdtUidNam: "ADM_2060",
    };
    if (checkToken()) {
      props.getManageVendorList(payload);
    }
  }, [
    size,
    from,
    props.vendorDetails.refreshKey,
    search,
    isVerifiedFilter,
    isNotVerifiedFilter,
    isActiveFilter,
    isNotActiveFilter,
    tableSort,
    vendorType,
    activeTab,
  ]);

  useEffect(() => {
    const { manageVendorList } = props.vendorDetails;
    let data = [];
    manageVendorList.list.forEach((element) => {
      data.push(
        createData(
          element.firstName,
          element.lastName,
          element.fnsNum,
          element.vendorName,
          element.primaryEmail,
          element.primaryMobile,
          element.publicEmail,
          element.publicMobile,
          element.lastUpdtTs,
          element.isVerified,
          element.isActive,
          element.createdBy,
          element.id,
          element.followUpFlag,
          element.adminLastUpdtTs,
          element.createTimeStamp,
          element.lastUpdtTimeStamp,
          element.primary_contact_info,
          element.public_website,
          element.products,
          element.business_desc,
          element.public_photo,
          element.isEnableDelBtn,
          element
        )
      );
    });
    setRows(data);
    handleColumns();
    setManageVendorListTotal(manageVendorList.total);
    setShowLoader(manageVendorList.loading);
  }, [props.vendorDetails.manageVendorList]);

  useEffect(() => {
    const userInfo = props?.userAuth?.user
      ? props?.userAuth?.user
      : JSON.parse(sessionStorage.getItem("userData"));
    // if (
    //   props?.allowedRoles &&
    //   userInfo &&
    //   userInfo !== null &&
    //   Object.keys(userInfo).length != 0
    // ) {
    //   const checkRole = props.allowedRoles.find((role) =>
    //     userInfo?.Role.includes(role)
    //   );
    //   if (!checkRole) {
    //     navigate("/kfreshfep");
    //     return;
    //   }
    // }
    const payload = {
      size: size,
      isVerified: "N",
      from: from,
      // createTimeStamp: 1710390575540,
      // ipAddress: "1",
      // lastUpdtTimeStamp: 1710390575540,
      // lastUpdtUidNam: "ADM_2060",
    };
    if (checkToken()) {
      props.getFollowVendorDetails(payload);
    }
  }, [size, from, props.vendorDetails.refreshKey]);

  useEffect(() => {
    const { followVendorDetails } = props.vendorDetails;

    let followVendorData = [];
    let followCSAVendorData = [];

    if (followVendorDetails.hipList && followVendorDetails.hipList.length) {
      followVendorDetails.hipList.forEach((element) => {
        followVendorData.push(
          createFollowVendorData(
            element.vendorName,
            element.primaryEmail,
            element.fnsNum,
            element.isVerified,
            element.id
          )
        );
      });
    }
    if (followVendorDetails.csaList && followVendorDetails.csaList.length) {
      followVendorDetails.csaList.forEach((element) => {
        followCSAVendorData.push(
          createFollowVendorData(
            element.vendorName,
            element.primaryEmail,
            element.fnsNum,
            element.isVerified,
            element.id
          )
        );
      });
    }
    setHipFollowRows(followVendorData);
    setCSAFollowRows(followCSAVendorData);
    setHipTotal(followVendorDetails?.hipTotal);
    setCSATotal(followVendorDetails?.csaTotal);
    setFollowVendorListTotal(followVendorDetails.total);
  }, [props.vendorDetails.followVendorDetails]);

  const handleNext = (page, rowSize) => {
    size != rowSize && setSize(rowSize);
    setFrom(rowSize * page);
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowClick = (e, row) => {
    var updatedSelectedRows = [];
    if (e.target.checked) {
      updatedSelectedRows = [...selectedRows, row];
    } else {
      updatedSelectedRows = selectedRows.filter((r) => r.id !== row.id);
    }
    setSelectAll(true);
    setSelectedRows(updatedSelectedRows);
  };

  const handleBulkUpdate = (items) => {
    let payload = [];
    items.map((item) => {
      payload.push({
        id: item.id,
        isActive: item.isActive,
        lastUpdateTime: item.lastUpdtTs,
        primaryEmail: item.primaryEmail,
        vendorType: vendorType,
      });
    });
    setSelectedRows([]);
    props.updateVendorBulk(payload);
  };

  const handleSort = (item) => {
    let sortitem = {};
    if (item.sort == "" || item.sort == "DESC") {
      sortitem[item.id] = "ASC";
    } else {
      sortitem[item.id] = "DESC";
    }
    setTableSort(sortitem);
  };

  const handleColumns = () => {
    const headers = [
      { id: "checkbox", label: "" },
      { id: "details", label: "Details" },
      {
        id: "vendorName",
        label: "Vendor Name",
        cell: (item) => {
          return (
            <>
              <Button
                sx={{
                  color: "#000",
                  fontSize: "20px",
                  textTransform: "capitalize",
                  fontFamily: "Texta-book",
                  whiteSpace: "nowrap",
                }}
                variant="text"
                startIcon={<VisibilityIcon />}
                onClick={() =>
                  navigate(
                    "/kfreshfep/vendors/" +
                      (vendorType === "HIPVDR" ? "hip" : "csa") +
                      "?vendor_id=" +
                      item.id
                  )
                }
              >
                {removeSpecialCharacter(item.row.vendorName)}
              </Button>
              <br />
              <span style={{ color: "#999" }}>Federal ID: </span> <br />{" "}
              <span style={{ overflowWrap: "anywhere" }}>
                {item.row.fnsNum}
              </span>{" "}
              <br />
              {item.row.isHipAndCsaVdr ? (
                <>
                  <span className="badge badge-primary mr-1">
                    {item?.row?.isDeletedCsaVendor ? "Deleted: " : ""} CSA SNAP
                    Vendor
                  </span>
                  <span className="badge badge-info">FEP Vendor</span>
                </>
              ) : item.row.isHIPVendor === "Y" ? (
                <span className="badge badge-info">FEP Vendor</span>
              ) : (
                <span className="badge badge-primary">CSA SNAP Vendor</span>
              )}
            </>
          );
        },
      },
      {
        id: "primaryEmail",
        label: "Primary Email",
        cell: (item) => {
          return (
            <>
              {item.primaryEmail}
              {item.row.isHIPVendor === "Y" &&
                userRole?.Role &&
                userRole?.Role === "ADMIN" && (
                  <>
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        handleOnEditEmailAction(item);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </>
                )}
            </>
          );
        },
      },
      {
        id: "isActive",
        label: "Status",
        cell: (item) => {
          return (
            <CustomSwitch
              checked={item.isActive === "Y"}
              verified={item.isVerified === "Y"}
              onChange={(status) => {
                item["isActive"] = status ? "Y" : "N";
                item["lastUpdtTs"] = new Date().getTime();
                handleBulkUpdate([item]);
              }}
            />
          );
        },
      },
      {
        id: "isVerified",
        label: "Verified",
        sort: "isVerified" in tableSort ? tableSort["isVerified"] : "",
        cell: (item) => {
          if (item.row.isVerified === "Y") {
            return "Yes";
          } else {
            return (
              <a
                href={
                  "/kfreshfep/vendors/" +
                  (vendorType === "HIPVDR" ? "hip" : "csa") +
                  "?vendor_id=" +
                  item.id
                }
              >
                {" "}
                Pending
              </a>
            );
          }
        },
      },
      {
        id: "lastUpdtTs",
        label: "Vendor Last Updated Time",
        sort: "lastUpdtTs" in tableSort ? tableSort["lastUpdtTs"] : "",
        cell: (item) => {
          return (
            <>
              {convertUTCDateToLocalDate(item["row"]["lastUpdtTs"])}
              <IconButton
                aria-label="edit"
                onClick={() => {
                  setOnUpdate({ ...item, lastUpdtTs: new Date().getTime() });
                  setShowVendorLastUpdate(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          );
        },
      },
      {
        id: "adminLastUpdtTs",
        label: "Staff Last Updated Time",
        sort:
          "adminLastUpdtTs" in tableSort ? tableSort["adminLastUpdtTs"] : "",
      },
      {
        id: "action",
        label: "Action",
        cell: (item) => {
          return (
            <>
              {item?.isEnableDelBtn && userRole?.Role === "ADMIN" && (
                <IconButton
                  aria-label="delete"
                  onClick={() => handleOnDeleteAction(item)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              <IconButton
                aria-label="audit"
                onClick={() => handleOnAuditAction(item)}
              >
                <AuditIcon />
              </IconButton>
              {item.row.isVerified === "N" && (
                <>
                  <IconButton
                    aria-label="flag"
                    onClick={() => handleOnFollowUpAction(item)}
                  >
                    <FlagIcon />
                  </IconButton>
                </>
              )}
            </>
          );
        },
      },
    ];
    setColumns(headers);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value.toLowerCase());
    setFrom(0);
  };

  const handleDelete = (row) => {
    const payload = {
      vendorId: row.id,
      vendorType,
    };
    props.manageDeleteVendorDetails(payload);
  };

  const handleOnDeleteAction = (row) => {
    popUpAlert({
      width: "400px",
      title: "Are you sure?",
      text: "Vendor will be removed from the application",
      icon: "warning",
      color: "#545454",
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonWidth: "700px",
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#AAAAAA",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => handleDelete(row),
    });
  };

  const handleOnAuditAction = (row) => {
    setShowAuditModal(true);
    setOnUpdate(row);
  };

  const handleIsVerifiedCheckboxChange = (e) => {
    if (e.target.checked) {
      setIsVerifiedFilter("Y");
    } else {
      setIsVerifiedFilter("N");
    }
  };
  const handleIsNotVerifiedCheckboxChange = (e) => {
    if (e.target.checked) {
      setIsNotVerifiedFilter("Y");
    } else {
      setIsNotVerifiedFilter("N");
    }
  };

  const handleIsActiveCheckboxChange = (e) => {
    if (e.target.checked) {
      setIsActiveFilter("Y");
    } else {
      setIsActiveFilter("N");
    }
  };

  const handleIsNotActiveCheckboxChange = (e) => {
    if (e.target.checked) {
      setIsNotActiveFilter("Y");
    } else {
      setIsNotActiveFilter("N");
    }
  };
  // RE
  const handleSelectVendorType = (e) => {
    if (e.target.value === "HIPVDR") {
      setVendorType("HIPVDR");
    } else {
      setVendorType("CSAVDR");
    }
    setShowLoader(true);
  };
  const handleFollowVendorVerification = () => {
    setShowModal(true);
  };

  const renderModal = () => {
    return (
      <AlertDetailsComponent
        hipFollowRows={hipFollowRows}
        csaFollowRows={csaFollowRows}
        hipTotal={hipTotal}
        csaTotal={csaTotal}
        followVendorListTotal={followVendorListTotal}
      />
    );
  };

  const renderEmailModal = () => {
    let initial = {
      primaryEmail: editEmail.primaryEmail,
      id: editEmail.id,
    };
    // eslint-disable-next-line no-unused-vars
    const [formValue, setFormValue] = useState(initial);

    const {
      register,
      formState: { errors },
      handleSubmit,
    } = useForm({
      defaultValues: formValue,
    });
    return (
      <Grid item xs={12}>
        <Form>
          <Row>
            <Col>
              <Form.Group as={Col} controlId="formPrimaryEmail">
                <Form.Label className="smallTitle">Primary Email *</Form.Label>
                <Form.Control
                  type="text"
                  size="lg"
                  className="lowercase"
                  {...register("primaryEmail", {
                    required: "Please enter Email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email format",
                    },
                  })}
                  isInvalid={Boolean(errors.primaryEmail)}
                />
                {errors.primaryEmail && (
                  <span className="dh-txt-danger">
                    {errors.primaryEmail.message}
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="modal-footer mt-4">
            <Col>
              <CustomButton
                variant="contained"
                sx={{ float: "right" }}
                endIcon={<ArrowForwardIcon />}
                onClick={handleSubmit(handleEditEmailSubmit)}
              >
                Update
              </CustomButton>
            </Col>
          </Row>
        </Form>
      </Grid>
    );
  };
  const renderVendorLastUpdateModal = () => {
    return (
      <Form>
        <Row>
          <Col>
            <Form.Label>Pick a date and time *</Form.Label>
            <DateTimePicker
              handleChange={(newValue) => {
                setOnUpdate({
                  ...onUpdate,
                  lastUpdtTs: newValue.unix() * 1000,
                });
              }}
              maxDate={new Date().getTime()}
            />
          </Col>
        </Row>
        <Row className="modal-footer mt-4">
          <Col>
            <CustomButton
              variant="contained"
              sx={{ float: "right" }}
              endIcon={<ArrowForwardIcon />}
              onClick={() => {
                handleBulkUpdate([onUpdate]);
                setOnUpdate({});
                setShowVendorLastUpdate(false);
              }}
            >
              Update
            </CustomButton>
          </Col>
        </Row>
      </Form>
    );
  };

  const handleUpdateInActive = () => {
    const bulkupdate = selectedRows.map((selectedRow) => {
      selectedRow.isActive = "N";
      selectedRow.lastUpdtTs =
        moment(selectedRow.lastUpdtTs, "MM/DD/YYYY h:mm A").unix() * 1000;
      return selectedRow;
    });
    const payload = bulkupdate;
    if (payload.length > 0) {
      handleBulkUpdate(payload);
    } else {
      popUpAlert({
        title: "Error",
        text: "Select atleast one vendor",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "OK",
        closeOnConfirm: true,
      });
    }
  };

  const handleUpdateActive = () => {
    const bulkupdate = selectedRows.map((selectedRow) => {
      selectedRow.isActive = selectedRow.isVerified == "N" ? "N" : "Y";
      selectedRow.lastUpdtTs =
        moment(selectedRow.lastUpdtTs, "MM/DD/YYYY h:mm A").unix() * 1000;
      return selectedRow;
    });
    const payload = bulkupdate;
    if (payload.length > 0) {
      handleBulkUpdate(payload);
    } else {
      popUpAlert({
        title: "Error",
        text: "Select atleast one vendor",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "OK",
        closeOnConfirm: true,
      });
    }
  };

  const handleOnEditEmailAction = (row) => {
    setShowEditEmail(true);
    setEditEmail(row);
  };

  const handleEditEmailSubmit = (item) => {
    const payload = {
      id: item.id,
      primaryEmail: item.primaryEmail.toLowerCase(),
    };
    // setIsRefresh(true);
    props.updatePrimaryEmail(payload);
    // props.refreshKey();
    setShowEditEmail(false);
  };

  const handleFollowUpSubmit = (id) => {
    setShowFollowUpFlag(false);
    const payload = {
      id: id,
      followUpFlag: "Y",
      vendorType,
    };
    props.updateFollowUpFlag(payload);
  };

  const handleOnFollowUpAction = (row) => {
    setShowFollowUpFlag(true);
    setFollowupVendor(row);
    const vN = row.vendorName;
    setVendorName(vN);
  };

  const renderFollowUpModal = () => {
    const displayText =
      followupVendor.vendorName +
      " vendor will remind after two days for further verification";
    return (
      <Grid item xs={12}>
        <p>{displayText}</p>
        <Button
          variant="contained"
          className="followUp-Button"
          onClick={() => {
            setShowFollowUpFlag(false);
            handleFollowUpSubmit(followupVendor?.id);
          }}
        >
          OK
        </Button>
      </Grid>
    );
  };

  const renderSnapModal = () => {
    const displayText = "Set " + vendorName + " vendor as CSA SNAP Vendor?";
    return (
      <Grid item xs={12}>
        <p>{displayText}</p>
        <Button
          variant="contained"
          className="handshake-Button"
          onClick={() => setshowCSASnapHand(false)}
        >
          Yes
        </Button>
      </Grid>
    );
  };
  const handleMenuItemClick = (option) => {
    const payload = { vendorType: vendorType };
    if (
      option === "FEP Verified Vendor(s)" ||
      option === "CSA Verified Vendor(s)"
    ) {
      props.exportVendorReport(payload);
    } else if (option === "FEP Vendor(s) Last Updated Time (90 Days)") {
      props.exportVendorLastUpdateReport(payload);
    } else if (option === "FEP Vendor(s) Last Login (30 Days)") {
      props.exportVendorLoginReport(payload);
    }
  };

  const renderAuditModal = () => {
    return <AuditHistoryComponent row={onUpdate} vendorType={vendorType} />;
  };

  const renderDetails = (row) => {
    return (
      <VendorDetails
        row={row}
        vendorAddtInfo={isRefresh ? {} : vendorInfo}
        vendorType={vendorType}
        setVendorAddtInfo={(data) => {
          setVendorInfo(data);
        }}
        showVendorHistoryDetails={false}
      />
    );
  };

  return (
    <Stack>
      <Grid container className="container-block" rowSpacing={2}>
        {showEditEmail && (
          <ModalComponent
            shown={showEditEmail}
            close={() => {
              setShowEditEmail(false);
            }}
            title={"EDIT EMAIL"}
            modalBody={renderEmailModal}
            label={"EDIT EMAIL"}
          />
        )}
        {showModal && (
          <ModalComponent
            shown={showModal}
            close={() => {
              setShowModal(false);
            }}
            title="PENDING VERIFICATION FOLLOW UP"
            modalBody={renderModal}
            size={"xl"}
            dialogClassName={"modal-90w"}
            label={"PENDING VERIFICATION FOLLOW UP"}
          />
        )}
        {showFollowUpFlag && (
          <ModalComponent
            shown={showFollowUpFlag}
            close={() => {
              setShowFollowUpFlag(false);
            }}
            title="PENDING VERIFICATION FOLLOW UP"
            modalBody={renderFollowUpModal}
            label={"PENDING VERIFICATION FOLLOW UP"}
          />
        )}
        {showCSASnapHand && (
          <ModalComponent
            shown={showCSASnapHand}
            close={() => {
              setshowCSASnapHand(false);
            }}
            title="CSA SNAP VENDOR CONFIRMATION"
            modalBody={renderSnapModal}
            label={"CSA SNAP VENDOR CONFIRMATION"}
          />
        )}
        {showAuditModal && (
          <ModalComponent
            shown={showAuditModal}
            close={() => {
              setShowAuditModal(false);
              setOnUpdate({});
            }}
            title="AUDIT HISTORY"
            modalBody={renderAuditModal}
            size={"xl"}
            dialogClassName={"modal-90w"}
            label={"AUDIT HISTORY"}
          />
        )}

        {showVendorLastUpdate && (
          <ModalComponent
            shown={showVendorLastUpdate}
            close={() => {
              setOnUpdate({});
              setShowVendorLastUpdate(false);
            }}
            title="EDIT VENDOR LAST UPDATED TIME"
            modalBody={renderVendorLastUpdateModal}
            size={"md"}
            label={"EDIT VENDOR LAST UPDATED TIME"}
          />
        )}

        {/* <Grid item xs={12} spacing={1}>
          <Alert
            className="alert alert-danger "
            icon={<NotificationsIcon />}
            severity="error"
          >
            <span className="font-book">
              {" "}
              Hello Admin ! You have <b>{followVendorListTotal}</b> follow up
              reminder for vendor verification.{" "}
              <Link onClick={handleFollowVendorVerification}>View More</Link>
            </span>
          </Alert>
        </Grid> */}
        <Grid item xs={12} md={4} lg={5}>
          <PageHeaderComponent
            title={"Farmer(s)"}
            showSubtitle={true}
            subtitle={"Total Farmers"}
            count={manageVendorListTotal}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={7} sx={{ flexGrow: 1 }}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={0.5}
            justifyContent="flex-end"
          >
            {/* <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={isVerifiedFilter === "Y"}
                    onChange={handleIsVerifiedCheckboxChange}
                  />
                }
                label="Verified"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    onChange={handleIsNotVerifiedCheckboxChange}
                  />
                }
                label="Not Verified"
              />
            </FormGroup> */}
            {/* <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    onChange={handleIsActiveCheckboxChange}
                  />
                }
                label="Active"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    onChange={handleIsNotActiveCheckboxChange}
                  />
                }
                label="Inactive"
              />
            </FormGroup> */}

            {/* <CustomButtonDropDown
              variant={"outlined"}
              title={"Export"}
              options={
                vendorType == "HIPVDR"
                  ? [
                      "SPF Verified Vendor(s)",
                      "SPF Vendor(s) Last Updated Time (90 Days)",
                      "SPF Vendor(s) Last Login (30 Days)",
                    ]
                  : ["SPF Verified Vendor(s)"]
              }
              handleMenuItemClick={handleMenuItemClick}
            /> */}
            <CustomButton
              variant="contained"
              startIcon={<i className="fa fa-plus" aria-hidden="true"></i>}
              onClick={() => navigate("/kfreshfep/registration")}
            >
              Add
            </CustomButton>
            <CustomButton
              variant="outlined"
              startIcon={<i className="fa fa-refresh" aria-hidden="true"></i>}
              onClick={() => {
                setIsRefresh(true);
                setSearch("");
                setExpand(false);
                setVendorInfo({});
                props.refreshKey();
                setIsRefresh(false);
                setShowLoader(true);
              }}
            >
              Refresh
            </CustomButton>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
          {/* <Stack direction="row" spacing={0.8}>
            <CustomButton
              variant="outlined"
              startIcon={<CloseIcon />}
              onClick={handleUpdateInActive}
            >
              Inactive
            </CustomButton>
            <CustomButton
              variant="outlined"
              startIcon={<CheckIcon />}
              onClick={handleUpdateActive}
            >
              Active
            </CustomButton>
          </Stack> */}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ flexGrow: 1, display: "flex", justifyContent: "end" }}
        >
          {/* <SelectVendorTypeComponent
            handleChange={handleSelectVendorType}
            vendorType={vendorType}
          /> */}
          <SearchComponent
            placeholder={"Merchant Name, Email, Name, Mobile"}
            handleChange={handleSearch}
            searchText={search}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            showDetails={true}
            expand={expand}
            renderDetails={renderDetails}
            handlePagination={handleNext}
            total={manageVendorListTotal}
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
            handleRowClick={handleRowClick}
            selectedRows={selectedRows}
            handleSort={handleSort}
            page={Math.floor(from / size)}
            rowsPerPage={size}
            dataLoading={showLoader}
          />
        </Grid> */}
      </Grid>
      <Stack p="1rem">
        <Tabs
          id="controlled-tab"
          activeKey={activeTab}
          onSelect={setActiveTab}
          style={{ background: "#fff" }}
        >
          <Tab
            eventKey="PE"
            title={
              <div className="tab-header">
                <i className="fa fa-hourglass-end"></i>
                <span className="m-l-5">Pending</span>
              </div>
            }
          >
            <div className="tab-content-wrapper">
              <VendorList
                vendorList={rows}
                userRole={userRole}
                vendorType={vendorType}
                handleOnAuditAction={handleOnAuditAction}
                handleOnDeleteAction={handleOnDeleteAction}
                handleOnFollowUpAction={handleOnFollowUpAction}
                handleBulkUpdate={handleBulkUpdate}
                handleOnEditEmailAction={handleOnEditEmailAction}
                handleRowClick={handleRowClick}
                selectedRows={selectedRows}
                renderDetails={renderDetails}
              />
            </div>
          </Tab>
          <Tab
            eventKey="AP"
            title={
              <div className="tab-header">
                <i className="fa fa-check-square-o"></i>
                <span className="m-l-5">Approved</span>
              </div>
            }
          >
            <div className="tab-content-wrapper">
              <VendorList
                vendorList={rows}
                userRole={userRole}
                vendorType={vendorType}
                handleOnAuditAction={handleOnAuditAction}
                handleOnDeleteAction={handleOnDeleteAction}
                handleOnFollowUpAction={handleOnFollowUpAction}
                handleBulkUpdate={handleBulkUpdate}
                handleOnEditEmailAction={handleOnEditEmailAction}
                handleRowClick={handleRowClick}
                selectedRows={selectedRows}
                renderDetails={renderDetails}
              />
            </div>
          </Tab>
          <Tab
            eventKey="DE"
            title={
              <div className="tab-header">
                <i className="fa fa-ban"></i>
                <span className="m-l-5">Denied</span>
              </div>
            }
          >
            <div className="tab-content-wrapper">
              <VendorList
                vendorList={rows}
                userRole={userRole}
                vendorType={vendorType}
                handleOnAuditAction={handleOnAuditAction}
                handleOnDeleteAction={handleOnDeleteAction}
                handleOnFollowUpAction={handleOnFollowUpAction}
                handleBulkUpdate={handleBulkUpdate}
                handleOnEditEmailAction={handleOnEditEmailAction}
                handleRowClick={handleRowClick}
                selectedRows={selectedRows}
                renderDetails={renderDetails}
              />
            </div>
          </Tab>
        </Tabs>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={manageVendorListTotal}
          rowsPerPage={size}
          page={Math.floor(from / size)}
          onPageChange={(e, newPage) => handleNext(newPage, size)}
          onRowsPerPageChange={(e) => handleNext(0, +e.target.value)}
          className={"paginationBlock"}
        />
      </Stack>
    </Stack>
  );
};

VendorComponent.propTypes = {
  getManageVendorList: PropTypes.func.isRequired,
  vendorDetails: PropTypes.object,
  refreshKey: PropTypes.func.isRequired,
  manageDeleteVendorDetails: PropTypes.func.isRequired,
  getFollowVendorDetails: PropTypes.func.isRequired,
  updateVendorBulk: PropTypes.func.isRequired,
  exportVendorReport: PropTypes.func.isRequired,
  exportVendorLastUpdateReport: PropTypes.func.isRequired,
  exportVendorLoginReport: PropTypes.func.isRequired,
  getVendorHistoryList: PropTypes.func.isRequired,
  updateFollowUpFlag: PropTypes.func.isRequired,
  allowedRoles: PropTypes.array,
  userAuth: PropTypes.object,
  updatePrimaryEmail: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    getManageVendorList: (payload) => dispatch(getManageVendorList(payload)),
    manageDeleteVendorDetails: (payload) =>
      dispatch(manageDeleteVendorDetails(payload)),
    refreshKey: () => dispatch(refreshKey()),
    getFollowVendorDetails: (payload) =>
      dispatch(getFollowVendorDetails(payload)),
    updateVendorBulk: (payload) => dispatch(updateVendorBulk(payload)),
    exportVendorReport: (payload) => dispatch(exportVendorReport(payload)),
    exportVendorLastUpdateReport: (payload) =>
      dispatch(exportVendorLastUpdateReport(payload)),
    exportVendorLoginReport: (payload) =>
      dispatch(exportVendorLoginReport(payload)),
    getVendorHistoryList: (payload) => dispatch(getVendorHistoryList(payload)),
    updateFollowUpFlag: (payload) => dispatch(updateFollowUpFlag(payload)),
    updatePrimaryEmail: (payload) => dispatch(updatePrimaryEmail(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorComponent);
