import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Typography,
  TablePagination
} from "@mui/material";
import { includes, map, reject, some } from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import AuditIcon from "@mui/icons-material/History";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import FlagIcon from "@mui/icons-material/Flag";
import { convertUTCDateToLocalDate } from "../../utils/globalMethods";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CustomSwitch from "../common/components/customSwitch";
import EditIcon from "@mui/icons-material/Edit";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { useTranslation } from "react-i18next";
import useTranslator from "../../hooks/useTranslator";
import Modal from "@mui/material/Modal";
import { documnetURL } from "../senior/constant";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {AUTH_API, headers } from "../../config";
import DataGridTable from "../../common/DataGrid";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import useLoader from "../../hooks/useLoader";
import axiosInstance from "../../redux/interceptor";
import toast from "react-hot-toast";
import {
  getManageVendorList,
  refreshKey,
  manageDeleteVendorDetails,
  getFollowVendorDetails,
  updateVendorBulk,
  getVendorHistoryList,
  exportVendorReport,
  exportVendorLastUpdateReport,
  exportVendorLoginReport,
  updateFollowUpFlag,
  updatePrimaryEmail,
} from "../../redux/actionTypes/vendordetails";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
function FarmersList(props) {
  const { openLoader, closeLoader } = useLoader();
  const { t } = useTranslation("translation", {
    keyPrefix: `components.applyBenefits`,
  });
  const transProps = useTranslator({
    applyBenefits: ["myApp"],
    yourInfo: ["firstName"],
  });
  
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [vendorsList, setVendorsList] = useState([]);
  const [vendorsListTotal, setVendorsListTotal] = useState([]);
  const [openIds, setOpenIds] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const userLogin = JSON.parse(sessionStorage.getItem("userLogin"))

  // open modal for view documents
  const handleShowDocument = (orgId) => {
    getFormerDocuments(orgId);
  };

  const handleAddFarmer = async (id) => {
    const url = `${AUTH_API}/marketcrd/vendor/select`;
    try {
      openLoader("add_farmer");
      const payload = {
        dirtyStatus: "Insert",
        hipVenderId: id,
        id: userLogin.id,
        createUidNam: "ADMN",
        lastUpdtUidNam: "ADMN",
      };
      const res = await axiosInstance.post(url, payload);
      if (res.status === 200) {
        toast.success("Farmer Choosed Successfully..");
        props.setTrigger(!props.trigger);
      }
    } catch (error) {
      toast.error("Failed..!");
      console.log(error);
    } finally {
      closeLoader("add_farmer");
    }
  };

  const getFormerDocuments = async (orgId) => {
    try {
      openLoader("get_document");
      const url = `${documnetURL}/docControl/getDocumentsByCaseId/${orgId}/KFresh`;
      const res = await axios.get(url, {
        headers: headers,
      });
      if (res) {
        let modifiedData = res?.data.map((item, index) => ({
          ...item,
          id: index + 1,
          docType: item.docType == "KFresh" ? item.docCat : item.docType,
        }));
        setTableData(modifiedData);
        setOpenModal((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("get_document");
    }
  };
  const COLUMN = [
    {
      field: "id",
      headerName: "S.NO",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "docType",
      headerName: "Document Type",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "grid_header",
      flex: 1,
      renderCell: ({ row: { documentId = null, sourceCaseId = null } }) => {
        return (
          <Stack direction="row" alignItems={"center"}>
            <Button
              startIcon={<VisibilityOutlinedIcon />}
              sx={{
                color: "#44A0E3",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "0px !important",
                "& .css-1d6wzja-MuiButton-startIcon": {
                  marginRight: "3px !important",
                },
              }}
              onClick={(e) => handlePDFDownload(e, documentId, sourceCaseId)}
            ></Button>
          </Stack>
        );
      },
    },
  ];
  const handlePDFDownload = async (e, documentId, sourceCaseId) => {
    try {
      openLoader("download_doc");
      const url = `${documnetURL}/docControl/downloadDocumentBy/${documentId}/${sourceCaseId}/KFresh`;
      const response = await axios.post(
        url,
        {},
        { responseType: "blob", headers: headers }
      );
      if (response.status === 200 && response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(blob);
        setPdfUrl(pdfUrl);
        // window.open(pdfUrl, "_blank");
        // const anchor = document.createElement("a");
        // anchor.href = pdfUrl;
        // anchor.download = userFileName;
        // anchor.click();
        // // Clean up: remove the anchor element and revoke the URL object
        // anchor.remove();
        // window.URL.revokeObjectURL(pdfUrl);
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (err) {
      console.log("ERROR", err);
    } finally {
      closeLoader("download_doc");
      // hideLoader();
    }
  };
  const toggleDetails = (id) => {
    const isOpen = includes(openIds, id);
    if (isOpen) {
      setOpenIds(reject(openIds, (_id) => id));
    } else {
      setOpenIds([...openIds, id]);
    }
  };
  const toggleFullscreen = () => {
    const element = document.querySelector(".fullscreen-object");
    if (!document.fullscreenElement) {
      element.requestFullscreen().catch((err) => {
        console.log(
          `Error attempting to enable full-screen mode: ${err.message}`
        );
      });
    } else {
      document.exitFullscreen();
    }
  };

    const handleNext = (page, rowSize) => {
      size != rowSize && setSize(rowSize);
      setFrom(rowSize * page);
    };

     useEffect(() => {

       const payload = {
         adminLastUpdtTsSort: "",
         from: 0,
         isActive: "Y",
         isNotActive: "Y",
         isNotVerified: "Y",
         isVerified: "Y",
         isVerifiedSort: "",
         lastUpdtTsSort: "DESC",
         name: "",
         size: 10,
         status: "AP",
         vendorType: "HIPVDR",
       }
         props.getManageVendorList(payload);
         
     }, [size, from, props.trigger]);

     useEffect(() => {
       openLoader("get_farmers");
       const { manageVendorList } = props.vendorDetails;
       const  farmerList = manageVendorList.list.map((item) => {
        return {
          ...item,
          lastUpdtTs:  convertUTCDateToLocalDate(item.lastUpdtTs),
          adminLastUpdtTs: item.adminLastUpdtTs  
           ? convertUTCDateToLocalDate(item.adminLastUpdtTs)
           : "-",
        };
       })
       setVendorsList(farmerList);
       setVendorsListTotal(manageVendorList.total);
       closeLoader("get_farmers");
     }, [props.vendorDetails.manageVendorList]);


     if (vendorsList.length === 0){
      return 'Loading...'
     }
       return (
         <>
           <Stack gap={2}>
             {map(vendorsList, (vendor = {}) => {
               const {
                 id,
                 vendorName,
                 isHipAndCsaVdr,
                 isDeletedCsaVendor,
                 isHIPVendor,
                 primaryEmail,
                 isActive,
                 fnsNum,
                 lastUpdtTs,
                 adminLastUpdtTs,
                 isVerified,
                 isEnableDelBtn,
                 orgId,
                 submitted,
                 row,
               } = vendor;
               const isOpen = includes(openIds, id);
               return (
                 <Stack direction="row" className="vendorContainer">
                   {/* <Box>
              <Checkbox
                checked={some(selectedRows, (row) => row.id === id)}
                onChange={(e) => handleRowClick(e, vendor)}
              />
            </Box> */}
                   <Box sx={{ flexGrow: 1 }}>
                     <Grid container>
                       <Grid xs={9}>
                         <Box>
                           <Grid container>
                             <Grid item xs={6} md={4}>
                               <Stack className="textContent">
                                 <p className="contentTitle">Merchant Name</p>
                                 <a
                                 // href={
                                 //   "/kfreshfep/vendors/" +
                                 //   (vendorType === "HIPVDR" ? "hip" : "csa") +
                                 //   "?vendor_id=" +
                                 //   id
                                 // }
                                 >
                                   {vendorName}
                                   <OpenInNewIcon />
                                 </a>
                               </Stack>
                             </Grid>
                             <Grid item xs={6} md={4}>
                               <Stack className="textContent" gap={0.5}>
                                 <p className="contentTitle">Federal ID</p>
                                 <p className="contentSubTitle">{fnsNum}</p>
                               </Stack>
                             </Grid>
                             {/* <Grid item xs={6} md={3}>
                        <Stack className="textContent" gap={0.5}>
                          <p className="contentTitle">Merchant Type</p>
                          {row?.isHipAndCsaVdr ? (
                            <>
                              <span className="badge badge-primary mr-1">
                                {row?.isDeletedCsaVendor ? "Deleted: " : ""} SPF
                                Vendor Registration
                              </span>
                              <span className="badge badge-info">
                                SPF Vendor Product
                              </span>
                            </>
                          ) : row?.isHIPVendor === "Y" ? (
                            <span className="badge badge-info">
                              SPF Vendor Product
                            </span>
                          ) : (
                            <span className="badge badge-primary">
                              SPF Vendor Registration
                            </span>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Stack className="textContent" gap={0.5}>
                          <p className="contentTitle">Status</p>
                          <CustomSwitch
                            sx={{ alignSelf: "flex-start" }}
                            checked={isActive === "Y"}
                            verified={isVerified === "Y"}
                            onChange={(status) => {
                              const newVendor = {
                                ...vendor,
                                isActive: status ? "Y" : "N",
                                lastUpdtTs: new Date().getTime(),
                              };
                              handleBulkUpdate([newVendor]);
                            }}
                          />
                        </Stack>
                      </Grid> */}
                             <Grid item xs={6} md={4}>
                               <Stack className="textContent">
                                 <p className="contentTitle">Email</p>
                                 <Stack direction="row" alignItems="center">
                                   <Typography variant="body1">
                                     {primaryEmail}
                                   </Typography>

                                   {/* {row.isHIPVendor === "Y" &&
                              userRole?.Role &&
                              userRole?.Role === "ADMIN" && (
                                <>
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                      handleOnEditEmailAction(vendor);
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </>
                              )} */}
                                 </Stack>
                               </Stack>
                             </Grid>
                             <Grid item xs={6} md={4}>
                               <Stack className="textContent">
                                 <p className="contentTitle">
                                   Vendor Last Updated Time
                                 </p>
                                 <Typography variant="body1">
                                   {lastUpdtTs}
                                 </Typography>
                               </Stack>
                             </Grid>
                             <Grid item xs={6} md={4}>
                               <Stack className="textContent">
                                 <p className="contentTitle">
                                   Staff Last Updated Time
                                 </p>
                                 <Typography variant="body1">
                                   {adminLastUpdtTs}
                                 </Typography>
                               </Stack>
                             </Grid>
                             <Grid item xs={6} md={4}>
                               <Stack className="textContent">
                                 <p className="contentTitle">Approved</p>
                                 {isVerified === "Y" ? (
                                   <Typography variant="body1">Yes</Typography>
                                 ) : (
                                   <a
                                   // href={
                                   //   "/kfreshfep/vendors/" +
                                   //   (vendorType === "HIPVDR" ? "hip" : "csa") +
                                   //   "?vendor_id=" +
                                   //   id
                                   // }
                                   >
                                     Pending
                                   </a>
                                 )}
                               </Stack>
                             </Grid>
                           </Grid>
                         </Box>
                       </Grid>
                       <Grid xs={6} md={3}>
                         <Stack gap={1}>
                           <p
                             className="contentTitle txtBtnTitle"
                             style={{ textAlign: "center" }}
                           >
                             Action
                           </p>
                           <Stack
                             direction="row"
                             gap={1}
                             className="actionButtons"
                           >
                             <>
                               {/* {isEnableDelBtn && userRole?.Role === "ADMIN" && (
                          <IconButton
                            aria-label="audit"
                            onClick={() => handleOnDeleteAction(vendor)}
                          >
                            <Stack alignItems="center">
                              <DeleteIcon />
                              <span className="txtBtnTitle">Delete</span>
                            </Stack>
                          </IconButton>
                        )} */}
                               {/* <IconButton
                          aria-label="audit"
                          onClick={() => handleOnAuditAction(vendor)}
                        >
                          <Stack alignItems="center">
                            <AuditIcon />
                            <span className="txtBtnTitle">History</span>
                          </Stack>
                        </IconButton>

                        {row.isVerified === "N" && (
                          <IconButton
                            aria-label="audit"
                            onClick={() => handleOnFollowUpAction(vendor)}
                          >
                            <Stack alignItems="center">
                              <FlagIcon />
                              <span className="txtBtnTitle">Follow</span>
                            </Stack>
                          </IconButton>
                        )} */}
                               <IconButton
                                 aria-label="view"
                                 onClick={() => handleShowDocument(orgId)}
                               >
                                 <Stack alignItems="center">
                                   <VisibilityOutlinedIcon />
                                   <span className="txtBtnTitle">
                                     Documents
                                   </span>
                                 </Stack>
                               </IconButton>
                               <IconButton
                                 aria-label="view"
                                 disabled={submitted}
                                 onClick={() => handleAddFarmer(id)}
                               >
                                 <Stack alignItems="center">
                                   {submitted ? (
                                     <DoneOutlineIcon />
                                   ) : (
                                     <PersonAddAltIcon />
                                   )}
                                   <span className="txtBtnTitle">
                                     {submitted ? "Added" : "Add"}
                                   </span>
                                 </Stack>
                               </IconButton>
                             </>
                           </Stack>
                         </Stack>
                       </Grid>
                     </Grid>
                     {/*<Stack direction="row" justifyContent="flex-end">
                <IconButton onClick={() => toggleDetails(id)}>
                  {/* {isOpen ? "Collapse details" : "Details"} */
                     /*}
                  {isOpen ? (
                    <ExpandCircleDownIcon
                      sx={{ transform: "rotate(180deg)" }}
                    />
                  ) : (
                    <ExpandCircleDownIcon />
                  )}
                </IconButton>
              </Stack>*/}
                     {/* <Collapse in={isOpen}>
                {isOpen && <Box>{renderDetails(vendor)}</Box>}
              </Collapse> */}
                   </Box>
                 </Stack>
               );
             })}
             <div>
               <Modal
                 open={openModal}
                 onClose={() => {
                   setOpenModal(false);
                   setPdfUrl(null);
                   if (document.fullscreenElement) {
                     document.exitFullscreen();
                   }
                 }}
                 aria-labelledby="modal-modal-title"
                 aria-describedby="modal-modal-description"
               >
                 <Box
                   sx={{
                     position: "absolute",
                     top: "50%",
                     left: "50%",
                     transform: "translate(-50%, -50%)",
                     width: "80%",
                     bgcolor: "background.paper",
                     p: 4,
                     "& .css-levciy-MuiTablePagination-displayedRows": {
                       margin: "0px !important",
                     },
                     "& .MuiDataGrid-columnHeaderTitleContainer": {
                       justifyContent: "center !important",
                     },
                     "& .css-wstmaz-MuiDataGrid-root .MuiDataGrid-cell--textLeft":
                       {
                         justifyContent: "center",
                       },
                     "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                       opacity: "0 !important",
                       transition: "none !important",
                     },
                     "& .MuiDataGrid-columnHeader": {
                       paddingLeft: "0px !important",
                     },
                     "& .MuiDataGrid-row": { paddingLeft: "0px !important" },
                     "& .MuiDataGrid-cell ": {
                       display: "flex",
                       alignItems: "center",
                       borderBottom: "1px solid",
                       justifyContent: "center !important",
                     },
                   }}
                 >
                   <DataGridTable columns={COLUMN} data={tableData} />
                   {/* Display PDF here */}
                   {pdfUrl && (
                     <>
                       <div style={{ position: "relative" }}>
                         <Button
                           onClick={toggleFullscreen}
                           sx={{
                             position: "absolute",
                             top: "12px",
                             right: "21%",
                             zIndex: 1000,
                             color: "#f1f1f1",
                           }}
                         >
                           <FullscreenIcon />
                         </Button>
                         <object
                           className="fullscreen-object"
                           data={pdfUrl}
                           type="application/pdf"
                           width="100%"
                           height="350px"
                         ></object>
                       </div>
                     </>
                   )}
                 </Box>
               </Modal>
             </div>
           </Stack>
           <TablePagination
             rowsPerPageOptions={[10, 25, 50, 100]}
             component="div"
             count={vendorsListTotal}
             rowsPerPage={size}
             page={Math.floor(from / size)}
             onPageChange={(e, newPage) => handleNext(newPage, size)}
             onRowsPerPageChange={(e) => handleNext(0, +e.target.value)}
             className={"paginationBlock"}
           />
         </>
       );
}

FarmersList.propTypes = {
  getManageVendorList: PropTypes.func.isRequired,
  vendorDetails: PropTypes.object,
  refreshKey: PropTypes.func.isRequired,
  trigger: PropTypes.bool,
  setTrigger: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    getManageVendorList: (payload) => dispatch(getManageVendorList(payload)),
    manageDeleteVendorDetails: (payload) =>
      dispatch(manageDeleteVendorDetails(payload)),
    refreshKey: () => dispatch(refreshKey()),
    getFollowVendorDetails: (payload) =>
      dispatch(getFollowVendorDetails(payload)),
    updateVendorBulk: (payload) => dispatch(updateVendorBulk(payload)),
    exportVendorReport: (payload) => dispatch(exportVendorReport(payload)),
    exportVendorLastUpdateReport: (payload) =>
      dispatch(exportVendorLastUpdateReport(payload)),
    exportVendorLoginReport: (payload) =>
      dispatch(exportVendorLoginReport(payload)),
    getVendorHistoryList: (payload) => dispatch(getVendorHistoryList(payload)),
    updateFollowUpFlag: (payload) => dispatch(updateFollowUpFlag(payload)),
    updatePrimaryEmail: (payload) => dispatch(updatePrimaryEmail(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FarmersList);
