import React, { useEffect, useState, useMemo } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AUTH_API } from "../../config";
import axiosInstance from "../../redux/interceptor";
import useLoader from "../../hooks/useLoader";
import { Col, Row, Tab, Tabs, Button, Badge } from "react-bootstrap";
import { pageRefresh } from "../../utils/globalMethods";
import * as Yup from "yup";
import { useFormik } from "formik";
import useLookup from "../../hooks/useLookup";
import { map } from "lodash";
import toast from "react-hot-toast";
import AlertMarketCord from "./alertOverviewMarket/alertMarketCord";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileUploadModal from "../../common/fileUpload/FileUploadModal";
import FarmersList from "./farmers";
import dayjs from "dayjs";
import SelectedFarmersList from "./selectedfarmers";
import InputMask from "react-input-mask";
import { actionTypes } from "../../redux/actionTypes/vendordetails";
import { useDispatch } from "react-redux";

function MarketDashboard() {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState("details");
  const [data, setData] = useState("");
  const [isEdit, setIsEdit] = useState(true);
  const [editPublicInfo, setEditPublicInfo] = useState(true);
  const { openLoader, closeLoader } = useLoader();
  const [docOpen, setDocOpen] = useState(false);
  const location = useLocation();
  const role = "MRKCRD";
  const [trigger, setTrigger] = useState(true);
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const { getOptions } = useLookup("marketRegistration");

  const validationSchema = Yup.object({
    fName: Yup.string().required("First Name is required"),
    lName: Yup.string().required("Last Name is required"),
    email: Yup.string().required("Email is required"),
    marketName: Yup.string().required("Market Name is required"),
    marketAddress: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    county: Yup.string().required("County is required"),
    zipCode: Yup.string()
      .required("ZipCode is required")
      .min(5, "Must be exactly 5 digits"),
    sigma: Yup.string()
      .required("Sigma ID is required")
      .min(9, "Must be exactly 9 digits"),
    fed: Yup.string()
      .required("FED ID is required")
      .min(9, "Must be exactly 9 digits"),
    routingN: Yup.string()
      .required("Routing Number is required")
      .min(9, "Must be exactly 9 digits"),
    bank: Yup.string().required("Bank is required"),
    accountN: Yup.string()
      .required("Account Number is required")
      .min(12, "Must be exactly 12 digits"),
    bankType: Yup.string().required("Bank Type is required"),
    farmers: Yup.string().required("Number of Farmers is required"),
    phone: Yup.string()
      .required("Phone is required")
      .min(10, "Must be exactly 10 digits"),
    marketPhone: Yup.string().min(10, "Must be exactly 10 digits"),
    eid: Yup.string()
      .required("EID number is required")
      .min(9, "Must be exactly 9 digits"),
    apptoApply: Yup.array()
      .min(1, "Please select at least one")
      .required("Application type is required"),
  });

  const formik = useFormik({
    initialValues: {
      fName: data?.firstName ?? "",
      mName: "",
      lName: data?.lastName ?? "",
      email: data?.primaryEmail ?? "",
      marketName: data?.marketName ?? "",
      marketAddress: data?.marketName ?? "",
      marketPhone: data?.primaryMobile ?? "",
      city: data?.city ?? "",
      state: data?.state ?? "",
      county: data?.county ?? "",
      zipCode: data?.zipcode ?? "",
      sigma: data?.sigma ?? "",
      fed: data?.fed ?? "",
      routingN: data?.routingNo ?? "",
      bank: data?.bankName ?? "",
      accountN: data?.accountNo ?? "",
      bankType: data?.bankType ?? "",
      farmers: data?.farmers ?? "",
      phone: data?.primaryMobile ?? "",
      eid: data?.eid ?? "",
      market: "",
      road: "",
      fb: data?.facebookPage ?? "",
      twitter: data?.twitterPage ?? "",
      cEmail: data?.communicationEmail ?? "",
      website: data?.websiteAddr ?? "",
      notificationVia: data.notificationVia
        ? data.notificationVia.split(", ")
        : [],
      apptoApply: data.apptoApply ? data.apptoApply.split(",") : [],
    },
    validationSchema: activeTab === "details" ? validationSchema : null,

    onSubmit: (values) => {
      updateYourInfo(values);
    },
    enableReinitialize: true,
  });
  // const userData = location?.state?.userData ?? "";
  const params = useParams();
  console.log(params, "params");
  const userLogin = sessionStorage.getItem("userLogin")
    ? JSON.parse(sessionStorage.getItem("userLogin"))
    : "";
  const fullName = `${userLogin?.firstName} ${userLogin?.lastName}`;
  const userRole = userLogin?.role;
  const disabled = location?.state?.disabled ?? false;

  const getYourInfo = async () => {
    try {
      openLoader("dashboard");
      const payLoad = {
        vendorType: "MRKCRD",
        lastUpdtUidNam: role + "_" + userData?.id,
        vendorId: userData.vendorId,
      };
      const res = await axiosInstance.post(
        AUTH_API + "/csa/marketCoordinator/info",
        payLoad
      );
      setData(res.data.data[0].hipInfo);
       dispatch({
         type: actionTypes.SET_VENDOR_INFO_LIST,
         payload: res.data.data,
       });
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("dashboard");
    }
  };

  const { stateOpts, bankingOpts } = useMemo(() => {
    return {
      stateOpts: getOptions("state"),
      bankingOpts: getOptions("bankingtype"),
    };
  }, [getOptions]);

  const updateYourInfo = async (values) => {
    console.log(values, "vl");
    try {
      openLoader("update");
      const payLoad = {
        firstName: values.fName,
        lastName: values.lName,
        primaryEmail: values.email,
        primaryMobile: values.phone,
        publicEmail: "",
        publicMobile: "",
        publicWebsite: "",
        id: userData.id,
        vendorId: userData.vendorId,
        bankName: values.bank,
        bankType: values.bankType,
        accountNo: values.accountN,
        routingNo: values.routingN,
        sigma: values.sigma,
        fed: values.fed,
        eid: values.eid,
        farmers: values.farmers,
        city: values.city,
        state: values.state,
        county: values.county,
        zipcode: values.zipCode,
        marketName: values.marketName,
        marketAddress: values.marketAddress,
        marketphone: values.phone,
        communicationEmail: values.cEmail,
        facebookPage: values.fb,
        twitterPage: values.twitter,
        websiteAddr: values.website,
        appType: "",
        notificationVia: "",
        products: "",
        businessDesc: "",
        lastUpdtUidNam: `MRKCRD_${userData?.id}`,
        // userId: 2042,
        submitForm: "Market Coordinator information",
        vendorType: "MRKCRD",
        isHipAndCsaVdr: true,
        // loginId: 2042,
        type: "edit",
        apptoApply: values.apptoApply ? values.apptoApply.join(", ") : "",
      };
      const res = await axiosInstance.post(
        AUTH_API + "/public/update/marketInfo",
        payLoad
      );

      if (res.status == 200) {
        toast.success("Profile details Updated");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsEdit(true);
      setEditPublicInfo(true);
      closeLoader("update");
    }
  };

  useEffect(() => {
    getYourInfo();
  }, []);

  const handleModalOpen = () => {
    setDocOpen(true);
  };

  return (
    <>
      <Stack>
        <Row className="mx-0">
          <Col md={6} sm={12} className="my-3 d-flex align-items-center">
            <h2 className="resText vendor-details mr-3">
              Market Coordinator details
            </h2>
            <div>
              <Badge
                className={data.status === "AP" ? "bg-success" : "bg-danger"}
              >
                {data.status === "AP"
                  ? "Approved"
                  : data.status === "DE"
                  ? "Denied"
                  : "Pending"}
              </Badge>
              <div>Enrollment ID: {data.orgId}</div>
            </div>
          </Col>
          <Col
            md={6}
            sm={12}
            className="d-flex align-items-center justify-content-md-end justify-content-sm-start gap-3"
          >
            <Button variant="primary" onClick={pageRefresh}>
              <i className="fa fa-refresh"></i> Refresh
            </Button>
          </Col>
        </Row>
        <Tabs
          className="mx-2"
          id="controlled-tab"
          activeKey={activeTab}
          onSelect={setActiveTab}
          style={{ background: "#fff", paddingTop: "2rem" }}
        >
          <Tab
            eventKey="details"
            title={
              <div className="tab-header">
                <i className="fa fa-user"></i>
                <span className="m-l-5">Market Coordinator Details</span>
              </div>
            }
          >
            <div className="tab-content-wrapper mx-2">
              <Row>
                <Col>
                  <h3 className="resText"> Profile Details</h3>
                </Col>
                <Col className="text-right">
                  {!isEdit ? (
                    <div>
                      <Button className="mr-1" onClick={formik.handleSubmit}>
                        <i className="fa fa-check"></i>Update
                      </Button>
                      <Button onClick={() => setIsEdit(true)} variant="danger">
                        <i className="fa fa-close"></i> Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button onClick={() => setIsEdit(false)}>
                      <i className="fa fa-pencil"></i>Edit
                    </Button>
                  )}
                </Col>
              </Row>

              <Grid container spacing={2} mt={4}>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    First Name
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={{
                      onInput: (event) => {
                        event.target.value = event.target.value.replace(
                          /[^a-z A-Z]/g,
                          ""
                        );
                      },
                    }}
                    inputProps={{ maxLength: 20 }}
                    fullWidth
                    name="fName"
                    size="small"
                    placeholder={"Enter First Name"}
                    value={formik.values.fName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.fName && formik.errors.fName}
                    helperText={formik.touched.fName && formik.errors.fName}
                    disabled={isEdit}
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    Middle Name
                  </Typography>
                  <TextField
                    InputProps={{
                      onInput: (event) => {
                        event.target.value = event.target.value.replace(
                          /[^a-z A-Z]/g,
                          ""
                        );
                      },
                    }}
                    inputProps={{ maxLength: 20 }}
                    fullWidth
                    name="mName"
                    size="small"
                    placeholder={"Enter Middle Name"}
                    disabled={isEdit}
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Last Name
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={isEdit}
                    InputProps={{
                      onInput: (event) => {
                        event.target.value = event.target.value.replace(
                          /[^a-z A-Z]/g,
                          ""
                        );
                      },
                    }}
                    inputProps={{ maxLength: 20 }}
                    fullWidth
                    className="formControl"
                    name="lName"
                    size="small"
                    placeholder={"Enter Last Name"}
                    value={formik.values.lName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.lName && formik.errors.lName}
                    helperText={formik.touched.lName && formik.errors.lName}
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Email Address
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={true}
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    name="email"
                    size="small"
                    placeholder={"Enter Email"}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                  ></TextField>
                </Grid>
              </Grid>

              <Grid container spacing={2} mt={2}>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    Phone Number
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  {/* <TextField
                    InputProps={{
                      onInput: (event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                      },
                    }}
                    inputProps={{ maxLength: 10 }}
                    fullWidth
                    disabled={isEdit}
                    name="phone"
                    size="small"
                    placeholder={"Enter Phone"}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && formik.errors.phone}
                    helperText={formik.touched.phone && formik.errors.phone}
                  ></TextField> */}
                  <InputMask
                    mask="(999)-999-9999"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    disabled={isEdit}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        fullWidth
                        disabled={isEdit}
                        name="phone"
                        placeholder={"Enter phone Number"}
                        type="tel"
                        error={formik.touched.phone && formik.errors.phone}
                        helperText={formik.touched.phone && formik.errors.phone}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    Market Name
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={isEdit}
                    inputProps={{ maxLength: 30 }}
                    fullWidth
                    name="marketName"
                    size="small"
                    placeholder={"Enter Market Name"}
                    value={formik.values.marketName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.marketName && formik.errors.marketName
                    }
                    helperText={
                      formik.touched.marketName && formik.errors.marketName
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Market Address
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={isEdit}
                    inputProps={{ maxLength: 40 }}
                    fullWidth
                    name="marketAddress"
                    size="small"
                    placeholder={"Enter Market Address"}
                    value={formik.values.marketAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.marketAddress &&
                      formik.errors.marketAddress
                    }
                    helperText={
                      formik.touched.marketAddress &&
                      formik.errors.marketAddress
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Market Phone Number
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  {/* <TextField
                    disabled={isEdit}
                    InputProps={{
                      onInput: (event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                      },
                    }}
                    inputProps={{ maxLength: 10 }}
                    fullWidth
                    name="marketPhone"
                    size="small"
                    value={formik.values.marketPhone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.marketPhone && formik.errors.marketPhone
                    }
                    helperText={
                      formik.touched.marketPhone && formik.errors.marketPhone
                    }
                  ></TextField> */}
                  <InputMask
                    mask="(999)-999-9999"
                    value={formik.values.marketPhone}
                    onChange={formik.handleChange}
                    disabled={isEdit}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        fullWidth
                        name="marketPhone"
                        disabled={isEdit}
                        placeholder={"Enter market phone Number"}
                        type="tel"
                        error={
                          formik.touched.marketPhone &&
                          formik.errors.marketPhone
                        }
                        helperText={
                          formik.touched.marketPhone &&
                          formik.errors.marketPhone
                        }
                      />
                    )}
                  </InputMask>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    City
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={isEdit}
                    InputProps={{
                      onInput: (event) => {
                        event.target.value = event.target.value.replace(
                          /[^a-z A-Z]/g,
                          ""
                        );
                      },
                    }}
                    inputProps={{ maxLength: 20 }}
                    fullWidth
                    name="city"
                    size="small"
                    placeholder={"Enter City"}
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.city && formik.errors.city}
                    helperText={formik.touched.city && formik.errors.city}
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    State
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box width="100%" overflow="hidden">
                    <Select
                      fullWidth
                      disabled={isEdit}
                      size="small"
                      name="state"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.state && formik.errors.state}
                    >
                      {map(stateOpts, (item) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>{formik.errors.state}</FormHelperText>
                  </Box>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    County
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={isEdit}
                    InputProps={{
                      onInput: (event) => {
                        event.target.value =
                          event.target.value.replace(/[^a-z A-Z]/g);
                      },
                    }}
                    inputProps={{ maxLength: 20 }}
                    fullWidth
                    name="county"
                    size="small"
                    placeholder={"Enter County"}
                    value={formik.values.county}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.county && formik.errors.county}
                    helperText={formik.touched.county && formik.errors.county}
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Zip Code
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={isEdit}
                    fullWidth
                    name="zipCode"
                    size="small"
                    placeholder={"Enter ZipCode"}
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.zipCode && formik.errors.zipCode}
                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                    InputProps={{
                      onInput: (event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                      },
                    }}
                    inputProps={{ maxLength: 5 }}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    FED ID
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={isEdit}
                    fullWidth
                    name="fed"
                    size="small"
                    placeholder={"Enter FED ID"}
                    value={formik.values.fed}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.fed && formik.errors.fed}
                    helperText={formik.touched.fed && formik.errors.fed}
                    InputProps={{
                      onInput: (event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                      },
                    }}
                    inputProps={{ maxLength: 9 }}
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    EIN Number
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={isEdit}
                    fullWidth
                    name="eid"
                    size="small"
                    placeholder={"Enter EIN Number"}
                    value={formik.values.eid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.eid && formik.errors.eid}
                    helperText={formik.touched.eid && formik.errors.eid}
                    InputProps={{
                      onInput: (event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                      },
                    }}
                    inputProps={{ maxLength: 9 }}
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    SIGMA Account Number
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={isEdit}
                    fullWidth
                    name="sigma"
                    size="small"
                    placeholder={"Enter SIGMA Account Number"}
                    value={formik.values.sigma}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.sigma && formik.errors.sigma}
                    helperText={formik.touched.sigma && formik.errors.sigma}
                    InputProps={{
                      onInput: (event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                      },
                    }}
                    inputProps={{ maxLength: 9 }}
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    How many farmers do you represent
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={isEdit}
                    fullWidth
                    name="farmers"
                    size="small"
                    placeholder={"Enter the number of farmers"}
                    value={formik.values.farmers}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.farmers && formik.errors.farmers}
                    helperText={formik.touched.farmers && formik.errors.farmers}
                    InputProps={{
                      onInput: (event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                      },
                    }}
                    inputProps={{ maxLength: 3 }}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Check all that apply
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControlLabel
                    // disabled
                    control={
                      <Checkbox
                        disabled={isEdit}
                        value="market"
                        checked={formik.values.apptoApply.includes("market")}
                        name="apptoApply"
                        onChange={formik.handleChange}
                      />
                    }
                    label="Market"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="road"
                        disabled={isEdit}
                        checked={formik.values.apptoApply.includes("road")}
                        name="apptoApply"
                        onChange={formik.handleChange}
                      />
                    }
                    label="Roadside Stand"
                    labelPlacement="end"
                  />
                  {formik.touched.apptoApply && formik.errors.apptoApply && (
                    <div style={{ color: "red", fontSize: "1rem" }}>
                      {formik.errors.apptoApply}
                    </div>
                  )}
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    Routing Number
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={isEdit}
                    InputProps={{
                      onInput: (event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                      },
                    }}
                    inputProps={{ maxLength: 9 }}
                    fullWidth
                    name="routingN"
                    size="small"
                    placeholder={"Enter Routing Number"}
                    value={formik.values.routingN}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.routingN && formik.errors.routingN}
                    helperText={
                      formik.touched.routingN && formik.errors.routingN
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    Banking Account Number
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={isEdit}
                    InputProps={{
                      onInput: (event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                      },
                    }}
                    inputProps={{ maxLength: 12 }}
                    fullWidth
                    name="accountN"
                    size="small"
                    placeholder={"Enter Banking Account Number"}
                    value={formik.values.accountN}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.accountN && formik.errors.accountN}
                    helperText={
                      formik.touched.accountN && formik.errors.accountN
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Bank Name
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    disabled={isEdit}
                    fullWidth
                    InputProps={{
                      onInput: (event) => {
                        event.target.value = event.target.value.replace(
                          /[^a-z A-Z]/g,
                          ""
                        );
                      },
                    }}
                    inputProps={{ maxLength: 25 }}
                    name="bank"
                    size="small"
                    placeholder={"Enter Bank Name"}
                    value={formik.values.bank}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.bank && formik.errors.bank}
                    helperText={formik.touched.bank && formik.errors.bank}
                  ></TextField>
                </Grid>
                {/* <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Bank Type
                  </Typography>
                  <TextField
                    disabled={isEdit}
                    fullWidth
                    className="formControl"
                    name="lName"
                    size="small"
                    value={data?.bankType}
                  ></TextField>
                </Grid> */}
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Bank Type
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box width="100%" overflow="hidden">
                    <Select
                      fullWidth
                      size="small"
                      name="bankType"
                      value={formik.values.bankType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={isEdit}
                      helperText={
                        formik.touched.bankType && formik.errors.bankType
                      }
                    >
                      {map(bankingOpts, (item) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>
                      {formik.errors.bankType}
                    </FormHelperText>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Tab>
          <Tab
            eventKey="Public"
            title={
              <div className="tab-header">
                <i className="fa fa-eye"></i>
                <span className="m-l-5">Market Coordinator Public Info</span>
              </div>
            }
          >
            <div className="tab-content-wrapper mx-2">
              <Row>
                <Col>
                  <h3 className="resText"> Public Info</h3>
                </Col>
                <Col className="text-right">
                  {!editPublicInfo ? (
                    <div>
                      <Button className="mr-1" onClick={formik.handleSubmit}>
                        <i className="fa fa-check"></i>Update
                      </Button>
                      <Button
                        onClick={() => setEditPublicInfo(true)}
                        variant="danger"
                      >
                        <i className="fa fa-close"></i> Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button onClick={() => setEditPublicInfo(false)}>
                      <i className="fa fa-pencil"></i>Edit
                    </Button>
                  )}
                </Col>
              </Row>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={6}>
                  <Typography
                    variant="h6"
                    className="typography"
                    fontWeight="fontWeightBold"
                  >
                    Communication Email Address
                  </Typography>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    name="cEmail"
                    size="small"
                    value={formik.values.cEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={editPublicInfo}
                  ></TextField>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Typography
                    className="typography"
                    variant="h6"
                    fontWeight="fontWeightBold"
                  >
                    Website Address
                  </Typography>
                  <TextField
                    inputProps={{ maxLength: 500 }}
                    fullWidth
                    name="website"
                    size="small"
                    value={formik.values.website}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={editPublicInfo}
                  ></TextField>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Typography
                    variant="h6"
                    className="typography"
                    fontWeight="fontWeightBold"
                  >
                    Facebook Page
                  </Typography>
                  <TextField
                    inputProps={{ maxLength: 500 }}
                    fullWidth
                    name="fb"
                    size="small"
                    value={formik.values.fb}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={editPublicInfo}
                  ></TextField>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Typography
                    className="typography"
                    variant="h6"
                    fontWeight="fontWeightBold"
                  >
                    Twitter
                  </Typography>
                  <TextField
                    inputProps={{ maxLength: 500 }}
                    fullWidth
                    name="twitter"
                    size="small"
                    value={formik.values.twitter}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={editPublicInfo}
                  ></TextField>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Typography variant="h6" fontWeight="fontWeightBold">
                    Notification VIA
                  </Typography>
                  <FormControlLabel
                    label="Email"
                    labelPlacement="end"
                    control={
                      <Checkbox
                        disabled={editPublicInfo}
                        value="mail"
                        checked={formik.values.notificationVia.includes("mail")}
                        name="notificationVia"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                  <FormControlLabel
                    label="USPS Mail"
                    labelPlacement="end"
                    control={
                      <Checkbox
                        disabled={editPublicInfo}
                        value="uspsmail"
                        checked={formik.values.notificationVia.includes(
                          "uspsmail"
                        )}
                        name="notificationVia"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                </Grid>
              </Grid>

              <Button
                onClick={handleModalOpen}
                sx={{
                  border: "1px solid #44A0E3 !important",
                  color: " var(--Secon-1-D-Blue, #44A0E3)",
                  fontSize: "14px !important ",
                  fontWeight: "700 !important",
                }}
                disabled={disabled}
              >
                <UploadFileIcon />
                File Upload
              </Button>
            </div>
            <FileUploadModal
              docOpen={docOpen}
              setDocOpen={setDocOpen}
              payload={{
                createDt: dayjs().format("YYYY/MM/DD"),
                createUserId: fullName,
                role: userRole,
                sourceCaseId: data.orgId,
              }}
            />
          </Tab>
          <Tab
            eventKey="alerts"
            title={
              <div className="tab-header">
                <i className="fa fa-bell"></i>
                <span className="m-l-5">Alerts Overview</span>
              </div>
            }
          >
            <AlertMarketCord active={activeTab === "alerts"} />
          </Tab>
          {data.status === "AP" && (
            <Tab
              eventKey="farmersList"
              title={
                <div className="tab-header">
                  <i className="fa fa-users"></i>
                  <span className="m-l-5">Farmers</span>
                </div>
              }
            >
              <div className="tab-content-wrapper mx-2 row">
                <div className="col-4">
                  <h5>
                    <u>Selected Farmers</u>
                  </h5>
                  <SelectedFarmersList
                    userData={userData}
                    trigger={trigger}
                    setTrigger={setTrigger}
                  />
                </div>
                <div className="col-8">
                  <h5>
                    <u>Farmers</u>
                  </h5>
                  <FarmersList trigger={trigger} setTrigger={setTrigger} />
                </div>
              </div>
            </Tab>
          )}
        </Tabs>
      </Stack>
    </>
  );
}

export default MarketDashboard;
