import * as React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AccountIcon } from "../../assets/icons/accounts.svg";
import { ReactComponent as NoAccountIcon } from "../../assets/icons/no-accounts.svg";
import { ReactComponent as PersonApron } from "../../assets/icons/person-apron.svg";
import { Stack } from "@mui/material";

const backgroundColorObj = {
  info: "#C5E2F6",
  warning: "#F6D9BF",
  success: "#C8EFE3",
};

const CustomCardContent = styled(CardContent)(({ link, status }) => ({
  borderRadius: "8px",
  textAlign: "center",
  boxShadow: "0 0.25rem 0.5rem rgba(0, 0, 0, 0.25)",
  // backgroundColor: link ? "#dc3545" : "transparent",
  backgroundColor: backgroundColorObj[status],
  height: "100%",
  //   color: link ? "#ffffff" : "#000000",
  //   cursor: link ? "pointer" : "default",
}));

const TotalCardComponent = (props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (props.link) {
      navigate(props.link);
    }
  };

  const getIcon = () => {
    switch (props.status) {
      case "info":
        return <AccountIcon />;
      case "warning":
        return <NoAccountIcon />;
      case "success":
        return <PersonApron />;
    }
  };

  return (
    <CustomCardContent
      link={props.link}
      status={props.status}
      onClick={handleClick}
    >
      <Stack alignItems="center" justifyContent="center" direction="row" gap={1}>
        {getIcon()}
        <Stack>
          <Typography
            sx={{
              mb: 1.5,
              fontFamily: "Roboto",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 500,
              color: "#6c6c6c",
              lineHeight: "normal",
              letterSpacing: "0.5px",
            }}
            className="card-title"
          >
            {props.subtitle}
          </Typography>
          <Typography variant="h4" component="div" className="card-total">
            {props.total}
          </Typography>
        </Stack>
      </Stack>
    </CustomCardContent>
  );
};

TotalCardComponent.propTypes = {
  total: PropTypes.number.isRequired,
  subtitle: PropTypes.string.isRequired,
  link: PropTypes.string,
  status: PropTypes.string,
};

export default TotalCardComponent;
