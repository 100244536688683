import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import { CustomButton } from "../../common/components/customButton";

import {
  Divider,
  Grid,
  Table,
  TableHead,
  Typography,
  TableRow,
  TableBody,
  TableCell,
  Box,
  Stack,
} from "@mui/material";
import TableComponent from "../../common/components/table";
import * as actionType from "../../../redux/actionTypes/csaSignup";
import SearchComponent from "../../common/components/searchComponent";
import { useNavigate, useSearchParams } from "react-router-dom";
import DatePickerComponent from "../../common/components/dateRangePicker";
import { CalculateUserTzToUTCTz } from "../../../utils/globalMethods";
import SelectDropdown from "../../common/selectdropdown-component";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import { daysOfOperation } from "../../../constants/constants";

function createData(
  chosenNam,
  clientEmail,
  shareNam,
  vendorName,
  addressId,
  shareId,
  address1,
  address2,
  city,
  state,
  zipcode,
  day,
  openTime,
  closeTime,
  paymentStatus,
  paymentAmount,
  paymentDate,
  paymentScheduleListDate,
  paymentScheduleListAmount,
  paymentScheduleListStatus,
  clientNam,
  phoneNumber,
  lastUpdtTs,
  paymentType,
  processedDate,
  startDate,
  endDate,
  paymentScheduleList,
  row
) {
  return {
    chosenNam,
    clientEmail,
    shareNam,
    vendorName,
    addressId,
    shareId,
    address1,
    address2,
    city,
    state,
    zipcode,
    day,
    openTime,
    closeTime,
    paymentStatus,
    paymentAmount,
    paymentDate,
    paymentScheduleListDate,
    paymentScheduleListAmount,
    paymentScheduleListStatus,
    clientNam,
    phoneNumber,
    lastUpdtTs,
    paymentType,
    processedDate,
    startDate:
      paymentType === "MNTHLY"
        ? moment(startDate, "YYYY-MM-DD").format("MMM YYYY")
        : moment(startDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
    endDate:
      paymentType === "MNTHLY"
        ? moment(endDate, "YYYY-MM-DD").format("MMM YYYY")
        : moment(endDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
    paymentScheduleList,
    row,
  };
}

const PaymentsTab = (props) => {
  const navigate = useNavigate();

  const userRole = props?.userAuth?.user
    ? props?.userAuth?.user
    : JSON.parse(sessionStorage.getItem("userData"));

  const [clientPaymentListTotal, setclientPaymentListTotal] = useState([0]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [searchParams] = useSearchParams();
  let vendorId = searchParams.get("vendor_id");
  const [search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [expand] = useState(false);
  const [tableSort, setTableSort] = useState({ lastUpdtTsSort: "DESC" });
  const [status, setStatus] = useState();
  const [showLoader, setShowLoader] = useState(true);
  // const [clientPaymentScheduleList, setClientPaymentScheduleList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({
    name: "",
    values: "All Status",
    isActive: 1,
  });
  const [selectedLocation, setSelectLocation] = useState({
    name: "",
    values: "All ",
    isActive: 1,
  });
  const [statusList] = useState([
    { name: "", values: "All Status", isActive: 1 },
    { name: "SUCESS", values: "Successful", isActive: 1 },
    { name: "CANCLD", values: "Cancelled", isActive: 1 },
    // {name:"PENDNG", values:"Pending", isActive:1},
    { name: "SCHDLD", values: "Scheduled", isActive: 1 },
    { name: "REJCTD", values: "Rejected", isActive: 1 },
  ]);
  const [addressList, setAddressList] = useState();
  const [startDate] = useState("");
  const [endDate] = useState("");
  const [addressId, setAddressId] = useState(0);
  const [resetDate, setResetDate] = useState(false);
  const [dateRangePickerLabel] = useState("Select Date");
  const [filterData, setFilterData] = useState({
    startDate: null,
    endDate: null,
  });

  const filterHandleChange = (date) => {
    setFilterData({
      ...filterData,
      endDate: date?.[0]?.endDate
        ? CalculateUserTzToUTCTz(date?.[0]?.endDate)
        : null,
      startDate: date?.[0]?.startDate
        ? CalculateUserTzToUTCTz(date?.[0]?.startDate)
        : null,
    });
  };

  if (!vendorId && props.userAuth.user.vendorId) {
    vendorId = props.userAuth.user.vendorId;
  }

  const filterDay = (dayValue) => {
    let tempData = daysOfOperation.filter((data) => data.weekDay == dayValue);
    return tempData[0]?.values;
  };

  const handleRowClick = (e, row) => {
    var updatedSelectedRows = [];
    if (e.target.checked) {
      updatedSelectedRows = [...selectedRows, row];
    } else {
      updatedSelectedRows = selectedRows.filter((r) => r.id !== row.id);
    }
    setSelectAll(true);
    setSelectedRows(updatedSelectedRows);
  };

  const handleNext = (page, rowSize) => {
    size != rowSize && setSize(rowSize);
    setFrom(rowSize * page);
  };

  const handleSort = (item) => {
    let sortitem = {};
    if (item.sort == "" || item.sort == "DESC") {
      sortitem[item.id] = "ASC";
    } else {
      sortitem[item.id] = "DESC";
    }
    setTableSort(sortitem);
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value).toLowerCase();
    setShowLoader(true);
    setFrom(0);
  };

  const handleReset = () => {
    console.log("BeforeReset", startDate, endDate);
    setFilterData("");
    setStatus(null);
    setSelectLocation({ name: "", values: "All ", isActive: 1 });
    setAddressId(0);
    setSelectedStatus({ name: "", values: "All Status", isActive: 1 });
    setSearch("");
    setFrom(0);
    setTableSort({ lastUpdtTsSort: "desc" });
    setResetDate((preDate) => !preDate);
    setShowLoader(true);
  };

  useEffect(() => {
    if (props?.allowedRoles) {
      const checkRole = props?.allowedRoles.find((role) =>
        userRole?.Role.includes(role)
      );
      if (!checkRole) {
        navigate("/kfreshfep");
        return;
      }
    }
    const payload = {
      vendorId: props?.isManagePayment ? null : vendorId,
      nam: search,
      shareId: 0,
      addressId: addressId,
      status: status,
      addressType: "delivery",
      startDate: filterData.startDate
        ? moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY")
        : "",
      endDate: filterData.endDate
        ? moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY")
        : "",
      // "startDate": moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY"),
      // "endDate": moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY"),
      clientNamSort: "clientName" in tableSort ? tableSort["clientName"] : "",
      shareNamSort: "shareName" in tableSort ? tableSort["shareName"] : "",
      paymentStatusSort:
        "paymentStatus" in tableSort ? tableSort["paymentStatus"] : "",
      clientEmailSort:
        "clientEmail" in tableSort ? tableSort["clientEmail"] : "",
      size: size,
      from: from,
      // "paymentSort": "paymentDate" in tableSort ? tableSort["paymentDate"] : "",
    };

    props.getClientPaymentList(payload);
  }, [
    props?.clientId,
    size,
    from,
    search,
    tableSort,
    status,
    filterData,
    addressId,
  ]);

  useEffect(() => {
    if (props?.allowedRoles) {
      const checkRole = props?.allowedRoles.find((role) =>
        userRole?.Role.includes(role)
      );
      if (!checkRole) {
        navigate("/kfreshfep");
        return;
      }
    }
    const payload2 = {
      vendorId: props?.isManagePayment ? null : vendorId,
      type: "payment",
      // "startDate": moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY"),
      // "endDate": moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY"),
      startDate: filterData.startDate
        ? moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY")
        : "",
      endDate: filterData.endDate
        ? moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY")
        : "",
      size: 1000,
      from: 0,
    };
    props.getPickupLocationList(payload2);
  }, [filterData]);

  useEffect(() => {
    const { list } = props.csaSignup.pickupLocationList;
    let dropdownAddress = [{ name: "", values: "All", isActive: 1 }];

    list.forEach((item) => {
      let formattedAddress = "";
      let addressId = "";
      addressId = item.addressId;
      formattedAddress += item.addressLine1 ? item.addressLine1 + ", " : "";
      formattedAddress += item.addressLine2 ? item.addressLine2 + ", " : "";
      formattedAddress += item.city ? item.city + ", " : "";
      formattedAddress += item.state ? item.state + ", " : "";
      formattedAddress += item.zipcode ? item.zipcode : "";
      if (formattedAddress != "")
        dropdownAddress.push({
          name: addressId,
          values: formattedAddress,
          isActive: 1,
        });
    });
    setAddressList(dropdownAddress);
  }, [props.csaSignup.pickupLocationList]);

  useEffect(() => {
    /* eslint-disable no-unsafe-optional-chaining */
    const { list, total, loading } = props?.csaSignup?.clientPaymentList;
    let data = [];
    // setRows([]);
    setTimeout(() => {
      list.forEach((element) => {
        data.push(
          createData(
            element?.chosenNam,
            element?.clientEmail,
            element?.shareNam,
            element?.vendorName,
            element?.shareInfo?.pickupInfo?.addressId,
            element?.shareId,
            element?.shareInfo?.pickupInfo?.address1
              ? element?.shareInfo?.pickupInfo?.address1
              : element?.shareInfo?.deliveryInfo?.address1,
            element?.shareInfo?.pickupInfo?.address2
              ? element?.shareInfo?.pickupInfo?.address2
              : element?.shareInfo?.deliveryInfo?.address2,
            element?.shareInfo?.pickupInfo?.city
              ? element?.shareInfo?.pickupInfo?.city
              : element?.shareInfo?.deliveryInfo?.city,
            element?.shareInfo?.pickupInfo?.state
              ? element?.shareInfo?.pickupInfo?.state
              : element?.shareInfo?.deliveryInfo?.state,
            element?.shareInfo?.pickupInfo?.zipcode
              ? element?.shareInfo?.pickupInfo?.zipcode
              : element?.shareInfo?.deliveryInfo?.zipcode,
            element?.shareInfo?.pickupInfo?.day,
            element?.shareInfo?.pickupInfo?.openTime,
            element?.shareInfo?.pickupInfo?.closeTime,
            element?.paymentStatus,
            element?.paymentAmount,
            element?.paymentDate,
            element?.paymentScheduleList?.paymentDate,
            element?.paymentScheduleList?.paymentAmount,
            element?.paymentScheduleList?.paymentSchdulDate,
            element.clientNam,
            element.phoneNumber,
            element.lastUpdtTs,
            element?.paymentType,
            element.processedDate,
            element?.startDate,
            element?.endDate,
            element?.paymentScheduleList,
            element
          )
        );
      });
      setRows(data);
      console.log(data);

      handleColumns();
      setclientPaymentListTotal(total);
    }, 300);
    setShowLoader(loading);
  }, [props?.csaSignup?.clientPaymentList]);

  const renderDetails = (row) => {
    // setClientPaymentScheduleList(row.paymentScheduleList);

    const formatStatus = (item) => {
      if (item == "CANCLD") {
        return (
          <div color="#3f6f8f" fontWeight="bold">
            {" "}
            Cancelled{" "}
          </div>
        );
      } else if (item == "SUCESS") {
        return (
          <div color="#3f6f8f" fontWeight="bold">
            {" "}
            Successful{" "}
          </div>
        );
      } else if (item == "REJCTD") {
        return (
          <div color="#3f6f8f" fontWeight="bold">
            {" "}
            Rejected{" "}
          </div>
        );
      } else if (item == "SCHDLD") {
        return (
          <span color="#3f6f8f" fontWeight="bold">
            Scheduled
          </span>
        );
      }
    };

    const formattedPhoneNumber = (phoneNumber) => {
      return (
        <div>
          {" "}
          {`(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
          )}-${phoneNumber.slice(6)}`}{" "}
        </div>
      );
    };
    return (
      <Box padding={"11px"}>
        <Box backgroundColor={"#f8f9fa"}>
          <Table width={"1230"}>
            <TableRow>
              <TableCell sx={{ borderStyle: "none", fontWeight: "bold" }}>
                Client`s Full Name
              </TableCell>
              <TableCell sx={{ borderStyle: "none", fontWeight: "bold" }}>
                Email
              </TableCell>
              <TableCell sx={{ borderStyle: "none", fontWeight: "bold" }}>
                Client Phone
              </TableCell>
              <TableCell sx={{ borderStyle: "none", fontWeight: "bold" }}>
                Pickup/Delivery Location
              </TableCell>
              <TableCell sx={{ borderStyle: "none", fontWeight: "bold" }}>
                Last Updated Time
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ borderStyle: "none" }}>
                {row.clientNam ? row.clientNam : "-"}
              </TableCell>
              <TableCell sx={{ borderStyle: "none" }}>
                {row.clientEmail ? row.clientEmail : "-"}
              </TableCell>
              <TableCell sx={{ borderStyle: "none" }}>
                {row.phoneNumber ? formattedPhoneNumber(row.phoneNumber) : "-"}
              </TableCell>
              <TableCell sx={{ borderStyle: "none" }}>
                {[row.address1, row.address2, row.city, row.state, row.zipcode]
                  .filter((value) => value !== undefined && value !== "")
                  .join(", ") || "-"}

                {row?.day && row?.openTime && row?.closeTime ? (
                  <div>
                    <AccessTimeRoundedIcon />
                    {` ${filterDay(row?.day)}, ${row?.openTime}-${
                      row?.closeTime
                    }`}
                  </div>
                ) : null}
              </TableCell>
              <TableCell sx={{ borderStyle: "none" }}>
                {" "}
                {row.lastUpdtTs
                  ? moment(row.lastUpdtTs).format("MM/DD/YYYY h:mm A")
                  : "-"}
              </TableCell>
            </TableRow>
          </Table>
          <Box>
            <TableCell sx={{ borderStyle: "none", fontWeight: "bold" }}>
              Payment Details
            </TableCell>
            <Divider
              sx={{
                borderWidth: 1,
                backgroundColor: "#006e52",
                width: "90px",
                marginLeft: "8px",
              }}
            />
          </Box>
          <Box padding={"16px"}>
            <Box width={"565px"}>
              <Table
                sx={{
                  "& > thead > tr > th, & > tbody > tr > td": {
                    border: "1px solid #dee2e6",
                  },
                }}
                aria-label="collapsible table"
              >
                <TableHead border={"1px"}>
                  <TableRow>
                    <TableCell>Payment Date</TableCell>
                    <TableCell>Payment Amount</TableCell>
                    <TableCell>Payment Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(row?.paymentScheduleList) ? (
                    row?.paymentScheduleList?.map((item) => (
                      <TableRow key={item.id} sx={{ backgroundColor: "#fff" }}>
                        <TableCell>
                          {moment(item.paymentSchdulDate).format("MM/DD/YYYY")}
                        </TableCell>
                        <TableCell>${item.paymentAmount}</TableCell>
                        <TableCell>
                          {formatStatus(item.paymentStatus)}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        No payment schedule data available.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Box>
      </Box>
    );
    // padding={'15px'}  padding={' 12px 0px'}
  };

  const handleColumns = () => {
    const headers = [
      { id: "details", label: "Details" },
      {
        id: "vendorName",
        label: "Vendor Name",
      },
      {
        id: "clientName",
        label: "Client Name",
        sort: "clientName" in tableSort ? tableSort["clientName"] : "",
        cell: (item) => {
          const fullName = item.clientNam || "";
          const nameArray = fullName.split(" ");
          const firstName = nameArray[0];
          return (
            <>
              <div
                style={{
                  color: "#000",
                  fontSize: "20px",
                  textTransform: "capitalize",
                  fontFamily: "Texta-book",
                  whiteSpace: "nowrap",
                  variant: "text",
                }}
              >
                {item.chosenNam || firstName}
              </div>
            </>
          );
        },
      },
      {
        id: "shareName",
        label: "Share Name",
        sort: "shareName" in tableSort ? tableSort["shareName"] : "",
        cell: (item) => {
          return (
            <>
              <div
                style={{
                  color: "#000",
                  fontSize: "20px",
                  textTransform: "capitalize",
                  fontFamily: "Texta-book",
                  whiteSpace: "nowrap",
                  variant: "text",
                }}
              >
                {item.shareNam}
              </div>
            </>
          );
        },
      },
      {
        id: "paymentStatus",
        label: "Payment Status",
        sort: "paymentStatus" in tableSort ? tableSort["paymentStatus"] : "",
        cell: (item) => {
          if (item?.paymentStatus == "SCHDLD") {
            return (
              <div color="#3f6f8f" fontWeight="bold">
                Scheduled
              </div>
            );
          } else if (item?.paymentStatus == "SUCESS") {
            return (
              <div color="#3f6f8f" fontWeight="bold">
                Successful
              </div>
            );
          } else if (item?.paymentStatus == "REJCTD") {
            return (
              <div color="#3f6f8f" fontWeight="bold">
                {" "}
                Rejected{" "}
              </div>
            );
          } else if (item?.paymentStatus == "CANCLD") {
            return (
              <div color="#3f6f8f" fontWeight="bold">
                {" "}
                Cancelled{" "}
              </div>
            );
          }
        },
      },
      {
        id: "amount",
        label: "Amount",
        cell: (item) => {
          return <div> ${item.paymentAmount} </div>;
        },
      },
      {
        id: "startDate",
        label: "Share Start Date",
        cell: (item) => {
          // const date = moment(item.startDate).format('MMMM YYYY');
          const date =
            item?.paymentType === "MNTHLY"
              ? moment(item.startDate).format("MMMM YYYY")
              : moment(item.startDate).format("MM/DD/YYYY");
          return <div>{date}</div>;
        },
      },
      {
        id: "endDate",
        label: "Share End Date",
        cell: (item) => {
          // const date = (item?.endDate === "Invalid date") ? "-" : item?.endDate;
          const date =
            item?.paymentType === "MNTHLY"
              ? moment(item.endDate).format("MMMM YYYY")
              : moment(item.endDate).format("MM/DD/YYYY");
          return <div>{date}</div>;
        },
      },
      {
        id: "paymentDate",
        label: "Scheduled Payment Date",
        sort: "paymentDate" in tableSort ? tableSort["paymentDate"] : "",
        cell: (item) => {
          const formattedDate = item?.paymentDate
            ? moment(item.paymentDate).format("MM/DD/YYYY")
            : "-"; //hh:mm A
          return <>{formattedDate}</>;
        },
      },
      {
        id: "processedDate,",
        label: "Processed Date",
        sort: "processedDate" in tableSort ? tableSort["processedDate"] : "",
        cell: (item) => {
          const formattedDate = item?.processedDate
            ? moment(item?.processedDate).format("MM/DD/YYYY")
            : "-"; //hh:mm A
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {formattedDate}
            </div>
          );
        },
      },
    ];

    setColumns(headers);
  };

  const exportData = () => {
    const payload = {
      vendorId: props?.isManagePayment ? null : vendorId,
      nam: search,
      shareId: 0,
      addressId: addressId,
      status: status,
      addressType: "delivery",
      // "startDate": moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY"),
      // "endDate": moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY"),
      startDate: filterData.startDate
        ? moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY")
        : "",
      endDate: filterData.endDate
        ? moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY")
        : "",
      paymentDateSort: "desc",
      size: size,
      from: from,
    };
    props.exportClientPaymentList(payload);
  };

  return (
    <Grid container className="container-block" spacing={2}>
      <Grid container sx={{ backgroundColor: "#DBE8DF" }} direction={"row"}>
        <Grid container item columnSpacing={{ xs: 1, sm: 2, md: 3 }} p={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1" sx={{ fontFamily: "texta-bold" }}>
              By Date
            </Typography>
            <DatePickerComponent
              label={dateRangePickerLabel}
              selectedDateObj={(date) => {
                filterHandleChange(date, "", "date");
              }}
              resetDate={resetDate}
            />
            {/* label={`${moment(CalculateUTCTzToUserTz(filterData.startDate)).format('MM-DD-YYYY')} - ${moment(CalculateUTCTzToUserTz(filterData.endDate)).format('MM-DD-YYYY')}`}  */}
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1" sx={{ fontFamily: "texta-bold" }}>
              By Pickup Location
            </Typography>
            <SelectDropdown
              id="statusFilter"
              value={selectedLocation}
              isSearch={true}
              style={{ width: "75%" }}
              variant="outlined"
              options={addressList}
              onChange={(address) => {
                setAddressId(address.name);
                setSelectLocation(address);
                setShowLoader(true);
              }}
            ></SelectDropdown>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1" sx={{ fontFamily: "texta-bold" }}>
              By Status
            </Typography>
            <SelectDropdown
              id="shareFilter"
              value={selectedStatus}
              isSearch={true}
              style={{ width: "75%" }}
              variant="outlined"
              options={statusList}
              onChange={(status) => {
                setStatus(status.name);
                setSelectedStatus(status);
                setShowLoader(true);
              }}
            ></SelectDropdown>
          </Grid>
          <Grid container item xs="auto" p={3} alignItems={"center"}>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Grid item>
                <CustomButton
                  variant="outlined"
                  sx={{ fontWeight: "bold" }}
                  onClick={handleReset}
                  startIcon={
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  }
                >
                  Reset
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton
                  variant="outlined"
                  sx={{ fontWeight: "bold" }}
                  startIcon={
                    <i className="fa fa-download" aria-hidden="true"></i>
                  }
                  onClick={exportData}
                >
                  Export
                </CustomButton>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} justifyContent={"flex-end"}>
        <SearchComponent
          placeholder={"Name,Email"}
          handleChange={handleSearch}
          searchText={search}
        />
      </Grid>
      <Grid item xs={12}>
        <TableComponent
          columns={columns}
          rows={rows}
          showDetails={true}
          expand={expand}
          renderDetails={renderDetails}
          handlePagination={handleNext}
          total={clientPaymentListTotal}
          selectAll={selectAll}
          handleSelectAll={handleSelectAll}
          handleRowClick={handleRowClick}
          selectedRows={selectedRows}
          handleSort={handleSort}
          dataLoading={showLoader}
          page={Math.floor(from / size)}
          rowsPerPage={size}
        />
      </Grid>
    </Grid>
  );
};

PaymentsTab.propTypes = {
  getClientPaymentList: PropTypes.func.isRequired,
  getPickupLocationList: PropTypes.func.isRequired,
  csaSignup: PropTypes.object,
  userAuth: PropTypes.object,
  clientId: PropTypes.func,
  exportClientPaymentList: PropTypes.func.isRequired,
  isManagePayment: PropTypes.bool,
  allowedRoles: PropTypes.array,
};

function mapDispatchToProps(dispatch) {
  return {
    getClientPaymentList: (payload) =>
      dispatch(actionType.getClientPaymentList(payload)),
    getPickupLocationList: (payload) =>
      dispatch(actionType.getPickupLocationList(payload)),
    exportClientPaymentList: (payload) =>
      dispatch(actionType.exportClientPaymentList(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsTab);
