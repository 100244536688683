import React from "react";
import PropTypes from "prop-types";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import LoadingComponent from "./loadingComponent";
import { GOOGLE_KEY } from "../../../config";

const MapComponent = (props) => {
    // eslint-disable-next-line no-unused-vars
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_KEY 
      })

      const renderMap = () => {
          return(<GoogleMap
            id="googleMap"
            mapContainerStyle={{
              position: "relative",
              width: "100%",
              height: "50vh",
              minHeight: "250px",
            }}
            zoom={14}
            center={{
              lat: props.row.latitude,
              lng: props.row.longitude,
            }}
            options={{
              zoomControl: true,
              draggable: props.draggable,
              scrollwheel: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
            }}
            
          >
          <Marker 
              position={{
                  lat: props.lat,
                  lng: props.lng,
              }} 
              draggable={props.draggable}
              onDrag={props.onMarkerChange}
          />
          </GoogleMap>)
      }

    return isLoaded ? renderMap() : <LoadingComponent />
}

MapComponent.propTypes = {
    lat: PropTypes.number,
    lng: PropTypes.number,
    row: PropTypes.object,
    onMarkerChange: PropTypes.func,
    draggable: PropTypes.bool,
};

export default MapComponent;