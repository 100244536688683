import React, { useState, Fragment, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import { UPDATE_VENDOR } from "../../../redux/actionTypes/vendordetails";
import { PUT_USER } from "../../../redux/actionTypes/registration";
import "../../../styles/registration.scss";
import {
  convertUTCDateToLocalDate,
  isAlphanumeric,
  isNumber,
  maskMobile,
} from "../../../utils/globalMethods";
import { getVendorInfoList } from "../../../redux/actionTypes/vendordetails";
import CommonLoading from "../../common/components/commonLoading";

const VendorDetailsTab = (props) => {
  let initial = {
    vendorName: "",
    fnsNum: "",
    firstName: "",
    lastName: "",
    primaryMobile: "",
    primaryEmail: "",
    communicationEmail: "",
    createdBy: "",
    createTs: "",
    lastUpdtTs: "",
    adminLastUpdtTs: "",
    lastUpdtUidNam: "",
    lastUpdtUid: "",
    additionalContactInfo: "",
    vendorHipId: "",
  };
  const userData = props?.userAuth?.user
    ? props?.userAuth?.user
    : JSON.parse(sessionStorage.getItem("userData"));
  const [mode, setMode] = useState("view");
  const [mobile, setMobile] = useState("");
  const [update, setUpdate] = useState(false);
  const [formValue, setFormValue] = useState(initial);
  const [loading, setLoading] = useState(false);
  const [orgId, setOrgId] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
  } = useForm({
    defaultValues: formValue,
  });

  const validateEmail = (value) => {
    if (
      value.toLowerCase().endsWith("state.ma.us") ||
      value.toLowerCase().endsWith("mass.gov")
    ) {
      return "Emails from this domain are not allowed";
    }
    return true;
  };

  useEffect(() => {
    if (props.vendorInfo) {
      const formData = props.vendorInfo;
      if (typeof formData.fnsNum === "string") {
        const format = formData.fnsNum
          .split(",")
          .map((item, i) => ({ id: i, name: item }));
        formData.fnsNum = format;
      }
      if (typeof formData.communicationEmail === "string") {
        const format = formData.communicationEmail
          .split(",")
          .map((item, i) => ({ id: i, name: item }));
        formData.communicationEmail = format;
      }
      setTimeout(() => {
        if (formData?.primaryMobile) {
          setMobile(maskMobile(formData?.primaryMobile));
        }
        reset(formData);
      }, 1000);
      formData.lastUpdtUid = props.vendorInfo.lastUpdtUidNam;
      formData.publicMobile = maskMobile(props.vendorInfo.publicMobile);
      setMobile(maskMobile(props.vendorInfo.primaryMobile));
      formData.adminLastUpdtTs = props.vendorInfo.adminLastUpdtTs
        ? convertUTCDateToLocalDate(props.vendorInfo.adminLastUpdtTs)
        : "";
      formData.createTs = props.vendorInfo.createTs
        ? convertUTCDateToLocalDate(props.vendorInfo.createTs)
        : "";
      formData.lastUpdtTs = props.vendorInfo.lastUpdtTs
        ? convertUTCDateToLocalDate(props.vendorInfo.lastUpdtTs)
        : "";
      setFormValue(formData);

      setOrgId(props?.vendorInfo?.orgId.substring(3))
    }
  }, [props.vendorInfo]);
  const {
    fields: emailField,
    append: EmailAppend,
    remove: EmailRemove,
  } = useFieldArray({
    control,
    name: "communicationEmail",
  });

  let watchEmailArray = watch("communicationEmail");
  const controlledEmailFields = emailField.map((emailField, index) => {
    return {
      ...emailField,
      ...watchEmailArray?.[index],
    };
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "fnsNum",
  });
  let watchFieldArray = watch("fnsNum");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray?.[index],
    };
  });

  const [addMore, setAddMore] = useState(1);
  const handleAddFields = () => {
    setAddMore(addMore + 1);
    append({ name: "" });
  };

  const handleRemove = (i) => {
    setAddMore(addMore - 1);
    remove(i);
  };

  const [communicationEmail, setCommunicationEmail] = useState(1);
  const EmailAddFields = () => {
    setCommunicationEmail(communicationEmail + 1);
    EmailAppend({ name: "" });
  };

  const EmailRemoveFields = (i) => {
    setCommunicationEmail(communicationEmail - 1);
    EmailRemove(i);
  };

  const onFormSubmit = (data) => {
    if (data.fnsNum && data.fnsNum.length > 0) {
      data.fnsNum = data.fnsNum.map((item) => item.name).toString();
      data.id = data.vendorId;
    }
    if (data.communicationEmail && data.communicationEmail.length > 0) {
      const commEmails = [
        ...new Set(data.communicationEmail.map((item) => item.name)),
      ];
      data.communicationEmail = commEmails.toString();
      data.id = data.vendorId;
    }
    const timestamp = new Date().getTime();
    const systemInfo = sessionStorage.getItem("systemInfo");
    const systemData = JSON.parse(systemInfo);
    let role = "";
    if (userData?.Role === "ADMIN") {
      role = "ADM_";
    } else if (userData?.Role === "HIPCRD") {
      role = "CRD_";
    } else {
      role = "VDR_";
    }
    setLoading(true);
    let updatedInfo = {
      firstName: data.firstName,
      lastName: data.lastName,
      fnsNum: data.fnsNum ? data.fnsNum : "",
      communicationEmail: data.communicationEmail
        ? data.communicationEmail
        : "",
      csaFnsNum: data.fnsNum ? data.fnsNum : "",
      csaCommunicationEmail: "",
      vendorName: data.vendorName,
      primaryEmail: data.primaryEmail,
      primaryMobile: data.primaryMobile,
      publicEmail: data.publicEmail ? data.publicEmail.toLowerCase() : "",
      publicMobile: data.publicMobile ? data.publicMobile : "",
      publicWebsite: data.publicWebsite ? data.publicWebsite : "",
      publicPhoto: data.publicPhoto ? data.publicPhoto : "",
      csaPickupSite: data.csaPickupSite ? data.csaPickupSite : "N",
      farmStand: data.farmStand ? data.farmStand : "N",
      farmersMarket: data.farmersMarket ? data.farmersMarket : "N",
      farmersMarketManagers: data.farmersMarketManagers
        ? data.farmersMarketManagers
        : "N",
      mobileMarket: data.mobileMarket ? data.mobileMarket : "N",
      isVerified: data.isVerified ? data.isVerified : "N",
      isActive: data.isActive ? data.isActive : "N",
      forceLogin: data.forceLogin,
      id: data.vendorId,
      vendorId: data.vendorId,
      additionalContactInfo: data.additionalContactInfo
        ? data.additionalContactInfo
        : "",
      products: data.products ? data.products : "",
      businessDesc: data.businessDesc ? data.businessDesc : "",
      lastUpdateTime: data.lastUpdateTime,
      lastUpdtUidNam: role + userData?.id,
      userId: props.userId,
      submitForm: "Vendor information",
      vendorType: "HIPVDR",
      isHipAndCsaVdr: props.isHipAndCsaVdr,
      createTimeStamp: timestamp,
      lastUpdtTimeStamp: timestamp,
      loginId: userData?.id,
      ipAddress: systemData.ip,
      type: "edit",
    };
    if (update) {
      props.updateUser(updatedInfo);
      props.getVendorInfoList(updatedInfo);
      setFormValue(updatedInfo);
      setUpdate(false);
      setMode("view");
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [props.user]);
  console.log(props?.vendorInfo, 45555);
  return (
    <div className="tab-content-wrapper">
      {/* {loading &&
                <CommonLoading />
            } */}
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Row>
          <Col>
            <h3 className="resText"> Profile Details</h3>
          </Col>
          {mode === "view" ? (
            <Col className="txt-right">
              <button
                disabled={props?.vendorInfo?.status === "DE"}
                onClick={() => setMode("edit")}
                className="btn btn-primary"
              >
                <span>
                  <i className="fa fa-pencil"></i> Edit
                </span>
              </button>
              {/* <div
                onClick={
                  props?.vendorInfo?.status === "DE"
                    ? null
                    : () => setMode("edit")
                }
                className="btn btn-primary"
              >
                <span>
                  <i className="fa fa-pencil"></i> Edit
                </span>
              </div> */}
            </Col>
          ) : (
            <Col md={6} xs={12} sm={6} xl={6} lg={6} className="txt-right">
              <Button
                type="submit"
                onClick={() => {
                  setUpdate(true);
                }}
                className="btn btn-primary m-r-5"
              >
                <span>
                  <i className="fa fa-check"></i> Update
                </span>
              </Button>
              <Button
                onClick={() => {
                  setMode("view");
                  if (props?.vendorInfo?.primaryMobile) {
                    setMobile(maskMobile(props?.vendorInfo?.primaryMobile));
                  }
                  reset(props.vendorInfo);
                }}
                className="btn btn-primary"
              >
                <span>
                  <i className="fa fa-times"></i> Cancel
                </span>
              </Button>
            </Col>
          )}
        </Row>
        <br />
        <Row>
          {/* <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="vendorName"
              >
                Farm / Vendor Name *
              </label>
              <input
                {...register("vendorName", {
                  required: "Farm / Vendor name is required",
                  validate: (value) => {
                    if (value.trim() === "") {
                      return "Farm / Vendor name must contain characters";
                    }
                    return true;
                  },
                })}
                className={`form-control form-control-lg required dh-input-box ${
                  errors.vendorName ? "dh-error-box" : ""
                }`}
                placeholder=""
                disabled={mode === "view"}
              />
              {errors.vendorName && (
                <span className="dh-txt-danger">
                  {errors.vendorName.message}
                </span>
              )}
            </div>
          </Col> */}
          <Col md={9} sm={9} xl={9} lg={9}>
            <div className="row" id="fnsNumbersList">
              {controlledFields.map((item, i) => {
                return (
                  <Fragment key={`fnsNum.${i}`}>
                    <div className="col-md-3" id={`fnsNum.${i}`}>
                      <div className="form-group mb-0">
                        <label
                          className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                          htmlFor={`fnsNum.${i}`}
                        >
                          Federal ID *
                          {i > 0 && mode === "edit" ? (
                            <span
                              className={
                                "text-decoration-underline cursor-pointer green dh-remove ml-2"
                              }
                              onClick={() => {
                                handleRemove(i);
                              }}
                            >
                              <i className="fa fa-trash-o"></i> Remove
                            </span>
                          ) : (
                            ""
                          )}
                        </label>

                        <input
                          {...register(`fnsNum.${i}.name`, {
                            required: true,
                            valueAsNumber: true,
                          })}
                          maxLength={7}
                          onKeyDown={isNumber}
                          type="text"
                          className={`form-control form-control-lg fns-number-input dh-input-box required ${
                            errors.fnsNum?.[i]?.name ? "dh-error-box" : ""
                          }`}
                          disabled={mode === "view"}
                        />
                        {errors.fnsNum?.[i]?.name && (
                          <span className="dh-txt-danger">
                            Federal ID is required
                          </span>
                        )}
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
            {mode === "edit" && addMore < 10 && (
              <div
                className="form-text text-muted dh-short-title"
                id="addMoreFNSNumber"
              >
                <span>Do you have an additional Federal ID? </span>
                <span
                  className={"text-decoration-underline cursor-pointer green"}
                  onClick={() => handleAddFields()}
                >
                  <i className="fa fa-plus"></i> Add More
                </span>
              </div>
            )}
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={12} sm={12} xl={12} lg={12}>
            <div className="row" id="communicationEmail">
              {controlledEmailFields.map((item, i) => {
                return (
                  <Fragment key={`communicationEmail.${i}`}>
                    <div
                      className="col-md-3"
                      id={`communicationEmail.${i}`}
                      style={{ display: "none" }}
                    >
                      <div className="form-group mb-0">
                        <label
                          className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                          htmlFor={`communicationEmail.${i}`}
                        >
                          Communication Email *
                          {i > 0 && mode === "edit" ? (
                            <span
                              key={i}
                              className={
                                "text-decoration-underline cursor-pointer dh-remove ml-2 green"
                              }
                              onClick={() => {
                                EmailRemoveFields(i);
                              }}
                            >
                              <i className="fa fa-trash-o"></i> Remove
                            </span>
                          ) : (
                            ""
                          )}
                        </label>

                        <input
                          {...register(`communicationEmail.${i}.name`, {
                            required: "Communication Email is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid Email Address",
                            },
                          })}
                          className={`form-control form-control-lg required dh-input-box lowercase ${
                            errors.communicationEmail?.[i]?.name
                              ? "dh-error-box"
                              : ""
                          }`}
                          id="infoEmailID"
                          aria-label="infoEmailID"
                          aria-labelledby="infoEmailID"
                          aria-describedby="infoEmailID"
                          onKeyDown={isAlphanumeric}
                          disabled={mode === "view"}
                          type="text"
                        />
                        {errors.communicationEmail?.[i]?.name && (
                          <span className="dh-txt-danger">
                            {/* Communication Email is required */}
                            {errors.communicationEmail[i].name.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
            {/* {mode === "edit" && controlledEmailFields.length < 10 && (
              <div
                className="form-text text-muted dh-short-title"
                id="communicationEmailID"
              >
                <span>Do you have an additional Communication Email? </span>
                <span
                  className={"text-decoration-underline cursor-pointer green"}
                  onClick={() => EmailAddFields()}
                >
                  <i className="fa fa-plus"></i> Add More
                </span>
              </div>
            )} */}
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="firstName"
              >
                First Name *
              </label>
              <input
                {...register("firstName", {
                  required: "First name is required",
                  validate: (value) => {
                    if (value.trim() === "") {
                      return "First name must contain characters";
                    }
                    return true;
                  },
                })}
                className={`form-control form-control-lg required dh-input-box ${
                  errors.firstName ? "dh-error-box" : ""
                }`}
                placeholder=""
                disabled={mode === "view"}
              />
              {errors.firstName && (
                <span className="dh-txt-danger">
                  {errors.firstName.message}
                </span>
              )}
            </div>
          </Col>
          <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="lastName"
              >
                Last Name *
              </label>
              <input
                {...register("lastName", {
                  required: "Last name is required",
                  validate: (value) => {
                    if (value.trim() === "") {
                      return "Last name must contain characters";
                    }
                    return true;
                  },
                })}
                className={`form-control form-control-lg required dh-input-box ${
                  errors.lastName ? "dh-error-box" : ""
                }`}
                placeholder=""
                disabled={mode === "view"}
              />
              {errors.lastName && (
                <span className="dh-txt-danger">{errors.lastName.message}</span>
              )}
            </div>
          </Col>

          <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="primaryPhoneNumber"
              >
                Primary Phone Number *
              </label>
              <input
                {...register("primaryMobile", {
                  required: "Primary Phone Number is required",
                  pattern: {
                    value: /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                    message: "Invalid Phone Number",
                  },
                })}
                type="tel"
                className={`form-control form-control-lg required dh-input-box ${
                  errors.primaryMobile ? "dh-error-box" : ""
                }`}
                value={mobile}
                onChange={(e) => setMobile(maskMobile(e.target.value))}
                placeholder="(___) ___-____"
                id="primaryPhoneNumber"
                aria-label="primaryPhoneNumber"
                aria-labelledby="primaryPhoneNumber"
                aria-describedby="primaryPhoneNumber"
                required=""
                disabled={mode === "view"}
              />
              {errors.primaryMobile && (
                <span className="dh-txt-danger">
                  {errors.primaryMobile
                    ? errors.primaryMobile.message
                    : "Primary Phone Number is required"}
                </span>
              )}
            </div>
          </Col>
          <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="infoEmailID"
              >
                Primary Email Address*
              </label>
              <input
                {...register("primaryEmail", {
                  required: "Primary Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid Email Address",
                  },
                  validate: validateEmail,
                })}
                type="text"
                className={`form-control form-control-lg required dh-input-box ${
                  errors.primaryEmail ? "dh-error-box" : ""
                }`}
                maxLength="100"
                id="infoEmailID"
                aria-label="infoEmailID"
                aria-labelledby="infoEmailID"
                aria-describedby="infoEmailID"
                disabled={
                  !(
                    mode === "edit" &&
                    (userData.Role == "ADMIN" || userData.Role == "HIPCRD")
                  )
                }
              />
              {errors.primaryEmail && (
                <span className="dh-txt-danger">
                  {errors.primaryEmail.message}
                </span>
              )}
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="vendorHipId"
              >
                Merchant ID
              </label>
              <input
                {...register("vendorHipId")}
                className={`form-control form-control-lg required dh-input-box ${
                  errors.vendorHipId ? "dh-error-box" : ""
                }`}
                placeholder=""
                disabled="true"
                // value={formValue?.vendorHipId}
                value={orgId}
              />
            </div>
          </Col>
          <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="createdBy"
              >
                Registered By
              </label>
              <input
                {...register("createdBy")}
                className={`form-control form-control-lg required dh-input-box ${
                  errors.createdBy ? "dh-error-box" : ""
                }`}
                placeholder=""
                disabled="true"
                value={formValue?.primaryEmail}
              />
            </div>
          </Col>
          <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="createTs"
              >
                Registered Time
              </label>
              <input
                {...register("createTs")}
                className={`form-control form-control-lg required dh-input-box ${
                  errors.createTs ? "dh-error-box" : ""
                }`}
                placeholder=""
                disabled="true"
                value={formValue?.createTs}
              />
            </div>
          </Col>

          <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="lastUpdtTs"
              >
                Last Updated Time
              </label>
              <input
                {...register("lastUpdtTs")}
                className={`form-control form-control-lg required dh-input-box ${
                  errors.lastUpdtTs ? "dh-error-box" : ""
                }`}
                disabled="true"
                value={formValue?.lastUpdtTs}
              />
              {errors.lastUpdtTs && (
                <span className="dh-txt-danger">
                  {errors.lastUpdtTs.message}
                </span>
              )}
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          {/* <Col md={9} sm={9} xl={9} lg={9}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="additionalContactInfo"
              >
                Additional Contact Info
              </label>
              <textarea
                {...register("additionalContactInfo", {
                  maxLength: {
                    value: "3000",
                    message: "Maximum character count exceeded",
                  },
                })}
                className={`form-control form-control-lg  ${
                  errors.primary_contact_info ? "dh-error-box" : ""
                }`}
                id="additionalContactInfo"
                rows="3"
                aria-label="additionalContactInfo"
                aria-labelledby="additionalContactInfo"
                aria-describedby="additionalContactInfo"
                disabled={mode === "view"}
              ></textarea>
              <span className="float-right text-muted mt-1 ">
                <i className="fa fa-info-circle mr-1" aria-hidden="true"></i>
                Max character : 3000
              </span>
              {errors.additionalContactInfo && (
                <span className="dh-txt-danger">
                  {errors.additionalContactInfo.message}
                </span>
              )}
            </div>
          </Col> */}
          <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="adminLastUpdtTs"
              >
                Staff Last Updated Time
              </label>
              <input
                {...register("adminLastUpdtTs")}
                className={`form-control form-control-lg required dh-input-box ${
                  errors.adminLastUpdtTs ? "dh-error-box" : ""
                }`}
                disabled="true"
                value={formValue?.adminLastUpdtTs}
              />
            </div>
            <br />
          </Col>
          <Col md={3} sm={3} xl={3} lg={3}>
            <div className="form-group mb-0">
              <label
                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                htmlFor="lastUpdtUidNam"
              >
                Last Updated By
              </label>
              <input
                {...register("lastUpdtUidNam")}
                className={`form-control form-control-lg required dh-input-box ${
                  errors.lastUpdtUidNam ? "dh-error-box" : ""
                }`}
                placeholder=""
                disabled="true"
                value={formValue?.lastUpdtUidNam}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
VendorDetailsTab.propTypes = {
  updateVendorDetails: PropTypes.func.isRequired,
  vendorInfo: PropTypes.object,
  updateUser: PropTypes.func.isRequired,
  userId: PropTypes.number,
  userAuth: PropTypes.object.isRequired,
  vendorDetails: PropTypes.object.isRequired,
  getVendorInfoList: PropTypes.func,
  vendorType: PropTypes.string,
  isHipAndCsaVdr: PropTypes.bool,
  vendorId: PropTypes.string,
  add_CSA: PropTypes.bool,
  user: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateVendorDetails: (user) => {
      dispatch({ type: UPDATE_VENDOR, value: user });
    },
    updateUser: (user) => {
      dispatch({ type: PUT_USER, value: user });
    },
    getVendorInfoList: (payload) => dispatch(getVendorInfoList(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorDetailsTab);
