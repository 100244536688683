
const initialState = {
  farmersMarket: {
    list: [],
    total: 0,
    loading: true,
  },
  productsList: {
    list: [],
    total: 0,
    loading: true,
  },
  accessLogs: {
    list: [],
    total: 0,
    loading: true,
  },
  loginLogs: {
    list: [],
    total: 0,
    loading: true,
  },
  userList: {
    list: [],
    total: 0,
    loading: true,
  },
  coordinatesList: {
    list: [],
    total: 0,
    loading: true,
  },

  refreshKey: 0,
  exportFarmersMarket: [],
  sendFeedback: {
    data: {},
  },
};

const administrationReducer = (state=initialState, action) => {
    switch (action.type) {
      case "SEND_FEEDBACK_SUCCESS":
        return {
          ...state,
          sendFeedback: { data: action.payload },
        };

      case "SET_FARMERS_MARKET": {
        return {
          ...state,
          farmersMarket: {
            list: action.payload.data,
            total: action.payload.total,
            loading: action.payload.loading,
          },
        };
      }
      case "GET_FARMERS_PRODUCTS": {
        return {
          ...state,
          productsList: {
            list: action.payload.data,
            total: action.payload.total,
            loading: action.payload.loading,
          },
        };
      }
      case "SET_EXPORT_FARMERS_MARKET": {
        return { ...state, exportFarmersMarket: action.payload };
      }
      case "SET_ACCESS_LOGS": {
        return {
          ...state,
          accessLogs: {
            list: action.payload.data,
            total: action.payload.total,
            loading: action.payload.loading,
          },
        };
      }
      case "SET_LOGIN_LOGS": {
        return {
          ...state,
          loginLogs: {
            list: action.payload.data,
            total: action.payload.total,
            loading: action.payload.loading,
          },
        };
      }
      case "SET_USER_LIST": {
        return {
          ...state,
          userList: {
            list: action.payload.data,
            total: action.payload.total,
            loading: action.payload.loading,
          },
        };
      }
      case "SET_MANAGE_COORDINATES": {
        return {
          ...state,
          coordinatesList: {
            list: action.payload.data,
            total: action.payload.total,
            loading: action.payload.loading,
          },
        };
      }

      case "REFRESH_KEY": {
        return { ...state, refreshKey: state.refreshKey + 1 };
      }
      default:
        return state;
    }
  };

  export default administrationReducer;