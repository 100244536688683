import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import "../../styles/home.scss";
import TotalCardComponent from "./totalCard";
import GraphComponent from "./graphs";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getMarketStats,
  getMarketCountyStats,
} from "../../redux/actionTypes/adminDashboard";
import { useSelector, useDispatch } from "react-redux";


const MarketDashboard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { adminDashboard } = useSelector(state => state)
  useEffect(() => {
    const payload = {
      cityId: "",
      // createTimeStamp: 1710347284338,
      // ipAddress: "1",
      // lastUpdtTimeStamp: 1710347284338,
      // lastUpdtUidNam: "ADM_2060",
    };
    dispatch(getMarketStats(payload));
    dispatch(getMarketCountyStats(payload));
  }, []);

  const legend = ["Total Count", "Verified Count", "Un Verified Count"];

  const { statisticsData, statsMarket, marketStatisticsData } = adminDashboard;
  const chartData = [
    { value: statsMarket.vendorTotalCount, name: "Total Count" },
    {
      value: statsMarket.verifiedCount,
      name: "Verified Count",
    },
    {
      value: statsMarket.unverifiedCount,
      name: "Un Verified Count",
    },
  ];
  const barChart = () => {
    let data = [];
    // data.push([
    //   "City",
    //   // "SPF Program",
    //   "SPF Roadside Stand",
    //   // "SPF Farmers' Market Booth",
    //   "SPF Farmers' Market",
    //   // "SPF Mobile Market Stop",
    // ]);
    let MICites = [
      {
        cityName: "Ingham",
        totalCsaPickupSite: 4,
        totalFarmersMarket: 2,
      },
      {
        cityName: "Oakland",
        totalCsaPickupSite: 6,
        totalFarmersMarket: 8,
      },
      {
        cityName: "Wayne",
        totalCsaPickupSite: 2,
        totalFarmersMarket: 3,
      },
    ];

    marketStatisticsData.totalCity.forEach((ele, i) => {
      // let randomMICity = MICites[Math.floor(Math.random() * MICites.length)];
      // MICites.splice(MICites.indexOf(randomMICity), 1);
      data.push([
        ele.cityName,
        // ele.totalCsaPickupSite,
        ele.totalCsaPickupSite,
        // ele.totalFarmersMarket,
        // ele.totalFarmersMarket,
        // ele.totalMobileMarket,
      ]);
    });
    return data;
  };

  const heatChart = () => {
    let data = [];
    let maxNum = 0;
    statisticsData.totalMonths.forEach((ele) => {
      let month = ele.month - 1;
      maxNum = Math.max(
        ...[
          maxNum,
          ele.totalCsaPickupSite,
          // ele.totalFarmStand,
          ele.totalFarmersMarket,
          // ele.totalFarmersMarketManagers,
          // ele.totalMobileMarket,
        ]
      );
      data.push(
        [month, 0, ele.totalCsaPickupSite],
        // [month, 1, ele.totalFarmStand],
        [month, 2, ele.totalFarmersMarket]
        // [month, 3, ele.totalFarmersMarketManagers],
        // [month, 4, ele.totalMobileMarket]
      );
    });

    return { data: data, maxNumber: maxNum };
  };

  return (
    <>
      <Grid container spacing={2} className="dashboard-block">
        <Grid item xs={12} lg={4}>
          <TotalCardComponent
            total={statsMarket.vendorTotalCount}
            subtitle={"Total Market-Co"}
            status="info"
          />
        </Grid>
        <Grid item xs={12} lg={4} tabIndex={"0"}>
          <TotalCardComponent
            total={statsMarket.unverifiedCount}
            subtitle={"Total Un-Verified Market-Co"}
            status="warning"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TotalCardComponent
            total={statsMarket.verifiedCount}
            subtitle={"Verified Count"}
            status="success"
          />
        </Grid>
        <Grid item xs={6}>
          <GraphComponent
            title={"Market Co-Ordinator Count"}
            graphType={"pie"}
            chartData={chartData}
            legend={legend}
          />
        </Grid>
        <Grid item xs={6}>
          <GraphComponent
            title={"Market Co-Ordinator By County"}
            graphType={"bar"}
            chartData={barChart()}
          />
        </Grid>
        <Grid item xs={12}>
          {/* <GraphComponent
            title={"Month(s) of Operation By Type"}
            graphType={"heat"}
            chartData={heatChart().data}
            maxNumber={heatChart().maxNumber}
          /> */}
        </Grid>
      </Grid>
    </>
  );
};

export default MarketDashboard;
