import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import useLoader from "../../../../hooks/useLoader";
import toast from "react-hot-toast";
import { formerPayload } from "./data";
import PropTypes from "prop-types";
import axiosInstance from "../../../../redux/interceptor";
import { AUTH_API } from "../../../../config";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CloseIcon from "@mui/icons-material/Close";

const AlertViewFarmer = ({
  viewDialogOpen,
  setViewDialogOpen,
  message,
  getSubmittedAlert,
  vendorId,
  alertTableData,
}) => {
  const { openLoader, closeLoader } = useLoader();

  const schema = yup.object().shape({
    alertMessageFormer: yup.string().required("Reply message is required"),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleViewModalClose = () => {
    setViewDialogOpen(false);
    reset();
  };

  const onSubmit = async (data) => {
    const url = `${AUTH_API}/createOrUpdateAlert`;
    try {
      openLoader("alert");
      const payload = formerPayload(data, message, vendorId);
      const res = await axiosInstance.post(url, payload);
      if (res.status === 200) {
        getSubmittedAlert();
        toast.success("Alert Reply Submitted !");
      }
    } catch (error) {
      toast.error("Filed to Submit!");
      console.log(error);
    } finally {
      closeLoader("alert");
      setTimeout(() => {
        handleViewModalClose();
      }, [2000]);
    }
  };

  return (
    <>
      <Dialog
        open={viewDialogOpen}
        maxWidth="sm"
        fullWidth
        scroll="paper"
        onClose={handleViewModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "22px", fontWeight: "600" }}
        >
          Alert
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleViewModalClose}
          sx={{
            position: "absolute !important",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Stack direction={"column"} gap={"20px"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Grid item xs={3}>
                      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                        Alert Message
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        name="alertMessageFormer"
                        control={control}
                        defaultValue={message?.alertMessage ?? ""}
                        required
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Write Alert message here"
                            multiline
                            rows={5}
                            fullWidth
                            sx={{ width: "25rem" }}
                            disabled={true}
                            readOnly={true}
                          />
                        )}
                      />
                    </Grid>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Grid item xs={3}>
                      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                        Reply<span style={{ color: "red" }}>*</span>
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        name="replyFormer"
                        control={control}
                        defaultValue={message ? message?.recipientComment : ""}
                        required
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={errors.replyFormer?.message}
                            helperText={errors.replyFormer?.message}
                            placeholder="Write Alert message here"
                            multiline
                            rows={5}
                            fullWidth
                            sx={{ width: "25rem" }}
                            disabled={message?.recipientComment}
                          />
                        )}
                      />
                    </Grid>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-end !important"}
                    ml={2}
                  >
                    <div style={{ marginLeft: "20px" }}>
                      <Button
                        autoFocus
                        color="success"
                        variant="contained"
                        type="submit"
                        disabled={message?.recipientComment}
                      >
                        Submit
                      </Button>
                    </div>
                  </Stack>
                </Stack>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
AlertViewFarmer.propTypes = {
  vendorId: PropTypes.number,
  viewDialogOpen: PropTypes.bool,
  setViewDialogOpen: PropTypes.bool,
  message: PropTypes.object,
  getSubmittedAlert: PropTypes.func,
  alertTableData: PropTypes.array,
};
export default AlertViewFarmer;
