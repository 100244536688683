import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';

export default function LoadingComponent() {
  return (
    <Stack direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}>
      <LoadingButton
        loading
        loadingPosition="start"
      >
        Processing…
      </LoadingButton>
    </Stack>
  );
}