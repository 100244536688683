import React from "react";
import { Divider, Box, Typography } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import PropTypes from "prop-types";

const FileUploadContent = ({ handleChange, selectedFile }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#e3e3e3",
        textAlign: "center",
        height: "auto !important",
      }}
    >
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={["PDF"]}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
        >
          <DriveFolderUploadIcon sx={{ fontSize: 250, color: "#d9d9d9" }} />
          <Typography variant="h5" mb={3}>
            Drop your files here
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="center" alignItems="center">
          <span>or</span>
        </Box>
        <div>Browse</div>
        <span style={{ fontSize: "12px" }}>
          Note: Only PDF file format is supported.
        </span>
        <p
          style={{
            fontSize: "14px",
            color: selectedFile?.name ? "green" : "red",
          }}
        >
          {selectedFile?.name}
        </p>
      </FileUploader>
    </div>
  );
};

FileUploadContent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  selectedFile: PropTypes.object,
};

export default FileUploadContent;
