import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  Typography,
  Box,
  Paper,
  Avatar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "20px !important",
    fontWeight: "700 !important",
    color: "#6C6C6C",
  },
  typography: {
    fontSize: "16px !important",
    fontWeight: "700 !important",
    // textAlign: "center",
  },
  label: {
    fontSize: "16px !important",
    fontWeight: "700 !important",
    color: "#6C6C6C",
  },
  formHelperText: {
    marginLeft: "0 !important",
  },
}));

const ResetPassword = () => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      userType: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      userType: Yup.string().required("User type is required"),
      email: Yup.string().required("Email is required"),
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password required"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <Box sx={{ padding: "30px", width: "100%" }}>
      <Typography
        variant="h5"
        display="inline"
        className="eligibilityText text-capitalize"
        gutterBottom
        component="div"
      >
        Reset Password
      </Typography>
      <Grid
        container
        mt={3}
        border="1px solid #C2C2B7"
        p={3}
        borderRadius={2}
        spacing={2}
      >
        <Grid container item xs={6} p={4} spacing={2}>
          <Grid item xs={12} display="flex" justifyContent="center">
            <LockOpenOutlinedIcon
              sx={{
                border: "1px solid #C2C2B7",
                borderRadius: "50%",
                fontSize: "45px",
                padding: "8px",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.typography}>User Type</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              select
              name="userType"
              size="small"
              value={formik.values.userType}
              onChange={formik.handleChange}
              error={formik.touched.userType && Boolean(formik.errors.userType)}
              helperText={formik.touched.userType && formik.errors.userType}
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
            >
              <MenuItem value="farmer">Farmer</MenuItem>
              <MenuItem value="senior">Senior</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography className={classes.typography}>Email ID</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              name="email"
              size="small"
              placeholder="Enter Email ID"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
              autoComplete="new-email"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography className={classes.typography}>Password</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              type="password"
              name="password"
              size="small"
              placeholder="Enter Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
              autoComplete="new-password"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography className={classes.typography}>
              Confirm Password
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              type="password"
              name="confirmPassword"
              size="small"
              placeholder="Confirm Password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
            />
          </Grid>

          <Grid item xs={12} textAlign="end" mt={1}>
            <Button
              type="submit"
              variant="contained"
              color="success"
              onClick={formik.handleSubmit}
            >
              Reset Password
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={6}
          border="1px solid #C2C2B7"
          p={3}
          spacing={2}
          borderRadius={2}
          mt={1}
        >
          <Grid item xs={12} display="flex" justifyContent="center">
            <Avatar sx={{ width: "60px", height: "60px" }}>FA</Avatar>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.label}>Name</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.label}>Firthous Ali</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.label}>DOB</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.label}>05/25/1970</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.label}>Gender</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.label}>Male</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.label}>Marital Status</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.label}>Married</Typography>
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <Typography className={classes.label}>Race</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.label}>White</Typography>
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <Typography className={classes.label}>Address</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.label}>
              101 Summer Street, Michigan MD 01201
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.label}>Email</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.label}>
              firthous@gmail.com
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.label}>Contact No</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.label}>+1 (204) 521-5321</Typography>
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <Typography className={classes.label}>Ethnicity</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.label}>Unknown</Typography>
          </Grid> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResetPassword;
