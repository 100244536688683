import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import HouseIcon from "@mui/icons-material/House";
import harvest from "../../assets/images/harvest.svg";
import { Card, Collapse, Grid, Link, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import ContactsIcon from "@mui/icons-material/Contacts";
import xls_export from "../../assets/images/marker/xls-export.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import _ from "lodash";
import {
  GET_SPECIAL_HOUR,
  GET_STORE_DETAILS,
  GET_STORE_REPORT,
} from "../../redux/actionTypes";
import moment from "moment";
import CommonLoading from "../common/components/commonLoading";
import {
  isBase64String,
  removeSpecialCharacter,
} from "../../utils/globalMethods";
import Expand from "../../assets/images/Expand.svg";
import CollapseImg from "../../assets/images/Collapse.svg";
import { appHomeUrl } from "../../config";
import { useSearchParams } from "react-router-dom";
import useTranslator from "../../hooks/useTranslator";

const LOCATION_TYPES = {
  csaPickupSite: {
    loc_type_nam: "SPFProgram",
    loc_type_img: "./marker/csa-farms.png",
  },
  farmStand: {
    loc_type_nam: "SPFFarmStand",
    loc_type_img: "./marker/farm-stands.png",
  },
  farmersMarket: {
    loc_type_nam: "SPFFarmersMarketBooth",
    loc_type_img: "./marker/farmers-market-managers.png",
  },
  farmersMarketManagers: {
    loc_type_nam: "SPFFarmersMarket",
    loc_type_img: "./marker/market-managers.png",
  },
  mobileMarket: {
    loc_type_nam: "SPFMobileMarketStop",
    loc_type_img: "./marker/mobile-market.png",
  },
};

const monthNumber = [
  { name: "january", value: "1", id: "janOperation" },
  { name: "february", value: "2", id: "febOperation" },
  { name: "march", value: "3", id: "marOperation" },
  { name: "april", value: "4", id: "aprOperation" },
  { name: "may", value: "5", id: "mayOperation" },
  { name: "june", value: "6", id: "junOperation" },
  { name: "july", value: "7", id: "julOperation" },
  { name: "august", value: "8", id: "augOperation" },
  { name: "september", value: "9", id: "sepOperation" },
  { name: "october", value: "10", id: "octOperation" },
  { name: "november", value: "11", id: "novOperation" },
  { name: "december", value: "12", id: "decOperation" },
];

const getMapURL = (data) => {
  return `https://www.google.com/maps/search/?api=1&query=${data?.store?.addressDetail?.latitude},${data?.store?.addressDetail?.longitude}`;
};

const getSiteUrl = (url) => {
  return url.indexOf("http://") == 0 || url.indexOf("https://") == 0
    ? url
    : `//${url}`;
};

const ListViewResult = (props) => {
  const transProps = useTranslator(
    [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
      "goStoreWebsite",
      "vendorDescription",
      "specialInstructions",
      "regularHourOperation",
      "closed",
      "monthOperation",
      "orderOption",
      "products",
      "vendorPhotos",
      "specialHourOperation",
      "available",
      "week",
      "day",
      "month",
      "time",
      "notAvailable",
      "export",
      "milesAway",
      "SPFSignupLink",
      "viewDetails",
      "navigateLocation",
      "sectionEnd",
      "SPFFarmersMarket",
      "SPFFarmersMarketBooth",
      "SPFProgram",
      "SPFFarmStand",
      "SPFMobileMarketStop",
      "closedToday",
      "openToday",
      "openNow",
      "closedNow",
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
      "curbsidePickup",
      "delivery",
      "inPerson",
      "phonePreSelection",
      "other",
      "onlinePreSelection",
    ],
    "listViewResult"
  );
  const [searchParams] = useSearchParams();
  const appId = searchParams.get("appId");

  const [singleSection, setSingleSection] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [locationId, setLocationId] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [marketNames, setMarketNames] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [searchResultView, setSearchResultView] = useState(false);
  const [originalArray, setOriginalArray] = useState([]);
  const [currentVendor, setCurrentVendor] = useState();
  const [isMarket, setIsMarket] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [accordStoreId, setAccordStoreId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [singleSectionClick, setSingleSelectionClick] = useState(false);
  // const [selectedStoreIds, setSelectedStoreIds]=useState({});
  const [currentVendorStoreDetail, setCurrentVendorStoreDetail] = useState({});
  const [currentMarketVendorStoreDetail, setCurrentMarketVendorStoreDetail] =
    useState({});
  const [sortBy, setSortBy] = useState("asc");
  const titlesListview = {
    "font-family":
      '"Texta-book", "Helvetica", "Arial", "sans-serif" !important',
    "font-size": "16px !important",
    "font-weight": "600",
    "line-height": "24px !important",
    color: "#6c6c6c !important",

    "letter-spacing": "0em !important",
    "text-align": "left !important",
  };
  const csaProperty = {
    width: "186px",
    height: "52px",
    padding: "14px, 10px, 14px, 10px",
    "border-radius": "6px",
    background: "#457425",
    gap: "10px",
  };
  const mergeFarmersMarket = (result) => {
    let commonNameList = {};
    result.map((item) => {
      if (item.store.storeType === "HIP Farmers Market Booth")
        item.farmersMarket = "Y";
      else if (item.store.storeType === "HIP Farmers Market")
        item.farmersMarketManagers = "Y";
      else if (item.store.storeType === "HIP CSA PROGRAM")
        item.csaPickupSite = "Y";
      else if (item.store.storeType === "HIP Farm Stand") item.farmStand = "Y";
      else if (item.store.storeType === "HIP Mobile Market Stop")
        item.mobileMarket = "Y";

      if (
        item.store.storeType == "HIP Farmers Market Booth" ||
        item.store.storeType == "HIP Farmers Market"
      ) {
        if (item.store.siteName in commonNameList) {
          if (item.store.storeType == "HIP Farmers Market Booth") {
            commonNameList[item.store.siteName].farmersMarket = "Y";
          } else {
            commonNameList[item.store.siteName].farmersMarketManagers = "Y";
          }
        } else if (!(item.store.siteName in commonNameList)) {
          commonNameList[item.store.siteName] = item;
        }
      } else {
        commonNameList[item.store.id] = item;
      }
    });
    return Object.values(commonNameList);
  };

  useEffect(() => {
    if (props.mapResult && props.mapResult.length > 0) {
      let CommonNameArray = mergeFarmersMarket(props.mapResult);
      // if (CommonNameArray.length === 0) {
      //     setSearchResultView(false);
      //     setSingleSection(false);
      // } else {
      //     setSingleSection(false);
      //     setSearchResultView(true);
      // }
      // if(singleSectionClick){
      //     setSingleSection(true);
      //     setSearchResultView(false);
      // }

      // const data = props.mapResult.find((location) => location.store.id === props.locationId);
      // if (data) {
      //     setCurrentVendor(data)
      // }

      //// grouping market names
      let marketNames = {};
      let storeList = [];
      let marketList = [];
      if (props?.filterClusterer?.length) {
        CommonNameArray = CommonNameArray.filter((item) =>
          props.filterClusterer.includes(item.store.id)
        );
      }
      if (CommonNameArray.length) {
        for (let i = 0; i < CommonNameArray.length; i++) {
          let obj = CommonNameArray[i];
          if (
            obj.location_types_name == "farmersMarket" ||
            obj.location_types_name == "farmersMarketManagers"
          ) {
            CommonNameArray[i]["displayName"] = obj.store.siteName;
          } else {
            CommonNameArray[i]["displayName"] = obj.vendorName;
          }
        }
      }
      marketNames = _.groupBy(
        _.sortBy(CommonNameArray, "farmer_market_name"),
        "farmer_market_name"
      );
      for (let property in marketNames) {
        for (let i = 0; i < marketNames[property].length; i++) {
          const element = marketNames[property][i];
          if (element.farmer_market_name) {
            //
          } else {
            storeList.push(element);
            delete marketNames[property][i];
          }
        }
      }
      if (Object.keys(marketNames).length > 0) {
        for (let property in marketNames) {
          let obj = marketNames[property][0];
          if (obj && property != "") {
            marketList.push(obj);
          }
        }
        setMarketNames(marketNames);
      }
      marketList = marketList.length ? marketList : [];
      storeList = storeList.length ? storeList : [];
      const vendorData = _.union(marketList, storeList);
      // console.log("********");
      // console.log(vendorData);
      // console.log("**********");
      setOriginalArray(vendorData);

      if (props.locationId) {
        const selectedStore = props.mapResult.filter(
          (item) => item.storeId == props.locationId
        );
        setOriginalArray(selectedStore);
        setCurrentVendor(selectedStore[0]);
        setSingleSection((prevState) => ({
          ...prevState,
          [props.locationId]: true,
        }));
        selectedStore?.[0]?.location_types_name === "farmersMarket" ||
        selectedStore?.[0]?.location_types_name === "farmersMarketManagers"
          ? openMarketStoreDetail(selectedStore[0])
          : openVendorStoreDetail(selectedStore[0]);
      }
    } else {
      setSearchResultView(false);
      // setSingleSection(false);
      // setSingleSection((prevState)=>({
      //   ...prevState,
      //   [obj.store.id] : !singleSection[obj.store.id]
      // }));
      // mapWidth ="100%";
      // closeResultSection("100%");
      setOriginalArray([]);
    }
  }, [
    props.mapResult,
    props.locationId,
    props?.filterClusterer,
    props?.mapRefresh,
  ]);

  useEffect(() => {
    setSingleSection({});
  }, [props?.filterClusterer]);

  useEffect(() => {
    if (props?.mapdata?.storeDetails?.length > 0) {
      if (currentVendor) {
        // let storeIds = [];
        props.mapdata.storeDetails.forEach((item) => {
          item["location_types_name"] = currentVendor["location_types_name"];
          // storeIds.push(item.store.id);
        });
        // setSelectedStoreIds((prevState) => ({
        //   ...prevState,
        //   [currentVendor?.store?.id] : storeIds
        // }));
        if (!isMarket) {
          setCurrentVendorStoreDetail((prevState) => ({
            ...prevState,
            [currentVendor?.store?.id]: props.mapdata.storeDetails,
          }));
          setIsLoading(false);
        } else {
          setCurrentMarketVendorStoreDetail((prevState) => ({
            ...prevState,
            [currentVendor?.store?.id]: props.mapdata.storeDetails,
          }));
          // setSingleSection(true);
          setIsLoading(false);
        }
      }
    }
  }, [props.mapdata.storeDetails]);

  // useEffect(()=>{
  //     // if (props.locationId) {
  //     //     const selectedStore = props?.mapResult.filter(item=>item.storeId == props.locationId);
  //     //     setSelectedStore(selectedStore);
  //     //     setStoreDetails((prevState) => ({
  //     //         ...prevState,
  //     //         [props.locationId]:selectedStore[0]
  //     //     }));
  //     //     storeDetailsData(props.locationId);
  //     // } else {
  //     //     setSelectedStore(props.mapResult);
  //     // }
  //     if (props.locationId) {
  //       const selectedStore = originalArray.filter(item=>item.storeId == props.locationId);
  //       setOriginalArray(selectedStore);
  //       (selectedStore[0].location_types_name === "farmersMarket" ||
  //       selectedStore[0].location_types_name === "farmersMarketManagers")
  //       ? openMarketStoreDetail(mapResultData)
  //       : openVendorStoreDetail(mapResultData);

  //     }
  // },[props.locationId]);

  const storeDetailsData = (id) => {
    if (id) {
      // setSingleSection(!singleSection);
      setSingleSelectionClick(true);
      setLocationId(id);
      let payload = {
        cityName: "",
        miles: "",
        lat: "",
        lon: "",
        allCityFlag: "N",
        vendorId: "",
        csaPickupSite: "Y",
        farmStand: "Y",
        farmersMarket: "Y",
        farmersMarketBooth: "Y",
        mobileMarket: "Y",
        monthsOperation: {
          janOperation: "Y",
          febOperation: "Y",
          marOperation: "Y",
          aprOperation: "Y",
          mayOperation: "Y",
          junOperation: "Y",
          julOperation: "Y",
          augOperation: "Y",
          sepOperation: "Y",
          octOperation: "Y",
          novOperation: "Y",
          decOperation: "Y",
        },
        distributionOption: {
          delivery: "Y",
          curbsidePickup: "Y",
          onlinePreselection: "Y",
          phonePreselection: "Y",
          inPerson: "Y",
          otherType: "Y",
        },
        monOperation: "Y",
        tueOperation: "Y",
        wedOperation: "Y",
        friOperation: "Y",
        thuOperation: "Y",
        satOperation: "Y",
        sunOperation: "Y",
        openToday: "N",
        storeId: id,
      };
      props.getStoreDetails(payload);
    }
  };

  // useEffect(()=>{
  //     if (props?.mapdata?.storeDetails && props?.mapdata?.storeDetails.length) {
  //         setStoreDetails((prevState) => ({
  //             ...prevState,
  //             [props?.mapdata?.storeDetails[0]?.store?.id]:props?.mapdata?.storeDetails[0]
  //         }));

  //     }
  // },[props?.mapdata?.storeDetails]);

  // useEffect(() => {
  //     const storeDetailsData = props?.mapdata?.storeDetails
  //     if (storeDetailsData.length > 0) {
  //       let storeIds = [];
  //       storeDetailsData.forEach(item=>{
  //         item['location_types_name'] = currentVendor['location_types_name'];
  //         storeIds.push(item.store.id);
  //       })
  //       setSelectedStoreIds(storeIds)
  //       setCurrentVendorStoreDetail(storeDetailsData);
  //       setSingleSection(true);
  //     }
  // },[props?.mapdata?.storeDetails,props.locationId]);

  // useEffect(()=>{
  //     console.log(props.mapdata.orderOptions);
  // },[props.mapdata.orderOptions])

  const getGroupFarmerMarket = (obj) => {
    let storeTypeElements = "";
    let storeMarketElements = "";
    // eslint-disable-next-line no-unused-vars
    // let isMarketManagerIcon = false;
    for (let property in LOCATION_TYPES) {
      if (obj.location_types_name === property) {
        if (
          property === "farmersMarket" ||
          property === "farmersMarketManagers"
        ) {
          // isMarketManagerIcon = true;
          let isMarketBooth = obj.farmersMarket === "Y";
          let isMarketManager = obj.farmersMarketManagers === "Y";
          if (isMarketBooth) {
            storeMarketElements = LOCATION_TYPES["farmersMarket"];
          }
          if (isMarketManager) {
            storeTypeElements = LOCATION_TYPES["farmersMarketManagers"];
          }
        } else {
          storeTypeElements = LOCATION_TYPES[property];
        }
      }
    }
    return (
      <>
        {" "}
        {Object.keys(storeMarketElements).length ? (
          <Grid md={12}>
            <small className="boldFont">
              {console.log(
                storeMarketElements.loc_type_nam,
                "storeMarketElements.loc_type_nam"
              )}
              {transProps[storeMarketElements.loc_type_nam]}
            </small>{" "}
            {/* <img
                src={storeMarketElements.loc_type_img} //getLocationImg(obj)
                width="24"
                title={storeMarketElements.loc_type_nam}
                aria-label={
                  storeMarketElements.loc_type_nam
                }
                alt="img"
                role="none"
              />{" "} */}
          </Grid>
        ) : (
          ""
        )}
        {Object.keys(storeTypeElements).length ? (
          <Grid md={12}>
            <small className="boldFont">
              {transProps[storeTypeElements.loc_type_nam]}
            </small>{" "}
            {/* <img
                  src={storeTypeElements.loc_type_img} //getLocationImg(obj)
                  width="24"
                  title={storeTypeElements.loc_type_nam}
                  aria-label={
                    storeTypeElements.loc_type_nam
                  }
                  alt="img"
                  role="none"
                />{" "} */}
          </Grid>
        ) : (
          ""
        )}
      </>
    );
  };

  const handleCollapseToggle = (storeId, index) => {
    // setOpenCollapse((prevIndex) => (prevIndex === index ? null : index));
    setOpenCollapse((prevState) => ({
      ...prevState,
      [storeId + index + 1]:
        openCollapse?.[storeId + index + 1] === storeId + index + 1
          ? null
          : storeId + index + 1,
    }));
  };

  // useEffect(()=>{
  //     let storesReport= props?.mapdata?.storesReport

  //     if (storesReport.length && filterClusterer.length) {
  //       storesReport = storesReport.filter(item => filterClusterer.includes(item.store.id));
  //     }
  //     if(props.mapResult.length && storesReport.length){
  //       let reportData = [];
  //       storesReport.map((item,i) => {
  //         for (let j = 0; j < props.mapResult.length; j++) {
  //           if(props?.mapResult[j].store.id === storesReport[i].store.id){
  //             storesReport[i]['location_types_name'] = props?.mapResult[j]['location_types_name'];
  //             storesReport[i]['address'] = props?.mapResult[j]['address'];
  //             reportData.push(storesReport[i]);
  //           }
  //         }
  //       })
  //       setExportReport(reportData);
  //     }
  //     setIsLoading(false);
  //   },[props.mapdata.storesReport,props?.mapResult,props.locationId]);

  const openMarketStoreDetail = (loc) => {
    //alert("openMarketStoreDetail")
    // setCurrentVendor((prevState)=>({
    //   ...prevState,
    //   [loc.store.id] : loc
    // }));
    storeDetailsData(loc?.store?.id);
    // setCurrentMarketObj(marketNames[loc.store.siteName]);
    // setSingleSection(true);
    setIsMarket(true);
    setAccordStoreId("");
    // setRemoveDetail(true);
    setIsLoading(true);
  };

  const openVendorStoreDetail = (loc) => {
    setAccordStoreId("");
    setIsMarket(false);
    // setCurrentVendor((prevState)=>({
    //   ...prevState,
    //   [loc.store.id] : loc
    // }));
    // setCurrentVendor(loc);
    storeDetailsData(loc?.store?.id);
    // setRemoveDetail(false);
    setIsLoading(true);
  };
  const statusColor = {
    background: "linear-gradient(90deg, #f32c2c 0%, #8d1a1a 100%)",

    width: "Fixed (165px)",
    height: "Hug (52px)",
    padding: "14px, 10px, 14px, 10px",
    "border-radius": "6px, 0px, 0px, 6px",
    gap: "10px",
    color: "white",
    cursor: "auto",
  };
  const ascendingOrder = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.displayName.toUpperCase();
    const bandB = b.displayName.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  const descendingOrder = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.displayName.toUpperCase();
    const bandB = b.displayName.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison * -1;
  };
  const sortResults = (sortBy) => {
    if (sortBy === "asc") {
      const data = originalArray.sort(ascendingOrder);
      setOriginalArray(data);
    } else {
      const data = originalArray.sort(descendingOrder);
      setOriginalArray(data);
    }
  };
  const arrowProduct = {
    "font-family": "Poppins",
    "font-size": "24px",
    "font-weight": "700",
    "line-height": "24px",
    "letter-spacing": "0em",
    "text-align": "left",
    color: "#44A0E3",
  };
  const getTodayStatus = (item) => {
    let displayWorkingClass = "btnLinkRed";
    let checkHoliday = "closedToday";
    if (item.openToday === "Y") {
      displayWorkingClass = "btnLink";
      checkHoliday = "openToday";
      if (
        new Date().getTime() >
          new Date(moment(item.todayStartTime, "hh:mm a")).getTime() &&
        new Date().getTime() <
          new Date(moment(item.todayEndTime, "hh:mm a")).getTime()
      ) {
        checkHoliday = "openNow";
      } else if (
        new Date().getTime() >
        new Date(moment(item.todayEndTime, "hh:mm a")).getTime()
      ) {
        checkHoliday = "closedNow";
        displayWorkingClass = "btnLinkRed";
      }
    }
    return (
      <>
        <Typography
          className={`${displayWorkingClass} mb-2 mt-2`}
          style={{
            display: "inline-block",
            padding: "10px 5px",
            position: "relative",
            top: "calc(calc(42% - 26px))",
          }}
        >
          <HouseIcon style={{ color: "white" }} />
          <small className="boldFont">{transProps[checkHoliday]}</small>
        </Typography>
      </>
    );
  };

  const getSpecialHoursRows = (row, i) => {
    let weeks = "",
      day = "",
      month = "",
      start_time = "",
      end_time = "";
    if (row) {
      let elementDay = row;
      // status = elementDay.specialStatusDescription ? elementDay.specialStatusDescription : "-";
      start_time = elementDay.startTime ? elementDay.startTime : "-";
      end_time = elementDay.endTime ? elementDay.endTime : "-";
      let dayInfo = elementDay.dayInfo ? elementDay.dayInfo.split(",") : "";
      let monthInfo = elementDay.monthInfo.split(",");

      let week = elementDay.weekInfo;

      for (let d = 0; d < dayInfo.length; d++) {
        let number = dayInfo[d];
        switch (number) {
          case "01":
            day += transProps.sunday + " ";
            break;
          case "02":
            day += transProps.monday + " ";
            break;
          case "03":
            day += transProps.tuesday + " ";
            break;
          case "04":
            day += transProps.wednesday + " ";
            break;
          case "05":
            day += transProps.thursday + " ";
            break;
          case "06":
            day += transProps.friday + " ";
            break;
          case "07":
            day += transProps.saturday + " ";
            break;
          default:
            day += "-";
        }
      }

      if (day && day !== "-") {
        day = day.match(/[^ ,]+/g).join(", ");
      }

      if (elementDay.monthInfo && monthInfo.length > 0) {
        let splMonth = monthNumber
          .filter((item) => {
            if (monthInfo.includes(item.value)) {
              console.log(transProps[item.name], "item.name");
              return transProps[item.name];
            }
          })
          .map((item) => transProps[item.name]);

        if (month !== "-") {
          month = splMonth.join(", ");
        }
      }

      let text = "";
      if (week === "1") {
        text = "st";
      } else if (week === "2") {
        text = "nd";
      } else if (week === "3") {
        text = "rd";
      } else if (week === "4" || week === "5") {
        text = "th";
      }
      weeks = week + text;
      if (weeks == "") {
        weeks = "-";
      }
      return (
        <tr
          key={i}
          style={{ borderBottom: "1px solid #ddd", paddingBottom: "1em" }}
        >
          <td>{weeks}</td>
          <td>{day}</td>
          <td>{month}</td>
          <td style={{ whiteSpace: "nowrap" }}>
            <div className="row">
              <div className="col-md-12 p-0" style={{ wordBreak: "break-all" }}>
                {start_time} - {end_time}
              </div>
              {/* <div
                      className="col-md-12 p-0"
                      style={{ wordBreak: "break-all" }}
                    >
                      {end_time}
                    </div> */}
            </div>
          </td>
        </tr>
      );
    }
  };

  const getAvailStore = (store) => {
    return store.filter((item) => item.specialStatusCode == "AVAIL");
  };

  const getNotAvailStore = (store) => {
    return store.filter((item) => item.specialStatusCode == "NAVAIL");
  };

  const getMoreDetails = (currentDetail) => {
    // console.log(currentDetail);
    const orderOptions = props.mapdata.orderOptions;
    const mnOpr = monthNumber
      .filter((item) => {
        if (currentDetail?.store?.storeOperationMonth?.[item.id] === "Y") {
          return item.name;
        }
      })
      .map((item) => item.name);

    const oderOperation = orderOptions
      .filter((item) => {
        if (currentDetail?.store?.storeOrderTypeDetail?.[item.id] === "Y") {
          return item.values;
        }
      })
      .map((item) => item.values);

    return (
      <>
        {/* className="card-details m-0 animatedDiv card-inner" */}
        <Grid container>
          <Grid container mb={2} ml={2} mr={2} xs={12} md={12}>
            <Grid
              container
              item
              xs={12}
              md={4}
              sx={{ display: "flex", alignItems: "flex-start" }}
              style={{ borderRight: "2px solid #d9d9d9" }}
            >
              {/* <Paper> */}

              <Grid container>
                {(currentDetail?.publicEmail ||
                  currentDetail?.publicMobile ||
                  currentDetail?.publicWebsite) && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={8}
                    lineHeight={"1.5"}
                    style={{
                      borderBottom: "2px solid #d9d9d9",
                      padding: "0.5em",
                    }}
                  >
                    <Box
                      className="p-none    
                        "
                      style={titlesListview}
                      aria-label="Vendor Description"
                    >
                      <icon className="cusIcon" style={{ fontSize: "24px" }}>
                        <ContactsIcon />
                      </icon>
                      &nbsp; Contact&apos;s Info
                    </Box>
                    {
                      <small style={{ fontSize: "16px" }}>
                        <a
                          aria-label="Navigate to location"
                          href={getMapURL(currentDetail)}
                          target="blank"
                          style={{
                            textDecoration: "underline",
                            color: "#6C6C6C",
                          }}
                        >
                          <i
                            className="fa fa-map  cusIcon"
                            style={{ fontSize: "14px" }}
                          ></i>
                          &nbsp; {transProps.navigateLocation}
                        </a>
                      </small>
                    }
                    {currentDetail?.publicEmail && (
                      <Typography
                        className="font-16 small"
                        component={"p"}
                        style={{ fontSize: "16px", color: "#7b7c7c" }}
                      >
                        <a
                          aria-label={`Email address ${currentDetail?.publicEmail}`}
                          href={"mailto:" + currentDetail?.publicEmail}
                          style={{
                            textDecoration: "underline",
                            color: "#7b7c7c",
                          }}
                        >
                          <i
                            className="fa fa-envelope  cusIcon"
                            style={{ fontSize: "14px" }}
                          ></i>{" "}
                          {currentDetail?.publicEmail}
                        </a>
                      </Typography>
                    )}

                    {currentDetail?.publicMobile && (
                      <Typography
                        className="font-16 small"
                        component={"p"}
                        style={{ fontSize: "16px", color: "#7b7c7c" }}
                      >
                        <a
                          aria-label={`Phone number ${currentDetail?.publicMobile}`}
                          href={"tel:" + currentDetail?.publicMobile}
                          style={{
                            textDecoration: "underline",
                            color: "#7b7c7c",
                          }}
                        >
                          <i className="fa fa-phone  cusIcon"></i>{" "}
                          {currentDetail?.publicMobile}
                        </a>
                      </Typography>
                    )}
                    {currentDetail?.publicWebsite && (
                      <Typography
                        className="font-16 small"
                        component={"p"}
                        style={{ fontSize: "16px", color: "#7b7c7c" }}
                      >
                        <a
                          aria-label={`Go to store website`}
                          href={getSiteUrl(currentDetail?.publicWebsite)}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            textDecoration: "underline",
                            color: "#7b7c7c",
                          }}
                        >
                          <i className="fa fa-globe cusIcon"></i>{" "}
                          {transProps.goStoreWebsite}
                        </a>
                      </Typography>
                    )}
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4}>
                  {currentDetail?.location_types_name == "farmersMarket" ||
                  currentDetail?.location_types_name == "farmersMarketManagers"
                    ? getTodayStatus(currentDetail)
                    : ""}
                </Grid>
                {currentDetail?.businessDesc && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={11}
                    mt={4}
                    style={{
                      // borderBottom: "2px solid #d9d9d9",
                      paddingBottom: "1em",
                    }}
                  >
                    <small className="d-md-none d-lg-none d-sm-block"></small>
                    <Box
                      className="p-none "
                      style={titlesListview}
                      aria-label="Vendor Description"
                    >
                      <i className="fa fa-home cusIcon"></i>&nbsp;
                      {transProps.vendorDescription}
                    </Box>
                    <Grid className="mt-1">
                      {" "}
                      <small aria-label={currentDetail?.businessDesc}>
                        {currentDetail?.businessDesc}
                      </small>{" "}
                    </Grid>
                  </Grid>
                )}
                {currentDetail?.store?.specialInstructions && (
                  <Grid item xs={12} sm={12} md={12} mt={4}>
                    <Box
                      className="p-none "
                      style={titlesListview}
                      aria-label="Special Instructions"
                    >
                      <i
                        className="fa fa-info-circle cusIcon"
                        style={{ fontSize: "21px" }}
                      ></i>
                      &nbsp;
                      {transProps.specialInstructions}
                    </Box>
                    <Grid className="mt-1">
                      <small
                        aria-label={currentDetail?.store?.specialInstructions}
                      >
                        {currentDetail?.store?.specialInstructions}
                      </small>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {/* </Paper> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              style={{ borderRight: "2px solid #d9d9d9", paddingLeft: "1em" }}
            >
              <Grid
                item
                mr={1}
                style={{
                  borderBottom: "2px solid #d9d9d9",
                  paddingBottom: "1em",
                }}
              >
                <Box
                  title={transProps.regularHourOperation}
                  className="soh-text  p-none "
                  style={titlesListview}
                  aria-label="Regular Hour(s) of
                        Operation"
                >
                  <i
                    className="fa fa-clock-o cusIcon"
                    style={{ fontSize: "24px" }}
                  ></i>
                  &nbsp;
                  {transProps.regularHourOperation}
                </Box>
                <Grid className="mt-1">
                  {currentDetail?.store?.storehours?.length &&
                    currentDetail?.store?.storehours.map((item, i) => (
                      <Grid container item key={i}>
                        <Grid item xs={6} md={6} sm={6}>
                          <small className="mt-2">
                            {console.log(
                              item.weekdayDescription,
                              "item.weekdayDescription"
                            )}
                            {transProps[item.weekdayDescription?.toLowerCase()]}
                          </small>
                        </Grid>
                        <Grid item xs={6} md={6} sm={6}>
                          <small
                            style={{
                              color: `${
                                item.isWorkingDay === "Y" ? "" : "red"
                              }`,
                            }}
                            aria-label={
                              item.isWorkingDay === "Y"
                                ? item.startTime + " - " + item.endTime
                                : transProps.closed
                            }
                          >
                            {item.isWorkingDay === "Y"
                              ? item.startTime + " - " + item.endTime
                              : transProps.closed}
                          </small>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              {currentDetail?.store?.storeOperationMonth && (
                <Grid item mt={1}>
                  <Box className="p-none " style={titlesListview}>
                    <i className="fa fa-calendar cusIcon"></i>&nbsp;
                    {transProps.monthOperation}
                  </Box>
                  <Typography component={"p"} className="mapResult">
                    <ul
                      className="row font-regular14"
                      style={{
                        listStyle: "none",
                        // padding: "0",
                        margin: "0 !important",
                      }}
                    >
                      {mnOpr.map((item, i) => (
                        <li
                          key={i}
                          style={{ background: "white" }}
                          className="col-md-4 monthBorder"
                        >
                          <small>{transProps[item]}</small>
                        </li>
                      ))}
                    </ul>
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              style={{
                paddingLeft: "1em",
                // borderBottom: "2px solid #d9d9d9",
                paddingBottom: "1em",
              }}
            >
              {/* {currentDetail?.store?.storeOrderTypeDetail && (
                <Grid
                  item
                  style={{
                    borderBottom: "2px solid #d9d9d9",
                    paddingBottom: "1em",
                  }}
                >
                  <Box className="p-none " style={titlesListview}>
                    <i
                      className="fa fa-check-square-o cusIcon"
                      style={{ fontSize: "21px" }}
                    ></i>
                    &nbsp;
                    {transProps.orderOption}
                  </Box>

                  <ul
                    className="row mt-2 mapResult"
                    style={{
                      listStyle: "none",
                      //  padding: '0 20px 0 43px',
                      margin: "0 !important",
                    }}
                  >
                    {oderOperation.sort().map((item, i) => (
                      <li
                        key={i}
                        style={{ background: "white" }}
                        className="col-md-12 col-12 monthBorder"
                      >
                        <small>{transProps[item]}</small>
                      </li>
                    ))}
                  </ul>
                </Grid>
              )} */}
              {currentDetail?.products && (
                <Grid
                  item
                  style={{
                    borderBottom: "2px solid #d9d9d9",
                    paddingBottom: "1em",
                  }}
                >
                  <Box className="p-none ">
                    <img
                      className="harvest-results"
                      src={harvest}
                      aria-label="harvest"
                      aria-labelledby="harvest"
                      alt="img"
                      role="none"
                    />
                    &nbsp;{transProps.products}
                  </Box>
                  <small>{currentDetail?.products} </small>
                </Grid>
              )}

              {currentDetail?.publicPhoto &&
                isBase64String(currentDetail?.publicPhoto) && (
                  <Grid item>
                    <Box className="p-none " style={titlesListview}>
                      <i className="fa fa-image cusIcon"></i>&nbsp;
                      {transProps.vendorPhotos}
                    </Box>
                    <Grid mt={1} className="text-center">
                      <Grid mt={1}>
                        {" "}
                        <img
                          src={currentDetail?.publicPhoto}
                          className="img-thumbnail"
                          aria-label="harvestGreen"
                          aria-labelledby="harvestGreen"
                          alt="img"
                          role="none"
                          style={{
                            marginBottom: "5px",
                            marginTop: "5px",
                            width: "250px",
                          }}
                        />{" "}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Grid>
          {currentDetail?.store?.specialOperationFlag === "Y" && (
            <Grid xs={12} md={12} sx={{ borderTop: "1px solid #006E52" }}>
              <Grid m={2}>
                <Box className="p-none " style={titlesListview}>
                  {transProps.specialHourOperation}
                </Box>
                <Grid item xs={12}>
                  <Grid item md={12} className="p-0">
                    <Grid
                      className="special-timings"
                      id="specialTiming_1ce781cf-dc40-15c9-5ae8-90246d330745"
                    >
                      {currentDetail?.store?.storeSpecialOperation &&
                        currentDetail?.store?.storeSpecialOperation.length && (
                          <>
                            {getAvailStore(
                              currentDetail?.store?.storeSpecialOperation
                            )?.length ? (
                              <div className="overflow-auto">
                                <Box
                                  className="boldFont boldFont mb-0 mt-1"
                                  style={{ color: "#006e52" }}
                                >
                                  {transProps.available}
                                </Box>
                                <table
                                  className="table border special-timings cardShadow"
                                  style={{
                                    border: "none !important",
                                    background: "#fff",
                                    marginBottom: "10px !important",
                                  }}
                                >
                                  <thead
                                    style={{
                                      position: "relative",
                                      boxShadow:
                                        "0px 1px 3px 1px rgba(0, 0, 0, 0.3)",
                                    }}
                                  >
                                    <tr>
                                      <th
                                        style={{ width: "6%" }}
                                        className="specialTime-table"
                                      >
                                        {transProps.week}
                                      </th>
                                      <th
                                        style={{ width: "40%" }}
                                        className="specialTime-table"
                                      >
                                        {transProps.day}
                                      </th>
                                      <th
                                        style={{ width: "40%" }}
                                        className="specialTime-table"
                                      >
                                        {transProps.month}
                                      </th>
                                      <th
                                        style={{ width: "14%" }}
                                        className="drop-down-menu specialTime-table"
                                      >
                                        {transProps.time}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    id="special_not_1ce781cf-dc40-15c9-5ae8-90246d330745"
                                    style={{
                                      padding: "15px 10px",
                                      borderTop: 0,
                                    }}
                                  >
                                    {getAvailStore(
                                      currentDetail?.store
                                        ?.storeSpecialOperation
                                    )?.map((item, i) =>
                                      getSpecialHoursRows(item, i)
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              ""
                            )}
                            {getNotAvailStore(
                              currentDetail?.store?.storeSpecialOperation
                            )?.length ? (
                              <div className="overflow-auto">
                                <Box
                                  className="boldFont boldFont mb-0 mt-1"
                                  style={{ color: "#ca0000" }}
                                >
                                  {transProps.notAvailable}
                                </Box>
                                <table
                                  className="table border special-timings cardShadow"
                                  style={{
                                    border: "none !important",
                                    background: "#fff",
                                    marginBottom: "10px !important",
                                  }}
                                >
                                  <thead
                                    style={{
                                      position: "relative",
                                      boxShadow:
                                        "0px 1px 3px 1px rgba(0, 0, 0, 0.3)",
                                    }}
                                  >
                                    <tr>
                                      <th
                                        style={{ width: "6%" }}
                                        className="specialTime-table"
                                      >
                                        {transProps.week}
                                      </th>
                                      <th
                                        style={{ width: "40%" }}
                                        className="specialTime-table"
                                      >
                                        {transProps.day}
                                      </th>
                                      <th
                                        style={{ width: "40%" }}
                                        className="specialTime-table"
                                      >
                                        {transProps.month}
                                      </th>
                                      <th
                                        style={{ width: "14%" }}
                                        className="drop-down-menu specialTime-table"
                                      >
                                        {transProps.time}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    id="special_not_1ce781cf-dc40-15c9-5ae8-90246d330745"
                                    style={{
                                      padding: "15px 10px",
                                      borderTop: 0,
                                      marginTop: "2px",
                                    }}
                                  >
                                    {getNotAvailStore(
                                      currentDetail?.store
                                        ?.storeSpecialOperation
                                    )?.map((item, i) =>
                                      getSpecialHoursRows(item, i)
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    );
  };
  return (
    // <Paper sx={{ width: '100%', margin: '13px 0' }} >
    <Box sx={{ width: "100%", overflowY: "auto", height: "662px" }}>
      {isLoading && <CommonLoading />}
      <Grid
        item
        md={12}
        className="text-right resultLabel"
        style={{ position: "absolute", top: "-30px", left: "90%" }}
      >
        <span style={{ marginRight: "10px", fontSize: "15px" }}>
          <button
            className="resultSection-reorder"
            aria-label="sort descending"
            onClick={() => {
              sortResults("desc");
              setSortBy("desc");
            }}
          >
            <i
              id="upArrow"
              className={`fa fa-long-arrow-up ${
                sortBy === "desc" ? "sortColorGreen" : "sortColor"
              }`}
            ></i>
          </button>
          <button
            className="resultSection-reorder"
            aria-label="sort ascending"
            onClick={() => {
              sortResults("asc");
              setSortBy("asc");
            }}
          >
            <i
              id="downArrow"
              className={`fa fa-long-arrow-down ${
                sortBy === "asc" ? "sortColorGreen" : "sortColor"
              }`}
              style={{ color: "#2a6e52" }}
            ></i>
          </button>
          {/* <a href="javascript:void(0)" aria-label="sort descending" onClick={() => {
                        sortResults("desc");
                        setSortBy("desc");
                    }}>
                    <i
                    id="upArrow"
                    className={`fa fa-long-arrow-up ${sortBy === "desc" ? "sortColorGreen" : "sortColor"
                        }`}
                    
                    
                    ></i>
                    </a>
                    &nbsp;
                  
                    <a href="javascript:void(0)" aria-label="sort ascending"  onClick={() => {
                        sortResults("asc");
                        setSortBy("asc");
                    }}>
                    <i
                    id="downArrow"
                    className={`fa fa-long-arrow-down ${sortBy === "asc" ? "sortColorGreen" : "sortColor"
                        }`}
                    style={{ color: "#2a6e52" }}
                    
                    ></i>
                    </a> */}
        </span>

        <button
          className="cursor resultSection-reorder"
          onClick={() => {
            props.getStoresReport(props.mapdata.mapSearchPayload);
          }}
        >
          <img src={xls_export} style={{ width: "15px" }} />
          <small className="boldFont">
            <u>{transProps.export}</u>
          </small>
        </button>
      </Grid>
      {originalArray?.length > 0 &&
        originalArray?.map((mapResultData) => (
          <Card
            elevation={3}
            sx={{ marginTop: "5px", padding: "10px", fontFamily: "Texta-book" }}
            key={mapResultData.store.id}
            id={mapResultData.store.id}
          >
            <Box
              style={{
                border: "2px solid grey",
                borderRadius: "5px",
              }}
            >
              <Grid
                container
                spacing={2}
                p={1}
                xs={12}
                // justifyContent="center"
                sx={{ justifyContent: "space-between" }}
                // style={{
                //   padding: 0,
                //   display: "flex",
                //   justifyContent: "space-between",
                // }}
              >
                <Grid
                  className="card-inner"
                  item
                  xs={6}
                  // md={mapResultData.isHipAndCsaVdr ? 6 : 8}
                  style={{ lineHeight: "1.3" }}
                >
                  <h3
                    className="text-color p-none haederlistView"
                    aria-label={
                      (mapResultData.location_types_name === "farmersMarket" ||
                        mapResultData.location_types_name ===
                          "farmersMarketManagers") &&
                      mapResultData.store.siteName
                        ? mapResultData.store.siteName.replace(/&#38;/g, "’")
                        : mapResultData.vendorName.replace(/&#38;/g, "’")
                    }
                  >
                    {/* {mapResultData.vendorName} */}
                    {(mapResultData.location_types_name === "farmersMarket" ||
                      mapResultData.location_types_name ===
                        "farmersMarketManagers") &&
                    mapResultData.store.siteName
                      ? mapResultData.store.siteName.replace(/&#38;/g, "’")
                      : mapResultData.vendorName.replace(/&#38;/g, "’")}
                  </h3>
                  {getGroupFarmerMarket(mapResultData)}
                  <Grid item md={12}>
                    <small className="boldFont">
                      {" "}
                      {mapResultData.store.storeType}
                    </small>
                  </Grid>
                  <Grid item md={12}>
                    <small>
                      <i className="fa fa-location-arrow cusIcon"></i>{" "}
                      {mapResultData.miles + " " + transProps.milesAway}
                    </small>
                  </Grid>
                  <Grid item md={12}>
                    <small>
                      <i className="fa fa-map-pin cusIcon"></i>{" "}
                      {mapResultData.store.siteName}
                    </small>
                    {/* {!(
                      mapResultData.location_types_name === "farmersMarket" ||
                      mapResultData.location_types_name ===
                        "farmersMarketManagers"
                    ) &&
                    mapResultData.store.siteName &&
                    mapResultData.store.siteName &&
                    mapResultData.store.siteName !== "" ? (
                      <>
                        <small
                          aria-label={mapResultData?.store?.siteName?.toUpperCase()}
                        >
                          <i className="fa fa-map-pin cusIcon"></i>{" "}
                          {mapResultData?.store?.siteName}
                        </small>
                        <br />
                      </>
                    ) : (
                      ""
                    )} */}
                  </Grid>
                  <Grid item md={12}>
                    <small>
                      <i className="fa fa-map-signs cusIcon"></i>&nbsp;
                      {mapResultData.address}
                    </small>
                  </Grid>
                  {(mapResultData?.publicMobile ||
                    mapResultData?.publicEmail) &&
                  !mapResultData.store.siteName ? (
                    <>
                      <Grid item md={12}>
                        <small>
                          <i className="fa fa-phone cusIcon"></i>&nbsp;
                          <u>{mapResultData.publicMobile}</u>
                        </small>
                      </Grid>
                      <Grid item md={12}>
                        <small>
                          <i className="fa fa-envelope cusIcon"></i>&nbsp;
                          {mapResultData.publicEmail}
                        </small>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item container xs={6} direction="column">
                  <Stack
                    direction="row"
                    style={{ alignSelf: "flex-end", gap: "10px" }}
                  >
                    <Grid
                      style={{
                        display: mapResultData.isHipAndCsaVdr ? "auto" : "none",
                      }}
                      item
                      justifyContent={"flex-end"}
                      textAlign={"right"}
                    >
                      <button
                        aria-label="button"
                        className="btn btn-primary btn-lg mt-2 btn-font reSizeFont"
                        style={csaProperty}
                      >
                        <Link
                          style={{ color: "#fff", textDecoration: "none" }}
                          onClick={() => {
                            window.open(
                              `${window.location.origin}/kfresh/csa-signup?vendorId=${mapResultData.vendorId}&appId=${appId}`
                            );
                          }}
                        >
                          <i
                            title="CSA Signup Link"
                            className="fa fa-handshake-o"
                            aria-hidden="true"
                          ></i>{" "}
                          {transProps.SPFSignupLink}
                        </Link>
                      </button>
                    </Grid>
                    <Grid item justifyContent={"flex-end"} textAlign={"center"}>
                      {mapResultData.location_types_name === "farmersMarket" ||
                      mapResultData.location_types_name ===
                        "farmersMarketManagers"
                        ? ""
                        : getTodayStatus(mapResultData)}
                    </Grid>
                  </Stack>
                  <Grid
                    container
                    item
                    justifyContent={"flex-end"}
                    style={{ padding: "0" }}
                  >
                    <button
                      style={{
                        background: "#fff",
                        border: "none",
                        paddingTop: "10px",
                        marginRight: "-20px",
                      }}
                      tabIndex={0}
                      aria-label={`${
                        mapResultData.location_types_name === "farmersMarket" ||
                        mapResultData.location_types_name ===
                          "farmersMarketManagers"
                          ? mapResultData.store.siteName
                          : mapResultData.vendorName
                      }
                        ${
                          singleSection[mapResultData.store.id]
                            ? "hide details is expanded"
                            : "view details is collapsed"
                        } `}
                      // className={`right-arrow ${!singleSection[mapResultData?.store?.id] ? 'up-arrow' : 'down-arrow'}`}
                      onClick={() => {
                        setCurrentVendor(mapResultData);
                        setSingleSection((prevState) => ({
                          ...prevState,
                          [mapResultData.store.id]:
                            !singleSection[mapResultData?.store?.id],
                        }));

                        if (!singleSection[mapResultData?.store?.id]) {
                          mapResultData.location_types_name ===
                            "farmersMarket" ||
                          mapResultData.location_types_name ===
                            "farmersMarketManagers"
                            ? openMarketStoreDetail(mapResultData)
                            : openVendorStoreDetail(mapResultData);
                        }
                      }}
                    >
                      {singleSection[mapResultData.store.id] ? (
                        <Box style={{ fontSize: "30px" }}>
                          <span style={arrowProduct}>
                            {transProps.viewDetails}
                          </span>{" "}
                          <KeyboardArrowUpIcon
                            style={{
                              color: "#44A0E3",
                              fontSize: "35px",
                              fontWeight: "bold",
                            }}
                          />
                        </Box>
                      ) : (
                        <Box style={{ fontSize: "30px" }}>
                          <span style={arrowProduct}>
                            {transProps.viewDetails}
                          </span>{" "}
                          <KeyboardArrowDownIcon
                            style={{
                              color: "#44A0E3",
                              fontSize: "35px",
                              fontWeight: "bold",
                            }}
                          />
                        </Box>
                      )}

                      {/* <i className="n_nav-right-icon fa fa-chevron-down"></i> */}
                    </button>
                  </Grid>
                </Grid>
                <Box />
              </Grid>
            </Box>
            {/* Details */}
            {/* { (singleSection && storeDetail[mapResultData.store.id]) && ( */}
            {singleSection?.[mapResultData?.store?.id] &&
              currentVendorStoreDetail &&
              currentVendorStoreDetail?.[mapResultData.store.id]?.length && (
                <Grid
                  container
                  className="card-details m-0 animatedDiv card-inner"
                >
                  {/* <Grid item xs={12} md={4} pl={2}>
                    <small>
                      <a
                        aria-label="Navigate to location"
                        href={getMapURL(
                          currentVendorStoreDetail?.[mapResultData.store.id][0]
                        )}
                        target="blank"
                        style={{ textDecoration: "underline" }}
                      >
                        <i className="fa fa-map text-muted cusIcon"></i>&nbsp;{" "}
                        Navigate to location
                      </a>
                    </small>
                  </Grid> */}
                  {getMoreDetails(
                    currentVendorStoreDetail?.[mapResultData.store.id][0]
                  )}
                </Grid>
              )}
            {singleSection?.[mapResultData?.store?.id] &&
              currentMarketVendorStoreDetail &&
              currentMarketVendorStoreDetail?.[mapResultData.store.id]
                ?.length !== 0 &&
              currentMarketVendorStoreDetail?.[mapResultData?.store?.id] && (
                //   <Card  sx={{ marginTop: '5px', padding: '10px' }}>
                <Grid
                  container
                  className="card-details m-0 animatedDiv card-inner"
                >
                  <Grid item xs={12} md={12} pl={2}>
                    <small>
                      <i
                        className="fa fa-map  cusIcon"
                        style={{ fontSize: "14px" }}
                      ></i>
                      &nbsp;{" "}
                      <a
                        href="#"
                        target="blank"
                        style={{
                          textDecoration: "underline",
                          color: "#6C6C6C",
                        }}
                      >
                        {transProps.navigateLocation}
                      </a>
                    </small>
                  </Grid>
                  {currentMarketVendorStoreDetail?.[
                    mapResultData?.store?.id
                  ]?.map((farm, index) => (
                    <React.Fragment key={index}>
                      <Grid
                        container
                        key={index}
                        sx={{
                          borderTop: "1px solid #006E52",
                          borderBottom: "1px solid #006E52",
                          paddingBottom: "1em",
                          margin: "10px 0 10px 0",
                          background: "#EAF2ED",
                        }}
                      >
                        <Grid item xs={10} pl={2}>
                          <h3 className="text-color p-none">
                            {removeSpecialCharacter(farm.vendorName)}
                          </h3>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <button
                            aria-label={` ${
                              openCollapse?.[farm?.store?.id + index + 1] ===
                              farm?.store?.id + index + 1
                                ? "To Hide details of"
                                : "To View details of "
                            } ${farm.vendorName}`}
                            style={{ display: "flex", alignItems: "center" }}
                            className="accordin-btn"
                            onClick={() =>
                              handleCollapseToggle(farm.store.id, index)
                            }
                          >
                            <i
                              className={` text-color fa  ${
                                openCollapse?.[farm?.store?.id + index + 1] ===
                                farm?.store?.id + index + 1
                                  ? "fa-minus"
                                  : "fa-plus"
                              }`}
                              aria-hidden="true"
                              style={{ fontSize: "20px" }}
                            ></i>
                          </button>
                        </Grid>
                      </Grid>
                      <Collapse
                        in={
                          openCollapse?.[farm?.store?.id + index + 1] ===
                          farm?.store?.id + index + 1
                        }
                        style={{
                          width: "100%",
                          background: "#F8F9FA",
                          marginTop: ".5em",
                          position: "relative",
                          borderTop: "1px solid #006E52",
                        }}
                      >
                        <Grid container key={index}>
                          {
                            getMoreDetails(farm)
                            // getTodayStatus(farm)
                          }
                        </Grid>
                      </Collapse>
                    </React.Fragment>
                  ))}
                </Grid>
                //   </Card>
              )}
            <span className="sr-only">{transProps.sectionEnd}</span>
          </Card>
        ))}
    </Box>
    // </Paper>
  );
};

ListViewResult.propTypes = {
  mapResult: PropTypes.array,
  mapdata: PropTypes.object,
  storesReport: PropTypes.array,
  filterClusterer: PropTypes.array,
  getStoreDetails: PropTypes.func,
  getSpecialHourData: PropTypes.func,
  initialStoreView: PropTypes.bool,
  locationId: PropTypes.func,
  getStoresReport: PropTypes.func.isRequired,
  coordinates: PropTypes.object,
  language: PropTypes.object,
  mapRefresh: PropTypes.bool,
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStoreDetails: (payload) =>
      dispatch({ type: GET_STORE_DETAILS, payload: payload }),
    getSpecialHourData: (id) => dispatch({ type: GET_SPECIAL_HOUR, value: id }),

    getStoresReport: (payload) =>
      dispatch({ type: GET_STORE_REPORT, payload: payload }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ListViewResult);
