import React, { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/images/Vector.svg";
import LoginComponent from "../../user/login/login";
import AdminLoginComponent from "../../user/login/admin-login";
import ForgotPwdComponent from "../../user/forgotpassword/forgotpassword";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HelpComponent from "../../user/help";
import useTranslator from "../../../hooks/useTranslator";

const Header = (props) => {
  const transProps = useTranslator(["kfresh"], "header");
  const [modalShown, toggleModal] = useState(false);
  const [modalShownAdmin, toggleModalAdmin] = useState(false);
  const [forgotShown, toggleForgotModal] = useState(false);
  const [helpModalShow, setHelpModalShow] = useState(false);

  const openForgotPwd = () => {
    toggleForgotModal(true);
  };

  return (
    <>
      {forgotShown ? (
        <ForgotPwdComponent
          shown={forgotShown}
          close={() => {
            toggleForgotModal(false);
          }}
        />
      ) : (
        ""
      )}
      {helpModalShow ? (
        <HelpComponent
          shown={helpModalShow}
          close={() => {
            setHelpModalShow(false);
          }}
        />
      ) : (
        ""
      )}
      <div className="header">
        {modalShown && (
          <LoginComponent
            shown={modalShown}
            close={(forgotpwd = "") => {
              toggleModal(false);
              if (forgotpwd) {
                openForgotPwd();
              }
            }}
          />
        )}
        {modalShownAdmin && (
          <AdminLoginComponent
            shown={modalShownAdmin}
            close={(forgotpwd = "") => {
              toggleModalAdmin(false);
              if (forgotpwd) {
                openForgotPwd();
              }
            }}
          />
        )}

        <nav className="navbar navbar-expand-lg navbar-light">
          <NavLink to="/" reloadDocument="true">
            <img
              src={logo}
              alt="DTA Finder Logo"
              className="header-logo-responsive mr-0"
            />

            <span className="boldFont page-title header-title">
              {transProps.kfresh}
            </span>
          </NavLink>
        </nav>
        {/* )} */}
      </div>
    </>
  );
};

Header.propTypes = {
  vendorDetails: PropTypes.object,
  mapdata: PropTypes.object,
  userAuth: PropTypes.object,
  authUserDetails: PropTypes.func,
};

export default connect(mapStateToProps)(Header);
