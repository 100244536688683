import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { styled } from '@mui/system';
import FormControl from '@mui/material/FormControl';
import PropTypes from "prop-types";

const dropDownlistCss = {
  border: "1px solid #44A0E3",
  borderRadius: "0.25rem",
  background: "#fff",
  width: "100%",
  ".MuiAutocomplete-input": {
    height: "12px",
    outline: "none",
    padding: "6px 8px !important",
  },
  ".MuiAutocomplete-input:hover": { outline: "none" },
  display: "block",
  marginTop: "5px",
};

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "#eee",
    cursor: "not-allowed",
    color: "#141414",
  },
  ".MuiInputLabel-root.MuiInputLabel-formControl": {
    visibility: "hidden",
  },
  ".MuiOutlinedInput-root": {
    padding: "7px",
  },
});

const SelectDropdown = (props) => {
  const [selectedValue, setSelectedValue] = useState(props.defaultValue);

  useEffect(() => {
    setSelectedValue(props.defaultValue);
  }, [props.defaultValue]);

  const dataList = props.dataList;
  const label = props.label;
  return (
    <>
      <FormControl sx={dropDownlistCss} className="label-hidden">
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) =>
            option ? option.label || option.name : "-- Select --"
          }
          disabled={props?.disabled ? true : false}
          disablePortal
          disableClearable
          // openOnFocus
          clearOnBlur
          value={selectedValue}
          options={dataList}
          renderInput={(params) => {
            const newParams = {
              ...params,
              inputProps: {
                ...params.inputProps,
                "aria-label": label,
                style: {
                  fontSize: "16px",
                  color: "#141414",
                  fontFamily: "Texta-book",
                },
              },
            };
            return <CustomTextField {...newParams} label={label} />;
          }}
          onChange={(event, newValue) => {
            setSelectedValue(newValue);

            props.onChange(newValue);
          }}
          renderOption={(props, option, state) => (
            <li
              {...props}
              key={option?.id}
              style={{
                fontSize: "16px",
                background: state.selected ? "#457425" : "",
                color: state.selected ? "#fff" : "#212529",
                fontFamily: "Texta-book",
              }}
            >
              {option.label || option.name}
            </li>
          )}
        />
      </FormControl>
    </>
  );
};

SelectDropdown.propTypes = {
  defaultValue: PropTypes.any,
  dataList: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default SelectDropdown;
