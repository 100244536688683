import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../../redux/reducers";
import getBase64 from '../../../utils/getbase64';
import { UPDATE_PUBLIC } from "../../../redux/actionTypes/vendordetails";
import { PUT_USER } from "../../../redux/actionTypes/registration";
import { isNumber } from "../../../utils/globalMethods";
import { getVendorInfoList } from "../../../redux/actionTypes/vendordetails";
import { appHomeUrl } from "../../../config";
import CommonLoading from "../../common/components/commonLoading";


const StyledImageUpload = styled.div` 
height: inherit;
width: inherit;
`;
 
const StyledImage = styled.img`
height: 185px;
object-fit: contain;
width: 200px;
`;

const CsaPublicInfoTab = (props) => {

    let formInitState = {
        publicEmail: "",
        publicMobile: "",
        publicWebsite: "",
        products: "",
        businessDesc: "",
        publicPhoto: "",
        vendorName:"",
        friendlyURL:""
    };

    const [publicDetails, setPublicDetails] = useState(formInitState);

    const { register, handleSubmit, control, formState: { errors }, reset } = useForm({ defaultValues: publicDetails });
    const userData = props?.userAuth?.user ? props?.userAuth?.user : JSON.parse(sessionStorage.getItem("userData"));
    const [mode, setMode] = useState("view");
    const [selectedImage, setSelectedImage] = useState({ file: null, base64URL: "" });
    const [update, setUpdate] = useState(false);
    const [publicMobile, setPublicMobile] = useState();
    const [previewImage, setPreviewImage] = useState(null);
    // eslint-disable-next-line no-unused-vars 
    const [isValidImage, setIsValidImage] = useState(true);
    const [loading, setLoading] = useState(props.isLoading);


    useEffect(() => {
        if (props.vendorInfo) {
            const formData = props.vendorInfo;
            if (typeof formData.fnsNum === "string") {
                const format = formData.fnsNum.split(",").map((item, i) => ({ id: i, name: item }));
                formData.fnsNum = format;
            }
            setTimeout(() => {
                if (formData?.publicMobile) {
                    setPublicMobile(formattedNumber(formData?.publicMobile));
                }
                reset(formData);
            }, 1000);
            formData.lastUpdtUid = props.vendorInfo.lastUpdtUidNam;
            formData.friendlyURL = props.vendorInfo?.friendlyURL ? props.vendorInfo?.friendlyURL : props.vendorInfo?.vendorName;
            setPublicDetails(formData);
            setPreviewImage(formData.publicPhoto)

        }
    }, [props.vendorInfo]);

    const onFormSubmit = (data) => {
        if (data.fnsNum && data.fnsNum.length > 0) {
            data.fnsNum = data.fnsNum.map(item => item.name).toString();
        }
        if (data.communicationEmail && data.communicationEmail.length > 0) {
            // data.communicationEmail = data.communicationEmail.map(item => item.name).toString();
            const commEmails = [...new Set(data.communicationEmail.map(item => item.name))];
            data.communicationEmail = commEmails.toString();
        }
        data.publicPhoto = selectedImage.base64URL ? selectedImage.base64URL : "";
        const timeStamp = new Date().getTime();
        const systemInfo = sessionStorage.getItem("systemInfo");
        const systemData = JSON.parse(systemInfo);
        let role = "";
        if (userData?.Role === "ADMIN") {
            role = "ADM_";
        } else if (userData?.Role === "HIPCRD") {
            role = "CRD_";
        } else {
            role = "VDR_";
        }
        let updatedInfo = {
            firstName: data.firstName,
            lastName: data.lastName,
            fnsNum: data.fnsNum ? data.fnsNum : "",
            communicationEmail: data.communicationEmail ? data.communicationEmail : "",
            csaFnsNum: data.fnsNum ? data.fnsNum : "",
            csaCommunicationEmail: data.communicationEmail ? data.communicationEmail : "",
            vendorName: data.vendorName,
            primaryEmail: data.primaryEmail,
            primaryMobile: data?.primaryMobile,
            publicEmail: data.publicEmail,
            publicMobile: data.publicMobile ? data.publicMobile : "",
            publicWebsite: data.publicWebsite,
            csaText: data.csaText,
            publicPhoto: previewImage,
            csaPickupSite: data.csaPickupSite ? data.csaPickupSite : "N",
            id: data.vendorId,
            csaSnapFlag: data.csaSnapFlag,
            additionalContactInfo: data.additionalContactInfo,
            products: data.products,
            businessDesc: data.businessDesc,
            lastUpdateTime: data.lastUpdateTime,
            userId: props.userId,
            createTimeStamp: timeStamp,
            lastUpdtTimeStamp: timeStamp,
            submitForm: "Public",
            vendorType: "CSAVDR",
            lastUpdtUidNam: role + userData?.id,
            ipAddress: systemData.ip,
            loginId: userData?.id,
            friendlyURL:data.friendlyURL 
        }
        if (update) {
            props.updateUser(updatedInfo);
            setPublicDetails(updatedInfo);
            setUpdate(false);
            setMode("view");
        }
    };
    
    const handleRemoveImage = () => {
        setSelectedImage({ file: null, base64URL: "" });
        setPreviewImage("");
        setIsValidImage(true);
        document.getElementById("vendorPhotoFile").value = "";
    };

    useEffect(() =>{
        setLoading(false)
    },[props.user]);

    const handleFileInputChange = useCallback((e) => {
        setLoading(true);
        let file = e.target.files[0];
        getBase64(file)
            .then((result) => {
                file["base64"] = result;
                setPreviewImage(result);
                setSelectedImage({
                    base64URL: result ? result : "",
                    file
                });
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);

            });
    }, []);

    const formattedNumber = (value) =>{
        const formattedValue = value.replace(
            /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
            '($1) $2-$3'
          );
          return formattedValue;

    }
    const csaPhoneNumber  = (e) => {
        const getValue = e.target.value;
        const formattedValue = formattedNumber(getValue);
        setPublicMobile(formattedValue);
      };

return (
  <div style={{ padding: "20px 10px", border: "1px solid #ccc" }}>
    {loading && <CommonLoading />}
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Row>
        <Col md={6} xs={12} sm={6} xl={6} lg={6}>
          <h3 className="resText">SPF PUBLIC INFO</h3>
        </Col>

        {mode === "view" ? (
          <Col className="txt-right">
            <div onClick={() => setMode("edit")} className="btn btn-primary">
              <span>
                <i className="fa fa-pencil"></i> Edit
              </span>
            </div>
          </Col>
        ) : (
          <Col md={6} xs={12} sm={6} xl={6} lg={6} className="txt-right">
            <Button
              type="submit"
              onClick={() => setUpdate(true)}
              className="btn btn-primary m-r-5"
            >
              <span>
                <i className="fa fa-check"></i> Update
              </span>
            </Button>
            <Button
              onClick={() => {
                setMode("view");
                if (props?.vendorInfo?.publicMobile) {
                  setPublicMobile(
                    formattedNumber(props?.vendorInfo?.publicMobile)
                  );
                  setPreviewImage(props.vendorInfo.publicPhoto);
                }
                reset(props.vendorInfo);
              }}
              className="btn btn-primary"
            >
              <span>
                <i className="fa fa-times"></i> Cancel
              </span>
            </Button>
          </Col>
        )}
      </Row>
      <br />
      <Row>
        {(userData.Role === "ADMIN" ||
          userData.Role === "HIPCRD" ||
          userData.Role === "CSAVDR") && (
          <Row>
            <Col
              style={{
                height: "40px",
                width: "100px",
                backgroundColor: "var(--bs-secondary-bg)",
                color: "#006e52",
                fontWeight: "bold",
                marginLeft: "10px",
                display: "flex",
              }}
            >
              {appHomeUrl}/dtaservices/csa-signup/vendor/
              {props.vendorInfo?.vendorId}/
              <input
                {...register("friendlyURL", {
                  required:
                    userData.Role === "ADMIN"
                      ? "Friendly URL name is required"
                      : false,
                })}
                defaultValue={props.vendorInfo?.vendorName}
                className={`required dh-input-box ${
                  errors.friendlyURL ? "dh-error-box" : ""
                }`}
                disabled={
                  mode === "view"
                    ? true
                    : userData.Role === "ADMIN"
                    ? false
                    : true
                }
              />
              {errors.friendlyURL && (
                <span className="dh-txt-danger">
                  &nbsp;&nbsp;
                  {errors.friendlyURL.message}
                </span>
              )}
            </Col>
          </Row>
        )}
        <Col md={4} sm={4} xl={4} lg={4}>
          <Form.Group controlId="publicEmail">
            <Form.Label className="font-14 mb-2 dh-title-lb smallTitle text-brand">
              Public SPF Email *
            </Form.Label>
            <Controller
              render={({ field }) => (
                <Form.Control
                  className={`form-control form-control-lg public-email-input dh-input-box required  ${
                    errors.publicEmail ? "dh-error-box" : ""
                  }`}
                  disabled={mode === "view"}
                  type="text"
                  {...field}
                />
              )}
              name="publicEmail"
              control={control}
              disabled={mode === "view"}
              {...register("publicEmail")}
              rules={{
                required: "Public SPF Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
              className={`form-control form-control-lg dh-input-box required lowercase ${
                errors.publicEmail ? "dh-error-box" : ""
              }`}
            />
            {errors.publicEmail && (
              <span className="dh-txt-danger">
                {errors.publicEmail.message}
              </span>
            )}
          </Form.Group>
        </Col>
        <Col md={4} sm={4} xl={4} lg={4}>
          <div className="form-group mb-0">
            <label
              className="font-14 mb-2 dh-title-lb smallTitle text-brand"
              htmlFor="PublicMobile"
            >
              Public SPF Phone *
            </label>
            <input
              {...register("publicMobile", {
                required: "Public SPF Phone Number is required",
                pattern: {
                  value: /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                  message: "Invalid phone number",
                },
              })}
              type="tel"
              onKeyDown={isNumber}
              maxLength={"14"}
              className={`form-control form-control-lg required dh-input-box ${
                errors.publicMobile ? "dh-error-box" : ""
              }`}
              value={publicMobile}
              onChange={csaPhoneNumber}
              placeholder="(___) ___-____"
              id="PublicMobile"
              aria-label="PublicMobile"
              aria-labelledby="PublicMobile"
              aria-describedby="PublicMobile"
              required=""
              disabled={mode === "view"}
            />
            {errors.publicMobile && (
              <span className="dh-txt-danger">
                {errors.publicMobile.message}
              </span>
            )}
          </div>
        </Col>
        <Col md={4} sm={4} xl={4} lg={4}>
          <Form.Group controlId="publicWebsite">
            <Form.Label className="font-14 mb-2 dh-title-lb smallTitle text-brand">
              SPF website
            </Form.Label>
            <Controller
              render={({ field }) => (
                <Form.Control
                  className="form-height form-control-lg"
                  disabled={mode === "view"}
                  type="text"
                  {...field}
                />
              )}
              {...register("publicWebsite", {
                pattern: {
                  value:
                    /^((ftp|http|https):\/\/)?(www\.)?([A-z]+)\.([A-z]{2,})/,
                  message: "Invalid Website",
                },
              })}
              name="publicWebsite"
              control={control}
              disabled={mode === "view"}
            />
            {errors.publicWebsite && (
              <span className="dh-txt-danger">
                {errors.publicWebsite.message}
              </span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={8} sm={8} xl={8} lg={8}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="font-14 mb-2 dh-title-lb smallTitle text-brand">
                  Notes about our SPF
                </Form.Label>
                <Controller
                  render={({ field }) => (
                    <Form.Control
                      className="form-control-lg"
                      id="products"
                      aria-label="products"
                      aria-labelledby="products"
                      aria-describedby="products"
                      {...field}
                      disabled={mode === "view"}
                      as="textarea"
                      rows={4}
                    />
                  )}
                  name="products"
                  control={control}
                  {...register("products", {
                    maxLength: {
                      value: 3000,
                      message: "Maximum character count exceeded.",
                    },
                  })}
                />
              </Form.Group>
              <span className="float-right text-muted mt-1">
                <i className="fa fa-info-circle mr-1" aria-hidden="true"></i>Max
                character : 3000
              </span>
              {errors.products && (
                <span className="dh-txt-danger">{errors.products.message}</span>
              )}
            </Col>
          </Row>
        </Col>
        <Col md={4} sm={4} xl={4} lg={4}>
          <Row>
            <Col>
              <Form.Group controlId="publicPhoto">
                <Form.Label className="font-14 mb-2 dh-title-lb smallTitle text-brand">
                  SPF Vendor Photo
                </Form.Label>
                <div
                  id="edit_publicPhoto_box"
                  className="dh-photo-cover"
                  style={{ position: "relative" }}
                >
                  <div
                    className="dh-store-photo"
                    id="edit_publicPhoto"
                    style={{ position: "relative" }}
                  >
                    {previewImage ? (
                      <StyledImageUpload>
                        <StyledImage alt="image not found" src={previewImage} />
                        {mode === "edit" && (
                          <>
                            <Button
                              title={"Remove"}
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                borderRadius: "3px",
                              }}
                              variant="danger"
                              onClick={handleRemoveImage}
                            >
                              <i className="fa fa-trash-o"></i>
                            </Button>
                          </>
                        )}
                      </StyledImageUpload>
                    ) : (
                      <div style={{ padding: "15px" }}>
                        <h1>
                          <i className="fa fa-picture-o fa-3"></i>
                        </h1>
                        <div className="smallTitle">Not Available</div>
                        <div>
                          Click `Edit` then `Choose File` to upload vendor photo
                        </div>
                      </div>
                    )}
                  </div>
                  {mode === "edit" ? (
                    <div className="form-group ">
                      <input
                        {...register("publicPhoto", {
                          required: false,
                          validate: {
                            lessThan3MB: (files) => {
                              if (selectedImage?.base64URL) {
                                setIsValidImage(
                                  files.length == 0 ||
                                    files[0]?.size / 1024 <= 3072
                                    ? false
                                    : true
                                );
                                return (
                                  files.length == 0 ||
                                  files[0]?.size / 1024 <= 3072 ||
                                  "File should be less than 3MB"
                                );
                              } else {
                                setIsValidImage(true);
                                return true;
                              }
                            },
                            acceptedFormats: (files) => {
                              if (selectedImage?.base64URL) {
                                return (
                                  files.length == 0 ||
                                  [
                                    "image/jpeg",
                                    "image/png",
                                    "image/gif",
                                  ].includes(files[0]?.type) ||
                                  "File format should be PNG, JPEG or GIF"
                                );
                              } else {
                                return true;
                              }
                            },
                          },
                        })}
                        type="file"
                        accept="image/*"
                        maxLength="100"
                        id="vendorPhotoFile"
                        className="form-control required uploadImage-style form-control-lg"
                        placeholder="Upload Photo"
                        onChange={(e) => {
                          handleFileInputChange(e);
                          errors.publicPhoto = "";
                        }}
                      />
                      {errors.publicPhoto && (
                        <span className="dh-txt-danger">
                          {errors.publicPhoto.message}
                        </span>
                      )}
                    </div>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  </div>
);
};

CsaPublicInfoTab.propTypes = {
    updatePublicDetails: PropTypes.func.isRequired,
    vendorId: PropTypes.string,
    updateUser: PropTypes.func.isRequired,
    userId: PropTypes.number,
    vendorInfo: PropTypes.func,
    vendorDetails: PropTypes.object,
    getVendorInfoList: PropTypes.func,
    getSignatureLink: PropTypes.func,
    userAuth : PropTypes.object,
    user : PropTypes.func,
    isLoading : PropTypes.bool
};
const mapDispatchToProps = (dispatch) => {
    return {
        updatePublicDetails: (user) => {
            console.log("dispatch update", user);
            dispatch({ type: UPDATE_PUBLIC, value: user });
        },
        updateUser: (user) => {
            dispatch({ type: PUT_USER, value: user });
        },
        getVendorInfoList: (payload) => dispatch(getVendorInfoList(payload)),
       
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CsaPublicInfoTab);
