export const actionTypes = {
  GET_VENDOR_STATS: "GET_VENDOR_STATS",
  GET_STORE_STATS: "GET_STORE_STATS",
  SET_VENDOR_STATS: "SET_VENDOR_STATS",
  SET_STORE_STATS: "SET_STORE_STATS",
  GET_SENIOR_STATS: "GET_SENIOR_STATS",
  SET_SENIOR_STATS: "SET_SENIOR_STATS",
  GET_MARKET_STATS: "GET_MARKET_STATS",
  SET_MARKET_STATS: "SET_MARKET_STATS",
  GET_SENIOR_COUNTY: "GET_SENIOR_COUNTY",
  SET_SENIOR_COUNTY: "SET_SENIOR_COUNTY",
  GET_MARKET_COUNTY: "GET_MARKET_COUNTY",
  SET_MARKET_COUNTY: "SET_MARKET_COUNTY",
};
  
  export  function getVendorStats(payload) {
      return {
        type: actionTypes.GET_VENDOR_STATS,
        payload: payload
      };
  }

   export function getSeniorStats(payload) {
     return {
       type: actionTypes.GET_SENIOR_STATS,
       payload: payload,
     };
   }

    export function getSeniorCountyStats(payload) {
      return {
        type: actionTypes.GET_SENIOR_COUNTY,
        payload: payload,
      };
    }

   
   export function getMarketStats(payload) {
     return {
       type: actionTypes.GET_MARKET_STATS,
       payload: payload,
     };
   }

    export function getMarketCountyStats(payload) {
      return {
        type: actionTypes.GET_MARKET_COUNTY,
        payload: payload,
      };
    }

  export  function getStoreStats(payload) {
    return {
      type: actionTypes.GET_STORE_STATS,
      payload: payload
    };
  }