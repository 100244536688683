import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Button, Typography } from "@mui/material";
import * as actionType from "../../../redux/actionTypes/csaSignup";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/reducers";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  Box,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { Col } from "react-bootstrap";
import { popUpAlert } from "../../../utils/globalMethods";
// import document from "../../../assets/csa_roles_responsibility/CSA Attestations.pdf";
import CommonLoading from "../../common/components/commonLoading";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
function SignSubmitComponent(props) {
  const transProps = useTranslator(
    [
      "pageTitle",
      "SPFRight",
      "paymentSchedule",
      "farmDecides",
      "benifitsWill",
      "signupInfo",
      "haveRead",
      "pleaseCheck",
      "date",
      "clientSignature",
      "clientSignatureReq",
      "prev",
      "submit",
    ],
    "signSubmit"
  );
  let loginId = props?.userAuth?.user?.id; //searchParams.get("loginId");
  const [isLoggin] = useState(loginId);
  const [signReadonly, setSignReadonly] = useState(false);
  const [isESignExist, setIsESignExist] = useState(false);
  const [showAttestations, setShowAttestations] = useState(false);
  const [isLoading] = useState();
  const [isSubmitClick, setIsSubmitClick] = useState();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  // useEffect(()=>{
  //   if (isLoggin) {
  //     const sign = {
  //       "signature": data?.sign,
  //       "isESignExist": "N",
  //     }
  //     props.setSignature(sign);
  //   }
  // },[isLoggin]);

  const [searchParams] = useSearchParams();
  const appId = searchParams.get("appId");
  console.log(appId);
  // const _appNumber = params?.appId ?? "";
  // console.log(_appNumber, 4444);

  useEffect(() => {
    if (props.csaSignup?.eSign?.code === "15") {
      setValue("sign", "");
      setSignReadonly(true);
      setIsESignExist(false);
      if (isSubmitClick) {
        popUpAlert({
          text: "Esign Id does not exist. Please resubmit the form",
          icon: "warning",
          timer: 2000,
        });
        setIsSubmitClick(false);
      }
    } else if (props.csaSignup?.eSign?.code === "200") {
      setValue("sign", props.csaSignup?.eSign?.message);
      setSignReadonly(true);
      setIsESignExist(true);
      if (isSubmitClick) {
        props.setIsSignSubmit(true);
        const sign = {
          signature: getValues("sign"),
          isESignExist: "Y",
        };
        props.setSignature(sign);
      }
    }
  }, [props.csaSignup?.eSign]);

  const onSubmit = () => {
    navigate(`/kfresh/client-agreement/${appId}`);
    // setIsSubmitClick(true);
    // if (showAttestations && getValues("sign")) {
    //   props.setIsSignSubmit(true);
    //   const sign = {
    //     signature: getValues("sign"),
    //     isESignExist: isESignExist ? "Y" : "N",
    //   };
    //   props.setSignature(sign);
    // } else {
    //   if (isLoggin && isLoggin > 0) {
    //     const payload = {
    //       firstName: props?.clientInfo?.firstName,
    //       lastName: props?.clientInfo?.lastName,
    //       apid: props?.clientInfo?.apid,
    //       date: dayjs().format("YYYY-MM-DD"),
    //     };
    //     props.getSignature(payload);
    //   }
    // }
  };

  useEffect(() => {
    if (isLoggin && isLoggin > 0) {
      const payload = {
        firstName: props?.clientInfo?.firstName,
        lastName: props?.clientInfo?.lastName,
        apid: props?.clientInfo?.apid,
        date: dayjs().format("YYYY-MM-DD"),
      };
      props.getSignature(payload);
    }
  }, [isLoggin]);

  const Attestations = () => {
    // props.setIsSignSubmit(false);
    setShowAttestations(!showAttestations);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <CommonLoading />}

      <Grid
        id="submit-signup"
        item
        lg={12}
        md={12}
        ml={4}
        mb={2}
        className="heading-grid"
        style={{ backgroundColor: "#EEEEEE" }}
      >
        <Table>
          <TableBody>
            {/* <Grid style={{ backgroundColor: "#EEEEEE", height: "48px", borderStyle: 'none', width: '800px' }}>
              <TableCell sx={{ width: 'auto', borderStyle: 'none' }}>
                <Typography className="sub-heading-sign">Sign & Submit</Typography>
              </TableCell>
            </Grid> */}
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderBottom: "1px solid #CCCCCC",
              }}
            >
              <Typography
                variant="h5"
                xs={{ fontFamily: "texta-bold" }}
                className="sub-heading"
              >
                {transProps.pageTitle}
              </Typography>
            </Box>
            <Card
              style={{
                color: "white",
                height: "auto",
                boxShadow: "none",
                borderRadius: 0,
              }}
            >
              {!showAttestations && (
                <Col
                  className="jumbotron"
                  style={{ alignContent: "center", height: "auto" }}
                >
                  <div className="csa-rights-card">
                    <h5 className="csa-rights">{transProps.SPFRight}</h5>
                    <div className="content">
                      <li>{transProps.paymentSchedule}</li>
                      <li>{transProps.farmDecides}</li>
                      <li>{transProps.benifitsWill}</li>
                      <li>{transProps.signupInfo}</li>
                    </div>
                  </div>
                </Col>
              )}
              <Grid className="row">
                <Box className="col-sm-8">
                  <div style={{ display: "flex" }}>
                    <input
                      style={{ position: "relative", left: "40px" }}
                      {...register("terms", { required: true })}
                      id="terms_conditions"
                      type="checkbox"
                      onClick={Attestations}
                    />
                    <button type="checkbox" className="checkbox">
                      <label htmlFor="terms_conditions">
                        {transProps.haveRead}
                      </label>
                    </button>
                  </div>
                  <div style={{ marginLeft: "18px" }}>
                    {errors.terms && (
                      <span className="dh-txt-danger">
                        <i className="fa fa-info-circle"></i>
                        {transProps.pleaseCheck}
                      </span>
                    )}
                  </div>
                </Box>
                <Box
                  style={{ color: "white", boxShadow: "none", borderRadius: 0 }}
                  className="col-sm-4"
                >
                  <Box
                    className="col-sm-12"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography className="client-signature col-sm-6">
                      {transProps.date}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Texta-book",
                        fontSize: "20px",
                        color: "#000",
                      }}
                      className="col-sm-6"
                    >
                      {dayjs().format("MM/DD/YYYY")}
                    </Typography>
                  </Box>
                  <Grid
                    sx={{ marginTop: "20px", marginBottom: "20px" }}
                    className="col-sm-10"
                  >
                    <Box md={8} className="row">
                      <Form.Label className="client-signature">
                        {transProps.clientSignature} *
                      </Form.Label>
                      <Form.Control
                        {...register("sign", { required: !signReadonly })}
                        className={
                          signReadonly ? "grey signsubmit" : "signsubmit"
                        }
                        readOnly={signReadonly}
                      />
                    </Box>
                    {errors.sign && (
                      <span className="dh-txt-danger">
                        <i className="fa fa-info-circle"></i>{" "}
                        {transProps.clientSignatureReq}
                      </span>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Card>

            <Grid
              style={{
                backgroundColor: "#FFFF",
              }}
            >
              <TableCell
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  onClick={() => {
                    props.setActiveTab("menuStep4");
                    props.setCSAFormValidate((prevState) => ({
                      ...prevState,
                      sign_submit:
                        signReadonly || getValues("sign") != "" ? true : false,
                    }));
                  }}
                  sx={{
                    backgroundColor: "#ffffff",
                    color: "#006E52",
                    padding: "10px",
                    borderColor: "#6C6C6C",
                    border: "solid",
                    borderWidth: "1px",
                    fontFamily: "texta-bold",
                    letterSpacing: "normal",
                    fontSize: "18px",
                    textTransform: "capitalize",
                    // "&:hover": {
                    //   backgroundColor: "#006E52",
                    //   color: "#ffffff",
                    // },
                    // "&:active": {
                    //   backgroundColor: "#006E52",
                    //   color: "#ffffff",
                    // },
                  }}
                >
                  {transProps.prev}
                </Button>

                <Button
                  type="submit"
                  disabled={isLoading}
                  sx={{
                    backgroundColor: "#457425 !important",
                    display: "flex",
                    alignItems: "end",
                    color: "white",
                    padding: "10px",
                    fontFamily: "Texta-bold !important",
                    letterSpacing: "normal",
                    fontSize: "18px",
                    textTransform: "capitalize",
                  }}
                >
                  {transProps.submit}
                </Button>
              </TableCell>
            </Grid>
          </TableBody>
        </Table>
        {/* </TableContainer> */}
      </Grid>
    </Form>
  );
}
SignSubmitComponent.propTypes = {
  setActiveTab: PropTypes.string.isRequired,
  signature: PropTypes.object,
  setSignature: PropTypes.object,
  setCSAFormValidate: PropTypes.func.isRequired,
  getSignature: PropTypes.func.isRequired,
  clientInfo: PropTypes.object,
  csaSignup: PropTypes.object,
  setIsSignSubmit: PropTypes.func.isRequired,
  userAuth: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    getSignature: (payload) => dispatch(actionType.getSignature(payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignSubmitComponent);
