import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import { Button } from "react-bootstrap";
import TopNav from "./TopNav";
import LandingHomePage from "./LandingHomePage";

const AdminLogin = () => {
  const [openLogin, setLoginOpen] = useState(false);
  const [openReg, setRegOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  const handleClickOpen = (value) => {
    if (value === "login") {
      setLoginOpen(true);
    } else {
      setRegOpen(true);
    }
  };

  const handleClose = () => {
    setLoginOpen(false);
    setRegOpen(false);
  };

  const openSeniorSignUp = (type) => {
    if(type === "signup"){
      setLoginOpen(false);
      setRegOpen(true);
    }else {
      setLoginOpen(true);
      setRegOpen(false);
    }
  }

  return (
    <>
      <TopNav handleClick={handleClickOpen} />
      <LandingHomePage
        setLoginOpen={setLoginOpen}
        setRegOpen={setRegOpen}
        setSelectedRole={setSelectedRole}
        selectedRole={selectedRole}
      />
    
    </>
  );
};

export default AdminLogin;
