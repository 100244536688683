export const AUTH_API = process.env.REACT_APP_BASE_URL;
export const API_URL =
  AUTH_API +
  "/" +
  (sessionStorage.getItem("ext_url")
    ? sessionStorage.getItem("ext_url")
    : "csa");
export const PUBLIC_API = AUTH_API + "/public";
export const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const AUTHORITY = process.env.REACT_APP_AUTHORITY;
export const KNOW_AUTHORITIES =
  process.env.REACT_APP_KNOW_AUTHORITIES.split(",");

export const headers = {
  Accept: "application/json",
  "access-control-allow-methods": "PUT, GET, HEAD, POST, DELETE, OPTIONS",
  "Content-Type": "application/json",
};

export const config = {
  navUrls: {
    massGov: "https://www.mass.gov/",
    massGovSnap: "http://mass.gov/snap",
    dtaConnect: "https://dtaconnect.eohhs.mass.gov/",
    snapNetwork: "https://www.snappathtowork.org/",
    dtaHip: "https://www.mass.gov/hip",
    mahealthyfoodsinasnap: "https://masnaped.org/",
    dtaListensUrl: "https://www.twitter.com/DTA_Listens",
    sitePoliciesUrl: "https://www.mass.gov/lists/eohhs-site-policies",
    accessibilityUrl:
      "http://www.mass.gov/eohhs/utility/eohhs-accessibility-policy.html",
  },
};

export const appHomeUrl = process.env.REACT_APP_APPHOMEURL;

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: AUTHORITY,
    knownAuthorities: KNOW_AUTHORITIES,
    redirectUri: appHomeUrl,
    postLogoutRedirectUri: appHomeUrl,
    // response_type: "id_token",
    // prompt: "login",
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["openid"],
  extraQueryParameters: {},
  authority: AUTHORITY,
};

export const postLogoutRedirectUri = {
  redirectUri: `${appHomeUrl}/kfreshfep`,
};

export const origin = "*";
