import React from "react";
import Switch, { switchClasses } from "@mui/joy/Switch";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

const CustomSwitch = ({ checked, verified, onChange }) => {
  return (
    <Switch
      checked={checked}
      disabled={!verified}
      onChange={(event) => onChange(event.target.checked)}
      slotProps={{
        track: {
          children: (
            <React.Fragment>
              {checked ? (
                <Typography
                  className={"trackActive"}
                  sx={{ fontSize: "14px", ml: "5px" }}
                >
                  Active
                </Typography>
              ) : (
                <Typography
                  className={"trackInactive"}
                  sx={{
                    textAlign: "right",
                    width: "100%",
                    fontSize: "14px",
                    mr: verified ? "5px" : "15px",
                  }}
                >
                  Inactive
                </Typography>
              )}
            </React.Fragment>
          ),
        },
      }}
      sx={{
        alignSelf: "flex-start",
        "--Switch-trackWidth": "80px",
        "--Switch-trackHeight": "30px",
        "--Switch-thumbSize": "24px",
        "--Switch-thumbWidth": verified ? "15px" : "0px",
        "--Switch-trackRadius": "4px",
        "--Switch-trackBackground": "#dc3545",
        "&:hover": {
          "--Switch-trackBackground": "#dc3545",
        },
        [`& .trackActive`]: {
          display: "none",
        },
        [`&.${switchClasses.checked} .trackActive`]: {
          display: "block",
        },
        [`&.${switchClasses.checked} .trackInactive`]: {
          display: "none",
        },
        [`&.${switchClasses.checked}`]: {
          "--Switch-trackBackground": "#006e52",
          "&:hover": {
            "--Switch-trackBackground": "#006e52",
          },
        },
        [`&.${switchClasses.disabled}`]: {
          "--Switch-trackBackground": "#dc3545",
          "--Switch-trackColor": "#FFFFFF",
        },
      }}
    />
  );
};

CustomSwitch.propTypes = {
  verified: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CustomSwitch;
