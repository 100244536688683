import React, { useState } from "react";
import SeniorDocuments from "./documents/seniorDocuments";
import FarmerDocuments from "./documents/farmerDocuments";
// import { Stack, Tabs } from "@mui/material";
import { Stack } from "@mui/material";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import useTranslator from "../../hooks/useTranslator";

const DocumentUploaded = () => {
  const [key, setKey] = useState("1");
  const transProps = useTranslator(
    ["seniorDocuments", "farmerDocuments"],
    "documentUploaded"
  );

  return (
    <Stack direction={"column"} width={"100%"} m={2} height={"100vh"}>
      <Row style={{ marginTop: "10px" }}>
        <Col className="vendor-info-tabs">
          <Tabs
            id="controlled-tab"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            style={{ background: "#fff" }}
            // onChange={handleChange}
          >
            <Tab
              eventKey="1"
              title={
                <div className="tab-header">
                  <span className="">{transProps.seniorDocuments}</span>
                </div>
              }
            >
              <SeniorDocuments />
            </Tab>
            <Tab
              eventKey="2"
              title={
                <div className="tab-header">
                  <span className="m-l-5">{transProps.farmerDocuments}</span>
                </div>
              }
            >
              <FarmerDocuments />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Stack>
  );
};

export default DocumentUploaded;
