import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { getStore } from "./redux/store/Store";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";
import "./i18n";
// azure integration
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { ThemeProvider } from "react-bootstrap";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Padding } from "@mui/icons-material";
import { color } from "echarts";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const theme = createTheme({
  palette: {
    black: {
      main: "#000000",
    },
    white: {
      main: "#FFFFFF",
    },
  },
  typography: {
    h6: {
      fontFamily: "Texta-bold",
      fontWeight: 900,
      fontSize: "1rem",
      color: "#000000",
    },
    body1: {
      fontFamily: ["Texta-book", "Helvetica", "Arial", "sans-serif"].join(","),
      fontSize: 19,
      color: "#212529 !important",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          backgroundColor: "#fff",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#44a0e3",
          },
          "&.Mui-disabled": {
            backgroundColor: "#e9ecef",
            opacity: 1,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ced4da",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ced4da",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#44a0e3",
          },
        },
        input: {
          padding: "10px",
          fontWeight: "bold",
        },
        notchedOutline: {
          borderColor: "#44a0e3",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedWhite: {
          backgroundColor: "#FFF",
          color: "black",
          "&:hover": {
            backgroundColor: "#FFF",
          },
        },
      },
    },
  },
});

// azure instance initialization
const msalInstance = new PublicClientApplication(msalConfig);

const store = getStore();

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <CookiesProvider>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {/* <ToastContainer  autoCloes={5000} /> */}
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </LocalizationProvider>
      </MsalProvider>
    </Provider>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
