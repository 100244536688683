import axios from "axios";
import { documnetURL } from "../../../pages/senior/constant";
// import { documentUpdatePayload } from "./apiPayload";

export const docFileUpload = (filePayload = {}) => {
  return axios.post(
    `${documnetURL}/docControl/documentDetails/upload`,
    filePayload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const getDocumentByCaseID = (caseId) => {
  return axios.get(
    `${documnetURL}/docControl/getDocumentsByCaseId/${caseId}/KFresh`
  );
};

export const DownloadDocument = async (documentId, sourceCaseId) => {
  return await axios.post(
    `${documnetURL}/docControl/downloadDocumentBy/${documentId}/${sourceCaseId}/KFresh`,
    {},
    { responseType: "blob" }
  );
};

export const documentEdit = (filePayload = {}) => {
  return axios.post(
    `${documnetURL}/docControl/documentDetails/edit`,
    filePayload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const documentDelete = (documentId) => {
  return axios.delete(
    `${documnetURL}/docControl/documentDetails/delete/${documentId}`
  );
};

export const getDocumentByDocumentID = (documentId) => {
  return axios.get(`${documnetURL}/docControl/getDocuments/${documentId}`);
};
