import React, { useState } from 'react';
import {
  Box,
  TextField,
  Stack,
  Button,
  FormLabel,
  InputAdornment,
  IconButton 
} from "@mui/material";
import PropTypes from "prop-types";
import {useFormik} from 'formik'
import * as Yup from "yup";
import axios from 'axios';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { AUTH_API } from "../../config";
import toast from 'react-hot-toast';

const OtpPassword = ({ screen, setScreen, role, email}) => {
const [showPassword, setShowPassword] = useState(false);
const validationSchema = Yup.object({
otp: Yup.string()
    .matches(/^[0-9]{6}$/, 'Must be 6 digits').required("Otp is required"),
newPassword: Yup.string().required("New Password is required"),
});


const formik = useFormik({
initialValues: {
    otp: "",
    newPassword:""
},
validationSchema,
onSubmit: async (values) => {
    let { otp, newPassword } = values;
    let payload ={
        userLoginId: email,
        role,
        otp,
        newPassword
    }
    try {
      const res = await axios.post(`${AUTH_API}/saveNewPassword`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if(res.status === 200){
        setScreen("")
        toast.success("Password reset successful.");
      }
    } catch (error) {
        toast.error("Password reset attempt failed.");
    } 
},
});


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  return (
    <div className="login-paper">
        <h2 className="font-bold boldFont pt-3">
           Reset your password
        </h2>
        <span style={{fontSize:'1rem', textAlign:'center'}} className='my-2'>
            We have sent a six digit code on your email        
        </span>
        <Stack component="form" className="login-form w-100"   onSubmit={formik.handleSubmit}>
            <Box sx={{ marginTop: "1rem" }}>
            <FormLabel className="labelName">OTP Number</FormLabel>
            <TextField
                fullWidth
                id="otp"
                placeholder="Enter the otp number"
                name="otp"
                value={formik.values.otp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.otp && formik.errors.otp}
                helperText={formik.touched.otp && formik.errors.otp}
                InputProps={{
                    onInput: (event) => {
                      event.target.value = event.target.value.replace(
                        /[^0-9]/g,
                        ''
                      );
                    },
                  }}
                inputProps={{ maxLength: 6 }}
            />
            </Box> 
            <Box sx={{ marginTop: "1rem" }}>
            <FormLabel className="labelName">New Password</FormLabel>
            <TextField
                fullWidth
                id="newPassword"
                placeholder="Enter your new password"  
                type={showPassword ? "text" : "password"}
                name="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.newPassword && formik.errors.newPassword}
                helperText={formik.touched.newPassword && formik.errors.newPassword}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? (
                            <VisibilityOff />
                            ) : (
                            <Visibility />
                            )}
                        </IconButton>
                        </InputAdornment>
                    ),
                    }}
            />
            </Box>
            <Box 
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                mt={5}
                gap={2}
            >
                <Button  type="submit" variant='contained' color='success' sx={{width:'100%'}}>
                    Reset Password
                </Button>
            </Box>
        </Stack>
    </div>
  )
}

OtpPassword.propTypes = {
  screen: PropTypes.string,
  setScreen: PropTypes.func,
  email: PropTypes.string,
  role: PropTypes.string,
  setEmail: PropTypes.func,
}

export default OtpPassword