import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Login from "./Login";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const LoginDialog = ({ open, handleClose, handleSignupDialog }) => {
  const onClose = () => {
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="lg"
        PaperProps={{
          sx: {
            borderRadius: "10px",
          },
        }}
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle></DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Login handleSignupDialog={handleSignupDialog} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

LoginDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSignupDialog: PropTypes.func,
};

export default LoginDialog;
