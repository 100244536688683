import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';


const FormInput = ({ label, name, register, errors,maxLength }) => {
  return (
      <>
      <label className="dh-form-label font-bold" htmlFor={name}>{label} *</label>
      <TextField
        name={name}
        {...register(name, {
          required: `${label} is required`,
        })}
        fullWidth
        inputProps={{ maxLength:maxLength}}
      />
            {errors[name] && (
            <span className="dh-txt-danger">
              {errors[name].message}
            </span>
          )}
      </>
  );
};
FormInput.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
};

export default FormInput;
