import React from "react";
import PropTypes from "prop-types";
import "../../styles/dashboard.scss";

const Modal=({ children, shown, close })=> {
    return shown ? (
      <div
        className="location-modal modal-backdrop"
        onClick={() => {
          // close modal when outside of modal is clicked
          close();
        }}
      >
        <div
          className="modal-content"
          onClick={e => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          {children}
        </div>
      </div>
    ) : null;
};
Modal.propTypes = {
    children: PropTypes.object,
    shown: PropTypes.bool,
    close: PropTypes.func
};
export default Modal;