import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SignaturePad from "../../components/SignaturePad";
import "./market.scss";
import { ReactComponent as FeatherIcon } from "../../../src/assets/images/feather.svg";
import { filter, map } from "lodash";
import { useFormik } from "formik";
import axios from "axios";
import { AUTH_API } from "../../config";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import useLoader from "../../hooks/useLoader";
import dayjs from "dayjs";
import FileUploadModal from "../../common/fileUpload/FileUploadModal";

function PublicInfo() {
  const [openSignatureModal, setOpenSignatureModal] = useState(false);
  const [questionCheck, setQuestionCheck] = useState([]);
  const [date, setDate] = useState(dayjs());
  // const [date, setDate] = useState("");
  const [docOpen, setDocOpen] = useState(false);
  const [getSign, setGetSign] = useState("");
  const [showSign, setShowSign] = useState("");
  const { openLoader, closeLoader } = useLoader();
  const navigate = useNavigate();
  const sentences = [
    "Do you and/or each farmer your represent at your market/roadside stand stand carry locally grown,eligible fresh,nutritious,unprocessed fruits and vegetables, and/or localy collected honey?",
    "Are you and/or each farmer you represent at your market/roadside stand a grower of at least someof the eligible products that you are selling? Note: a grower is defined as an individual or group that has a plot of land or garden that is wholly or partially dedicated for growing produce.",
    "If i am registering for the first time, I agree to participate in training required by the USDA.",
    "New and returning Market Masters:I agree to read the Market Master Guidebook (available on the website) and agree to follow the rules and procedures listed in the Guidebook,as well as those issued during the current season.",
    "If i participate in both Senior Project FRESH and WIC Project FRESH, I acknowledge that they are two seperate programs, and that i will adhere to the rules and regulations of both programs, which may be different.",
    " By hitting the Submit Button below indicates I certify that the information given is true and correct to the best of my knowledge and belief.",
  ];
  const location = useLocation();
  const userData = location?.state?.userData ?? "";
  console.log(userData, "userData");

  const id = userData?.id;
  const vendrName = userData?.vendorName;

  const params = useParams();
  const _appNumber = params?.appNumber ?? "";
  _appNumber ? sessionStorage.setItem("appNumber", _appNumber) : "";
  const userLogin = sessionStorage.getItem("userLogin")
    ? JSON.parse(sessionStorage.getItem("userLogin"))
    : "";
  const fullName = `${userLogin?.firstName} ${userLogin?.lastName}`;
  const userRole = userLogin?.role;

  const toggleSignature = async (signatureImage) => {
    if (!signatureImage.target) {
      const payLoad = {
        dirtyStatus: "INSERT",
        signatoryName: "Murali",
        signSource: "Verified",
        signBytes: signatureImage,
        deleteInd: "N",
        vendorId: id,
        source: "MRKCRD",
      };

      setShowSign(signatureImage);
      try {
        const headers = {
          "Content-Type": "application/json",
        };
        const res = await axios.post(
          AUTH_API + "/common/signature/save",
          payLoad,
          {
            headers: headers,
          }
        );
      } catch (error) {
        console.log(error);
      } finally {
        setOpenSignatureModal(false);
      }
    }
  };

  // const get_signature = async () => {
  //   try {
  //     const res = await axios.post(
  //       AUTH_API + `/common/signatureInfo/${id}/marketcorordinator`,
  //       {}
  //     );
  //     setGetSign(res.data.signBytes);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const formik = useFormik({
    initialValues: {
      email: "",
      website: "",
      fb: "",
      twitter: "",
      mail: "",
      uMail: "",
      date: "",
      notificationVia: [],
    },

    onSubmit: (values) => {
      publicinfo(values);
    },
    enableReinitialize: true,
  });

  const publicinfo = async (values) => {
    const timeStamp = new Date().getTime();
    let payload = {
      websiteAddr: values.website,
      facebookPage: values.fb,
      communicationEmail: values.email,
      twitterPage: values.twitter,
      id: id,
      lastUpdtUidNam: vendrName,
      createTime: timeStamp,
      lastUpdateTime: timeStamp,
      vendorType: "MRKCRD",
      notificationVia: values.notificationVia
        ? values.notificationVia.join(", ")
        : "",
    };

    try {
      openLoader("public_Info");
      const res = await axios.post(
        AUTH_API + "/public/update/marketcoordinator",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.status == 200) {
        navigate("/");
        toast.success("Registered Successfully!");
      } else {
        toast.error("Not Registered!");
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("public_Info");
    }
  };
  const onchangeHandler = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setQuestionCheck((prev) => {
        return [...prev, name];
      });
    } else {
      setQuestionCheck((prev) => {
        return filter(questionCheck, (val) => val !== name);
      });
    }
  };

  const checkboxCheck = questionCheck.length === 6;
  const currentDate = dayjs();
  // const signatureDateCheck = checkboxCheck ? dayjs() : "";
  const dateCheck = date;

  // useEffect(() => {
  //   get_signature();
  // }, []);
  const handleModalOpen = () => {
    setDocOpen(true);
  };
  return (
    <React.Fragment>
      <Typography className="title" variant="h5" sx={{ margin: "1rem" }}>
        Public Info
      </Typography>
      <Box
        className="box"
        sx={{ border: "1px solid #d9d9d9", borderRadius: "0.5rem" }}
        m={3}
        p={2}
        width="80%"
      >
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="h6" className="typography">
              Communication Email Address
            </Typography>
            <TextField
              inputProps={{ maxLength: 50 }}
              fullWidth
              name="email"
              size="small"
              placeholder={"Enter Email Address"}
              value={formik.values.email}
              onChange={formik.handleChange}
            ></TextField>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography className="typography" variant="h6">
              Website Address
            </Typography>
            <TextField
              inputProps={{ maxLength: 500 }}
              fullWidth
              name="website"
              size="small"
              placeholder={"Enter Website Address"}
              value={formik.values.website}
              onChange={formik.handleChange}
            ></TextField>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="h6" className="typography">
              Facebook Page
            </Typography>
            <TextField
              inputProps={{ maxLength: 500 }}
              fullWidth
              name="fb"
              size="small"
              placeholder={"Enter Facebook Page"}
              value={formik.values.fb}
              onChange={formik.handleChange}
            ></TextField>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography className="typography" variant="h6">
              Twitter
            </Typography>
            <TextField
              inputProps={{ maxLength: 500 }}
              fullWidth
              name="twitter"
              size="small"
              placeholder={"Enter Twitter"}
              value={formik.values.twitter}
              onChange={formik.handleChange}
            ></TextField>
          </Grid>
          {/* <Grid item xs={6} md={6} lg={6}>
            <Typography variant="h6">Notification VIA</Typography>
            <FormControlLabel
              value="mail"
              control={
                <Checkbox
                  {...formik.getFieldProps("mail")}
                  checked={formik.values.mail === "mail"}
                  onChange={(e, checked) => {
                    formik.setFieldValue("mail", checked ? "mail" : "");
                  }}
                />
              }
              label="Email"
              labelPlacement="end"
            />
            <FormControlLabel
              value="umail"
              control={
                <Checkbox
                  {...formik.getFieldProps("uMail")}
                  checked={formik.values.uMail === "uMail"}
                  onChange={(e, checked) => {
                    formik.setFieldValue("uMail", checked ? "uMail" : "");
                  }}
                />
              }
              label="USPS Mail"
              labelPlacement="end"
            />
          </Grid> */}
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="h6" fontWeight="fontWeightBold">
              Notification VIA
            </Typography>
            <FormControlLabel
              label="Email"
              labelPlacement="end"
              control={
                <Checkbox
                  value="mail"
                  checked={formik.values.notificationVia.includes("mail")}
                  name="notificationVia"
                  onChange={formik.handleChange}
                />
              }
            />
            <FormControlLabel
              label="USPS Mail"
              labelPlacement="end"
              control={
                <Checkbox
                  value="uspsmail"
                  checked={formik.values.notificationVia.includes("uspsmail")}
                  name="notificationVia"
                  onChange={formik.handleChange}
                />
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{ border: "1px solid #d9d9d9", borderRadius: "0.5rem" }}
        m={3}
        p={5}
        width="80%"
      >
        <Grid container spacing={2}>
          <Typography variant="h5" width={"100%"}>
            THESE ARE ALL REQUIRED
            <span style={{ color: "red" }}>*</span>
          </Typography>

          {map(sentences, (sentence, index) => {
            return (
              <Typography variant="h6">
                <Checkbox name={index + 1} onChange={onchangeHandler} />
                {sentence}
              </Typography>
            );
          })}
        </Grid>
      </Box>
      <Box mx={3}>
        <Button
          onClick={handleModalOpen}
          sx={{
            border: "1px solid #44A0E3 !important",
            color: " var(--Secon-1-D-Blue, #44A0E3)",
            fontSize: "14px !important ",
            fontWeight: "700 !important",
          }}
          disabled={!userData}
        >
          <UploadFileIcon />
          File Upload
        </Button>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "2.5rem",
          marginRight: "2.5rem",
        }}
      >
        <div style={{ margin: "2.5rem" }}>
          <Typography variant="h6">Signature Date</Typography>
          <DatePicker
            minDate={dayjs().subtract(5, "day")}
            value={checkboxCheck ? date : null}
            onChange={(date) => setDate(date)}
            disableFuture
            slotProps={{ textField: { size: "small" } }}
            disabled={!checkboxCheck}
          />
        </div>
        <Box>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {showSign ? <img width={100} height={100} src={showSign} /> : ""}
          </div>
          <div>
            <Button
              startIcon={<FeatherIcon />}
              onClick={() => setOpenSignatureModal(true)}
              variant="contained"
              color="success"
              size="large"
              disabled={!checkboxCheck}
              sx={{ marginTop: "1rem" }}
            >
              Signature
            </Button>
          </div>
        </Box>
      </div>

      <Stack
        direction={"row"}
        spacing={2}
        mt={3}
        mr={3}
        mb={3}
        justifyContent={"flex-end"}
      >
        <Button
          size="small"
          variant="outlined"
          color="inherit"
          onClick={() => {
            navigate("/marketRegistration");
          }}
        >
          Go Back
        </Button>
        <Button
          size="small"
          variant="contained"
          color="success"
          onClick={formik.handleSubmit}
          disabled={!showSign}
        >
          Submit
        </Button>
      </Stack>
      <SignaturePad
        open={openSignatureModal}
        onClose={toggleSignature}
        setOpenSignatureModal={setOpenSignatureModal}
      />
      <FileUploadModal
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        payload={{
          createDt: dayjs().format("YYYY/MM/DD"),
          createUserId: fullName,
          role: userRole,
          sourceCaseId: id,
        }}
      />
    </React.Fragment>
  );
}

export default PublicInfo;
