import { styled } from '@mui/material/styles';
import { Button } from "@mui/material";

export const CustomButton = styled(Button)((theme) => ({
    color: theme.variant ==="contained" ? "#FFFFFF" : "#006e52",
    background: theme.variant ==="contained" ? "#006e52":"#FFFFFF",
    border: "1px solid rgba(0, 110, 80, 0.5);",
    textTransform:"capitalize",
    "&:hover":{
        background: "#006e52",
        color: "#FFFFFF",
    }
}));