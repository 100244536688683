import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import { useSelector } from "react-redux";

function Loader({ visible }) {
  const loadingItems = useSelector((state) => state.loader.loadingItems);
  const show = loadingItems.length > 0;

  if (!show && !visible) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        width="256.14px"
        height="80.37px"
        viewBox="0 0 256.14 80.37"
        // style="overflow:visible;enable-background:new 0 0 256.14 80.37;"
      >
        <path
          className={styles.fruits}
          fill="none"
          stroke="#467537"
          //   strokeMiterlimit="10"
          d="M0,78.55h83.28c0,0-4.34-5.28-8.28-8.36S62.93,59.46,61.91,58.12s-8.91-7.57-8.04-14.27
	c0.87-6.7,3.08-7.89,3.08-7.89l-19.08-9.38l0.08-2.13c0,0,19.48,8.44,20.27,8.2c-1.66-2.84-8.99-12.93-13.88-15.85
	c0.24-2.92,0.24-3.47,0.63-3.71c1.42,1.03,15.38,11.2,17.74,15.85c-3.31-10.57-1.58-7.57-9.38-20.35C50,4.89,49.45,4.42,49.45,4.42
	h2.92l12.54,16.4c0,0-2.21-12.38-6.78-15.85c1.5,0.55,8.04,9.54,10.73,21.21c6.23-0.55,12.38-3.94,23.11,11.67
	c8.99-10.17,24.29-10.96,29.97-9.23c5.68,1.73,8.6,3.79,8.6,3.79s3.31-0.55,2.05-6.55c-1.5-5.99,1.42-7.89,2.44-8.75
	c1.03-0.87,1.97,5.91,0.95,9.86c3.71-11.12,6.78-15.99,7.73-16.64c1.26-0.87,5.44-1.81,6.23-0.95c0.79,0.87-9.86,18.69-9.86,18.69
	s10.49-17.74,19.64-23.66c8.52-5.76,15.22-3.47,15.22-3.47s-3.47,6.94-4.17,7.33c-0.71,0.39,1.97,0.87,1.34,2.52
	s-2.29,3.15-3.94,2.76c-1.66-0.39,0.16,1.34,0.16,1.34s-0.47,1.34-1.5,1.66c-1.03,0.32,6.86,1.34,6.94,3.08s-2.05,6.23-3.39,6.47
	c-1.34,1.42-3.71,4.81-5.52,5.2c-1.81,0.39-4.42-0.39-4.42-0.39s0.39,1.1,0.39,1.97c0,0.87-1.66,2.44-7.18,0.95
	c-5.52-1.5-11.12,1.66-11.12,1.66s1.26,0.16,1.34,1.58c0.08,1.42-0.39,2.05-1.66,1.89c-1.26-0.16-3.71-0.16-4.18-0.16
	c-0.47,0,4.57,3.36,6.68,10.94c2.1,7.57-1.89,20.19-3.58,22.29c-1.68,2.1-4.21,6.52-4.21,6.52h119.24"
        />
        <g>
          <path
            className="st1"
            fill="#467537"
            d="M118.52,76.24c-1.53,0.23-2.88-1.37-3.18-3.24c-0.39-2.48,1.03-5.75,3.21-8.06L118.52,76.24z M120.3,75.98
		c-0.45-0.66-2.13-3.55,1.76-7.29c-0.92-2.05-2.7-4.3-2.74-4.56c0,0-0.17,0.27-0.58,0.74l0.28,11.25
		C119.32,75.96,119.81,75.99,120.3,75.98z M120.32,72.26c-0.58,2.03,0.23,2.84,0.65,3.71l16.54-9.77l0.08,0.05l-16.36,10.59
		c0.27,1.03,0.75,2.22,2.26,2.81c6.07,2.67,12.9-4.95,14.58-13.92c-4.98-0.01-8.39-0.49-11.68,0.81
		C123.84,67.54,121.07,69.52,120.32,72.26z"
          />
        </g>
        <path
          className="st2"
          fill="#EE302E"
          d="M115.59,80.37l-12.52-16.45v16.45h-9.08V43.11h9.08v16.35l12.42-16.35h10.67l-14.44,18.26l14.97,19H115.59z"
        />
      </svg>
    </div>
  );
}

Loader.propTypes = {
  visible: PropTypes.bool,
};

Loader.defaultProps = {
  visible: false,
};

export default Loader;
