import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from "prop-types";
import "../../../styles/dateTimePicker.scss";

const DatePickerViews = ({handleChange,value}) => {
    // const [date, setDate] = React.useState(dayjs(value?value:new Date().getTime()));
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']} sx={{padding: "0px"}}>
        <DatePicker 
          className={"datePicker"}
          onChange={(newValue) => {
              handleChange(newValue)
          }}
          value={dayjs(value)}
          views={['month', 'year']} />
      </DemoContainer>
    </LocalizationProvider>
  );
}

DatePickerViews.propTypes = {
    handleChange:PropTypes.func,
    value:PropTypes.string,
}

export default DatePickerViews;