export const actionTypes = {
    SET_CSA_VENDOR_LIST: "SET_CSA_VENDOR_LIST",
    GET_CSA_VENDOR_LIST: "GET_CSA_VENDOR_LIST",
    SET_CSA_VENDOR_INFO: "SET_CSA_VENDOR_INFO",
    GET_CSA_VENDOR_INFO: "GET_CSA_VENDOR_INFO",
    SET_CLIENT_INFO: "SET_CLIENT_INFO",
    GET_CLIENT_INFO: "GET_CLIENT_INFO",
    SET_CSA_SHARE_LIST: "SET_CSA_SHARE_LIST",
    GET_CSA_SHARE_LIST: "GET_CSA_SHARE_LIST",
    SET_VENDOR_SHARE_LIST: "SET_VENDOR_SHARE_LIST",
    GET_VENDOR_SHARE_LIST: "GET_VENDOR_SHARE_LIST",
    SET_MANAGE_CSA_VENDOR_LIST: "SET_MANAGE_CSA_VENDOR_LIST",
    GET_MANAGE_CSA_VENDOR_LIST: "GET_MANAGE_CSA_VENDOR_LIST",
    SET_MANAGAE_CSA_VENDOR_INFO: "SET_MANAGAE_CSA_VENDOR_INFO ",
    GET_MANAGAE_CSA_VENDOR_INFO: "GET_MANAGAE_CSA_VENDOR_INFO",
    SET_CLIENT_PARTIAL_SIGNUP_LIST: "SET_CLIENT_PARTIAL_SIGNUP_LIST",
    GET_CLIENT_PARTIAL_SIGNUP_LIST: "GET_CLIENT_PARTIAL_SIGNUP_LIST",
    SET_CLIENT_AGREEMENT_LIST: "SET_CLIENT_AGREEMENT_LIST",
    GET_CLIENT_AGREEMENT_LIST: "GET_CLIENT_AGREEMENT_LIST",
    GET_PICKUP_LOCTION_LIST: "GET_PICKUP_LOCTION_LIST",
    SET_PICKUP_LOCTION_LIST: "SET_PICKUP_LOCTION_LIST",
    GET_EXPORT_CLIENT_AGREEMENT_LIST: "GET_EXPORT_CLIENT_AGREEMENT_LIST",
    SET_CLIENT_PAYMENT_LIST: "SET_CLIENT_PAYMENT_LIST",
    GET_CLIENT_PAYMENT_LIST: "GET_CLIENT_PAYMENT_LIST",
    SET_CLIENT_PICKUP_LOCATION_LIST: "SET_CLIENT_PICKUP_LOCATION_LIST",
    GET_CLIENT_PICKUP_LOCATION_LIST: "GET_CLIENT_PICKUP_LOCATION_LIST",
    EXPORT_CLIENT_PARTIAL_SIGNUP: "EXPORT_CLIENT_PARTIAL_SIGNUP",
    ADD_VENDOR_SHARE_LIST: "ADD_VENDOR_SHARE_LIST",
    EDIT_VENDOR_SHARE_LIST: "EDIT_VENDOR_SHARE_LIST",
    REFRESH_KEY: "REFRESH_KEY",
    DELETE_VENDOR_SHARE_LIST: "DELETE_VENDOR_SHARE_LIST",
    ADD_SHARE_LANG: "ADD_SHARE_LANG",
    EDIT_SHARE_LANG: "EDIT_SHARE_LANG",
    DELETE_SHARE_LANG: "DELETE_SHARE_LANG",
    DELETE_CLIENT_PARTIAL_SIGNUP_LIST: "DELETE_CLIENT_PARTIAL_SIGNUP_LIST",
    SET_CSASIGNUP_STATUS_LIST: "SET_CSASIGNUP_STATUS_LIST",
    GET_CSASIGNUP_STATUS_LIST: "GET_CSASIGNUP_STATUS_LIST",
    GET_SNAP_SHARE: "GET_SNAP_SHARE",
    SET_SNAP_SHARE: "SET_SNAP_SHARE",
    GET_SNAP_HIP_AMOUNT: "GET_SNAP_HIP_AMOUNT",
    SET_SNAP_HIP_AMOUNT: 'SET_SNAP_HIP_AMOUNT',
    EXPORT_CSASIGNUP_STATUS: "EXPORT_CSASIGNUP_STATUS",
    UPDATE_APPROVE_CSASIGNUP_STATUS: "UPDATE_APPROVE_CSASIGNUP_STATUS",
    GET_SNAP_CSA_LOCATION_LIST: "GET_SNAP_CSA_LOCATION_LIST",
    SET_SNAP_CSA_LOCATION_LIST: "SET_SNAP_CSA_LOCATION_LIST",
    SET_ALL_SNAP_CSA_LOCATION_LIST: "SET_ALL_SNAP_CSA_LOCATION_LIST",
    ADD_SNAP_CSA_LOCATION: "ADD_SNAP_CSA_LOCATION",
    EDIT_SNAP_CSA_LOCATION: "EDIT_SNAP_CSA_LOCATION",
    DELETE_SNAP_CSA_LOCATION: "DELTE_SNAP_CSA_LOCATION",
    GET_REQUEST_HELP: "GET_REQUEST_HELP",
    SET_REQUEST_HELP: "SET_REQUEST_HELP",
    GET_SUBMIT_SIGNUP: "GET_SUBMIT_SIGNUP",
    SET_SUBMIT_SIGNUP: "SET_SUBMIT_SIGNUP",
    GET_SAVE_CSASIGNUP: "GET_SAVE_CSASIGNUP",
    SET_SAVE_CSASIGNUP: "SET_SAVE_CSASIGNUP",
    GET_CSA_DOC: "GET_CSA_DOC",
    SET_CSA_DOC: "SET_CSA_DOC",
    GET_SIGNATURE: "GET_SIGNATURE",
    SET_SIGNATURE: "SET_SIGNATURE",
    GET_CLIENT_AGRMNT_SHR_LST: "GET_CLIENT_AGRMNT_SHR_LST",
    SET_CLIENT_AGRMNT_SHR_LST: "SET_CLIENT_AGRMNT_SHR_LST",
    EXPORT_CLIENT_PAYMENTLIST: "EXPORT_CLIENT_PAYMENTLIST",
    SET_EXPORT_CLIENT_PAYMENTLIST: "SET_EXPORT_CLIENT_PAYMENTLIST",
    EXPORT_CLIENT_AGREEMENT: "EXPORT_CLIENT_AGREEMENT",
    SET_EXPORT_CLIENT_AGREEMENT: "SET_EXPORT_CLIENT_AGREEMENT",
    GET_CANCEL_AGREEMENT_LIST: "GET_CANCEL_AGREEMENT_LIST",
    SET_CANCEL_AGREEMENT_LIST: "SET_CANCEL_AGREEMENT_LIST",
    UPDATE_CANCEL_AGREEMENT_SHARE: "UPDATE_CANCEL_AGREEMENT_SHARE",
    GET_DOUCMENT: "GET_DOUCMENT",
    SET_DOUCMENT: "SET_DOUCMENT",
    GET_VENDOR_SUB_USER_LIST :"GET_VENDOR_SUB_USER_LIST",
    SET_VENDOR_SUB_USER_LIST : "SET_VENDOR_SUB_USER_LIST",
    GET_ADD_USER_LIST:"GET_ADD_USER_LIST",
    SET_ADD_USER_LIST:"SET_ADD_USER_LIST",
    UPDATE_SUB_USER : "UPDATE_SUB_USER",
    DELETE_SUB_USER : "DELETE_SUB_USER ",

};

export function deleteSubUser(payload) {
    return {
        type: actionTypes.DELETE_SUB_USER,
        payload: payload
    };
}

export function updateSubUser(payload) {
    return {
        type: actionTypes.UPDATE_SUB_USER,
        payload: payload
    };
}

export function getVendorSubUserList(payload) {
    return {
        type: actionTypes.GET_VENDOR_SUB_USER_LIST,
        payload: payload
    };
}

export function updateCancelAgreement(payload) {
    return {
        type: actionTypes.UPDATE_CANCEL_AGREEMENT_SHARE,
        payload: payload
    };
}
export function addUserList(payload) {
    return {
        type: actionTypes.GET_ADD_USER_LIST,
        payload: payload
    };
}



export function getDocument(payload) {
    console.log(payload,'getDocumentPayload');
    return {
        type: actionTypes.GET_DOUCMENT,
        payload: payload
    };
}



export function getCancelAgreementList(payload) {
    return {
        type: actionTypes.GET_CANCEL_AGREEMENT_LIST,
        payload: payload
    };
}

export function getCSADoc(payload) {
    return {
        type: actionTypes.GET_CSA_DOC,
        payload: payload
    };
}

export function submitSignup(payload) {
    return {
        type: actionTypes.GET_SUBMIT_SIGNUP,
        payload: payload
    };
}

export function saveCSASignup(payload) {
    return {
        type: actionTypes.GET_SAVE_CSASIGNUP,
        payload: payload
    };
}

export function addSnapCsaLocation(payload) {
    return {
        type: actionTypes.ADD_SNAP_CSA_LOCATION,
        payload: payload
    };
}

export function editSnapCsaLocation(payload) {
    return {
        type: actionTypes.EDIT_SNAP_CSA_LOCATION,
        payload: payload
    };
}

export function deleteSnapCsaLocation(payload) {
    return {
        type: actionTypes.DELETE_SNAP_CSA_LOCATION,
        payload: payload
    };
}
export function getSnapCsaLocationList(payload) {
    return {
        type: actionTypes.GET_SNAP_CSA_LOCATION_LIST,
        payload: payload
    };
}

export function getCsaSignupStatusList(payload) {
    return {
        type: actionTypes.GET_CSASIGNUP_STATUS_LIST,
        payload: payload
    };
}

export function exportCsaSignupStatus(payload) {
    return {
        type: actionTypes.EXPORT_CSASIGNUP_STATUS,
        payload: payload
    };
}

export function updateApproveCsaSignupStatus(payload) {
    return {
        type: actionTypes.UPDATE_APPROVE_CSASIGNUP_STATUS,
        payload: payload
    };
}

export function refreshKey() {
    return {
        type: actionTypes.REFRESH_KEY,
    };
}
export function getClientPartialSignupList(payload) {

    return {
        type: actionTypes.GET_CLIENT_PARTIAL_SIGNUP_LIST,
        payload: payload
    };
}

export function deleteClientPartialSignupList(payload) {
    return {
        type: actionTypes.DELETE_CLIENT_PARTIAL_SIGNUP_LIST,
        payload: payload
    };
}

export function exportClientPartialSignup(payload) {
    return {
        type: actionTypes.EXPORT_CLIENT_PARTIAL_SIGNUP,
        payload: payload
    };
}



export function getVendorShareList(payload) {

    return {
        type: actionTypes.GET_VENDOR_SHARE_LIST,
        payload: payload
    };
}

export function addVendorShareList(payload) {
    return {
        type: actionTypes.ADD_VENDOR_SHARE_LIST,
        payload: payload
    };
}

export function editVendorShareList(payload) {
    return {
        type: actionTypes.EDIT_VENDOR_SHARE_LIST,
        payload: payload
    };
}

export function deleteVendorShareList(payload) {
    return {
        type: actionTypes.DELETE_VENDOR_SHARE_LIST,
        payload: payload
    };
}

export function addShareLang(payload) {
    return {
        type: actionTypes.ADD_SHARE_LANG,
        payload: payload
    };
}

export function editShareLang(payload) {
    return {
        type: actionTypes.EDIT_SHARE_LANG,
        payload: payload
    };
}

export function deleteShareLang(payload) {
    return {
        type: actionTypes.DELETE_SHARE_LANG,
        payload: payload
    };
}

export function getManageCsaVendorList(payload) {

    return {
        type: actionTypes.GET_MANAGE_CSA_VENDOR_LIST,
        payload: payload
    };
}

export function getClientPickupLocationList(payload) {

    return {
        type: actionTypes.GET_CLIENT_PICKUP_LOCATION_LIST,
        payload: payload
    };
}

export function getClientPaymentList(payload) {

    return {
        type: actionTypes.GET_CLIENT_PAYMENT_LIST,
        payload: payload
    };
}

export function exportClientAgreement(payload) {
    return {
        type: actionTypes.EXPORT_CLIENT_AGREEMENT,
        payload: payload
    };
}
export function exportClientPaymentList(payload) {
    return {
        type: actionTypes.EXPORT_CLIENT_PAYMENTLIST,
        payload: payload
    };

}

export function getManageCsaVendorInfo(payload) {

    return {
        type: actionTypes.GET_MANAGAE_CSA_VENDOR_INFO,
        payload: payload
    };
}

export function getClientAgreementList(payload) {
    return {
        type: actionTypes.GET_CLIENT_AGREEMENT_LIST,
        payload: payload
    };
}

export function getPickupLocationList(payload) {
    return {
        type: actionTypes.GET_PICKUP_LOCTION_LIST,
        payload: payload
    };
}

export function getExportClientAgreementList(payload) {
    return {
        type: actionTypes.GET_EXPORT_CLIENT_AGREEMENT_LIST,
        payload: payload
    };
}
export function getCsaVendorList(payload) {
    return {
        type: actionTypes.GET_CSA_VENDOR_LIST,
        payload: payload
    };
}

export function getVendorInfo(payload) {
    return {
        type: actionTypes.GET_CSA_VENDOR_INFO,
        payload: payload
    };
}


export function getClientInfo(payload) {
    return {
        type: actionTypes.GET_CLIENT_INFO,
        payload: payload
    };
}
export function getSnapShare(payload) {
    return {
        type: actionTypes.GET_SNAP_SHARE,
        payload: payload
    };
}

export function getRequestHelp(payload) {
    return {
        type: actionTypes.GET_REQUEST_HELP,
        payload: payload
    };
}

export function getSnapHipAmount(payload) {

    return {
        type: actionTypes.GET_SNAP_HIP_AMOUNT,
        payload: payload
    };
}

export function getCSAShareList(payload) {
    return {
        type: actionTypes.GET_CSA_SHARE_LIST,
        payload: payload
    };
}

export function getClientAgrmntShrLst(payload) {
    return {
        type: actionTypes.GET_CLIENT_AGRMNT_SHR_LST,
        payload: payload
    };
}

export function getSignature(payload) {
    console.log(payload, "hello this is share");
    return {
        type: actionTypes.GET_SIGNATURE,
        payload: payload
    };
}
