import axiosInstance from "../interceptor";
import { takeLatest, call, put } from "redux-saga/effects"; //takeEvery
import { actionTypes } from "../actionTypes/administration";
import { API_URL, PUBLIC_API, AUTH_API } from "../../config";
import { popUpAlert } from "../../utils/globalMethods";
import { ExportFarmersMarket } from "../../pages/common/components/exportExcel";
import toast from "react-hot-toast";
import { removeLoadingItem, setLoadingItem } from "../actionTypes/loader";

function* sendFeedback(props) {
  let url = `${PUBLIC_API}/feedback`;
  try {
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    yield put({
      type: actionTypes.SEND_FEEDBACK_SUCCESS,
      payload: result.data,
    });
  } catch {
    popUpAlert({
      title: "Error",
      text: "Failed to send Feedback!",
      icon: "error",
      timer: 1500,
      showCancelButton: false,
      showConfirmButton: false,
    });
    //   console.log("Failed");
  }
}
function* getFarmersMarketSaga(props) {
  let url = `${PUBLIC_API}/farmersmarket/list`;
  try {
    yield put(setLoadingItem("get_farmer_markets"));
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    if (result.data.code === "200") {
      yield put({
        type: actionTypes.SET_FARMERS_MARKET,
        payload: Object.assign(result.data, { loading: false }),
      });
    } else {
      yield put({
        type: actionTypes.SET_FARMERS_MARKET,
        payload: { data: [], total: 0, loading: false },
      });
    }
  } catch(e) {
    console.log(e);
  } finally {
    yield put(removeLoadingItem("get_farmer_markets"));
  }
}

function* getFarmersProductsSaga(props) {
  let url = `${AUTH_API}/farmers/get/productsList`;
  try {
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    if (result.status === 200) {
      yield put({
        type: actionTypes.GET_FARMERS_PRODUCTS,
        payload: Object.assign(result.data, { loading: false }),
      });
    } else {
      yield put({
        type: actionTypes.GET_FARMERS_PRODUCTS,
        payload: { data: [], total: 0, loading: false },
      });
    }
  } catch {
    console.log("Failed");
  }
}

function* exportFarmersMarketSaga() {
  let url = `${API_URL}/farmer/view`;
  try {
    const uri = url;
    const body = {};
    const result = yield call(axiosInstance.post, uri, body);
    yield put({
      type: actionTypes.SET_EXPORT_FARMERS_MARKET,
      payload: result.data.data,
    });
    ExportFarmersMarket(result.data.data);
  } catch {
    console.log("Failed");
  }
}

function* addFarmersMarketSaga(props) {
  let url = `${API_URL}/farmersmarket/insert`;
  try {
    const uri = url;
    yield call(axiosInstance.post, uri, props.payload);
    yield put({ type: actionTypes.REFRESH_KEY });
    popUpAlert({
      title: "Successfully",
      text: "Farmer market has been Added successfully!",
      icon: "success",
      timer: 1500,
      showCancelButton: false,
      showConfirmButton: false,
    });
  } catch {
    popUpAlert({
      title: "Error",
      text: "Failed to Process the request!",
      icon: "error",
      timer: 1500,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
}

function* editFarmersMarketSaga(props) {
  let url = `${API_URL}/farmersmarket/update`;
  try {
    const uri = url;
    yield call(axiosInstance.post, uri, props.payload);
    yield put({ type: actionTypes.REFRESH_KEY });
    popUpAlert({
      title: "Successfully",
      text: "Farmer market has been updated successfully!",
      icon: "success",
      timer: 1500,
      showCancelButton: false,
      showConfirmButton: false,
    });
  } catch {
    popUpAlert({
      title: "Error",
      text: "Failed to Process the request!",
      icon: "error",
      timer: 1500,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
}

function* bulkUpdateFarmersMarketsaga(props) {
  let url = `${API_URL}/farmer/import`;
  try {
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    if (result.data.code === "200") {
      yield put({ type: actionTypes.REFRESH_KEY });
      popUpAlert({
        title: "Successfully",
        text: "File imported successfully, within few seconds of time data will get updated!",
        icon: "success",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    } else {
      popUpAlert({
        title: "Error",
        text: "Failed to Process the request!",
        icon: "error",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  } catch {
    popUpAlert({
      title: "Error",
      text: "Failed to Process the request!",
      icon: "error",
      timer: 1500,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
}

function* deleteFarmersMarketSaga(props) {
  let url = `${API_URL}/farmersmarket/delete`;
  try {
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    if (result.data.code == 200) {
      popUpAlert({
        title: "Successfully",
        text: "Farmer market has been deleted successfully!",
        icon: "success",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    } else {
      popUpAlert({
        title: "Error",
        text: "Failed to delete Farmer market!",
        icon: "error",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
    yield put({ type: actionTypes.REFRESH_KEY });
  } catch {
    popUpAlert({
      title: "Error",
      text: "Failed to delete Farmer market!",
      icon: "error",
      timer: 1500,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
}

function* getAccessLogsSaga(props) {
  let url = `${API_URL}/audit/accesslogs`;
  try {
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    yield put({
      type: actionTypes.SET_ACCESS_LOGS,
      payload: Object.assign(result.data, { loading: false }),
    });
  } catch {
    console.log("Failed");
  }
}

function* getLoginLogsSaga(props) {
  let url = `${API_URL}/audit/loginlogs`;
  try {
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    yield put({
      type: actionTypes.SET_LOGIN_LOGS,
      payload: Object.assign(result.data, { loading: false }),
    });
  } catch {
    console.log("Failed");
  }
}

function* getUserListSaga(props) {
  let url = `${API_URL}/user/list`;
  try {
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    yield put({
      type: actionTypes.SET_USER_LIST,
      payload: Object.assign(result.data, { loading: false }),
    });
  } catch {
    console.log("Failed");
  }
}

function* addUserListSaga(props) {
  let url = `${API_URL}/user/add`;
  try {
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    yield put({ type: actionTypes.REFRESH_KEY });
    if (result.data.code == 200) {
      popUpAlert({
        title: "Successfully",
        text: "User added successfully!",
        icon: "success",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    } else {
      popUpAlert({
        title: "Error",
        text:
          "message" in result.data
            ? result.data.message
            : "Failed to Process the request!",
        icon: "error",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  } catch {
    popUpAlert({
      title: "Error",
      text: "Failed to Process the request!",
      icon: "error",
      timer: 1500,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
}

function* editUserListSaga(props) {
  let url = `${API_URL}/user/update`;
  try {
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    if (result.data.code == 200) {
      popUpAlert({
        title: "Successfully",
        text: " User updated successfully!",
        icon: "success",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
      yield put({ type: actionTypes.REFRESH_KEY });
    } else {
      popUpAlert({
        title: "Error",
        text:
          "message" in result.data
            ? result.data.message
            : "Failed to Process the request!",
        icon: "error",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  } catch {
    popUpAlert({
      title: "Error",
      text: "Failed to Process the request!",
      icon: "error",
      timer: 1500,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
}

function* deleteUserListSaga(props) {
  let url = `${API_URL}/user/delete`;
  try {
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);

    if (result.data.code == 200) {
      popUpAlert({
        title: "Success",
        text: "User has been removed from the application",
        icon: "success",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
      yield put({ type: actionTypes.REFRESH_KEY });
    } else {
      popUpAlert({
        title: "Error",
        text: "Failed to delete the user!",
        icon: "error",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  } catch {
    popUpAlert({
      title: "Error",
      text: "Failed to delete the user!",
      icon: "error",
      timer: 1500,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
}

function* getCoordinatesListSaga(props) {
  let url = `${API_URL}/manage/stores/list`;
  try {
    setLoadingItem("stores-list");
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    yield put({
      type: actionTypes.SET_MANAGE_COORDINATES,
      payload: Object.assign(result.data, { loading: false }),
    });
  } catch {
    console.log("Failed");
  } finally {
    removeLoadingItem("stores-list");
  }
}

function* editCoordinatesSaga(props) {
  let url = `${API_URL}/update/vendor/store/location`;
  try {
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    if (result.data.code == 200) {
      popUpAlert({
        title: "Success",
        text: "Coordinates updated successfully",
        icon: "success",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
      yield put({ type: actionTypes.REFRESH_KEY });
    } else {
      popUpAlert({
        title: "Error",
        text: "Failed to update Coordinates!",
        icon: "error",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  } catch {
    popUpAlert({
      title: "Error",
      text: "Failed to Process the request!",
      icon: "error",
      timer: 1500,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
}

export const watchSendFeedback = function* () {
  yield takeLatest(actionTypes.SEND_FEEDBACK, sendFeedback);
};

export const watchGetFarmersMarketSaga = function* () {
  yield takeLatest(actionTypes.GET_FARMERS_MARKET, getFarmersMarketSaga);
};

export const watchGetFarmersProductsSaga = function* () {
  yield takeLatest(actionTypes.GET_FARMERS_PRODUCTS, getFarmersProductsSaga);
};

export const watchExportFarmersMarketSaga = function* () {
  yield takeLatest(actionTypes.EXPORT_FARMERS_MARKET, exportFarmersMarketSaga);
};

export const watchAddFarmersMarketSaga = function* () {
  yield takeLatest(actionTypes.ADD_FARMERS_MARKET, addFarmersMarketSaga);
};

export const watchEditFarmersMarketSaga = function* () {
  yield takeLatest(actionTypes.EDIT_FARMERS_MARKET, editFarmersMarketSaga);
};

export const watchBulkUpdateFarmersMarketsaga = function* () {
  yield takeLatest(
    actionTypes.BULK_UPDATE_FARMERS_MARKET,
    bulkUpdateFarmersMarketsaga
  );
};

export const watchDeleteFarmersMarketSaga = function* () {
  yield takeLatest(actionTypes.DELETE_FARMERS_MARKET, deleteFarmersMarketSaga);
};

export const watchGetAccessLogsSaga = function* () {
  yield takeLatest(actionTypes.GET_ACCESS_LOGS, getAccessLogsSaga);
};

export const watchGetLoginLogsSaga = function* () {
  yield takeLatest(actionTypes.GET_LOGIN_LOGS, getLoginLogsSaga);
};

export const watchGetUserListSaga = function* () {
  yield takeLatest(actionTypes.GET_USER_LIST, getUserListSaga);
};

export const watchAddUserListSaga = function* () {
  yield takeLatest(actionTypes.ADD_USER_LIST, addUserListSaga);
};

export const watchEditUserListSaga = function* () {
  yield takeLatest(actionTypes.EDIT_USER_LIST, editUserListSaga);
};

export const watchDeleteUserListSaga = function* () {
  yield takeLatest(actionTypes.DELETE_USER_LIST, deleteUserListSaga);
};

export const watchGetCoordinatesListSaga = function* () {
  yield takeLatest(actionTypes.GET_MANAGE_COORDINATES, getCoordinatesListSaga);
};

export const watchEditCoordinatesSaga = function* () {
  yield takeLatest(actionTypes.EDIT_COORDINATES, editCoordinatesSaga);
};
