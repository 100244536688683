import React, { useState, useEffect } from 'react';
import "../../styles/landingpage.scss";
import HomePage from './HomePage';
import LoginDialog from "./LoginDialog";
import RegistrationDialog from './RegistrationDialog';

const LandingHomePage = () => {
  const [openLogin, setLoginOpen] = useState(false);  
  const [signupOpen, setSignupOpen] = useState(false);

  const openLoginDialog = () => {
    setLoginOpen(true);
    setSignupOpen(false);
  };

  const openSignupDialog = () => {
    setSignupOpen(true);
    setLoginOpen(false);
  };

  const handleClose = () => {
    setLoginOpen(false);
    setSignupOpen(false)
  };
  
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);

  return (
    <>
      <HomePage handleLoginDialog={openLoginDialog} />
      {openLogin && (
        <LoginDialog
          open={openLogin}
          handleClose={handleClose}
          handleSignupDialog={openSignupDialog}
        />
      )}
      {signupOpen && (
        <RegistrationDialog
          open={signupOpen}
          handleClose={handleClose}
          handleLoginDialog={openLoginDialog}
        />
      )}
    </>
  );
}

export default LandingHomePage