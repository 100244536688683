import React, {useEffect, useState} from "react";
import Form from 'react-bootstrap/Form';
import {Row, Col} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import '../../styles/home.scss';
import {connect} from "react-redux";
import { mapStateToProps } from "../../redux/reducers";
import {addComment, deleteComment, updateComment, getCommentList} from "../../redux/actionTypes/comments";
import PropTypes from "prop-types";
import { Box,Typography } from "@mui/material";
import { popUpAlert, convertUTCDateToLocalDate } from "../../utils/globalMethods";

const CommentModal = (props) => {

    const [commentText, setCommentText] = useState("");
    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedComment, setSelectedComment] = useState({});

    const getCommentsList = () => {
        const payload = {
            "id": props.vendorId,
            "vendorType":props.vendorType
        }
        props.getCommentList(payload);
    }

    useEffect(() => {
        getCommentsList();
    },[]);

    // useEffect(() => {
    //     getCommentsList();
    // }, [props.administration.refreshKey])

    const onAddComment = () => {
        setIsAdd(true);
        setIsEdit(false);
        if (commentText.trim()) {
            const payload = {
                id: props.vendorId,
                vendorType: props.vendorType,
                "notes": commentText
            }
            props.addComment(payload);
            setCommentText(" "); 
        }else{
            setCommentText(""); 
        }
    }

    const onDeleteComment = (item) => {
        popUpAlert({
            title: "Are you sure?",
            text: "Your comment will be removed for this vendor.",
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonClass: "btn-danger",
            confirmButtonText: "Yes, delete it!",
            closeOnConfirm: false,
            onConfirmed: true,
            handleConfirm:()=>handleDelete(item),
          });
    }

    const handleDelete = (item) => {
        const payload = {
            id: props.vendorId,
            vendorType: props.vendorType,
            setId: item.setId,
        }
        props.deleteComment(payload);
    }

    const onEditComment = (item) => {
        setIsAdd(false);
        setIsEdit(true);
        setCommentText(item?.notes);
        setSelectedComment(item);
    }
    // eslint-disable-next-line no-unused-vars
    const onUpdateComment = (item)=>{
        setIsAdd(false);
        setIsEdit(false);
        const payload = {
            id: selectedComment?.id,
            vendorType: props.vendorType,
            setId: selectedComment?.setId,
            "notes": commentText
        }
        props.updateComment(payload);
        setCommentText("");
    }

    const handleChange = (e) => {
        setCommentText(e.target.value);
    }

    return (
        <div className="form-block">
            <Form noValidate>
                <Row>
                    <Col md={ isEdit ? 9 : 10} sm={12} className="mb-2">
                        { isAdd && !commentText && (                      
                            <Typography component="p" className={"error"}>
                                <i className="fa fa fa-info-circle ml-2" aria-hidden="true"></i>&nbsp;Please fill in this field
                            </Typography>
                        )}
                        <Form.Group as={Col} controlId="formDescription">
                            <Form.Control
                                as="textarea"
                                placeholder="Enter your comments..."
                                style={{ height: '114px'}}
                                value={commentText}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={ isEdit ? 3 : 2} className={'flex-column mt-auto mb-2 d-flex col-sm-12'}>
                        { isEdit && (
                            <Box display="flex" sx={{ paddingTop: { sm: "10px"}, alignContent: 'flex-end', justifyContent:"flex-start"}}>
                                <Button variant="primary" onClick={() =>{
                                    setIsEdit(false);
                                    setCommentText("");
                                }} className="btn-align grey-btn">
                                    <i className="mr-2"></i>{'Cancel'}
                                </Button>&nbsp;&nbsp;
                                <Button variant="primary" onClick={() => onUpdateComment()} className="btn-align">
                                    <i className="mr-2"></i>{'Update'}
                                </Button>
                            </Box>
                        )}
                        { !isEdit && (
                            <Box display="flex" sx={{ paddingTop: { sm: "10px"}, alignContent: 'flex-end', justifyConten:"flex-start"}}>
                                <Button variant="primary" onClick={() => onAddComment()} className="mt-auto btn-align">
                                    <i className="fa fa-plus mr-2"></i>{'Add'}
                                </Button>
                            </Box>
                        )}
                    </Col>
                </Row>
                {/* <Row className='row'> */}
                        {props.commentList?.list?.map((item, index) =>
                            <Row className='comment_section' key={index}>
                                <Col md={1}><i style={{fontSize: '40px'}} className="fa fa-user-circle-o text-color"
                                               aria-hidden="true"></i>  </Col>
                                <Col md={8} className={'mb-0 ml-2'}>
                                    <h5 style={{marginBottom: '-2px'}}>
                                        <b>{item.firstNam} {item.lastNam}</b>
                                    </h5>
                                    <small style={{color: '#999'}}><i className="fa fa-clock-o" aria-hidden="true"></i> {convertUTCDateToLocalDate(item.lastUpdtTs)}</small><p className="comments-pre">{item.notes}</p></Col>
                                <Col md={2} className={'text-end'}>
                                    <span className='cursor sortColorGreen mr-2' title="Edit" onClick={() => onEditComment(item)}><i className="fa fa-edit" aria-hidden="true"></i></span>
                                    <span className='cursor sortColorGreen' title="Delete" onClick={() => onDeleteComment(item)}><i className="fa fa-trash-o" aria-hidden="true"></i></span>
                                </Col>
                            </Row>
                        )}
                {/* </Row> */}
            </Form>
        </div>
    );
};

CommentModal.propTypes = {
    getCommentList: PropTypes.func.isRequired,
    commentList: PropTypes.array,
    deleteComment: PropTypes.func.isRequired,
    addComment: PropTypes.func.isRequired,
    updateComment: PropTypes.func.isRequired,
    vendorId: PropTypes.number,
    administration:PropTypes.object,
    vendorType: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCommentList: (payload) => dispatch(getCommentList(payload)),
        addComment: (payload) => dispatch(addComment(payload)),
        deleteComment: (payload) => dispatch(deleteComment(payload)),
        updateComment: (payload) =>  dispatch(updateComment(payload))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CommentModal);