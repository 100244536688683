import {
  FETCH_DEMOGRAPHIC_DATA,
  FETCH_PROGRAM_INFO,
  FETCH_DEMOGRAPHIC_DASHBOARD_DATA,
} from "../actionTypes/seniorInfo";

const initialState = {
  demoGraphicData: {},
  programInfo: {},
  demographicDashboardData: {},
};

export const seniorInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEMOGRAPHIC_DATA:
      return Object.assign({}, state, { demoGraphicData: action.payload });
    case FETCH_PROGRAM_INFO:
      return Object.assign({}, state, { programInfo: action.payload });
    case FETCH_DEMOGRAPHIC_DASHBOARD_DATA:
      return Object.assign({}, state, { demographicDashboardData: action.payload });
    default:
      return state;
  }
};
