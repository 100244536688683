import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  FormHelperText,
} from "@mui/material";
import axios from "axios";

import FileUploadContent from "./FileUploadContent";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { documnetURL, GET_FILE } from "../../pages/senior/constant";
import toast from "react-hot-toast";
import useLoader from "../../hooks/useLoader";
import DataGridTable from "../DataGrid";
import ActionButtons from "../ActionButtons";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import {
  docFileUpload,
  documentDelete,
  documentEdit,
  DownloadDocument,
  getDocumentByCaseID,
  getDocumentByDocumentID,
} from "../../utils/services/fileUpload/apiRequests";
import getMimeType from "../../utils/services/fileType";
import base64ToUint8Array from "../../utils/helpers";
import * as yup from "yup";
import { useStyles } from "../../utils/multi-utils";

const DocumentUploadForm = ({ docOpen, setDocOpen, payload, section }) => {
  const { openLoader, closeLoader } = useLoader();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileButton, setFileButton] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [deleteSingleData, setDeleteSingleData] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [isEdited, setIsEdited] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (docOpen) {
      api();
    }
  }, [docOpen, deleteSingleData]);

  const api = async () => {
    try {
      openLoader("doc_detail_upload");
      const res = await getDocumentByCaseID("AP0006154");
      if (res.status === 200) {
        setTableData(res?.data);
      } else {
        throw new Error("Failed to fetch document data");
      }
    } catch (error) {
      console.error("API error:", error);
      toast.error("Failed to load document data.");
    } finally {
      closeLoader("doc_detail_upload");
    }
  };

  const dropDocument = [
    { name: "Income", value: "1" },
    { name: "Identity", value: "2" },
  ];

  const formvalidation = yup.object({
    documentTypeCd: yup.string().required("Please select Document Type"),
    fileName: yup.string().required("Please enter File Name"),
  });

  const formik = useFormik({
    initialValues: {
      documentTypeCd: "",
      fileName: "",
    },
    validationSchema: formvalidation,
    onSubmit: (values) => {
      if (section?.id === "farmersMarket") {
        handleClose();
      } else {
        handleSubmit(values);
      }
    },
  });

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      const isFileChanged = selectedFile !== editData?.file;
      const isDocTypeChanged =
        formik.values.documentTypeCd !== editData?.docType;
      setIsEdited(isFileChanged || isDocTypeChanged);
    }
  }, [formik.values.documentTypeCd, selectedFile, editData]);

  const handleSubmit = async (values) => {
    const initialState = {
      docCat: "kfresh",
      docType: values.documentTypeCd,
      sourceSystem: "KFresh",
      fileName: values.fileName,
      fileLocation: "",
      files: selectedFile,
      documentId: editData?.documentId ?? "",
      ...payload,
    };

    const FilesPayload = (filePayload) => {
      const formData = new FormData();
      Object.keys(filePayload).map((key) => {
        formData.append(key, filePayload[key]);
      });
      return formData;
    };

    let filePayload = FilesPayload(initialState);
    try {
      openLoader("doc_detail_upload");
      const res =
        Object.keys(editData).length !== 0
          ? await documentEdit(filePayload, editData?.documentId)
          : await docFileUpload(filePayload);
      if (res.status === 200) {
        closeLoader("doc_detail_upload");
        Object.keys(editData).length !== 0
          ? toast.success("File Updated Successfully!")
          : toast.success("File Uploaded Successfully!");
        setTimeout(() => {
          handleClose();
          closeLoader("doc_detail_upload");
        }, 2000);
      }
    } catch (error) {
      toast.error("File Uploading failed!");
    } finally {
      closeLoader("doc_detail_upload");
    }
  };

  const handleFileChange = (file) => {
    if (file) {
      if (file.type !== "application/pdf") {
        toast.error("Only PDF files are allowed.");
        setSelectedFile(null);
        return;
      }
      setSelectedFile(file);
      setFileButton(false);
    } else {
      setSelectedFile(null);
    }
  };

  const handleReset = () => {
    formik.resetForm({
      values: {
        documentTypeCd: "",
        fileName: "",
      },
    });
    setSelectedFile(null);
    setFileButton(true);
    setIsEdited(false);
  };

  const handleClose = () => {
    setDocOpen(false);
    handleReset();
    setEditData({});
  };

  const handleGetDocumentData = async (documentId) => {
    try {
      openLoader("doc_detail_upload");
      const res = await getDocumentByDocumentID(documentId);
      if (res && res?.data) {
        const fileName = res?.data?.userFileName;
        const fileType = getMimeType(fileName);
        const fileData = base64ToUint8Array(res?.data?.fileBytes);
        const fileBlob = new Blob([fileData], { type: fileType });
        const file = new File([fileBlob], fileName, { type: fileBlob.type });
        setEditData({ ...res?.data, file, documentId } || {});
        setSelectedFile(file);
        formik.setFieldValue("documentTypeCd", res?.data?.docType || "");
        formik.setFieldValue("fileName", fileName || "");
        setFileButton(false);
      }
    } catch (error) {
      closeLoader("doc_detail_upload");
      toast.error("Failed Edit a File!");
    } finally {
      closeLoader("doc_detail_upload");
    }
  };

  const handleDeleteDocument = (documentId) => {
    setDeleteSingleData(documentId);
    setIsDeleteOpen(true);
  };

  const handleDeleteData = async () => {
    try {
      openLoader("doc_detail_upload");
      const res = await documentDelete(deleteSingleData);
      if (res?.status === 200) {
        toast.success("File Deleted Successfully!");
        setIsDeleteOpen(false);
        await api();
      }
    } catch (error) {
      closeLoader("doc_detail_upload");
      toast.error("Failed Edit a File!");
    } finally {
      closeLoader("doc_detail_upload");
      setIsDeleteOpen(false);
    }
  };

  const handleDownloadFile = async (documentId) => {
    if (!documentId) {
      toast.error("No PDF URL available");
      return;
    }
    try {
      openLoader("doc_detail_upload");
      const response = await DownloadDocument(documentId, "AP0006154");
      if (response.status === 200 && response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(blob);
        window.open(pdfUrl, "_blank");
        toast.success("File Downloaded Successfully!");
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      toast.error("Failed to download PDF");
    } finally {
      closeLoader("doc_detail_upload");
    }
  };

  const columns = [
    {
      field: "docType",
      headerName: "Document Type",
      flex: 1,
    },
    {
      field: "userFileName",
      headerName: "File Description",
      flex: 1,
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        const documentId = params?.row?.documentId;
        return (
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <ActionButtons
              onEdit={() => handleGetDocumentData(documentId)}
              onDelete={() => handleDeleteDocument(documentId)}
            />
            <IconButton onClick={() => handleDownloadFile(documentId)}>
              <DownloadOutlinedIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Container
        sx={{
          height: "auto",
          boxShadow: "0px 0px 2px 2px rgba(0,0,0,0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px 10px 10px 0px",
        }}
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FileUploadContent
              handleChange={handleFileChange}
              selectedFile={selectedFile}
              // isAdd={isAdd}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.typography}>
              Document Type
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel id="doc-type">Document Type</InputLabel>
              <Select
                labelId="doc-type"
                id="select-doc-type"
                label="Document Type"
                value={formik.values.documentTypeCd}
                name="documentTypeCd"
                // onChange={(event) => handeChangeCallApi(event)}
                onChange={formik.handleChange}
                error={
                  formik.touched.documentTypeCd &&
                  Boolean(formik.errors.documentTypeCd)
                }
                helperText={
                  formik.touched.documentTypeCd && formik.errors.documentTypeCd
                }
              >
                {(dropDocument || [])?.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.name}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error>
                {formik.errors.documentTypeCd}
              </FormHelperText>
            </FormControl>
            <Typography className={classes.typography}>
              File Description
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              label="File Description"
              fullWidth
              margin="normal"
              name="fileName"
              value={formik.values.fileName}
              onChange={formik.handleChange}
              error={formik.touched.fileName && Boolean(formik.errors.fileName)}
              helperText={formik.touched.fileName && formik.errors.fileName}
            />
            <div style={{ float: "none", left: "0" }}>
              {/* <Button variant="outlined">Reset</Button> */}
              <Button
                variant="contained"
                style={{ marginTop: "10px" }}
                type="submit"
                disabled={
                  Object.keys(editData).length !== 0 ? !isEdited : fileButton
                }
              >
                {Object.keys(editData).length !== 0
                  ? "Update file"
                  : "Upload file"}
              </Button>
              <Button
                // className="okay-btn"
                variant="contained"
                onClick={handleClose}
                sx={{
                  mr: "2rem",
                  width: "8rem",
                  marginLeft: "30px",
                  marginTop: "7px",
                }}
              >
                Close
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: 4 }}>
        <DataGridTable data={tableData} columns={columns} />
      </Container>
      <Dialog
        open={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
          setDeleteSingleData("");
        }}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDeleteOpen(false);
              setDeleteSingleData("");
            }}
            variant="outlined"
            size="medium"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteData}
            variant="outlined"
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DocumentUploadForm.propTypes = {
  docOpen: PropTypes.bool,
  setDocOpen: PropTypes.bool,
  payload: PropTypes.object,
  initData: PropTypes.object,
  section: PropTypes.object,
};

export default DocumentUploadForm;
