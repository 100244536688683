import {  all } from "redux-saga/effects"; //fork,
import {
  watchGetArea,
  watchGetCityList,
  watchGetDayOption,
  watchGetSpecialHourData,
  watchGetMapStoresList,
  watchGetStoresReport,
  watchGetMapData,
  watchGetMonthOption,
  watchGetOrderOption,
  watchGetType,
  watchGetVendorList,
  watchGetStoreDetails,
} from "./mapdata";
import {
  watchGetFarmersMarketSaga,
  watchGetAccessLogsSaga,
  watchGetLoginLogsSaga,
  watchGetUserListSaga,
  watchGetCoordinatesListSaga,
  watchAddFarmersMarketSaga,
  watchEditFarmersMarketSaga,
  watchDeleteFarmersMarketSaga,
  watchAddUserListSaga,
  watchEditUserListSaga,
  watchDeleteUserListSaga,
  watchEditCoordinatesSaga,
  watchExportFarmersMarketSaga,
  watchBulkUpdateFarmersMarketsaga,
  watchSendFeedback,
  watchGetFarmersProductsSaga,
} from "./administration";
import { 
    watchPostUser,
    watchPutUser, watchGetRegCityList, watchGetLocType, 
    watchGetLocTypeQues,watchPostProgram,watchGetRegMonthOption,watchGetRegDayOption, watchSendSupportEmail
 } from "./registration";

 
// import { watchGetLocType, watchGetLocTypeQues } from "./dropdown";

import {
    watchGetVendorId, watchGetUserDetails, watchUpdateVendorDetails, watchUpdatePublicDetails, watchGetVendorLocDetails, //watchRemoveVendorStore,
    watchUpdateProgram, watchLocShowHide, watchGetManageVendorListSaga, watchManageDeleteVendorDetailsSaga, watchGetFollowVendorDetailsSaga,
    watchUpdateVendorBulkSaga, watchGetVendorHistoryListSaga, watchGetVendorInfoListSaga, watchVerifyVendorSaga, watchGetVendorStoreListSaga, watchExportVendorReportSaga,
    watchExportVendorLoginReportSaga, watchExportVendorLateUpdateReportSaga, watchGetMyStoreDetailsSaga, watchRemoveVendorStoreSaga, watchUpdateFollowVendorDetailsSaga, 
    watchShowLocationTypeSaga, watchGetVendorHistoryDetailSaga,watchUpdatePrimaryEmailSaga
} from "./vendordetails";

import {
  watchGetStoreStatsSage,
  watchGetVendorStatsSaga,
  watchGetSeniorStatsSaga,
  watchGetMarketStatsSaga,
  watchGetSeniorCountyStatsSaga,
  watchGetMarketCountyStatsSaga,
} from "./adminDashboard";
import { watchGetAdminTokenSaga, watchGetUserTokenSaga, watchRefreshAdminTokenSaga, watchGetAuthUserDetails , watchTokenRevokeSaga } from "./auth";
import {watchAddCommentSaga, watchDeleteCommentSaga, watchGetCommentListSaga, watchUpdateCommentSaga} from "./comments";
import {
    watchGetCSAShareListSaga, watchGetCSAVendorInfoSaga, watchGetCSAVendorListSaga, watchGetClientInfoSaga,
    watchGetVendorShareListSaga, watchGetManageCSAVendorListSaga, watchGetClientPartialSignupListSaga, watchGetClientAgreementListSaga, watchGetPickupLocationListSaga,
    watchGetClientPaymentListSaga, watchGetCientPickupLocationListSaga, watchAddVendorShareListSaga, watchExportClientPartialSignupSaga,
    watchEditVendorShareListSaga, watchDeleteVendorShareListSaga, watchGetCsaSignupStatusListSaga, watchGetSnapShareSaga, watchGetSnapHipAmount,
    watchDeleteClientPartialSignupListSaga, watchExportCsaSignupStatusSaga, watchUpdateApproveCsaSignupStatusSaga,
    watchGetSnapCsaLocationListSaga, watchAddSnapCsaLocationSaga, watchGetRequestHelpSaga, watchSubmitSignupSaga, watchGetCSADocSaga,
    watchGetSignatureSaga, watchSaveCSASignupSaga, watchGetClientAgrmntShrLstSaga, watchEditSnapCSALocationListSaga,
    watchDeleteSnapCSALocationSaga, watchExportClientPaymentListSaga,watchGetCancelAgreementListSaga, watchGetExportClientAgreementListSaga, 
    watchCancelUpdateAgreementShareSaga,watchGetDocumentSaga,watchGetVendorSubUserListSaga,watchGetAddUserListSaga,watchUpdateSubUserSaga,
    watchDeleteSubVendorSaga, watchAddShareLangSaga, watchEditShareLangSaga, watchDeleteShareLangSaga
} from "./csaSignup";
export default function* () {
    // console.log("watchGetLanguageData");
    // yield all([
    //     fork(watchGetMapData()),
    //     fork(watchGetLanguageData()),
    //     fork(watchUserRegistration())
    // ]);
    yield all([
        watchGetMapData(),
        watchPostUser(),
        watchPutUser(),
        watchGetLocType(),
        watchGetLocTypeQues(),
        watchPostProgram(),
        watchGetCityList(),
        watchGetArea(),
        watchGetType(),
        watchGetOrderOption(),
        watchGetMonthOption(),
        watchGetDayOption(),
        watchGetVendorList(),
        watchGetRegCityList(),
        watchGetRegMonthOption(),
        watchGetRegDayOption(),
        watchSendSupportEmail(),
        // watchGetVendorPublic(),
        watchGetVendorId(),
        // watchGetVendorStoreDetails(),
        watchGetUserDetails(),
        watchUpdateVendorDetails(),
        watchUpdatePublicDetails(),
        // watchGetVendorStoreDetails(),
        watchGetMapStoresList(),
        watchGetStoresReport(),
        watchGetStoreDetails(),
        watchGetSpecialHourData(),
        watchGetVendorLocDetails(),
        // watchRemoveVendorStore(),
        watchUpdateProgram(),
        watchLocShowHide(),
        watchGetFarmersMarketSaga(),
        watchGetFarmersProductsSaga(),
        watchExportFarmersMarketSaga(),
        watchAddFarmersMarketSaga(),
        watchEditFarmersMarketSaga(),
        watchBulkUpdateFarmersMarketsaga(),
        watchDeleteFarmersMarketSaga(),
        watchGetAccessLogsSaga(),
        watchGetLoginLogsSaga(),
        watchGetUserListSaga(),
        watchAddUserListSaga(),
        watchEditUserListSaga(),
        watchDeleteUserListSaga(),
        watchGetCoordinatesListSaga(),
        watchGetStoreStatsSage(),
        watchGetVendorStatsSaga(),
        watchGetSeniorStatsSaga(),
        watchGetSeniorCountyStatsSaga(),
        watchGetMarketCountyStatsSaga(),
        watchGetMarketStatsSaga(),
        watchEditCoordinatesSaga(),
        watchGetManageVendorListSaga(),
        watchManageDeleteVendorDetailsSaga(),
        watchGetFollowVendorDetailsSaga(),
        watchUpdateFollowVendorDetailsSaga(),
        watchUpdateVendorBulkSaga(),
        watchExportVendorReportSaga(),
        watchExportVendorLateUpdateReportSaga(),
        watchExportVendorLoginReportSaga(),
        watchGetVendorHistoryListSaga(),
        watchGetVendorInfoListSaga(),
        watchVerifyVendorSaga(),
        watchGetMyStoreDetailsSaga(),
        watchGetVendorStoreListSaga(),
        watchGetAdminTokenSaga(),
        watchGetUserTokenSaga(),
        watchRefreshAdminTokenSaga(),
        watchGetAuthUserDetails(),
        watchGetCommentListSaga(),
        watchAddCommentSaga(),
        watchUpdateCommentSaga(),
        watchDeleteCommentSaga(),
        watchRemoveVendorStoreSaga(),
        watchTokenRevokeSaga(),
        watchSendFeedback(),
        watchGetVendorShareListSaga(),
        watchGetManageCSAVendorListSaga(),
        watchGetClientPaymentListSaga(),
        watchGetCientPickupLocationListSaga(),
        watchGetClientPartialSignupListSaga(),
        watchAddVendorShareListSaga(),
        watchExportClientPartialSignupSaga(),
        watchEditVendorShareListSaga(),
        watchDeleteVendorShareListSaga(),
        watchAddShareLangSaga(), 
        watchEditShareLangSaga(), 
        watchDeleteShareLangSaga(),
        watchGetCsaSignupStatusListSaga(),
        watchGetSnapShareSaga(),
        watchGetSnapHipAmount(),
        watchDeleteClientPartialSignupListSaga(),
        watchExportCsaSignupStatusSaga(),
        watchUpdateApproveCsaSignupStatusSaga(),
        watchGetSnapCsaLocationListSaga(),
        watchAddSnapCsaLocationSaga(),
        watchEditSnapCSALocationListSaga(),
        watchDeleteSnapCSALocationSaga(),
        watchGetRequestHelpSaga(),
        watchShowLocationTypeSaga(),
        watchSubmitSignupSaga(),
        watchGetCSADocSaga(),
        watchGetSignatureSaga(),
        watchSaveCSASignupSaga(),
        watchGetClientAgrmntShrLstSaga(),
        watchExportClientPaymentListSaga(),
        watchGetCancelAgreementListSaga(),
        watchGetExportClientAgreementListSaga(),
        watchCancelUpdateAgreementShareSaga(),
        watchGetDocumentSaga(),
        watchGetVendorHistoryDetailSaga(),
        watchGetVendorSubUserListSaga(),
        watchGetAddUserListSaga(),
        watchUpdateSubUserSaga(),
        watchDeleteSubVendorSaga(),
        watchGetCSAVendorListSaga(),
        watchGetPickupLocationListSaga(),
        watchGetClientAgreementListSaga(),
        watchGetClientInfoSaga(),
        watchGetCSAVendorInfoSaga(),
        watchGetCSAShareListSaga(),
        watchUpdatePrimaryEmailSaga()


    ]);
    // yield all([ fork(watchGetLanguageData) ]);
}