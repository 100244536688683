import React from "react";
import PropTypes from "prop-types";

const Menu = (props) => {
    const shadow = "hsla(218, 50%, 10%, 0.1)";
    return (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 4,
          boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
          marginTop: 8,
          position: "absolute",
          zIndex: 2
        }}
        {...props}
      />
    );
  };
  const Blanket = (props) => (
    <div
      style={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: "fixed",
        zIndex: 1
      }}
      {...props}
    />
  );
  const Dropdown = ({ children, isOpen, target, onClose }) => (
    <div style={{ position: "relative", display:"inline-block" }}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
  );
  
  Dropdown.propTypes = {
      children: PropTypes.any,
      isOpen: PropTypes.any,
      target: PropTypes.any,
      onClose: PropTypes.any,
      isselected: PropTypes.any,
      label: PropTypes.any
  };

  export default Dropdown;