import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import PropTypes from "prop-types";
import "../../../styles/dateTimePicker.scss";

const TimePickerViews = ({handleChange,value}) => {
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['TimePicker']} sx={{padding: "0px"}}>
        <TimePicker 
          className={"datePicker"}
          value={dayjs(value)}
          onChange={(newValue) => {
              handleChange(newValue)
          }} />
      </DemoContainer>
    </LocalizationProvider>
  );
}

TimePickerViews.propTypes = {
    handleChange:PropTypes.func,
    value:PropTypes.string,
}

export default TimePickerViews;