import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeaderComponent from "../common/components/pageHeader";
import DatePickerComponent from "../common/components/dateRangePicker";
import { CustomButton } from "../common/components/customButton";
import { CalculateUserTzToUTCTz, popUpAlert, removeSpecialCharacter } from "../../utils/globalMethods";
import moment from "moment";
import SelectDropdown from "../common/selectdropdown-component";
import SearchComponent from "../common/components/searchComponent";
import TableComponent from "../common/components/table";
import { connect } from "react-redux";
import { mapStateToProps } from "../../redux/reducers";
import { refreshKey } from "../../redux/actionTypes/administration";
import { useNavigate } from "react-router-dom";
import { PropTypes } from "prop-types";
import * as actionTypes from "../../redux/actionTypes/csaSignup";
import CsaSignupStatusDetails from "./csaSignupStatusDetails";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseIcon from '@mui/icons-material/Close';
import useLanguageCode from "../../hooks/useLanguageCode";

function createData(
  id,
  isAction,
  startDate,
  endDate,
  setId,
  vendorId,
  clientId,
  shareId,
  vendorName,
  chosenNam,
  paymentType,
  shareName,
  shareCost,
  apprvlStatus,
  submittedDate,
  agrmntAppId,
  clientNam,
  clientEmail,
  phoneNumber,
  lastUpdtUidNam,
  rejectedDate,
  address1,
  address2,
  city,
  state,
  zipcode,
  day,
  openTime,
  closeTime,
  dsc,
  cancelNotes,
  cancelSign,
  cancelDate,
  row
) {
  // console.log('Dev',shareName)
  return {
    id,
    isAction,
    startDate:
      paymentType === "MNTHLY"
        ? moment(startDate, "YYYY-MM-DD").format("MMM YYYY")
        : moment(startDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
    endDate:
      paymentType === "MNTHLY"
        ? moment(endDate, "YYYY-MM-DD").format("MMM YYYY")
        : moment(endDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
    setId,
    vendorId,
    clientId,
    shareId,
    vendorName,
    chosenNam,
    paymentType,
    shareName,
    shareCost,
    apprvlStatus,
    submittedDate: moment(submittedDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
    agrmntAppId: agrmntAppId,
    clientNam: clientNam,
    clientEmail: clientEmail,
    phoneNumber: phoneNumber,
    lastUpdtUidNam: lastUpdtUidNam,
    rejectedDate: moment(rejectedDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
    address1: address1,
    address2: address2,
    city: city,
    state: state,
    zipcode: zipcode,
    day: day,
    openTime: openTime,
    closeTime: closeTime,
    dsc: dsc,
    cancelNotes: cancelNotes,
    cancelSign: cancelSign,
    cancelDate: moment(cancelDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
    row,
  };
}

const CSASignupStatusComponent = (props) => {
  const languageCode = useLanguageCode();
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(true);
  const [csaSignupStatusTotal, setcsaSignupStatusTotal] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({
    name: "",
    values: "All Status",
    isActive: 1,
  });

  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [columns, setColumns] = useState([]);
  const [tableSort, setTableSort] = useState({ lastUpdtTs: "DESC" });
  const [expand, setExpand] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [csaSignupStatusList, setCsaSignupStatusList] = useState([]);
  const [search, setSearch] = useState("");
  const [status] = useState("");
  const [resetDate, setResetDate] = useState(false);
  const [statusList] = useState([
    { name: "", values: "All status", isActive: 1 },
    { name: "APPRVD", values: "Approved", isActive: 1 },
    { name: "CANCLD", values: "Cancelled", isActive: 1 },
    { name: "DRAFT", values: "Pending", isActive: 1 },
    { name: "REJCTD", values: "Rejected", isActive: 1 },
  ]);

  // const [shareList, setShareList] = useState([
  //   { name: "", values: "All Status", isActive: 1 },
  //   { name: "ACTV", values: "Active", isActive: 1 },
  //   { name: "INACTV", values: "Inactive", isActive: 1 },
  //   { name: "ENRLNG", values: "Enrolling", isActive: 1 },
  // ]);
  const [dateRangePickerLabel] = useState("Select Date");
  const [filterData, setFilterData] = useState({
    startDate: null,
    endDate: null,
  });
  const filterHandleChange = (date) => {
    setFilterData({
      ...filterData,
      endDate: date?.[0]?.endDate
        ? CalculateUserTzToUTCTz(date?.[0]?.endDate)
        : null,
      startDate: date?.[0]?.startDate
        ? CalculateUserTzToUTCTz(date?.[0]?.startDate)
        : null,
    });
  };

  useEffect(() => {
    const userRole = props?.userAuth?.user
      ? props?.userAuth?.user
      : JSON.parse(sessionStorage.getItem("userData"));
    if (props?.allowedRoles) {
      const checkRole = props?.allowedRoles.find((role) =>
        userRole?.Role.includes(role)
      );
      if (!checkRole) {
        navigate("/kfreshfep");
        return;
      }
    }
    const payload = {
      nam: search,
      shareId: 0,
      addressId: 0,
      shareStatus: status,
      signupStatus: selectedStatus.name == "all" ? "" : selectedStatus.name,
      addressType: "delivery",
      // "startDate": moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY"),
      // "endDate": moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY"),
      startDate: filterData.startDate
        ? moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY")
        : "",
      endDate: filterData.endDate
        ? moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY")
        : "",
      size: size,
      from: from,
    };
    props.getCsaSignupStatusList(payload);
  }, [
    size,
    from,
    status,
    selectedStatus,
    props.csaSignup.refreshKey,
    search,
    filterData,
    tableSort,
  ]);

  useEffect(() => {
    if (props.csaSignup?.csaSignupStatusList?.csaSignupStatusList?.length) {
      setCsaSignupStatusList(
        props?.csaSignup?.csaSignupStatusList?.csaSignupStatusList
      );
    } else {
      setCsaSignupStatusList([]);
    }
  }, [props?.csaSignup?.csaSignupStatusList?.csaSignupStatusList]);

  useEffect(() => {
    const { list, total, loading } = props.csaSignup.csaSignupStatusList;
    let data = [];
    setRows([]);
    setTimeout(() => {
      list.forEach((element) => {
        data.push(
          createData(
            element.id,
            element?.isAction,
            element?.startDate,
            element?.endDate,
            element.setId,
            element.vendorId,
            element.clientId,
            element.shareInfo?.id,
            element?.vendorName,
            element?.chosenNam,
            element?.paymentType,
            element?.shareInfo?.nam,
            element?.shareInfo?.shareCost,
            element?.apprvlStatus,
            element?.submittedDate,
            element?.agrmntAppId,
            element?.clientNam,
            element?.clientEmail,
            element?.phoneNumber,
            element?.lastUpdtUidNam,
            element?.rejectedDate,
            element?.shareInfo?.pickupInfo?.address1
              ? element?.shareInfo?.pickupInfo?.address1
              : element?.shareInfo?.deliveryInfo?.address1,
            element?.shareInfo?.pickupInfo?.address2
              ? element?.shareInfo?.pickupInfo?.address2
              : element?.shareInfo?.deliveryInfo?.address2,
            element?.shareInfo?.pickupInfo?.city
              ? element?.shareInfo?.pickupInfo?.city
              : element?.shareInfo?.deliveryInfo?.city,
            element?.shareInfo?.pickupInfo?.state
              ? element?.shareInfo?.pickupInfo?.state
              : element?.shareInfo?.deliveryInfo?.state,
            element?.shareInfo?.pickupInfo?.zipcode
              ? element?.shareInfo?.pickupInfo?.zipcode
              : element?.shareInfo?.deliveryInfo?.zipcode,
            element?.shareInfo?.pickupInfo?.day,
            element?.shareInfo?.pickupInfo?.openTime,
            element?.shareInfo?.pickupInfo?.closeTime,
            element?.shareInfo?.dsc,
            element?.cancelNotes,
            element?.cancelSign,
            element?.cancelDate,
            element
          )
        );
      });
      setRows(data);
    }, 300);
    handleColumns();
    setcsaSignupStatusTotal(total);
    setShowLoader(loading);
  }, [props.csaSignup.csaSignupStatusList]);

  const handleSearch = (e) => {
    setExpand(false);
    setShowLoader(true);
    setSearch(e.target.value.toLowerCase());
    setFrom(0);
  };

  const handleNext = (page, rowSize) => {
    size != rowSize && setSize(rowSize);
    setFrom(rowSize * page);
  };
  const handleApproveStatus = (row) => {
    console.log(row);
    const payload = {
      id: row?.id,
      setId: row?.setId,
      shareId: row?.shareId,
      clientId: row?.clientId,
      vendorId: row?.vendorId,
      apprvlStatus: "APPRVD",
      paymentType: row?.paymentType,
      languageCode: languageCode,
    };
    props.updateApproveCsaSignupStatus(payload);
  };

  const handleApprove = (row) => {
    console.log(row);
    popUpAlert({
      title: "Are you sure?",
      text: "Do you want to approve this CSA Signup.",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, approve it!",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => handleApproveStatus(row),
    });
  };

  const handleRejectStatus = (row) => {
    console.log(row);
    const payload = {
      id: row?.id,
      setId: row?.setId,
      shareId: row?.shareId,
      clientId: row?.clientId,
      vendorId: row?.vendorId,
      apprvlStatus: "REJCTD",
      paymentType: row?.paymentType,
      languageCode: languageCode,
    };
    props.updateApproveCsaSignupStatus(payload);
  };
  const handleReject = (row) => {
    popUpAlert({
      title: "Are you sure?",
      text: "Do you want to reject this CSA Signup.",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, reject it!",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => handleRejectStatus(row),
    });
  };
  const handleSort = (item) => {
    let sortitem = {};
    if (item.sort == "" || item.sort == "DESC") {
      sortitem[item.id] = "ASC";
    } else {
      sortitem[item.id] = "DESC";
    }
    setTableSort(sortitem);
  };

  const handleColumns = () => {
    const headers = [
      { id: "details", label: "Details" },
      {
        id: "vendorName",
        label: "Vendor Name",
        cell: (item) => {
          let status = " ";
          if (item.paymentType === "MNTHLY") {
            status = "Monthly";
            return (
              <>
                <p>{removeSpecialCharacter(item.vendorName)}</p>
                {status && (
                  <span className="badge badge-primary">{status}</span>
                )}
              </>
            );
          } else {
            status = "Daily";
            return (
              <>
                <p>{removeSpecialCharacter(item.vendorName)}</p>
                {status && <span className="badge badge-grey">{status}</span>}
              </>
            );
          }
        },
      },
      {
        id: "clientNam",
        label: "Client Name",
        cell: (item) => {
          const fullName = item.clientNam || "";
          const nameArray = fullName.split(" ");
          const firstName = nameArray[0];
          return (
            <>
              <div>{item.chosenNam || firstName}</div>
            </>
          );
        },
      },
      {
        id: "shareName",
        label: "Share Name",
      },
      {
        id: "shareCost",
        label: "Amount",
        cell: (item) => {
          return <div>{"$ " + item.shareCost}</div>;
        },
      },
      {
        id: "apprvlStatus",
        label: "CSA Signup status",
        cell: (item) => {
          let status = "";
          if (item.apprvlStatus == "DRAFT") {
            status = "Pending";
            return (
              <div style={{ color: "#456F8F", fontWeight: "bold" }}>
                {" "}
                {status}{" "}
              </div>
            );
          } else if (item.apprvlStatus == "REJCTD") {
            status = "Rejected";
            return (
              <div style={{ color: "#dc3545", fontWeight: "bold" }}>
                {" "}
                {status}{" "}
              </div>
            );
          } else if (item.apprvlStatus == "APPRVD") {
            status = "Approved";
            return (
              <div style={{ color: "#006e52", fontWeight: "bold" }}>
                {" "}
                {status}{" "}
              </div>
            );
          } else if (item.apprvlStatus == "CANCLD") {
            status = "Cancelled";
            return (
              <div style={{ color: "#dc3545", fontWeight: "bold" }}>
                {" "}
                {status}{" "}
              </div>
            );
          }
        },
      },
      {
        id: "startDate",
        label: "Share Start Date",
        cell: (item) => {
          const date =
            item?.startDate === "Invalid date" ? (
              <div style={{ paddingLeft: "40px" }}>{"-"}</div>
            ) : (
              item?.startDate
            );
          return <div>{date}</div>;
        },
      },
      {
        id: "endDate",
        label: "Share End Date",
        cell: (item) => {
          const date =
            item?.endDate === "Invalid date" ? (
              <div style={{ paddingLeft: "40px" }}>{"-"}</div>
            ) : (
              item?.endDate
            );
          return <div>{date}</div>;
        },
      },
      {
        id: "rejectedDate",
        label: "Approve/Reject/Cancel Date",
        cell: (item) => {
          const date =
            item?.rejectedDate === "Invalid date" ? (
              <div style={{ paddingLeft: "40px" }}>{"-"}</div>
            ) : (
              item?.rejectedDate
            );
          return <div>{date}</div>;
        },
      },
      {
        id: "submittedDate",
        label: "Signup Submitted Date",
      },
      {
        id: "action",
        label: "Action",
        cell: (item) => {
          console.log("-----------");
          console.log(item);
          console.log("-----------");
          if (item?.isAction === "Y") {
            if (item?.apprvlStatus === "DRAFT") {
              return (
                <div style={{ paddingLeft: "12px" }}>
                  <div style={{ margin: "8px 0px 8px 0px" }}>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => handleApprove(item)}
                      startIcon={<DoneOutlinedIcon />}
                      style={{ textTransform: "none", width: "100px" }}
                    >
                      Approve
                    </Button>
                  </div>
                  <div style={{ margin: "8px 0px 8px 0px" }}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleReject(item)}
                      startIcon={<CloseIcon />}
                      style={{ textTransform: "none", width: "100px" }}
                    >
                      Reject
                    </Button>
                  </div>
                </div>
              );
            } else if (item?.apprvlStatus === "APPRVD") {
              return (
                <div style={{ padding: "12px" }}>
                  <div style={{ margin: "8px 0px 8px 0px" }}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleReject(item)}
                      startIcon={<CloseIcon />}
                      style={{ textTransform: "none", width: "100px" }}
                    >
                      Reject
                    </Button>
                  </div>
                </div>
              );
            }
          } else {
            return <div style={{ paddingLeft: "40px" }}> - </div>;
          }
        },
      },
    ];
    setColumns(headers);
  };

  const renderDetails = (row) => {
    return <CsaSignupStatusDetails row={row} />;
  };

  const exportData = () => {
    const payload = {
      nam: search,
      shareId: 0,
      addressId: 0,
      shareStatus: status,
      signupStatus: selectedStatus.name == "all" ? "" : selectedStatus.name,
      addressType: "delivery",
      // "startDate": moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY"),
      // "endDate": moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY"),
      startDate: filterData.startDate
        ? moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY")
        : "",
      endDate: filterData.endDate
        ? moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY")
        : "",
      size: 10000,
      from: 0,
    };

    props.exportCsaSignupStatus(payload);
  };
  return (
    <Grid container className="container-block" rowSpacing={2}>
      <Grid container paddingBottom={2}>
        <Grid item xs={12} md={6}>
          <PageHeaderComponent
            title={"CSA SIGNUP STATUS"}
            showSubtitle={true}
            subtitle={"Total Records"}
            count={csaSignupStatusTotal}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <CustomButton
              variant="outlined"
              startIcon={<i className="fa fa-download" aria-hidden="true"></i>}
              onClick={exportData}
            >
              Export
            </CustomButton>
            <CustomButton
              variant="outlined"
              startIcon={<i className="fa fa-refresh" aria-hidden="true"></i>}
              onClick={() => {
                setSearch("");
                setExpand(false);
                props.refreshKey();
                setShowLoader(true);
              }}
            >
              Refresh
            </CustomButton>
          </Stack>
        </Grid>
      </Grid>
      <Grid container item xs={12} sx={{ backgroundColor: "#DBE8DF" }}>
        <Grid container item columnSpacing={{ xs: 1, sm: 2, md: 3 }} p={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1" sx={{ fontFamily: "texta-bold" }}>
              By Date
            </Typography>
            <DatePickerComponent
              label={dateRangePickerLabel}
              selectedDateObj={(date) => {
                filterHandleChange(date, "", "date");
              }}
              resetDate={resetDate}
            />
            {/* label={`${moment(CalculateUTCTzToUserTz(filterData.startDate)).format('MM-DD-YYYY')} - ${moment(CalculateUTCTzToUserTz(filterData.endDate)).format('MM-DD-YYYY')}`} */}
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1" sx={{ fontFamily: "texta-bold" }}>
              By Signup Status
            </Typography>
            <SelectDropdown
              id="statusFilter"
              value={selectedStatus}
              isSearch={true}
              style={{ width: "75%" }}
              variant="outlined"
              options={statusList}
              onChange={(item) => {
                setSelectedStatus(item);
                setShowLoader(true);
              }}
            ></SelectDropdown>
          </Grid>
          {/* <Grid item xs={3} > 
                        <Typography variant='subtitle1' sx={{fontFamily: "texta-bold"}}>
                            By Share Status
                        </Typography>
                        <SelectDropdown
                            id="shareFilter"
                            value={shareStatus}
                            isSearch={true}
                            style={{ width: "75%" }}
                            variant="outlined"
                            options={shareList}
                            onChange={(item) =>{
                              setStatus(item.name)
                              setShareStatus(item)
                            }}
                        >
                          
                        </SelectDropdown>
                    </Grid> */}
          <Grid container item xs="auto" p={3} alignItems={"center"}>
            <CustomButton
              variant="outlined"
              sx={{ fontWeight: "bold" }}
              startIcon={<i className="fa fa-refresh" aria-hidden="true"></i>}
              onClick={() => {
                setSearch("");
                setExpand(false);
                setSelectedStatus({
                  name: "",
                  values: "All Status",
                  isActive: 1,
                });
                props.refreshKey();
                setShowLoader(true);
                setFilterData("");
                setResetDate((preDate) => !preDate);
                setShowLoader(true);
              }}
            >
              Reset
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ flexGrow: 1 }}>
        <SearchComponent
          placeholder={"Name,Email,Agreement ID"}
          handleChange={handleSearch}
          searchText={search}
        />
      </Grid>
      <Grid item xs={12}>
        <TableComponent
          columns={columns}
          rows={rows}
          showDetails={true}
          expand={expand}
          renderDetails={renderDetails}
          handlePagination={handleNext}
          total={csaSignupStatusTotal}
          page={Math.floor(from / size)}
          rowsPerPage={size}
          handleSort={handleSort}
          dataLoading={showLoader}
        />
      </Grid>
    </Grid>
  );
};

CSASignupStatusComponent.propTypes = {
  refreshKey: PropTypes.func.isRequired,
  getCsaSignupStatusList: PropTypes.func.isRequired,
  csaSignup: PropTypes.object.isRequired,
  exportCsaSignupStatus: PropTypes.func.isRequired,
  updateApproveCsaSignupStatus: PropTypes.func.isRequired,
  allowedRoles: PropTypes.array,
  userAuth : PropTypes.object,

}
function mapDispatchToProps(dispatch) {
  return {
    refreshKey: () => dispatch(refreshKey()),
    getCsaSignupStatusList: (payload) => dispatch(actionTypes.getCsaSignupStatusList(payload)),
    exportCsaSignupStatus: (payload) => dispatch(actionTypes.exportCsaSignupStatus(payload)),
    updateApproveCsaSignupStatus: (payload) => dispatch(actionTypes.updateApproveCsaSignupStatus(payload))

  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CSASignupStatusComponent);
