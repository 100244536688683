const initialState = {
    mySideStatus:[
        {id:1, name:'sidenav1',status:false},
        {id:2, name:'sidenav2',status:false},
        {id:3, name:'sidenav3',status:false},
        {id:4, name:'sidenav4',status:false},
        {id:5, name:'sidenav5',status:false},
        {id:6, name:'sidenav6',status:false},
    ]
}

const sideNavigationReducer = (state = initialState) => {
    return state;
}

export default sideNavigationReducer;