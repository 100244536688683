import { takeLatest, select } from "@redux-saga/core/effects";
import axios from "axios";
import swal from "sweetalert";
import { call, put, takeEvery } from "redux-saga/effects"; //,delay ,  call
import {
  SET_USER,
  PUT_USER,
  GET_LOC_TYPE,
  GET_LOC_TYPE_QUES,
  SET_LOC_TYPE,
  SET_LOC_TYPE_QUES,
  GET_CITY,
  SET_ORDER_OPTION,
  GET_ORDER_OPTION,
  SET_CITY,
  SET_PROGRAM,
  SET_PROGRAM_FAILURE,
  GET_DAY_OPTION,
  GET_MONTH_OPTION,
  SET_MONTH_OPTION,
  SET_DAY_OPTION,
  SET_USER_ERROR_RESPONSE,
  SET_USER_ASYNC_SUCCESS,
  actionTypes,
} from "../actionTypes/registration"; //,SET_PROGRAM,SET_USER_SUCCESS,GET_USER,,setRegistration
import { API_URL, PUBLIC_API } from "../../config";
import axiosInstance from "../interceptor";
import { popUpAlert } from "../../utils/globalMethods";
import { removeLoadingItem, setLoadingItem } from "../actionTypes/loader";
import toast from "react-hot-toast";

export const watchPostUser = function* () {
  //   console.log("watch new message");
  yield takeEvery(SET_USER, addUser);
};

export const watchPutUser = function* () {
  yield takeEvery(PUT_USER, updateUser);
};

export const watchGetLocType = function* () {
  yield takeEvery(GET_LOC_TYPE, getLocTypeValues);
};

export const watchGetLocTypeQues = function* () {
  yield takeEvery(GET_LOC_TYPE_QUES, getLocTypeQues);
};

export const watchPostProgram = function* () {
  yield takeEvery(SET_PROGRAM, addProgram);
};

export const watchGetRegCityList = function* () {
  yield takeEvery(GET_CITY, getCityList);
};

export const watchGetRegOrderOption = function* () {
  yield takeEvery(GET_ORDER_OPTION, getRegOrderOption);
};
export const watchGetRegMonthOption = function* () {
  yield takeEvery(GET_MONTH_OPTION, getMonthOption);
};
export const watchGetRegDayOption = function* () {
  yield takeEvery(GET_DAY_OPTION, getDayOption);
};

export const watchSendSupportEmail = function* () {
  yield takeLatest(actionTypes.SEND_SUPPORT_EMAIL, sendSupportEmail);
};

// function* getLoctypeProgram() {
//     yield put({ type: "LOCTYPE_PROGRAM_PAGES_FULLFILLED"});
// }

// export const watchGetRegisterSuccess = function* () {
//     yield takeEvery(GET_USERS, registerSuccess);
// }

// function* registerSuccess(user) {
//     yield put({ type: GET_USER_SUCCESS});
// }

function* sendSupportEmail(props) {
  let url = `${PUBLIC_API}/support/email`;
  try {
    const uri = url;
    const result = yield call(axiosInstance.post, uri, props.payload);
    if (result.data.code == 200) {
      popUpAlert({
        title: "Success",
        text: "Email sent successfully!",
        icon: "success",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    } else {
      popUpAlert({
        title: "Error",
        text: "Failed to send Email!",
        icon: "error",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  } catch {
    popUpAlert({
      title: "Error",
      text: "Failed to send Email!",
      icon: "error",
      timer: 1500,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
}

const swalAlertAuto = (type, msg) => {
  var typeTxt = "";
  if (type === "success") {
    typeTxt = "Success";
  } else if (type === "error") {
    typeTxt = "Failed";
  }
  swal({
    icon: type,
    type: type,
    title: typeTxt,
    text: msg,
    showConfirmButton: false,
    timer: 2000,
    button: false,
  });
};

function* postUser(user, cb) {
  let updatePayload = { ...user, status: "PE" };
  try {
    yield put(setLoadingItem("vendor_register"));
    // Resetting programs to override prev one
    yield put({ type: "SET_PROGRAM_RES", payload: {} });
    const uri = `${PUBLIC_API}/vendor/register`;
    const result = yield call(axiosInstance.post, uri, updatePayload);
    console.log(result);
    if (result.data.code == 200) {
      const { vendorId, firstName, lastName, roleName, id } = result.data;
      const userInfo = {
        Name: `${firstName} ${lastName}`,
        Role: roleName,
        vendorId,
        id,
      };
      sessionStorage.setItem("userData", JSON.stringify(userInfo));
      // if(cb) {
      //   yield cb(user, vendorId);
      // }
      yield put({ type: "SET_HIP_USER_DETAILS", payload: userInfo });
      yield put({ type: SET_USER_ASYNC_SUCCESS, value: result.data });
      yield put({
        type: SET_USER_ERROR_RESPONSE,
        value: { status: "success", message: "Successfully registed!!" },
      });
    } else {
      yield put({
        type: SET_USER_ERROR_RESPONSE,
        value: {
          status: "error",
          message:
            result.data.message == "Email address already registered with us"
              ? result.data.message
              : "We were not able to send the confirmation message, Please check your email address. Email address already registered with us.",
        },
      });
    }
    // if (result.status) {
    //   swalAlertAuto("success", "Basic registration completed successfully");
    // yield put({ type: SET_USER_ERROR_RESPONSE, value: {} });
    // yield put({ type: SET_USER, value: result.data.data });
    //   console.log("Added a user successfully");
    // }
  } catch (e) {
    // console.log(e);
    const code = e.response?.data?.code;
    const message = e.response?.data?.message;
    if (code === "601") {
      yield put({
        type: SET_USER_ERROR_RESPONSE,
        value: {
          status: "error",
          message:
            message?.replace(/fep/i, "SPF") ??
            "We were not able to send the confirmation message, Please check your email address. Email address already registered with us.",
        },
      });
    }
    // const apiRes = e.response;
    // yield put({ type: SET_USER_ERROR_RESPONSE, value: apiRes });
    // console.log("apiRes",apiRes);  //  swalAlertAuto("error", result.message);
  } finally {
    yield put(removeLoadingItem("vendor_register"));
  }
}

function* postCSAUser(user, vendorId) {
  let updateuser = {
    ...user,
    vendorType: "CSAVDR",
    submitForm: "Vendor",
    role: "CSAVDR",
    vendorId,
  };
  try {
    const uri = `${PUBLIC_API}/vendor/register`;
    const result = yield call(axiosInstance.post, uri, updateuser);
    console.log(result);
    if (result.data.code == 200) {
      const { vendorId, firstName, lastName, roleName, id } = result.data;
      const userInfo = {
        Name: `${firstName} ${lastName}`,
        Role: roleName,
        vendorId,
        id,
      };
      sessionStorage.setItem("userData", JSON.stringify(userInfo));
      yield put({ type: "SET_HIP_USER_DETAILS", payload: userInfo });
      yield put({ type: SET_USER_ASYNC_SUCCESS, value: result.data });
      // yield put({
      //   type: SET_USER_ERROR_RESPONSE,
      //   value: { status: "success", message: "Successfully registed!!" },
      // });
    } else {
      yield put({
        type: SET_USER_ERROR_RESPONSE,
        value: {
          status: "error",
          message:
            result.data.message == "Email address already registered with us"
              ? result.data.message
              : "We were not able to send the confirmation message, Please check your email address. Email address already registered with us.",
        },
      });
    }
    // if (result.status) {
    //   swalAlertAuto("success", "Basic registration completed successfully");
    // yield put({ type: SET_USER_ERROR_RESPONSE, value: {} });
    // yield put({ type: SET_USER, value: result.data.data });
    //   console.log("Added a user successfully");
    // }
  } catch (e) {
    // console.log(e);
    const apiRes = e.response;
    yield put({ type: SET_USER_ERROR_RESPONSE, value: apiRes });
    // console.log("apiRes",apiRes);  //  swalAlertAuto("error", result.message);
  }
}

function* addUser(action) {
  try {
    yield postUser(action.value, postCSAUser);
  } catch (e) {
    // console.log(e);
  }
}

function* updateUser(action) {
  try {
    yield put(setLoadingItem("update_vendor_info"));
    const uri = `${PUBLIC_API}/update/vendorinfo`;
    const data = action.value;
    data.lastUpdtUidNam = data?.lastUpdtUid
      ? data?.lastUpdtUid
      : data?.lastUpdtUidNam;
    data.primaryMobile = data.primaryMobile.replace(/\D/g, "");
    data.publicMobile = data.publicMobile.replace(/\D/g, "");
    const result = yield call(axiosInstance.post, uri, data);
    if (result.status) {
      yield put({ type: "UPDATE_USER_SUCCESS", value: true });
      yield put({ type: "STOP_LOADING" });

      if (data.type === "edit") {
        yield put({ type: actionTypes.REFRESH_KEY });
      }
    } else {
      yield put({ type: "UPDATE_USER_SUCCESS", value: false });
    }
    "callback" in action &&
      action.callback({
        vendorType: data.vendorType,
        isHipAndCsaVdr: data.isHipAndCsaVdr,
      });
    popUpAlert({
      title: "Success",
      text: data.submitForm + " updated successfully",
      icon: "success",
      timer: 1500,
      showCancelButton: false,
      showConfirmButton: false,
    });
  } catch {
    console.log("Failed");
    // popUpAlert({
    //   title: "Error",
    //   text: "Failed to Update Public info",
    //   icon: "error",
    //   timer:1500,
    //   showCancelButton: false,
    //   showConfirmButton: false,
    // });
  } finally {
    yield put(removeLoadingItem("update_vendor_info"));
  }
}

function* getLocTypeValues() {
  //   console.log("getting location type");
  try {
    const uri = `${API_URL}/lov/type`;
    const result = yield call(axios.get, uri);
    // console.log(result);
    yield put({ type: SET_LOC_TYPE, payload: result.data.data });
    // console.log("loc type loaded successfully");
  } catch {
    // console.log("Failed");
  }
}

function* getLocTypeQues() {
  //   console.log("getting location type ques");
  try {
    const uri = `${API_URL}/lov/locationtypes`;
    const result = yield call(axios.get, uri);
    // console.log(result);
    yield put({ type: SET_LOC_TYPE_QUES, payload: result.data.data });
    // console.log("loc type ques loaded successfully");
  } catch {
    // console.log("Failed");
  }
}

function* addProgram(program) {
  try {
    const storeId = yield select((state) => state.user?.program?.id);
    yield put(setLoadingItem("update_vendor_store"));
    const uri = `${PUBLIC_API}/update/vendor/store`;
    const storeDetails = {
      ...(program?.value?.vendorStores[0] ?? {}),
      // isUpdate: storeId ? "Y" : program?.value?.vendorStores[0]?.storeId ?? "N",
      // storeId: storeId ?? program?.value?.vendorStores[0]?.storeId ?? 0,
      isUpdate: "N",
      storeId: 0,
    };

    const result = yield call(axiosInstance.post, uri, storeDetails);
    if (result.status == 200) {
      swalAlertAuto("success", "Store updated successfully");
      if (!storeId) {
        yield put({ type: "SET_PROGRAM_RES", payload: result.data });
      }
    } else {
      swalAlertAuto("error", "Something went wrong! Please try again later");
      yield put({ type: SET_PROGRAM_FAILURE, payload: result.data });
    }
  } catch (error) {
    toast.error("Unable to save store details");
  } finally {
    yield put(removeLoadingItem("update_vendor_store"));
  }
}

function* getCityList() {
  // console.log("getting location type ques");
  try {
    const uri = `${API_URL}/lov/citytype`;
    const result = yield call(axios.get, uri);
    // console.log(result);
    yield put({ type: SET_CITY, payload: result.data.data });
    // console.log("loc type ques loaded successfully");
  } catch {
    console.log("Failed");
  }
}

function* getRegOrderOption() {
  // console.log("getting location type ques");
  try {
    const uri = `${API_URL}/lov/orderOptionType`;
    const result = yield call(axios.get, uri);
    // console.log(result);
    yield put({ type: SET_ORDER_OPTION, payload: result.data.data });
    // console.log("loc type ques loaded successfully");
  } catch {
    console.log("Failed");
  }
}

function* getMonthOption() {
  // console.log("getting location type ques");
  try {
    const uri = `${API_URL}/lov/months_operation`;
    const result = yield call(axios.get, uri);
    // console.log(result);
    yield put({ type: SET_MONTH_OPTION, payload: result.data.data });
    // console.log("loc type ques loaded successfully");
  } catch {
    // console.log("Failed");
  }
}

function* getDayOption() {
  // console.log("getting location type ques");
  try {
    const uri = `${API_URL}/lov/days_operation`;
    const result = yield call(axios.get, uri);
    // console.log(result);
    yield put({ type: SET_DAY_OPTION, payload: result.data.data });
    // console.log("loc type ques loaded successfully");
  } catch {
    // console.log("Failed");
  }
}
