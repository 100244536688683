import React, { useEffect, useRef, useState } from "react";
4;
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import SignaturePad from "react-signature-canvas";

import "./drawStyle.js";
import { noop } from "lodash";

export default function DrawComponent(props) {
  const [saveSign, setSaveSign] = useState(null);
  var sigCanvas = useRef({});
  useEffect(() => {
    clear();
  }, [props.clear, props.color]);
  const clear = () => sigCanvas.current.clear();

  const save = () => {
    props.setDrawSign(
      sigCanvas.current.getTrimmedCanvas().toDataURL(" image/ png")
    );
    if (saveSign) {
      props.setDrawSign(saveSign);
    }
  };

  return (
    <Grid>
      <Grid>
        <SignaturePad
          penColor={props.color}
          ref={sigCanvas}
          onEnd={save}
          canvasProps={{
            className: "drawHere",
          }}
        />
      </Grid>
    </Grid>
  );
}

DrawComponent.propTypes = {
  clear: PropTypes.func,
  color: PropTypes.string,
  setDrawSign: PropTypes.func,
};

DrawComponent.defaultProps = {
  clear: noop,
  color: "",
  setDrawSign: noop,
};
