import * as React from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const SelectVendorTypeComponent = (props) => {
  return (
    <Stack
      direction="row"
      spacing={0.5}
      mr={1}
      justifyContent={"flex-end"}
      style={{ display: "inline" }}
    >
      <Typography
        display="inline"
        mt={1}
        variant="body2"
        sx={{ fontFamily: "texta-bold", fontSize: "18px" }}
      >
        Vendor Type:
      </Typography>
      <FormControl size="small">
        <Select
          defaultValue={props.vendorType}
          onChange={props.handleChange}
          sx={{ fontFamily: "texta-bold", fontSize: "18px" }}
        >
          <MenuItem
            value="HIPVDR"
            sx={{ fontFamily: "texta-bold", fontSize: "18px" }}
          >
            SPF Vendor Registration
          </MenuItem>
          <MenuItem
            value="CSAVDR"
            sx={{ fontFamily: "texta-bold", fontSize: "18px" }}
          >
            SPF Vendor Product
          </MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
};

SelectVendorTypeComponent.propTypes = {
  handleChange: PropTypes.func,
  vendorType: PropTypes.string,
};

export default SelectVendorTypeComponent;
