import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EligibilityCriteria from "./EligibilityCriteria";
import FederalAmount from "./FederalAmount";
import AgeLimit from "./AgeLimit";
import { ReactComponent as AgeLimitIcon } from "../../assets/icons/avg_pace.svg";
import { ReactComponent as CountyListIcon } from "../../assets/icons/carbon_map.svg";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";

const EligibilityCriteriaTab = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            TabIndicatorProps={{
              style: {
                backgroundColor: "#457425",
              },
            }}
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab
              label="Age Limit"
              icon={<AgeLimitIcon />}
              value="1"
              style={{
                alignItems: "center",
                color: "#457425",
                flexDirection: "row",
                justifyContent: "center",
                gap: "10px",
              }}
            />
            <Tab
              label="County List"
              icon={<CountyListIcon />}
              value="2"
              style={{
                alignItems: "center",
                color: "#457425",
                flexDirection: "row",
                justifyContent: "center",
                gap: "10px",
              }}
            />
            <Tab
              label="Income Limit"
              value="3"
              icon={<FactCheckOutlinedIcon />}
              style={{
                alignItems: "center",
                color: "#457425",
                flexDirection: "row",
                justifyContent: "center",
                gap: "10px",
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <AgeLimit />
        </TabPanel>
        <TabPanel value="2">
          <FederalAmount />
        </TabPanel>
        <TabPanel value="3">
          <EligibilityCriteria />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default EligibilityCriteriaTab;
