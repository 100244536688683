import { useDispatch } from "react-redux";
import { removeLoadingItem, setLoadingItem } from "../redux/actionTypes/loader";

const useLoader = () => {
  const dispatch = useDispatch();

  const openLoader = (item) => {
    dispatch(setLoadingItem(item));
  };

  const closeLoader = (item) => {
    dispatch(removeLoadingItem(item));
  };

  return {
    openLoader,
    closeLoader,
  };
};

export default useLoader;
