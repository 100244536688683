export const area = [
  { name: "1", values: "1 mile", isActive: 1 },
  { name: "2", values: "2 miles", isActive: 1 },
  { name: "5", values: "5 miles", isActive: 1 },
  { name: "10", values: "10 miles", isActive: 1 },
  { name: "15", values: "15 miles", isActive: 1 },
  { name: "20", values: "20 miles", isActive: 1 },
  { name: "25", values: "25 miles", isActive: 1 },
  { name: "30", values: "30 miles", isActive: 1 },
];

// export const vendorType = [
//   {
//     id: "csaPickupSite",
//     name: "csaPickupSite",
//     values: "FEP_CSA_Program",
//     dec: "Does your farm or nonprofit buying co-op offer a SPF?",
//     isActive: 1,
//     addId: "csaPickupSite",
//     storeTypeCode: "HPCSAP",
//     info: "csaSignupInfo",
//   },
//   {
//     id: "farmStand",
//     name: "farmStand",
//     values: "SPF_FarmStand",
//     dec: "Do you accept SPF at a Farm Stand?",
//     isActive: 1,
//     addId: "farmStand",
//     storeTypeCode: "HPFRSD",
//     info: "farmStandInfo",
//   },
//   {
//     id: `farmersMarketBooth`,
//     name: `farmersMarketBooth`,
//     values: `SPF_FarmersMarketBooth`,
//     dec: "Do you accept SPF at a Farmers' Market Booth?",
//     isActive: 1,
//     addId: "farmersMarket",
//     storeTypeCode: "HPFRMB",
//     info: "farmersMarketInfo",
//   },
//   {
//     id: "mobileMarket",
//     name: "mobileMarket",
//     values: "SPF_MobileMarketStop",
//     dec: "Do you process SPF via a Mobile Market with multiple stops?",
//     isActive: 1,
//     addId: "mobileMarket",
//     storeTypeCode: "HPMMSP",
//     info: "mobileMarketInfo",
//   },
//   {
//     id: "farmersMarket",
//     name: "farmersMarket",
//     values: `SPF_FarmersMarket`,
//     dec: "Are you a market manager who processes SPF at a Farmers’ Market on behalf of your vendors?",
//     isActive: 1,
//     addId: "farmersMarketManagers",
//     storeTypeCode: "HPFRMT",
//     info: "farmersMarketInfo",
//   },
// ];

export const vendorType = [
  {
    id: "csaPickupSite",
    name: "csaPickupSite",
    values: "SPFFarmStand",
    dec: "SPF Roadside Stand",
    isActive: 1,
    addId: "csaPickupSite",
    storeTypeCode: "HPCSAP",
    info: "csaSignupInfo",
  },
  {
    id: "farmersMarket",
    name: "farmersMarket",
    values: `SPFFarmersMarket`,
    dec: "SPF Farmers' Market",
    isActive: 1,
    addId: "farmersMarketManagers",
    storeTypeCode: "HPFRMT",
    info: "farmersMarketInfo",
  },
];

export const orderOptions = [
  {
    id: "curbsidePickup",
    name: "curbsidePickup",
    values: "curbsidePickup",
    isActive: 1,
  },
  {
    id: "delivery",
    name: "delivery",
    values: "delivery",
    isActive: 1,
  },
  {
    id: `inPerson`,
    name: `inPerson`,
    values: `inPerson`,
    isActive: 1,
  },
  {
    id: "onlinePreselection",
    name: "onlinePreselection",
    values: "onlinePreSelection",
    isActive: 1,
  },
  {
    id: "phonePreselection",
    name: "phonePreselection",
    values: "phonePreSelection",
    isActive: 1,
  },
  {
    id: "otherType",
    name: "otherType",
    values: "other",
    isActive: 1,
  },
];

export const monthsOfOperation = [
  {
    id: "janOperation",
    name: "janOperation",
    values: "january",
    isActive: 1,
    month: "1",
  },
  {
    id: "febOperation",
    name: "febOperation",
    values: "february",
    isActive: 1,
    month: "2",
  },
  {
    id: `marOperation`,
    name: `marOperation`,
    values: `march`,
    isActive: 1,
    month: "3",
  },
  {
    id: "aprOperation",
    name: "aprOperation",
    values: "april",
    isActive: 1,
    month: "4",
  },
  {
    id: "mayOperation",
    name: "mayOperation",
    values: "may",
    isActive: 1,
    month: "5",
  },
  {
    id: "junOperation",
    name: "junOperation",
    values: "june",
    isActive: 1,
    month: "6",
  },
  {
    id: "julOperation",
    name: "julOperation",
    values: "july",
    isActive: 1,
    month: "7",
  },
  {
    id: "augOperation",
    name: "augOperation",
    values: "august",
    isActive: 1,
    month: "8",
  },
  {
    id: "sepOperation",
    name: "sepOperation",
    values: "september",
    isActive: 1,
    month: "9",
  },
  {
    id: "octOperation",
    name: "octOperation",
    values: "october",
    isActive: 1,
    month: "10",
  },
  {
    id: "novOperation",
    name: "novOperation",
    values: "november",
    isActive: 1,
    month: "11",
  },
  {
    id: "decOperation",
    name: "decOperation",
    values: "december",
    isActive: 1,
    month: "12",
  },
];

export const daysOfOperation = [
  {
    id: "monOperation",
    name: "monOperation",
    values: "monday",
    isActive: 1,
    weekDay: "02",
  },
  {
    id: "tueOperation",
    name: "tueOperation",
    values: "tuesday",
    isActive: 1,
    weekDay: "03",
  },
  {
    id: `wedOperation`,
    name: `wedOperation`,
    values: `wednesday`,
    isActive: 1,
    weekDay: "04",
  },
  {
    id: "thuOperation",
    name: "thuOperation",
    values: "thursday",
    isActive: 1,
    weekDay: "05",
  },
  {
    id: "friOperation",
    name: "friOperation",
    values: "friday",
    isActive: 1,
    weekDay: "06",
  },
  {
    id: "satOperation",
    name: "satOperation",
    values: "saturday",
    isActive: 1,
    weekDay: "07",
  },
  {
    id: "sunOperation",
    name: "sunOperation",
    values: "sunday",
    isActive: 1,
    weekDay: "01",
  },
];
