import React, { useState } from "react";
import {
  Box,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuList,
  MenuItem,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import Kfresh from "../../assets/images/kfreshlogo.png";
import Fade from "@mui/material/Fade";
import PropTypes from "prop-types";

const TopNav = ({ handleClick }) => {
  const [anchorNav, setAnchorNav] = useState(null);
  const open = Boolean(anchorNav);
  const openMenu = (event) => {
    setAnchorNav(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorNav(null);
  };

  return (
    <AppBar
      position="sticky"
      style={{
        backgroundColor: "#FFFFFF",
        boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
      }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <IconButton size="large" edge="start" color="inherit">
            <img src={Kfresh} alt="Logo" />
          </IconButton>
          {/* <Box gap={2} sx={{ display: { xs: "none", md: "flex" } }}>
            <Button color="success">Home</Button>
            <Button color="success">About Us</Button>
            <Button color="success">Our Services</Button>
            <Button color="success">Blog</Button>
          </Box> */}
        </Box>

        {/* <Box gap={2} sx={{ display: { xs: "none", md: "flex" } }}>
          <Button
            variant="outlined"
            color="success"
            onClick={() => handleClick("login")}
          >
            Login
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleClick("register")}
          >
            Sign Up
          </Button>
        </Box> */}

        <Box sx={{ display: { xs: "flex", md: "none" }, marginLeft: "auto" }}>
          <IconButton
            size="large"
            edge="end"
            onClick={openMenu}
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorNav}
            open={open}
            onClose={closeMenu}
            TransitionComponent={Fade}
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <MenuList>
              <MenuItem>Home</MenuItem>
              <MenuItem>About Us</MenuItem>
              <MenuItem>Our Services</MenuItem>
              <MenuItem>Blog</MenuItem>
              <MenuItem>Login</MenuItem>
              <MenuItem>Sign Up</MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopNav.propTypes = {
  handleClick: PropTypes.func,
};

export default TopNav;
