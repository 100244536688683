import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
  FormHelperText,
  Grid,
  Box,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AUTH_API, headers } from "../../config";
import { useFormik } from "formik";
import * as yup from "yup";
import { map } from "lodash";
import { Ethnicity, Race } from "./constant";
import dayjs from "dayjs";
import useTranslator from "../../hooks/useTranslator";
import useLkpTranslator from "../../hooks/useLkpTranslator";
import { connect } from "react-redux";
import { fetchDemographicData } from "../../redux/actionTypes/seniorInfo";
import PropTypes from "prop-types";
import { DatePicker } from "@mui/x-date-pickers";
import useLoader from "../../hooks/useLoader";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px !important",
    fontWeight: "700 !important",
    color: "#6C6C6C",
  },
  typography: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
    // width: "30%",
    minWidth: "70px",
  },
  formHelperText: {
    marginLeft: "0 !important",
  },
}));

const YourInformation = (props) => {
  const { getLkpTranslation } = useLkpTranslator();
  const races = getLkpTranslation("lookUp.Race", Race);
  const ethnicity = getLkpTranslation("lookUp.ethnicity", Ethnicity);

  const location = useLocation();
  const disabled = location?.state?.disabled ?? false;
  const _clientId = location?.state?.clientId ?? null;

  let userDetails = sessionStorage.getItem("userLogin")
    ? JSON.parse(sessionStorage.getItem("userLogin"))
    : {};

  const transProps = useTranslator(
    [
      "pageTitle",
      "yourInfo",
      "firstName",
      "lastName",
      "enterFname",
      "enterLname",
      "enterAge",
      "dob",
      "age",
      "isAgeBelow60",
      "gender",
      "male",
      "female",
      "delineAnswer",
      "race",
      "ethnicity",
      "goBack",
      "next",
      "yes",
      "no",
    ],
    "yourInfo"
  );
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const _appNumber = params?.appNumber ?? "";
  const [data, setData] = useState([]);
  const { openLoader, closeLoader } = useLoader();
  const [editData, setEditData] = useState({});
  const [appNumber, setAppNumber] = useState("");

  const formvalidation = yup.object({
    firstName: yup.string().required("Please enter First Name"),
    lastName: yup.string().required("Please enter Last Name"),
    dob: yup.string().required("Please enter DOB"),
    gender: yup.string().required("Please choose gender"),
    race: yup.string().required("Please choose race"),
    ethnicity: yup.string().required("Please choose ethnicity"),
    tribalTypeInd: yup.string().when("dob", (dob, schema) => {
      const age = calculateAge(dob);
      return age < 60
        ? schema.required("This field is required")
        : schema.notRequired();
    }),
  });

  const calculateAge = (dob) => {
    return dayjs().diff(dayjs(dob), "year");
  };

  const formik = useFormik({
    initialValues: {
      firstName: editData?.firstName ?? userDetails?.firstName ?? "",
      lastName: editData?.lastName ?? userDetails?.lastName ?? "",
      dob: editData?.dob ?? "",
      age: editData?.dob ? calculateAge(editData?.dob) : null,
      gender: editData?.gender ?? "",
      race: editData?.race ?? "",
      ethnicity: editData?.ethinicity ?? "",
      clDemographicId: editData?.clDemographicId ?? null,
      clientId: editData?.clientId ?? null,
      appNumber: editData?.appNumber ?? null,
      createUserId: userDetails?.email ?? "",
      tribalTypeInd: editData?.tribalTypeInd ?? "",
      // pinNumber: Math.floor(1000 + Math.random() * 9000),
    },
    validationSchema: disabled ? null : formvalidation,
    onSubmit: (values) => {
      postYourInfo(values);
    },
    enableReinitialize: true,
  });

  const postYourInfo = async (values) => {
    if (formik.dirty) {
      try {
        openLoader("senior-demographic-info");
        let payload = {
          dirtyStatus: !appNumber ? "INSERT" : "UPDATE",
          firstName: values.firstName,
          lastName: values.lastName,
          dob: values.dob,
          age: values.age,
          gender: values.gender,
          race: values.race,
          ethinicity: values.ethnicity,
          clDemographicId: values?.clDemographicId,
          clientId: values?.clientId,
          appNumber: values?.appNumber,
          createUserId: values?.createUserId,
          tribalTypeInd: values?.tribalTypeInd,
        };
        const res = await axios.post(
          AUTH_API + "/seniors/demographicInfo",
          payload,
          {
            headers: headers,
          }
        );
        if (res && res.data) {
          toast.success(
            appNumber
              ? "Your Information Update Succesfully.."
              : "Your Information Added Succesfully.."
          );
          handleNext(res.data?.appNumber, res.data?.clientId);
        }
      } catch (error) {
        console.log(error);
        toast.error(
          appNumber
            ? "Your Information Update Failed.."
            : "Your Information Added Failed.."
        );
      } finally {
        closeLoader("senior-demographic-info");
      }
    } else {
      handleNext(_appNumber, _clientId);
    }
  };

  useEffect(() => {
    getYourInfo();
  }, []);

  const getYourInfo = async () => {
    try {
      openLoader("get-senior-demographic");
      const res = await axios.post(
        AUTH_API + `/seniors/demographicInfo/${_appNumber}`,
        {},
        {
          headers: headers,
        }
      );
      if (res && res.data) {
        setEditData(res.data);
        props.fetchDemographicData(res.data);
        if (res.data?.appNumber) {
          setAppNumber(res.data?.appNumber ?? "");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("get-senior-demographic");
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? dayjs(date).format("MM/DD/YYYY") : "";
    formik.setFieldValue("dob", formattedDate, true);
    const age = calculateAge(formattedDate);
    formik.setFieldValue("age", age);
  };

  // const handleDobChange = (event) => {
  //   const { $d } = event;
  //   // formik.setFieldValue("dob", value);
  //   const age = calculateAge($d);
  //   formik.setFieldValue("age", age);
  // };

  const handleGoBack = () => {
    navigate("/seniorDashboard");
  };

  const handleNext = (id, clientId) => {
    if (id && clientId) {
      navigate(`/contactInfo/${id}/${clientId}`, {
        state: { disabled: disabled },
      });
    } else if (id) {
      navigate(`/contactInfo/${id}`, {
        state: { disabled: disabled },
      });
    } else {
      navigate(`/contactInfo`, {
        state: { disabled: disabled },
      });
    }
  };

  function capitalizeFirstLetter(string) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <>
      <Typography className={classes.title}>{transProps.yourInfo}</Typography>
      <Grid container spacing={2} mt={1} flexDirection="column">
        <Grid item xs={12} md={8} lg={6}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            spacing={1}
          >
            <Typography
              sx={{
                textAlign: { xs: "start", sm: "right" },
              }}
              className={classes.typography}
            >
              {transProps.firstName} <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              name="firstName"
              size="small"
              placeholder={transProps.enterFname}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
              disabled={disabled}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} md={8} lg={6}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            spacing={1}
          >
            <Typography
              sx={{
                textAlign: { xs: "start", sm: "right" },
              }}
              className={classes.typography}
            >
              {transProps.lastName} <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              name="lastName"
              size="small"
              placeholder={transProps.enterLname}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
              disabled={disabled}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} md={8} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                spacing={1}
              >
                <Typography
                  sx={{
                    textAlign: { xs: "start", sm: "right" },
                  }}
                  className={classes.typography}
                >
                  {transProps.dob} <span style={{ color: "red" }}>*</span>
                </Typography>
                <DatePicker
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: Boolean(formik.touched.dob && formik.errors.dob),
                      size: "small",
                      helperText:
                        formik.touched.dob && formik.errors.dob
                          ? formik.errors.dob
                          : "",
                    },
                  }}
                  sx={{
                    ".css-k4qjio-MuiFormHelperText-root": {
                      marginLeft: "0 !important",
                    },
                  }}
                  views={["year", "month", "day"]}
                  name="dob"
                  type="date"
                  format="MM/DD/YYYY"
                  value={dayjs(formik.values.dob || "")}
                  onChange={handleDateChange}
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                spacing={1}
              >
                <Typography
                  sx={{
                    textAlign: { xs: "start", sm: "right" },
                  }}
                  className={classes.typography}
                >
                  {transProps.age}
                </Typography>
                <TextField
                  fullWidth
                  type="number"
                  name="age"
                  size="small"
                  placeholder={transProps.enterAge}
                  value={formik.values.age}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}></Grid>
        {formik.values.age && formik.values.age < 60 ? (
          <>
            <Grid item xs={12} md={6}>
              <Stack
                display="block"
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                spacing={2}
              >
                <Typography
                  pl={{ xs: 0, sm: "5rem" }}
                  className={classes.typography}
                >
                  {transProps.isAgeBelow60}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box sx={{ width: "100%" }} pl={{ xs: 0, sm: "4.4rem" }}>
                  <RadioGroup
                    row
                    {...formik.getFieldProps("tribalTypeInd")}
                    name="tribalTypeInd"
                  >
                    <FormControlLabel
                      value="Y"
                      control={<Radio disabled={disabled} />}
                      label={transProps.yes}
                    />
                    <FormControlLabel
                      value="N"
                      control={<Radio disabled={disabled} />}
                      label={transProps.no}
                    />
                  </RadioGroup>
                  <FormHelperText error>
                    {formik.errors.tribalTypeInd}
                  </FormHelperText>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={6}></Grid>
          </>
        ) : null}
        <Grid item xs={12} md={12} lg={12}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            spacing={2}
          >
            <Typography
              sx={{
                textAlign: { xs: "start", sm: "right" },
              }}
              className={classes.typography}
            >
              {transProps.gender}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box sx={{ width: "100%" }}>
              <RadioGroup row {...formik.getFieldProps("gender")} name="gender">
                <FormControlLabel
                  value="Male"
                  control={<Radio disabled={disabled} />}
                  label={transProps.male}
                />
                <FormControlLabel
                  value="Female"
                  control={<Radio disabled={disabled} />}
                  label={transProps.female}
                />
                <FormControlLabel
                  value="Decline to answer"
                  control={<Radio disabled={disabled} />}
                  label={transProps.delineAnswer}
                />
              </RadioGroup>
              <FormHelperText error>{formik.errors.gender}</FormHelperText>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                spacing={1}
              >
                <Typography
                  sx={{
                    textAlign: { xs: "start", sm: "right" },
                  }}
                  className={classes.typography}
                >
                  {transProps.race}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box width="100%" overflow="hidden">
                  <Select
                    fullWidth
                    size="small"
                    name="race"
                    value={formik.values.race}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={disabled}
                    helperText={formik.touched.race && formik.errors.race}
                  >
                    {map(races, (item) => (
                      <MenuItem value={item.lkpDesc}>
                        {item.lookupLongVal}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>{formik.errors.race}</FormHelperText>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                spacing={1}
              >
                <Typography
                  sx={{
                    textAlign: { xs: "start", sm: "right" },
                  }}
                  className={classes.typography}
                >
                  {transProps.ethnicity}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box width="100%" overflow="hidden">
                  <Select
                    fullWidth
                    size="small"
                    name="ethnicity"
                    value={formik.values.ethnicity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={disabled}
                  >
                    {map(ethnicity, (item) => (
                      <MenuItem value={item.lkpDesc}>
                        {item.lookupLongVal}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {formik.errors.ethnicity}
                  </FormHelperText>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} mt={3} justifyContent="flex-end">
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              onClick={handleGoBack}
            >
              {transProps.goBack}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={formik.handleSubmit}
            >
              {transProps.next}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
YourInformation.propTypes = {
  fetchDemographicData: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => ({
  fetchDemographicData: (payload) => dispatch(fetchDemographicData(payload)),
});
export default connect(undefined, mapDispatchToProps)(YourInformation);
