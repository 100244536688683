import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import * as actionType from "../../../redux/actionTypes/csaSignup";
import { Grid, Button, Typography, Box, Stack } from "@mui/material";
import { Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { maskMobile, popUpAlert } from "../../../utils/globalMethods";
import { useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Info } from "@mui/icons-material";
import { AUTH_API, headers } from "../../../config/index";
import axios from "axios";
import "./client.scss";
import { useSearchParams } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";

function ClientInfoComponent(props) {
  const transProps = useTranslator(
    [
      "pageTitle",
      "applicationId",
      "fstName",
      "lstName",
      "day",
      "month",
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
      "year",
      "email",
      "phone",
      "eligibilityStatus",
      "approved",
      "pending",
      "denied",
      "prev",
      "next",
    ],
    "clientInfo"
  );
  const [searchParams] = useSearchParams();
  const appId = searchParams.get("appId");

  let { agreementId, vendorId, clientId } = useParams();
  const [clientInfo, setClientInfo] = useState(props?.clientInfo);
  // eslint-disable-next-line no-unused-vars
  const [firstName, setFirstName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [lastName, setLastName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [chosenNam, setChosenNam] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [email, setEmail] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [day, setDay] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [month, setMonth] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [year, setYear] = useState("");
  const [showContent, setShowContent] = useState("DTA agency");
  const [isEdit, setIsEdit] = useState(true);
  const [isNext, setIsNext] = useState(false);
  const [formattedValue, setFormattedValue] = useState(""); //(342) 342-3432
  const [isPartialSignup, setIsPartialSignup] = useState(false);
  const [formData, setFormData] = useState();
  const [yearError, setYearError] = useState("");
  const [error, setError] = useState("");
  const [details, setDetails] = useState("");
  const [contactDetails, setContactDetails] = useState("");
  const [response, setResponse] = useState("");
  const [enableNext, setEnableNext] = useState(true);
  const params = useParams();
  const _appNumber = params?.appId ?? "";
  console.log(_appNumber, 4555);
  // const isNumber = (e) => /^[0-9]+$/.test(e.key);
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (isNext || isPartialSignup) {
      if (props?.csaSignup?.clientInfo?.clientInfo?.code) {
        setClientInfo({});
        props.setActiveTab("menuStep3");

        // popUpAlert({
        //   title: "Error",
        //   text: "Invalid Client Details",
        //   icon: "error",
        //   showCancelButton: false,
        //   showConfirmButton: true,
        //   confirmButtonClass: "btn-danger",
        //   confirmButtonText: "OK",
        //   closeOnConfirm: false,
        //   onConfirmed: true,
        // });
      } else {
        const clientInfo = props?.csaSignup?.clientInfo?.clientInfo;
        clientInfo.phoneno = formData?.phoneno
          ? formData?.phoneno
          : setFormattedValue(maskMobile(clientInfo?.phoneNumber));
        setClientInfo(clientInfo);
        if (Object.keys(clientInfo).length) {
          const clientData = {
            apid: clientInfo?.apid,
            apiId: formData?.apiId ? formData?.apiId : clientInfo?.apid,
            dob: clientInfo?.dob,
            email: clientInfo?.email,
            firstName: formData?.firstName
              ? formData?.firstName
              : clientInfo?.firstName,
            hhSize: clientInfo?.hhSize,
            lastName: formData?.lastName
              ? formData?.lastName
              : clientInfo?.lastName,
            chosenNam: formData?.chosenNam
              ? formData?.chosenNam
              : clientInfo?.chosenNam,
            panNumber: clientInfo?.panNumber,
            phoneNumber: clientInfo?.phoneNumber,
            snapAmount: clientInfo?.snapAmount,
            hipAmount: clientInfo?.hipAmount,
            ebtCardBalance: clientInfo?.ebtCardBalance,
            ebtCardBalanceAsOfDate: clientInfo?.ebtCardBalanceAsOfDate,
            day: formData?.day
              ? formData?.day
              : clientInfo?.dob
              ? clientInfo?.dob.split("-")[2]
              : "",
            month: formData?.month
              ? formData?.month
              : clientInfo?.dob
              ? parseInt(clientInfo?.dob.split("-")[1])
              : "",
            year: formData?.year
              ? formData?.year
              : clientInfo?.dob
              ? clientInfo?.dob.split("-")[0]
              : "",
            phoneno: formData?.phoneno
              ? formData?.phoneno
              : setFormattedValue(maskMobile(clientInfo?.phoneNumber)),
          };
          if (!formData?.ebt) {
            clientData.ebt = "DTA agency";
            clientData.apiId = clientInfo?.apid;
          }

          reset(clientData);
          setShowContent(
            clientInfo?.apid === clientInfo?.clientId
              ? "DTA agency"
              : "EBT card"
          );
          props.setClientInfo(clientData);
          props.setCSAFormValidate((prevState) => ({
            ...prevState,
            client_info: true,
          }));
          if (isNext) {
            props.setActiveTab("menuStep3");
          }
        }
      }
    }
  }, [props?.csaSignup?.clientInfo]);

  useEffect(() => {
    if (clientId && !clientInfo) {
      const obj = {
        apid: clientId,
        isPartialSignup: true,
        agreementId,
        vendorId,
      };
      props.getClientInfo(obj);
      setIsPartialSignup(true);
    } else {
      if (clientInfo) {
        clientInfo.phoneno = clientInfo?.phoneno
          ? setFormattedValue(maskMobile(clientInfo?.phoneno))
          : setFormattedValue(maskMobile(clientInfo?.phoneNumber));
        reset(clientInfo);
      }
    }
  }, [clientId]);

  const handleButtonClick = (data) => {
    setFormData(data);
    setIsNext(true);
    props.setCSAFormValidate((prevState) => ({
      ...prevState,
      client_info: true,
    }));
    props.setActiveTab("menuStep3");
    // const dob =
    //   data.year +
    //   "-" +
    //   (data.month < 10 ? "0" + data.month : data.month) +
    //   "-" +
    //   (data?.day?.length < 2 ? "0" + data.day : data.day);
    // const { firstName, lastName, ebt } = data;
    // const newData = {
    //   firstName,
    //   lastName,
    //   dob,
    //   apid: ebt === "DTA agency" ? data.apiId : null,
    //   panNumber: ebt === "EBT card" ? data.apiId : null,
    // };
    // props.getClientInfo(newData);
  };

  const handleRadioChange = (e) => {
    setShowContent(e.target.value.trim());
    setIsEdit(true);
  };

  let message = "";
  if (showContent === "EBT card") {
    message = "EBT Card Number";
  } else if (showContent === "DTA agency") {
    message = "DTA Agency ID";
  }

  const handleYearChange = (e) => {
    let value = e.target.value.trim();

    if (parseInt(value, 10) >= currentYear) {
      setYearError("Please enter a valid year");
      setYear("");
    } else {
      setYearError("");
      setYear(value);
    }
  };
  const handleChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/^0+/, "");

    if (inputValue !== "") {
      const intValue = parseInt(inputValue, 10);
      if (!isNaN(intValue) && intValue >= 1 && intValue <= 31) {
        inputValue = intValue < 10 ? `0${intValue}` : `${intValue}`;
        setError("");
      } else {
        setError("Please enter a valid day between 1 and 31.");
        inputValue = "";
      }
    }
    setDay(inputValue);
  };

  const get_details = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const res = await axios.post(
        AUTH_API + `/seniors/demographicInfo/${appId}`,
        {
          headers: headers,
        }
      );

      setDetails(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const get_phoneDetails = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const res = await axios.post(AUTH_API + `/seniors/contactInfo/${appId}`, {
        headers: headers,
      });
      setContactDetails(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const dateString = details?.dob;

  const date = new Date(dateString);
  const dayy = date.getDate();
  const monthh = date.getMonth() + 1;
  const yearr = date.getFullYear();

  const getEligibilityDetails = async () => {
    try {
      const res = await axios.post(
        AUTH_API + `/seniors/eligibilityDetails/getEligibility/${appId}`,
        {},
        { headers: headers }
      );
      setResponse(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    get_details();
    get_phoneDetails();
    getEligibilityDetails();
  }, [appId]);

  useEffect(() => {
    if (response?.EligibilityStatusCd === "AP") {
      setEnableNext(false);
    }
  }, [response]);

  // switch (response.EligibilityStatusCd) {
  //   case AP:
  //     day = "Approved";
  //     break;
  //   case PE:
  //     day = "Pending";
  //     break;
  //   case DE:
  //     day = "Denied";
  //     break;
  // }

  return (
    <Form onSubmit={handleSubmit(handleButtonClick)}>
      <Grid
        item
        lg={12}
        md={12}
        ml={4}
        className="wrapper-gird"
        sx={{
          border: "1px solid #CCCCCC",
          background: "#FFFF",
          height: "94vh",
        }}
      >
        <Box
          sx={{
            borderBottom: "1px solid #CCCCCC",
            direction: "flex",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            {/* <p style={{ marginLeft: "1rem" }}>client inf</p> */}
            <Typography
              variant="h5"
              xs={{ fontFamily: "texta-bold" }}
              className="sub-heading"
            >
              {transProps.pageTitle}
            </Typography>
            <Typography
              variant="h5"
              xs={{ fontFamily: "texta-bold" }}
              className="sub-heading"
            >
              {`${transProps.applicationId} : ${appId}`}
            </Typography>
          </Stack>
          {/* <Typography
            variant="h5"
            xs={{ fontFamily: "texta-bold" }}
            className="sub-heading"
          >
            Client Information
          </Typography>
          <Typography
            variant="h5"
            xs={{ fontFamily: "texta-bold" }}
            className="sub-heading"
          >
            Application ID :
          </Typography> */}
        </Box>
        <div style={{ padding: "20px" }} id="client-info">
          <Row className="mb-3">
            <Col xs={12} md={5}>
              <Form.Label className="firstName ">
                {transProps.fstName}
              </Form.Label>
              <Form.Control
                className="first-textfield"
                type="text"
                readOnly
                // {...register("firstName", { required: "Please enter First Name" })}
                defaultValue={details?.firstName}
                autoComplete="off"
                onChange={(e) => setFirstName(e.target.value.trim())}
              />
              {/* {errors.firstName && (
                <span className="dh-txt-danger">
                  {errors.firstName.message}
                </span>
              )} */}
            </Col>
            <Col xs={12} md={5}>
              <Form.Label className="lastName">{transProps.lstName}</Form.Label>
              <Form.Control
                className="last-textfield custom-input"
                type="text"
                readOnly
                // {...register("lastName", {
                //   required: "Please enter Last Name",
                // })}
                defaultValue={details?.lastName}
                autoComplete="off"
                onChange={(e) => setLastName(e.target.value.trim())}
              />
              {/* {errors.lastName && (
                <span className="dh-txt-danger">{errors.lastName.message}</span>
              )} */}
            </Col>
          </Row>
          <Row className="mb-3">
            {/* <Col xs={12} md={5} style={{ marginTop: "20px" }}>
              <Form.Label className="chosenName">
                Preferred/Chosen First Name{" "}
                <h6>
                  (name you prefer the vendor to call you by){" "}
                  <Tooltip
                    title="May be same or different than first name on SNAP case"
                    placement="top"
                  >
                    <IconButton>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </h6>
              </Form.Label>
              <Form.Control
                className="Chosen-textfield"
                type="text"
                {...register("chosenNam")}
                defaultValue={
                  clientInfo?.chosenNam ? clientInfo?.chosenNam : ""
                }
                autoComplete="off"
                onChange={(e) => setChosenNam(e.target.value.trim())}
                // style={{ fontSize: "12px" }}
              />
            </Col> */}
            <Col xs={12} md={5}>
              {/* {(errors.day || errors.month || errors.year) && (<p className='dateOfBirth'><ErrorIcon />&nbsp;Date of birth is required</p>)} */}
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                {/* {error && <span className='dh-txt-danger'>{error}</span>}
              {yearError && (
                 <div style={{ color: 'red' }}>{yearError}</div>
                   )} */}
                <Col md={3}>
                  <Form.Label className="DateOfBirth">
                    {transProps.day}*
                  </Form.Label>
                  <Form.Control
                    className="formControl"
                    type="text"
                    readOnly
                    // {...register("day", { required: true })}
                    // onKeyDown={isNumber}
                    onKeyPress={(e) => {
                      if (e.key < "0" || e.key > "9") {
                        e.preventDefault();
                      }
                    }}
                    maxLength={2}
                    defaultValue={dayy ? dayy : ""}
                    autoComplete="off"
                    // onChange={(e) => setDay(e.target.value.trim())}
                    onChange={handleChange}
                  />
                </Col>
                <Col md={6}>
                  <Form.Label className="DateOfBirth">
                    {transProps.month}*
                  </Form.Label>
                  <Form.Select
                    className="clearArrowSelect"
                    style={{ height: "43px" }}
                    value={monthh ? monthh : ""}
                    autoComplete="off"
                    // {...register("month", { required: true })}
                    onChange={(e) => {
                      setMonth(e.target.value);
                    }}
                    disabled
                  >
                    <option value="1">{transProps.january}</option>
                    <option value="2">{transProps.february}</option>
                    <option value="3">{transProps.march}</option>
                    <option value="4">{transProps.april}</option>
                    <option value="5">{transProps.may}</option>
                    <option value="6">{transProps.june}</option>
                    <option value="7">{transProps.july}</option>
                    <option value="8">{transProps.august}</option>
                    <option value="9">{transProps.september}</option>
                    <option value="10">{transProps.october}</option>
                    <option value="11">{transProps.november}</option>
                    <option value="12">{transProps.december}</option>
                  </Form.Select>
                </Col>
                <Col md={3}>
                  <Form.Label className="DateOfBirth">
                    {transProps.year}*
                  </Form.Label>
                  <Form.Control
                    // disabled={true}
                    readOnly
                    className="formcontrol-year"
                    type="text"
                    autoComplete="off"
                    // {...register("year", { required: true })}
                    // onKeyDown={isNumber}
                    onKeyPress={(e) => {
                      if (e.key < "0" || e.key > "9") {
                        e.preventDefault();
                      }
                    }}
                    maxLength={4}
                    defaultValue={yearr ? yearr : ""}
                    onChange={handleYearChange}
                  />
                </Col>
              </Row>
              {/* {(errors.day || errors.month || errors.year) && (
                <span className="dh-txt-danger">
                  Please enter Date of Birth
                </span>
              )}
              {error && <span className="dh-txt-danger">{error}</span>}
              {yearError && <span className="dh-txt-danger">{yearError}</span>} */}
            </Col>
            <Col xs={12} md={5}>
              <Form.Label className="email ">{transProps.email}</Form.Label>
              <Form.Control
                className="email-textfield lowercase"
                type="text"
                readOnly
                // {...register("email", {
                //   pattern: {
                //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                //     message: "Invalid Email format",
                //   },
                // })}
                onChange={(e) => {
                  setEmail(e.target.value.toLowerCase().trim());
                }}
                defaultValue={contactDetails?.contactNumber}
                autoComplete="off"
              />
              {/* {errors.email && (
                <span className="dh-txt-danger">{errors.email.message}</span>
              )} */}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={5}>
              <Form.Label className="phoneno">{transProps.phone} *</Form.Label>
              <Form.Control
                className="phoneNo-textField "
                readOnly
                // value={formattedValue}
                // {...register("phoneno", {
                //   required: true,
                //   pattern: {
                //     value: /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                //     message: "Invalid Phone number",
                //   },
                // })}
                type="Text"
                placeholder="(___) ___-____"
                // onChange={handleChange}
                // value={formattedValue}

                defaultValue={contactDetails?.contactNumber}
                onChange={(e) => {
                  setFormattedValue(maskMobile(e.target.value));
                }}
                // inputProps={{
                //   maxLength: 10
                // }}
                // maxLength={10}

                autoComplete="off"
              />
              {/* {errors.phoneno && (
                <span className="dh-txt-danger">
                  Please enter Primary Phone Number
                </span>
              )} */}
            </Col>
            <Col xs={12} md={5} style={{ marginTop: "0.6rem" }}>
              <Form.Label className="email ">
                {transProps.eligibilityStatus}
              </Form.Label>
              <Typography>
                {response?.EligibilityStatusCd === "AP"
                  ? transProps.approved
                  : response?.EligibilityStatusCd === "PE"
                  ? transProps.pending
                  : transProps.denied}
              </Typography>
            </Col>
          </Row>
        </div>

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            // border: "1px solid rgba(0, 0, 0, 0.125)",
            borderTop: "none",
            // backgroundColor: "#EEEEEE",
            height: "80px",
          }}
        >
          <Box
            sx={{
              padding: "10px",
              // background: "#eeeeee",
              borderTop: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              height: "80px",
            }}
          >
            <Button
              onClick={() => {
                props.setActiveTab("menuStep1");
                props.setClientInfo(getValues());
              }}
              sx={{
                backgroundColor: "#ffffff",
                color: "#457425",
                height: "50px",
                width: "100px",
                borderColor: "#457425",
                border: "solid",
                borderWidth: "1px",
                "&:hover": {
                  backgroundColor: "#457425",
                  color: "#ffffff",
                },
                "&:active": {
                  backgroundColor: "#457425",
                  color: "#ffffff",
                },
              }}
            >
              <ArrowBackIosNewIcon /> {transProps.prev}
            </Button>
            <Button
              sx={{
                backgroundColor: "#457425 !important",
                color: "white",
                height: "50px",
                width: "100px",
                marginLeft: "10px",
              }}
              type="submit"
              value="Submit"
              disabled={enableNext}
            >
              {" "}
              {transProps.next} <ArrowForwardIosIcon />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
}

ClientInfoComponent.propTypes = {
  setActiveTab: PropTypes.func.isRequired,
  getClientInfo: PropTypes.func.isRequired,
  csaSignup: PropTypes.object.isRequired,
  activeMenu: PropTypes.object,
  handleUpdate: PropTypes.func,
  setCSAFormValidate: PropTypes.func.isRequired,
  clientInfo: PropTypes.func,
  setClientInfo: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    getClientInfo: (payload) => dispatch(actionType.getClientInfo(payload)),
    getSnapHipAmount: (payload) =>
      dispatch(actionType.getSnapHipAmount(payload)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientInfoComponent);
