import React, { useEffect, useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BackupIcon from "@mui/icons-material/Backup";
import { makeStyles } from "@mui/styles";
import {
  Stack,
  Typography,
  Button,
  Grid,
  Checkbox,
  IconButton,
  Box,
  TextField,
  Popover,
  Divider,
  InputAdornment,
  Tooltip,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { DataGrid } from "@mui/x-data-grid";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as SNAP } from "../../assets/images/snap_img.svg";
import { ReactComponent as FOOD_BANK } from "../../assets/images/food_bank.svg";
import { ReactComponent as HOME_DELIVER } from "../../assets/images/home_deliver.svg";
import { ReactComponent as COMMODTIES } from "../../assets/images/commodities.svg";
import { ReactComponent as CONGREGATE } from "../../assets/images/congregate.svg";
import { ReactComponent as OTHER_FAP } from "../../assets/images/other_fap.svg";
import { map } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useFormik } from "formik";
import axios from "axios";
import { AUTH_API, headers } from "../../config";
import { familyWithIncome } from "./constant";
import DocumentUploadForm from "../../common/fileUpload/FileForm";
import FileUploadModal from "../../common/fileUpload/FileUploadModal";
import CommonLoading from "../common/components/commonLoading";
import useTranslator from "../../hooks/useTranslator";
import { fetchProgramInfo } from "../../redux/actionTypes/seniorInfo";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import dayjs, { Dayjs } from "dayjs";
import useLoader from "../../hooks/useLoader";
import toast from "react-hot-toast";
import AddMemberDialog from "./AddMemberDialog";
import useLookup from "../../hooks/useLookup";
import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px !important",
    fontWeight: "700 !important",
    color: "#6C6C6C",
  },
  typography: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
    // width: "30%",
    minWidth: "90px",
  },
  grid: {
    // width: "50rem !important",
    border: "1px solid #C2C2B7 !important",
    borderRadius: "5px",
  },
  incomeGrid: {
    border: "1px solid #C2C2B7",
    height: "100%",
    padding: "10px",
    borderRadius:'10px'
  },
}));

const ProgramIncome = (props) => {
  const transProps = useTranslator(
    [
      "pageTitle",
      "indicate",
      "snap",
      "seniorProjectFresh",
      "foodBank",
      "homeDelevery",
      "csfp",
      "congregate",
      "fap",
      "familyMembers",
      "incomeAmount",
      "goBack",
      "next",
      "familySize",
      "grossAnnual",
      "clickBtn",
      "fileUpload",
    ],
    "programIncome"
  );
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const _appNumber = params?.appNumber ?? "";
  _appNumber ? sessionStorage.setItem("appNumber", _appNumber) : "";
  const _clientId = params?.clientId ?? "";
  const { openLoader, closeLoader } = useLoader();
  const [docOpen, setDocOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [clientId, setClientId] = useState(null);
  const [allMembers, setAllMembers] = useState([]);
  const [addMember, setAddMember] = useState(false);
  const [editMember, setEditMember] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const { getOptions } = useLookup("verifyDocument");

  const location = useLocation();
  const disabled = location?.state?.disabled ?? false;

  const userLogin = sessionStorage.getItem("userLogin")
    ? JSON.parse(sessionStorage.getItem("userLogin"))
    : "";
  const fullName = `${userLogin?.firstName} ${userLogin?.lastName}`;
  const userRole = userLogin?.role;
  const {
    seniorInfoData: {
      demoGraphicData: { appNumber = "" } = {},
      programInfo: { createDt = "" },
    } = {},
  } = props;

  const programs = [
    {
      label: transProps.seniorProjectFresh,
      value: "SPF",
      image: <SNAP />,
    },
    {
      label: transProps.foodBank,
      value: "FB",
      image: <FOOD_BANK />,
    },
    {
      label: transProps.homeDelevery,
      value: "HDM",
      image: <HOME_DELIVER />,
    },
    {
      label: transProps.csfp,
      value: "CSFP",
      image: <COMMODTIES />,
    },
    {
      label: transProps.congregate,
      value: "CM",
      image: <CONGREGATE />,
    },
    {
      label: transProps.fap,
      value: "OF",
      image: <OTHER_FAP />,
    },
  ];

    const formvalidation = yup.object({
      familyMembers: yup.string().required("Please enter Family Members"),
      incomeAmount: yup.string().required("Please enter Income Amount"),
      verifiedDocument: yup.string().required("Please enter Verified Document"),
    });

  const formik = useFormik({
    initialValues: {
      programCode: editData?.programCd ?? "",
      programText: editData?.programText ?? "",
      verifiedDocument: editData?.verifiedDocument ?? "",
      familyMembers: editData?.householdSize ?? allMembers.length,
      incomeAmount: editData?.householdIncome ?? null,
      clientId: editData?.clientId ?? Number(_clientId) ?? null,
      clAddressId: editData?.clAddressId ?? null,
    },
    validationSchema: disabled ? null : formvalidation,
    onSubmit: (values) => {
      postYourInfo(values);
    },
    enableReinitialize: true,
  });

  const disableNext = formik.values.programCode.length >= 1;

  const postYourInfo = async (values) => {
    if (formik.dirty) {
      try {
        openLoader("farmers-program-save");
        let payload = {
          dirtyStatus: clientId ? "UPDATE" : "INSERT",
          clProgramId: values.clProgramId,
          clientId: values.clientId,
          programCd: values.programCode,
          programText: values.programText,
          verifiedDocument: values.verifiedDocument,
          deleteInd: "N",
          householdSize: allMembers.length || values.familyMembers,
          householdIncome: values?.incomeAmount
            ? Number(String(values.incomeAmount).replace(/,/g, ""))
            : null,
        };
        const res = await axios.post(
          AUTH_API + "/farmers/program/save",
          payload,
          {
            headers: headers,
          }
        );
        if (res && res.data) {
          handleNext();
          toast.success(
            clientId
              ? "Updated Program Succesfully"
              : "Added Program Succesfully"
          );
        }
      } catch (error) {
        console.log(error);
        toast.error(
          clientId ? "Updateing Program Succesfully" : "Adding Program Failed"
        );
      } finally {
        closeLoader("farmers-program-save");
      }
    } else {
      handleNext();
    }
  };

  useEffect(() => {
    getProgramInfo();
    getMembers();
  }, []);

  const { documentOpts, relationOpts } = useMemo(() => {
    return {
      documentOpts: getOptions("verifyDocument"),
      relationOpts: getOptions("relationShip"),
    };
  }, [getOptions]);

  const getProgramInfo = async () => {
    try {
      openLoader("farmer-program-info");
      const res = await axios.post(
        AUTH_API + `/farmers/programInfo/${_appNumber}`,
        {},
        {
          headers: headers,
        }
      );
      if (res && res.data) {
        setEditData(res.data);
        props.fetchProgramInfo(res.data);
        if (res.data?.clientId) {
          setClientId(res.data?.clientId ?? "");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("farmer-program-info");
    }
  };

    const getMembers = async () => {
      try {
        openLoader("farmer-member-info");
        const res = await axios.get(
          AUTH_API + `/senior/getAllMember/${_appNumber}`,
          {
            headers: headers,
          }
        );
        if(res.status == 200){
          setAllMembers(res.data)
        }
      } catch (error) {
        console.log(error);
      } finally {
        closeLoader("farmer-member-info");
      }
    };

  const isCheckboxDisabled = (itemValue) => {
    return formik.values.programCode && formik.values.programCode !== itemValue;
  };

  const handleIncrement = () => {
    const currentValue = parseInt(formik.values.familyMembers, 10);
    if (currentValue < 25) {
      formik.setFieldValue("familyMembers", String(currentValue + 1));
    } else {
      toast.error("Maximum family members count is 25");
    }
  };

  const handleDecrement = () => {
    const currentValue = parseInt(formik.values.familyMembers, 10);
    if (currentValue > 1) {
      formik.setFieldValue("familyMembers", String(currentValue - 1));
    }
  };

  function formatCurrency(value) {
    let converToCurrency = value.toString().replace(/\D/g, "");
    // converToCurrency = converToCurrency.replace(/(\d)(\d{2})$/, "$1.$2");
    // converToCurrency = converToCurrency.replace(/(?=(\d{3})+(\D))\B/g, ",");
    return converToCurrency;
  }

  const handlePayAmount = (event) => {
    const { value } = event.target;
    let usFormatCurrency = formatCurrency(value);
    formik.setFieldValue("incomeAmount", usFormatCurrency);
  };

  const handleGoBack = () => {
    if (_appNumber && _clientId) {
      navigate(`/contactInfo/${_appNumber}/${_clientId}`, {
        state: { disabled: disabled },
      });
    } else {
      navigate(`/contactInfo`, {
        state: { disabled: disabled },
      });
    }
  };

  const handleNext = () => {
    navigate(`/certification/${_appNumber}/${_clientId}`, {
      state: { disabled: disabled },
    });
  };

  const handleModalOpen = () => {
    setDocOpen(true);
  };

  const handleEdit = (data) =>{
    setSelectedMember(data)
    setEditMember(!editMember)
  }

  // const rows = [
  //   {
  //     id: 1,
  //     name: "Vairamani",
  //     relation: "Brother",
  //     age: "27",
  //     gender: "Male",
  //     address: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
  //   },
  //   {
  //     id: 2,
  //     name: "Anand Kumar",
  //     relation: "Brother",
  //     age: "29",
  //     gender: "Male",
  //     address: "2972 Westheimer Rd. Santa Ana, Illinois 85486",
  //   },
  // ];

    const handleDelete = async (data) => {
        console.log('data', data);
      try {
        openLoader("delete-member-info");
        const res = await axios.delete(
          AUTH_API + `/senior/deleteSubMember/${data.clientId}`, {},
          {
            headers: headers,
          }
        );
        if(res.status == 200){
          toast.success("Deleted Successfully!")
          getMembers()
        }
      } catch (error) {
        console.log(error);
      } finally {
        closeLoader("delete-member-info");
      }
    }

    const columns = [
      {
        headerName: "Name",
        // width: 200,
        flex: 1,
        renderCell: (params) => {
          return `${params?.row?.firstName || ""} ${
            params?.row?.lastName || ""
          }`;
        },
      },
      // {
      //   field: "relation",
      //   headerName: "Relationship",
      //   width: 150,
      //   editable: false,
      // },
      {
        field: "dob",
        headerName: "Date of birth",
        // width: 120,
        flex: 1,
      },
      {
        field: "gender",
        headerName: "Gender",
        // width: 120,
        flex: 1,
      },
      {
        field: "createUserId",
        headerName: "Email",
        // width: 250,
        flex: 1,
      },
      // {
      //   field: "address",
      //   headerName: "Address",
      //   width: 250,
      //   editable: false,
      // },
      {
        field: "actions",
        headerName: "Actions",
        // width: 150,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          if (!params.row.hoh) {
            return (
              <>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleEdit(params.row)}
                  disabled={disabled}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete(params.row)}
                  disabled={disabled}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            );
          } else {
            return <></>;
          }
        },
      },
    ];

  const handleClose = () => {
    setAddMember(false)
    setEditMember(false);
  }

  return (
    <>
      <Typography className={classes.title}>{transProps.pageTitle}</Typography>
      <Typography className={classes.typography} mt={3}>
        {transProps.indicate}
      </Typography>
      <Grid container mt={3} spacing={2}>
        <Grid item xs={12} md={12} lg={9}>
          <Grid container className={classes.grid} p={0.5}>
            {map(programs, (item) => (
              <Grid item xs={12} sm={6} md={3} lg={2} p={1}>
                <Box
                  borderRadius={2}
                  border={2}
                  borderColor={"green"}
                  minHeight={120}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Checkbox
                      name="programCode"
                      color="success"
                      value={item?.value}
                      checked={formik?.values?.programCode === item?.value}
                      disabled={disabled || isCheckboxDisabled(item.value)}
                      onChange={(e) => (
                        formik.setFieldValue(
                          "programCode",
                          e.target.checked ? item.value : ""
                        ),
                        formik.setFieldValue(
                          "programText",
                          e.target.checked ? item.label : ""
                        )
                      )}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {item?.image}
                    <Typography
                      textAlign={"center"}
                      className={classes.typography}
                    >
                      {item?.label}
                    </Typography>
                  </div>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Grid item lg={12} mt={2}>
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="b">
                Members
              </Typography>
              <Button onClick={() => setAddMember(true)} disabled={disabled}>
                + Add Member
              </Button>
            </Stack>
            <Box sx={{ height: 340, width: "100%", overflowX: "auto" }}>
              <DataGrid
                rows={allMembers}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontSize: "15px",
                    fontWeight: "600",
                  },
                }}
                loading={false}
                rowHeight={50}
                rowsPerPageOptions={[5, 10, 15]}
                checkboxSelection={false}
                getRowId={(row) => row.clientId}
                disableRowSelectionOnClick={true}
                disableColumnMenu={true}
                slots
              />
            </Box>
          </Grid>
        </Grid>

        {/* <Grid item xs={12} md={6} mt={2}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                spacing={1}
              >
                <Typography
                  sx={{
                    textAlign: { xs: "start", sm: "right" },
                  }}
                  className={classes.typography}
                >
                  {transProps.familyMembers}
                </Typography>
                <Box>
                  <IconButton disabled={disabled}>
                    <RemoveIcon onClick={handleDecrement} />
                  </IconButton>
                  <TextField
                    sx={{ maxWidth: "50px", mx: 1 }}
                    size="small"
                    name="familyMembers"
                    value={formik.values.familyMembers}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={disabled}
                  />
                  <IconButton disabled={disabled}>
                    <AddIcon onClick={handleIncrement} />
                  </IconButton>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                spacing={1}
              >
                <Typography className={classes.typography}>
                  {transProps.incomeAmount}
                </Typography>
                <TextField
                  fullWidth
                  placeholder="0"
                  name="incomeAmount"
                  sx={{ mx: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  value={formik.values.incomeAmount}
                  onChange={(event) => handlePayAmount(event)}
                  size="small"
                  inputProps={{ maxLength: 11 }}
                  disabled={disabled}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}></Grid> */}
        <Grid item xs={12} md={12} lg={3}>
          <Stack spacing={1} className={classes.incomeGrid}>
            <Box>
              <Typography className={classes.typography}>
                {transProps.familyMembers}{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                fullWidth
                name="familyMembers"
                value={formik.values.familyMembers}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={disabled}
                error={
                  formik.touched.familyMembers &&
                  Boolean(formik.errors.familyMembers)
                }
                helperText={
                  formik.touched.familyMembers && formik.errors.familyMembers
                }
              />
            </Box>
            <Box>
              <Typography className={classes.typography}>
                {transProps.incomeAmount}{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                fullWidth
                placeholder="0"
                name="incomeAmount"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.incomeAmount}
                onChange={(event) => handlePayAmount(event)}
                size="small"
                inputProps={{ maxLength: 11 }}
                disabled={disabled}
                error={
                  formik.touched.incomeAmount &&
                  Boolean(formik.errors.incomeAmount)
                }
                helperText={
                  formik.touched.incomeAmount && formik.errors.incomeAmount
                }
              />
            </Box>
            <Box>
              <Typography className={classes.typography}>
                Verified Document <span style={{ color: "red" }}>*</span>
              </Typography>
              <Select
                fullWidth
                size="small"
                name="verifiedDocument"
                value={formik.values.verifiedDocument}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.verifiedDocument &&
                  Boolean(formik.errors.verifiedDocument)
                }
                helperText={
                  formik.touched.verifiedDocument &&
                  formik.errors.verifiedDocument
                }
              >
                {map(documentOpts, (item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {formik.errors.verifiedDocument}
              </FormHelperText>
            </Box>
            {/* <Box>
              <Typography className={classes.typography}>
                Upload a document
              </Typography>
              <Button
                onClick={handleModalOpen}
                sx={{
                  width: "100%",
                  border: "1px solid #44A0E3 !important",
                  color: " var(--Secon-1-D-Blue, #44A0E3)",
                  fontSize: "14px !important ",
                  fontWeight: "700 !important",
                }}
                disabled={disabled}
              >
                <UploadFileIcon />
                {transProps.fileUpload}
              </Button>
            </Box> */}
            <Box
              style={{
                height: "100%",
                width: "100%",
                background: "#eeeeef",
                border: "3px dashed #C2C2B7",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  // justifyContent: "center",
                }}
              >
                <BackupIcon
                  sx={{
                    fontSize: 100,
                    color: "#d9d9d9",
                  }}
                />
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "700",
                    fontSize: "1.2rem",
                    color: "#6C6C6C",
                  }}
                >
                  Upload document
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <span
                    style={{
                      border: "1px solid #e0e0e0",
                      width: "9rem",
                      height: "0px !important",
                    }}
                  />
                </Stack>
                {/* <Button
                  sx={{
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: "700",
                    textTransform: "capitalize !important",
                    padding: "10px 20px",
                    border: "2px solid #4096d6",
                    borderRadius: "20px",
                  }}
                >
                  Browse Files
                </Button> */}
                <Button
                  onClick={handleModalOpen}
                  sx={{
                    marginTop: "1rem",
                    border: "1px solid #44A0E3 !important",
                    color: " var(--Secon-1-D-Blue, #44A0E3)",
                    fontSize: "14px !important ",
                    fontWeight: "700 !important",
                  }}
                  disabled={disabled}
                >
                  <UploadFileIcon />
                  {transProps.fileUpload}
                </Button>
              </div>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} mt={3}>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            {/* <Typography className={classes.typography}>
              {transProps.clickBtn}
            </Typography>
            <Button
              onClick={handleModalOpen}
              sx={{
                border: "1px solid #44A0E3 !important",
                color: " var(--Secon-1-D-Blue, #44A0E3)",
                fontSize: "14px !important ",
                fontWeight: "700 !important",
              }}
              disabled={disabled}
            >
              <UploadFileIcon />
              {transProps.fileUpload}
            </Button> */}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} mt={3}>
          <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              onClick={handleGoBack}
            >
              {transProps.goBack}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={formik.handleSubmit}
              disabled={!disableNext}
            >
              {transProps.next}
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {/* <TextField
            type="number"
            sx={{ mx: 1 }}
            size="small"
            name="incomeAmount"
            value={formik.values.incomeAmount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          /> */}
      {/* <Tooltip
            title={
              <React.Fragment>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography variant="body1">
                    {transProps.familySize}
                  </Typography>
                  <Typography variant="body1">
                    {transProps.grossAnnual}
                  </Typography>
                  <Divider />
                </Stack>
                {map(familyWithIncome, (element) => (
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="body1">
                      {element.familySize}
                    </Typography>
                    <Typography variant="body1">
                      {element.grossAnnual}
                    </Typography>
                    <Divider />
                  </Stack>
                ))}
              </React.Fragment>
            }
            placement="right"
            arrow
          >
            <TextField
              placeholder="0.00"
              name="incomeAmount"
              sx={{ mx: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={formik.values.incomeAmount}
              onChange={(event) => handlePayAmount(event)}
              size="small"
              inputProps={{ maxLength: 11 }}
              disabled={disabled}
            />
          </Tooltip> */}
      <FileUploadModal
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        payload={{
          createDt: dayjs().format("YYYY/MM/DD"),
          createUserId: fullName,
          role: userRole,
          sourceCaseId: _appNumber,
        }}
      />
      {addMember && (
        <AddMemberDialog
          open={addMember}
          handleClose={handleClose}
          appNumber={_appNumber}
          getMembers={getMembers}
          relationOpts={relationOpts}
        />
      )}
      {editMember && (
        <AddMemberDialog
          editData
          selectedMember={selectedMember}
          open={editMember}
          handleClose={handleClose}
          appNumber={_appNumber}
          getMembers={getMembers}
          relationOpts={relationOpts}
        />
      )}
    </>
  );
};

ProgramIncome.propTypes = {
  fetchProgramInfo: PropTypes.func,
  seniorInfoData: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  fetchProgramInfo: (payload) => dispatch(fetchProgramInfo(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProgramIncome);
