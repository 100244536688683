import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const CardTypeInput = ({ label, name, register, errors, value }) => {
    return (
        <>
            <label className="dh-form-label font-bold" htmlFor={name}>{label} *</label>
            <FormControl>
                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group"
                >
                    <FormControlLabel  {...register(name, {
                        required: `${label} is required`,
                    })} checked={value === "ebt"} name={name} value="ebt" control={<Radio />} label="EBT Card Number" />
                    <FormControlLabel  {...register(name, {
                        required: `${label} is required`,
                    })} name={name} checked={value === "apid"} value="apid" control={<Radio />} label="DTA Agency ID" />
                </RadioGroup>
            </FormControl>
            {errors.card && (
                <div className="dh-txt-danger">
                    {errors.card.message}
                </div>
            )}
        </>
    );
};
CardTypeInput.propTypes = {
    register: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value:PropTypes.string 
};
export default CardTypeInput;
