import axiosInstance from "../interceptor";
import { takeLatest, call, put } from "redux-saga/effects"; //takeEvery
import { actionTypes } from "../actionTypes/auth";
import { API_URL, AUTH_API } from "../../config";
import { logout, pageRefresh,popUpAlert } from "../../utils/globalMethods";

function* getAdminToken(props) {
    let url =  `${AUTH_API}/oauth/token?grant_type=password&username=${props.payload.username}&password=${props.payload.password}`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri);
        sessionStorage.setItem("access_token",result.data.access_token);
        sessionStorage.setItem("refresh_token",result.data.refresh_token);
        sessionStorage.setItem("username",props.payload.username);
        yield put({ type: actionTypes.SET_AUTHENTICATE_USER, payload: props.payload.username });
        yield put({ type: actionTypes.SET_AUTHENTICATE_USER_ERROR, payload: {status:"success",message:""}});
    }
    catch {
        yield put({ type: actionTypes.SET_AUTHENTICATE_USER_ERROR, payload: {status:"error",message:"Username and/or Password are incorrect. Please try again"} });
        console.log("Failed");
    } 
}

function* tokenRevoke(props) {
    let url = `${API_URL}/oauth/revoke`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri,props.payload);
        if (result) {
           logout();
           yield put ({type: actionTypes.SET_TOKEN_REVOKE, payload: { status: "SUCCESS"}})
        }
    }
    catch {
        console.log("Failed");
    }
}
function* refreshAccessToken(props) {
    let url =  `${AUTH_API}/oauth/token?grant_type=refresh_token&refresh_token=${props.payload.refreshToken}`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri);
        sessionStorage.setItem("access_token",result.data.access_token);
        sessionStorage.setItem("refresh_token",result.data.refresh_token);
    } 
    catch {
        console.log("Failed");
    }
}

function* getUserToken(props) {
    let url =  `${AUTH_API}/oauth/token?grant_type=password&username=${props.payload.username}&vendorprimephonenumber=${props.payload.phone}&vendorhipid=${props.payload.hipid}`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri);
        sessionStorage.setItem("access_token",result.data.access_token);
        sessionStorage.setItem("refresh_token",result.data.refresh_token);
        sessionStorage.setItem("username",props.payload.username);
        yield put({ type: actionTypes.SET_AUTHENTICATE_USER, payload: props.payload.username });
        yield put({ type: actionTypes.SET_AUTHENTICATE_USER_ERROR, payload: {status:"success",message:""}});
    } 
    catch {
        yield put({ type: actionTypes.SET_AUTHENTICATE_USER_ERROR, payload: {status:"error",message:"Email, Phone Number and/or HIP Organization ID are incorrect. Please try again"} });
        console.log("Failed");
    }
}

function* getAuthUserDetails(props) {
    // let url =  `${API_URL}/user/getUser`;
    // try {
    //     const uri = url;
    //     const result = yield call(axiosInstance.post, uri, props.payload);
    //     const userData = {"Name":result.data.data[0].firstNam +" "+result.data.data[0].lastNam,"Role":result.data.data[0].hipUserRoleCdCodeK,"id":result.data.data[0].id, "vendorId": result.data.data[0].hipUserRoleCdCodeK=="VENDOR" ? result?.data?.data?.[0]?.hipVendorIdk : null};
    //     sessionStorage.setItem("userData",JSON.stringify(userData));
    //     if(result.data.data[0].hipUserRoleCdCodeK=="ADMIN" || result.data.data[0].hipUserRoleCdCodeK=="HIPCRD"){
    //         props.payload.navigate("/kfreshfep/admin-dashboard");
    //     }else if(result.data.data[0].hipUserRoleCdCodeK=="VENDOR"){ 
    //         props.payload.navigate("/kfreshfep/mystore", {state:{hipVendorIdk:result?.data?.data?.[0]?.hipVendorIdk}});
    //     }        
    //     yield put({ type: actionTypes.SET_HIP_USER_DETAILS, payload: userData});
    // } 
    // catch {
    //     console.log("Failed");
    // }
    if (props.payload.ext_url) {
        let url =  `${AUTH_API}/${props.payload.ext_url}/login`;
        try {
            const uri = url;
            const token = sessionStorage.getItem("access_token");
            let config;
            if(!token){
                config = {
                    headers: {
                    "Authorization": "Bearer "+ props.payload.currentAccount.idToken,
                    }
                }
            }
            let data = {
                email:props.payload.email
            }
            const result = yield call(axiosInstance.post, uri, data, config);
            const userData = {"Name":result.data.userData.firstName +" "+result.data.userData.lastName,"Role":result.data.userData.role,"id":result.data.userData.id,"vendorId":result.data.userData.vendorId};
            sessionStorage.setItem("ext_url",props.payload.ext_url);
            sessionStorage.setItem("userData",JSON.stringify(userData));
            if(props.payload.ext_url == 'csa'){
                const myAccount = props.payload.currentAccount.homeAccountId;
                sessionStorage.setItem("accountInfo", JSON.stringify(myAccount));
                sessionStorage.setItem("access_token", props.payload.currentAccount.idToken);
                sessionStorage.setItem("username",props.payload.email);
                if(result.data.userData.role=="ADMIN" || result.data.userData.role=="HIPCRD"){
                    props.payload.navigate("/kfreshfep/admin-dashboard");
                }else if(result.data.userData.role=="CSAVDR" || result.data.userData.role=="SUBVDR"){
                    props.payload.navigate("/kfreshfep/mystore/csa");
                }
            }else if(props.payload.ext_url == 'res'){
                if(result.data.userData.role=="HIPVDR"){
                    props.payload.navigate("/kfreshfep/mystore/hip");
                }
            }
            // if(result.data.data[0].hipUserRoleCdCodeK=="ADMIN" || result.data.data[0].hipUserRoleCdCodeK=="HIPCRD"){
            //     props.payload.navigate("/kfreshfep/admin-dashboard");
            // }else if(result.data.data[0].hipUserRoleCdCodeK=="VENDOR"){ 
            //     props.payload.navigate("/kfreshfep/mystore", {state:{hipVendorIdk:result?.data?.data?.[0]?.hipVendorIdk}});
            // } 
            yield put({ type: actionTypes.SET_HIP_USER_DETAILS, payload: userData});
            pageRefresh();
        } 
        catch (e) {
            if (e?.response?.data?.code == "713") {
                logout();
                popUpAlert({
                    title: "Error",
                    text: "Invalid Credential",
                    icon: "error",
                    timer: 1500,
                    showCancelButton: false,
                    showConfirmButton: false
                });
                yield put ({type: actionTypes.SET_TOKEN_REVOKE, payload: { status: "SUCCESS"}});
            }else{
                if(props.payload.ext_url == 'csa'){
                    const myAccount = props.payload.currentAccount;
                    sessionStorage.setItem("accountInfo", JSON.stringify(myAccount));
                    sessionStorage.setItem("access_token", props.payload.currentAccount.idToken);
                    sessionStorage.setItem("username",props.payload.email);
                    props.payload.navigate("/kfreshfep/registration", {state:{vendorData:props.payload.currentAccount}});
                }
            
            }
        }
    }
}

export const watchTokenRevokeSaga = function*() { 
    yield takeLatest(actionTypes.TOKEN_REVOKE, tokenRevoke);
};

export const watchGetAdminTokenSaga = function*() {
    yield takeLatest(actionTypes.AUTHENTICATE_ADMIN_USER, getAdminToken);
};

export const watchRefreshAdminTokenSaga = function*() {
    yield takeLatest(actionTypes.REFRESH_ACCESS_TOKEN, refreshAccessToken);
};

export const watchGetUserTokenSaga = function*() {
    yield takeLatest(actionTypes.AUTHENTICATE_HIP_USER, getUserToken);
};

export const watchGetAuthUserDetails = function*() {
    yield takeLatest(actionTypes.HIP_USER_DETAILS, getAuthUserDetails);
};