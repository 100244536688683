import React, { useState, useEffect } from "react";
import { Grid, Box, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import CommonLoading from "../common/components/commonLoading";

import { CustomButton } from "../common/components/customButton";
import PageHeaderComponent from "../common/components/pageHeader";

import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";

import SelectDropdown from "../common/selectdropdown-component";

import "../../styles/home.scss";
import TotalCardComponent from "./totalCard";
import GraphComponent from "./graphs";
import { GET_COUNTYCITY } from "../../redux/actionTypes";
import { sortName, checkToken } from "../../utils/globalMethods";
import {
  getVendorStats,
  getStoreStats,
} from "../../redux/actionTypes/adminDashboard";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import useTranslator from "../../hooks/useTranslator";
import { useTranslation } from "react-i18next";
import MarketDashboard from "./MarketDashboard";
import SeniorDashboard from "./SeniorDashboard";

const AdminComponent = (props) => {
  const navigate = useNavigate();

  const [refreshKey, setRefreshKey] = useState(0);
  const [city, setCity] = useState({ name: "Statewide", values: "Statewide" });
  const [cityList, setCityList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("FA");

  useEffect(() => {
    props.getCityList();
  }, []);

  useEffect(() => {
    const userInfo = props?.userAuth?.user
      ? props?.userAuth?.user
      : JSON.parse(sessionStorage.getItem("userData"));
    if (
      props?.allowedRoles &&
      userInfo &&
      userInfo !== null &&
      Object.keys(userInfo).length != 0
    ) {
      const checkRole = props.allowedRoles.find((role) =>
        userInfo?.Role.includes(role)
      );
      if (!checkRole) {
        navigate("/kfreshfep/admin-dashboard");
        return;
      }
    }
    setIsLoading(true);
    const payload = {
      cityId: city.name === "Statewide" ? "" : city.name,
      // createTimeStamp: 1710347284338,
      // ipAddress: "1",
      // lastUpdtTimeStamp: 1710347284338,
      // lastUpdtUidNam: "ADM_2060",
    };
    // if (checkToken()) {
    //   props.getVendorStats(payload);
    //   props.getStoreStats(payload);
    // }
    props.getVendorStats(payload);
    props.getStoreStats(payload);
  }, [refreshKey, city]);

  useEffect(() => {
    setIsLoading(false);
  }, [props.adminDashboard.statisticsData]);

  const { stats, statisticsData } = props.adminDashboard;
  
  const chartData = [
    // { value: statisticsData.totalCsaPickupSite, name: "SPF Program" },
    { value: statisticsData.totalStoreCount, name: "SPF Roadside Stand" },
    // {
    //   value: statisticsData.totalFarmersMarket,
    //   name: "SPF Farmers' Market Booth",
    // },
    {
      value: statisticsData.totalFarmersMarket,
      name: "SPF Farmers' Market",
    },
    // { value: statisticsData.totalMobileMarket, name: "SPF Mobile Market Stop" },
  ];

  const barChart = () => {
    let data = [];
    // data.push([
    //   "City",
    //   // "SPF Program",
    //   "SPF Roadside Stand",
    //   // "SPF Farmers' Market Booth",
    //   "SPF Farmers' Market",
    //   // "SPF Mobile Market Stop",
    // ]);
    let MICites = [
      {
        cityName: "Ingham",
        totalCsaPickupSite: 4,
        totalFarmersMarket: 2,
      },
      {
        cityName: "Oakland",
        totalCsaPickupSite: 6,
        totalFarmersMarket: 8,
      },
      {
        cityName: "Wayne",
        totalCsaPickupSite: 2,
        totalFarmersMarket: 3,
      },
    ];

    
statisticsData.totalCity.forEach((ele, i) => {
  // let randomMICity = MICites[Math.floor(Math.random() * MICites.length)];
  // MICites.splice(MICites.indexOf(randomMICity), 1);
  data.push([
    ele.cityName,
    // ele.totalCsaPickupSite,
    ele.totalCsaPickupSite,
    // ele.totalFarmersMarket,
    ele.totalFarmersMarket,
    // ele.totalMobileMarket,
  ]);
});
    return data;
  };

  // const heatChart = () => {
  //   let data = [];
  //   let maxNum = 0;
  //   statisticsData?.totalMonths.forEach((ele) => {
  //     var randomNum = Math.floor(Math.random() * 10 + 1);
  //     var randomNum1 = Math.floor(Math.random() * 10 + 1);
  //     let month = ele.month - 1;
  //     maxNum = Math.max(
  //       ...[
  //         maxNum,
  //         randomNum1,
  //         randomNum,
  //         ele.totalFarmersMarket,
  //         ele.totalFarmersMarketManagers,
  //         ele.totalMobileMarket,
  //       ]
  //     );
  //     data.push(
  //       [month, 0, randomNum1],
  //       [month, 1, randomNum],
  //       [month, 2, ele.totalFarmersMarket],
  //       [month, 3, ele.totalFarmersMarketManagers],
  //       [month, 4, ele.totalMobileMarket]
  //     );
    
  //   });

  //   return { data: data, maxNumber: maxNum };
  // };

    const heatChart = () => {
      let data = [];
      let maxNum = 0;
      statisticsData.totalMonths.forEach((ele) => {
        let month = ele.month - 1;
        maxNum = Math.max(
          ...[
            maxNum,
            ele.totalCsaPickupSite,
            // ele.totalFarmStand,
            ele.totalFarmersMarket,
            // ele.totalFarmersMarketManagers,
            // ele.totalMobileMarket,
          ]
        );
        data.push(
          [month, 0, ele.totalCsaPickupSite],
          // [month, 1, ele.totalFarmStand],
          [month, 2, ele.totalFarmersMarket],
          // [month, 3, ele.totalFarmersMarketManagers],
          // [month, 4, ele.totalMobileMarket]
        );
      });

      return { data: data, maxNumber: maxNum };
    };

  useEffect(() => {
    let cityOption = props.mapdata.city
    .filter((item) => item.cityName.includes("County")).map((item) => {
      return {
        name: item.id,
        values: item.cityName,
        isActive: 1,
      };
    });
    let defaultData = [{ name: "Statewide", values: "Statewide" }];
    setCityList(defaultData.concat(sortName(cityOption)));
  }, [props.mapdata.city]);

   const legend = [
     // "SPF Program",
     "SPF Roadside Stand",
     // "SPF Farmers' Market Booth",
     "SPF Farmers' Market",
     // "SPF Mobile Market Stop",
   ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} className="dashboard-block">
        {isLoading && <CommonLoading />}
        <Grid item xs={12} md={6}>
          <PageHeaderComponent
            title={"Dashboard - Statewide View"}
            showSubtitle={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={{ xs: 2, md: 0.5 }}
            sx={{ flexGrow: 0.1 }}
          >
            <FormControl sx={{ width: 250 }} size="small">
              <SelectDropdown
                value={city}
                // onChange={(v) => {
                //   setCity(v);
                // }}
                options={cityList}
                startIcon={<SearchIcon />}
                isSearch={true}
                defaultValueProps={{
                  default: true,
                  defaultOption: { name: "Statewide", values: "Statewide" },
                }}
              />
            </FormControl>
            <CustomButton
              variant="outlined"
              startIcon={<i className="fa fa-refresh" aria-hidden="true"></i>}
              onClick={() => setRefreshKey(refreshKey + 1)}
            >
              Refresh
            </CustomButton>
          </Stack>
        </Grid>
      </Grid>

      <Stack p="1rem">
        <Tabs
          id="controlled-tab"
          activeKey={activeTab}
          onSelect={setActiveTab}
          style={{ background: "#fff" }}
        >
          <Tab
            eventKey="FA"
            title={
              <div className="tab-header">
                {/* <i className="fa fa-hourglass-end"></i> */}
                <span className="m-l-5">Farmers</span>
              </div>
            }
          >
            <Grid container spacing={2} className="dashboard-block">
              <Grid item xs={12} lg={4}>
                <TotalCardComponent
                  total={stats.vendorTotalCount}
                  subtitle={"Total Farmers (statewide)"}
                  status="info"
                />
              </Grid>
              <Grid item xs={12} lg={4} tabIndex={"0"}>
                <TotalCardComponent
                  total={stats.unverifiedCount}
                  subtitle={"Total Un-Verified Farmers (statewide)"}
                  status="warning"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TotalCardComponent
                  total={stats.verifiedCount}
                  subtitle={"Verified Count"}
                  status="success"
                />
              </Grid>
              <Grid item xs={6}>
                <GraphComponent
                  title={"SPF Locations"}
                  graphType={"pie"}
                  chartData={chartData}
                  legend={legend}
                />
              </Grid>
              <Grid item xs={6}>
                <GraphComponent
                  title={"Total SPF Locations By County"}
                  graphType={"bar"}
                  chartData={barChart()}
                  legend={legend}
                />
              </Grid>
              <Grid item xs={12}>
                <GraphComponent
                  title={"Month(s) of Operation By Type"}
                  graphType={"heat"}
                  chartData={heatChart().data}
                  maxNumber={heatChart().maxNumber}
                  legend={legend}
                />
              </Grid>
            </Grid>
          </Tab>
          <Tab
            eventKey="SE"
            title={
              <div className="tab-header">
                {/* <i className="fa fa-check-square-o"></i> */}
                <span className="m-l-5">Seniors</span>
              </div>
            }
          >
            {activeTab === "SE" && <SeniorDashboard activeTab={activeTab} />}
          </Tab>

          <Tab
            eventKey="MC"
            title={
              <div className="tab-header">
                {/* <i className="fa fa-ban"></i> */}
                <span className="m-l-5">Markets</span>
              </div>
            }
          >
            {activeTab === "MC" && <MarketDashboard activeTab={activeTab} />}
          </Tab>
        </Tabs>
      </Stack>
    </Box>
  );
};

AdminComponent.propTypes = {
  getVendorStats: PropTypes.func.isRequired,
  adminDashboard: PropTypes.object,
  getStoreStats: PropTypes.func.isRequired,
  getCityList: PropTypes.func.isRequired,
  mapdata: PropTypes.object,
  allowedRoles: PropTypes.object,
  userAuth: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    getVendorStats: (payload) => dispatch(getVendorStats(payload)),
    getStoreStats: (payload) => dispatch(getStoreStats(payload)),
    getCityList: (city_county) =>
      dispatch({ type: GET_COUNTYCITY, city: city_county }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminComponent);
