import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Box, Fade, Button, Paper, Popper, Typography } from "@mui/material";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import dayjs from "dayjs";

import { formatDTADateForPicker } from "../../../utils/globalMethods";
import "../../../styles/home.scss";

const DatePickerComponent = ({
  selectedDateObj,
  label = "Select Date",
  resetDate,
}) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: dayjs().endOf("day").toDate(),
    },
  ]);

  const [selectedDateLabel, setSelectedDateLabel] = useState(label);
  const [displayLable, setDisplayLabel] = useState("-");
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateOpen, setDateOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const [storeLabel] = useState(label);

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setDateOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  useEffect(() => {
    const Today = dayjs(new Date()).format("MM-DD-YYYY");
    const Yesterday = dayjs().add(-1, "day").format("MM-DD-YYYY");
    const selectedDates = selectedDateLabel.split(" - ");
    if (selectedDates[0] && selectedDates[1]) {
      if (selectedDates[0] === selectedDates[1] && selectedDates[1] === Today) {
        setDisplayLabel("Today: " + formatDTADateForPicker(selectedDates[0]));
      } else if (
        selectedDates[0] === selectedDates[1] &&
        selectedDates[1] === Yesterday
      ) {
        setDisplayLabel(
          "Yesterday: " + formatDTADateForPicker(selectedDates[0])
        );
      } else {
        setDisplayLabel(
          formatDTADateForPicker(selectedDates[0]) +
            " - " +
            formatDTADateForPicker(selectedDates[1])
        );
      }
    } else {
      setDisplayLabel("Select Date");
    }
  }, [selectedDateLabel]);

  useEffect(() => {
    setSelectedDateLabel(storeLabel);
  }, [resetDate]);

  return (
    <>
      <Button
        sx={{
          textTransform: "capitalize",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          color: "#000",
          padding: "5px 8px",
          display: "inline-block",
          textAlign: "left",
          background: "#FFFFFF",
        }}
        fullWidth={true}
        color="primary"
        className={"dateRangePicker"}
        onClick={handleClick("bottom-start")}
        startIcon={<CalendarMonthIcon />}
        endIcon={<ArrowDropDownIcon />}
      >
        {displayLable}
      </Button>
      <Popper
        style={{ zIndex: "9999" }}
        open={dateOpen}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography component="span" sx={{ border: "1px solid #ccc" }}>
                <div>
                  <DateRangePicker
                    className="datepicker"
                    onChange={(item) => {
                      setState([item.range1]);
                    }}
                    rangeColors={["#002a57"]}
                    showSelectionPreview
                    moveRangeOnFirstSelection={false}
                    showDateDisplay={false}
                    months={2}
                    color="f6be00"
                    retainEndDateOnFirstSelection
                    ranges={state}
                    direction={
                      window.innerWidth > 796 ? "horizontal" : "vertical"
                    }
                    staticRanges={createStaticRanges([
                      {
                        label: "Today",
                        range: () => ({
                          startDate: dayjs().startOf("day").toDate(),
                          endDate: dayjs().endOf("day").toDate(),
                        }),
                      },
                      {
                        label: "Yesterday",
                        range: () => ({
                          startDate: dayjs()
                            .add(-1, "day")
                            .startOf("day")
                            .toDate(),
                          endDate: dayjs().add(-1, "day").endOf("day").toDate(),
                        }),
                      },
                      {
                        label: "Last 7 Days",
                        range: () => ({
                          startDate: dayjs()
                            .add(-7, "day")
                            .startOf("day")
                            .toDate(),
                          endDate: dayjs().endOf("day").toDate(),
                        }),
                      },
                      {
                        label: "Last 30 Days",
                        range: () => ({
                          startDate: dayjs()
                            .add(-30, "day")
                            .startOf("day")
                            .toDate(),
                          endDate: dayjs().endOf("day").toDate(),
                        }),
                      },
                      {
                        label: "Custom Ranges",
                        range: () => ({
                          startDate: dayjs()
                            .add(-30, "day")
                            .startOf("day")
                            .toDate(),
                          endDate: dayjs().endOf("day").toDate(),
                        }),
                      },
                      // {
                      //   label: 'Reset',
                      //   range: () => ({
                      //     // startDate: null,
                      //     // endDate: null,
                      //     startDate: new Date(),
                      //     endDate:  dayjs().endOf('day').toDate()
                      //   })
                      // },
                    ])}
                    inputRanges={[]}
                  />
                  <br />
                  <Box
                    sx={{
                      display: "flex",
                      width: "98%",
                      flexDirection: "row",
                      alignItems: "right",
                      justifyContent: "right",
                      m: 1,
                      "& .css-iajp3t-MuiButtonGroup-root .MuiButtonGroup-grouped":
                        {
                          minWidth: "80px",
                        },
                    }}
                  >
                    <Button
                      onClick={() => {
                        setDateOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      variant="contained"
                      onClick={() => {
                        console.log(state);
                        setDateOpen(false);
                        selectedDateObj(state);
                        if (state && state[0]?.startDate && state[0]?.endDate) {
                          const d1 = dayjs(state[0].startDate).format(
                            "MM-DD-YYYY"
                          );
                          const d2 = dayjs(state[0].endDate).format(
                            "MM-DD-YYYY"
                          );
                          setSelectedDateLabel(`${d1} - ${d2}`);
                        } else {
                          setSelectedDateLabel("Select Date");
                        }
                      }}
                    >
                      OK
                    </Button>
                  </Box>
                </div>
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

DatePickerComponent.propTypes = {
  selectedDateObj: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  resetDate: PropTypes.bool.isRequired,
};

export default DatePickerComponent;
