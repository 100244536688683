import React from "react";
import Header from "./TopNav";
import SearchMarkets from "./SearchMarkets";
import SpecialFeatures from "./SpecialFeatures";
import FarmProducts from "./FarmProducts";
import Participant from "./Participant";
import Help from "./Help";
import Footer from "./Footer";
import PropTypes from "prop-types";

const HomePage = ({ handleLoginDialog }) => {
  return (
    <div className="w-100 h-100">
      <Header handleLoginDialog={handleLoginDialog} />
      <SearchMarkets />
      <SpecialFeatures />
      <FarmProducts />
      <Participant />
      <Help />
      <Footer />
    </div>
  );
};

HomePage.propTypes = {
  handleLoginDialog: PropTypes.func,
};

export default HomePage;
