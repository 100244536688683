import {
  Badge,
  Box,
  Checkbox,
  Grid,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../styles/eligibility.scss";
import { ReactComponent as FeatherIcon } from "../../../src/assets/images/feather.svg";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import EventRepeatOutlinedIcon from "@mui/icons-material/EventRepeatOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
// import { ReactComponent as DownloadIcon } from "../../../src/assets/images/download.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useTranslator from "../../hooks/useTranslator";
import { AUTH_API, headers } from "../../config";
import axios from "axios";
import dayjs from "dayjs";
import useLoader from "../../hooks/useLoader";
import DownloadIcon from "@mui/icons-material/Download";
import toast from "react-hot-toast";

let userDetails = sessionStorage.getItem("userLogin")
  ? JSON.parse(sessionStorage.getItem("userLogin"))
  : {};

function Eligibility() {
  const transProps = useTranslator(
    [
      "pageTitle",
      "applicationDate",
      "downloadNotice",
      "downloadForm",
      "renewalDate",
      "applicationId",
      "eligiblityStat",
      "pass",
      "searchMarket",
      "approved",
      "denied",
      "goBack",
      "finish",
    ],
    "eligiblity"
  );
  const navigate = useNavigate();
  const [response, setResponse] = useState("");
  const { openLoader, closeLoader } = useLoader();
  const params = useParams();
  const _appNumber = params?.appNumber ?? "";
  const _clientId = params?.clientId ?? "";

  const location = useLocation();
  const disabled = location?.state?.disabled ?? false;

  const searchFarmers = () => {
    navigate(`/kfreshfep?appId=${_appNumber}`);
  };

  const handleGoBack = () => {
    if (_appNumber || _clientId) {
      navigate(`/certification/${_appNumber}/${_clientId}`, {
        state: { disabled: disabled },
      });
    } else {
      navigate(`/certification`, {
        state: { disabled: disabled },
      });
    }
  };

  const goToDashboard = () => {
    if (userDetails?.email) {
      navigate("/seniorDashboard");
    } else {
      searchFarmers();
    }
  };

  const getEligibilityDetails = async () => {
    try {
      const res = await axios.post(
        AUTH_API + `/seniors/eligibilityDetails/getEligibility/${_appNumber}`,
        {},
        { headers: headers }
      );
      setResponse(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEligibilityDetails();
  }, []);

  const getForm = async () => {
    try {
      openLoader("download-notice-pfd");
      const res = await axios.post(
        AUTH_API + `/seniors/downloadNoticePdf/${response?.formPdf}`,
        {},

        {
          responseType: "blob",
        }
      );
      if (res && res.data) {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(blob);
        window.open(pdfUrl, "_blank");
      }
    } catch (error) {
      console.log(error);
      toast.error("Document downloading Failed");
    } finally {
      closeLoader("download-notice-pfd");
    }
  };

  const getNotice = async () => {
    try {
      openLoader("get-notice-pdf");
      const res = await axios.post(
        AUTH_API + `/seniors/downloadNoticePdf/${response?.noticePdf}`,
        {},

        {
          responseType: "blob",
        }
      );
      if (res && res.data) {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(blob);
        window.open(pdfUrl, "_blank");
      }
    } catch (error) {
      console.log(error);
      toast.error("Document downloading Failed");
    } finally {
      closeLoader("get-notice-pdf");
    }
  };

  const renderButtonStatus = (status) => {
    switch (status) {
      case "AP":
        return (
          <Button
            variant="contained"
            color="success"
            className="passButton"
            startIcon={<TaskAltOutlinedIcon />}
            sx={{ borderRadius: "2rem", cursor: "default !important" }}
          >
            {transProps.approved}
          </Button>
        );
      case "WL":
        return (
          <Button
            variant="contained"
            color="warning"
            className="passButton"
            sx={{ borderRadius: "2rem", cursor: "default !important" }}
          >
            {"Waitlisted"}
          </Button>
        );
      case "PE":
        return (
          <Button
            variant="contained"
            color="warning"
            className="passButton"
            sx={{ borderRadius: "2rem", cursor: "default !important" }}
          >
            {"Pending"}
          </Button>
        );

      case "DE":
        return (
          <Button
            variant="contained"
            color="error"
            className="passButton"
            startIcon={<DangerousOutlinedIcon />}
            sx={{
              borderRadius: "2rem",
              cursor: "default !important",
            }}
          >
            {transProps.denied}
          </Button>
        );
    }
  };

  const renderMsgStatus = (status) => {
    switch (status) {
      case "ANV":
        return "Age not Valid";
      case "SNV":
        return "State not Valid";
      case "INV":
        return "Income Not Valid";
    }
  };
  return (
    <>
      <div>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 700,
            color: "#6c6c6c",
          }}
          mb={2}
        >
          {transProps.pageTitle}
        </Typography>
      </div>
      <div
        style={{
          border: ".1rem solid  #c2c2b7",
          borderRadius: "1rem",
        }}
      >
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <p className="h1 font-bold" style={{ marginLeft: "1rem" }}>
            {response?.firstName} {response?.lastName}
          </p>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Button
              startIcon={<DownloadIcon />}
              variant="outlined"
              style={{ border: "none", padding: "0.5rem" }}
              onClick={getForm}
            >
              {transProps.downloadForm}
            </Button>
            <Button
              startIcon={<DownloadIcon />}
              variant="outlined"
              style={{ border: "none", padding: "0.5rem" }}
              onClick={getNotice}
              disabled={response?.EligibilityStatusCd === "WL"}
            >
              {transProps.downloadNotice}
            </Button>
          </Stack>
        </Stack>
        <hr></hr>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box className="item">
              <Stack direction="row" spacing={2} alignItems="center">
                <div className="icon">
                  <EventAvailableOutlinedIcon sx={{ fontSize: 50 }} />
                </div>
                <div className="category">
                  {transProps.applicationDate}
                  <p>
                    {response?.eligiblityStartDt
                      ? dayjs(response?.eligiblityStartDt).format("MM-DD-YYYY")
                      : "N/A"}
                  </p>
                </div>
              </Stack>
            </Box>
            <Box className="item">
              <Stack direction="row" spacing={2} alignItems="center">
                <div className="icon">
                  <EventRepeatOutlinedIcon sx={{ fontSize: 50 }} />
                </div>
                <div className="category">
                  {transProps.renewalDate}{" "}
                  <p>
                    {response?.eligiblityEndDt
                      ? dayjs(response?.eligiblityEndDt).format("MM-DD-YYYY")
                      : "N/A"}
                  </p>
                </div>
              </Stack>
            </Box>

            <Box className="item">
              <Stack direction="row" spacing={2} alignItems="center">
                <div className="icon">
                  <ArticleOutlinedIcon sx={{ fontSize: 50 }} />
                </div>
                <div className="category">
                  {transProps.applicationId}{" "}
                  <p>{response?.applicationNumber}</p>
                </div>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ marginTop: "3rem" }}
              justifyContent={{ xs: "center", md: "flex-start" }}
            >
              {renderButtonStatus(response?.eligibilityStatusCd)}

              <p className="msgStatus">
                {renderMsgStatus(response?.eligibilityRsnCd)}
              </p>
            </Stack>
            <Stack
              direction="row"
              style={{ marginTop: "1rem" }}
              alignItems="center"
              justifyContent={{ xs: "center", md: "flex-start" }}
            >
              {response?.eligibilityStatusCd == "AP" ? (
                <Button
                  variant="contained"
                  color="success"
                  className="passButton"
                  sx={{ borderRadius: "2rem", marginTop: "2rem" }}
                  onClick={searchFarmers}
                >
                  {transProps.searchMarket}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  className="passButton"
                  sx={{ borderRadius: "2rem", marginTop: "2rem" }}
                  disabled
                >
                  {transProps.searchMarket}
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Stack
          direction={"row"}
          spacing={2}
          mx={3}
          my={3}
          justifyContent={"flex-end"}
        >
          <Button variant="outlined" color="inherit" onClick={handleGoBack}>
            {transProps.goBack}
          </Button>
          <Button
            size="small"
            variant="contained"
            color="success"
            onClick={goToDashboard}
          >
            {transProps.finish}
          </Button>
        </Stack>
      </div>
    </>
  );
}

export default Eligibility;
