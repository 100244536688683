import React from 'react'
import Kfresh from "../../assets/images/kfreshlogo.png";

const Footer = () => {
  return (
    <footer className="footer_container">
      <div className="footer_content">
        <div className="f_content">
          <h5>Sitemap</h5>
          <p>
            <a>About us</a>
          </p>
          <p>
            <a>Services</a>
          </p>
          <p>
            <a>Blog</a>
          </p>
        </div>
        <div className="f_content">
          <h5>Resources</h5>
          <p>
            <a>KConnect</a>
          </p>
          <p>
            <a>KNotify</a>
          </p>
          <p>
            <a>KQuality Control</a>
          </p>
        </div>
        <div className="f_content">
          <h5>Help</h5>
          <p>
            <a>FAQ</a>
          </p>
          <p>
            <a>Customer Support</a>
          </p>
          <p>
            <a>AI Chat</a>
          </p>
        </div>
        <div className="f_content">
          <h5>Get In Touch</h5>
          <p>
            <i className="fa fa-phone" aria-hidden="true"></i>{" "}(000)000-0000
          </p>
          <p>
            <i className="fa fa-map-marker" aria-hidden="true"></i> Farmington
            Hills, Michigan
          </p>
          <p>
            <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
            example@kyyba.com
          </p>
        </div>
      </div>
      <div className="our_info">
        <h1>
          © 2024 All Rights Reserved <span>Kyyba Tech Pvt Ltd</span>
        </h1>
        <img src={Kfresh} />
      </div>
    </footer>
  );
}

export default Footer