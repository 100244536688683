import React from 'react'
import store from '../../assets/images/store.png'
import online from "../../assets/images/online_reg.png";
import cash from "../../assets/images/cash.png";
import card from "../../assets/images/spf_card.png";

const SpecialFeatures = () => {
  return (
    <div className="special_container">
      <h1>Our Special Features</h1>
      <div className="special_card_container">
        <div className="special_card">
          <img src={online} />
          <h6>Online Registration</h6>
          <p>
            Online registration streamlines SPF applications, boosting
            accessibility for eligible seniors.
          </p>
        </div>
        <div className="special_card">
          <img src={card} />
          <h6>Apply SPF Card</h6>
          <p>
            The SPF Card offers coupons for fresh produce from local vendors,
            enabling cashless transactions at markets
          </p>
        </div>
        <div className="special_card">
          <img src={cash} />
          <h6>Cashless Transaction</h6>
          <p>
            Cashless transactions simplify commerce by using electronic mediums,
            replacing physical currency
          </p>
        </div>
        <div className="special_card">
          <img src={store} />
          <h6>Discover Market</h6>
          <p>
            Find local treasures, support small businesses, and connect with
            your community.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SpecialFeatures