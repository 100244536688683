import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { AUTH_API, headers } from "../../config";
import useLoader from "../../hooks/useLoader";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
import axiosInstance from "../../redux/interceptor";
import { Button } from 'react-bootstrap';

const SelectedFarmersList = ({ userData, trigger, setTrigger }) => {
  const { openLoader, closeLoader } = useLoader();
  const [selectedFarmers, setSelectedFarmers] = useState([])
  const isVerified = "Y";

  useEffect(() => {
    const handleFetchFarmer = async () => {
      const url = `${AUTH_API}/marketcrd/vendorslist/${userData.id}`;
      openLoader("fetch_farmer");
      try {
        const res = await axiosInstance.post(url, {});
        if (res.status === 200) {
          setSelectedFarmers(res.data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        closeLoader("fetch_farmer");
      }
    };
    handleFetchFarmer();
  }, [trigger]);

  const handleRemoveFarmer = async (data) => {
    const url = `${AUTH_API}/marketcrd/vendor/select`;
    try {
      openLoader("delete_farmer");
      const payload = {
        dirtyStatus: "delete",
        hipVenderId: data.id,
        id: userData.id,
        createUidNam: "ADMN",
        lastUpdtUidNam: "ADMN",
        hipMrkcrdVendorDetailsId: data.hipMrkcrdVendorDetailsId,
      };
      const res = await axiosInstance.post(url, payload);
      if (res.status === 200) {
        toast.success("Farmer Deleted Successfully..");
        setTrigger(!trigger);
      }
    } catch (error) {
      toast.error("Failed..!");
      console.log(error);
    } finally {
      closeLoader("delete_farmer");
    }
  };


  return (
    <Stack gap={2}>
      {selectedFarmers.map((data, index) => (
        <Stack direction="row" className="vendorContainer" key={index}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container>
              <Grid xs={12}>
                <Box>
                  <Grid container>
                    <Grid item xs={6}>
                      <Stack className="textContent">
                        <p className="contentTitle">Name</p>
                        <p>{data.firstName + " " + data.lastName}</p>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack className="textContent">
                        <p className="contentTitle">Merchant Name</p>
                        <p>{data.vendorName}</p>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack className="textContent">
                        <p className="contentTitle">Email</p>
                        <Typography variant="body1">
                          {data.primaryEmail}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack className="textContent">
                        <p className="contentTitle">Mobile Number</p>
                        <Typography variant="body1">
                          {data.primaryMobile}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack className="textContent">
                        <p className="contentTitle">Approved</p>
                        {data.isVerified === "Y" ? (
                          <Typography variant="body1">Yes</Typography>
                        ) : (
                          <p>Pending</p>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack className="textContent mt-2" alignItems="start">
                        <Button onClick={()=>handleRemoveFarmer(data)}>Remove</Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      ))}
    </Stack>
  );
};

SelectedFarmersList.propTypes = {
  userData: PropTypes.object,
  trigger: PropTypes.bool,
  setTrigger: PropTypes.func,
};

export default SelectedFarmersList