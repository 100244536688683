import { Dialog, DialogContent } from "@mui/material";
import {
  Box,
  Container,
  TextField,
  Typography,
  Grid,
  Stack,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormLabel } from "@mui/material";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import "../../styles/login.scss";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AUTH_API } from "../../config";
import toast from "react-hot-toast";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function MarketCoordinator({ open, handleClose }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const onClose = () => {
    handleClose();
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = Yup.object({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSignIn = async (values) => {
    sessionStorage.setItem("ext_url", "csa");
    try {
      const payLoad = {
        email: values.email,
        password: values.password,
      };
      const res = await axios.post(
        AUTH_API + "/marketcoordinator/login",
        payLoad,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const userData = {
        Name:
          res &&
          res?.data?.userData?.firstName + " " + res?.data?.userData?.lastName,
        Role: res && res?.data?.userData?.role,
        id: res && res?.data?.userData?.id,
        vendorId: res && res?.data?.userData?.vendorId,
        lastUpdtUidNam: res && res?.data?.userData?.lastUpdtUidNam,
      };

      if (res.data.code == "200") {
        sessionStorage.setItem("userLogin", JSON.stringify(res.data.userData));
        sessionStorage.setItem("userData", JSON.stringify(userData));
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate("/marketDashboard");
      }
    } catch (error) {
      console.log(error);
      toast.error("Login Failed!");
    } finally {
      console.log("finally");
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleSignIn(values);
    },
  });

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        keepMounted
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogContent>
          <Grid container component="main">
            <Grid item xs={12} sm={12} md={12}>
              <Container maxWidth="lg">
                {/* <div className="login-paper"> */}
                <h2 className="font-bold boldFont pt-3">Sign In</h2>
                <Stack
                  component="form"
                  className="login-form"
                  onSubmit={formik.handleSubmit}
                >
                  <Grid item xs={12} md={12} lg={12} sx={{ marginTop: "1rem" }}>
                    <FormLabel className="labelName">Email </FormLabel>
                    <TextField
                      fullWidth
                      id="email"
                      placeholder="Enter Email Id"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.email && formik.errors.email}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                    {/* </Box> */}
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormLabel className="labelName">Password</FormLabel>
                      <TextField
                        fullWidth
                        name="password"
                        placeholder="Enter your password"
                        type={showPassword ? "text" : "password"}
                        id="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.password && formik.errors.password
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={6} md={6} lg={6}>
                      <FormLabel className="labelName">
                        Confirm Password
                      </FormLabel>
                      <TextField
                        fullWidth
                        name="confirm_password"
                        placeholder="Enter your confirm password"
                        type="password"
                        id="cf_password"
                        value={formik.values.confirm_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.confirm_password &&
                          formik.errors.confirm_password
                        }
                        helperText={
                          formik.touched.confirm_password &&
                          formik.errors.confirm_password
                        }
                      />
                    </Grid> */}
                  </Grid>

                  {/* <Grid container spacing={2}> */}
                  {/* <Grid item xs={6} md={6} lg={6} sx={{ marginTop: "1rem" }}>
                      <FormLabel className="labelName">
                        Primary Phone Number
                      </FormLabel>
                      <TextField
                        fullWidth
                        name="confirm_password"
                        placeholder="Enter your phone number"
                        type="password"
                        id="cf_password"
                        value={formik.values.confirm_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.confirm_password &&
                          formik.errors.confirm_password
                        }
                        helperText={
                          formik.touched.confirm_password &&
                          formik.errors.confirm_password
                        }
                      />
                    </Grid> */}
                  {/* <Grid item xs={6} md={6} lg={6} sx={{ marginTop: "1rem" }}>
                      <FormLabel className="labelName">
                        Routing Number
                      </FormLabel>
                      <TextField
                        fullWidth
                        name="confirm_password"
                        placeholder="Enter your routing number"
                        type="password"
                        id="cf_password"
                        value={formik.values.confirm_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.confirm_password &&
                          formik.errors.confirm_password
                        }
                        helperText={
                          formik.touched.confirm_password &&
                          formik.errors.confirm_password
                        }
                      />
                    </Grid> */}
                  {/* </Grid> */}
                  <Button
                    className="login-button"
                    type="submit"
                    onClick={formik.handleSubmit}
                    sx={{ marginTop: "1rem !important" }}
                  >
                    Signup
                  </Button>
                </Stack>

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="body1" mt={1}>
                    Dont have an account?
                    <span
                      style={{ color: "#44A0E3", cursor: "pointer" }}
                      onClick={() => navigate("/marketRegistration")}
                    >
                      Register here
                    </span>
                  </Typography>
                </Box>
                {/* </div> */}
              </Container>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

MarketCoordinator.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default MarketCoordinator;
