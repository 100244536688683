import React, { useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { AUTH_API, headers } from "../../config";
import { DatePicker } from "@mui/x-date-pickers";
import { Ethnicity, Race, State, Relation } from "./constant";
import { useFormik } from "formik";
import * as yup from "yup";
import { map } from "lodash";
import useLkpTranslator from "../../hooks/useLkpTranslator";
import MaskedInput from "react-text-mask";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import useLoader from "../../hooks/useLoader";

function TextMaskCustom(props) {
  const { ...other } = props;

  return (
    <MaskedInput
      {...other}
      mask={[
        "(",
        /\d/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

const calculateAge = (dob) => {
  return dayjs().diff(dayjs(dob), "year");
};

const AddMemberDialog = ({
  open,
  handleClose,
  editData,
  selectedMember,
  appNumber,
  getMembers,
  relationOpts,
}) => {
  const { getLkpTranslation } = useLkpTranslator();
  const races = getLkpTranslation("lookUp.Race", Race);
  const ethnicity = getLkpTranslation("lookUp.ethnicity", Ethnicity);
  const state = getLkpTranslation("lookUp.state", State);
  let userLogin = JSON.parse(sessionStorage.getItem("userLogin"));
  const { openLoader, closeLoader } = useLoader();

  const memberElementRef = useRef("");

  const validationSchema = yup.object({
    firstName: yup.string().required("Please enter First Name"),
    lastName: yup.string().required("Please enter Last Name"),
    dob: yup.string().required("Please enter DOB"),
    gender: yup.string().required("Please choose gender"),
    race: yup.string().required("Please choose race"),
    relationShip: yup.string().required("Please choose relationship"),
    hohAddress: yup.string().required("Please choose address"),
    spouse: yup.string().when(["relationShip", "hohAddress"], {
      is: (relationShip, hohAddress) =>
        relationShip === "SP" && hohAddress === "Y",
      then: () => yup.string().required("Please choose spouse benefits"),
      otherwise: () => yup.string().notRequired(),
    }),
    tribalTypeInd: yup.string().when("dob", {
      is: (dob) => calculateAge(dob) < 60,
      then: () => yup.string().required("This field is required"),
      otherwise: () => yup.string().notRequired(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      firstName: selectedMember?.firstName ?? "",
      lastName: selectedMember?.lastName ?? "",
      dob: selectedMember?.dob ?? null,
      age: selectedMember?.dob ? calculateAge(selectedMember?.dob) : "",
      gender: selectedMember?.gender ?? "",
      race: selectedMember?.race ?? "",
      ethinicity: selectedMember?.ethinicity ?? "",
      dirtyStatus: editData ? "UPDATE" : "INSERT",
      appNumber: appNumber,
      createUserId: userLogin.email,
      tribalTypeInd: selectedMember?.tribalTypeInd ?? "",
      hohAddress: editData
        ? selectedMember?.hohAddress === true
          ? "Y"
          : "N"
        : "",
      relationShip: selectedMember?.relationShip ?? "",
      spouse: editData ? (selectedMember?.spouse === true ? "Y" : "N") : "",
      clDemographicId: selectedMember?.clDemographicId ?? null,
      clientId: selectedMember?.clientId ?? null,
    },
    validationSchema,
    onSubmit: (values) => {
      postAddMemberInfo(values);
    },
    enableReinitialize: true,
  });

  const handleDateChange = (date) => {
    const formattedDate = date ? dayjs(date).format("MM/DD/YYYY") : "";
    formik.setFieldValue("dob", formattedDate, true);
    const age = calculateAge(formattedDate);
    formik.setFieldValue("age", age);
  };

  const postAddMemberInfo = async (values) => {
    let payload = {
      ...values,
      hohAddress: values.hohAddress === "Y" ? true : false,
      spouse: values.spouse === "Y" ? true : false,
    };
    try {
      openLoader("add-member-info");
      const res = await axios.post(
        AUTH_API + `/senior/subMember/demographicInfo`,
        payload,
        {
          headers: headers,
        }
      );
      if (res.status === 200) {
        toast.success(editData ? "Successfully updated" : "Successfully added");
        getMembers();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("add-member-info");
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      onClose={handleClose}
      aria-labelledby="addMember"
      aria-describedby="addMemberContent"
    >
      <DialogTitle id="addMember">
        <h4>Add Member</h4>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText id="addMemberContent" ref={memberElementRef}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6">
                  First Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="firstName"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Last Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="lastName"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5}>
              <Grid container item xs={6} spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h6">
                    DOB <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <DatePicker
                    sx={{ width: "100%" }}
                    format="YYYY-MM-DD"
                    disableFuture
                    slotProps={{ textField: { size: "small" } }}
                    value={formik.values.dob ? dayjs(formik.values.dob) : null}
                    onChange={handleDateChange}
                    // onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">Age</Typography>
                  <TextField
                    fullWidth
                    size="small"
                    name="age"
                    value={formik.values.age}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" my={0.5}>
                  Gender
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  row
                  {...formik.getFieldProps("gender")}
                  name="gender"
                >
                  <FormControlLabel
                    value="Male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="Female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="Decline to answer"
                    control={<Radio />}
                    label="Decline to answer"
                  />
                </RadioGroup>
                <FormHelperText error>{formik.errors.gender}</FormHelperText>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5}>
              <Grid item xs={6}>
                {formik.values.age < 60 && formik.values.age !== "" && (
                  <>
                    <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
                      55 and older and member of a Federally recognized Indian
                      Tribe or Urban Tribal Group in Michigan
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <RadioGroup
                      row
                      {...formik.getFieldProps("tribalTypeInd")}
                      name="tribalTypeInd"
                    >
                      <FormControlLabel
                        value="Y"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="N"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    <FormHelperText error>
                      {formik.errors.tribalTypeInd}
                    </FormHelperText>
                  </>
                )}
              </Grid>
              <Grid xs={6}></Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Relationship of applicant
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                  fullWidth
                  size="small"
                  name="relationShip"
                  value={formik.values.relationShip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  //   disabled={disabled}
                  helperText={
                    formik.touched.relationShip && formik.errors.relationShip
                  }
                >
                  {map(relationOpts, (item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {formik.errors.relationShip}
                </FormHelperText>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Do you stay with applicant address ?
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  row
                  {...formik.getFieldProps("hohAddress")}
                  name="hohAddress"
                >
                  <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                  <FormControlLabel value="N" control={<Radio />} label="No" />
                </RadioGroup>
                <FormHelperText error>
                  {formik.errors.hohAddress}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5}>
              <Grid item xs={6}>
                {formik.values.relationShip === "SP" &&
                  formik.values.hohAddress === "Y" && (
                    <>
                      <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
                        Do you want apply the benefit
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <RadioGroup
                        row
                        {...formik.getFieldProps("spouse")}
                        name="spouse"
                      >
                        <FormControlLabel
                          value="Y"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="N"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                      <FormHelperText error>
                        {formik.errors.spouse}
                      </FormHelperText>
                    </>
                  )}
              </Grid>
              <Grid xs={6}></Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5}>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Race <span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                  fullWidth
                  size="small"
                  name="race"
                  value={formik.values.race}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  //   disabled={disabled}
                  helperText={formik.touched.race && formik.errors.race}
                >
                  {map(races, (item) => (
                    <MenuItem value={item.lkpDesc}>
                      {item.lookupLongVal}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>{formik.errors.race}</FormHelperText>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Ethnicity</Typography>
                <Select
                  fullWidth
                  size="small"
                  name="ethinicity"
                  value={formik.values.ethinicity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // disabled={disabled}
                >
                  {map(ethnicity, (item) => (
                    <MenuItem value={item.lkpDesc}>
                      {item.lookupLongVal}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} sx={{ display: "none" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Address Line 1 <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="address1"
                  //   onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Address Line 2 <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="address2"
                  //   onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5}>
              <Grid item xs={6}>
                <Typography variant="h6">
                  City <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="city"
                  //   onChange={formik.handleChange}
                />
              </Grid>
              <Grid container item xs={6} spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h6">
                    State <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Select
                    fullWidth
                    name="stateCd"
                    size="small"
                    // value={formik.values.stateCd}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // error={
                    //   formik.touched.stateCd && Boolean(formik.errors.stateCd)
                    // }
                    // helperText={formik.touched.stateCd && formik.errors.stateCd}
                    // FormHelperTextProps={{
                    //   className: classes.formHelperText,
                    // }}
                    // disabled={disabled}
                  >
                    {map(state, (item) => (
                      <MenuItem value={item.lkpDesc}>
                        {item.lookupLongVal}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* {formik.touched.stateCd && formik.errors.stateCd && (
                    <FormHelperText className={classes.formHelperText} error>
                      {formik.errors.stateCd}
                    </FormHelperText>
                  )} */}
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">Zip Code</Typography>
                  <TextField
                    fullWidth
                    size="small"
                    name="zipCode"
                    //   onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5}>
              <Grid item xs={6}>
                <Typography variant="h6">Phone No</Typography>
                <TextField
                  fullWidth
                  name="contactNumber"
                  size="small"
                  placeholder="Enter Phone No"
                  //   value={formik.values.contactNumber}
                  //   onChange={formik.handleChange}
                  //   onBlur={formik.handleBlur}
                  //   disabled={disabled}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Email ID</Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="email"
                  disabled
                  //   onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Close
        </Button>
        <Button onClick={formik.handleSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddMemberDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  appNumber: PropTypes.string,
  editData: PropTypes.bool,
  selectedMember: PropTypes.object,
  getMembers: PropTypes.func,
  relationOpts: PropTypes.array,
};

export default AddMemberDialog;
