import React from "react";
import DataGridTable from "../../../common/DataGrid";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Button, Stack } from "@mui/material";
import useTranslator from "../../../hooks/useTranslator";

const SeniorDocuments = () => {
  const transProps = useTranslator(
    ["documentType", "fileName", "createdBy", "createdDate", "action"],
    "seniorDocuments"
  );
  const COLUMN = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "docType",
      headerName: transProps.documentType,
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "userFileName",
      headerName: transProps.fileName,
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "createUserId",
      headerName: transProps.createdBy,
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "createDt",
      headerName: transProps.createdDate,
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "action",
      headerName: transProps.action,
      headerClassName: "grid_header",
      flex: 1,
      renderCell: ({
        row: { documentId = null, sourceCaseId = null, userFileName = "" },
      }) => {
        return (
          <Stack direction="row" alignItems={"center"}>
            <Button
              sx={{
                color: "#44A0E3",
                cursor: "pointer",
                padding: "0px !important",
                "& .css-1d6wzja-MuiButton-startIcon": {
                  marginRight: "3px !important",
                },
              }}
              startIcon={<FileDownloadOutlinedIcon />}
              // onClick={(e) =>
              //   handlePDFDownload(e, documentId, sourceCaseId, userFileName)
              // }
            >
              <span style={{ fontSize: "14px" }}>Download</span>
            </Button>
          </Stack>
        );
      },
    },
  ];
  const ROW = [
    {
      id: 1,
      docType: "Income",
      userFileName: "Incomefile.png",
      createUserId: "John Doe",
      createDt: "03/24/2024",
    },
    {
      id: 2,
      docType: "Identity",
      userFileName: "Identityfile.png",
      createUserId: "John Doe",
      createDt: "03/14/2024",
    },
    {
      id: 3,
      docType: "Income",
      userFileName: "Incomefile.png",
      createUserId: "John Doe",
      createDt: "03/15/2024",
    },
    {
      id: 4,
      docType: "Identity",
      userFileName: "File1.png",
      createUserId: "John Doe",
      createDt: "03/20/2024",
    },
    {
      id: 5,
      docType: "Income",
      userFileName: "Incomefile2.png",
      createUserId: "John Doe",
      createDt: "03/25/2024",
    },
  ];
  return (
    <div>
      <DataGridTable columns={COLUMN} data={ROW} />
    </div>
  );
};

export default SeniorDocuments;
