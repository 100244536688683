import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import { daysOfOperation } from "../../constants/constants";
import { formatPhoneNumber } from "../../utils/globalMethods";

const CsaSignupStatusDetails = ({ row }) => {
  const filterDay = (dayValue) => {
    let tempData = daysOfOperation.filter((data) => data.weekDay == dayValue);
    return tempData[0]?.values;
  };

  return (
    <Grid container className="collapsibleTable" spacing={2}>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Agreement ID :{" "}
          <span style={{ color: "#006E78" }}>
            {row.agrmntAppId ? row.agrmntAppId : "-"}
          </span>
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography
          variant="h5"
          display="inline"
          className="resText text-capitalize"
          gutterBottom
          component="div"
        >
          Client Information
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Client`s Full Name
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.clientNam ? row.clientNam : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Email
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.clientEmail ? row.clientEmail : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Phone
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.phoneNumber ? formatPhoneNumber(row.phoneNumber) : "-"}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" gutterBottom component="div">
          Approved/Rejected/Cancelled By
        </Typography>
        {row.rejectedDate !== undefined && row.rejectedDate !== null ? (
          <Typography variant="subtitle1" gutterBottom>
            {row.lastUpdtUidNam ? row.lastUpdtUidNam : "-"}
          </Typography>
        ) : (
          "-"
        )}
      </Grid>
      {row.apprvlStatus === "CANCLD" ? (
        <React.Fragment>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom component="div">
              Reason for cancel
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {row.cancelNotes ? row.cancelNotes : "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom component="div">
              Signature
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {row.cancelSign ? row.cancelSign : "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom component="div">
              Cancelled Date
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {row.cancelDate ? row.cancelDate : "-"}
            </Typography>
          </Grid>
        </React.Fragment>
      ) : row.apprvlStatus === "APPRVD" || row.apprvlStatus === "REJCTD" ? (
        <React.Fragment>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom component="div">
              Approved / Rejected Date
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {row.rejectedDate ? row.rejectedDate : "-"}
            </Typography>
          </Grid>
        </React.Fragment>
      ) : null}

      <Grid item xs={10}>
        <Typography
          variant="h5"
          display="inline"
          className="resText text-capitalize"
          gutterBottom
          component="div"
        >
          Share Information
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" gutterBottom component="div">
          Pickup/Delivery Location
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {[row.address1, row.address2, row.city, row.state, row.zipcode]
            .filter((value) => value !== undefined && value !== "")
            .join(", ") || "-"}
          <Typography variant="subtitle1" gutterBottom component="div">
            {row?.day && row?.openTime && row?.closeTime ? (
              <div>
                <AccessTimeRoundedIcon />
                {` ${filterDay(row?.day)}, ${row?.openTime}-${row?.closeTime}`}
              </div>
            ) : null}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" gutterBottom component="div">
          Description
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {row.dsc ? row.dsc : "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};

CsaSignupStatusDetails.propTypes = {
  row: PropTypes.object.isRequired,
};

export default CsaSignupStatusDetails;
