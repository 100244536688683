import { CHANGE_LOADING,SET_MAPDATA,SET_COUNTYCITY, SET_AREA, SET_TYPE, SET_ORDER_OPTION,
    SET_MONTH_OPTION, SET_STORE_LIST, SET_STORE_DETAILS, SET_STORE_REPORT, SET_DAY_OPTION, SET_VENDOR_LIST,SET_SPECIAL_HOUR, SET_MAP_SEARCH } from "../actionTypes";

import {area, vendorType, orderOptions, monthsOfOperation, daysOfOperation} from "../../constants/constants";

const initialState = {
    city: [],
    area: area,
    vendorType: vendorType,
    orderOptions: orderOptions,
    months: monthsOfOperation,
    days: daysOfOperation,
    vendor: [],
    storesList: [],
    loading: false,
    storeDetails: [],
    storesReport: [],
    mapSearchPayload: {},
};

const googlemapReducer = (state=initialState, action) => {
    switch (action.type) {
    case SET_SPECIAL_HOUR: {
        const newState = Object.assign({}, state);
        newState.specialHour = action.payload;
        return newState;
    }
    case SET_STORE_LIST: {
        const newState = Object.assign({}, state);
        newState.storesList = action.payload.data;
        newState.storesLocationCount = action.payload.totalLocation;
        newState.storesTotalCount = action.payload.total;
        newState.loading = false;
        return newState;
    }
    case SET_STORE_REPORT: {
        const newState = Object.assign({}, state);
        newState.storesReport = action.payload;
        return newState;
    }
    case SET_STORE_DETAILS:{
        const newState = Object.assign({}, state);
        newState.storeDetails = action.payload;
        return newState;
    }
    case CHANGE_LOADING: {
        const newState = Object.assign({}, state);
        newState.loading = action.payload;
        return newState;
    }
    case SET_MAPDATA: {
        return {...state,loaddata:action.payload};
    }
    case SET_COUNTYCITY: {
        return {...state,city:action.payload};
    }
    case SET_AREA: {
        // return [...action.value];
        const newState = Object.assign({}, state);
        newState.area = action.payload;
        return newState; 
    }
    case SET_TYPE: {
        // return [...action.value];
        const newState = Object.assign({}, state);
        newState.type = action.payload;
        return newState; 
    }
    case SET_ORDER_OPTION: {
        // return [...action.value];
        const newState = Object.assign({}, state);
        newState.order_options = action.payload;
        return newState; 
    }
    case SET_MONTH_OPTION: {
        // return [...action.value];
        const newState = Object.assign({}, state);
        newState.months = action.payload;
        return newState; 
    }
    case SET_DAY_OPTION: {
        // return [...action.value];
        const newState = Object.assign({}, state);
        newState.days = action.payload;
        return newState; 
    }
    case SET_VENDOR_LIST: {
        return {...state,vendor:action.payload};
    }
    case SET_MAP_SEARCH: {
        return {...state,mapSearchPayload:action.payload};   
    }
    default:
        return state;
    }
};

export default googlemapReducer;
