export const Race = [
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "Race",
    lkpDesc: "N",
    localeCd: "en_US",
    lookupLongVal: "American Indian/Alaskan Native",
    sortOrderNbr: 5,
    lookupId: 38,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "Race",
    lkpDesc: "A",
    localeCd: "en_US",
    lookupLongVal: "Asian",
    sortOrderNbr: 1,
    lookupId: 38,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "Race",
    lkpDesc: "B",
    localeCd: "en_US",
    lookupLongVal: "Black/African American",
    sortOrderNbr: 2,
    lookupId: 38,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "Race",
    lkpDesc: "P",
    localeCd: "en_US",
    lookupLongVal: "Native Hawaiian/Other Pacific Islander",
    sortOrderNbr: 4,
    lookupId: 38,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "Race",
    lkpDesc: "U",
    localeCd: "en_US",
    lookupLongVal: "Other",
    sortOrderNbr: 6,
    lookupId: 38,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "Race",
    lkpDesc: "C",
    localeCd: "en_US",
    lookupLongVal: "White",
    sortOrderNbr: 3,
    lookupId: 38,
    sysDesc: "EEAPI",
  },
];

export const Ethnicity = [
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "Ethnicity",
    lkpDesc: "H",
    localeCd: "en_US",
    lookupLongVal: "Hispanic",
    sortOrderNbr: 1,
    lookupId: 135,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "Ethnicity",
    lkpDesc: "N",
    localeCd: "en_US",
    lookupLongVal: "Not Hispanic",
    sortOrderNbr: 2,
    lookupId: 135,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "Ethnicity",
    lkpDesc: "Z",
    localeCd: "en_US",
    lookupLongVal: "Unknown",
    sortOrderNbr: 3,
    lookupId: 135,
    sysDesc: "EEAPI",
  },
];

export const Relation = [
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "Relation",
    lkpDesc: "FA",
    localeCd: "en_US",
    lookupLongVal: "Father",
    sortOrderNbr: 1,
    lookupId: 135,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "Relation",
    lkpDesc: "MO",
    localeCd: "en_US",
    lookupLongVal: "Mother",
    sortOrderNbr: 2,
    lookupId: 135,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "Relation",
    lkpDesc: "SO",
    localeCd: "en_US",
    lookupLongVal: "Son",
    sortOrderNbr: 3,
    lookupId: 135,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "Relation",
    lkpDesc: "DA",
    localeCd: "en_US",
    lookupLongVal: "Daughter",
    sortOrderNbr: 3,
    lookupId: 135,
    sysDesc: "EEAPI",
  },
];

export const State = [
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "AL",
    localeCd: "en_US",
    lookupLongVal: "Alabama",
    sortOrderNbr: 1,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "AK",
    localeCd: "en_US",
    lookupLongVal: "Alaska",
    sortOrderNbr: 2,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "AZ",
    localeCd: "en_US",
    lookupLongVal: "Arizona",
    sortOrderNbr: 3,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "AR",
    localeCd: "en_US",
    lookupLongVal: "Arkansas",
    sortOrderNbr: 4,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "CA",
    localeCd: "en_US",
    lookupLongVal: "California",
    sortOrderNbr: 5,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "CZ",
    localeCd: "en_US",
    lookupLongVal: "Canal Zone",
    sortOrderNbr: 6,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "CO",
    localeCd: "en_US",
    lookupLongVal: "Colorado",
    sortOrderNbr: 7,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "CT",
    localeCd: "en_US",
    lookupLongVal: "Connecticut",
    sortOrderNbr: 8,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "DE",
    localeCd: "en_US",
    lookupLongVal: "Delaware",
    sortOrderNbr: 10,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "DC",
    localeCd: "en_US",
    lookupLongVal: "Dist. Of Col.",
    sortOrderNbr: 9,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "FL",
    localeCd: "en_US",
    lookupLongVal: "Florida",
    sortOrderNbr: 11,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "GA",
    localeCd: "en_US",
    lookupLongVal: "Georgia",
    sortOrderNbr: 12,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "GU",
    localeCd: "en_US",
    lookupLongVal: "Guam",
    sortOrderNbr: 13,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "HI",
    localeCd: "en_US",
    lookupLongVal: "Hawaii",
    sortOrderNbr: 14,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "ID",
    localeCd: "en_US",
    lookupLongVal: "Idaho",
    sortOrderNbr: 15,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "IL",
    localeCd: "en_US",
    lookupLongVal: "Illinois",
    sortOrderNbr: 16,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "IN",
    localeCd: "en_US",
    lookupLongVal: "Indiana",
    sortOrderNbr: 17,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "IA",
    localeCd: "en_US",
    lookupLongVal: "Iowa",
    sortOrderNbr: 18,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "KS",
    localeCd: "en_US",
    lookupLongVal: "Kansas",
    sortOrderNbr: 19,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "KY",
    localeCd: "en_US",
    lookupLongVal: "Kentucky",
    sortOrderNbr: 20,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "LA",
    localeCd: "en_US",
    lookupLongVal: "Louisiana",
    sortOrderNbr: 21,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "ME",
    localeCd: "en_US",
    lookupLongVal: "Maine",
    sortOrderNbr: 22,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "MD",
    localeCd: "en_US",
    lookupLongVal: "Maryland",
    sortOrderNbr: 23,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "MA",
    localeCd: "en_US",
    lookupLongVal: "Massachusetts",
    sortOrderNbr: 24,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "MI",
    localeCd: "en_US",
    lookupLongVal: "Michigan",
    sortOrderNbr: 25,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "MN",
    localeCd: "en_US",
    lookupLongVal: "Minnesota",
    sortOrderNbr: 26,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "MS",
    localeCd: "en_US",
    lookupLongVal: "Mississippi",
    sortOrderNbr: 27,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "MO",
    localeCd: "en_US",
    lookupLongVal: "Missouri",
    sortOrderNbr: 28,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "MT",
    localeCd: "en_US",
    lookupLongVal: "Montana",
    sortOrderNbr: 29,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "NE",
    localeCd: "en_US",
    lookupLongVal: "Nebraska",
    sortOrderNbr: 30,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "NV",
    localeCd: "en_US",
    lookupLongVal: "Nevada",
    sortOrderNbr: 31,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "NH",
    localeCd: "en_US",
    lookupLongVal: "New Hampshire",
    sortOrderNbr: 32,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "NJ",
    localeCd: "en_US",
    lookupLongVal: "New Jersey",
    sortOrderNbr: 33,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "NM",
    localeCd: "en_US",
    lookupLongVal: "New Mexico",
    sortOrderNbr: 34,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "NY",
    localeCd: "en_US",
    lookupLongVal: "New York",
    sortOrderNbr: 35,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "NC",
    localeCd: "en_US",
    lookupLongVal: "North Carolina",
    sortOrderNbr: 36,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "ND",
    localeCd: "en_US",
    lookupLongVal: "North Dakota",
    sortOrderNbr: 37,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "OH",
    localeCd: "en_US",
    lookupLongVal: "Ohio",
    sortOrderNbr: 38,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "OK",
    localeCd: "en_US",
    lookupLongVal: "Oklahoma",
    sortOrderNbr: 39,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "OR",
    localeCd: "en_US",
    lookupLongVal: "Oregon",
    sortOrderNbr: 40,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "OT",
    localeCd: "en_US",
    lookupLongVal: "Other",
    sortOrderNbr: 65,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "PA",
    localeCd: "en_US",
    lkpCatgryTyp: "",
    lookupLongVal: "Pennsylvania",
    sortOrderNbr: 66,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "PR",
    localeCd: "en_US",
    lookupLongVal: "Puerto Rico",
    sortOrderNbr: 42,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "RI",
    localeCd: "en_US",
    lookupLongVal: "Rhode Island",
    sortOrderNbr: 43,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "SC",
    localeCd: "en_US",
    lookupLongVal: "South Carolina",
    sortOrderNbr: 44,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "SD",
    localeCd: "en_US",
    lookupLongVal: "South Dakota",
    sortOrderNbr: 45,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "TN",
    localeCd: "en_US",
    lookupLongVal: "Tennessee",
    sortOrderNbr: 46,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "TX",
    localeCd: "en_US",
    lookupLongVal: "Texas",
    sortOrderNbr: 47,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "UT",
    localeCd: "en_US",
    lookupLongVal: "Utah",
    sortOrderNbr: 48,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "VT",
    localeCd: "en_US",
    lookupLongVal: "Vermont",
    sortOrderNbr: 49,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "VA",
    localeCd: "en_US",
    lookupLongVal: "Virginia",
    sortOrderNbr: 50,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "VI",
    localeCd: "en_US",
    lookupLongVal: "Virgin Is.",
    sortOrderNbr: 51,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "WA",
    localeCd: "en_US",
    lookupLongVal: "Washington",
    sortOrderNbr: 52,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "WV",
    localeCd: "en_US",
    lookupLongVal: "West Virginia",
    sortOrderNbr: 53,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "WI",
    localeCd: "en_US",
    lookupLongVal: "Wisconsin",
    sortOrderNbr: 54,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
  {
    systemId: "EEAPI",
    type: "EEAPI",
    lookupTypeCd: "State",
    lkpDesc: "WY",
    localeCd: "en_US",
    lookupLongVal: "Wyoming",
    sortOrderNbr: 55,
    lookupId: 22,
    sysDesc: "EEAPI",
  },
];

export const familyWithIncome = [
  {
    familySize: "1 Person",
    grossAnnual: "$2,248",
  },
  {
    familySize: "2 Person",
    grossAnnual: "$3,041",
  },
  {
    familySize: "3 Person",
    grossAnnual: "$3,833",
  },
  {
    familySize: "4 Person",
    grossAnnual: "$4,625",
  },
  {
    familySize: "5 Person",
    grossAnnual: "$5,418",
  },
  {
    familySize: "6 Person",
    grossAnnual: "$6,210",
  },
  {
    familySize: "7 Person",
    grossAnnual: "$7,003",
  },
  {
    familySize: "8 Person",
    grossAnnual: "$7,795",
  },
  {
    familySize: "9 Person",
    grossAnnual: "$8,588",
  },
  {
    familySize: "10 Person",
    grossAnnual: "$9,380",
  },
  {
    familySize: "Each Additional Family Member",
    grossAnnual: "approx +$792",
  },
];

export const STATUS_INFO = (type) => {
  switch (type) {
    case "AP":
      return "Approved";
    case "PE":
      return "Pending";
    case "DE":
      return "Denied";
    case "WL":
      return "Waitlisted";
    default:
      return type;
  }
};

export const documnetURL = process.env.REACT_APP_FILE_UPLOAD_URL;
export const GET_FILE = "/docControl/getDocuments/KFresh";
