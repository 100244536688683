import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileUploadModal from "../../common/fileUpload/FileUploadModal";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// import { COLUMN } from "./constant";
import DataGridTable from "../../common/DataGrid";
import { AUTH_API, headers } from "../../config";
import { useParams } from "react-router-dom";
import { mapStateToProps } from "../../redux/reducers";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { GET_FILE, documnetURL } from "./constant";
import useTranslator from "../../hooks/useTranslator";
import { getVendorInfoList } from "../../redux/actionTypes/vendordetails";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "22px !important",
    fontWeight: "700 !important",
    color: "#6C6C6C",
    padding: "20px 10px 30px 18px ",
  },
  typography: {
    textAlign: "center",
    fontSize: "18px !important",
    fontWeight: "700 !important",
    color: "#6C6C6C",
    paddingBottom: "20px",
  },
  btn: {
    display: "flex",
    justifyContent: "center",
    border: "2px solid #44A0E3 !important",
    borderRadius: "10px",
    color: " var(--Secon-1-D-Blue, #44A0E3)",
    fontSize: "14px !important ",
    fontWeight: "700 !important",
  },
  actionColumn: {
    color: "#44A0E3",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "0px !important",
    "& .css-1d6wzja-MuiButton-startIcon": {
      marginRight: "3px !important",
    },
  },
  tableBorder: {
    "& .css-0": { padding: "5px 16px" },
    "& .MuiDataGrid-columnHeader": { paddingLeft: "0px !important" },
    "& .MuiDataGrid-row": { paddingLeft: "0px !important" },
  },
}));

const DocumentUpload = (props) => {
  const transProps = useTranslator(
    [
      "documentType",
      "fileName",
      "createdBy",
      "createdDate",
      "action",
      "documents",
    ],
    "documentUpload"
  );
  const classes = useStyles();
  const keyName = "sourceCaseId";
  const [docOpen, setDocOpen] = useState(false);
  const [tableData, setTableData] = useState({});
  const userLogin = sessionStorage.getItem("userLogin")
    ? JSON.parse(sessionStorage.getItem("userLogin"))
    : "";
  const fullName = `${userLogin?.firstName} ${userLogin?.lastName}`;
  const _appNumber = sessionStorage.getItem("appNumber")
    ? sessionStorage.getItem("appNumber")
    : "";
  const {
    seniorInfoData: {
      // programInfo: { createDt = "" } = {},
      demographicDashboardData,
    } = {},
    vendorDetails,
  } = props;
  const appNumber = demographicDashboardData?.applicationModel
    ? demographicDashboardData?.applicationModel.map((obj) => obj.appNumber)
    : [];

  let arrayOfAppnumbers = appNumber.map((value) => {
    return { sourceCaseId: value };
  });
  const appnumberFromSession = numberToArrayWithKey(_appNumber, keyName);

  let payloadApp =
    arrayOfAppnumbers.length > 0 ? arrayOfAppnumbers : appnumberFromSession;
  let orgId = vendorDetails?.vendorInfoList?.hipInfo?.orgId;
  if (orgId) {
    payloadApp[0]["sourceCaseId"] = orgId;
  }

  function numberToArrayWithKey(number, keyName) {
    const resultArray = [
      {
        [keyName]: number.toString(),
      },
    ];
    return resultArray;
  }

  const handlePDFDownload = async (
    e,
    documentId,
    sourceCaseId,
    userFileName
  ) => {
    try {
      const url = `${documnetURL}/docControl/downloadDocumentBy/${documentId}/${sourceCaseId}/KFresh`;
      const response = await axios.post(
        url,
        {},
        { responseType: "blob", headers: headers }
      );
      if (response.status === 200 && response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(blob);
        // window.open(pdfUrl, "_blank");
        const anchor = document.createElement("a");
        anchor.href = pdfUrl;
        anchor.download = userFileName;
        anchor.click();
        // Clean up: remove the anchor element and revoke the URL object
        anchor.remove();
        window.URL.revokeObjectURL(pdfUrl);
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (err) {
      console.log("ERROR", err);
    } finally {
      // hideLoader();
    }
  };

  const handleModalOpen = () => {
    setDocOpen(true);
  };

  useEffect(() => {
    const payload = {
      vendorId: userLogin.vendorId,
      vendorType: "HIPVDR",
    };
    props.getVendorInfoList(payload);
  }, []);

  useEffect(() => {
    getUploadedFiles();
  }, [props.vendorDetails]);

  const getUploadedFiles = async () => {
    try {
      const url = `${documnetURL}${GET_FILE}`;
      const res = await axios.post(url, payloadApp, {
        headers: headers,
      });
      if (res) {
        const modifiedData = (res?.data ?? []).map((item, index) => {
          const datePart = item.createDt.split("T")[0]; /*Setting date format*/
          return {
            ...item,
            id: index + 1,
            createDt: datePart,
          };
        });
        setTableData(modifiedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const COLUMN = [
    {
      field: "id",
      headerName: "S.NO",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "docType",
      headerName: transProps.documentType,
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "userFileName",
      headerName: transProps.fileName,
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "createUserId",
      headerName: transProps.createdBy,
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "createDt",
      headerName: transProps.createdDate,
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "action",
      headerName: transProps.action,
      headerClassName: "grid_header",
      flex: 1,
      renderCell: ({
        row: { documentId = null, sourceCaseId = null, userFileName = "" },
      }) => {
        return (
          <Stack direction="row" alignItems={"center"}>
            <Button
              startIcon={<FileDownloadOutlinedIcon />}
              className={classes.actionColumn}
              onClick={(e) =>
                handlePDFDownload(e, documentId, sourceCaseId, userFileName)
              }
            >
              <span style={{ fontSize: "14px" }}>Download</span>
            </Button>
          </Stack>
        );
      },
    },
  ];
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <Typography className={classes.title}>{transProps.documents}</Typography>

      {/* <div style={{ marginLeft: "15px", marginBottom: "10px" }}>
        <Button className={classes.btn} onClick={handleModalOpen}>
          <UploadFileIcon />
          <span className="">File Upload</span>/
        </Button>
      </div> */}
      <Grid className={classes.tableBorder}>
        <DataGridTable columns={COLUMN} data={tableData} />
      </Grid>
      <FileUploadModal
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        payload={{
          createDt: dayjs().format("YYYY/MM/DD"),
          createUserId: fullName,
          sourceCaseId: appNumber ? appNumber : _appNumber,
        }}
      />
    </div>
  );
};
DocumentUpload.propTypes = {
  fetchProgramInfo: PropTypes.func,
  seniorInfoData: PropTypes.object,
  vendorDetails: PropTypes.object,
  getVendorInfoList: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorInfoList: (payload) => dispatch(getVendorInfoList(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUpload);
