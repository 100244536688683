import React from 'react'
import { Grid } from '@mui/material'
import PageHeaderComponent from "../common/components/pageHeader";
import PaymentsTab from "../mystore/tabs/payments";

const PaymentsComponent = () => {

  return (
    <>
      <Grid container className="container-block" spacing={2}>
        <Grid item xs={12}>
          <PageHeaderComponent
            title={"MANAGE PAYMENTS"}
       
        />
        </Grid>
        <Grid item xs={12}>
            <PaymentsTab isManagePayment={true} allowedRoles={["ADMIN"]} />
        </Grid>
      </Grid>
    </>
  );
};
export default PaymentsComponent;