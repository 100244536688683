import React from "react";
import { Outlet } from "react-router-dom";
import styles from "./index.module.css";
import SideNavigation from "../../senior/sidebar/index";

function MarketLayout() {
  return (
    <div className={styles.layout}>
      <div className={styles.leftSection}>
        <SideNavigation role={"marketRegistration"} isHidden />
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
}

export default MarketLayout;
