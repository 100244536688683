import React, { useState } from "react";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { getFollowVendorDetails } from "../../redux/actionTypes/vendordetails";
import TableComponent from "../common/components/table";
import "../../styles/home.scss";
import TabPanel from "../common/components/tabPanel";
import PageHeaderComponent from "../common/components/pageHeader";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
  };
}

const AlertDetailsComponent = (props) => {
  const [hipSize, setHipSize] = useState(10);
  const [hipFrom, setHipFrom] = useState(0);
  const [csaSize, setCsaSize] = useState(10);
  const [csaFrom, setCsaFrom] = useState(0);
  const [tabValue, setTabValue] = React.useState(0);

  const handleHipNext = (page, rowSize) => {
    hipSize != rowSize && setHipSize(rowSize);
    setHipFrom(rowSize * page);
  };

  const handleCsaNext = (page, rowSize) => {
    csaSize != rowSize && setCsaSize(rowSize);
    setCsaFrom(rowSize * page);
  };

  const followColumns = [
    {
      id: "vendorName",
      label: "Vendor Name",
    },
    {
      id: "primaryEmail",
      label: "Primary Email",
    },
    {
      id: "fnsNum",
      label: "Federal ID",
    },
    {
      id: "isVerified",
      label: "Verified",
    },
  ];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1 }}>
        <Tabs
          indicatorColor=""
          TabIndicatorProps={{
            style: { background: "#ffffff", height: 4, borderTop: "0px" },
          }}
          sx={{ marginBottom: -0.5 }}
          value={tabValue}
          onChange={handleTabChange}
        >
          <Tab
            sx={{
              "&.Mui-selected": {
                borderTop: 1,
                borderLeft: 1,
                borderRight: 1,
                borderColor: "#495057 !important",
                borderWidth: "1",
                color: "#495057",
                bottomBorder: "1px",
              },
              "&:not(.Mui-selected)": {
                color: "#006e52",
                borderBottom: "0",
              },
            }}
            label="SPF Vendor Registration"
            {...a11yProps(0)}
          ></Tab>
          <Tab
            sx={{
              "&.Mui-selected": {
                borderTop: 1,
                borderLeft: 1,
                borderRight: 1,
                borderColor: "#495057",
                color: "#495057",

                borderWidth: "1px",
              },
              "&:not(.Mui-selected)": {
                color: "#006e52",
              },
            }}
            label="SPF Vendor Product"
            {...a11yProps(1)}
          ></Tab>
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <Grid container className="container-block" rowSpacing={3}>
          <Grid item xs={6}>
            <PageHeaderComponent
              title={"SPF FOLLOW UP"}
              showSubtitle={true}
              subtitle={"Total Vendor(s)"}
              count={props.hipTotal}
            />
          </Grid>
          <Grid item xs={12}>
            <TableComponent
              columns={followColumns}
              rows={props.hipFollowRows.slice(
                hipFrom,
                hipSize * (Math.floor(hipFrom / hipSize) + 1)
              )}
              handlePagination={handleHipNext}
              total={props.hipTotal}
              page={Math.floor(hipFrom / hipSize)}
              rowsPerPage={hipSize}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Grid container className="container-block" rowSpacing={3}>
          <Grid item xs={6}>
            <PageHeaderComponent
              title={"SPF FOLLOW UP"}
              showSubtitle={true}
              subtitle={"Total Vendor(s)"}
              count={props.csaTotal}
            />
          </Grid>
          <Grid item xs={12}>
            <TableComponent
              columns={followColumns}
              rows={props.csaFollowRows.slice(
                csaFrom,
                csaSize * (Math.floor(csaFrom / csaSize) + 1)
              )}
              handlePagination={handleCsaNext}
              total={props.csaTotal}
              page={Math.floor(csaFrom / csaSize)}
              rowsPerPage={csaSize}
            />
          </Grid>
        </Grid>
      </TabPanel>
    </Box>
  );
};

AlertDetailsComponent.propTypes = {
  getFollowVendorDetails: PropTypes.func.isRequired,
  vendorDetails: PropTypes.object,
  followRows: PropTypes.object,
  followVendorListTotal: PropTypes.number,
  hipTotal: PropTypes.bool,
  csaTotal: PropTypes.bool,
  hipFollowRows: PropTypes.array,
  csaFollowRows: PropTypes.array,
};

function mapDispatchToProps(dispatch) {
  return {
    getFollowVendorDetails: (payload) =>
      dispatch(getFollowVendorDetails(payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertDetailsComponent);
