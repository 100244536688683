const LookUpPayload = {
  marketRegistration: [
    { lookupType: "bankingtype" },
    { lookupType: "state" },
    { lookupType: "county" },
  ],
  verifyDocument: [
    { lookupType: "verifyDocument" },
    { lookupType: "relationShip" },
  ],
};
export default LookUpPayload;
