import React, { useState } from "react";
import {
  Box,
  Container,
  TextField,
  Typography,
  Grid,
  Stack,
  InputAdornment,
  IconButton,
} from "@mui/material";
import "../../styles/login.scss";
import { FormLabel } from "@mui/material";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { AUTH_API } from "../../config";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useLoader from "../../hooks/useLoader";
import toast from "react-hot-toast";

const Registration = ({ handleLoginDialog }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { openLoader, closeLoader } = useLoader();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    primaryEmail: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
    confirm_password: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Passwords do not match"),
  });

  const seniorInitialValue = {
    firstName: "",
    lastName: "",
    primaryEmail: "",
    primaryMobile: "9453554344",
    password: "",
    confirm_password: "",
    vendorType: "SENIOR",
    isHipAndCsaVdr: false,
    role: "SENIOR",
    isVerified: "N",
    isActive: "N",
    forceLogin: "N",
    createTimeStamp: 1710162108448,
    lastUpdtTimeStamp: 1710162108448,
    submitForm: "SENIOR",
  };

  const formik = useFormik({
    initialValues: seniorInitialValue,
    validationSchema,
    onSubmit: async (values) => {
      handleSeniorReg(values);
    },
  });

  const handleSeniorReg = async (values) => {
    try {
      openLoader("senior_signUp");
      const res = await axios.post(
        AUTH_API + "/public/senior/register",
        values,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.code == "200") {
        handleLoginDialog();
        formik.resetForm();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Registration Failed!");
    } finally {
      closeLoader("senior_signUp");
    }
  };

  return (
    <>
      <Grid container component="main" className="login-root">
        <Grid item xs={12} sm={12} md={12} square pb={4}>
          <Container maxWidth="xs">
            <div className="login-paper">
              <h2 className="font-bold boldFont">Create Account</h2>
              <Stack
                component="form"
                className="login-form"
                onSubmit={formik.handleSubmit}
              >
                <Box sx={{ marginTop: "0.5rem" }}>
                  <Stack direction="row" spacing={2}>
                    <div>
                      <FormLabel className="labelName">First Name</FormLabel>
                      <TextField
                        fullWidth
                        id="fname"
                        placeholder="First name"
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.firstName && formik.errors.firstName
                        }
                        helperText={
                          formik.touched.firstName && formik.errors.firstName
                        }
                      />
                    </div>{" "}
                    <div>
                      <FormLabel className="labelName">Last name</FormLabel>
                      <TextField
                        fullWidth
                        id="lname"
                        placeholder="Last name"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.lastName && formik.errors.lastName
                        }
                        helperText={
                          formik.touched.lastName && formik.errors.lastName
                        }
                      />
                    </div>
                  </Stack>
                </Box>
                <Box sx={{ marginTop: "0.5rem" }}>
                  <FormLabel className="labelName">Email </FormLabel>
                  <TextField
                    fullWidth
                    id="email"
                    placeholder="Enter Email Id"
                    name="primaryEmail"
                    value={formik.values.primaryEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.primaryEmail && formik.errors.primaryEmail
                    }
                    helperText={
                      formik.touched.primaryEmail && formik.errors.primaryEmail
                    }
                  />
                </Box>
                <Box sx={{ marginTop: "0.5rem" }}>
                  <FormLabel className="labelName">Password</FormLabel>
                  <TextField
                    fullWidth
                    name="password"
                    placeholder="Enter your password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && formik.errors.password}
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>{" "}
                <Box sx={{ marginTop: "0.5rem" }}>
                  <FormLabel className="labelName">Confirm Password</FormLabel>
                  <TextField
                    fullWidth
                    name="confirm_password"
                    placeholder="Enter your confirm password"
                    type="password"
                    id="cf_password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.confirm_password &&
                      formik.errors.confirm_password
                    }
                    helperText={
                      formik.touched.confirm_password &&
                      formik.errors.confirm_password
                    }
                  />
                </Box>
                <Button className="login-button" type="submit">
                  Signup
                </Button>
              </Stack>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body1" mt={1}>
                  Already have an account?{" "}
                  <span
                    style={{ color: "#44A0E3", cursor: "pointer" }}
                    onClick={handleLoginDialog}
                  >
                    Login
                  </span>
                </Typography>
              </Box>
            </div>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

Registration.propTypes = {
  handleLoginDialog: PropTypes.func,
};

export default Registration;
