import React from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router";
import { Box, Stack } from "@mui/material";
import SideNavigation from "../../pages/senior/sidebar";

function AdminContainer() {
  return (
    <Stack direction="row" sx={{}}>
      <Box sx={{ background: "#000" }}>
        <SideNavigation role="admin" />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
    </Stack>
  );
}

AdminContainer.propTypes = {};

export default AdminContainer;
