const initState = {
    commentList: {
        list:[], total:0
    }
};

const CommentsReducer = (state = initState, action = {}) => {
    const newState = {...state};

    switch (action.type) {
        case "SET_COMMENT_LIST":
            return {
                ...newState,
                commentList: {list:action.payload.data, total:action.payload.total}
            };
        case "ADD_COMMENT_RESPONSE":
            return {
                ...newState, addCommentResponse:
                    {data: action.value}
            };
        case "UPDATE_COMMENT_RESPONSE":
            return {
                ...newState, updateCommentResponse:
                    {data: action.value}
            };
        case "DELETE_COMMENT_RESPONSE":
            return {
                ...newState, deleteCommentResponse:
                    {data: action.value}
            };
        default:
            return newState;
    }
};
export default CommentsReducer;